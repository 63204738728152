import { Component, Input, OnInit } from '@angular/core';

import { BaseSelectComponent } from '../../../base/tc-selects/base-select.component';

@Component({
  selector: 'tc-hub-select',
  templateUrl: './select.component.html',
  styleUrls: ['../base-select.component.scss', './select.component.scss'],
})
export class SelectComponent extends BaseSelectComponent implements OnInit {
  @Input() type: string;

  @Input() items = [];

  constructor() {
    super();
  }

  ngOnInit() {
    this.loading = false;
  }
}
