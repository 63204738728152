<ng-select
  [(ngModel)]="innerValue"
  [items]="items$ | async"
  bindLabel="_label"
  [typeahead]="typeahead$"
  bindValue="id"
  searchable="true"
  [inputAttrs]="{ class: 'form-control' }"
>
</ng-select>
