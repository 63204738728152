<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-5">
          <div class="form-group">
            <tc-hub-collection-select
              label="{{ 'dispo/task.attrs.plan' | translate }}"
              [collectionTypes]="['DispoPlan']"
              [formGroup]="form"
              controlName="plan_id"
              bindLabel="label"
              bindValue="id"
              [searchable]="true"
            >
            </tc-hub-collection-select>
          </div>
        </div>
        <div class="col-xs-12 col-sm-2">
          <div class="form-group">
            <tc-input-integer-web
              label="{{ 'dispo/task.attrs.size' | translate }}"
              formControlName="size"
            ></tc-input-integer-web>
          </div>
        </div>
        <div class="col-xs-12 col-sm-5">
          <div class="form-group">
            <tc-input-date-web
              label="{{ 'dispo/task.attrs.date' | translate }}"
              [tcConfig]="{
                limit: dateLimit
              }"
              formControlName="date"
            ></tc-input-date-web>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <tc-interval-set-web
            formControlName="times"
            [tcReferenceDate]="form.value.date"
            [tcConfig]="{
              limit: dateLimit
            }"
          ></tc-interval-set-web>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="copy()"
    >
      {{ 'copy' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="move()"
    >
      {{ 'move' | translate }}
    </button>
  </div>
</form>
