import {
  AsyncSubject,
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  bufferTime,
  catchError,
  debounceTime,
  filter,
  first,
  flatMap,
  map,
  shareReplay,
} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { deserialize } from 'typeserializer';
import format from 'date-fns/format';
import FuzzySearch from 'fuzzy-search';
import * as _ from 'lodash-es';

import {
  DateUnicodeFormat,
  parseDate,
  removeNullProperties,
} from '@timecount/utils';

import { Cable } from './cable';
import { StoreService } from './store.service';
import { debug, dig } from './helpers';
import { CurrentUserService } from './current-user.service';
import { ApiErrorService } from './api-error.service';
import { ResponseMessage } from './types/response-message.model';
import { ActionType } from './types/action-type';
import { Validation } from './types/validation';
import { ValidationErrorLevel } from './types/validation-error-level';
import { DeletableResponse } from './types/deletable-response.model';
import { ConflictingEntryType } from './types/conflicting-entry-type';
import { LocalSettingsService } from './local-settings.service';

@Injectable({
  providedIn: 'root',
})
export class SetsCollection {
  public identifier: string;
  public endpoint: string;
  public type: object;
  public refresh: number;
  public cache: number;
  public decorators: Observable<any[]>[] = [];
  public client_id;
  public localSearch;
  public bindLabel;
  public loadOnInit = false;
  public refreshOnStale = true;
  public remoteDeleteValidation = false;

  protected setsCache = {};

  protected operations$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  protected syncItems$: Subject<any> = new Subject();

  constructor(
    protected http: HttpClient,
    protected cable: Cable,
    protected store: StoreService,
    protected errorHandler: ApiErrorService,
    protected currentUser: CurrentUserService,
    protected localSettingsService: LocalSettingsService,
    @Inject('Flash') protected flash,
  ) {
    this.client_id = uuid();
    setTimeout(() => this.init(), 1);
  }

  init() {
    this.cable.subscribe((sync) => {
      if (Array.isArray(sync.data) && sync.data.length === 0) {
        return;
      }

      if (
        sync.resource === this.identifier &&
        sync.client_id !== this.client_id
      ) {
        if (this.currentUser.hasAccessToRecord(sync.data, sync.resource)) {
          this.syncItems$.next(sync);
        }
      }
    });

    this.cable.connectionStale().subscribe(() => {
      if (this.refreshOnStale) {
        this.refreshCollection();
      }
    });

    this.syncItems$.pipe(bufferTime(200)).subscribe((syncs) => {
      syncs.forEach((sync) => {
        this.handleSync(sync);
      });
    });

    this.localSettingsService
      .get(this.identifier + '.operation', [])
      .pipe(first())
      .subscribe((operation: any) => {
        this.operations$.next(operation);
      });

    if (!this.refresh) {
      this.operations$
        .pipe(
          debounceTime(2000),
          filter((ops) => ops?.length > 3),
        )
        .subscribe((ops) => {
          ops = [...ops].sort();
          let missingOps = false;

          ops.reduce((lastOp, op) => {
            if (lastOp && lastOp !== op && lastOp < op - 1) {
              missingOps = true;
            }

            return op;
          });

          if (missingOps) {
            this.refreshCollection();
          } else {
            this.localSettingsService.set(this.identifier + '.operation', ops);
          }
        });
    }

    if (this.loadOnInit) {
      this.initLoad()
        .all()
        .pipe()
        .subscribe({
          next: (res) => {
            // do nothing
          },
        });
    }
  }

  query(q, syncFunc): SetCollection {
    const lookup = JSON.stringify(q);
    if (!this.setsCache[lookup]) {
      this.setsCache[lookup] = new SetCollection(
        q,
        syncFunc,
        this.http,
        this.store,
        this.localSettingsService,
        this,
      );
    }

    return this.setsCache[lookup];
  }

  queryAll(): SetCollection {
    return this.query({}, (e) => true);
  }

  all(): Observable<any[]> {
    return this.queryAll().all();
  }

  label() {
    return this.bindLabel;
  }

  initLoad() {
    return this.queryAll();
  }

  find(id: number, options = { request: {}, query: {} }) {
    const url = `${this.subEndpoint(options.query)}/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const response$ = new AsyncSubject();

    if (id) {
      this.http
        .get(url, Object.assign(httpOptions, options.request))
        .pipe(first())
        .subscribe({
          next: (resp: any) => {
            this.processItem(resp.data).subscribe({
              next: (item) => {
                response$.next(item);
                response$.complete();
              },
            });
          },
          error: (error: any) => {
            response$.error(error);
            response$.complete();
          },
        });
    } else {
      response$.error('');
    }

    return response$;
  }

  get(id: number, options = { request: {}, query: {} }) {
    return this.query({}, (e) => true).get(id, options);
  }

  search(query: string, options = { request: {}, query: {} }) {
    return this.query({}, (e) => true).search(query, options);
  }

  remoteSearch(query: string, options = { request: {}, query: {} }) {
    if (!query) {
      query = ' ';
    }

    const url = `${this.subEndpoint(options.query)}/search/${encodeURIComponent(
      query,
    )}`;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const response$ = new AsyncSubject();

    this.http
      .get(url, Object.assign(httpOptions, options.request))
      .pipe(first())
      .subscribe({
        next: (resp: any) => {
          this.processItems(resp.data).subscribe({
            next: (item) => {
              response$.next(item);
              response$.complete();
            },
          });
        },
        error: (error: any) => {
          response$.error(error);
          response$.complete();
        },
      });

    return response$;
  }

  create(obj: any, options = { request: {}, query: {} }) {
    const url = `${this.subEndpoint(options.query)}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const request$ = this.http
      .post(
        url,
        this.transport(obj),
        Object.assign(httpOptions, options.request),
      )
      .pipe(first());
    const response$ = new AsyncSubject();

    obj.id = uuid();
    this.syncItems$.next({
      action: 'create',
      resource: this.identifier,
      data: obj,
    });

    request$.subscribe(
      (resp: any) => {
        // delete optimistic obj
        this.syncItems$.next({
          action: 'delete',
          resource: this.identifier,
          data: obj,
        });

        this.syncItems$.next({
          action: 'create',
          resource: this.identifier,
          cycle: resp.cycle,
          data: resp.data,
        });

        this.processItem(resp.data).subscribe({
          next: (item) => {
            response$.next(item);
            response$.complete();
          },
        });

        this.showMessages(resp.meta.messages);
      },
      (resp) => {
        console.warn(resp);
        this.syncItems$.next({
          action: 'delete',
          resource: this.identifier,
          data: obj,
        });

        this.errorHandler.handle(resp);
        response$.error(resp.error);
        response$.complete();
      },
    );

    return response$;
  }

  clone(obj: any): Promise<any> {
    return new Promise((resolve) => {
      const clonedObject = _.cloneDeep(_.omit(obj, 'id'));
      resolve(clonedObject);
    });
  }

  update(id: number, obj: any, options = { request: {}, query: {} }) {
    const url = `${this.subEndpoint(options.query)}/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const request$ = this.http
      .put(
        url,
        this.transport(obj),
        Object.assign(httpOptions, options.request),
      )
      .pipe(first());
    const response$ = new AsyncSubject();

    const prevItems = this.syncItems$.next({
      action: 'update',
      resource: this.identifier,
      data: obj,
    });

    request$.subscribe(
      (resp: any) => {
        // this.addOperation(resp.cycle);
        this.syncItems$.next({
          action: 'update',
          resource: this.identifier,
          data: resp.data,
          cycle: resp.cycle,
        });

        this.processItem(resp.data).subscribe({
          next: (item) => {
            response$.next(item);
            response$.complete();
          },
        });

        this.showMessages(resp.meta.messages);
      },
      (resp) => {
        console.warn(resp);
        this.syncItems$.next({
          action: 'update',
          resource: this.identifier,
          data: prevItems[0],
        });

        this.errorHandler.handle(resp);
        response$.error(resp.error);
        response$.complete();
      },
    );

    return response$;
  }

  getAll(options = { request: {}, query: {} }) {
    const flattenQueryObject = (obj, result = {}, path = '') => {
      // currently only simple arrays are possible
      if (Array.isArray(obj)) {
        result[`${path}[]`] = obj;
      } else {
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
          const nestedPath = path ? `${path}[${key}]` : key;

          if (typeof value === 'undefined' || value === null) {
            result[nestedPath] = undefined;
          } else if (typeof value === 'object') {
            flattenQueryObject(value, result, nestedPath);
          } else {
            result[nestedPath] = value;
          }
        });
      }

      return result;
    };

    const params = flattenQueryObject(options.query);
    const url = this.subEndpoint(options.query);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams({ fromObject: <any>params }),
    };

    return this.http.get(url, httpOptions).pipe(
      catchError((err, _obs) => {
        console.warn(err);
        return of({ data: [] });
      }),
    );
  }

  validate(
    obj: any,
    options = { request: {}, query: {} },
  ): AsyncSubject<{ valid: boolean; errors: any[]; warnings: any[] }> {
    const url = `${this.subEndpoint(options.query)}/validate`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const request$ = this.http
      .post(
        url,
        this.transport(obj),
        Object.assign(httpOptions, options.request),
      )
      .pipe(first());
    const response$: AsyncSubject<{
      valid: boolean;
      fatal: boolean;
      errors: any[];
      warnings: any[];
    }> = new AsyncSubject();

    request$.subscribe(
      (resp: any) => {
        response$.next({ valid: true, fatal: false, errors: [], warnings: [] });
        response$.complete();
      },
      (resp) => {
        resp = resp.error;
        const errors = [...Object.values(resp.errors || {})];
        const warnings = [...Object.values(resp.warnings || {})];

        response$.next({
          valid: false,
          fatal: errors.length !== 0,
          errors: errors,
          warnings: warnings,
        });
        response$.complete();
      },
    );

    return response$;
  }

  remoteValidations(
    item: any,
    stack: any[],
    action: ActionType,
    options = { request: {}, query: {} },
  ): Observable<Validation[]>[] {
    let remoteAction;

    switch (action) {
      case ActionType.delete:
        remoteAction = this.deletable(item.id, item, options);
        break;
      case ActionType.create:
      case ActionType.update:
        remoteAction = this.validate(item, options);
        break;
      default:
        remoteAction = of({ warnings: [], errors: [] });
        break;
    }

    return [
      remoteAction.pipe(
        map((resp: any) => {
          const warnings = resp.warnings.map(
            (message: string): Validation => ({
              id: uuid(),
              message,
              type: ValidationErrorLevel.warning,
              conflicting_entry_type: ConflictingEntryType.None,
            }),
          );

          const errors = resp.errors.map(
            (message: string): Validation => ({
              id: uuid(),
              message,
              type: ValidationErrorLevel.critical,
              conflicting_entry_type: ConflictingEntryType.None,
            }),
          );

          return [...warnings, ...errors];
        }),
      ),
    ];
  }

  deletable(
    id: number,
    obj: any,
    options = { request: {}, query: {} },
  ): AsyncSubject<DeletableResponse> {
    const response$: AsyncSubject<DeletableResponse> = new AsyncSubject();
    if (this.remoteDeleteValidation) {
      const url = `${this.subEndpoint(options.query)}/deletable/${id}`;
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      };

      const request$ = this.http
        .get(url, Object.assign(httpOptions, options.request))
        .pipe(first());

      request$.subscribe(
        (resp: any) => {
          const warnings = [].concat(...Object.values(resp.warnings || {}));

          response$.next({
            accepted: true,
            messages: Object.values(resp.warnings || {}),
            warnings: warnings,
            errors: [],
            item: obj,
          });
          response$.complete();
        },
        (resp) => {
          const errors = [].concat(...Object.values(resp.error?.errors || {}));
          const warnings = [].concat(
            ...Object.values(resp.error?.warnings || {}),
          );

          response$.next({
            accepted: false,
            messages: resp.error?.errors || [],
            warnings: warnings,
            errors: errors,
            item: obj,
          });
          response$.complete();
        },
      );
    } else {
      response$.next({
        accepted: true,
        item: obj,
        messages: [],
        errors: [],
        warnings: [],
      });
      response$.complete();
    }
    return response$;
  }

  delete(
    ids: number | number[],
    obj: any,
    options = { request: {}, query: {} },
  ) {
    let url = `${this.subEndpoint(options.query)}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    if (Array.isArray(ids)) {
      Object.assign(httpOptions, { body: { ids } });
    } else {
      url += `/${ids}`;
    }

    const request$ = this.http
      .delete(url, Object.assign(httpOptions, options.request))
      .pipe(first());
    const response$ = new AsyncSubject();

    this.syncItems$.next({
      action: 'delete',
      resource: this.identifier,
      data: obj,
    });

    request$.subscribe(
      (resp: any) => {
        this.addOperation(resp.cycle);
        this.syncItems$.next({
          action: 'delete',
          resource: this.identifier,
          data: resp.data,
          cycle: resp.cycle,
        });

        this.showMessages(resp.meta.messages);

        response$.next(resp);
        response$.complete();
      },
      (resp) => {
        console.warn(resp);
        this.syncItems$.next({
          action: 'update',
          resource: this.identifier,
          data: obj,
        });

        this.errorHandler.handle(resp);
        response$.error(resp.error);
        response$.complete();
      },
    );

    return response$;
  }

  action(
    action: string,
    id: number,
    obj: any,
    options = { request: {}, query: {} },
  ) {
    const url = id
      ? `${this.subEndpoint(options.query)}/${id}/${action}`
      : `${this.subEndpoint(options.query)}/${action}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const request$ = this.http
      .put(
        url,
        this.transport(obj),
        Object.assign(httpOptions, options.request),
      )
      .pipe(first());
    const response$ = new AsyncSubject();

    request$.subscribe(
      (resp: any) => {
        const actionType = resp.data.id !== id ? 'create' : 'update';
        this.syncItems$.next({
          action: actionType,
          resource: this.identifier,
          data: resp.data,
          cycle: resp.cycle,
        });

        this.processItem(resp.data).subscribe({
          next: (item) => {
            response$.next(item);
            response$.complete();
          },
        });

        this.showMessages(resp.meta.messages);
      },
      (resp) => {
        // TODO: display error
        console.warn(resp);

        this.errorHandler.handle(resp);
        response$.error(resp.error);
        response$.complete();
      },
    );

    return response$;
  }

  refreshCollection() {
    this.operations$.next([]);
    this.localSettingsService.set(this.identifier + '.operation', []);
    Object.values(this.setsCache).map((set: SetCollection) =>
      set.refreshCollection(),
    );
  }

  transport(obj) {
    //it is required to remove the properties without a value from the store,
    //as the backend sometimes is adding default values for missing properties
    if (obj.store) {
      obj.store = removeNullProperties(obj.store);
    }
    obj = Object.assign({}, obj);
    obj.client_id = this.client_id;
    // TODO: fix serialize for dates (currently creates empty objects)
    // if (this.type) {
    //   obj = serialize(obj);
    // } else {
    obj = this.simple_transport(obj);
    // }

    return obj;
  }

  transform(obj) {
    if (this.type) {
      return [deserialize(JSON.stringify(obj), this.type)];
    } else {
      return [this.simple_transform(obj)];
    }
  }

  decorate(obj, ...decorators) {
    return obj;
  }

  sync(obj: any) {
    this.syncItems$.next({
      action: 'update',
      resource: this.identifier,
      data: obj,
    });
  }

  updateLegacyEntries(legacyEntries: any, entries: any[]) {
    const entriesLookup = {};
    const entriesInsertedLookup = {};
    const entriesToBeRemoved = [];

    entries = entries.filter((entry) => parseInt(entry.id) === entry.id);

    entries.forEach((entry) => {
      entriesLookup[entry.id] = entry;
      entriesInsertedLookup[entry.id] = false;
    });

    legacyEntries.forEach(function (entry) {
      const newEntry = entriesLookup[entry.id];

      if (newEntry) {
        entry = Object.assign(entry, newEntry);

        entriesInsertedLookup[entry.id] = true;
      } else {
        entriesToBeRemoved.push(entry);

        entriesInsertedLookup[entry.id] = true;
      }
    });

    entriesToBeRemoved.forEach(function (entry) {
      const index = legacyEntries.findIndex((e) => e.id === entry.id);

      if (index !== -1) {
        legacyEntries.splice(index, 1);
      }
    });

    entries
      .sort((a, b) => {
        return a.id - b.id;
      })
      .forEach(function (entry) {
        if (!entriesInsertedLookup[entry.id]) {
          legacyEntries.push(entry);
        }
      });

    legacyEntries.cacheKey = Math.random().toString(36);
  }

  protected addOperation(op) {
    op = parseInt(op, 10);
    if (op) {
      const ops = this.operations$.value.concat([op]);
      this.operations$.next(ops);
    }
  }

  protected handleSync(sync) {
    let prevItems = [];
    if (this.operations$.value.indexOf(sync.cycle) !== -1) {
      return;
    }

    this.addOperation(sync.cycle);

    Object.values(this.setsCache).forEach((f: SetCollection) => {
      const setPrevItems = f.handleSync(sync);
      if (Array.isArray(setPrevItems)) {
        prevItems = prevItems.concat(setPrevItems.filter((i) => i));
      } else if (setPrevItems) {
        prevItems.push(setPrevItems);
      }
    });

    return prevItems;
  }

  protected simple_transform(obj) {
    Object.keys(obj).forEach((key) => {
      const dates = [
        'starts_at',
        'ends_at',
        'intermission_starts_at',
        'intermission_ends_at',
      ];

      if (dates.indexOf(key) !== -1 && obj[key]) {
        obj[key] = parseDate(obj[key]);
      }
    });

    return obj;
  }

  protected simple_transport(obj) {
    const resp = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] instanceof Date) {
        const date = obj[key];
        resp[key] = format(date, DateUnicodeFormat.apiDateTime);
      } else {
        resp[key] = obj[key];
      }
    });

    return JSON.stringify(resp);
  }

  private subEndpoint(query) {
    return this.endpoint.replace(/:(\w*)/g, (match, queryProp) => {
      const value = dig(query, queryProp);
      if (value) {
        return value;
      } else {
        return '';
      }
    });
  }

  private processItems(unprocessedEntries: any[]) {
    return combineLatest([of(unprocessedEntries), ...this.decorators]).pipe(
      map(([items, ...decorators]) => {
        return items.map((item) =>
          this.decorate(Object.assign({}, item), decorators),
        );
      }),
      map((items) => items.map((item) => this.transform(item))),
      map((items) => [...items]),
      first(),
    );
  }

  private processItem(unprocessedEntry: any) {
    return combineLatest(of(unprocessedEntry), ...this.decorators).pipe(
      map(([item, ...decorators]) => {
        return this.decorate(Object.assign({}, item), decorators);
      }),
      map((item) => {
        return this.transform(item);
      }),
      map((items) => {
        if (items.length === 1) {
          return items[0];
        } else {
          return items;
        }
      }),
      first(),
    );
  }

  private showMessages(messages: ResponseMessage[]) {
    messages.forEach((message) => {
      this.flash.create(message.detail, message.type);
    });
  }
}

export class SetCollection {
  protected init = false;
  protected refreshing = false;
  protected lastRefreshed = 0;
  protected result: any[] = [];
  protected processedResult: any[] = [];

  protected filterCache = {};

  protected loadSignal$: ReplaySubject<boolean> = new ReplaySubject(1);
  protected syncSignal$: ReplaySubject<boolean> = new ReplaySubject(1);
  protected all$: ReplaySubject<any[]> = new ReplaySubject(1);

  protected allResult$: Observable<any[]> = this.all$.pipe(
    map((items: any[]): any[] => {
      const result = [];
      const ids = {};

      items.forEach((item) => {
        if (!ids[item.id] || item.id === undefined) {
          ids[item.id] = true;
          result.push(item);
        }
      });

      return result;
    }),
    flatMap((items) => {
      return combineLatest(of(items), ...this.parent.decorators);
    }),
    map(([items, ...decorators]) => {
      return items.map((item) => {
        if (item.decorated) {
          return item;
        } else {
          return this.parent.decorate({ ...item, decorated: true }, decorators);
        }
      });
    }),
    map((items) => {
      const result = [];

      items.forEach((item) => {
        if (item.transformed) {
          result.push(item);
        } else {
          result.push(...this.parent.transform({ ...item, transformed: true }));
        }
      });

      return result;
    }),
    map((items: any[]): any[] => {
      this.cache(items);
      return items;
    }),
    map((items) => {
      setTimeout(() => {
        this.loadSignal$.next(true);
        this.syncSignal$.next(true);
      }, 10);

      this.result = items;
      return items;
    }),
    shareReplay(1),
  );

  constructor(
    protected query: object,
    protected syncFunc: (unknown) => boolean,
    protected http: HttpClient,
    protected store: StoreService,
    protected localSettingsService: LocalSettingsService,
    protected parent: SetsCollection,
  ) {
    this.loadSignal$.next(false);
    this.syncSignal$.next(false);

    if (parent.refresh) {
      setInterval(() => {
        this.refreshCollection();
      }, parent.refresh * 1000 * (Math.random() / 3 + 1));
    }
  }

  all(): Observable<any> {
    if (!this.init) {
      this.init = true;

      if (
        !this.localSettingsService.get('isCacheEnabled', false).getValue() ||
        !this.parent.cache
      ) {
        this.refreshCollection();
      } else {
        const cacheKey = this.cacheKey();

        this.store
          .getItem(cacheKey)
          .pipe(
            first(),
            map((cache: any) => {
              if (!cache) {
                debug(this.parent.endpoint + ' set - no cache found');
                return null;
              }

              const validUntil = cache.timeout - new Date().getTime();

              if (validUntil < 0) {
                debug(this.parent.endpoint + ' set invalid - refreshing soon');
                return null;
              }

              return [cache.data, cache.timeout, validUntil];
            }),
            catchError((error: unknown) => {
              this.refreshCollection();

              if (error instanceof Error) {
                debug(`${this.parent.endpoint} ${error.message}`);
              }

              return of(null);
            }),
          )
          .subscribe((result) => {
            if (result) {
              const [data, timeout, validUntil] = result;
              this.lastRefreshed = timeout - this.parent.cache * 1000;
              this.result = data;

              debug(
                this.parent.endpoint + ' set valid for ' + validUntil / 1000,
              );

              this.all$.next(this.result);
            } else {
              // Handle the case when cache is not available or invalid
              // You can choose to refresh the collection or take any other appropriate action
              this.refreshCollection();
            }
          });
      }
    }

    return this.allResult$;
  }
  loaded(): ReplaySubject<boolean> {
    return this.loadSignal$;
  }

  current() {
    return [...this.processedResult];
  }

  refreshCollection() {
    if (this.refreshing) {
      return;
    }

    this.refreshing = true;
    this.loadSignal$.next(false);
    this.syncSignal$.next(false);

    const options = { query: this.query, request: {} };

    this.parent
      .getAll(options)
      .pipe(first())
      .subscribe((res: any) => {
        this.refreshing = false;
        this.lastRefreshed = new Date().getTime();
        this.result = res.data;
        // this.cache(res.data);
        this.all$.next(this.result);
        // this.loadSignal$.next(true);
      });
  }

  find(id: number, options = { request: {}, query: {} }) {
    options.query = this.query;
    return this.parent.find(id, options);
  }

  get(id: number, options = { request: {}, query: {} }) {
    const response$ = new AsyncSubject();
    this.all()
      .pipe(first())
      .subscribe({
        next: (items: any[]) => {
          const item = items.find((t) => t.id === id);

          if (item && id) {
            response$.next(item);
            response$.complete();
          } else {
            this.find(id, options).subscribe({
              next: (remoteItem) => {
                response$.next(remoteItem);
              },
              error: (error: any) => {
                response$.error(error);
              },
              complete: () => {
                response$.complete();
              },
            });
            response$.error('Not found');
          }
        },
        error: (error: any) => {
          response$.error(error);
        },
        complete: () => {
          response$.complete();
        },
      });

    return response$;
  }

  remoteSearch(query: string, options = { request: {}, query: {} }) {
    options.query = this.query;
    return this.parent.remoteSearch(query, options);
  }

  search(query: string, options = { request: {}, query: {} }) {
    if (this.parent.localSearch) {
      const response$ = new AsyncSubject();
      this.all()
        .pipe(first())
        .subscribe({
          next: (items: any[]) => {
            if (query && query.trim()) {
              const searcher = new FuzzySearch(items, this.parent.localSearch, {
                sort: true,
              });
              items = searcher.search(query);
            } else {
              const sortAttribute = this.parent.localSearch[0];
              items = items.sort((a, b) =>
                ('' + a[sortAttribute]).localeCompare(b[sortAttribute]),
              );
            }

            response$.next(items);
            response$.complete();
          },
          error: (error: any) => {
            response$.error(error);
          },
          complete: () => {
            response$.complete();
          },
        });

      return response$;
    } else {
      return this.remoteSearch(query, options);
    }
  }

  create(obj: any, options = { request: {}, query: {} }) {
    options.query = this.query;
    return this.parent.create(obj, options);
  }

  clone(obj: unknown): Promise<unknown> {
    return this.parent.clone(obj);
  }

  update(id: number, obj: any, options = { request: {}, query: {} }) {
    options.query = this.query;
    return this.parent.update(id, obj, options);
  }

  action(
    action: string,
    id: number,
    obj: any,
    options = { request: {}, query: {} },
  ) {
    options.query = this.query;
    return this.parent.action(action, id, obj, options);
  }

  validate(obj: any, options = { request: {}, query: this.query }) {
    return this.parent.validate(obj, options);
  }

  remoteValidations(
    item: any,
    stack: any[],
    action: ActionType,
    options = { request: {}, query: this.query },
  ) {
    return this.parent.remoteValidations(item, stack, action, options);
  }

  deletable(id: number, obj: any, options = { request: {}, query: {} }) {
    options.query = this.query;
    return this.parent.deletable(id, obj, options);
  }

  delete(
    id: number | number[],
    obj: any,
    options = { request: {}, query: {} },
  ) {
    options.query = this.query;
    return this.parent.delete(id, obj, options);
  }

  filter(func, identifier): BehaviorSubject<any[]> {
    if (!this.init) {
      this.all();
    }

    if (!this.filterCache[identifier]) {
      const subject = new ReplaySubject(1);

      this.filterCache[identifier] = {
        filter: func,
        subject: subject,
        value: [],
      };

      this.loadSignal$
        .pipe(
          filter((signal) => signal),
          // debounceTime(500) // TODO: wait for allResult, improve in case transform takes too long
        )
        .subscribe((_) => {
          this.allResult$.pipe(first()).subscribe((result) => {
            result = result.filter(func);
            this.filterCache[identifier].value = result;
            subject.next(result);
          });
        });
    }

    return this.filterCache[identifier].subject;
  }

  label() {
    return this.parent.bindLabel;
  }

  handleSync(sync) {
    this.syncSignal$.next(false);

    if (sync.data instanceof Array) {
      const prevItems = sync.data.map((data) => {
        const singleDataSync = Object.assign({}, sync);
        singleDataSync.data = data;
        return this.handleSync(singleDataSync);
      });

      return prevItems;
    }

    this.handleFilterSync(sync);
    return this.insertSyncData(sync);
  }

  protected handleFilterSync(sync) {
    const data = Array.isArray(sync.data) ? sync.data : [sync.data];
    const ids = data.map((i) => i.id);

    this.syncSignal$
      .pipe(
        filter((s) => s),
        first(),
      )
      .subscribe({
        next: (_) => {
          this.allResult$.pipe(first()).subscribe((res) => {
            Object.values(this.filterCache).map((f: any) => {
              if (
                // filter does not apply
                data.filter(f.filter).length > 0 ||
                // special case -> filtered attribute changed
                f.value.filter((i) => ids.includes(i.id)).length > 0
              ) {
                const newFilterValue = res.filter(f.filter);
                f.value = newFilterValue;
                f.subject.next(newFilterValue);
              }
            });
          });
        },
      });
  }

  private insertSyncData(sync) {
    let prevItem;

    sync.data.transformed = false;
    sync.data.decorated = false;

    if (sync.action === 'delete') {
      const index = this.result.findIndex((i: any) => i.id === sync.data.id);

      if (index !== -1) {
        prevItem = this.result.splice(index, 1);
        this.all$.next(this.result);
      } else {
        this.syncSignal$.next(true);
      }
    }

    if (sync.action === 'update') {
      const index = this.result.findIndex((i: any) => i.id === sync.data.id);

      if (this.syncFunc(sync.data)) {
        if (index !== -1) {
          prevItem = this.result[index];
          this.result[index] = sync.data;
        } else {
          this.result.push(sync.data);
        }

        this.all$.next(this.result);
      } else {
        if (index !== -1) {
          prevItem = this.result.splice(index, 1);
          this.all$.next(this.result);
        } else {
          this.syncSignal$.next(true);
        }
      }
    }

    if (sync.action === 'create') {
      if (this.syncFunc(sync.data)) {
        const alreadyPresent = this.result.find(
          (i: any) => i.id === sync.data.id,
        );
        if (alreadyPresent) {
          return;
        }

        // const index = this.result.findIndex( (i: any) => i._id === sync.data._id);

        // if (index === -1 || !sync.data._id) {
        this.result.push(sync.data);
        // } else {
        // this.result[index] = sync.data;
        // }

        this.all$.next(this.result);
      } else {
        this.syncSignal$.next(true);
      }
    }

    return prevItem;
  }

  private cache(items) {
    if (
      this.parent.cache &&
      this.localSettingsService.get('isCacheEnabled', false).getValue()
    ) {
      const timeout = this.lastRefreshed + this.parent.cache * 1000;

      this.store.setItem(this.cacheKey(), items, timeout);
    }
  }

  private cacheKey() {
    return this.parent.endpoint + '|' + JSON.stringify(this.query);
  }
}
