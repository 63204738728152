<td
  colspan="10"
  [nzExpand]="item.expanded"
  (nzExpandChange)="toggleItem(item.type, item.id)"
>
  <label
    nz-checkbox
    [(nzChecked)]="item.checked"
    [nzIndeterminate]="item.indeterminate"
  ></label>

  {{ 'timesheet.orphaned' | translate }}
</td>
