import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { Directive } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzTimePickerComponent } from 'ng-zorro-antd/time-picker';
import { Subject } from 'rxjs';

import { isValidDate } from '@timecount/utils';

import { TcCustomPickerBaseComponent } from './custom-picker-base.component';

/**
 * This directive is a base for both nz and custom pickers
 */
@Directive()
export abstract class TcBasePickerDirective {
  picker:
    | NzDatePickerComponent
    | NzTimePickerComponent
    | TcCustomPickerBaseComponent;

  origin: CdkOverlayOrigin;
  overlayPositions: ConnectionPositionPair[];

  private _value: Date;
  public get value(): Date {
    return this._value;
  }
  public set value(date: Date) {
    this._value = isValidDate(date) ? date : undefined;
  }

  allowClear = true;

  valueChange$ = new Subject<unknown>();

  init(
    pickerComponent:
      | NzDatePickerComponent
      | NzTimePickerComponent
      | TcCustomPickerBaseComponent,
  ) {
    this.picker = pickerComponent;

    this.setOrigin();
    this.setPositions();
  }

  // ---------------
  // Events Handlers
  // ---------------

  abstract onModelChange(value: unknown);

  // ---------------
  // Private Methods
  // ---------------

  setOrigin() {
    this.picker.origin = this.origin;
  }

  setPositions() {
    this.picker.overlayPositions = this.overlayPositions;
  }
}
