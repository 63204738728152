import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TcFormsModule } from '@timecount/ui';

import { CollectionSelectComponent } from './collection-select/collection-select.component';
import { MessageTemplateSelectComponent } from './message-template-select/message-template-select.component';
import { OptionSelectComponent } from './option-select/option-select.component';
import { RemoteSelectComponent } from './remote-select/remote-select.component';
import { SelectComponent } from './select/select.component';

@NgModule({
  imports: [CommonModule, TcFormsModule],
  declarations: [
    CollectionSelectComponent,
    MessageTemplateSelectComponent,
    OptionSelectComponent,
    RemoteSelectComponent,
    SelectComponent,
  ],
  exports: [
    CollectionSelectComponent,
    MessageTemplateSelectComponent,
    OptionSelectComponent,
    RemoteSelectComponent,
    SelectComponent,
  ],
  providers: [],
})
export class TcSelectsModule {}
