import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';

import { parseDate } from '@timecount/utils';

import { dateToString, integerToDate } from '../../core/helpers';
import { DispoFocusService } from '../dispo-focus.service';

import { DispoTaskCollection, Task } from './task';
import { DispoScheduleCollection } from './schedule';

@Injectable({
  providedIn: 'root',
})
export class DispoCombinedTaskCollection {
  private tasks$;
  private tasksForDate$: { [key: string]: Observable<any[]> } = {};

  constructor(
    private taskCollection: DispoTaskCollection,
    private scheduleCollection: DispoScheduleCollection,
    private focusService: DispoFocusService,
  ) {}

  observeItem(item): Observable<Task> {
    const itemId = item.id;
    const scheduleId = item.schedule_id;
    const lookupDate = item.date;
    const shallow = item.shallow;

    if (scheduleId) {
      return combineLatest([
        this.scheduleCollection.filter(
          (s) => s.id === scheduleId,
          `id: ${scheduleId}`,
        ),
        this.taskCollection.filter(
          (s) => s.schedule_id === scheduleId && s.date === lookupDate,
          `id: ${scheduleId}; date: ${lookupDate}`,
        ),
      ]).pipe(
        map(([schedules, tasks]) => {
          const schedule = schedules[0];
          let task = tasks[0];

          if (!schedule) {
            return task;
          }

          if (task) {
            task = Object.assign({}, task);
            task.slots = schedule.slots_matrix[lookupDate] || [];
            task.slots_config = schedule.slots_config;
            return task;
          }

          task = this.taskCollection.fromSchedule(
            parseDate(lookupDate),
            schedule,
          );
          return task;
        }),
      );
    } else {
      return this.taskCollection.observeItem(item);
    }
  }

  observeItems(tasks: Task[]): Observable<Task[]> {
    if (tasks.length < 5) {
      return combineLatest(tasks.map((task) => this.observeItem(task))).pipe(
        map((tasks) => tasks.filter((task) => task)),
      );
    } else {
      const itemsMatchObj = tasks.map((task) =>
        task.shallow
          ? { shallow: true, schedule_id: task.schedule_id, date: task.date }
          : { id: task.id },
      );
      return this.all().pipe(
        map((tasks) => {
          return itemsMatchObj
            .map((matchObj) => {
              if (matchObj.shallow) {
                return tasks.find(
                  (task) =>
                    task.schedule_id === matchObj.schedule_id &&
                    task.date === matchObj.date,
                );
              } else {
                return tasks.find((task) => task.id === matchObj.id);
              }
            })
            .filter((task) => task);
        }),
      );
    }
  }

  forDate(date: Date): Observable<Task[]> {
    const lookupDate = dateToString(date);

    if (!this.tasksForDate$[lookupDate]) {
      this.tasksForDate$[lookupDate] = combineLatest(
        this.scheduleCollection.all(),
        this.taskCollection.forDate(date),
      ).pipe(
        map(([schedules, tasks]) => {
          const tasksAndShallowTasks = [];
          const scheduleTasksLookup = [];

          tasks.forEach((task) => {
            if (!task.schedule_id) {
              tasksAndShallowTasks.push(task);
            } else {
              scheduleTasksLookup[task.schedule_id] = task;
            }
          });

          schedules.forEach((schedule) => {
            let task = scheduleTasksLookup[schedule.id];
            const slots = schedule.slots_matrix[lookupDate];

            if (!slots) {
              return;
            }

            // generate a shallow task as slots are present
            if (!task) {
              tasksAndShallowTasks.push(
                this.taskCollection.fromSchedule(date, schedule),
              );
              return;
            }

            // extend/overwrite task with schedule config
            if (task) {
              task = Object.assign({}, task);
              task.slots = slots;
              task.slots_config = schedule.slots_config;

              tasksAndShallowTasks.push(task);
            }
          });

          return tasksAndShallowTasks;
        }),
        shareReplay(1),
      );
    }

    return this.tasksForDate$[lookupDate];
  }

  all(): Observable<Task[]> {
    if (!this.tasks$) {
      this.tasks$ = combineLatest(
        this.scheduleCollection.all(),
        this.taskCollection.all(),
        this.focusService.range(),
      ).pipe(
        debounceTime(100),
        map(([schedules, tasks, [begin, end]]) => {
          let currSlice = begin;
          const tasksAndShallowTasks = [];
          const scheduleTasksLookup = {};
          const unscheduledTasksLookup = {};

          tasks.forEach((task) => {
            if (!task.schedule_id) {
              if (!unscheduledTasksLookup[task.date]) {
                unscheduledTasksLookup[task.date] = [];
              }

              unscheduledTasksLookup[task.date].push(task);
            } else {
              if (!scheduleTasksLookup[task.schedule_id]) {
                scheduleTasksLookup[task.schedule_id] = {};
              }

              scheduleTasksLookup[task.schedule_id][task.date] = task;
            }
          });

          while (currSlice <= end) {
            const date = integerToDate(currSlice);
            const lookupDate = dateToString(date);

            const unscheduledTasks = unscheduledTasksLookup[lookupDate] || [];
            tasksAndShallowTasks.push(...unscheduledTasks);

            schedules.forEach((schedule) => {
              let task = (scheduleTasksLookup[schedule.id] || {})[lookupDate];
              const slots = schedule.slots_matrix[lookupDate];

              if (!slots) {
                return;
              }

              // generate a shallow task as slots are present
              if (!task) {
                tasksAndShallowTasks.push(
                  this.taskCollection.fromSchedule(date, schedule),
                );
                return;
              }

              // extend/overwrite task with schedule config
              if (task) {
                task = Object.assign({}, task);
                task.slots = slots;
                task.slots_config = schedule.slots_config;
                // task.size = schedule.template.size;
                // task.state = schedule.state;
                // task.requested_at = schedule.requested_at;
                // task.tracking_enabled = schedule.tracking_enabled;
                // task.push_notification = schedule.push_notifications;

                tasksAndShallowTasks.push(task);
              }
            });

            currSlice = currSlice + 60 * 60 * 24;
          }

          return tasksAndShallowTasks;
        }),
        shareReplay(1),
      );
    }

    return this.tasks$;
  }
}
