import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TcZorroAntdModule } from '../../shared/tc-zorro-antd.module';

import { DispoShiftGridModule } from './dispo-shift-grid/dispo-shift-grid.module';
import { BreaksListTableComponent } from './breaks-list-table/breaks-list-table.component';
import { DispoSlotLabelsComponent } from './dispo-slot-labels/dispo-slot-labels.component';

/**
 * Dispo shared stuff
 *
 * TODO: This is a work in progress. Dispo itself still needs to be moved to its
 * own module, but some shared components have already been moved here.
 */
@NgModule({
  imports: [
    CommonModule,
    DispoShiftGridModule,
    TranslateModule,
    TcZorroAntdModule,
  ],
  exports: [
    DispoShiftGridModule,
    BreaksListTableComponent,
    DispoSlotLabelsComponent,
  ],
  declarations: [BreaksListTableComponent, DispoSlotLabelsComponent],
})
export class DispoSharedModule {}
