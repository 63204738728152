import { ExclusionPolicy, Strategy, Type } from 'typeserializer';
import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

@Strategy(ExclusionPolicy.NONE)
export class Qualification {
  id: number;
  visibility: number;

  title: string;
  shorthand: string;

  included_qualification_ids: number[];

  @Type(Object)
  store: object;
}
@Injectable({
  providedIn: 'root',
})
export class QualificationCollection extends SetsCollection {
  identifier = 'preferences/qualification';
  endpoint = '/api/preferences/qualifications';
  type = Qualification;

  localSearch = ['title'];

  cache = 60 * 60 * 12;

  visible() {
    return this.query({ 'filter[visibility]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visibility]': '0' }, (e) => e.visibility === 0);
  }
}
