import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { PostsService } from '../posts.service';
import { Post } from '../post.model';

@Component({
  selector: 'tc-hub-posts-widget',
  templateUrl: './posts-widget.component.html',
  styleUrls: ['./posts-widget.component.scss'],
})
export class TcHubPostsWidgetComponent implements OnDestroy {
  posts: Post[];
  loading = true;

  private _destroyed$ = new Subject<void>();

  constructor(public postsService: PostsService) {
    this.postsService.getAll().subscribe((posts) => {
      this.posts = posts;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
