<td
  colspan="14"
  [nzExpand]="item.expanded"
  (nzExpandChange)="toggleItem(item.type, item.id)"
>
  <label
    nz-checkbox
    [(nzChecked)]="item.checked"
    [nzIndeterminate]="item.indeterminate"
  ></label>

  {{ 'aggregation_entry/service.orphaned' | translate }}
</td>
