import { Injectable } from '@angular/core';
import { ExclusionPolicy, Strategy } from 'typeserializer';

import { SetsCollection } from '../../core/sets-collection';

const DAY_IN_S = 3600 * 24;

export function stitchShifts(candidates) {
  return candidates
    .sort((a, b) => a.offset - b.offset)
    .reduce((shifts, current) => {
      const shift_before = shifts.find((s) => {
        return (
          s.offset + s.length === current.offset ||
          s.offset + s.length === current.offset + DAY_IN_S
        );
      });

      if (shift_before) {
        shift_before.title = [shift_before.title, current.title].join(', ');
        shift_before.length = shift_before.length + current.length;
      } else {
        shifts.push(Object.assign({}, current));
      }

      return shifts;
    }, []);
}

@Strategy(ExclusionPolicy.NONE)
export class DispoShift {
  id: number;

  title: string;
  color: string;

  offset: number;
  length: number;
}
@Injectable({
  providedIn: 'root',
})
export class DispoShiftCollection extends SetsCollection {
  type = DispoShift;
  identifier = 'tc_dispo/settings/shift';
  endpoint = '/api/dispo/settings/shifts';

  refreshOnStale = false;
  cache = 60 * 60 * 12;
}
