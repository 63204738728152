import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

@Injectable({
  providedIn: 'root',
})
export class AggregationTypeCollection extends SetsCollection {
  identifier = 'aggregation_type';
  endpoint = '/api/preferences/aggregations';

  cache = 60 * 60 * 12;

  localSearch = ['title'];
}
