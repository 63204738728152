import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { startOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DispoPlanCollection, Plan } from '../../collections/plan';
import { DispoScheduleCollection, Schedule } from '../../collections/schedule';

@Component({
  selector: 'tc-hub-ds-move',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss'],
})
export class DSMoveComponent implements OnInit, OnDestroy {
  @Input() schedule: Schedule;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  dateLimit: Interval;

  destroyed$ = new Subject<void>();

  constructor(
    private scheduleCollection: DispoScheduleCollection,
    private planCollection: DispoPlanCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.buildForm(this.schedule);
    this.signal.emit({ action: 'resize' });

    this.form
      .get('plan_id')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (plan_id) => {
          this.planCollection.get(plan_id).subscribe((plan: any) => {
            this.dateLimit = {
              start: plan.starts_at,
              end: plan.ends_at,
            };
          });
        },
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  validate() {
    // validate intermission in range
  }

  copy() {
    this.move(true);
  }

  move(copy = false) {
    this.loading = true;

    const { dateInterval, ...formValues } = this.form.value;

    const payload = {
      title: formValues.title,
      starts_at: dateInterval.start,
      ends_at: dateInterval.end,
      plan_id: formValues.plan_id,
    };

    const action = copy ? 'copy' : 'move';

    this.scheduleCollection
      .action(action, this.schedule.id, payload)
      .subscribe({
        next: (schedule) => {
          this.loading = false;
          this.signal.emit({ action: 'close' });
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  private buildForm(schedule) {
    const initialDateRangeValue = {
      start: startOfDay(schedule.starts_at),
      end: startOfDay(schedule.ends_at),
    };
    this.dateLimit = { start: this.plan.starts_at, end: this.plan.ends_at };

    return this.formBuilder.group({
      plan_id: [schedule.plan_id, [Validators.required]],
      dateInterval: [initialDateRangeValue],
      title: [schedule.title, [Validators.required]],
    });
  }
}
