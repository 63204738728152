import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { TcConfigService } from '@timecount/core';

import { ContractorCollection } from '../../../contractors/contractor.collection';
import { integerToDate } from '../../../core/helpers';
import { Assignment } from '../../../dispo/collections/assignment.model';
import { EmployeeCollection } from '../../../employees/employee.collection';

import { ResourceInfo } from './resource-info.model';

@Component({
  selector: 'tc-hub-da-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class DAInfoComponent implements OnInit {
  @Input() assignments: Assignment[];

  @Output() signal: EventEmitter<any> = new EventEmitter();

  items: ResourceInfo[] = [];

  allowOnsiteCheckin = this.configService.config.company.allowOnsiteCheckin;
  allowAppAttendance = this.configService.config.company.allowAppAttendance;

  constructor(
    private employeeService: EmployeeCollection,
    private contractorService: ContractorCollection,
    private configService: TcConfigService,
  ) {}

  ngOnInit() {
    combineLatest(
      this.assignments.map((a) => {
        const catchErrorObject = {
          type: a.resource_type,
          id: a.resource_id,
          name: '?',
          unknown: true,
        };

        return a.resource_type === 'Employee'
          ? this.employeeService
              .find(a.resource_id)
              .pipe(catchError((val) => of(catchErrorObject)))
          : this.contractorService
              .find(a.resource_id)
              .pipe(catchError((val) => of(catchErrorObject)));
      }),
    )
      .pipe(first())
      .subscribe((resources) => {
        this.items = this.assignments
          .map((a) => {
            const resource: any = resources.find(
              (r: any) => r.type === a.resource_type && r.id === a.resource_id,
            );

            return <ResourceInfo>{
              position: a.position + 1,
              date: integerToDate(a.marker[0]),
              token: resource?.token,
              name: resource?.name,
              marker: a.marker[0],
              type: a.resource_type,
              id: a.resource_id,
              unknown: resource?.unknown,
              responses: a.responses,
            };
          })
          .sort((a, b) => a.marker - b.marker || a.position - b.position);

        this.signal.emit({ action: 'resize' });
      });
  }

  open(item: ResourceInfo) {
    const url =
      item.type === 'Employee'
        ? `/#/employees/${item.id}/personal`
        : `/#/preferences/contractors/${item.id}/general`;

    window.open(url, '_blank');
  }

  cancel() {
    this.signal.emit({ action: 'close' });
  }
}
