import { Component, Input, OnInit } from '@angular/core';
import { NG_VALIDATORS, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { TcConfigService } from '@timecount/core';

import { ValueAccessorDirective } from '../../../shared/value-accessor.directive';
import { TcIntervalBaseDirective } from '../interval.directive';
import { TcInputService } from '../../../components/input/input.service';

@Component({
  selector: 'tc-interval-web',
  templateUrl: './interval-web.component.html',
  styleUrls: ['./interval-web.component.scss'],
  hostDirectives: [ValueAccessorDirective],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: TcIntervalWebComponent,
      multi: true,
    },
  ],
})
export class TcIntervalWebComponent
  extends TcIntervalBaseDirective
  implements OnInit
{
  @Input() tcHideLabels = false;
  @Input() tcIsCompact = false;
  @Input() tcIsHorizontal = false;
  showDateOptionsFields: { start: boolean; end: boolean };

  constructor(
    protected _formBuilder: UntypedFormBuilder,
    protected valueAccessor: ValueAccessorDirective<Interval>,
    protected translateService: TranslateService,
    protected inputService: TcInputService,
    protected configService: TcConfigService,
  ) {
    super(
      _formBuilder,
      valueAccessor,
      translateService,
      inputService,
      configService,
    );
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit(): void {
    super.ngOnInit();

    this.showDateOptionsFields = {
      start: this._fieldCanHaveDateOptions('start'),
      end: this._fieldCanHaveDateOptions('end'),
    };
  }

  // ---------------
  // Private Methods
  // ---------------

  private _fieldCanHaveDateOptions(fieldName: 'start' | 'end'): boolean {
    return (
      this.allowDoubledWorkingDay &&
      (this.tcFieldWithDateOptions === fieldName ||
        this.tcFieldWithDateOptions === 'both')
    );
  }
}
