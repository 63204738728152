import { PanelMenuSub } from 'primeng/panelmenu';
import { Component } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'tc-hub-panel-menu-sub',
  templateUrl: './panelmenu-sub.component.html',
  styleUrls: ['./panelmenu-sub.component.scss'],
  animations: [
    trigger('submenu', [
      state(
        'hidden',
        style({
          height: '0px',
        }),
      ),
      state(
        'void',
        style({
          height: '{{height}}',
        }),
        { params: { height: '0' } },
      ),
      state(
        'visible',
        style({
          height: '*',
        }),
      ),
      transition('visible => hidden', animate('{{transitionParams}}')),
      transition('hidden => visible', animate('{{transitionParams}}')),
      transition('void => hidden', animate('{{transitionParams}}')),
      transition('void => visible', animate('{{transitionParams}}')),
    ]),
  ],
})
export class PanelMenuSubComponent extends PanelMenuSub {}
