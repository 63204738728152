import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import DragSelect from 'dragselect';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { TcFieldSetIntervalSetService } from '@timecount/ui';
import {
  feedbackRequestsOnStatus,
  getFeedbackRequestWithHighestPriority,
  TcConfigService,
  TcIntervalSet,
  TcIntervalWithBreak,
} from '@timecount/core';

import { LocalSettingsService } from '../../../core/local-settings.service';
import { DispoTaskCollection, SlotPos } from '../../collections/task';
import { DispoLoaderService } from '../../loader/loader.service';
import { TaskService } from '../../actions/task.service';
import { debug } from '../../../core/helpers';
import {
  DispoTasksDayService,
  DispoTasksServiceFactory,
} from '../dispo-tasks.service';

// REFACTOR: same as dispo-schedule/schedule.component
const isDroppable = (e) => {
  const dragType = e.dataTransfer.getData('drag');

  if (
    dragType === 'timecount/dispo_assignments' ||
    dragType === 'timecount/resources'
  ) {
    return true;
  }

  return false;
};

// TODO: Draw Drag Image
const drawDragImage = (elementRef, e) => {
  // /* demo implementation, leads to flicker */
  // const wrapper = elementRef.nativeElement.querySelector('.wrapper').cloneNode(true);
  // const slots = wrapper.querySelectorAll('app-dispo-slot');
  // slots.forEach(slot => {
  //   const input = slot.querySelector('input[type="checkbox"]');
  //   if (input && input.checked) { return; }
  //   slot.style.display = 'none';
  //   setTimeout(_ => slot.style.display = '' , 1);
  // });
  // elementRef.nativeElement.appendChild(wrapper);
  // setTimeout(_ => elementRef.nativeElement.removeChild(wrapper) , 1);
  // e.dataTransfer.setDragImage(wrapper, 0, 0);
};

@Component({
  selector: 'tc-hub-dt-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DTTaskComponent implements OnInit, OnChanges, OnDestroy {
  @Input() date: Date;
  @Input() task: any;
  @Input() expanded$: Subject<boolean> = new Subject();

  @Output() select: EventEmitter<any> = new EventEmitter(); // eslint-disable-line

  service: DispoTasksDayService;
  slots$: Observable<any[]>;
  task$: Observable<any>;
  subs = [];
  assignments = [];

  updateSelectables$: Subject<any> = new Subject();
  selectable: DragSelect;
  active = false;
  loading = false;
  visible = false;

  timesList: Interval[];
  private allowMultiBreaks =
    this.configService.config.company.times.allowMultiBreaks;

  allowOnsiteCheckin = this.configService.config.company.allowOnsiteCheckin;
  allowAppAttendance = this.configService.config.company.allowAppAttendance;

  constructor(
    private factory: DispoTasksServiceFactory,
    private elementRef: ElementRef,
    private localSettings: LocalSettingsService,
    private taskCollection: DispoTaskCollection,
    private loadingService: DispoLoaderService,
    private intervalSetService: TcFieldSetIntervalSetService,
    private configService: TcConfigService,
    private taskActionService: TaskService,
  ) {
    this.onDragStart = this.onDragStart.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  ngOnChanges({ task }: SimpleChanges): void {
    if (task) {
      const parsedTimes = this.intervalSetService.parseFromApi(this.task);
      this.timesList = this.allowMultiBreaks
        ? (parsedTimes as Interval[])
        : (TcIntervalSet.timeSetToIntervalList(
            parsedTimes as TcIntervalWithBreak,
          ) as Interval[]);
    }
  }

  ngOnInit() {
    console.log('init task');
    this.service = this.factory.forDate(this.date);
    this.slots$ = this.service.taskSlots(this.task).pipe(
      map((slots) =>
        slots.map((slot) =>
          slot.tracked
            ? {
                ...slot,
                times: this.allowMultiBreaks
                  ? this.intervalSetService.parseFromApi(slot)
                  : TcIntervalSet.timeSetToIntervalList(
                      this.intervalSetService.parseFromApi(
                        slot,
                      ) as TcIntervalWithBreak,
                    ),
              }
            : slot,
        ),
      ),
    );

    this.task$ = this.service.taskDS(this.task);

    this.expanded$ = this.localSettings.get(
      `dispo.taskExpanded.${this.task.id}`,
      true,
    );
  }

  // REFACTOR: same as dispo-schedules/schedule.component#onDestroy
  ngOnDestroy() {
    if (this.selectable) {
      this.selectable.stop();
    }
    this.elementRef.nativeElement.removeEventListener(
      'dragstart',
      this.onDragStart,
    );
    this.elementRef.nativeElement.removeEventListener('drop', this.onDrop);
    this.elementRef.nativeElement.removeEventListener(
      'dragend',
      this.onDragEnd,
    );
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // REFACTOR: similar to dispo-schedules/schedule.component#contextmenu
  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    $event.menu_contexts = [];

    const slotTarget = $event
      .composedPath()
      .find((item) => item.classList && item.classList.contains('selectable'));

    if (
      $event.type === 'contextmenu' &&
      (this.selectable.getSelection().length === 0 ||
        this.selectable.getSelection().indexOf(slotTarget) === -1)
    ) {
      if (slotTarget) {
        this.selectable.setSelection([slotTarget], true);
      } else {
        this.selectable.setSelection([], true);
      }

      this.active = true;
      this.select.emit('started');
      this.active = false;
    }

    const slots = this.selectable.getSelection().map((el) => {
      return Object.assign(new SlotPos(), {
        x: parseInt(el.dataset.hslot, 10),
        y: parseInt(el.dataset.vslot, 10),
        task_id: this.task.id,
        schedule_id: this.task.schedule_id,
      });
    });

    const assignments = slots
      .map((slot) => {
        return this.assignments.find((a) => a.position === slot.y);
      })
      .filter((a) => a);

    if (assignments.length > 0) {
      $event.menu_contexts.push({
        item_type: 'DispoAssignment',
        item: assignments,
      });
    }

    if (slots.length > 0) {
      $event.menu_contexts.push({
        item_type: 'DispoSlot',
        item: slots,
      });
    }

    $event.menu_contexts.push({
      item_type: 'DispoTask',
      item: [this.task],
    });
  }

  requestWithHighestPriority(feedbackRequests) {
    return getFeedbackRequestWithHighestPriority([
      ...feedbackRequestsOnStatus(feedbackRequests, ['responded', 'expired']),
      ...feedbackRequestsOnStatus(feedbackRequests, 'pending', 'read'),
    ]);
  }

  onVisible() {
    if (this.visible) {
      return;
    }
    this.visible = true;

    this.subs.push(
      this.service.taskAssignments(this.task).subscribe({
        next: (assignments) => {
          this.assignments = assignments;
        },
      }),
    );

    setTimeout(() => {
      this.init();
    }, 1000);
  }

  init() {
    this.elementRef.nativeElement.addEventListener(
      'dragstart',
      this.onDragStart,
    );
    this.elementRef.nativeElement.addEventListener('drop', this.onDrop);
    this.elementRef.nativeElement.addEventListener('dragend', this.onDragEnd);

    this.selectable = new DragSelect({
      selectables: [],
      area: this.elementRef.nativeElement.querySelector('.select-root'),
      selectedClass: 'selected',
      onDragStartBegin: function (event) {
        const nodes = this.getSelection();
        const target = event
          .composedPath()
          .find((item) => item.getAttribute && item.getAttribute('drag'));
        if (!target) {
          return;
        }

        if (nodes.indexOf(target) > -1) {
          this.break();
          target.setAttribute('draggable', true);
        } else {
          target.setAttribute('draggable', false);
        }
      },
      onDragStart: (_event) => {
        this.active = true;
        this.select.emit('started');
      },
      callback: (slots) => {
        this.onSelect(slots);
        this.active = false;
      },
    });

    this.subs.push(
      this.updateSelectables$.pipe(debounceTime(300)).subscribe(() => {
        // if (this.loading) { return; }
        const elements = [].slice.call(
          this.elementRef.nativeElement.querySelectorAll('.selectable'),
        );
        const selectables = this.selectable.selectables;
        const addToSelectables = elements.filter(
          (e) => selectables.indexOf(e) === -1,
        );
        const removeFromSelectables = selectables.filter(
          (s) => elements.indexOf(s) === -1,
        );

        this.selectable.removeSelectables(removeFromSelectables);
        this.selectable.addSelectables(addToSelectables);
      }),
    );

    this.subs.push(this.slots$.subscribe((s) => this.updateSelectables()));

    this.subs.push(
      this.loadingService.loading('task', this.task.id).subscribe((loading) => {
        this.loading = loading;
      }),
    );

    this.updateSelectables();
  }

  // REFACTOR: same as dispo-schedules/schedule.component#clearSelection
  clearSelection() {
    if (!this.active && this.selectable) {
      this.selectable.clearSelection();
    }
  }

  availableWidgetActions = (action: string) => {
    const availableActions = {
      enableTimeTracking: () => {
        this.taskActionService.enableTimeTracking([this.task]);
      },
      disableTimeTracking: () => {
        this.taskActionService.disableTimeTracking([this.task]);
      },
      setHidden: () => {
        this.taskActionService.setHidden([this.task]);
      },
      setPublic: () => {
        this.taskActionService.setPublic([this.task]);
      },
      enableOnsiteCheckin: () => {
        this.taskActionService.enableOnsiteCheckin([this.task]);
      },
      disableOnsiteCheckin: () => {
        this.taskActionService.disableOnsiteCheckin([this.task]);
      },
      enableAttendance: () => {
        this.taskActionService.enableAttendance([this.task]);
      },
      disableAttendance: () => {
        this.taskActionService.disableAttendance([this.task]);
      },
      requestConfirmation: () => {
        this.taskActionService.requestConfirmation([this.task]);
      },
      edit: () => {
        this.taskActionService.general(this.task);
      },
      move: () => {
        this.task.schedule_id
          ? this.taskActionService.moveScheduled([this.task])
          : this.taskActionService.move(this.task);
      },
      times: () => {
        this.taskActionService.times(this.task);
      },
    };

    if (availableActions[action]) {
      availableActions[action]();
    } else {
      debug(
        action,
        'not implemented available actions are:',
        Object.keys(availableActions),
      );
    }
  };

  // REFACTOR: same as dispo-schedules/schedule.component#updateSelectables
  private updateSelectables() {
    this.updateSelectables$.next(true);
  }

  // REFACTOR: same as dispo-schedules/schedule.component#onSelect
  private onSelect(elements) {
    const slots = elements.map((el) => {
      return Object.assign(new SlotPos(), {
        x: parseInt(el.dataset.hslot, 10),
        y: parseInt(el.dataset.vslot, 10),
      });
    });

    this.service.handleSelect(this.task, slots);
  }

  onMarkdownUpdate(markdown) {
    this.task.note = markdown;
    this.taskCollection.update(this.task.id, this.task);
  }

  onToggleTask(event) {
    this.localSettings.toggle(`dispo.taskExpanded.${this.task.id}`, true);
  }

  // REFACTOR: similar to dispo-schedules/schedule.component#onDragStart
  private onDragStart(e) {
    const dragType = e.target.getAttribute('drag');

    if (dragType === 'timecount/dispo_assignments') {
      const slots = [];

      this.selectable.getSelection().map((el) => {
        slots.push({
          x: parseInt(el.dataset.hslot, 10),
          y: parseInt(el.dataset.vslot, 10),
        });
      });

      const task = Object.assign({}, this.task);
      const action = e.metaKey || e.ctrlKey || e.altKey ? 'create' : 'update';

      e.dataTransfer.effectAllowed = 'all';
      e.dataTransfer.setData('drag', dragType);
      e.dataTransfer.setData(
        dragType,
        JSON.stringify({
          task: task,
          slots: slots,
          x: parseInt(e.srcElement.dataset.hslot, 10),
          y: parseInt(e.srcElement.dataset.vslot, 10),
          action: action,
        }),
      );
    }
  }

  // REFACTOR: similar to dispo-schedules/schedule.component#onDrop
  private onDrop(e) {
    if (isDroppable(e)) {
      e.preventDefault();

      if (e.stopPropagation) {
        e.stopPropagation();
      }

      const type = e.dataTransfer.getData('drag');
      let data = e.dataTransfer.getData(type);
      if (data) {
        data = JSON.parse(data);
      } else {
        return false;
      }

      const slot = e
        .composedPath()
        .find(
          (node) => node.dataset && node.dataset.hslot && node.dataset.vslot,
        );
      const toPosition = slot
        ? {
            x: parseInt(slot.dataset.hslot, 10),
            y: parseInt(slot.dataset.vslot, 10),
          }
        : { x: this.hslot(), y: null };

      const selectedSlots = this.selectable.getSelection().map((el) => {
        return {
          x: parseInt(el.dataset.hslot, 10),
          y: parseInt(el.dataset.vslot, 10),
        };
      });

      this.service.handleInsert(
        type,
        data,
        this.task,
        toPosition,
        selectedSlots,
      );

      this.active = false;
      this.clearSelection();
      this.updateSelectables();

      return true;
    }
  }

  // REFACTOR: same as dispo-schedules/schedule.component#onDragEnd
  private onDragEnd(e) {
    e.preventDefault();
  }

  private hslot() {
    return this.task && this.task.marker ? this.task.marker[0] : 0;
  }

  private trackByPos(index, item) {
    return item.position;
  }
}
