<tc-button-ionic
  size="small"
  class="button-toggle"
  [expand]="expand"
  [tcCompact]="tcCompact"
  [color]="color"
  [fill]="value ? 'solid' : 'outline'"
  [disabled]="isDisabled"
  (click)="onClick($event)"
  (blur)="onTouched()"
>
  {{ label }}
</tc-button-ionic>
