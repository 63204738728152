import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tc-hub-dispo-filter-sort',
  templateUrl: './filter-sort.component.html',
  styleUrls: ['./filter-sort.component.css'],
})
export class DispoFilterSortComponent {
  @Input() section: string;
  @Input() sorts = [];

  @Output() requestSort: EventEmitter<any> = new EventEmitter();

  setSort(sort) {
    this.requestSort.emit(sort);
  }
}
