<nz-table
  #nzTable
  nzBordered
  [nzData]="variables | keyvalue | keyValueFilter : searchVariable"
  [nzSize]="'small'"
  [nzScroll]="{ y: '516px' }"
  [nzShowPagination]="false"
  [nzFrontPagination]="false"
  nzTableLayout="fixed"
>
  <thead>
    <tr>
      <th colspan="2">
        <nz-input-group
          [nzSuffix]="variableClearTmpl"
        >
          <input
            nz-input
            placeholder="{{ 'search' | translate }}"
            [(ngModel)]="searchVariable"
          />
        </nz-input-group>
        <ng-template #variableClearTmpl>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchVariable"
            (click)="searchVariable = ''"
          ></span>
        </ng-template>
      </th>
    </tr>
    <tr>
      <th>{{ 'formulas.variables.name' | translate }}</th>
      <th>{{ 'formulas.variables.value' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let data of nzTable.data">
      <tr>
        <td>{{ data.key }}</td>
        <td
          *ngIf="
            data.value && (data.value | typeof) === 'object';
            else variableValue
          "
          class="expandable-cell"
          [nzExpand]="expandedValues.has(data.key)"
          (nzExpandChange)="onExpandChange(data.key, $event)"
        >
          {{
            (expandedValues.has(data.key) ? 'collapse' : 'expand') | translate
          }}
        </td>
      </tr>

      <tr [nzExpand]="expandedValues.has(data.key)">
        <td colspan="2">
          <pre>{{ data.value | json }}</pre>
        </td>
      </tr>
      <ng-template #variableValue>
        <td>
          {{ data.value }}
        </td>
      </ng-template>
    </ng-container>
  </tbody>
</nz-table>
