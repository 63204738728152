import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { add, sub } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { setTimeToDate } from '@timecount/utils';

import { DispoPlanCollection, Plan } from '../../collections/plan';
import { ProjectCollection } from '../../../projects/project.collection';

@Component({
  selector: 'tc-hub-dp-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class DPGeneralComponent implements OnInit, OnDestroy {
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  dateLimit: Interval;

  destroyed$ = new Subject<void>();

  readonly overlapDays = window.config.company.dispo_project_overlap_days;

  constructor(
    private planService: DispoPlanCollection,
    private formBuilder: UntypedFormBuilder,
    private projectCollection: ProjectCollection,
  ) {}

  ngOnInit() {
    this.buildForm(this.plan);
    this.signal.emit({ action: 'resize' });

    this.form
      .get('project_id')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (project_id) => {
          this.setPlanValues(project_id);
        },
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save() {
    const { dateInterval, ...formValues } = this.form.value;

    const newValues = {
      ...this.plan,
      ...formValues,
      [`starts_at`]: dateInterval.start,
      [`ends_at`]: dateInterval.end,
    };

    newValues.store = Object.assign({}, this.plan.store, formValues.store);

    this.loading = true;

    this.planService.update(this.plan.id, newValues).subscribe({
      next: (plan: Plan) => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
        // this.buildForm(plan);
        // this.signal.emit({ action: 'reload', items: { plan: plan } });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(plan) {
    // HACK: While the date helpers and the forms group service can't handle
    // well date only (without time) comparisons, we are setting the dates here
    // to midnoght to make sure the validators will be compare them correctly.
    const todayAtMidnight = new Date().setHours(0, 0, 0, 0);

    const initialDateIntervalValue = {
      start: setTimeToDate(plan[`starts_at`], todayAtMidnight),
      end: setTimeToDate(plan[`ends_at`], todayAtMidnight),
    };

    this.setPlanValues(this.plan.project_id, false);

    this.form = this.formBuilder.group({
      title: [this.plan.title, [Validators.required]],
      project_id: [this.plan.project_id, [Validators.required]],
      dateInterval: [initialDateIntervalValue, [Validators.required]],
      color: [this.plan.color || '#000000'],
      note: [this.plan.note],
      store: [this.plan.store],
    });
  }

  private setPlanValues(project_id: number, updateTitle = true) {
    this.projectCollection.get(project_id).subscribe((project: any) => {
      this.dateLimit = {
        start: sub(project.starts_at, { days: this.overlapDays }),
        end: add(project.ends_at, { days: this.overlapDays }),
      };
      if (updateTitle) {
        this.form.get('title').setValue(project.title);
      }
    });
  }
}
