import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { dateToTzUnix } from '@timecount/utils';

import { LocalSettingsService } from '../core/local-settings.service';

@Injectable({
  providedIn: 'root',
})
export class DispoFocusService {
  private dates$: ReplaySubject<Date[]> = new ReplaySubject(1);
  private weeks$: ReplaySubject<Date[][]> = new ReplaySubject(1);
  private begin$: ReplaySubject<Date> = new ReplaySubject(1);
  private end$: ReplaySubject<Date> = new ReplaySubject(1);

  constructor(private localSettings: LocalSettingsService) {
    this.dateAndLength()
      .pipe(debounceTime(10))
      .subscribe({
        next: ([date, range]) => {
          const rangeBegin = date.getTime();
          const begin = new Date(rangeBegin);
          begin.setHours(0, 0, 0, 0);
          this.begin$.next(begin);

          const rangeEnd =
            begin.getTime() + (range + 0.5) * 60 * 60 * 24 * 1000;
          const end = new Date(rangeEnd);
          end.setHours(23, 59, 59);
          this.end$.next(end);

          const visibleDates = [];
          let currentDay = new Date(begin.getTime());
          while (currentDay <= end) {
            visibleDates.push(currentDay);
            currentDay = new Date(currentDay.getTime());
            currentDay.setDate(currentDay.getDate() + 1);
          }
          this.dates$.next(visibleDates);

          const visibleWeeks = [];
          let currentWeek = [];

          visibleDates.forEach((day, i) => {
            currentWeek.push(day);
            if (day.getDay() === 0 || i === visibleDates.length - 1) {
              visibleWeeks.push(currentWeek);
              currentWeek = [];
            }
          });

          this.weeks$.next(visibleWeeks);
        },
      });
  }

  length(): Observable<any> {
    return this.localSettings.get('dispo.focusLength');
  }

  setLength(length: any): void {
    this.localSettings.set('dispo.focusLength', length);
  }

  date(): Observable<Date> {
    return this.localSettings.get('dispo.focusDate').pipe(
      map((item) => {
        if (item.getTime) {
          return item;
        } else {
          item = parseInt(item, 10);
          return new Date(item);
        }
      }),
    );
  }

  setDate(date: Date): void {
    this.localSettings.set('dispo.focusDate', date.getTime(), true);
  }

  begin(): Observable<Date> {
    return this.begin$;
  }

  end(): Observable<Date> {
    return this.end$;
  }

  range(): Observable<[number, number]> {
    return combineLatest(this.begin$, this.end$).pipe(
      map(([begin, end]) => {
        return [dateToTzUnix(begin), dateToTzUnix(end)];
      }),
    );
  }

  dates(): Observable<Date[]> {
    return this.dates$;
  }

  weeks(): Observable<Date[][]> {
    return this.weeks$;
  }

  private dateAndLength(): Observable<any> {
    return combineLatest([this.date(), this.length()]);
  }
}
