<td
  colspan="5"
  [nzExpand]="item.expanded"
  (nzExpandChange)="toggleItem(item.type, item.id)"
>
  <label
    nz-checkbox
    [(nzChecked)]="item.checked"
    [nzIndeterminate]="item.indeterminate"
  ></label>
  {{ item.title }}

  <div class="data-panel">
    <tc-hub-data-panel
      [data]="item"
      section="finance.entries.project.title"
    ></tc-hub-data-panel>
  </div>
</td>
<td [colSpan]="zoneGroupNumber"></td>
<td colspan="3"></td>
<td>
  {{ item.starts_at | date : 'd.MM.' }} -
  {{ item.ends_at | date : 'd.MM.' }}
</td>
<td colspan="5"></td>
<td>
  <tc-hub-data-panel
    [data]="item"
    section="finance.entries.project.status"
  ></tc-hub-data-panel>
</td>
