import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'tc-hub-department-info',
  templateUrl: './department-info.component.html',
  styleUrls: ['./department-info.component.scss'],
})
export class TcDepartmentInfoComponent implements ControlValueAccessor {
  @Input() icon: string;
  @Input() color: string;
  @Input() title: string;

  public value = false;
  public isDisabled = false;

  private _onChange: (_: unknown) => void;
  private _onTouched: () => void;

  constructor(public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  // -----------------------
  // Abstract Implementation
  // -----------------------

  writeValue(obj: boolean): void {
    this.value = obj;
  }

  registerOnChange(fn): void {
    this._onChange = fn;
  }

  registerOnTouched(fn): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // ---------------
  // Events Handlers
  // ---------------

  onClick(): void {
    if (!this.isDisabled) {
      this.value = !this.value;
      this._onChange(this.value);
    }
  }
}
