<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <nz-table
      [nzData]="slots.controls"
      nzFrontPagination="false"
      nzSize="small"
    >
      <thead>
        <tr>
          <th>{{ 'dispo/schedule.attrs.slot' | translate }}</th>
          <th>{{ 'dispo/schedule.attrs.gratis' | translate }}</th>
          <th>{{ 'dispo/schedule.attrs.job' | translate }}</th>
          <th>{{ 'dispo/schedule.attrs.group' | translate }}</th>
          <th>{{ 'dispo/schedule.attrs.roles' | translate }}</th>
          <th>{{ 'dispo/schedule.attrs.qualifications' | translate }}</th>
          <th>
            <tc-hub-custom-fields
              class="tc-form-table"
              section="schedule.slot"
              [formGroup]="form"
              formControlName="store"
              asHeader="true"
            ></tc-hub-custom-fields>
          </th>
        </tr>
      </thead>
      <tbody class="table-multi-actions">
        <tr class="input-fields">
          <td>{{ 'apply_to_all' | translate }}</td>
          <td nzAlign="center">
            <label [formGroup]="form" nz-checkbox formControlName="gratis"></label>

            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="set('gratis', form.get('gratis').value)"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
              {{ 'set_all' | translate }}
            </div>
          </td>
          <td></td>
          <td class="input-field">
            <tc-hub-collection-select
              [formGroup]="form"
              [collectionTypes]="['DispoGroup']"
              controlName="group_id"
              bindLabel="title"
              bindValue="id"
              searchable="true"
            >
            </tc-hub-collection-select>
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="set('group_id', form.get('group_id').value)"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
              {{ 'set_all' | translate }}
            </div>
          </td>
          <td class="input-field">
            <tc-hub-collection-select
              [formGroup]="form"
              [collectionTypes]="['DispoRole']"
              controlName="role_ids"
              bindLabel="title"
              bindValue="id"
              multiple="true"
              searchable="true"
            >
            </tc-hub-collection-select>
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="set('role_ids', form.get('role_ids').value)"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
              {{ 'set_all' | translate }}
            </div>
          </td>
          <td class="input-field">
            <tc-hub-collection-select
              [formGroup]="form"
              [collectionTypes]="['Qualification']"
              controlName="qualification_ids"
              bindLabel="title"
              bindValue="id"
              searchable="true"
              multiple="true"
            >
            </tc-hub-collection-select>
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="
                set('qualification_ids', form.get('qualification_ids').value)
              "
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
              {{ 'set_all' | translate }}
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
      <tbody formArrayName="slots">
        <tr
          class="input-fields"
          *ngFor="let slot of slots.controls; index as i"
          formGroupName="{{ i }}"
        >
          <td class="input-field">
            <div class="form-group">
              <strong>{{ slot.controls.position.value + 1 }}</strong>
            </div>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="gratis"></label>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-collection-select
                [formGroup]="slot"
                [collectionTypes]="['Job']"
                controlName="job_id"
                bindLabel="title"
                bindValue="id"
                searchable="true"
              >
              </tc-hub-collection-select>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-collection-select
                [formGroup]="slot"
                [collectionTypes]="['DispoGroup']"
                controlName="group_id"
                bindLabel="title"
                bindValue="id"
                searchable="true"
              >
              </tc-hub-collection-select>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-collection-select
                [formGroup]="slot"
                [collectionTypes]="['DispoRole']"
                controlName="role_ids"
                bindLabel="title"
                bindValue="id"
                multiple="true"
                searchable="true"
              >
              </tc-hub-collection-select>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-collection-select
                [formGroup]="slot"
                [collectionTypes]="['Qualification']"
                controlName="qualification_ids"
                bindLabel="title"
                bindValue="id"
                searchable="true"
                multiple="true"
              >
              </tc-hub-collection-select>
            </div>
          </td>
          <td class="input-field">
            <tc-hub-custom-fields
              class="tc-form-table"
              section="schedule.slot"
              [formGroup]="slot"
              formControlName="store"
              asRow="true"
            ></tc-hub-custom-fields>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'dispo/schedule.actions.sync_save' | translate }}
    </button>
  </div>
</form>
