import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';

import { TcConfigService } from '@timecount/core';

import { TcInputDateTimeConfig } from '../../components/input/input-datetime.config';
import { TcInputService } from '../../components/input/input.service';

import { TcFormGroupIntervalComponentConfig } from './form-group-interval.config';
import { TcFormGroupIntervalValidators } from './form-group-interval.validators';

@Injectable({
  providedIn: 'root',
})
export class TcFormGroupIntervalService {
  readonly maxDateIntervalDuration: Duration = {
    days: 365,
  };

  private _timesConfig = this.configService.config.company.times;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private inputService: TcInputService,
    private configService: TcConfigService,
  ) {}

  getIntervalFormGroup(
    config: TcFormGroupIntervalComponentConfig = {
      fieldType: 'date',
    },
    groupValidators: ValidatorFn[] = [],
    controlValidators: ValidatorFn[] = [],
  ): UntypedFormGroup {
    const formGroupConfig = {};

    // eslint-disable-next-line  prefer-const
    const { initialValue, ...restOfConfig } = config;
    const value = initialValue ?? { start: undefined, end: undefined };

    Object.keys(value).forEach((key) => {
      const formControlConfig = restOfConfig as TcInputDateTimeConfig;
      formControlConfig.initialValue = value[key] as Date;

      formGroupConfig[key] = this.inputService.getDatetimeFormControl(
        formControlConfig,
        controlValidators,
      );
    });

    const formGroup = this._formBuilder.group(formGroupConfig);

    // Save config in the form group in order to be used later if necessary
    formGroup.addControl('config', new UntypedFormControl(config));

    if (!config.noValidators) {
      formGroup.setValidators(this.getValidators(config, groupValidators));
    }

    return formGroup;
  }

  /**
   * Gets an interval default validation, based on the passed config.
   *
   * Accepts additional validators to be returned along with the default
   */
  getValidators(
    config: TcFormGroupIntervalComponentConfig,
    additionalValidators: ValidatorFn[] = [],
  ): ValidatorFn[] {
    // TODO Implement a max duration condition for time intervals
    if (config.fieldType === 'date') {
      config.maxDuration ??= this.maxDateIntervalDuration;
    }

    return [
      TcFormGroupIntervalValidators.interval(config),
      ...additionalValidators,
    ];
  }
}
