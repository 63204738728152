import { Injectable } from '@angular/core';

import { DispoTaskCollection, Slot, SlotPos, Task } from '../collections/task';
import { DispoAssignmentCollection } from '../collections/assignment';
import { debug, integerToDate } from '../../core/helpers';
import { DispoScheduleCollection, Schedule } from '../collections/schedule';
import { DispoPlanCollection, Plan } from '../collections/plan';
import { Announcement } from '../collections/announcement';
import { Assignment } from '../collections/assignment.model';

const unique = (objectArray: any[], key) => {
  let values = objectArray.map((o) => o[key]);

  values = values.filter((v) => typeof v !== 'undefined' || v === null);

  if (Array.isArray(values[0])) {
    values = values
      .flat()
      .filter((v) => typeof v !== 'undefined' || v === null);
  }

  return [...new Set(values)];
};

export interface Range {
  starts_at: number;
  ends_at: number;
  valid: boolean;
}

export interface Selection {
  task_ids?: number[];

  job_ids?: number[];
  venue_ids?: number[];
  schedule_ids?: number[];
  plan_ids?: number[];
  project_ids?: number[];
  customer_ids?: number[];
  department_ids?: number[];
  assignment_ids?: number[];
  employee_ids?: number[];
  ranges?: Range[];
  slots?: {
    positions?: number[];
    job_ids?: number[];
    qualification_ids?: number[];
    role_ids?: number[];
    group_ids?: number[];
  };
  positions?: SlotPos[];
  assignments?: Assignment[];
  employees?: any[];
}

@Injectable({
  providedIn: 'root',
})
export class DispoSelectorService {
  private selection = {};

  private assignments = [];
  private tasks = [];
  private schedules = [];
  private plans = [];

  constructor(
    private assignmentCollection: DispoAssignmentCollection,
    private planCollection: DispoPlanCollection,
    private scheduleCollection: DispoScheduleCollection,
    private taskCollection: DispoTaskCollection,
  ) {
    this.assignmentCollection.all().subscribe({
      next: (assignments) => (this.assignments = assignments),
    });

    this.taskCollection.all().subscribe({
      next: (tasks) => (this.tasks = tasks),
    });

    this.scheduleCollection.all().subscribe({
      next: (schedules) => (this.schedules = schedules),
    });

    this.planCollection.all().subscribe({
      next: (plans) => (this.plans = plans),
    });
  }

  // setLocal(item_type, item) {
  //   this.setSelection('local.' + item_type, item);
  // }

  setSlots(slotPositions: SlotPos[]) {
    const slots: Slot[] = [];
    const ranges: { starts_at: number; ends_at: number; valid: boolean }[] = [];

    const assignments: Assignment[] = [];
    const plans = [];
    const schedules = [];
    const tasks = [];

    slotPositions.forEach((position) => {
      const task =
        this.tasks.find((s) => s.id === position.task_id) || undefined;
      const schedule =
        this.schedules.find((s) => s.id === position.schedule_id) || undefined;
      const plan =
        this.plans.find(
          (p) => p.id === (task ? task.plan_id : schedule.plan_id),
        ) || {};

      let range;
      let slot;

      if (task) {
        range = {
          starts_at: task.starts_at.getTime(),
          ends_at: task.ends_at.getTime(),
          valid: true,
        };

        assignments.push(
          ...this.assignments
            .filter((a) => {
              return a.task_id === task.id && a.position === position.y;
            })
            .map((assignment) => Object.assign({}, assignment)),
        );

        // TODO: investigate why slotsconfig becomes undefined
        slot = ((schedule ? schedule : task).slots_config || []).find(
          (s) => s.position === position.y,
        );
      } else if (schedule) {
        const timestamp = integerToDate(position.x).setHours(0, 0, 0, 0);

        range = {
          starts_at: timestamp + schedule.template.offset * 1000,
          ends_at:
            timestamp +
            schedule.template.offset * 1000 +
            schedule.template.length * 1000,
          valid: true,
        };

        slot = schedule.slots_config.find((s) => s.position === position.y);
      } else {
        return;
      }

      if (
        !ranges.find(
          (r) => r.starts_at === range.starts_at && r.ends_at === range.ends_at,
        )
      ) {
        ranges.push(range);
      }

      if (slot) {
        slots.push(slot);
      } else {
        slot = new Slot();
        slots.push(
          Object.assign(slot, {
            position: position.y,
          }),
        );
      }

      if (task) {
        tasks.push(task);
      }

      if (schedule) {
        schedules.push(schedule);
      }

      plans.push(plan);
    });

    const task_ids = unique(tasks, 'id');
    const job_ids = unique(tasks, 'job_id');
    const venue_ids = unique(tasks, 'venue_id');
    const schedule_ids = unique(schedules, 'id');
    const plan_ids = unique(plans, 'id');
    const project_ids = unique(plans, 'project_id');
    const customer_ids = unique(plans, 'customer_id');
    const department_ids = unique(plans, 'department_id');
    const assignment_ids = unique(assignments, 'id');

    const positions = unique(slots, 'position');
    const slot_job_ids = unique(slots, 'job_id');
    const qualification_ids = unique(slots, 'qualification_ids');
    const role_ids = unique(slots, 'role_ids');
    const group_ids = unique(slots, 'group_id');

    this.setSelection('dispo.slots', {
      task_ids: task_ids,
      job_ids: job_ids,
      venue_ids: venue_ids,
      schedule_ids: schedule_ids,
      plan_ids: plan_ids,
      project_ids: project_ids,
      customer_ids: customer_ids,
      department_ids: department_ids,
      assignment_ids: assignment_ids,
      ranges: ranges,
      slots: {
        positions: positions,
        job_ids: slot_job_ids,
        qualification_ids: qualification_ids,
        role_ids: role_ids,
        group_ids: group_ids,
      },
      positions: slotPositions,
      assignments: assignments,
    });
  }

  setTasks(tasks: Task[]) {
    this.setSelection('dispo.tasks', this.selectionForTasks(tasks));
  }

  setSchedule(schedule: Schedule) {
    const job_ids = [schedule.template.job_id];
    const venue_ids = [schedule.template.venue_id];
    const tasks = this.tasks.filter((s) => s.schedule_id === schedule.id); // does not include shallow tasks
    const taskSelection = this.selectionForTasks(tasks);

    const plan = this.plans.find((p) => p.id === schedule.plan_id);

    this.setSelection(
      'dispo.schedules',
      Object.assign({}, taskSelection, {
        job_ids: job_ids,
        venue_ids: venue_ids,
        schedule_ids: [schedule.id],
        plan_ids: [schedule.plan_id],
        project_ids: [plan.project_id],
        customer_ids: [plan.customer_id],
        department_ids: [plan.department_id],
      }),
    );
  }

  setPlan(plan: Plan) {
    const tasks = this.tasks.filter((t) => t.plan_id === plan.id); // does not include shallow tasks
    const taskSelection = this.selectionForTasks(tasks);

    this.setSelection(
      'dispo.plans',
      Object.assign({}, taskSelection, {
        plan_ids: [plan.id],
        project_ids: [plan.project_id],
        customer_ids: [plan.customer_id],
        department_ids: [plan.department_id],
      }),
    );
  }

  setEmployee(employee) {
    this.setSelection('dispo.employees', {
      employee_ids: [employee.id],
      employees: [employee],
    });
  }

  setAnnouncement(announcement: Announcement) {
    const tasks = this.tasks.filter((t) =>
      announcement.task_ids.includes(t.id),
    );
    const taskSelection = this.selectionForTasks(tasks);

    const plan = this.plans.find((p) => p.id === announcement.plan_id);

    this.setSelection(
      'dispo.announcements',
      Object.assign({}, taskSelection, {
        venue_ids: [announcement.venue_id],
        plan_ids: [plan.id],
        project_ids: [plan.project_id],
        customer_ids: [plan.customer_id],
        department_ids: [plan.department_id],
      }),
    );
  }

  // getSlots(): Selection {
  //   return this.getSelection('slots');
  // }

  // getTask(): Selection {
  //   return this.getSelection('tasks');
  // }

  // getSchedule(): Selection {
  //   return this.getSelection('schedules');
  // }

  // getPlan(): Selection {
  //   return this.getSelection('plans');
  // }

  // getLocal(): Selection {
  //   return this.getSelection('local');
  // }

  setSelection(section, selection: Selection) {
    const defaults: Selection = {
      task_ids: [],
      job_ids: [],
      venue_ids: [],
      schedule_ids: [],
      plan_ids: [],
      project_ids: [],
      customer_ids: [],
      department_ids: [],
      assignment_ids: [],
      employee_ids: [],
      ranges: [],
      positions: [],
      assignments: [],
      employees: [],
      slots: {
        positions: [],
        job_ids: [],
        qualification_ids: [],
        role_ids: [],
        group_ids: [],
      },
    };

    selection = Object.assign(defaults, selection);
    debug('SelectionDS', section, selection);

    this.selection[section] = selection;
  }

  getSelection(section): Selection {
    return this.selection[section];
  }

  private selectionForTasks(tasks: Task[]) {
    const ranges: { starts_at: number; ends_at: number; valid: boolean }[] = [];
    const plans = [];
    const schedules = [];
    const slots = [];

    tasks.forEach((task) => {
      const schedule =
        this.schedules.find((s) => s.id === task.schedule_id) || undefined;
      const plan = this.plans.find((p) => p.id === task.plan_id);

      const range = {
        starts_at: task.starts_at.getTime(),
        ends_at: task.ends_at.getTime(),
        valid: true,
      };

      if (
        !ranges.find(
          (r) => r.starts_at === range.starts_at && r.ends_at === range.ends_at,
        )
      ) {
        ranges.push(range);
      }

      slots.push(...task.slots_config);
      tasks.push(task);

      if (schedule) {
        schedules.push(schedule);
      }

      if (plan) {
        plans.push(plan);
      }
    });

    const task_ids = unique(tasks, 'id');
    const venue_ids = unique(tasks, 'venue_id');
    const schedule_ids = unique(schedules, 'id');
    const plan_ids = unique(plans, 'id');
    const project_ids = unique(plans, 'project_id');
    const customer_ids = unique(plans, 'customer_id');
    const department_ids = unique(plans, 'department_id');
    const job_ids = unique(tasks, 'job_id');

    const slot_job_ids = unique(slots, 'job_id');
    const qualification_ids = unique(slots, 'qualification_ids');
    const role_ids = unique(slots, 'role_ids');
    const group_ids = unique(slots, 'group_id');

    return {
      task_ids: task_ids,
      job_ids: job_ids,
      venue_ids: venue_ids,
      schedule_ids: schedule_ids,
      plan_ids: plan_ids,
      project_ids: project_ids,
      customer_ids: customer_ids,
      department_ids: department_ids,
      ranges: ranges,
      slots: {
        positions: [],
        job_ids: slot_job_ids,
        qualification_ids: qualification_ids,
        role_ids: role_ids,
        group_ids: group_ids,
      },
    };
  }
}
