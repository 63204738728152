import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';

import { DispoScheduleTaskCollection } from '../../collections/task';
import { DispoScheduleCollection, Schedule } from '../../collections/schedule';
import { DeletableResponse } from '../../../core/types/deletable-response.model';

@Component({
  selector: 'tc-hub-ds-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css'],
})
export class DSDeleteComponent implements OnInit {
  @Input() schedule: Schedule;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  loading = true;
  deletable = false;
  messages: string[] = [];
  taskNonDeletables = [];

  constructor(
    private scheduleTaskCollection: DispoScheduleTaskCollection,
    private scheduleCollection: DispoScheduleCollection,
  ) {}

  ngOnInit() {
    const taskCollection = this.scheduleTaskCollection.forSchedule(
      this.schedule.id,
    );

    const deletableTasks$ = taskCollection.all().pipe(
      first(),
      map((tasks) => {
        if (tasks.length === 0) {
          return [of({ accepted: true })];
        } else {
          return tasks.map((t) =>
            taskCollection.deletable(t.id, t).asObservable(),
          );
        }
      }),
      switchMap((deletables$: Observable<DeletableResponse>[]) =>
        combineLatest(deletables$),
      ),
      map((taskDeletables) => {
        return taskDeletables.filter((t) => !t.accepted);
      }),
      map((taskDeletables) => {
        return taskDeletables.map((deletable) => {
          deletable.messages = Object.values(deletable.messages);
          return deletable;
        });
      }),
    );

    combineLatest(
      deletableTasks$,
      this.scheduleCollection.deletable(this.schedule.id, this.schedule),
    )
      .pipe(first())
      .subscribe({
        next: ([taskNonDeletables, deletable]) => {
          this.loading = false;
          this.deletable = taskNonDeletables.length === 0;
          this.taskNonDeletables = taskNonDeletables;
          this.messages = Object.values(deletable.messages);

          this.signal.emit({ action: 'resize' });
        },
      });
  }

  cancel() {
    this.signal.emit({ action: 'close' });
  }

  delete() {
    this.loading = true;

    const taskCollection = this.scheduleTaskCollection.forSchedule(
      this.schedule.id,
    );

    taskCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (tasks) => {
          if (tasks.length === 0) {
            this.scheduleCollection
              .delete(this.schedule.id, this.schedule)
              .subscribe({
                next: (_) => {
                  this.loading = false;
                  this.signal.emit({ action: 'close' });
                },
              });
          } else {
            combineLatest(
              tasks.map((task) => taskCollection.delete(task.id, task)),
            ).subscribe({
              next: (_) => {
                this.scheduleCollection
                  .delete(this.schedule.id, this.schedule)
                  .subscribe({
                    next: (_) => {
                      this.loading = false;
                      this.signal.emit({ action: 'close' });
                    },
                  });
              },
            });
          }
        },
      });
  }
}
