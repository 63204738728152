<div class="tc-modal-body" #modalBody>
  <div class="modal-container">
    <nz-steps [nzCurrent]="currentStep">
      <nz-step
        nzTitle="{{ 'attachment_release.batch.step-s2' | translate }}"
      ></nz-step>
      <nz-step
        nzTitle="{{ 'attachment_release.batch.step-s1' | translate }}"
      ></nz-step>
    </nz-steps>

    <div class="steps">
      <ng-container *ngIf="currentStep === 0">
        <tc-hub-tbdoc-step1
          class="modal-container"
          [document]="document"
          (signal)="onSignal($event)"
        ></tc-hub-tbdoc-step1>
      </ng-container>
      <ng-container *ngIf="currentStep === 1">
        <tc-hub-tbdoc-step2
          class="modal-container"
          [document]="document"
          (signal)="onSignal($event)"
        ></tc-hub-tbdoc-step2>
      </ng-container>
    </div>
  </div>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
