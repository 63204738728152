<ul
  class="ui-submenu-list"
  [@submenu]="
    expanded
      ? {
          value: 'visible',
          params: { transitionParams: transitionOptions, height: '*' }
        }
      : {
          value: 'hidden',
          params: { transitionParams: transitionOptions, height: '0' }
        }
  "
  role="tree"
>
  <ng-template ngFor let-child [ngForOf]="item.items">
    <li
      *ngIf="child.separator"
      class="ui-menu-separator ui-widget-content"
      role="separator"
    ></li>
    <li
      *ngIf="!child.separator"
      class="ui-menuitem ui-corner-all"
      [ngClass]="child.styleClass"
      [class.ui-helper-hidden]="child.visible === false"
      [ngStyle]="child.style"
    >
      <a
        *ngIf="!child.routerLink"
        [href]="child.url || '#'"
        class="ui-menuitem-link ui-corner-all"
        [attr.tabindex]="
          item.expanded ? null : child.tabindex ? child.tabindex : '-1'
        "
        [attr.id]="child.id"
        [ngClass]="{
          'ui-state-disabled': child.disabled,
          'ui-state-active': child.expanded
        }"
        role="treeitem"
        [attr.aria-expanded]="child.expanded"
        (click)="handleClick($event, child)"
        [attr.target]="child.target"
        [attr.title]="child.title"
      >
        <span *ngIf="child.items" class="ui-panelmenu-icon">
          <i nz-icon nzType="caret-right" *ngIf="!child.expanded"></i>
          <i nz-icon nzType="caret-down" *ngIf="child.expanded"></i>
        </span>
        <span class="ui-menuitem-icon" *ngIf="child.icon">
          <i
            nz-icon
            [nzType]="child.icon"
            [nzTheme]="child.iconNzTheme ?? 'outline'"
          ></i>
        </span>
        <span class="ui-menuitem-text">{{ child.label }}</span>
      </a>
      <a
        *ngIf="child.routerLink"
        [routerLink]="child.routerLink"
        [queryParams]="child.queryParams"
        [routerLinkActive]="'ui-menuitem-link-active'"
        [routerLinkActiveOptions]="
          child.routerLinkActiveOptions || { exact: false }
        "
        class="ui-menuitem-link ui-corner-all"
        [ngClass]="{ 'ui-state-disabled': child.disabled }"
        [attr.tabindex]="
          item.expanded ? null : child.tabindex ? child.tabindex : '-1'
        "
        [attr.id]="child.id"
        role="treeitem"
        [attr.aria-expanded]="child.expanded"
        (click)="handleClick($event, child)"
        [attr.target]="child.target"
        [attr.title]="child.title"
        [fragment]="child.fragment"
        [queryParamsHandling]="child.queryParamsHandling"
        [preserveFragment]="child.preserveFragment"
        [skipLocationChange]="child.skipLocationChange"
        [replaceUrl]="child.replaceUrl"
        [state]="child.state"
      >
        <span *ngIf="child.items" class="ui-panelmenu-icon">
          <i nz-icon nzType="caret-right" *ngIf="!child.expanded"></i>
          <i nz-icon nzType="caret-down" *ngIf="child.expanded"></i>
        </span>
        <span class="ui-menuitem-icon" *ngIf="child.icon">
          <i
            nz-icon
            [nzType]="child.icon"
            [nzTheme]="child.iconNzTheme ?? 'outline'"
          ></i>
        </span>
        <span class="ui-menuitem-text">{{ child.label }}</span>
      </a>
      <tc-hub-panel-menu-sub
        [item]="child"
        [expanded]="child.expanded"
        [transitionOptions]="transitionOptions"
        *ngIf="child.items"
      ></tc-hub-panel-menu-sub>
    </li>
  </ng-template>
</ul>
