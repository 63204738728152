import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';
import { TcInterval } from '@timecount/core';
import { dateToTzUnix, isValidInterval } from '@timecount/utils';

import { Bias } from '../../biases/bias.model';
import { EmployeeBiasCollection } from '../employee-biases.collection';

@Component({
  selector: 'tc-hub-employee-biases-form',
  templateUrl: './employee-biases-form.component.html',
  styleUrls: ['./employee-biases-form.component.scss'],
})
export class TcEmployeeBiasesFormComponent
  extends BaseModalComponent
  implements OnInit
{
  formGroup: UntypedFormGroup;

  employeeId: number;
  initialValue: Bias;
  target: unknown;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    protected config: ModalConfig,
    protected modalRef: ModalRef,
    private _employeeBiasCollection: EmployeeBiasCollection,
  ) {
    super(config, modalRef);
  }

  ngOnInit(): void {
    Object.assign(this, this.config.data);
    this._buildForm();
  }

  // --------------
  // Public Methods
  // --------------

  save() {
    const { target, range, impact, note } = this.formGroup.value;
    const { id, target_type } = this.initialValue;
    const payload = {
      id,
      resource_type: 'Employee',
      resource_id: this.employeeId,
      target_id: target.id ?? target,
      target_type: target.type ?? target_type,
      ...TcInterval.formatForApi(range),
      ends_at: range.end,
      marker: isValidInterval(range)
        ? [dateToTzUnix(range.start), dateToTzUnix(range.end)]
        : [],
      impact,
      note,
    };
    const collectionService = this._employeeBiasCollection.forEmployee(
      this.employeeId,
    );
    if (id) {
      collectionService.update(id, payload);
    } else {
      collectionService.create(payload);
    }

    this.modalRef.close();
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm() {
    const { target_id, starts_at, ends_at, impact, note, store, target_type } =
      this.initialValue ?? {};

    this.formGroup = this._formBuilder.group({
      target: [this.target, Validators.required],
      range: [TcInterval.parseFromApi({ starts_at, ends_at })],
      impact: [impact, Validators.required],
      note: [note],
      store: [store],
    });
  }
}
