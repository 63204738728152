import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { IntervalSummary, TcIntervalList } from '@timecount/core';
import {
  TcFieldSetIntervalSetService,
  TcTimesSummaryService,
} from '@timecount/ui';
import { durationInMinutes } from '@timecount/utils';

import { DispoAssignmentsService } from '../dispo-assignments.service';
import { DASItemComponent } from '../item.component';

@Component({
  selector: 'tc-hub-das-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
})
export class DASAssignmentComponent
  extends DASItemComponent
  implements OnChanges
{
  public now = new Date();

  @Input() item: any;

  parsedAssignmentTimes: Interval[];
  assignmentSummary: IntervalSummary;
  taskSummary: IntervalSummary;

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    if ($event.type === 'mouseup' && $event.button === 2) {
      return;
    }

    const nextState = $event.type === 'contextmenu' ? true : !this.item.checked;
    this.selectItem(this.item.type, this.item.id, nextState, $event);
  }

  constructor(
    protected service: DispoAssignmentsService,
    private intervalSetService: TcFieldSetIntervalSetService,
    private summaryService: TcTimesSummaryService,
  ) {
    super(service);
  }

  ngOnChanges({ item }: SimpleChanges): void {
    if (item) {
      this.parsedAssignmentTimes = TcIntervalList.parseFromApi(this.item);
      const parsedTimes = this.intervalSetService.parseFromApi(this.item);
      const parsedTaskTimes = this.intervalSetService.parseFromApi(
        this.item.task,
      );
      this.assignmentSummary = this.summaryService.getSummary(parsedTimes);
      this.taskSummary = this.summaryService.getSummary(parsedTaskTimes);
    }
  }

  durationInMinutes(duration) {
    return durationInMinutes(duration);
  }
}
