<div class="bar">
  <tc-hub-dispo-filter-display
    #display
    [section]="section"
    [schema]="schema"
    [filter]="filter"
    (click)="toggleForm()"
    (requestFilter)="requestFilter($event)"
  ></tc-hub-dispo-filter-display>
  <tc-hub-dispo-filter-preset
    *ngIf="presets.length !== 0"
    [section]="section"
    [presets]="presets"
    (requestPreset)="requestPreset($event)"
  >
  </tc-hub-dispo-filter-preset>
  <tc-hub-dispo-filter-sort
    *ngIf="sorts.length > 1"
    [section]="section"
    [sorts]="sorts"
    (requestSort)="requestSort($event)"
  >
  </tc-hub-dispo-filter-sort>
  <tc-hub-dispo-toggle-resources [dispoSection]="section">
  </tc-hub-dispo-toggle-resources>
</div>
<tc-hub-dispo-filter-form
  #form
  [section]="section"
  [schema]="schema"
  [filter]="filter"
  (requestFilter)="requestFilter($event)"
></tc-hub-dispo-filter-form>
