import { Component, OnInit } from '@angular/core';

import { BaseModalComponent } from '@timecount/ui';

@Component({
  selector: 'tc-hub-tb-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class TimebalanceDocumentComponent
  extends BaseModalComponent
  implements OnInit
{
  currentStep = 0;
  date: Date;
  template: any;
  document: any;

  ngOnInit() {
    super.ngOnInit();
    this.date = this.config.data.date;
    this.template = this.config.data.template;

    this.document = {
      date: this.date,
      template_id: this.template.id,
      template_handle: this.template.handle,
      resources: [],
      store: {},
      release: {
        release: false,
        attach_file: false,
        subject: '',
        body: '',
      },
    };
  }

  onSignal(signal) {
    if (signal.action === 'next') {
      this.document = signal.document;
      this.next();
    }

    if (signal.action === 'prev') {
      this.document = signal.document;
      this.prev();
    }

    super.onSignal(signal);
  }

  prev(): void {
    this.currentStep -= 1;
  }

  next(): void {
    this.currentStep += 1;
  }
}
