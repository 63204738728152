<div class="tc-modal-body" #modalBody>
  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="section === 'general' || section === 'availability'"
  >
    <nz-tab
      nzTitle="{{ 'dispo/assignment.tab_type.general' | translate }}"
      *tcHubHide="'views.dispo.assignment.general'"
    >
      <ng-template nz-tab>
        <tc-hub-da-general
          class="modal-container"
          [assignment]="assignments[0]"
          (signal)="onSignal($event)"
        ></tc-hub-da-general>
      </ng-template>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/assignment.tab_type.availability' | translate }}"
      *tcHubHide="'views.dispo.assignment.availability'"
    >
      <ng-template nz-tab>
        <tc-hub-da-avail
          class="modal-container"
          [assignments]="assignments"
          (signal)="onSignal($event)"
        ></tc-hub-da-avail>
      </ng-template>
    </nz-tab>
  </nz-tabset>

  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    *ngIf="section === 'timebalance'"
  >
    <nz-tab
      nzTitle="{{ 'dispo/assignment.tab_type.timebalance' | translate }}"
      *tcHubHide="'views.dispo.assignment.timebalance'"
    >
      <tc-hub-da-timebalance
        class="modal-container"
        [assignments]="assignments"
        (signal)="onSignal($event)"
      ></tc-hub-da-timebalance>
    </nz-tab>
  </nz-tabset>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'delete'">
    <nz-tab
      nzTitle="{{ 'dispo/assignment.tab_type.delete' | translate }}"
      *tcHubHide="'views.dispo.assignment.delete'"
    >
      <tc-hub-da-delete
        class="modal-container"
        [assignments]="assignments"
        (signal)="onSignal($event)"
      ></tc-hub-da-delete>
    </nz-tab>
  </nz-tabset>

  <ng-container *ngIf="section === 'info'">
    <tc-hub-da-info
      *tcHubHide="'views.dispo.assignment.info'"
      class="modal-container"
      [assignments]="assignments"
      (signal)="onSignal($event)"
    ></tc-hub-da-info>
  </ng-container>

  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="section === 'assignContractor' || section === 'assignEmployee'"
  >
    <nz-tab
      nzTitle="{{ 'dispo/assignment.tab_type.assign_contractor' | translate }}"
      *tcHubHide="'views.dispo.assignment.assign_contractor'"
    >
      <ng-template nz-tab>
        <tc-hub-da-contractor
          class="modal-container"
          [assignments]="assignments"
          (signal)="onSignal($event)"
        ></tc-hub-da-contractor>
      </ng-template>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/assignment.tab_type.assign_employee' | translate }}"
      *tcHubHide="'views.dispo.assignment.assign_employee'"
    >
      <ng-template nz-tab>
        <tc-hub-da-employee
          class="modal-container"
          [assignments]="assignments"
          (signal)="onSignal($event)"
        ></tc-hub-da-employee>
      </ng-template>
    </nz-tab>
  </nz-tabset>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
