import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalSettingsService } from '../../../core/local-settings.service';
import { ScheduleService } from '../../actions/schedule.service';
import { DispoFocusService } from '../../dispo-focus.service';
import { DispoSchedulesService } from '../dispo-schedules.service';
import { DSScheduleComponent } from '../schedule/schedule.component';

@Component({
  selector: 'tc-hub-ds-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DSPlanComponent implements OnInit {
  @ViewChildren('scheduleComponent')
  scheduleComponents: QueryList<DSScheduleComponent>;

  @Input() plan: any;
  @Input() showEmpty: Observable<boolean>;

  @Output() tcSelect: EventEmitter<unknown> = new EventEmitter();

  plan$: Observable<any>;
  weeks$: Observable<any[]>;
  schedules$: Observable<any[]>;

  expanded$: Observable<boolean>;
  visible$: Observable<boolean>;

  constructor(
    private dispoFocus: DispoFocusService,
    private localSettings: LocalSettingsService,
    private service: DispoSchedulesService,
    private scheduleActions: ScheduleService,
  ) {}

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onEvent($event) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({
      item_type: 'DispoPlan',
      item: [this.plan],
    });
  }

  ngOnInit() {
    this.plan$ = this.service.planDS(this.plan);
    this.weeks$ = this.dispoFocus.weeks();
    this.expanded$ = this.localSettings.get(
      `dispo.planExpanded.${this.plan.id}`,
      true,
    );
    this.schedules$ = this.service.planSchedules(this.plan);
    this.visible$ = combineLatest(this.showEmpty, this.schedules$).pipe(
      map(([showEmpty, schedules]) => {
        if (showEmpty) {
          return true;
        } else {
          return schedules.length !== 0;
        }
      }),
    );
  }

  onTogglePlan($event) {
    this.localSettings.toggle(`dispo.planExpanded.${this.plan.id}`, true);
  }

  onSelect(e) {
    this.tcSelect.emit(e);
  }

  createSchedule() {
    this.scheduleActions.createFromPlanDefault(this.plan);
  }

  clearSelection() {
    this.scheduleComponents.map((s) => s.clearSelection());
  }

  trackById(index, item) {
    return item.id;
  }

  includes(date: Date) {
    const timestamp = new Date(date.getTime()).setHours(12, 0, 0, 0) / 1000;

    return this.plan.marker[0] <= timestamp && this.plan.marker[1] >= timestamp;
  }

  isWeekend(date: Date) {
    const wday = date.getDay();

    return wday === 0 || wday === 6;
  }
}
