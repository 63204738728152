import { tap } from 'rxjs/operators';

export function integerToDate(int: number): Date {
  const date = new Date(int * 1000);

  return new Date(int * 1000 + date.getTimezoneOffset() * 60 * 1000);
}

export function integerToString(int: number): string {
  const date = integerToDate(int);

  return dateToString(date);
}

export function dateToString(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month < 10 ? '0' + month : month}-${
    day < 10 ? '0' + day : day
  }`;
}

export function dateToAPIString(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}${month < 10 ? '0' + month : month}${
    day < 10 ? '0' + day : day
  }`;
}

export function overlaps(marker, begin: number, end: number) {
  if (Array.isArray(marker)) {
    return marker[0] <= end && marker[1] >= begin;
  } else {
    return marker >= begin && marker <= end;
  }
}

export function dig(obj, path) {
  return path.split('.').reduce((nestedObject, key) => {
    if (nestedObject && key in nestedObject) {
      return nestedObject[key];
    }
    return undefined;
  }, obj);
}

export function debug(...args): any {
  if ((<any>window).debug) {
    console.log(...args);
  }
}

export function debugTap(msg): any {
  return tap((items) => {
    if ((<any>window).debug) {
      console.log(msg, items);
    }
  });
}

export function sumObjectKeys(objs: any[], keys = [], base: any = {}): any {
  return objs.reduce((total, obj) => {
    keys.forEach((key) => {
      total[key] = (total[key] || 0) + (obj[key] || 0);
    });

    return total;
  }, base);
}

export const naturalSort: any = (a, b) => {
  const re =
    /(^([+-]?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?(?=\D|\s|$))|^0x[\da-fA-F]+$|\d+)/g;
  const sre = /^\s+|\s+$/g; // trim pre-post whitespace
  const snre = /\s+/g; // normalize all whitespace to single ' ' character
  const dre =
    /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[/-]\d{1,4}[/-]\d{1,4}|^\w+, \w+ \d+, \d{4})/;
  const hre = /^0x[0-9a-f]+$/i;
  const ore = /^0/;
  const i = function (s) {
    return (
      (naturalSort.insensitive && ('' + s).toLowerCase()) ||
      '' + s
    ).replace(sre, '');
  };
  // convert all to strings strip whitespace
  const x = i(a);
  const y = i(b);
  // chunk/tokenize
  const xN = x
    .replace(re, '\0$1\0')
    .replace(/\0$/, '')
    .replace(/^\0/, '')
    .split('\0');
  const yN = y
    .replace(re, '\0$1\0')
    .replace(/\0$/, '')
    .replace(/^\0/, '')
    .split('\0');
  // numeric, hex or date detection
  // eslint-disable-next-line
  // @ts-ignore
  const parsedX = parseInt(x.match(hre), 16);
  const xD = parsedX || (xN.length !== 1 && Date.parse(x));
  // eslint-disable-next-line
  // @ts-ignore
  const parsedY = parseInt(y.match(hre), 16);
  const yD = parsedY || (xD && y.match(dre) && Date.parse(y)) || null;
  const normChunk = function (s, l) {
    // normalize spaces; find floats not starting with '0', string or 0 if not defined (Clint Priest)
    return (
      ((!s.match(ore) || l == 1) && parseFloat(s)) ||
      s.replace(snre, ' ').replace(sre, '') ||
      0
    );
  };
  let oFxNcL;
  let oFyNcL;

  // first try and sort Hex codes or Dates
  if (yD) {
    if (xD < yD) {
      return -1;
    } else if (xD > yD) {
      return 1;
    }
  }
  // natural sorting through split numeric strings and default strings
  for (
    let cLoc = 0, xNl = xN.length, yNl = yN.length, numS = Math.max(xNl, yNl);
    cLoc < numS;
    cLoc++
  ) {
    oFxNcL = normChunk(xN[cLoc] || '', xNl);
    oFyNcL = normChunk(yN[cLoc] || '', yNl);
    // handle numeric vs string comparison - number < string - (Kyle Adams)
    if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
      return isNaN(oFxNcL) ? 1 : -1;
    }
    // if unicode use locale comparison
    // eslint-disable-next-line no-control-regex
    if (/[^\x00-\x80]/.test(oFxNcL + oFyNcL) && oFxNcL.localeCompare) {
      const comp = oFxNcL.localeCompare(oFyNcL);
      return comp / Math.abs(comp);
    }
    if (oFxNcL < oFyNcL) {
      return -1;
    } else if (oFxNcL > oFyNcL) {
      return 1;
    }
  }
};
