<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row" *ngIf="import.projectChangeable">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <tc-hub-collection-select
              label="{{ 'dispo/import.attrs.project_id' | translate }}"
              [collectionTypes]="['Project']"
              [formGroup]="form"
              controlName="project_id"
              bindLabel="label"
              bindValue="id"
              searchable="true"
            >
            </tc-hub-collection-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <tc-hub-remote-select
              label="{{ 'dispo/import.attrs.aggregation_id' | translate }}"
              [url]="aggregationUrl$ | async"
              [formGroup]="form"
              [decorate]="aggregationDecorator"
              controlName="aggregation_id"
              bindLabel="label"
              bindValue="id"
              searchable="true"
            >
            </tc-hub-remote-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-option-select
              label="{{ 'dispo/import.attrs.target' | translate }}"
              type="dispo.import_target"
              [formGroup]="form"
              controlName="target"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [disabled]="form.invalid"
      (click)="next()"
    >
      {{ 'next' | translate }}
    </button>
  </div>
</form>
