<form class="modal-container" [formGroup]="formGroup" *ngIf="formGroup" novalidate>
  <div class="modal-body">
    <div class="container">
      <tc-input-text-web 
        label="{{ 'aggregation_entry/work.batch.attrs.title' | translate }}"
        formControlName="title">
      </tc-input-text-web>

      <tc-hub-collection-select 
        label="{{ 'aggregation_entry/work.batch.attrs.template' | translate }}"
        [collectionTypes]="['WorkEntryBatchTemplate']" 
        [formGroup]="formGroup" 
        controlName="template_id" 
        bindLabel="title"
        bindValue="id">
      </tc-hub-collection-select>
    </div>
  </div>

  <div class="modal-footer">
    <button 
      type="button" 
      nz-button 
      nzType="primary" 
      (click)="next()" 
      [disabled]="formGroup.invalid">
        {{ 'next' | translate }}
    </button>
  </div>
</form>