import { Directive, Input } from '@angular/core';

import { DispoAssignmentsService } from './dispo-assignments.service';

@Directive()
export abstract class DASItemComponent {
  @Input() item: any;

  constructor(protected service: DispoAssignmentsService) {}

  toggleItem(type, id) {
    this.service.toggleItem(type, id);
  }

  selectItem(type, id, state, event = new MouseEvent('click')) {
    this.service.selectItem(type, id, state, event);
  }
}
