<div class="wrapper">
  <div class="filter-bar">
    <tc-hub-dispo-filter section="finances.entries"></tc-hub-dispo-filter>
  </div>

  <div class="panel">
    <div class="assignments">
      <div class="content">
        <nz-table
          nzVirtualScroll
          nzSize="middle"
          nzVirtualItemSize="40"
          nzVirtualMinBufferPx="1000"
          nzVirtualMaxBufferPx="2600"
          [nzWidthConfig]="widthConfig"
          [nzData]="items$ | async"
          [nzVirtualForTrackBy]="trackByIdAndType"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          [nzScroll]="{ x: '1600px', y: 'calc(100vh - 55px - 50px - 47px)' }"
        >
          <thead>
            <tr>
              <th>
                <label
                  nz-checkbox
                  [(nzChecked)]="root.checked"
                  [nzIndeterminate]="root.indeterminate"
                  (nzCheckedChange)="selectItem('Root', 0, root.checked)"
                ></label>
              </th>
              <th>{{ 'aggregation_entry.attrs.sku_short' | translate }}</th>
              <th>{{ 'aggregation_entry.attrs.pos_short' | translate }}</th>
              <th>
                {{ 'aggregation_entry.attrs.work_duration' | translate }}
              </th>
              <th>{{ 'aggregation_entry.attrs.price' | translate }}</th>
              <ng-container *ngFor="let zone of zoneGroups$ | async">
                <th>{{ zone.display_key }}</th>
              </ng-container>
              <th>{{ 'aggregation_entry.attrs.total' | translate }}</th>
              <th>{{ 'aggregation_entry.attrs.invoice_token' | translate }}</th>
              <th>{{ 'aggregation_entry.attrs.gratis' | translate }}</th>
              <th>{{ 'aggregation_entry.attrs.date_short' | translate }}</th>
              <th>
                {{ 'aggregation_entry.attrs.starts_at_short' | translate }}
              </th>
              <th>{{ 'aggregation_entry.attrs.ends_at_short' | translate }}</th>
              <th>
                {{ 'aggregation_entry.attrs.breaks_number' | translate }}
              </th>

              <th>
                {{ 'aggregation_entry.attrs.break_duration' | translate }}
              </th>
              <th>
                {{ 'aggregation_entry.attrs.total_duration' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-template nz-virtual-scroll let-item let-index="index">
              <ng-container *ngIf="item.type === 'Project'">
                <tc-hub-finances-entries-project
                  [item]="item"
                  [zoneGroupNumber]="(zoneGroups$ | async).length"
                >
                </tc-hub-finances-entries-project>
              </ng-container>
              <ng-container *ngIf="item.type === 'WorkEntryGroup'">
                <tc-hub-finances-entries-work-entry-group
                  [item]="item"
                  [zoneGroupNumber]="(zoneGroups$ | async).length"
                >
                </tc-hub-finances-entries-work-entry-group>
              </ng-container>
              <ng-container *ngIf="item.type === 'DispoPlan'">
                <tc-hub-finances-entries-plan
                  [item]="item"
                  [zoneGroupNumber]="(zoneGroups$ | async).length"
                >
                </tc-hub-finances-entries-plan>
              </ng-container>
              <ng-container *ngIf="item.type === 'DispoSchedule'">
                <tc-hub-finances-entries-schedule
                  [item]="item"
                  [zoneGroupNumber]="(zoneGroups$ | async).length"
                >
                </tc-hub-finances-entries-schedule>
              </ng-container>
              <ng-container *ngIf="item.type === 'DispoTask'">
                <tc-hub-finances-entries-task
                  [item]="item"
                  [zoneGroupNumber]="(zoneGroups$ | async).length"
                >
                </tc-hub-finances-entries-task>
              </ng-container>
              <ng-container *ngIf="item.type === 'WorkEntry'">
                <tc-hub-finances-entries-work-entry
                  [item]="item"
                  [zoneGroups]="zoneGroups$ | async"
                >
                </tc-hub-finances-entries-work-entry>
              </ng-container>
              <ng-container *ngIf="item.type === 'ServiceEntryGroup'">
                <tc-hub-finances-entries-service-entry-group
                  [item]="item"
                  [zoneGroupNumber]="(zoneGroups$ | async).length"
                >
                </tc-hub-finances-entries-service-entry-group>
              </ng-container>
              <ng-container *ngIf="item.type === 'ServiceEntry'">
                <tc-hub-finances-entries-service-entry
                  [item]="item"
                  [zoneGroupNumber]="(zoneGroups$ | async).length"
                >
                </tc-hub-finances-entries-service-entry>
              </ng-container>
            </ng-template>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
