<div class="sf-group" [formGroup]="form">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>
  <div class="sf-controls">
    <div class="sf-elements">
      <tc-interval-web
        [tcFieldType]="'dateTime'"
        formControlName="range"
        [tcConfig]="{
          limit: limit,
          timePickerSteps: 15,
          maxItemDuration: { days: 31 },
          fieldType: 'dateTime'
        }"
        [tcLabels]="{
          start: ('range_begin' | translate),
          end: ('range_end' | translate)
        }"
      ></tc-interval-web>
    </div>
  </div>
</div>
