import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuModule } from 'primeng/contextmenu';

import { SharedModule } from '../../shared';

import { ContextMenuComponent } from './contextmenu/contextmenu.component';
import { ContextMenuSubComponent } from './contextmenu/contextmenu-sub.component';
import { PanelMenuComponent } from './panelmenu/panelmenu.component';
import { PanelMenuSubComponent } from './panelmenu/panelmenu-sub.component';
import { MenuComponent } from './menu/menu.component';
import { MenuItemContentComponent } from './menu/menu-item-content.component';

@NgModule({
  declarations: [
    ContextMenuComponent,
    ContextMenuSubComponent,
    PanelMenuComponent,
    PanelMenuSubComponent,
    MenuComponent,
    MenuItemContentComponent,
  ],
  imports: [CommonModule, ContextMenuModule, SharedModule],
  exports: [
    ContextMenuComponent,
    ContextMenuSubComponent,
    PanelMenuComponent,
    PanelMenuSubComponent,
    MenuComponent,
    MenuItemContentComponent,
  ],
})
export class TcPrimengModule {}
