import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplateCollection extends SetsCollection {
  identifier = 'preferences/message_template';
  endpoint = '/api/preferences/message_templates';

  cache = 60 * 60 * 12;

  localSearch = ['title'];

  byType(resourceType, dataType) {
    return this.query(
      {
        resource_type: resourceType,
        data_type: dataType,
      },
      (t) => {
        return t.resource_type === resourceType && t.data_type === dataType;
      },
    );
  }
}
