<tc-form-nz-picker-base [picker]="datePicker">
  <nz-date-picker
    #datePicker
    [nzFormat]="format"
    [nzShowTime]="showTime ? { nzFormat: timeFormat } : null"
    [nzAllowClear]="allowClear"
    [nzDateRender]="highlightedDateTemplate"
    [nzDisabledDate]="isDateDisabled"
    [(ngModel)]="value"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onModelChange($event)"
  ></nz-date-picker>
</tc-form-nz-picker-base>

<ng-template #highlightedDateTemplate let-current>
  <div
    class="ant-picker-cell-inner"
    [tcFormHighlightedDay]="isDateHighlight(current)"
  >
    {{ current.getDate() }}
  </div>
</ng-template>
