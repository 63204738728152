import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  Provider,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const Inputmask = (window as any).Inputmask;

export const MASK_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MaskComponent),
  multi: true,
};

@Component({
  selector: 'tc-hub-schema-mask',
  templateUrl: './mask.component.html',
  styleUrls: ['./mask.component.scss'],
  providers: [MASK_CONTROL_VALUE_ACCESSOR],
})
export class MaskComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  @ViewChild('input', { read: ElementRef }) input: ElementRef;

  @Input() mask;
  @Input() alias;

  private _mask: any;
  private _innerValue: any;
  public isDisabled = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: any[]) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {};

  ngAfterViewInit() {
    const options = {
      placeholder: '_',
      insertMode: false,
      oncomplete: () => this.onMaskChange(new CustomEvent('complete')),
      onincomplete: () => this.onMaskChange(new CustomEvent('incomplete')),
      oncleared: () => this.onMaskChange(new CustomEvent('clear')),
    };

    if (this.mask) {
      options['mask'] = this.mask;
    }

    if (this.alias) {
      options['alias'] = this.alias;
    }

    this._mask = Inputmask(options).mask(this.input);
  }

  ngOnDestroy() {
    this._mask?.remove();
  }

  onMaskChange(event) {
    if (event.type === 'complete') {
      this.innerValue = this.input.nativeElement.value;
    } else {
      this.innerValue = undefined;
    }
  }

  public get innerValue(): any {
    return this._innerValue;
  }

  public set innerValue(newValue: any) {
    this._innerValue = newValue;

    if (newValue) {
      this.onChangeCallback(newValue.replace(/_/g, ''));
    } else {
      this.onChangeCallback(undefined);
    }
  }

  public onBlur() {
    this.onTouchedCallback();
  }

  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(callback: (_: any) => void) {
    this.onChangeCallback = callback;
  }

  public registerOnTouched(callback: () => void) {
    this.onTouchedCallback = callback;
  }
}
