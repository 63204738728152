import { NgModule } from '@angular/core';
import { DateFnsModule } from 'ngx-date-fns';

import { DateIsPipe } from './date-is.pipe';
import { DateISOPipe } from './date-iso.pipe';
import { DurationPipe } from './duration.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { RelativeDayPipe } from './relative-day.pipe';
import { IntervalPipe } from './interval.pipe';

@NgModule({
  declarations: [
    DateISOPipe,
    DurationPipe,
    DateIsPipe,
    IntervalPipe,
    LocalizedDatePipe,
    RelativeDayPipe,
  ],
  imports: [DateFnsModule.forRoot()],
  exports: [
    DateISOPipe,
    DurationPipe,
    DateIsPipe,
    IntervalPipe,
    LocalizedDatePipe,
    RelativeDayPipe,
    DateFnsModule,
  ],
})
export class DateModule {}
