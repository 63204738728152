import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { of } from 'rxjs';
import * as MessagePack from 'msgpack-ts';

// currently completely disabled until we have a better understanding of
// why the cache keeps growing and growing. Potentially a circular structure?
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private decoder: MessagePack.Decoder;
  private encoder: MessagePack.Encoder;

  constructor(private db: NgxIndexedDBService) {
    this.decoder = new MessagePack.Decoder();
    this.encoder = new MessagePack.Encoder();
  }

  setItem(key: string, data: object, timeout: number, meta?: object) {
    // this.db
    //   .update('cache', {
    //     key,
    //     timeout,
    //     meta,
    //     data: this.encoder.encode(data),
    //   })
    //   .subscribe((_) => {
    //     console.log('cached', key);
    //   });
  }

  getItem(key: string) {
    return of(undefined);
    // return from(
    //   this.db.getByKey('cache', key).pipe(
    //     map((res) => {
    //       if (!res || res['timeout'] < Date.now()) {
    //         return undefined;
    //       }

    //       res['data'] = this.decoder.decode(res['data']);
    //       return res;
    //     }),
    //     catchError(() => of(undefined)),
    //   ),
    // );
  }
}
