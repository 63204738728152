export const generateSupplementsFromForm = (formValue, supplements) => {
  const obj = {};

  supplements.forEach((s) => {
    let modelValue = formValue[`supplement_${s.id}`] || 0;
    modelValue =
      s.data_type === 'money' ? Math.round(modelValue * 100) : modelValue;

    obj[s.id] = modelValue;
  });

  return obj;
};

export const generateEmptySupplements = (supplements) => {
  const obj = {};

  supplements.forEach((s) => {
    obj[s.id] = 0;
  });

  return obj;
};

export const generateFieldsForSupplements = (obj, supplements, opts = {}) => {
  const group = {};

  supplements.forEach((supplement) => {
    const { id, data_type } = supplement;

    let displayValue = obj ? (obj['supplements'] || {})[id] || 0 : undefined;
    displayValue = data_type === 'money' ? displayValue / 100.0 : displayValue;

    group[`supplement_${id}`] = [
      {
        value: displayValue,
        ...opts,
      },
    ];
  });

  return group;
};

export const generateValuesForSupplements = (obj, supplements) => {
  const group = {};

  supplements.forEach((supplement) => {
    const { id, data_type } = supplement;

    let displayValue = obj ? (obj['supplements'] || {})[id] || 0 : undefined;
    displayValue = data_type === 'money' ? displayValue / 100.0 : displayValue;

    group[`supplement_${id}`] = displayValue;
  });

  return group;
};
