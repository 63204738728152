import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { AnnouncementService } from '../actions/announcement.service';
import { CurrentUserService } from '../../core/current-user.service';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../collections/announcement';
import { Task } from '../collections/task';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuAnnouncementSlot {
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private announcementActions: AnnouncementService,
    private announcementCollection: DispoAnnouncementCollection,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/announcement.actions.detach_tasks',
        'dispo/announcement.actions.increase_size',
        'dispo/announcement.actions.decrease_size',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  items(currAnnouncement: Announcement, currTasks: Task[]): Observable<any[]> {
    return this.announcementCollection.observeItem(currAnnouncement).pipe(
      map((announcement: Announcement) => {
        const announcementActions = [];
        const announcedTasks = currTasks.filter(
          (t) => announcement.task_ids.indexOf(t.id) !== -1,
        );

        announcementActions.push({
          label: this.translations['dispo/announcement.actions.increase_size'],
          icon: 'plus-circle',
          visible:
            announcedTasks.length > 0 &&
            this.currentUser.hasAccessToSection(
              'views.dispo.announcement.increase_size',
            ),
          command: (event) => {
            this.announcementActions.increaseSize(announcement, announcedTasks);
          },
        });

        announcementActions.push({
          label: this.translations['dispo/announcement.actions.decrease_size'],
          icon: 'minus-circle',
          visible:
            announcedTasks.length > 0 &&
            this.currentUser.hasAccessToSection(
              'views.dispo.announcement.decrease_size',
            ),
          command: (event) => {
            this.announcementActions.decreaseSize(announcement, announcedTasks);
          },
        });

        announcementActions.push({
          label: this.translations['dispo/announcement.actions.detach_tasks'],
          icon: 'delete',
          visible:
            announcedTasks.length > 0 &&
            this.currentUser.hasAccessToSection(
              'views.dispo.announcement.detach_tasks',
            ),
          command: (event) => {
            this.announcementActions.detachTasks(announcement, announcedTasks);
          },
        });

        const items = [
          ...(announcementActions.length !== 0
            ? [
                {
                  header: true,
                  label: this.translations['dispo/task.menu.announcement'],
                },
                ...announcementActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }
}
