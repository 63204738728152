<ng-container *ngIf="user$ | async; let user" [formGroup]="formGroup">
  <div class="wrapper">
    <a nz-button nzType="text" class="icon-button" href="/#/preferences/user">
      <span
        class="user-icon"
        nz-icon
        nzType="user"
        nzTheme="outline"
        nz-tooltip
        nzTooltipTitle="{{ user.name }}"
      ></span>
    </a>
    <button
      class="departments-button"
      *ngIf="!isFromAnotherAccount"
      nz-button
      nzType="primary"
      nzSize="small"
      nz-popover
      nzPopoverTrigger="click"
      nzPopoverPlacement="rightBottom"
      [nzPopoverContent]="popoverContent"
      nz-tooltip
      nzTooltipTitle="{{ 'employee.attrs.department' | translate }}"
    >
      <span class="active-departments"
        >{{ user.activeDepartmentsNumber }} /
        {{ user.departments.length }}</span
      >
    </button>
  </div>

  <ng-template #popoverContent>
    <cdk-virtual-scroll-viewport
      itemSize="45"
      class="scroll-container"
      [style.height.px]="user.departments.length * 45"
    >
      <nz-list
        formGroupName="departments"
        nzItemLayout="horizontal"
        class="departments-list"
      >
        <nz-list-item
          *cdkVirtualFor="
            let department of user.departments;
            trackBy: trackByMethod;
            templateCacheSize: 0
          "
        >
          <tc-hub-department-info
            [formControlName]="department.id"
            [icon]="department.icon"
            [color]="department.color"
            [title]="department.title"
          >
          </tc-hub-department-info>
        </nz-list-item>
      </nz-list>
    </cdk-virtual-scroll-viewport>
  </ng-template>
</ng-container>
