import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

@Injectable({
  providedIn: 'root',
})
export class AttachmentCollection extends SetsCollection {
  identifier = 'attachment';
  endpoint = '/api/attachments';

  cache = 60 * 60 * 12;

  forItem(attachableId, attachableType) {
    return this.query(
      {
        attachable_id: attachableId,
        attachable_type: attachableType,
      },
      (a) => {
        return (
          a.attachable_id === attachableId &&
          a.attachable_type === attachableType
        );
      },
    );
  }
}
