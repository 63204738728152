import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { Observable } from 'rxjs';

import { CurrentUserService } from '../../core/current-user.service';
import { TaskService } from '../actions/task.service';
import { DispoCombinedTaskCollection } from '../collections/combined_task';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuDate {
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private taskActions: TaskService,
    private combinedTaskCollection: DispoCombinedTaskCollection,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/task.actions.hide_all_tasks',
        'dispo/task.actions.show_all_tasks',
        'dispo/task.actions.request_all_tasks',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  // --------------
  // Public Methods
  // --------------

  itemsForDate(date: Date): Observable<any[]> {
    return this.combinedTaskCollection.forDate(date).pipe(
      map((tasks) => {
        if (tasks.length === 0) {
          return [];
        }

        const draftTasks = tasks.filter((t) => t.state === 'draft');
        const publicTasks = tasks.filter((t) => t.state === 'open');
        const publicActions = [
          {
            label: this.translations['dispo/task.actions.hide_all_tasks'],
            icon: 'eye-invisible',
            visible:
              publicTasks.length > 0 &&
              this.currentUser.hasAccessToSection('views.dispo.task.hide'),
            command: () => {
              this.taskActions.setHidden(tasks);
            },
          },
          {
            label: this.translations['dispo/task.actions.show_all_tasks'],
            icon: 'eye',
            visible:
              draftTasks.length > 0 &&
              this.currentUser.hasAccessToSection('views.dispo.task.show'),
            command: () => {
              this.taskActions.setPublic(tasks);
            },
          },
          {
            label: this.translations['dispo/task.actions.request_all_tasks'],
            icon: 'wifi',
            visible:
              publicTasks.length > 0 &&
              this.currentUser.hasAccessToSection('views.dispo.task.confirm'),
            command: () => {
              this.taskActions.requestConfirmation(tasks);
            },
          },
        ];
        return [
          ...(publicActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: format(date, 'PP'),
                },
                ...publicActions,
              ]
            : []),
        ];
      }),
    );
  }
}
