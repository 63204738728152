import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  TcFeedbackRequestsHasStatusPipe,
  TcFeedbackRequestsOfTypePipe,
  TcFeedbackRequestsOnStatusPipe,
  TcFeedbackRequestSortByPriorityPipe,
} from './feedback-requests';
import { TcAssignmentsStateClassesDirective } from './assignment-state-classes.directive';

@NgModule({
  declarations: [
    TcAssignmentsStateClassesDirective,
    TcFeedbackRequestsHasStatusPipe,
    TcFeedbackRequestsOfTypePipe,
    TcFeedbackRequestsOnStatusPipe,
    TcFeedbackRequestSortByPriorityPipe,
  ],
  exports: [
    TcAssignmentsStateClassesDirective,
    TcFeedbackRequestsHasStatusPipe,
    TcFeedbackRequestsOfTypePipe,
    TcFeedbackRequestsOnStatusPipe,
    TcFeedbackRequestSortByPriorityPipe,
  ],
  imports: [CommonModule],
})
export class AssignmentsModule {}
