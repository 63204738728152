import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SetsCollection } from '../sets-collection';
import { Vat } from '../types/vat.model';

@Injectable({
  providedIn: 'root',
})
export class VatCollection extends SetsCollection {
  identifier = 'vat';
  endpoint = '/api/preferences/vats';

  cache = 60 * 60 * 12;

  localSearch = ['title'];

  default(): Observable<Vat> {
    return this.all().pipe(
      first(),
      map((vats) => _.find(vats, { default: true })),
    );
  }
}
