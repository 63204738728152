import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { of, ReplaySubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseSelectComponent } from '../base-select.component';

@Component({
  selector: 'tc-hub-remote-select',
  templateUrl: './remote-select.component.html',
  styleUrls: [
    '../base-select.component.scss',
    './remote-select.component.scss',
  ],
})
export class RemoteSelectComponent extends BaseSelectComponent {
  @Input() label: string;

  /**
   * If provided, an option is added to the select list with a -1 id value
   * and the label set to the passed string.
   */
  @Input() createNewLabel: string;

  @Input() set url(val: string) {
    if (!val) {
      this.items$.next([]);
      this.loading = false;
      return;
    }

    this.loading = true;
    this.http
      .get(val)
      .pipe(
        catchError(() => of({ data: [], meta: {} })),
        tap(() => (this.loading = false)),
        map((items: { data: any; meta: any }) => {
          return items.data.map(this.decorate);
        }),
      )
      .subscribe({
        next: (items) => {
          if (this.createNewLabel) {
            items.unshift({
              id: -1,
              title: this.createNewLabel,
            });
          }

          this.items$.next(items);
        },
      });
  }

  items$: ReplaySubject<any> = new ReplaySubject(1);

  @Input() decorate = (item) => item;

  constructor(private http: HttpClient) {
    super();
    this.loading = false;
  }
}
