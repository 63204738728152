import { Pipe, PipeTransform } from '@angular/core';

/**
 * Source:
 * https://www.fueltravel.com/blog/migrating-from-angular-1-to-2-part-1-pipes/
 */

@Pipe({
  name: 'tcOrderBy',
})
export class OrderByPipe implements PipeTransform {
  static _orderByComparator(a, b): number {
    if (
      isNaN(parseFloat(a)) ||
      !isFinite(a) ||
      isNaN(parseFloat(b)) ||
      !isFinite(b)
    ) {
      //Isn't a number so lowercase the string to properly compare
      if (a?.toLowerCase() < b?.toLowerCase()) return -1;
      if (a?.toLowerCase() > b?.toLowerCase()) return 1;
    } else {
      //Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }

    return 0; //equal each other
  }

  transform(input: unknown, args = []) {
    if (!Array.isArray(input)) return input;

    const inputToSort = [...input];

    const config = args[0] ?? '+';

    if (
      !Array.isArray(config) ||
      (Array.isArray(config) && config.length == 1)
    ) {
      const propertyToCheck: string = !Array.isArray(config)
        ? config
        : config[0];

      const desc = propertyToCheck.substr(0, 1) == '-';

      //Basic array
      if (
        !propertyToCheck ||
        propertyToCheck == '-' ||
        propertyToCheck == '+'
      ) {
        const sorted = inputToSort.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
        return !desc ? sorted : sorted.reverse();
      } else {
        const property: string =
          propertyToCheck.substr(0, 1) == '+' ||
          propertyToCheck.substr(0, 1) == '-'
            ? propertyToCheck.substr(1)
            : propertyToCheck;

        return inputToSort.sort((a, b) => {
          return !desc
            ? OrderByPipe._orderByComparator(a[property], b[property])
            : -OrderByPipe._orderByComparator(a[property], b[property]);
        });
      }
    } else {
      //Loop over property of the array in order and sort
      return inputToSort.sort((a, b) => {
        for (let i = 0; i < config.length; i++) {
          const desc = config[i].substr(0, 1) == '-';
          const property =
            config[i].substr(0, 1) == '+' || config[i].substr(0, 1) == '-'
              ? config[i].substr(1)
              : config[i];

          const comparison = !desc
            ? OrderByPipe._orderByComparator(a[property], b[property])
            : -OrderByPipe._orderByComparator(a[property], b[property]);

          //Don't return 0 yet in case of needing to sort by next property
          if (comparison != 0) return comparison;
        }

        return 0; //equal each other
      });
    }
  }
}
