<tc-form-nz-picker-base [picker]="timePicker">
  <nz-time-picker
    #timePicker
    [nzFormat]="format"
    [nzAllowEmpty]="allowClear"
    [nzHourStep]="1"
    [nzMinuteStep]="1"
    [nzSecondStep]="1"
    [nzDefaultOpenValue]="defaultOpenValue"
    [(ngModel)]="value"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onModelChange($event)"
  ></nz-time-picker>
</tc-form-nz-picker-base>
