import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared';

import { EmployeeGridAssignmentComponent } from './employee-grid-assignment/employee-grid-assignment.component';
import { EmployeeGridInvitationComponent } from './employee-grid-invitation/employee-grid-invitation.component';
import { EmployeeGridAvailabilityComponent } from './employee-grid-availability/employee-grid-availability.component';
import { EmployeeGridTimeBalanceComponent } from './employee-grid-time-balance/employee-grid-time-balance.component';
import { EmployeesGridHeaderComponent } from './employees-grid-header/employees-grid-header.component';
import { EmployeesGridItemTogglerComponent } from './employees-grid-item-toggler/employees-grid-item-toggler.component';
import { EmployeeInfoBtnComponent } from './info-btn/info-btn.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    EmployeeInfoBtnComponent,
    EmployeesGridHeaderComponent,
    EmployeesGridItemTogglerComponent,
    EmployeeGridAvailabilityComponent,
    EmployeeGridAssignmentComponent,
    EmployeeGridInvitationComponent,
    EmployeeGridTimeBalanceComponent,
  ],
  exports: [
    EmployeeInfoBtnComponent,
    EmployeesGridHeaderComponent,
    EmployeesGridItemTogglerComponent,
    EmployeeGridAvailabilityComponent,
    EmployeeGridAssignmentComponent,
    EmployeeGridInvitationComponent,
    EmployeeGridTimeBalanceComponent,
  ],
})
export class DispoEmployeesSharedModule {}
