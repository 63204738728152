import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash-es';

import { AggregationCollection } from '../aggregation.collection';
import { Project } from '../../project.model';
import { Aggregation } from '../aggregation.model';

@Component({
  selector: 'tc-hub-aggregation-base-form',
  templateUrl: './aggregations-base-form.component.html',
  styleUrls: ['./aggregations-base-form.component.scss'],
})
export class TcAggregationsBaseFormComponent implements OnInit, OnChanges {
  formGroup: FormGroup;

  @Input() tcInitialValue: Aggregation;
  @Input() tcProject: Project;

  @Input() triggerSave: boolean;
  @Output() updatedValues: EventEmitter<any> = new EventEmitter();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _aggregationCollection: AggregationCollection,
  ) {}

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this._buildForm();
  }

  ngOnChanges({ triggerSave }: SimpleChanges): void {
    if (triggerSave?.currentValue) {
      this.save();
    }
  }

  // --------------
  // Public Methods
  // --------------
  save() {
    const { type, customer, tariff, pay_grade } = this.tcInitialValue;
    const {
      title,
      typeTitle = type.title,
      customerName = customer.name,
      tariffTitle = tariff.title,
      payGradeTitle = pay_grade.title,
      aggregationGeneral,
      aggregationHandleGeneral,
    } = this.formGroup.getRawValue();

    const payload = {
      ...this.tcInitialValue,
      title,
      type: { ...type, title: typeTitle },
      customer: { ...customer, name: customerName },
      tariff: { ...tariff, title: tariffTitle },
      pay_grade: { ...pay_grade, title: payGradeTitle },
      store: _.merge(
        this.tcInitialValue?.store,
        aggregationGeneral,
        aggregationHandleGeneral,
      ),
    };

    this._aggregationCollection
      .forProject(this.tcProject.id)
      .update(this.tcInitialValue.id, payload);
    this.updatedValues.emit(payload);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm() {
    const { customer_id, tariff_id, pay_grade_id } = this.tcProject;
    const { title, type, customer, locked, tariff, pay_grade, store } =
      this.tcInitialValue ?? {};

    this.formGroup = this._formBuilder.group({
      title: [title, Validators.required],
      typeTitle: [{ value: type.title, disabled: true }],
      aggregationGeneral: [store],
      aggregationHandleGeneral: [store],
    });

    if (customer.id !== customer_id) {
      this.formGroup.addControl(
        'customerName',
        new FormControl({ value: customer.name, disabled: true }),
      );
    }
    if (tariff.id !== tariff_id) {
      this.formGroup.addControl(
        'custometariffTitlerName',
        new FormControl({ value: tariff.title, disabled: true }),
      );
    }
    if (pay_grade.id !== pay_grade_id) {
      this.formGroup.addControl(
        'payGradeTitle',
        new FormControl({ value: pay_grade.title, disabled: true }),
      );
    }

    if (locked) {
      this.formGroup.disable();
    }
  }
}
