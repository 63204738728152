import { Component, Input } from '@angular/core';

import { DispoImport } from '../dispo-import.component';
import { ImportService } from '../../actions/import.service';

@Component({
  selector: 'tc-hub-dim-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class DIMButtonComponent {
  @Input() import: DispoImport;

  constructor(private importActions: ImportService) {}

  public startImport() {
    this.import.projectChangeable = false;
    this.importActions.create(this.import);
  }
}
