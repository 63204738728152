import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { DateUnicodeFormat } from '@timecount/utils';
import { TcFieldSetIntervalSetService } from '@timecount/ui';
import {
  TcConfigService,
  TcIntervalSet,
  TcIntervalWithBreak,
} from '@timecount/core';

import { SlotPos } from '../../../collections/task';
import { LocalSettingsService } from '../../../../core/local-settings.service';

@Component({
  selector: 'tc-hub-dispo-employee-grid-invitation',
  templateUrl: './employee-grid-invitation.component.html',
  styleUrls: ['./employee-grid-invitation.component.scss'],
})
export class EmployeeGridInvitationComponent implements OnInit {
  @Input() invitation: any;
  @Input() expanded: boolean;
  @Input() popoverPlacement = 'top';

  @Input()
  @HostBinding('class.overflows-left')
  overflowsLeft: boolean;

  @Input()
  @HostBinding('class.overflows-right')
  overflowsRight: boolean;

  @HostBinding('style')
  get style() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--item-color: ${this.invitation.plan?.color || 'lightgray'}`,
    );
  }

  timesList: Interval[];
  dateFormat = DateUnicodeFormat.dateTime;

  constructor(
    private sanitizer: DomSanitizer,
    private localSettings: LocalSettingsService,
    private intervalSetService: TcFieldSetIntervalSetService,
    private configService: TcConfigService,
  ) {}

  ngOnInit(): void {
    const { allowMultiBreaks } = this.configService.config.company.times;
    const parsedTimes = this.intervalSetService.parseFromApi(
      this.invitation.task,
    );

    this.timesList = allowMultiBreaks
      ? (parsedTimes as Interval[])
      : (TcIntervalSet.timeSetToIntervalList(
          parsedTimes as TcIntervalWithBreak,
        ) as Interval[]);
  }

  onMenu($event, item_type, item) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({
      item_type: 'DispoInvitationSlot',
      item: [
        Object.assign(new SlotPos(), {
          x: item.task.marker[0],
          y: 0,
          task_id: item.task.id,
          invitation_id: item.id,
          announcement_id: item.announcement_id,
        }),
      ],
    });

    $event.menu_contexts.push({
      item_type: 'DispoInvitation',
      item: [item],
    });

    $event.menu_contexts.push({
      item_type: 'DispoAnnouncement',
      item: [item.announcement],
    });
  }
}
