import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { TcCompactDirective } from '../../../shared';

@Component({
  selector: 'tc-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class TcToggleButtonComponent
  extends TcCompactDirective
  implements ControlValueAccessor
{
  @Input() label: string;
  @Input() color = 'primary';
  @Input() canUnselect = true;
  @Input() expand: string;

  public value = false;
  public isDisabled = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  constructor(private ngControl: NgControl) {
    super();

    this.ngControl.valueAccessor = this;
  }

  // -----------------------
  // Abstract Implementation
  // -----------------------

  writeValue(obj: boolean): void {
    this.value = obj;
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // ---------------
  // Events Handlers
  // ---------------

  onClick(event: MouseEvent): void {
    if (this.canUnselect === false && this.value === true) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.value = !this.value;
      this.onChange(this.value);
    }
  }
}
