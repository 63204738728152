import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-collection-filter-widget',
  templateUrl: './collection-filter-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './collection-filter-widget.component.scss',
  ],
})
export class CollectionFilterWidgetComponent extends TcControlWidgetBaseDirective {}
