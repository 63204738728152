import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[tcCompact]',
})
export class TcCompactDirective {
  @Input()
  @HostBinding('class.is-compact')
  tcCompact = false;
}
