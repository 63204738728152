import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { TcDateTimePickerModule } from '../date-time-picker/date-time-picker.module';
import { TcFormFieldModule } from '../form-field';

import { TcInputIonicComponent } from './input-ionic/input-ionic.component';
import { TcInputWebMaskDirective } from './input-web/input-web-mask.directive';
import { TcInputWebComponent } from './input-web/input-web.component';

@NgModule({
  declarations: [
    TcInputIonicComponent,
    TcInputWebComponent,
    TcInputWebMaskDirective,
  ],
  exports: [TcInputIonicComponent, TcInputWebComponent],
  imports: [SharedModule, TcDateTimePickerModule, TcFormFieldModule],
})
export class TcInputModule {}
