<tc-form-wrapper
  [tcFormTitle]="'timesheet.singular' | translate"
  [primaryButtonLabelKey]="'formulas.actions.test' | translate"
  [isModal]="!!modalReference"
  [tcNoPadding]="true"
  [isLoading]="!formGroup"
  [isSubmitting]="isSubmitting"
  [isInvalid]="formGroup?.invalid"
  (primaryButtonClick)="onSubmit()"
>
  <form
    class="form"
    *ngIf="formGroup"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
  >
    <nz-layout *ngIf="!isLoading; else loading" class="layout">
      <nz-sider nzWidth="240px" nzTheme="light">
        <nz-layout>
          <nz-content class="fields">
            <tc-formulas-fields-list
              [fields]="itemAttrs"
              [calculatedAttributes]="calcAttrs$ | async"
              [(selectCalcAttr)]="selectedCalcAttr"
              (addCalcAttr)="onAddCalcAttr($event)"
              (reorderCalcAttrs)="setData($event)"
            ></tc-formulas-fields-list>
          </nz-content>

          <nz-footer class="add-custom">
            <nz-input-group
              [nzAddOnBefore]="fieldTypeTmpl"
              [nzAddOnAfter]="addButtonTmpl"
            >
              <input
                nz-input
                placeholder="{{ 'formulas.fields.add_custom' | translate }}"
                [(ngModel)]="customFieldValue"
                [ngModelOptions]="{ standalone: true }"
                (keydown.enter)="
                  onAddCalcAttr(customFieldPrefix + customFieldValue);
                  $event.preventDefault()
                "
              />
            </nz-input-group>
            <ng-template #fieldTypeTmpl>
              <nz-select
                class="add-custom-select"
                [(ngModel)]="customFieldPrefix"
                [ngModelOptions]="{ standalone: true }"
              >
                <nz-option nzLabel="_" nzValue="_"></nz-option>
                <nz-option nzLabel="store." nzValue="store."></nz-option>
              </nz-select>
            </ng-template>
            <ng-template #addButtonTmpl>
              <button
                class="add-custom-button"
                type="button"
                [disabled]="customFieldValue === ''"
                nz-button
                nzType="primary"
                nzSearch
                (click)="onAddCalcAttr(customFieldPrefix + customFieldValue)"
              >
                <span nz-icon nzTheme="outline" nzType="plus"></span>
              </button>
            </ng-template>
          </nz-footer>
        </nz-layout>
      </nz-sider>
      <nz-content class="content">
        <nz-layout *ngIf="selectedCalcAttr; else noAttrSelected">
          <nz-layout>
            <nz-header class="header">
              <h3>{{ 'formulas.formula.header' | translate }}</h3>
              <button nz-button (click)="onReset(selectedCalcAttr.field)">
                {{ 'formulas.actions.reset' | translate }}
              </button>
              <button
                nzDanger
                nz-button
                (click)="onRemoveCalcAttr(selectedCalcAttr.field)"
              >
                <span nz-icon nzType="delete" nzTheme="outline"></span>
              </button>
              <button
                *ngIf="showVariables"
                nz-button
                (click)="showVariables = false"
              >
                <span class="trigger" nz-icon [nzType]="'menu-fold'"></span>
              </button>
            </nz-header>
            <nz-content class="editor">
              <tc-code-editor
                *ngIf="selectedCalcAttr"
                type="dentaku"
                [formControl]="formGroup.controls[selectedCalcAttr.field]"
                [knownVariables]="selectedCalcAttr.variables | keysList"
              ></tc-code-editor>
            </nz-content>
          </nz-layout>
          <nz-sider
            nzCollapsible
            [(nzCollapsed)]="showVariables"
            nzBreakpoint="lg"
            [nzTrigger]="null"
            [nzCollapsedWidth]="0"
            class="variables"
            [nzWidth]="400"
            nzTheme="light"
          >
            <nz-header class="header">
              <h3>{{ 'formulas.variables.header' | translate }}</h3>
              <button type="button" nz-button (click)="showVariables = true">
                <span class="trigger" nz-icon [nzType]="'menu-unfold'"></span>
              </button>
            </nz-header>

            <tc-formulas-variables-list
              [variables]="selectedCalcAttr?.variables"
            ></tc-formulas-variables-list>
          </nz-sider>
        </nz-layout>
        <ng-template #noAttrSelected>
          <p>{{ 'formulas.nothing_selected' | translate }}</p>
        </ng-template>
      </nz-content>
    </nz-layout>

    <ng-template #loading>
      <div class="loading">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
      </div>
    </ng-template>
  </form>
</tc-form-wrapper>
