import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { DateUnicodeFormat } from '@timecount/utils';
import { TcFieldSetIntervalSetService } from '@timecount/ui';
import {
  TcConfigService,
  TcIntervalSet,
  TcIntervalWithBreak,
} from '@timecount/core';

import { Assignment } from '../../../collections/assignment.model';
import { LocalSettingsService } from '../../../../core/local-settings.service';

@Component({
  selector: 'tc-hub-dispo-employee-grid-assignment',
  templateUrl: './employee-grid-assignment.component.html',
  styleUrls: ['./employee-grid-assignment.component.scss'],
})
export class EmployeeGridAssignmentComponent implements OnChanges {
  @Input() assignment: Assignment;
  @Input() expanded: boolean;
  @Input() popoverPlacement = 'top';

  @Input()
  @HostBinding('class.overflows-left')
  overflowsLeft: boolean;

  @Input()
  @HostBinding('class.overflows-right')
  overflowsRight: boolean;

  timesList: Interval[];
  trackedTimesList: Interval[];

  @HostBinding('style')
  get style() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--item-color: ${this.assignment.plan?.color || 'lightgray'}`,
    );
  }

  dateFormat = DateUnicodeFormat.dateTime;
  allowOnsiteCheckin = this.configService.config.company.allowOnsiteCheckin;
  allowAppAttendance = this.configService.config.company.allowAppAttendance;

  constructor(
    private sanitizer: DomSanitizer,
    private localSettings: LocalSettingsService,
    private intervalSetService: TcFieldSetIntervalSetService,
    private configService: TcConfigService,
  ) {}

  ngOnChanges({ assignment }: SimpleChanges): void {
    if (assignment) {
      const { allowMultiBreaks } = this.configService.config.company.times;
      const parsedTimes = this.intervalSetService.parseFromApi(
        this.assignment.task,
      );
      const parsedTrackedTimes = this.intervalSetService.parseFromApi(
        this.assignment,
      );

      this.timesList = allowMultiBreaks
        ? (parsedTimes as Interval[])
        : (TcIntervalSet.timeSetToIntervalList(
            parsedTimes as TcIntervalWithBreak,
          ) as Interval[]);

      this.trackedTimesList = allowMultiBreaks
        ? (parsedTrackedTimes as Interval[])
        : (TcIntervalSet.timeSetToIntervalList(
            parsedTrackedTimes as TcIntervalWithBreak,
          ) as Interval[]);
    }
  }

  onMenu($event, item_type, item) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({
      item_type: item_type,
      item: [item],
    });
  }
}
