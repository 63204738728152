import { Timesheet } from '../../timesheets';

export class TimesheetDS extends Timesheet {
  readonly type = 'Timesheet';

  resource: any;
  project: any;

  job: any;
  venue?: any;
}
