<ng-container [formGroup]="innerForm">
  <ng-container *ngIf="platform === 'web'">
    <tc-input-date-web
      class="date-field"
      formControlName="date"
      *ngIf="innerForm.controls.date"
      [label]="
        tcHideLabels
          ? null
          : tcDateLabel || ('dispo/task.attrs.date' | translate)
      "
      [tcConfig]="{ limit: tcConfig.startLimit, required: tcConfig.required }"
    ></tc-input-date-web>

    <tc-interval-with-break-web
      *ngIf="!timesConfig.allowMultiBreaks"
      class="time-fields"
      [formControlName]="controlName"
      [tcHideLabels]="tcHideLabels"
      [tcIsCompact]="tcIsCompact"
      [tcIsHorizontal]="tcIsHorizontal"
      [tcConfig]="tcConfig"
      [tcReferenceDate]="
        (tcReferenceDate | dfnsIsValid)
          ? (tcReferenceDate | dfnsStartOfDay)
          : tcRefetcReferenceDate
      "
      (tcStatusChange)="tcStatusChange.emit($event)"
    ></tc-interval-with-break-web>

    <tc-interval-list-web
      class="list-fields"
      *ngIf="timesConfig.allowMultiBreaks"
      [formControlName]="controlName"
      [maxTotalDuration]="timesConfig.maxTotalDuration"
      [maxItemDuration]="timesConfig.maxItemDuration"
      [tcHideLabels]="tcHideLabels"
      [tcIsCompact]="tcIsCompact"
      [tcIsHorizontal]="tcIsHorizontal"
      [tcReferenceDate]="
        (tcReferenceDate | dfnsIsValid)
          ? (tcReferenceDate | dfnsStartOfDay)
          : tcRefetcReferenceDate
      "
      (tcStatusChange)="tcStatusChange.emit($event)"
    ></tc-interval-list-web>
  </ng-container>

  <ng-container *ngIf="platform === 'ionic'">
    <ion-card>
      <tc-input-date-ionic
        formControlName="date"
        *ngIf="innerForm.controls.date"
        [label]="
          tcHideLabels ? null : tcDateLabel || ('GENERAL.TIME.DATE' | translate)
        "
        [tcConfig]="{ limit: tcConfig.startLimit, required: tcConfig.required }"
      ></tc-input-date-ionic>
    </ion-card>

    <tc-form-group-time-set-ionic
      *ngIf="!timesConfig.allowMultiBreaks"
      [formGroupName]="controlName"
      [tcReferenceDate]="
        (tcReferenceDate | dfnsIsValid)
          ? (tcReferenceDate | dfnsStartOfDay)
          : tcRefetcReferenceDate
      "
      [tcConfig]="tcConfig"
      (tcStatusChange)="tcStatusChange.emit($event)"
    ></tc-form-group-time-set-ionic>

    <tc-interval-list-ionic
      *ngIf="timesConfig.allowMultiBreaks"
      [formControlName]="controlName"
      [maxTotalDuration]="timesConfig.maxTotalDuration"
      [maxItemDuration]="timesConfig.maxItemDuration"
      [tcTimePickerSteps]="tcConfig.timePickerSteps"
      [startLimit]="tcConfig.startLimit"
      [platform]="ionic"
      [tcReferenceDate]="
        (tcReferenceDate | dfnsIsValid)
          ? (tcReferenceDate | dfnsStartOfDay)
          : tcRefetcReferenceDate
      "
      (tcStatusChange)="tcStatusChange.emit($event)"
    ></tc-interval-list-ionic>
  </ng-container>
</ng-container>
