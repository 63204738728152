import { Injectable } from '@angular/core';
import { Deserializer, ExclusionPolicy, Strategy, Type } from 'typeserializer';

import { dateToTzUnix, parseDate } from '@timecount/utils';

import { FrameCollection } from '../../core/frame-collection';
import { generateDateRanges } from '../../core/date_ranges';

@Strategy(ExclusionPolicy.NONE)
export class Plan {
  id: number;
  project_id: number;
  customer_id: number;
  department_id: number;
  department_ids: number[];

  marker: [number, number];

  @Type(Object)
  project: object;

  label: string;
  token: string;
  title: string;
  color: string;
  note: string;

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;

  @Type(Object)
  store: object;
}
@Injectable({
  providedIn: 'root',
})
export class DispoPlanCollection extends FrameCollection {
  type = Plan;
  cache = 600;
  bindLabel = 'label';
  identifier = 'tc_dispo/plan';
  endpoint = '/api/dispo/plans/range';
  localSearch = ['title', 'token'];

  remoteDeleteValidation = true;

  decorate(plan) {
    plan.label = [plan.token, plan.title].filter((a) => a).join(' ');

    return plan;
  }

  fromTemplate(date, template) {
    date = new Date(date.getTime());

    const [starts_at, ends_at] = generateDateRanges(
      date,
      template.base || 'week',
      template.offset,
      template.length,
    );

    const obj = Object.assign(
      {
        starts_at: starts_at,
        ends_at: ends_at,
        marker: [dateToTzUnix(starts_at), dateToTzUnix(ends_at)],
      },
      template,
    );

    delete obj.base;
    delete obj.offset;
    delete obj.length;

    return obj;
  }
}
