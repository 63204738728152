import type { AcornNode } from './acorn-node';
import { unescapeFormula } from './formula-helpers';

const prec = {
  '!=': 5,
  '%': 20,
  '*': 20,
  '+': 10,
  '-': 10,
  '/': 20,
  '<': 5,
  '<=': 5,
  '<>': 5,
  '=': 5,
  '==': 5,
  '>': 5,
  '>=': 5,
  '': 1,
  unary: 30,
};

export function formatNode(
  node: AcornNode,
  ind = true,
  comments: { [id: string]: string },
  maxLen: number,
  prevOp_ = '',
): string {
  const comment = comments[node.start] ?? '';
  if (comment) delete comments[node.start];

  let formattedNode = `${comment && `/* ${comment} */ `}`;

  switch (node.type) {
    case 'Program':
      formattedNode += `${node.body
        .map((b) => formatNode(b, ind, comments, maxLen))
        .join('')}`;
      break;

    case 'ExpressionStatement':
      formattedNode += `${formatNode(node.expression, ind, comments, maxLen)}`;
      break;

    case 'CallExpression':
      formattedNode += formatCallExpressionNode(node, ind, comments, maxLen);
      break;

    case 'UnaryExpression':
      formattedNode += formatUnaryExpressionNode(
        node,
        ind,
        comments,
        maxLen,
        prevOp_,
      );
      break;

    case 'BinaryExpression':
      formattedNode += formatBinaryExpressionNode(
        node,
        ind,
        comments,
        maxLen,
        prevOp_,
      );
      break;

    case 'LogicalExpression':
      formattedNode += formatLogicalExpressionNode(node, ind, comments, maxLen);
      break;

    case 'AssignmentExpression':
      formattedNode += formatAssignmentExpressionNode(
        node,
        ind,
        comments,
        maxLen,
      );
      break;

    case 'MemberExpression':
      formattedNode += formatMemberExpressionNode(node, ind, comments, maxLen);
      break;

    case 'Identifier':
      formattedNode += node.name;
      break;

    case 'Literal':
      formattedNode += node.raw;
      break;

    default:
      throw Error(`Unknown Node type ${node.type}`);
  }

  return formattedNode;
}

function indentFormula(input: string) {
  return input
    .split('\n')
    .map((l) => `  ${l}`)
    .join('\n');
}

function formatCallExpressionNode(
  node: AcornNode,
  ind: boolean,
  comments: { [id: string]: string },
  maxLen: number,
) {
  const args = node.arguments.map((b) => formatNode(b, ind, comments, maxLen));
  const len = unescapeFormula(`${node.callee.name}(${args.join(', ')})`).length;
  if (len > maxLen && ind)
    return `${node.callee.name}(\n${indentFormula(args.join(',\n'))}\n)`;
  return `${node.callee.name}(${args.join(', ')})`;
}

function formatUnaryExpressionNode(
  node: AcornNode,
  ind: boolean,
  comments: { [id: string]: string },
  maxLen: number,
  prevOp_: string,
) {
  const prevOp = prevOp_ ?? '';
  const argument = formatNode(
    node.argument,
    ind,
    comments,
    maxLen,
    node.operator,
  );
  const min = `${node.operator} ${argument}`;
  const pars = !(prec[prevOp] && prec['unary']) || prec[prevOp] > prec['unary'];
  return pars ? `(${min})` : min;
}

function formatBinaryExpressionNode(
  node: AcornNode,
  ind: boolean,
  comments: { [id: string]: string },
  maxLen: number,
  prevOp_: string,
) {
  const prevOp = prevOp_ ?? '';
  const left = formatNode(node.left, ind, comments, maxLen, node.operator);
  const right = formatNode(node.right, ind, comments, maxLen, node.operator);
  const min = `${left} ${node.operator} ${right}`;
  const pars =
    !(prec[prevOp] && prec[node.operator]) ||
    prec[prevOp] > prec[node.operator];
  const short = pars ? `(${min})` : min;
  if (unescapeFormula(short).length > maxLen && ind) {
    const long = `${left}\n${node.operator} ${right}`;
    return `${pars ? `(\n${indentFormula(long)}\n)` : long}`;
  }
  return short;
}

function formatLogicalExpressionNode(
  node: AcornNode,
  ind: boolean,
  comments: { [id: string]: string },
  maxLen: number,
) {
  const left = formatNode(node.left, ind, comments, maxLen, node.operator);
  const right = formatNode(node.right, ind, comments, maxLen, node.operator);
  const op: string =
    {
      '&&': 'AND',
      '||': 'OR',
    }[node.operator] ?? '';
  const short = `${op}(${left}, ${right})`;
  if (unescapeFormula(short).length > maxLen && ind)
    return `${op}(\n${left},\n${right}\n)`;
  return short;
}

function formatAssignmentExpressionNode(
  node: AcornNode,
  ind: boolean,
  comments: { [id: string]: string },
  maxLen: number,
) {
  const left = formatNode(node.left, ind, comments, maxLen);
  const right = formatNode(node.right, ind, comments, maxLen);
  const short = `${left} ${node.operator} ${right}`;
  if (unescapeFormula(short).length > maxLen && ind)
    return `${left}\n${node.operator} ${right}`;
  return short;
}

function formatMemberExpressionNode(
  node: AcornNode,
  ind: boolean,
  comments: { [id: string]: string },
  maxLen: number,
) {
  return `${formatNode(node.object, ind, comments, maxLen)}.${formatNode(
    node.property,
    ind,
    comments,
    maxLen,
  )}`;
}
