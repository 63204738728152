import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalService } from '@timecount/ui';

import { TimebalanceDocumentComponent } from '../document.component';

@Component({
  selector: 'tc-hub-tb-tpl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class TBDOCButtonComponent implements OnDestroy {
  @Input() template: any;
  @Input() date: Date;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  private translations: { [key: string]: string } = {};

  private destroyed$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {
    this.translateService
      .get(['attachment_release.batch.title'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  generate() {
    const ref = this.modalService.open(TimebalanceDocumentComponent, {
      data: {
        template: this.template,
        date: this.date,
        section: 'create',
      },
      modalTitle: this.translations['attachment_release.batch.title'],
    });

    ref.tcClose.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (event) => {
        this.signal.emit(event);
      },
    });
  }
}
