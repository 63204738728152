import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash-es';
import { map, takeUntil } from 'rxjs';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';
import { TcInterval } from '@timecount/core';
import { getUTCDate } from '@timecount/utils';

import { AggregationCollection } from '../aggregation.collection';
import { Aggregation } from '../aggregation.model';
import { CurrentUserService } from '../../../core/current-user.service';
import { AggregationImporterSettings } from '../aggregation-importer-settings.model';
import { ProjectSetsCollection } from '../../project-sets.collection';
import { Project } from '../../project.model';

@Component({
  selector: 'tc-hub-aggregations-import-form',
  templateUrl: './aggregations-import-form.component.html',
  styleUrls: ['./aggregations-import-form.component.scss'],
})
export class TcAggregationsImportFormComponent
  extends BaseModalComponent
  implements OnInit, OnDestroy
{
  formGroup: FormGroup;
  @Input() tcInitialValue: Aggregation;

  isModal = false;
  isSubmitting = false;

  @Input() canScheduleAggregations =
    this._currentUserService.hasAccessToFeature('scheduled_aggregations');

  get importer() {
    return this.formGroup.get('importer').value;
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _aggregationCollection: AggregationCollection,
    private _currentUserService: CurrentUserService,
    private _projectCollection: ProjectSetsCollection,
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super(config, modalRef);
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    if (this.config?.data) {
      Object.assign(this, this.config.data);
    }

    this._buildForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // --------------
  // Public Methods
  // --------------

  async save() {
    const { project_id, id, scheduled, imported_at } = this.tcInitialValue;
    const collection = this._aggregationCollection.forProject(project_id);

    const payload = await this._getPayload(this.formGroup.value);

    this.isSubmitting = true;

    let resp;

    if (id) {
      if (scheduled) {
        resp = collection.update(id, payload);
      } else {
        resp = collection.action('import_entries', id, payload);
      }
    } else {
      resp = collection.create(payload);
    }

    resp.subscribe(
      () => {
        this.modalRef?.close();
      },
      () => {
        this.modalRef?.close();
      },
    );
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm() {
    const {
      id,
      importer,
      title,
      project_id,
      store,
      importer_settings,
      scheduled,
      scheduled_at,
      scheduled_target_state,
      imported_at,
    } = this.tcInitialValue || {};
    const { starts_at, ends_at, aggregate } =
      this.tcInitialValue?.importer_settings || {};
    const { includes, ...restImporterSettings } = importer_settings ?? {};
    const intervalValue = {
      start: getUTCDate(starts_at),
      end: getUTCDate(ends_at),
    };

    this.formGroup = this._formBuilder.group({
      importer: [importer],
      title: [title, Validators.required],
      project_id: [project_id],
      aggregation_id: [
        id,
        importer === 'aggregation' ? Validators.required : null,
      ],
      aggregate: [aggregate],
      includeServiceEntries: [includes?.includes('billing_service_entries')],
      includeWorkEntries: [includes?.includes('billing_work_entries')],
      aggregationImport: [store],
      aggregationHandleImport: [store],
      filterControl: [restImporterSettings],
      scheduleControl: [{ scheduled, scheduled_at, scheduled_target_state }],
    });

    if (imported_at && !this.isModal) {
      this.formGroup.disable();
    }

    this._toggleIntervalControl(importer, intervalValue);

    this.formGroup
      .get('importer')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((importer) => {
        this._toggleIntervalControl(importer, intervalValue);

        const aggregationControl = this.formGroup.get('aggregation_id');
        importer === 'aggregation'
          ? aggregationControl.setValidators(Validators.required)
          : aggregationControl.clearValidators();
        aggregationControl.updateValueAndValidity();
        if (this.isModal) {
          this.onSignal({ action: 'resize' });
        }

        const includeServiceEntries = this.formGroup.get(
          'includeServiceEntries',
        );
        const includeWorkEntries = this.formGroup.get('includeWorkEntries');

        if (includeServiceEntries.value) {
          includeServiceEntries.setValue(false);
        }
        if (includeWorkEntries.value) {
          includeWorkEntries.setValue(false);
        }
      });

    this.formGroup
      .get('project_id')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.formGroup.get('aggregation_id').reset();
      });
  }

  aggregationDecorator(agg: Aggregation): Aggregation {
    agg = Object.assign({}, agg);
    agg.label = [agg.token, agg.title].filter((a) => a).join(' ');

    return agg;
  }

  private _toggleIntervalControl(importer: string, initialValue: Interval) {
    if (importer === 'dispo_availability') {
      this.formGroup.addControl(
        'interval',
        new FormControl(initialValue, Validators.required),
      );
    } else {
      this.formGroup.removeControl('interval');
    }
  }

  private async _getPayload(formValues): Promise<Aggregation> {
    const { store, type, type_id, id } = this.tcInitialValue ?? {};
    let projectUpdated = {};
    const {
      importer,
      includeServiceEntries,
      includeWorkEntries,
      title,
      interval,
      project_id,
      aggregationImport,
      aggregationHandleImport,
      scheduleControl,
      filterControl,
      ...restBasis
    } = formValues ?? {};
    const {
      intervalFilter,
      events,
      schedules,
      tasks,
      isSubFilter,
      skus,
      resources,
      project,
      ...restFilter
    } = filterControl ?? {};

    const mergedStore = _.merge(
      store,
      aggregationImport,
      aggregationHandleImport,
    );

    if (project_id) {
      projectUpdated = await this._projectCollection
        .find(project_id)
        .pipe(
          map((value: Project) => ({
            id: value.id,
            title: value.title,
            token: value.token,
          })),
        )
        .toPromise();
    }

    const importer_settings = new AggregationImporterSettings({
      store: mergedStore,
      includes: [
        includeServiceEntries && 'billing_service_entries',
        includeWorkEntries && 'billing_work_entries',
      ].filter(Boolean),
      resources,
      skus,
      ...((interval || intervalFilter) &&
        TcInterval.formatForApi(interval ?? intervalFilter)),
      ...restBasis,
      ...restFilter,
      ...(isSubFilter
        ? {
            event_for_sub_filter: events,
            schedules: [schedules],
            tasks: [tasks],
          }
        : { events }),
      project_id: project_id ?? this.tcInitialValue.project_id,
      project: projectUpdated,
    });

    return {
      id,
      project_id: this.tcInitialValue.project_id,
      store: mergedStore,
      type,
      importer,
      title,
      type_id,
      importer_settings,
      ...scheduleControl,
    };
  }
}
