import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from '@angular/forms';

import { TcFormGroupIntervalService } from '../../../form-groups/form-group-interval';
import { TcFieldsetIntervalListBaseDirective } from '../interval-list-base.directive';

@Component({
  selector: 'tc-interval-list-web',
  templateUrl: './interval-list-web.component.html',
  styleUrls: ['./interval-list-web.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TcFielsetIntervalListWebComponent,
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TcFielsetIntervalListWebComponent,
      multi: true,
    },
  ],
})
export class TcFielsetIntervalListWebComponent extends TcFieldsetIntervalListBaseDirective {
  @Input() tcHideLabels: boolean;
  @Input() tcIsHorizontal: boolean;
  @HostBinding('class.is-compact') @Input() tcIsCompact: boolean;

  set expanded(value: boolean) {
    this._expanded = value;
  }
  get expanded(): boolean {
    // By default, the list should be expanded when not horizontal and collapsed
    // when horizontal.
    return this._expanded ?? !this.tcIsHorizontal;
  }
  private _expanded: boolean;

  @ViewChildren('listItems', { read: ElementRef })
  listItems: QueryList<ElementRef>;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public intervalService: TcFormGroupIntervalService,
    public formGroupIntervalService: TcFormGroupIntervalService,
  ) {
    super(formBuilder, intervalService, formGroupIntervalService);
  }

  splitTime(index: number): void {
    super.splitTime(index);

    this.listItems
      .toArray()
      [index].nativeElement.querySelector('.group-item.end .tc-input')
      .focus();
  }

  addAfter(index?: number) {
    super.addAfter(index);

    const indexToInsert = index ? index + 1 : this.timesList.length - 1;

    setTimeout(() => {
      this.listItems
        .toArray()
        [indexToInsert].nativeElement.querySelector(
          '.group-item.start .tc-input',
        )
        .focus();
    }, 100);
  }
}
