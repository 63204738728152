import { Component, OnInit } from '@angular/core';

import { BaseModalComponent } from '@timecount/ui';

import { Timesheet } from './timesheet.model';

@Component({
  selector: 'tc-hub-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss'],
})
export class TimesheetComponent extends BaseModalComponent implements OnInit {
  timesheets: Timesheet[] = [];

  tabs = {
    general: 0,
  };

  ngOnInit() {
    super.ngOnInit();
    this.timesheets = this.config.data.timesheets;
  }
}
