import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  add,
  compareAsc,
  endOfDay,
  isAfter,
  isBefore,
  max,
  min,
  startOfDay,
  sub,
} from 'date-fns';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import {
  TcFieldSetIntervalSetService,
  TcFormGroupIntervalService,
} from '@timecount/ui';
import { dateToTzUnix } from '@timecount/utils';

import { ResourceTemplateCollection } from '../../../core/collections/resource_template';
import { DispoPlanCollection, Plan } from '../../collections/plan';
import { DispoTaskCollection } from '../../collections/task';
import { ProjectCollection } from '../../../projects/project.collection';
import { DispoScheduleCollection } from '../../collections/schedule';
import { DispoAssignmentCollection } from '../../collections/assignment';
import { DispoFocusService } from '../../dispo-focus.service';
import { dateToString } from '../../../core/helpers';
import { DispoImport } from '../dispo-import.component';

@Component({
  selector: 'tc-hub-dim-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
})
export class DIMStep4Component implements OnInit, OnDestroy {
  @Input() import: DispoImport;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;

  initialLimit: Interval;
  startLimit: Interval;
  endLimit: Interval;

  loading = false;

  attachToPlan = false;
  planUrl;
  planDefaultTemplate;

  subs = [];
  private destroyed$ = new Subject<void>();

  readonly overlapDays = window.config.company.dispo_project_overlap_days;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private assignmentCollection: DispoAssignmentCollection,
    private scheduleCollection: DispoScheduleCollection,
    private taskCollection: DispoTaskCollection,
    private planCollection: DispoPlanCollection,
    private projectCollection: ProjectCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private formGroupIntervalService: TcFormGroupIntervalService,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
    private focusService: DispoFocusService,
    @Inject('Flash') private flash,
  ) {}

  ngOnInit(): void {
    this.planUrl = `/api/projects/${this.import.project_id}/events`;
    this.buildForm(this.import);

    this.projectCollection
      .find(this.import.project_id)
      .pipe(first())
      .subscribe((project: any) => {
        this.form.patchValue({
          title: project.title,
        });
      });

    this.resourceTemplateCollection
      .setForResource('dispo.plans')
      .all()
      .pipe(first())
      .subscribe((templates) => {
        this.planDefaultTemplate = (
          templates.find((t) => t.default) || { template: {} }
        ).template;
        this.form.patchValue({
          color: this.planDefaultTemplate.color || '#000000',
        });
      });

    this.signal.emit({ action: 'resize' });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  prev() {
    this.signal.emit({ action: 'prev', import: this.import });
  }

  save() {
    this.loading = true;
    const formValue = this.form.value;
    const focusDate = new Date(
      (
        this.fieldSetIntervalSetService.getWholeSetInterval(
          this.import.entries[0]?.times,
        )?.start as Date
      )?.getTime(),
    );
    focusDate.setHours(12, 0, 0, 0);

    const settings = {
      aggregation_id: this.import.aggregation_id,
      target: this.import.target,
      template: this.import.template,
      entries: this.import.entries.map((entry) => {
        const { times, ...restOfValues } = entry;

        const formattedTimes =
          this.fieldSetIntervalSetService.formatValueToApi(times);

        return {
          ...restOfValues,
          ...formattedTimes,
        };
      }),
    };

    if (formValue.plan_id && formValue.plan_id !== -1) {
      settings['plan_id'] = formValue.plan_id;
    } else {
      const formSettings = {
        project_id: this.import.project_id,
        color: formValue.color,
        title: formValue.title,
        starts_at: dateToString(formValue.dateInterval.start),
        ends_at: dateToString(formValue.dateInterval.end),
      };

      const plan = Object.assign(
        new Plan(),
        this.planDefaultTemplate,
        formSettings,
      );
      settings['plan'] = plan;
    }

    this.planCollection.action('import', undefined, settings).subscribe({
      next: () => {
        this.loading = false;

        this.focusService
          .range()
          .pipe(first())
          .subscribe((marker) => {
            const focusDateInt = dateToTzUnix(focusDate);
            if (marker[0] > focusDateInt || marker[1] < focusDateInt) {
              this.focusService.setDate(focusDate);
            }
          });

        this.assignmentCollection.refreshCollection();
        this.taskCollection.refreshCollection();
        this.scheduleCollection.refreshCollection();
        this.planCollection.refreshCollection();

        this.flash.create('Import wurde erfolgreich durchgeführt', 'success');

        this.signal.emit({ action: 'close' });
      },
      error: () => {
        this.flash.create(
          'Import konnte leider nicht durchgeführt werden',
          'warning',
        );
        this.loading = false;
      },
    });
  }

  private buildForm(dispoImport: DispoImport) {
    const entriesIntervals = dispoImport.entries.map((entry) =>
      this.fieldSetIntervalSetService.getWholeSetInterval(entry.times),
    );

    entriesIntervals.sort((a, b) => compareAsc(a.start, b.start));

    this.initialLimit = entriesIntervals.reduce((prev, curr) => ({
      start: startOfDay(
        isBefore(curr.start, prev.start) ? curr.start : prev.start,
      ),
      end: endOfDay(isAfter(curr.start, prev.start) ? curr.start : prev.start),
    }));

    this.projectCollection
      .get(dispoImport.project_id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project: any) => {
        this.startLimit = {
          start: sub(project.starts_at, { days: this.overlapDays }),
          end: min(entriesIntervals.map((entry) => entry.start)),
        };
        this.endLimit = {
          start: max(entriesIntervals.map((entry) => entry.start)),
          end: add(project.ends_at, { days: this.overlapDays }),
        };
      });

    this.form = this.formBuilder.group({
      plan_id: [
        -1, // Create new as default.
        [Validators.required],
      ],
      title: [undefined, [Validators.required]],
      color: ['#000000'],
      dateInterval: [this.initialLimit],
    });

    this.form.get('plan_id').valueChanges.subscribe((plan_id) => {
      this.attachToPlan = plan_id !== -1;
      this.signal.emit({ action: 'resize' });

      Object.keys(this.form.controls)
        .filter((key) => key !== 'plan_id')
        .forEach((key) => {
          const control = this.form.get(key);
          this.attachToPlan ? control.disable() : control.enable();
        });
    });
  }
}
