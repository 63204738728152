import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { startOfDay } from 'date-fns';

import { TcFieldSetIntervalSetService, TcInputValidators } from '@timecount/ui';

import { DispoAssignmentCollection } from '../../../dispo/collections/assignment';
import { Assignment } from '../../../dispo/collections/assignment.model';
import { ValidationService } from '../../../dispo/collections/validation.service';
import { EntryType } from '../../../core/types/entry-type';
import { Action } from '../../../core/types/action';
import { ActionType } from '../../../core/types/action-type';
import { DispoPlanCollection, Plan } from '../../collections/plan';
import { DispoTaskCollection, Task } from '../../collections/task';
import { Validation } from '../../../core/types/validation';
import { ValidationErrorLevel } from '../../../core/types/validation-error-level';

@Component({
  selector: 'tc-hub-dt-move',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss'],
})
export class DTMoveComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<unknown> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  dateLimit: Interval;

  private destroyed$ = new Subject<void>();
  private translations: { [key: string]: string } = {};

  constructor(
    private taskCollection: DispoTaskCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private validationService: ValidationService,
    private planCollection: DispoPlanCollection,
    private formBuilder: UntypedFormBuilder,
    private fieldSetIntervalService: TcFieldSetIntervalSetService,
    private translateService: TranslateService,
    @Inject('Flash') private flash,
  ) {
    this.translateService
      .get([
        'errors.messages.validation_resolved',
        'errors.messages.validation_not_resolved',
        'dispo/assignment.errors.cannot_move_confirmed_assignment',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnInit() {
    this._buildForm();
    this._setDateLimit(this.plan);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  validate() {
    // custom validators
  }

  move() {
    this.loading = true;

    const payload = this.getFormValues();

    // TODO: [DEV-1877] move this to a service and share behaviour with move-scheduled
    this.assignmentCollection
      .forTask(this.task)
      .pipe(
        first(),
        mergeMap((assignments: Assignment[]) => {
          if (assignments.length) {
            const movedAssignments = assignments.map((a) =>
              this.assignmentCollection.move(a, a.position, payload),
            );

            const actions = movedAssignments.map((assignment) => {
              return <Action>{
                id: uuid(),
                entry: assignment,
                entry_type: EntryType.Assignment,
                type: ActionType.confirm,
                errors: [],
                validations: (
                  item: Assignment,
                  _stack: Assignment[],
                ): Observable<Validation[]>[] => {
                  const validations$: Observable<Validation[]>[] = [];

                  if (item.times_state === 'confirmed') {
                    validations$.push(
                      of([
                        Object.assign(new Validation(), {
                          id: uuid(),
                          message:
                            this.translations[
                              'dispo/assignment.errors.cannot_move_confirmed_assignment'
                            ],
                          type: ValidationErrorLevel.critical,
                        }),
                      ]),
                    );
                  }

                  return validations$;
                },
              };
            });

            return this.validationService.run(actions, false).pipe(
              mergeMap(() => this.taskCollection.update(this.task.id, payload)),
              tap(() => {
                this.flash.create(
                  this.translations['errors.messages.validation_resolved'],
                  'success',
                  6000,
                );
              }),
              map(() => 100),
              catchError((error: unknown) => {
                this.flash.create(
                  this.translations['errors.messages.validation_not_resolved'],
                  'error',
                  6000,
                );

                return of(error);
              }),
            );
          } else {
            return this.taskCollection
              .update(this.task.id, payload)
              .pipe(map(() => 100));
          }
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((delay: number) => {
        if (delay) {
          this.signal.emit({ action: 'close' });
        }
      });
  }

  copy() {
    this.loading = true;

    const payload = this.getFormValues({ isCopy: true });

    this.taskCollection.create(payload).subscribe({
      next: () => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private _buildForm() {
    const timesValue = this.fieldSetIntervalService.parseFromApi(this.task);

    this.form = this.formBuilder.group({
      date: [startOfDay(this.task.starts_at), [Validators.required]],
      times: [timesValue, [Validators.required]],
      plan_id: [this.task.plan_id, [Validators.required]],
      size: [
        this.task.size,
        [
          Validators.required,
          TcInputValidators.minMaxNumber(
            0,
            window.config.company.dispo_task_max_size,
          ),
        ],
      ],
    });

    this.form
      .get('plan_id')
      .valueChanges.pipe(
        takeUntil(this.destroyed$),
        switchMap((planId) => this.planCollection.get(planId)),
      )
      .subscribe((plan: Plan) => this._setDateLimit(plan));
  }

  private _setDateLimit({ starts_at, ends_at }: Plan): void {
    this.dateLimit = {
      start: starts_at,
      end: ends_at,
    };
  }

  private getFormValues(
    options: {
      isCopy?: boolean;
      withAssignments?: boolean;
    } = {},
  ): Task {
    const { times, date, ...restOfValues } = this.form.value;

    const formattedTimes = this.fieldSetIntervalService.formatValueToApi(
      times,
      { includeMarker: true },
    );

    const values = Object.assign({}, this.task, restOfValues, formattedTimes);

    if (options.isCopy) {
      delete values.id;
    }

    if (options.withAssignments) {
      // TODO: implement copy with assignments
      // this.taskCollection.copyWithAssingments(values);
    }

    return values;
  }
}
