import { Component } from '@angular/core';
import { ContextMenuSub } from 'primeng/contextmenu';

import { ContextMenuComponent } from './contextmenu.component';

@Component({
  selector: 'tc-hub-context-menu-sub',
  templateUrl: './contextmenu-sub.component.html',
  styleUrls: ['./contextmenu-sub.component.scss'],
})
export class ContextMenuSubComponent extends ContextMenuSub {
  constructor(contextMenu: ContextMenuComponent) {
    super(contextMenu);
  }
}
