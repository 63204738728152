<div class="sf-group tc-select">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>
  <div class="sf-controls">
    <div class="sf-elements" *ngIf="schema.type !== 'array'">
      <ng-select
        [formControl]="control"
        [items]="bindValue ? (items$ | async | keyvalue) : (items$ | async)"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        searchable="true"
        class="sf-element"
        [inputAttrs]="{ class: 'form-control' }"
      >
      </ng-select>
    </div>
  </div>
</div>
