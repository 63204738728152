import { Component, OnInit } from '@angular/core';
import { finalize, map, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';
import { TcApiResponse } from '@timecount/core';

import { Log } from './log.model';

@Component({
  selector: 'tc-hub-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent extends BaseModalComponent implements OnInit {
  resourceInfo: { resource_id: number; resource_type: string };
  logs$: Observable<Log[]>;

  loading = true;

  constructor(
    protected config: ModalConfig,
    protected modalRef: ModalRef,
    private httpClient: HttpClient,
  ) {
    super(config, modalRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.resourceInfo = this.config.data;

    const { resource_id, resource_type } = this.config.data;

    const params = new HttpParams({
      fromObject: {
        'filter[resource_id]': resource_id,
        'filter[resource_type]': resource_type,
      },
    });

    this.logs$ = this.httpClient
      .get<TcApiResponse<Log[]>>('/api/preferences/trails', { params })
      .pipe(
        map(({ data }) =>
          data
            .map(({ date, event, user }) => {
              return {
                date: new Date(date),
                event,
                user,
              };
            })
            .sort((a, b) => b.date.getTime() - a.date.getTime()),
        ),
        finalize(() => {
          this.loading = false;
          this.modalRef.resize();
        }),
      );
  }
}
