import { snakeCase } from 'lodash-es';
import { parseISO } from 'date-fns';

import { PostType } from './post-type';

export class Post {
  id: number;
  title: string;
  text: string;
  type: PostType;
  createdAt: Date;
  updatedAt: Date;

  static parseFromApi(data: unknown): Post {
    const { id, title, text, type } = data as Record<string, unknown>;

    return {
      id: id as number,
      title: title as string,
      text: text as string,
      type: PostType[snakeCase(type as string).toUpperCase()],
      createdAt: parseISO(data[`created_at`]),
      updatedAt: parseISO(data[`updated_at`]),
    };
  }
}
