import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { TcFieldSetIntervalSetService } from '@timecount/ui';
import { TcConfigService } from '@timecount/core';

import {
  Supplement,
  SupplementCollection,
} from '../../core/collections/supplement';
import { CurrentUserService } from '../../core/current-user.service';
import {
  generateFieldsForSupplements,
  generateSupplementsFromForm,
} from '../../core/supplements';
import { DispoTaskCollection } from '../../dispo/collections/task';
import { ValidationService } from '../../dispo/collections/validation.service';
import { Action } from '../../core/types/action';
import { ActionType } from '../../core/types/action-type';
import { EntryType } from '../../core/types/entry-type';
import { TimesheetCollection } from '../timesheet.collection';

@Component({
  selector: 'tc-hub-ts-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class TSGeneralComponent implements OnInit {
  @Input() timesheet: any;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;
  timeTrackingPossible = false;

  supplements: Supplement[];

  allowsPlus24Hours =
    this.configService.config.company.times.allowDoubledWorkingDay;

  constructor(
    private timesheetCollection: TimesheetCollection,
    private taskService: DispoTaskCollection,
    private supplementService: SupplementCollection,
    private formBuilder: UntypedFormBuilder,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
    private validationService: ValidationService,
    private currentUser: CurrentUserService,
    private configService: TcConfigService,
  ) {}

  ngOnInit() {
    this.timeTrackingPossible =
      this.timesheet.state !== 'confirmed' ||
      this.currentUser.can('manage', 'Timesheet', ['unlocked']);

    this.supplementService
      .all()
      .pipe(first())
      .subscribe({
        next: (supplements) => {
          this.supplements = supplements.filter(
            (s) => s.visibility === 'visible' || s.visibility === 'fields_only',
          );

          this.buildForm(this.timesheet);

          this.signal.emit({ action: 'resize' });
        },
      });
  }

  save() {
    const form = this.form.value;
    let timesheet = Object.assign({}, this.timesheet);

    this.loading = true;

    if (
      timesheet.state === 'confirmed' &&
      !this.currentUser.can('manage', 'Timesheet')
    ) {
      this.signal.emit({ action: 'close' });
      return;
    }

    if (timesheet.shallow && !form[`tracked_time`]) {
      this.signal.emit({ action: 'close' });
      return;
    }

    timesheet = Object.assign({}, timesheet, {
      state: form['state'] || timesheet.state,
      job_id: form['job_id'] || timesheet.job_id,
      ...this.fieldSetIntervalSetService.formatValueToApi(form.times, {
        includeMarker: true,
      }),
    });

    timesheet.supplements = Object.assign(
      {},
      timesheet.supplements,
      generateSupplementsFromForm(form, this.supplements),
    );

    timesheet.store = Object.assign({}, timesheet.store, form.store);

    const actionType = timesheet.shallow
      ? ActionType.create
      : ActionType.update;

    this.validationService.run([
      <Action>{
        id: uuid(),
        entry: timesheet,
        entry_type: EntryType.Timesheet,
        type: actionType,
        validations: (item, stack) =>
          this.timesheetCollection.remoteValidations(item, stack, actionType),
        errors: [],
      },
    ]);

    this.signal.emit({ action: 'close' });
  }

  toggle() {
    const { tracked_time } = this.form.value;

    const fields = [
      'times',
      'state',
      'store',
      ...this.supplements.map((s) => `supplement_${s.id}`),
    ];

    fields.forEach((f) => {
      const control: AbstractControl = this.form.controls[f];

      if (tracked_time) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }

  private buildForm(timesheet) {
    const initialValues = this.buildFormValues(timesheet);

    let formConfig = {
      times: [initialValues.times, [Validators.required]],
      tracked_time: [
        {
          value: initialValues.tracked_time,
          disabled: !timesheet.shallow,
        },
      ],
      gratis: [
        {
          value: initialValues.gratis,
          disabled: !this.timeTrackingPossible,
        },
      ],
      state: [
        {
          value: initialValues.state,
          disabled: !this.currentUser.can('manage', 'Timesheet', ['unlocked']),
        },
      ],
      job_id: [
        {
          value: initialValues.job_id,
          disabled: !this.timeTrackingPossible,
        },
      ],
      store: [
        {
          value: initialValues.store,
          disabled: !this.timeTrackingPossible,
        },
      ],
    };

    const supplementFields = generateFieldsForSupplements(
      timesheet,
      this.supplements,
      { disabled: !this.timeTrackingPossible },
    );

    formConfig = Object.assign(formConfig, supplementFields);

    this.form = this.formBuilder.group(formConfig);
  }

  private buildFormValues(timesheet): { [key: string]: unknown } {
    return {
      times: this.fieldSetIntervalSetService.parseFromApi(timesheet),
      tracked_time: true,
      gratis: timesheet.gratis,
      state: timesheet.state,
      store: timesheet.store,
      job_id: timesheet.job_id,
    };
  }
}
