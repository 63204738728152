import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { TcButtonIonicComponent } from './button-ionic/button-ionic.component';

@NgModule({
  imports: [SharedModule],
  declarations: [TcButtonIonicComponent],
  exports: [TcButtonIonicComponent],
})
export class TcButtonModule {}
