import { Component, OnDestroy, OnInit } from '@angular/core';
import { first, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LocalSettingsService } from '../../core/local-settings.service';
import { DispoFilterService } from '../../shared/filter/filter.service';
import { RemoteConfig } from '../../core/remote_config.service';
import { DispoMenuService } from '../menus/menu.service';
import { DispoFocusService } from '../dispo-focus.service';

@Component({
  selector: 'tc-hub-dispo-view-settings',
  templateUrl: './dispo-view-settings.component.html',
  styleUrls: ['./dispo-view-settings.component.scss'],
})
export class DispoViewSettingsComponent implements OnInit, OnDestroy {
  menu = false;
  viewRange;

  availableViews$;

  private destroyed$ = new Subject<void>();

  constructor(
    public dispoFocus: DispoFocusService,
    public remoteConfig: RemoteConfig,
    public localSettings: LocalSettingsService,
    public filterService: DispoFilterService,
    public menuService: DispoMenuService,
  ) {}

  ngOnInit() {
    this.dispoFocus
      .length()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((l) => (this.viewRange = l + 1));

    // reset filter on load
    this.localSettings
      .get('dispo.views')
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe((views) => {
        views.forEach(([view, size]) => {
          this.filterService.recoverFilter(view);
        });
      });

    this.availableViews$ = this.remoteConfig.get('view_layout').pipe(
      map((views: any[]) => {
        return views.sort((a: any, b: any) => a.position - b.position);
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateViews(viewConfig) {
    const range = viewConfig.range;
    const views = [];

    viewConfig.views.map((view: any) => {
      if (typeof view === 'object') {
        views.push([view.view, view.size]);

        if (view.filter && Object.keys(view.filter).length !== 0) {
          this.filterService.requestFilter(view.view, view.filter);
        }

        // TODO: add sort
        // if (view.sort) {
        //   // this.filterService.requestSort(view.view, view.sort);
        // }
      } else {
        views.push([view]);
      }
    });

    this.localSettings.set('dispo.views', []);
    this.resetMenu();
    setTimeout(() => {
      this.updateRange(range);
      setTimeout(() => {
        this.localSettings.set('dispo.views', views);
      }, 10);
    }, 10);
  }

  resetMenu() {
    this.menuService.reset();
  }

  updateRange(value: number) {
    this.dispoFocus.setLength(value - 1);
  }

  toggleMenu() {
    this.menu = !this.menu;
  }
}
