import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class ProjectSetsCollection extends SetsCollection {
  cache = 0;
  identifier = 'project';
  endpoint = '/api/projects';

  remoteDeleteValidation = true;

  decorate(project: any, imports) {
    project.label = [project.token, project.title].filter((a) => a).join(' ');

    return project;
  }

  visible() {
    return this.query({ 'filter[visibility]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visibility]': '0' }, (e) => e.visibility === 0);
  }
}
