export enum ConflictingEntryType {
  None = 'none',
  Assignment = 'assignments',
  Invitation = 'invitations',
  Availability = 'availabilities',
  TimeBalance = 'timebalances',
  Subtotal = 'subtotals',
  Resource = 'resource',
  Bias = 'biases',
}
