import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

import {
  DateUnicodeFormat,
  isDateWithinInterval,
  isValidInterval,
  stringifyDate,
} from '@timecount/utils';

import { TcBasePickerDirective } from '../base/base-picker.directive';
import { TcDateTimePicker } from '../form-picker-datetime';

@Component({
  selector: 'tc-form-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class TcDatePickerComponent
  extends TcBasePickerDirective
  implements TcDateTimePicker, AfterViewInit
{
  format = DateUnicodeFormat.date;
  timeFormat = DateUnicodeFormat.time;

  showTime = false;

  limit: Interval;

  highlightedDates = [];

  @ViewChild('datePicker') datePickerComponent: NzDatePickerComponent;

  constructor() {
    super();
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngAfterViewInit() {
    super.init(this.datePickerComponent);
  }

  // --------------
  // Public Methods
  // --------------

  isDateHighlight(date: Date): boolean {
    return this.highlightedDates.includes(
      stringifyDate(date, DateUnicodeFormat.date),
    );
  }

  isDateDisabled = (current: Date): boolean => {
    return (
      isValidInterval(this.limit) && !isDateWithinInterval(current, this.limit)
    );
  };

  // ---------------
  // Events Handlers
  // ---------------

  /**
   * Overrides the base picker's model change handler to set the time to 0 making
   * sure that all dates will be selected at midnight instead of the current time
   */
  onModelChange(date: Date) {
    if (!this.showTime) {
      date.setHours(0, 0, 0, 0);
    }
    this.valueChange$.next(date);
  }
}
