import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { ContractorCollection } from '../contractors/contractor.collection';
import { SetCollection } from '../core/sets-collection';
import { EmployeeCollection } from '../employees/employee.collection';
import { DispoFilterService } from '../shared/filter/filter.service';

import { DispoResourceDataStructure } from './datastructures/resource.service';

@Injectable({
  providedIn: 'root',
})
export class DispoResourceService {
  private employeeService: SetCollection;
  private contractorService: SetCollection;

  private employeesDS$: Observable<any[]>;
  private contractorsDS$: Observable<any[]>;

  constructor(
    private filterService: DispoFilterService,
    private employeeSets: EmployeeCollection,
    private contractorSets: ContractorCollection,
    private resourceDS: DispoResourceDataStructure,
    private httpClient: HttpClient,
  ) {
    this.employeeService = this.employeeSets.visible();
    this.contractorService = this.contractorSets.visible();
  }

  employees(): Observable<any[]> {
    return combineLatest(
      this.employeeService.all(),
      this.employeesDS(),
      this.employeeQuery(),
      this.employeeSort(),
    ).pipe(
      map(([employees, employeesDS, query, sort]) => {
        const visibleEmployeeIds = employeesDS
          .filter(query)
          .sort(sort)
          .map((s: any) => s.id);

        const sortOrder = {};
        visibleEmployeeIds.forEach((id, index) => {
          sortOrder[id] = index;
        });

        return employees
          .filter((s) => visibleEmployeeIds.indexOf(s.id) !== -1)
          .sort((a, b) => {
            return sortOrder[a.id] - sortOrder[b.id];
          });
      }),
    );
  }

  employeesCondensed(): Observable<any[]> {
    return combineLatest(
      this.employeeService.all(),
      this.employeesDS(),
      this.employeeCondensedQuery(),
      this.employeeCondensedSort(),
    ).pipe(
      map(([employees, employeesDS, query, sort]) => {
        const visibleEmployeeIds = employeesDS
          .filter(query)
          .sort(sort)
          .map((s: any) => s.id);

        const sortOrder = {};
        visibleEmployeeIds.forEach((id, index) => {
          sortOrder[id] = index;
        });

        return employees
          .filter((s) => visibleEmployeeIds.indexOf(s.id) !== -1)
          .sort((a, b) => {
            return sortOrder[a.id] - sortOrder[b.id];
          });
      }),
    );
  }

  contractors() {
    return this.contractorService.all();
  }

  employeesDS() {
    return this.resourceDS.employees();
  }

  contractorsDS() {
    return this.resourceDS.contractors();
  }

  venues(venueable_type: string, venueable_id: number) {
    return this.httpClient
      .get('/api/venues/roles/' + venueable_type + '/' + venueable_id)
      .pipe(
        map((response) => {
          return response[`data`];
        }),
      );
  }

  private employeeQuery() {
    return this.filterService.getQuery('dispo.employees');
  }

  private employeeSort() {
    return this.filterService.getSort('dispo.employees', 'employees');
  }

  private employeeCondensedSort() {
    return this.filterService.getSort('dispo.employees_condensed', 'employees');
  }

  private employeeCondensedQuery() {
    return this.filterService.getQuery('dispo.employees_condensed');
  }
}
