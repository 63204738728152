import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { CurrentUserService } from '../../core/current-user.service';
import {
  Subtotal,
  SubtotalCollection,
  SubtotalPartialQuarterStatCollection,
  SubtotalPartialYearStatCollection,
  SubtotalQuarterStatCollection,
  SubtotalStats,
  SubtotalYearStatCollection,
} from '../../timebalances/subtotal.collection';
import {
  TimeBalanceDayStatCollection,
  TimeBalanceStats,
  TimeBalanceWeekStatCollection,
} from '../../timebalances/timebalance.collection';
import { ComparableStat } from '../../timebalances/comparable-stat.model';
import { DispoFocusService } from '../dispo-focus.service';

import {
  AssignmentStats,
  DispoAssignmentDayStatCollection,
  DispoAssignmentMonthStatCollection,
  DispoAssignmentPartialQuarterStatCollection,
  DispoAssignmentPartialYearStatCollection,
  DispoAssignmentQuarterStatCollection,
  DispoAssignmentWeekStatCollection,
  DispoAssignmentYearStatCollection,
} from './assignment_stats';

export type CombinedSubtotalStat = ComparableStat &
  Partial<SubtotalStats> &
  Partial<AssignmentStats> & { balance: number };

export class BlankCombinedSubtotalStat implements CombinedSubtotalStat {
  planned_hours = 0;
  planned_gross_hours = 0;
  total_planned_hours = 0;
  total_planned_gross_hours = 0;

  tracked_hours = 0;
  tracked_gross_hours = 0;
  total_tracked_hours = 0;
  total_tracked_gross_hours = 0;

  assigned_count = 0;
  assigned_distinct_count = 0;
  assigned_count_sunday = 0;
  assigned_count_saturday = 0;
  assigned_count_mofr = 0;

  work_day_count = 0;
  work_hours = 0;
  sickness_day_count = 0;
  sickness_hours = 0;
  vacation_day_count = 0;
  vacation_hours = 0;
  custom_day_count = 0;
  custom_hours = 0;
  transfer_day_count = 0;
  transfer_hours = 0;
  unpaid_sickness_day_count = 0;
  unpaid_sickness_hours = 0;
  unpaid_vacation_day_count = 0;
  unpaid_vacation_hours = 0;
  education_day_count = 0;
  education_hours = 0;
  holiday_day_count = 0;
  holiday_hours = 0;
  absence_day_count = 0;
  absence_hours = 0;

  total_target_hours = 0;
  total_hours = 0;
  overtime_hours = 0;
  total = 0;
  accumulates = false;

  balance = 0;

  resource_id: number;
  resource_type: 'Employee' | 'Contractor';
  date: string;
  marker: [number, number];
}

@Injectable({
  providedIn: 'root',
})
export class DispoSubtotalCollection {
  constructor(
    private assignmentDayStats: DispoAssignmentDayStatCollection,
    private assignmentWeekStats: DispoAssignmentWeekStatCollection,
    private assignmentMonthStats: DispoAssignmentMonthStatCollection,
    private assignmentQuarterStats: DispoAssignmentQuarterStatCollection,
    private partialAssignmentQuarterStats: DispoAssignmentPartialQuarterStatCollection,
    private partialAssignmentYearStats: DispoAssignmentPartialYearStatCollection,
    private assignmentYearStats: DispoAssignmentYearStatCollection,
    private subtotalQuarterStats: SubtotalQuarterStatCollection,
    private partialSubtotalQuarterStats: SubtotalPartialQuarterStatCollection,
    private subtotalYearStats: SubtotalYearStatCollection,
    private partialSubtotalYearStats: SubtotalPartialYearStatCollection,
    private subtotals: SubtotalCollection,
    private timeBalanceDayStats: TimeBalanceDayStatCollection,
    private timeBalanceWeekStats: TimeBalanceWeekStatCollection,
    private focusService: DispoFocusService,
    private currentUser: CurrentUserService,
  ) {}

  monthStats(): Observable<CombinedSubtotalStat[]> {
    return combineLatest(
      this.subtotals.all(),
      this.assignmentMonthStats.all(),
    ).pipe(
      map(
        ([subtotalStats, assignmentStats]: [Subtotal[], AssignmentStats[]]) => {
          return this.combineStats(subtotalStats, assignmentStats);
        },
      ),
      shareReplay(1),
    );
  }

  dayStats(): Observable<CombinedSubtotalStat[]> {
    if (this.currentUser.hasAccessToFeature('day_stats')) {
      return combineLatest(
        this.timeBalanceDayStats.all(),
        this.assignmentDayStats.all(),
      ).pipe(
        map(
          ([timeBalanceStats, assignmentStats]: [
            TimeBalanceStats[],
            AssignmentStats[],
          ]) => {
            return this.combineStats(assignmentStats, timeBalanceStats);
          },
        ),
        shareReplay(1),
      );
    } else {
      return of([]);
    }
  }

  weekStats(): Observable<CombinedSubtotalStat[]> {
    if (this.currentUser.hasAccessToFeature('week_stats')) {
      return combineLatest(
        this.timeBalanceWeekStats.all(),
        this.assignmentWeekStats.all(),
      ).pipe(
        map(
          ([timeBalanceStats, assignmentStats]: [
            TimeBalanceStats[],
            AssignmentStats[],
          ]) => {
            return this.combineStats(assignmentStats, timeBalanceStats);
          },
        ),
        shareReplay(1),
      );
    } else {
      return of([]);
    }
  }

  quarterStats(): Observable<CombinedSubtotalStat[]> {
    if (this.currentUser.hasAccessToFeature('quarter_stats')) {
      return this.focusService.begin().pipe(
        switchMap((date) => {
          return combineLatest(
            this.subtotalQuarterStats.forDate(date).all(),
            this.assignmentQuarterStats.forDate(date).all(),
          ).pipe(
            map(
              ([subtotalStats, assignmentStats]: [
                SubtotalStats[],
                AssignmentStats[],
              ]) => {
                return this.combineStats(subtotalStats, assignmentStats);
              },
            ),
            shareReplay(1),
          );
        }),
      );
    } else {
      return of([]);
    }
  }

  partialQuarterStats(): Observable<CombinedSubtotalStat[]> {
    if (this.currentUser.hasAccessToFeature('partial_quarter_stats')) {
      return this.focusService.begin().pipe(
        switchMap((date) => {
          return combineLatest(
            this.partialSubtotalQuarterStats.forDate(date).all(),
            this.partialAssignmentQuarterStats.forDate(date).all(),
          ).pipe(
            map(
              ([subtotalStats, assignmentStats]: [
                SubtotalStats[],
                AssignmentStats[],
              ]) => {
                return this.combineStats(subtotalStats, assignmentStats);
              },
            ),
            shareReplay(1),
          );
        }),
      );
    } else {
      return of([]);
    }
  }

  yearStats(): Observable<CombinedSubtotalStat[]> {
    if (this.currentUser.hasAccessToFeature('year_stats')) {
      return this.focusService.begin().pipe(
        switchMap((date) => {
          return combineLatest(
            this.subtotalYearStats.forDate(date).all(),
            this.assignmentYearStats.forDate(date).all(),
          ).pipe(
            map(
              ([subtotalStats, assignmentStats]: [
                SubtotalStats[],
                AssignmentStats[],
              ]) => {
                return this.combineStats(subtotalStats, assignmentStats);
              },
            ),
            shareReplay(1),
          );
        }),
      );
    } else {
      return of([]);
    }
  }

  partialYearStats(): Observable<CombinedSubtotalStat[]> {
    if (this.currentUser.hasAccessToFeature('partial_year_stats')) {
      return this.focusService.begin().pipe(
        switchMap((date) => {
          return combineLatest(
            this.partialSubtotalYearStats.forDate(date).all(),
            this.partialAssignmentYearStats.forDate(date).all(),
          ).pipe(
            map(
              ([subtotalStats, assignmentStats]: [
                SubtotalStats[],
                AssignmentStats[],
              ]) => {
                return this.combineStats(subtotalStats, assignmentStats);
              },
            ),
            shareReplay(1),
          );
        }),
      );
    } else {
      return of([]);
    }
  }

  private combineStats(
    mainStats: ComparableStat[],
    additionalStats: ComparableStat[],
  ): CombinedSubtotalStat[] {
    additionalStats = [...additionalStats];

    const additionalStatsLookup = {};
    const additionalStatsMerged = {};
    additionalStats.forEach((a) => {
      additionalStatsLookup[`${a.resource_id}:${a.resource_type}:${a.date}`] =
        a;
    });

    const combinedMainStats = mainStats.map((main) => {
      const lookup = `${main.resource_id}:${main.resource_type}:${main.date}`;

      const additionalStat = additionalStatsLookup[lookup] || {};
      additionalStatsMerged[lookup] = true;

      return { ...main, ...additionalStat };
    });

    additionalStats = additionalStats.filter(
      (a) =>
        !additionalStatsMerged[`${a.resource_id}:${a.resource_type}:${a.date}`],
    );

    return [...combinedMainStats, ...additionalStats].map((baseStat) => {
      const stat = <CombinedSubtotalStat>{
        ...new BlankCombinedSubtotalStat(),
        ...baseStat,
      };

      stat.balance =
        stat.total_hours +
        stat.planned_hours +
        stat.tracked_hours -
        stat.total_target_hours;

      return stat;
    });
  }
}
