<div class="sf-group">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>

  <div class="sf-controls">
    <div class="sf-elements">
      <input
        nz-input
        type="number"
        [formControl]="control"
        class="sf-element"
      />
    </div>
  </div>
</div>
