import { Injectable } from '@angular/core';
import { AsyncSubject, combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { PlanService } from '../actions/plan.service';
import { TaskService } from '../actions/task.service';
import { DispoFilterService } from '../../shared/filter/filter.service';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { DispoSelectorService } from '../selector/selector.service';
import { ScheduleService } from '../actions/schedule.service';
import { MessageService } from '../actions/message.service';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoPlanCollection, Plan } from '../collections/plan';
import { DispoFocusService } from '../dispo-focus.service';

import { MenuItem } from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuPlan {
  private taskTemplates: any[] = [];
  private planFilters: any[] = [];
  private scheduleTemplates: any[] = [];
  private planTemplates: any[] = [];
  private planMessageTemplates: any[] = [];
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private planActions: PlanService,
    private taskActions: TaskService,
    private scheduleActions: ScheduleService,
    private messageActions: MessageService,
    private filterService: DispoFilterService,
    private selectorService: DispoSelectorService,
    private planCollection: DispoPlanCollection,
    private focusService: DispoFocusService,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/plan.actions.edit',
        'dispo/plan.actions.create',
        'dispo/plan.actions.move',
        'dispo/plan.actions.delete',
        'dispo/plan.actions.task_from_date_and_plan',
        'dispo/plan.actions.message',
        'dispo/plan.actions.project',
        'dispo/task.menu.message',
        'dispo/plan.actions.show_all_tasks',
        'dispo/plan.actions.hide_all_tasks',
        'dispo/plan.actions.track_all_tasks',
        'dispo/plan.actions.request_all_tasks',
        'dispo/plan.menu.filter',
        'dispo/plan.menu.plan',
        'dispo/plan.menu.template',
        'dispo/plan.menu.public',
        'dispo/plan.menu.task',
        'dispo/task.menu.template',
        'dispo/plan.actions.schedule_from_plan',
        'dispo/schedule.menu.schedule',
        'dispo/schedule.menu.template',
        'trails.actions.resource',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.resourceTemplateCollection
      .setForResource('dispo.tasks')
      .all()
      .subscribe((templates) => {
        this.taskTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.schedules')
      .all()
      .subscribe((templates) => {
        this.scheduleTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.plans')
      .all()
      .subscribe((templates) => {
        this.planTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.plan')
      .all()
      .subscribe((templates) => {
        this.planMessageTemplates = templates;
      });

    this.filterService.getPresets('dispo.plans').subscribe((presets) => {
      this.planFilters = presets;
    });
  }

  itemsForDate(currPlan: any, date: Date): Observable<any[]> {
    return this.planCollection.observeItem(currPlan).pipe(
      map((plan: Plan) => {
        if (!plan) {
          return [];
        }

        const planActions = [
          {
            label: this.translations['dispo/plan.actions.edit'],
            icon: 'edit',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.general',
            ),
            command: (event: any) => {
              this.planActions.general(plan);
            },
          },
          {
            label: this.translations['dispo/plan.actions.move'],
            icon: 'calendar',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.move',
            ),
            command: (event: any) => {
              this.planActions.move(plan);
            },
          },
          {
            label: this.translations['dispo/plan.actions.delete'],
            icon: 'delete',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.delete',
            ),
            command: (event: any) => {
              this.planActions.delete(plan);
            },
          },
          {
            label: this.translations['dispo/plan.actions.project'],
            icon: 'tc:projects',
            command: (event: any) => {
              window.open(`/#/projects/${plan.project_id}/general`, '_blank');
            },
          },
          {
            label: this.translations['dispo/plan.actions.create'],
            icon: 'plus',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.create',
            ),
            command: (event: any) => {
              this.planActions.createFromDateDefault(date);
            },
          },
          {
            label: this.translations['trails.actions.resource'],
            icon: 'audit',
            visible:
              this.currentUser.hasAccessToFeature('logs') &&
              this.currentUser.can('index', 'PaperTrail::Version', {
                item_type: 'TcDispo::Event',
              }),
            command: (_event: unknown) => {
              this.planActions.viewLogs(plan.id);
            },
          },
        ];

        const planTemplateActions: any[] = this.planTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                const templ = Object.assign({}, template.template);
                this.planActions.createFromDateAndTemplate(date, templ);
              },
            };
          });

        const taskActions = [
          {
            label:
              this.translations['dispo/plan.actions.task_from_date_and_plan'],
            icon: 'plus',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.task.create',
            ),
            command: (event: any) => {
              this.taskActions.createFromPlanDefault(date, plan);
            },
          },
        ];

        const taskTemplateActions: any[] = this.taskTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                this.taskActions.createFromPlan(date, plan, template.template);
              },
            };
          });

        const messageTemplates: any[] = this.planMessageTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                const templ = Object.assign({}, template.template);
                this.messageActions.plan(plan, date, templ);
              },
            };
          });

        const publicActions = [
          {
            label: this.translations['dispo/plan.actions.message'],
            icon: 'comment',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.message',
            ),
            command: (event) => {
              this.messageActions.planDefault(plan, date);
            },
          },
          {
            label: this.translations['dispo/task.menu.message'],
            icon: 'copy',
            visible:
              messageTemplates.length > 0 &&
              this.currentUser.hasAccessToSection('views.dispo.plan.message'),
            items: messageTemplates,
          },
          {
            label: this.translations['dispo/plan.actions.show_all_tasks'],
            icon: 'eye',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.show_all',
            ),
            command: (event: any) => {
              this.planActions.setTasksPublic(plan, date);
            },
          },
          {
            label: this.translations['dispo/plan.actions.hide_all_tasks'],
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.hide_all',
            ),
            icon: 'eye-invisible',
            command: (event: any) => {
              this.planActions.setTasksHidden(plan, date);
            },
          },
          {
            label: this.translations['dispo/plan.actions.track_all_tasks'],
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.track_all',
            ),
            icon: 'clock-circle',
            command: (event: any) => {
              this.planActions.setTasksTrackable(plan, date);
            },
          },
          {
            label: this.translations['dispo/plan.actions.request_all_tasks'],
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.request_all',
            ),
            icon: 'wifi',
            command: (event: any) => {
              this.planActions.requestTasksConfirmation(plan, date);
            },
          },
        ];

        const filterActions: any[] = this.planFilters.map((preset) => {
          return {
            label: preset.label,
            icon: 'filter',
            command: (event: any) => {
              this.selectorService.setPlan(plan);
              this.filterService.setPreset(preset.id);
            },
          };
        });

        const items: MenuItem[] = [
          ...(filterActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/plan.menu.filter'],
                },
                ...filterActions,
              ]
            : []),
          ...(planActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/plan.menu.plan'],
                },
                ...planActions,
              ]
            : []),
          ...(planTemplateActions.length !== 0
            ? [
                {
                  label: this.translations['dispo/plan.menu.template'],
                  icon: 'copy',
                  visible: this.currentUser.hasAccessToSection(
                    'views.dispo.plan.create',
                  ),
                  items: planTemplateActions,
                },
              ]
            : []),
          ...(publicActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/plan.menu.public'],
                },
                ...publicActions,
              ]
            : []),
          ...(taskActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/plan.menu.task'],
                },
                ...taskActions,
              ]
            : []),
          ...(taskTemplateActions.length !== 0
            ? [
                {
                  label: this.translations['dispo/task.menu.template'],
                  icon: 'copy',
                  visible: this.currentUser.hasAccessToSection(
                    'views.dispo.task.create',
                  ),
                  items: taskTemplateActions,
                },
              ]
            : []),
        ];

        return items;
      }),
    );
  }

  items(currPlan: any): Observable<any[]> {
    return combineLatest(
      this.planCollection.observeItem(currPlan),
      this.focusService.date(),
    ).pipe(
      map(([plan, date]: [Plan, Date]) => {
        if (!plan) {
          return [];
        }

        const planActions = [
          {
            label: this.translations['dispo/plan.actions.edit'],
            icon: 'edit',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.general',
            ),
            command: (event: any) => {
              this.planActions.general(plan);
            },
          },
          {
            label: this.translations['dispo/plan.actions.move'],
            icon: 'calendar',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.move',
            ),
            command: (event: any) => {
              this.planActions.move(plan);
            },
          },
          {
            label: this.translations['dispo/plan.actions.delete'],
            icon: 'delete',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.delete',
            ),
            command: (event: any) => {
              this.planActions.delete(plan);
            },
          },
          {
            label: this.translations['dispo/plan.actions.project'],
            icon: 'tc:projects',
            command: (event: any) => {
              window.open(`/#/projects/${plan.project_id}/general`, '_blank');
            },
          },
          {
            label: this.translations['dispo/plan.actions.create'],
            icon: 'plus',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.create',
            ),
            command: (event: any) => {
              this.planActions.createFromDateDefault(date);
            },
          },
          {
            label: this.translations['trails.actions.resource'],
            icon: 'audit',
            visible:
              this.currentUser.hasAccessToFeature('logs') &&
              this.currentUser.can('index', 'PaperTrail::Version', {
                item_type: 'TcDispo::Event',
              }),
            command: (_event: unknown) => {
              this.planActions.viewLogs(plan.id);
            },
          },
        ];

        const filterActions: any[] = this.planFilters.map((preset) => {
          return {
            label: preset.label,
            icon: 'filter',
            command: (event: any) => {
              this.selectorService.setPlan(plan);
              this.filterService.setPreset(preset.id);
            },
          };
        });

        const planTemplateActions: any[] = this.planTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                const templ = Object.assign({}, template.template);
                this.planActions.createFromDateAndTemplate(date, templ);
              },
            };
          });

        const scheduleActions = [
          {
            label: this.translations['dispo/plan.actions.schedule_from_plan'],
            icon: 'plus',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.create',
            ),
            command: (event: any) => {
              this.scheduleActions.createFromPlanDefault(plan);
            },
          },
        ];

        const scheduleTemplateActions: any[] = this.scheduleTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                const templ = Object.assign({}, template.template, {
                  plan_id: plan.id,
                  starts_at: new Date(plan.starts_at.getTime()),
                  ends_at: new Date(plan.ends_at.getTime()),
                });

                this.scheduleActions.create(templ);
              },
            };
          });

        const publicActions = [
          {
            label: this.translations['dispo/plan.actions.show_all_tasks'],
            icon: 'eye',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.show_all',
            ),
            command: () => {
              this.planActions.setTasksPublic(plan);
            },
          },
          {
            label: this.translations['dispo/plan.actions.hide_all_tasks'],
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.hide_all',
            ),
            icon: 'eye-invisible',
            command: () => {
              this.planActions.setTasksHidden(plan);
            },
          },
          {
            label: this.translations['dispo/plan.actions.request_all_tasks'],
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.plan.request_all',
            ),
            icon: 'wifi',
            command: () => {
              this.planActions.requestTasksConfirmation(plan);
            },
          },
        ];

        const items: MenuItem[] = [
          ...(filterActions.length !== 0
            ? [
                {
                  header: true,
                  label: this.translations['dispo/plan.menu.filter'],
                },
                ...filterActions,
              ]
            : []),
          ...(planActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/plan.menu.plan'],
                },
                ...planActions,
              ]
            : []),
          ...(planTemplateActions.length !== 0
            ? [
                {
                  label: this.translations['dispo/plan.menu.template'],
                  icon: 'copy',
                  visible: this.currentUser.hasAccessToSection(
                    'views.dispo.plan.create',
                  ),
                  items: planTemplateActions,
                },
              ]
            : []),
          ...(scheduleActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/schedule.menu.schedule'],
                },
                ...scheduleActions,
              ]
            : []),
          ...(scheduleTemplateActions.length !== 0
            ? [
                {
                  label: this.translations['dispo/schedule.menu.template'],
                  icon: 'copy',
                  visible: this.currentUser.hasAccessToSection(
                    'views.dispo.schedule.create',
                  ),
                  items: scheduleTemplateActions,
                },
              ]
            : []),
          ...(publicActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/plan.menu.public'],
                },
                ...publicActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }

  base(date = new Date()) {
    const items$ = new AsyncSubject<any[]>();

    const planActions = [
      {
        label: this.translations['create'],
        icon: 'plus',
        visible: this.currentUser.hasAccessToSection('views.dispo.plan.create'),
        command: (event: any) => {
          this.planActions.createFromDateDefault(date);
        },
      },
    ];

    const planTemplateActions: any[] = this.planTemplates
      .filter((t) => !t.default)
      .map((template) => {
        return {
          label: template.title,
          command: (event: any) => {
            const templ = Object.assign({}, template.template);
            this.planActions.createFromDateAndTemplate(date, templ);
          },
        };
      });

    const items: MenuItem[] = [
      ...(planActions.length !== 0
        ? [
            { separator: true },
            {
              header: true,
              label: this.translations['dispo/plan.menu.plan'],
            },
            ...planActions,
          ]
        : []),
      ...(planTemplateActions.length !== 0
        ? [
            {
              label: this.translations['dispo/plan.menu.template'],
              icon: 'copy',
              visible: this.currentUser.hasAccessToSection(
                'views.dispo.plan.create',
              ),
              items: planTemplateActions,
            },
          ]
        : []),
    ];

    items$.next(items);
    items$.complete();

    return items$;
  }
}
