import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigCollection extends SetsCollection {
  identifier = 'preferences/config';
  endpoint = '/api/preferences/configs';

  cache = 60 * 60 * 12;
}
