import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { dateToTzUnix } from '@timecount/utils';

import {
  DispoInvitationCollection,
  Invitation,
  InvitationState,
} from '../../collections/invitation';
import { SlotPos, Task } from '../../collections/task';
import { Plan } from '../../collections/plan';
import { dateToString } from '../../../core/helpers';
import { DispoAssignmentCollection } from '../../collections/assignment';
import { InvitationService } from '../../actions/invitation.service';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';
import { AssignmentService } from '../../actions/assignment.service';
import { DispoResourceCollection } from '../../collections/resource';

@Component({
  selector: 'tc-hub-dt-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css'],
})
export class DTInvitationsComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @Input() plan: Plan;
  @Input() announcement: Announcement;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  private translations: { [key: string]: string } = {};
  private destroyed$ = new Subject<void>();

  public invitations$: Observable<any[]>;

  constructor(
    private translateService: TranslateService,
    private invitationCollection: DispoInvitationCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private announcementCollection: DispoAnnouncementCollection,
    private invitationActions: InvitationService,
    private assignmentActions: AssignmentService,
    private resourceCollection: DispoResourceCollection,
  ) {
    this.translateService
      .get([
        'dispo/invitation.state.accepted',
        'dispo/invitation.state.assigned',
        'dispo/invitation.state.cancelled',
        'dispo/invitation.state.refused',
        'dispo/invitation.state.other',
      ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnInit(): void {
    this.invitations$ = combineLatest(
      this.announcementCollection.observeItem(this.announcement),
      this.invitationCollection.forAnnouncement(this.announcement),
      this.assignmentCollection.forTask(this.task),
      this.resourceCollection.all(),
    ).pipe(
      map(([announcement, invitations, assignments, resources]) => {
        this.announcement = <Announcement>announcement;

        invitations = invitations.map((invitation) => {
          invitation = Object.assign({}, invitation);
          invitation.state =
            invitation.states[dateToString(this.task.starts_at)];
          invitation.assignment = assignments.find(
            (a) => a.invitation_id === invitation.id,
          );
          invitation.resource = resources.find(
            (r) =>
              r.type === invitation.resource_type &&
              r.id === invitation.resource_id,
          );
          return invitation;
        });

        return this.announcement.resources
          .map((res) => {
            const invitation = invitations.find(
              (i) => i.resource_type === res.type && i.resource_id === res.id,
            );

            if (invitation) {
              return invitation;
            } else {
              return Object.assign(new Invitation(), {
                resource_id: res.id,
                resource_type: res.type,
                resource: resources.find(
                  (r) => r.type === res.type && r.id === res.id,
                ),
                announcement_id: this.announcement.id,
                states: {},
                state: 'unread',
                shallow: true,
              });
            }
          })
          .sort(
            (a, b) =>
              (b.read_at || new Date(0)).getTime() -
                (a.read_at || new Date(0)).getTime() ||
              (a.resource?.name > b.resource?.name ? 1 : -1),
          );
      }),
      map((invitations) => {
        const requestedSize = this.announcement.sizes[this.task.date];
        const invitationsAssigned = invitations.filter((i) => i.assignment);
        const invitationsAccepted = invitations.filter(
          (i) =>
            !i.assignment && i.state === InvitationState.accepted && !i.shallow,
        );
        const invitationsCancelled = invitations.filter(
          (i) =>
            !i.assignment &&
            i.state === InvitationState.cancelled &&
            !i.shallow,
        );
        const invitationsRefused = invitations.filter(
          (i) =>
            !i.assignment && i.state === InvitationState.refused && !i.shallow,
        );
        const invitationsOther = invitations.filter(
          (i) =>
            i.state !== InvitationState.accepted &&
            i.state !== InvitationState.refused &&
            !i.assignment,
        );

        return [
          {
            header: true,
            title: this.translations['dispo/invitation.state.accepted'],
            size: [invitationsAccepted.length, requestedSize],
          },
          ...invitationsAccepted,
          {
            header: true,
            title: this.translations['dispo/invitation.state.assigned'],
            size: [invitationsAssigned.length, requestedSize],
          },
          ...invitationsAssigned,
          {
            header: true,
            title: this.translations['dispo/invitation.state.cancelled'],
            size: [invitationsCancelled.length, requestedSize],
          },
          ...invitationsCancelled,
          {
            header: true,
            title: this.translations['dispo/invitation.state.refused'],
            size: [invitationsRefused.length, invitations.length],
          },
          ...invitationsRefused,
          {
            header: true,
            title: this.translations['dispo/invitation.state.other'],
            size: [invitationsOther.length, invitations.length],
          },
          ...invitationsOther,
        ];
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  assign(invitation: Invitation) {
    this.invitationCollection.activateInvitations([invitation]).subscribe({
      next: ([invitation]) => {
        const slotPos = Object.assign(new SlotPos(), {
          x: dateToTzUnix(this.task.starts_at),
          invitation_id: invitation.id,
        });

        this.invitationActions.assign([slotPos], this.announcement, [
          invitation,
        ]);
      },
    });
  }

  unassign(invitation: Invitation) {
    const slotPos = Object.assign(new SlotPos(), {
      x: dateToTzUnix(this.task.starts_at),
      invitation_id: invitation.id,
    });

    this.invitationActions.unassign([slotPos], this.announcement, [invitation]);
  }

  delete(invitation: Invitation) {
    this.invitationActions.delete([invitation]);
  }

  cancel(invitation: Invitation) {
    this.invitationCollection.activateInvitations([invitation]).subscribe({
      next: ([invitation]) => {
        const slotPos = Object.assign(new SlotPos(), {
          x: dateToTzUnix(this.task.starts_at),
          invitation_id: invitation.id,
        });

        this.invitationActions.cancel([slotPos], [invitation]);
      },
    });
  }

  accept(invitation: Invitation) {
    this.invitationCollection.activateInvitations([invitation]).subscribe({
      next: ([invitation]) => {
        const slotPos = Object.assign(new SlotPos(), {
          x: dateToTzUnix(this.task.starts_at),
          invitation_id: invitation.id,
        });

        this.invitationActions.accept([slotPos], [invitation]);
      },
    });
  }

  refuse(invitation: Invitation) {
    this.invitationCollection.activateInvitations([invitation]).subscribe({
      next: ([invitation]) => {
        const slotPos = Object.assign(new SlotPos(), {
          x: dateToTzUnix(this.task.starts_at),
          invitation_id: invitation.id,
        });

        this.invitationActions.refuse([slotPos], [invitation]);
      },
    });
  }
}
