import { Project } from '../project.model';

import { Aggregation } from './aggregation.model';

type SelectableResource = {
  id: number;
  type: string;
};

export class AggregationImporterSettings {
  project_id: number;
  gratis_type?: 'gratis' | 'invoicable' | null;
  offset?: number = 0;
  aggregate?: boolean = true;
  storno?: boolean = false;
  gross_duration?: boolean = false;
  target_time?: boolean = false;
  project: Partial<Project>;
  resource_type?: 'Employee' | 'Contractor';
  starts_at?: string;
  ends_at?: string;
  venues?: number[];
  store?: Record<string, unknown>;
  skus?: SelectableResource[];
  resources?: SelectableResource[];
  includes?: string[] = [];
  jobs?: number[];
  aggregation?: Aggregation;
  entry_type?: string;
  tasks?: number[];
  schedules?: number[];
  events?: number[];
  availability_types: number[];

  constructor(init: Partial<AggregationImporterSettings>) {
    Object.assign(this, init);
  }
}
