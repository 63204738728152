import { Component, Input } from '@angular/core';

import { DateInfo } from '@timecount/utils';

@Component({
  selector: 'tc-hub-dispo-shift-grid-header-weeks',
  templateUrl: './shift-grid-header-weeks.component.html',
  styleUrls: ['./shift-grid-header-weeks.component.scss'],
})
export class DispoShiftGridHeaderWeeksComponent {
  @Input() weeks: DateInfo[][];
  @Input() isCompact = false;
}
