import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { TcHubPostsWidgetComponent } from './posts-widget/posts-widget.component';

@NgModule({
  imports: [SharedModule],
  declarations: [TcHubPostsWidgetComponent],
})
export class PostsModule {}
