import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Link } from './types/link';
import { RemoteConfig } from './remote_config.service';

@Injectable({
  providedIn: 'root',
})
export class TcLinksService {
  constructor(
    private remoteConfig: RemoteConfig,
    private translateService: TranslateService,
    private httpClient: HttpClient,
  ) {}

  // --------------
  // Public Methods
  // --------------

  getLinksForResource(resources: string[]): Promise<Link[]> {
    return new Promise((resolve) => {
      combineLatest([
        ...resources.map((resource) =>
          this.remoteConfig.get('action', `${resource}.actions`),
        ),
      ]).subscribe((links: Link[][]) => {
        resolve(links.flat());
      });
    });
  }

  async openLink(link, object) {
    link.target = this.translateService.parser.interpolate(link.target, {
      object,
    });
    if (link.action === 'signed_link') {
      const signedUrl = await this.signLink(link.target).toPromise();
      window.open(signedUrl);
    } else {
      window.open(link.target);
    }
  }

  // ---------------
  // Private Methods
  // ---------------

  signLink(url: string): Observable<string> {
    return this.httpClient.get('/api/verifications/sign/' + btoa(url)).pipe(
      map((response) => {
        return response[`data`][`signed_target`];
      }),
    );
  }
}
