import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

import { BillingAggregationCollection } from '../billing-aggregation.collection';
import { Aggregation } from '../aggregation.model';

@Component({
  selector: 'tc-hub-aggregations-bill-form',
  templateUrl: './aggregations-bill-form.component.html',
  styleUrls: ['./aggregations-bill-form.component.scss'],
})
export class TcAggregationsBillFormComponent
  extends BaseModalComponent
  implements OnInit, OnDestroy
{
  formGroup: FormGroup;
  @Input() tcInitialValue: Aggregation;

  isModal = false;
  isSubmitting = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _aggregationCollection: BillingAggregationCollection,
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super(config, modalRef);
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    if (this.config?.data) {
      Object.assign(this, this.config.data);
    }

    this._buildForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // --------------
  // Public Methods
  // --------------

  save() {
    const payload = this._getPayload(this.formGroup.value);
    const { id } = this.tcInitialValue;

    this._aggregationCollection.action('bill', id, payload);
    this.modalRef?.close();
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm() {
    this.formGroup = this._formBuilder.group({
      billed_at: [new Date()],
      performed_at: [this.tcInitialValue.starts_at],
      store: [{}],
    });
  }

  private _getPayload(formValues): any {
    const { billed_at, performed_at, store } = formValues ?? {};

    return {
      billed_at,
      performed_at,
      store,
    };
  }
}
