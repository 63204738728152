<button
  class="button"
  nz-button
  nzType="link"
  [nzSize]="size"
  nz-popover
  nzPopoverTrigger="click"
  nzPopoverPlacement="right"
  [nzPopoverContent]="contentTemplate"
  [(nzPopoverVisible)]="visible"
>
  <i nz-icon nzType="info-circle"></i>
</button>

<ng-template #contentTemplate>
  <table class="table">
    <tbody>
      <tr *ngIf="employee.mobile">
        <td class="table-head">{{ 'contact.attrs.mobile' | translate }}</td>
        <td>
          <a href="tel:{{ employee.mobile }}">{{ employee.mobile }}</a>
        </td>
      </tr>
      <tr *ngIf="employee.phone">
        <td class="table-head">{{ 'contact.attrs.phone' | translate }}</td>
        <td>
          <a href="tel:{{ employee.phone }}">{{ employee.phone }}</a>
        </td>
      </tr>
      <tr *ngIf="employee.email">
        <td class="table-head">{{ 'contact.attrs.email' | translate }}</td>
        <td>
          <a href="mailto:{{ employee.email }}">{{ employee.email }}</a>
        </td>
      </tr>
      <tr *ngIf="employee.qualifications.length !== 0">
        <td class="table-head">
          {{ 'employee.attrs.qualification_list' | translate }}
        </td>
        <td>
          <span *ngFor="let qualification of employee.qualifications"
            >{{ qualification.title }}
          <br /></span>
        </td>
      </tr>
      <tr *ngIf="employee.biases?.length > 0">
        <td class="table-head">
          {{ 'employee.attrs.bias_list' | translate }}
        </td>
        <td>
          <nz-tag
            class="badge"
            [nzColor]="bias.color"
            *ngFor="let bias of employee.biases"
            >{{ bias.title }}
          </nz-tag>
        </td>
      </tr>
      <tr *ngIf="employee.employee_tags.length !== 0">
        <td class="table-head">
          {{ 'employee.attrs.employee_tag_list' | translate }}
        </td>
        <td>
          <span *ngFor="let tag of employee.employee_tags"
            >{{ tag }}
          <br /></span>
        </td>
      </tr>
      <tr *ngIf="employee.driving_licenses.length !== 0">
        <td class="table-head">
          {{ 'employee.attrs.driving_license_list' | translate }}
        </td>
        <td>
          <span *ngFor="let license of employee.driving_licenses"
            >{{ license }}
          <br /></span>
        </td>
      </tr>
      <tr *ngFor="let colDef of columnDefs$ | async">
        <td class="table-head">{{ colDef.title | translate }}</td>
        <td [innerHTML]="colDef?.data?.call(employee, employee)"></td>
      </tr>
    </tbody>
  </table>
</ng-template>
