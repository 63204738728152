import { Injectable } from '@angular/core';
import { ExclusionPolicy, Strategy } from 'typeserializer';

import { SetsCollection } from '../../core/sets-collection';

@Strategy(ExclusionPolicy.NONE)
export class AvailabilitySource {
  id: number;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class DispoAvailabilitySourceCollection extends SetsCollection {
  identifier = 'tc_dispo/settings/availability_source';
  endpoint = '/api/dispo/settings/availability_sources';
  type = AvailabilitySource;

  refreshOnStale = false;
  cache = 60 * 60 * 12;

  localSearch = ['title'];
}
