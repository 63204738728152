<div class="modal-container">
  <div class="modal-body">
    <nz-table [nzData]="items" nzFrontPagination="false" nzSize="small">
      <thead>
        <tr>
          <th>{{ 'dispo/task.attrs.date' | translate }}</th>
          <th>{{ 'dispo/task.attrs.position' | translate }}</th>
          <th>{{ 'employee.tables.token' | translate }}</th>
          <th>{{ 'employee.tables.name' | translate }}</th>
          <ng-container *ngIf="allowOnsiteCheckin">
            <th>{{ 'dispo/task.attrs.onsite_checkin_at' | translate }}</th>
            <th>
              {{ 'dispo/task.attrs.onsite_checkin_location' | translate }}
            </th>
          </ng-container>
          <th *ngIf="allowAppAttendance">
            {{ 'dispo/task.attrs.attendance_at' | translate }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items">
          <td>{{ item.date | date : 'short' }}</td>
          <td>{{ item.position }}</td>
          <td>{{ item.token }}</td>
          <td>{{ item.name }}</td>
          <ng-container *ngIf="allowOnsiteCheckin">
            <ng-container
              *ngIf="
                (item.responses | feedbackRequestsOfType: 'onsite_checkin')?.[0];
                let onSiteCheckIn; else noOnsiteCheckinTemplate
              "
            >
              <td
                *ngIf="
                  onSiteCheckIn?.response?.responseAt;
                  else noValueTemplate
                "
              >
                {{ onSiteCheckIn?.response.responseAt | date : 'short' }}
              </td>
              <td
                *ngIf="
                  onSiteCheckIn?.response?.feedback;
                  else noValueTemplate;
                  let feedback
                "
              >
                <span
                  *ngIf="
                    feedback.latitude && feedback.longitude;
                    else noValueTemplate
                  "
                  nz-popover
                  [nzPopoverContent]="mapPopover"
                  nzPopoverOverlayClassName="tc-popover with-padding"
                >
                  <i nz-icon nzType="tc:location" nzTheme="fill"></i>
                </span>
              </td>
              <ng-template #mapPopover>
                <tc-map
                  [center]="{
                    location: onSiteCheckIn?.response?.feedback
                  }"
                  [size]="[640, 320]"
                ></tc-map>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="allowAppAttendance">
            <ng-container>
              <td
                *ngIf="
                (item.responses | feedbackRequestsOfType: 'attend')?.[0]?.response?.responseAt;
                let responseAt; else noValueTemplate
              "
              >
                {{ responseAt | date : 'short' }}
              </td>
            </ng-container>
          </ng-container>
          <ng-template #noValueTemplate>
            <td>
              <span>-</span>
            </td>
          </ng-template>
          <ng-template #noOnsiteCheckinTemplate>
            <td>
              <span>-</span>
            </td>
            <td>
              <span>-</span>
            </td>
          </ng-template>
          <td>
            <button
              nz-button
              nzSize="small"
              nzShape="circle"
              nzType="default"
              *ngIf="!item.unknown"
              (click)="open(item)"
            >
              <i nz-icon nzType="tc:employees"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="modal-footer">
    <button type="button" nz-button nzType="secondary" (click)="cancel()">
      {{ 'cancel' | translate }}
    </button>
  </div>
</div>
