import { NgModule } from '@angular/core';

import { TcComponentsModule } from './components';
import { TcFieldSetsModule } from './fieldsets';
import { TcFormGroupsModule } from './form-groups/form-groups.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  exports: [
    SharedModule,
    TcComponentsModule,
    TcFormGroupsModule,
    TcFieldSetsModule,
  ],
  imports: [
    SharedModule,
    TcComponentsModule,
    TcFormGroupsModule,
    TcFieldSetsModule,
  ],
})
export class TcFormsModule {}
