<div class="tc-modal-body" #modalBody>
  <div class="modal-container">
    <nz-steps [nzCurrent]="currentStep">
      <nz-step
        nzTitle="{{ 'dispo/import.tab_type.create-s1' | translate }}"
      ></nz-step>
      <nz-step
        nzTitle="{{ 'dispo/import.tab_type.create-s2' | translate }}"
      ></nz-step>
      <nz-step
        nzTitle="{{ 'dispo/import.tab_type.create-s3' | translate }}"
      ></nz-step>
      <nz-step
        nzTitle="{{ 'dispo/import.tab_type.create-s4' | translate }}"
      ></nz-step>
    </nz-steps>

    <div class="steps">
      <ng-container *ngIf="currentStep === 0">
        <tc-hub-dim-step1
          class="modal-container"
          [import]="import"
          (signal)="onSignal($event)"
        ></tc-hub-dim-step1>
      </ng-container>
      <ng-container *ngIf="currentStep === 1">
        <tc-hub-dim-step2
          class="modal-container"
          [import]="import"
          (signal)="onSignal($event)"
        ></tc-hub-dim-step2>
      </ng-container>
      <ng-container *ngIf="currentStep === 2">
        <tc-hub-dim-step3
          class="modal-container"
          [import]="import"
          (signal)="onSignal($event)"
        ></tc-hub-dim-step3>
      </ng-container>
      <ng-container *ngIf="currentStep === 3">
        <tc-hub-dim-step4
          class="modal-container"
          [import]="import"
          (signal)="onSignal($event)"
        ></tc-hub-dim-step4>
      </ng-container>
    </div>
  </div>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
