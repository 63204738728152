import { Injectable } from '@angular/core';
import { DefaultWidgetRegistry } from 'ngx-schema-form';

import { CollectionFilterWidgetComponent } from './collection-filter-widget/collection-filter-widget.component';
import { CollectionSelectWidgetComponent } from './collection-select-widget/collection-select-widget.component';
import { RemoteSelectWidgetComponent } from './remote-select-widget/remote-select-widget.component';
import { ArrayWidgetComponent } from './array-widget/array-widget.component';
import { DateRangeFilterWidgetComponent } from './date-range-filter-widget/date-range-filter-widget.component';
import { StringWidgetComponent } from './string-widget/string-widget.component';
import { IntegerWidgetComponent } from './integer-widget/integer-widget.component';
import { CheckboxWidgetComponent } from './checkbox-widget/checkbox-widget.component';
import { ObjectWidgetComponent } from './object-widget/object-widget.component';
import { SelectWidgetComponent } from './select-widget/select-widget.component';
import { BooleanWidgetComponent } from './boolean-widget/boolean-widget.component';
import { NumberWidgetComponent } from './number-widget/number-widget.component';
import { EmailWidgetComponent } from './email-widget/email-widget.component';
import { MaskWidgetComponent } from './mask-widget/mask-widget.component';
import { CurrencyWidgetComponent } from './currency-widget/currency-widget.component';
import { DatetimeWidgetComponent } from './datetime-widget/datetime-widget.component';
import { DateRangeWidgetComponent } from './date-range-widget/date-range-widget.component';

@Injectable()
export class TcWidgetRegistry extends DefaultWidgetRegistry {
  constructor() {
    super();

    // Text
    this.register('string', StringWidgetComponent);
    this.register('email', EmailWidgetComponent);
    this.register('iban', MaskWidgetComponent);
    this.register('phone', MaskWidgetComponent);
    this.register('mobile', MaskWidgetComponent);
    this.register('mask', MaskWidgetComponent);

    // Number
    this.register('currency', CurrencyWidgetComponent);
    this.register('integer', IntegerWidgetComponent);
    this.register('number', NumberWidgetComponent);

    // Date
    this.register('date', DatetimeWidgetComponent);
    this.register('time', DatetimeWidgetComponent);
    this.register('datetime', DatetimeWidgetComponent);
    this.register('date-range-filter', DateRangeFilterWidgetComponent);
    this.register('date-range', DateRangeWidgetComponent);

    // Boolean
    this.register('checkbox', CheckboxWidgetComponent);
    this.register('boolean', BooleanWidgetComponent);

    // Object - Displays in panel only if title or description are present
    this.register('object', ObjectWidgetComponent);
    // Flat - Never displays in panel and both title and description are ignored
    // Doesn't flat inner objects if they are not also set to the `flat` widget
    this.register('flat', ObjectWidgetComponent);
    // Panel - Always displays in panel even if title and description are not
    // present, in which case the header should be hidden.
    this.register('panel', ObjectWidgetComponent);

    // Complex
    this.register('array', ArrayWidgetComponent);
    this.register('select', SelectWidgetComponent);
    this.register('map-select', SelectWidgetComponent);
    this.register('remote-select', RemoteSelectWidgetComponent);
    this.register('collection-select', CollectionSelectWidgetComponent);
    this.register('collection-filter', CollectionFilterWidgetComponent);
  }
}
