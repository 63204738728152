import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { TcFormGroupIntervalModule } from './form-group-interval';
import { TcFormGroupTimeSetModule } from './form-group-time-set';

@NgModule({
  imports: [SharedModule, TcFormGroupIntervalModule, TcFormGroupTimeSetModule],
  exports: [TcFormGroupIntervalModule, TcFormGroupTimeSetModule],
})
export class TcFormGroupsModule {}
