export const feedbackRequestStatuses = [
  'scheduled',
  'pending',
  'urgent',
  'responded',
  'expired',
];

const feedbackRequestStatusesConst = [...feedbackRequestStatuses] as const;

export type TcFeedbackRequestStatus =
  typeof feedbackRequestStatusesConst[number];

// Lower number means higher priority
export const feedbackRequestStatusesPriority: {
  [key in TcFeedbackRequestStatus]: number;
} = {
  expired: 0,
  urgent: 1,
  pending: 2,
  scheduled: 3,
  responded: 4,
} as const;
