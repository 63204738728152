import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { first, map } from 'rxjs/operators';

import { LocalSettingsService } from '../../core/local-settings.service';
import { RemoteConfig } from '../../core/remote_config.service';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  private translations: { [key: string]: string } = {};

  constructor(
    private shepherdService: ShepherdService,
    private translateService: TranslateService,
    private localSettings: LocalSettingsService,
    private remoteConfig: RemoteConfig,
  ) {
    this.translateService
      .get([
        'tour.button.next',
        'tour.button.back',
        'tour.button.cancel',
        'tour.button.exit',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.shepherdService.defaultStepOptions = {
      classes: '',
      scrollTo: false,
      beforeShowPromise: () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 200);
        });
      },
      cancelIcon: {
        enabled: true,
      },
      when: {
        show() {
          const currentStepElement = this.getElement();
          const currentStepTour = this.getTour();
          const footer = currentStepElement.querySelector('.shepherd-footer');
          const progress = document.createElement('span');
          progress.className = 'shepherd-progress';
          progress.innerText = `${currentStepTour.steps.indexOf(this) + 1} / ${
            currentStepTour.steps.length
          }`;
          footer.insertBefore(
            progress,
            currentStepElement.querySelector('.shepherd-button:first-child'),
          );
        },
      },
    };
  }

  autoStart(section) {
    this.localSettings
      .get(`tour.${section}`, false)
      .pipe(first())
      .subscribe((completedAlready) => {
        if (!completedAlready) {
          this.start(section);
        }
      });
  }

  start(section) {
    this.stepsForSection(section)
      .pipe(first())
      .subscribe((steps) => {
        if (steps && steps.length > 0) {
          this.shepherdService.modal = true;
          this.shepherdService.confirmCancel = false;
          this.shepherdService.addSteps(steps);
          this.shepherdService.tourObject.on('complete', (event) => {
            this.complete(section);
          });

          this.shepherdService.start();
        }
      });
  }

  complete(section) {
    this.localSettings.set(`tour.${section}`, true);
  }

  tours(area) {
    return this.remoteConfig.get('tour').pipe(
      map((tours: any[]) => {
        return tours.filter((t) => t.key.startsWith(area));
      }),
    );
  }

  private stepsForSection(section) {
    const btnLookup = {
      cancel: {
        classes: 'ant-btn ant-btn-secondary',
        text: this.translations['tour.button.cancel'],
        type: 'cancel',
        action: function () {
          return this.cancel();
        },
      },
      back: {
        classes: 'ant-btn ant-btn-secondary',
        text: this.translations['tour.button.back'],
        type: 'back',
        action: function () {
          return this.back();
        },
      },
      next: {
        classes: 'ant-btn ant-btn-primary',
        text: this.translations['tour.button.next'],
        type: 'next',
        action: function () {
          return this.next();
        },
      },
      exit: {
        classes: 'ant-btn ant-btn-primary',
        text: this.translations['tour.button.exit'],
        type: 'next',
        action: function () {
          return this.complete();
        },
      },
    };

    return this.remoteConfig.getItem('tour', section).pipe(
      map((config: any) => {
        // const testData = this.testData();
        // if (testData) { config = testData }

        if (config) {
          return (config.steps || []).map((step) => {
            step = Object.assign({}, step);
            step.buttons = (step.buttons || ['cancel', 'back', 'next']).map(
              (btn) => btnLookup[btn],
            );

            if (step.wait) {
              step.beforeShowPromise = () => {
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(true);
                  }, step.wait);
                });
              };
            }

            return step;
          });
        }
      }),
    );
  }

  // private testData() {
  //   return {
  //     "label": "TEST",
  //     "steps": [
  //       {
  //         "id": "intro",
  //         "title": "Welcome to the dispo",
  //         "text": ["This Tutorial will lead you through the dispo!"],
  //         "wait": 1000,
  //         "buttons": ["exit", "next"]
  //       },
  //       {
  //         "id": "first",
  //         "attachTo": {
  //           "element": "tc-hub-dispo-view-settings",
  //           "on": "left"
  //         },
  //         "advanceOn": { "selector": "tc-hub-dispo-view-settings", "event": "click" },
  //         "title": "The Ansicht",
  //         "text": ["Click here"],
  //         "buttons": ["cancel", "back"]
  //       },
  //       {
  //         "id": "second",
  //         "attachTo": {
  //           "element": ".cdk-overlay-pane .ant-dropdown-menu li:nth-child(2)",
  //           "on": "left"
  //         },
  //         "advanceOn": { "selector": ".cdk-overlay-pane .ant-dropdown-menu li:nth-child(2)", "event": "click" },
  //         "title": "YEAH",
  //         "text": ["Now click on Dienstpläne | Mitarbeiterkalender"],
  //       },
  //       {
  //         "id": "third",
  //         "attachTo": {
  //           "element": "tc-hub-dt-plan",
  //           "on": "right"
  //         },
  //         "wait": 2000,
  //         "advanceOn": { "selector": "tc-hub-dt-plan", "event": "contextmenu" },
  //         "title": "YEAH",
  //         "text": ["Right Click on the Plan"]
  //       },
  //       {
  //         "id": "fourth",
  //         "title": "YEAH",
  //         "text": ["<video width=\"200\" controls autoplay>",
  //           "<source src=\"https://timecount-public.s3.eu-central-1.amazonaws.com/Arztauschreibung+-+App+Tutorial+-+Entwurf.mp4\" type=\"video/mp4\">",
  //           "Ihr Browser unterstützt das Video Element nicht",
  //           "</video>"
  //         ],
  //         "buttons": ["back", "exit"]
  //       }
  //     ]
  //   }
  // }
}
