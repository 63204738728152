import { isObject } from '@timecount/utils';

import { dig } from '../../core/helpers';

import { JsonCalculationObject } from './json-calculation-object.model';

export class JsonCalculator {
  constructor(private data: Record<string, any>) {}

  public evaluateStructure(structure: any) {
    // quickly check if the structure might be evaluatable
    const stringifiedStructure = JSON.stringify(structure);
    if (
      !stringifiedStructure.includes('"operation":') &&
      !stringifiedStructure.includes('"operands":')
    ) {
      return structure;
    }

    return this.recursivelyEvaluateStructure(structure);
  }

  public value(calc: JsonCalculationObject | string | number) {
    if (typeof calc === 'object' && calc !== null) {
      return this.calculateValue(calc);
    }

    if (typeof calc === 'string') {
      return dig(this.data, calc);
    }

    return calc;
  }

  private recursivelyEvaluateStructure(structure: any) {
    if (isObject(structure) && structure.operation && structure.operands) {
      return this.value(<JsonCalculationObject>structure);
    } else if (Array.isArray(structure)) {
      return structure.map((item) => this.recursivelyEvaluateStructure(item));
    } else if (isObject(structure)) {
      const evaluatedStructure = {};
      Object.keys(structure).forEach((key) => {
        evaluatedStructure[key] = this.recursivelyEvaluateStructure(
          structure[key],
        );
      });
      return evaluatedStructure;
    } else {
      return structure;
    }
  }

  private calculateValue(calc: JsonCalculationObject) {
    const operands = [...calc.operands];

    switch (calc.operation) {
      case 'add': {
        return operands.reduce((res, operand) => {
          return res + this.numericValue(operand);
        }, 0);
      }
      case 'sub': {
        const firstOp = operands.shift();

        return operands.reduce((res, operand) => {
          return res - this.numericValue(operand);
        }, this.numericValue(firstOp));
      }
      case 'div': {
        const firstOp = operands.shift();

        return operands.reduce((res, operand) => {
          return res / this.numericValue(operand);
        }, this.numericValue(firstOp));
      }
      case 'mul': {
        const firstOp = operands.shift();

        return operands.reduce((res, operand) => {
          return res * this.numericValue(operand);
        }, this.numericValue(firstOp));
      }
      case 'not': {
        const firstOp = operands.shift();

        return !this.booleanValue(firstOp);
      }
      case 'and': {
        const firstOp = operands.shift();

        return operands.reduce((res, operand) => {
          return res && this.numericValue(operand);
        }, this.numericValue(firstOp));
      }
      case 'or': {
        const firstOp = operands.shift();

        return operands.reduce((res, operand) => {
          return res || this.numericValue(operand);
        }, this.numericValue(firstOp));
      }
      case 'cmp': {
        const firstOp = operands.shift();
        const secondOp = operands.shift();

        // eslint-disable-next-line: triple-equals
        return this.value(firstOp) == this.value(secondOp);
      }
      case 'val': {
        return operands.shift();
      }
    }
  }

  private numericValue(key) {
    return +this.value(key) || 0;
  }

  private booleanValue(key) {
    return !!this.value(key);
  }
}
