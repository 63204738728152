<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <label>{{ 'dispo/import.attrs.plan_id' | translate }}</label>
            <tc-hub-remote-select
              [url]="planUrl"
              [formGroup]="form"
              createNewLabel="{{ 'dispo/plan.actions.create' | translate }}"
              controlName="plan_id"
              bindLabel="title"
              bindValue="id"
              searchable="true"
            >
            </tc-hub-remote-select>
          </div>
        </div>
      </div>
    </div>

    <div class="container" *ngIf="!attachToPlan">
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <tc-input-text-web
              label="{{ 'dispo/plan.attrs.title' | translate }}"
              formControlName="title"
            ></tc-input-text-web>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <tc-interval-web
            formControlName="dateInterval"
            [tcFieldType]="'date'"
            [tcStartLimit]="startLimit"
            [tcEndLimit]="endLimit"
            [tcLabels]="{
              start: ('dispo/plan.attrs.starts_at' | translate),
              end: ('dispo/plan.attrs.ends_at' | translate)
            }"
            [tcConfig]="{
              fieldType: 'date'
            }"
            [tcAllowZeroDuration]="true"
          ></tc-interval-web>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-2">
          <tc-input-color-web
            label="{{ 'dispo/plan.attrs.color' | translate }}"
            formControlName="color"
          ></tc-input-color-web>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      (click)="prev()"
    >
      {{ 'back' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
