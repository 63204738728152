import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AsyncValidator,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { add, isAfter, min, startOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import {
  DateUnicodeFormat,
  getBreakIntervalsFromIntervalList,
  getMinutesFromIntervalList,
  isValidDate,
  setTimeToDate,
  stringifyDate,
} from '@timecount/utils';

import { TcFormGroupIntervalService } from '../../form-groups/form-group-interval';
import { asyncStatusOfControl } from '../../shared';
import { TcFieldsetValidators } from '../../shared/fieldset.validators';

@Directive()
export abstract class TcFieldsetIntervalListBaseDirective
  implements OnChanges, OnDestroy, ControlValueAccessor, AsyncValidator
{
  @Input() maxTotalDuration: Duration;
  @Input() maxItemDuration: Duration;
  @Input() tcReferenceDate: Date;
  @Input() tcTimePickerSteps: number;
  @Input() startLimit: Interval;

  @Input() platform: 'ionic' | 'web' = 'web';

  @Output() tcStatusChange = new EventEmitter();

  innerForm: UntypedFormGroup;

  limits: Interval[] = [];
  duration: number;
  breakDuration: number;

  maxEndLimit: Date;

  get timesList(): UntypedFormArray {
    return this.innerForm?.get('timesList') as UntypedFormArray;
  }

  private _needsTimeOut;

  private _destroyed$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (_: unknown) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  constructor(
    public formBuilder: UntypedFormBuilder,
    public intervalService: TcFormGroupIntervalService,
    public formGroupIntervalService: TcFormGroupIntervalService,
    needsTimeOut = true,
  ) {
    this._needsTimeOut = needsTimeOut;
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnChanges({ tcReferenceDate }: SimpleChanges): void {
    if (!tcReferenceDate.firstChange) {
      const { currentValue } = tcReferenceDate;
      const formValue = this.timesList.get([0, 'start'])?.value;

      this._setMaxEndLimit(
        isValidDate(formValue) && isValidDate(currentValue)
          ? setTimeToDate(currentValue, formValue)
          : currentValue,
      );
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // ControlValueAccessor Implementation
  // ---------------------------------------------------------------------------

  writeValue(value: Interval[]): void {
    if (this.innerForm instanceof UntypedFormGroup) {
      this.timesList.reset([]);

      const controls = this._getFromGroups(value);

      controls.forEach((value, index) => {
        this.timesList.insert(index, value);
      });

      //hack to delete the last element added in the entry array
      //when applying to all, one empty row will be added at the end of the array
      if (
        this.timesList.value.length > value?.length &&
        this.timesList.value.length > 1
      ) {
        this.deleteTime(this.timesList.value.length - 1);
      }
    } else {
      this._setForm(value);
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.innerForm.disable() : this.innerForm.enable();
  }

  // ---------------------------------------------------------------------------
  // Validator Implementation
  // ---------------------------------------------------------------------------

  validate(): Promise<ValidationErrors | null> {
    return asyncStatusOfControl(this.innerForm, {
      needsTimeOut: this._needsTimeOut,
    });
  }

  // --------------
  // Public Methods
  // --------------

  /**
   * Adds an item after the provided index or at the end if the list, if no
   * index is provided.
   */
  addAfter(index?: number) {
    index ??= this.timesList.length - 1;

    const limit = {
      start: this.timesList?.get([index, 'end'])?.value,
      end: this.timesList?.get([index + 1, 'start'])?.value,
    };

    this.timesList.insert(index + 1, this._getItemFromGroup(undefined, limit));
  }

  deleteTime(index: number) {
    this.timesList.removeAt(index);
  }

  splitTime(index: number) {
    this.timesList.insert(
      index + 1,
      this._getItemFromGroup(
        {
          start: undefined,
          end: this.timesList.at(index).value.end,
        },
        {
          start: this.timesList.at(index).value.start,
          end: this.timesList?.at(index + 1)?.value.start,
        },
      ),
    );

    this.timesList.at(index).patchValue({
      end: undefined,
    });
  }

  setDateToStartOfDay(date: Date) {
    const newDate = startOfDay(date);
    return newDate;
  }

  // ---------------
  // Private Methods
  // ---------------

  private _setForm(initialValues: Interval[]): void {
    this.innerForm = this.formBuilder.group({
      timesList: this.formBuilder.array(this._getFromGroups(initialValues), [
        this._innerValidator(),
        TcFieldsetValidators.maxDuration(this.maxTotalDuration),
      ]),
    });

    this._onValueChangeHandler(initialValues);

    this.timesList.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        map((value) => value.map(({ config: _, ...dates }) => dates)),
      )
      .subscribe((value) => {
        this._onValueChangeHandler(value);
      });

    this.timesList.statusChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((status) => {
        this.tcStatusChange.emit(status);
      });
  }

  private _innerValidator(): ValidatorFn {
    return (control: UntypedFormArray): ValidationErrors | null => {
      const { start } = control.value?.[0] ?? {};

      const maxDate = isValidDate(start)
        ? add(start, this.maxTotalDuration)
        : undefined;

      control.controls.forEach((intervalControl: UntypedFormGroup) => {
        for (const key in intervalControl.controls) {
          const control = intervalControl.controls[key];

          if (
            isValidDate(control.value) &&
            isValidDate(maxDate) &&
            isAfter(control.value, maxDate)
          ) {
            control.setErrors({
              ['date_before_date']: {
                maxDate: stringifyDate(maxDate, DateUnicodeFormat.dateTime),
              },
            });
          } else if (control.hasError('date_before_date')) {
            control.setErrors(null);
          }
        }
      });

      return null;
    };
  }

  // OPTIMIZE: This should be replaced by actual maxDuration validation.
  // Jira ticket: DEV-1594 (In the requirements)
  private _setMaxEndLimit(start: Date) {
    const maxDurationEnd = isValidDate(start)
      ? add(start, this.maxTotalDuration)
      : null;

    this.maxEndLimit = min(
      [maxDurationEnd, this.startLimit?.end].filter(isValidDate),
    );
  }

  private _onValueChangeHandler(
    value: Interval[],
    options = { firstChange: false },
  ): void {
    // OPTIMIZE: Durations transformations should be replaced by pipes
    // Jira ticket: DEV-1594 (In the requirements)
    this.duration = getMinutesFromIntervalList(value);
    this.breakDuration = getMinutesFromIntervalList(
      getBreakIntervalsFromIntervalList(value),
    );

    // Must be defined before setting the limits
    this._setMaxEndLimit(value?.[0]?.start as Date);

    // OPTIMIZE: Limits definitions should be replaced by pipes
    // Jira ticket: DEV-1594 (In the requirements)
    this.limits = value.map((_, index) => {
      const { start: limitStart } = this.startLimit ?? {};
      const { end: previousEnd = limitStart } =
        index === 0
          ? this.maxEndLimit
          : this.timesList.at(index - 1)?.value ?? {};
      const { start: nextStart = this.maxEndLimit } =
        index === value.length - 1
          ? limitStart ?? {}
          : this.timesList.at(index + 1)?.value ?? {};

      return { start: previousEnd, end: nextStart };
    });

    if (!options.firstChange) {
      this.onChange(value);
    }
  }

  private _getFromGroups(
    value: Interval[],
  ): (UntypedFormGroup | UntypedFormControl)[] {
    value ??= [];

    return value.length > 0
      ? value.map((initialValue, index) => {
          const limit =
            index > 0
              ? {
                  start: value[index - 1]?.end,
                  end:
                    index === value.length - 1
                      ? add(value[0].start, this.maxTotalDuration)
                      : value[index + 1]?.start,
                }
              : null;

          return this._getItemFromGroup(initialValue, limit);
        })
      : [this._getItemFromGroup()];
  }

  private _getItemFromGroup(
    initialValue?: Interval,
    limit?: Interval,
  ): UntypedFormGroup | UntypedFormControl {
    return this.platform === 'web'
      ? new UntypedFormControl(initialValue ?? { start: null, end: null }, [
          Validators.required,
        ])
      : this.formGroupIntervalService.getIntervalFormGroup({
          fieldType: 'time',
          initialValue,
          limit,
          maxDuration: this.maxItemDuration,
          required: true,
        });
  }
}
