<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="well" style="margin-bottom: 10px">
        <div class="row">
          <div class="col-md-7">
            {{ 'attachment_release.batch.note' | translate }}
          </div>
          <div class="col-md-5">
            <button
              type="button"
              nz-button
              nzType="primary"
              *ngIf="release"
              (click)="toggle()"
              class="pull-right"
            >
              {{ 'attachment_release.batch.activ' | translate }}
            </button>
            <button
              type="button"
              nz-button
              nzType="default"
              *ngIf="!release"
              (click)="toggle()"
              class="pull-right"
            >
              {{ 'attachment_release.batch.inactiv' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="release">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-message-template-select
              label="{{ 'message/template.actions.select' | translate }}"
              [formGroup]="form"
              controlName="message_template_id"
              bindLabel="title"
              bindValue="id"
              resourceType="attachment_release"
              dataType="employee"
              [mapping]="{ body: 'body', subject: 'subject' }"
            ></tc-hub-message-template-select>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6">
          <div class="form-group" style="margin-top: 30px">
            <div class="checkbox lightswitch">
              <label class="sr-only">{{
                'attachment_release.attrs.attach_file' | translate
              }}</label>
              <label>
                <input type="checkbox" formControlName="attach_file" />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label">{{
                  'attachment_release.attrs.attach_file' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="release">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <div class="form-controls">
              <label>{{
                'attachment_release.attrs.subject' | translate
              }}</label>
              <div class="textarea-group">
                <div class="textarea-controls">
                  <tc-hub-placeholder
                    [formGroup]="form"
                    target="subject"
                    type="message/template.placeholders.employee"
                  ></tc-hub-placeholder>
                </div>
                <div class="textarea-form">
                  <textarea
                    class="form-control"
                    rows="1"
                    name="subject"
                    formControlName="subject"
                    autosize
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="release">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <div class="form-controls">
              <label>{{ 'attachment_release.attrs.body' | translate }}</label>
              <div class="textarea-group">
                <div class="textarea-controls">
                  <tc-hub-placeholder
                    [formGroup]="form"
                    target="body"
                    type="message/template.placeholders.employee"
                  ></tc-hub-placeholder>
                </div>
                <div class="textarea-form">
                  <textarea
                    class="form-control"
                    rows="5"
                    name="body"
                    formControlName="body"
                    autosize
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <tc-hub-custom-fields
        section="month.pdf_template.{{ document.template_handle }}"
        [formGroup]="form"
        formControlName="store"
      >
      </tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [disabled]="!valid()"
      (click)="next()"
    >
      {{ 'next' | translate }}
    </button>
  </div>
</form>
