import { TcEmailHeaders } from './email-headers.model';
export class TcSmtpConfig {
  address!: string;
  port = 587;
  auth: 'plain' | 'login' | 'cram_md5' = 'login';
  encryption: 'starttls_auto' | 'starttls' | 'tls' = 'starttls_auto';
  user!: string;
  password!: string;
  emailHeaders!: TcEmailHeaders;
  enabled = false;

  constructor({ port, auth, encryption, ...rest }: Partial<TcSmtpConfig>) {
    Object.assign(this, {
      ...rest,
      ...(port !== null && { port }),
      ...(auth !== null && { auth }),
      ...(encryption !== null && { encryption }),
    });
  }
  static parseFromApi?(value: TcSmtpConfigApi): TcSmtpConfig {
    const { sender, carbon_copy, blind_carbon_copy, ...rest } = value;
    return new TcSmtpConfig({
      ...rest,
      emailHeaders: {
        sender,
        carbon_copy,
        blind_carbon_copy,
      },
    });
  }

  static formatForApi?(value: TcSmtpConfig): TcSmtpConfigApi {
    const { emailHeaders, ...rest } = value;
    return {
      ...rest,
      ...emailHeaders,
    };
  }
}
export type TcSmtpConfigApi = Omit<TcSmtpConfig, 'emailHeaders'> &
  TcEmailHeaders;
