import { Injectable } from '@angular/core';

import { FrameCollection } from '../core/frame-collection';

import { Bias } from './bias.model';
import { BiasImpactColor } from './bias-impact-color';

@Injectable({
  providedIn: 'root',
})
export class BiasCollection extends FrameCollection {
  type = Bias;
  cache = 600;
  identifier = 'bias';
  endpoint = '/api/biases/range';

  localSearch = ['title'];

  decorate(bias: Bias, imports) {
    bias.color = BiasImpactColor[bias.impact];

    return bias;
  }
}
