import { CommonModule } from '@angular/common';
import { Component, OnInit, Optional } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  BehaviorSubject,
  filter,
  finalize,
  Subject,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';

import {
  BaseModalComponent,
  ModalConfig,
  ModalRef,
  TcCodeEditorComponent,
  TcFormWrapperComponent,
} from '@timecount/ui';
import { TcDentakuPipe, TcKeysListPipe } from '@timecount/utils';

import { CalculatedAttributes, ItemField, TcBuilder } from './builder-config';
import { TcVariablesListComponent } from './variables-list/variables-list.component';
import { TcFieldsListComponent } from './fields-list/fields-list.component';
import { TcFormulasService } from './formulas.service';
import { TcFormulasType } from './formula-type';

@Component({
  standalone: true,
  selector: 'tc-formulas',
  templateUrl: './formulas.component.html',
  styleUrls: ['./formulas.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzLayoutModule,
    NzSelectModule,
    TranslateModule,
    TcDentakuPipe,
    TcFormWrapperComponent,
    TcFieldsListComponent,
    TcCodeEditorComponent,
    TcKeysListPipe,
    TcVariablesListComponent,
  ],
})
export class TcFormulasComponent extends BaseModalComponent implements OnInit {
  type!: TcFormulasType;
  id!: number;

  private _calcAttrs = new BehaviorSubject<CalculatedAttributes[]>([]);
  public calcAttrs$ = this._calcAttrs.asObservable();

  itemAttrs: Record<string, ItemField> = {};

  selectedCalcAttr: CalculatedAttributes | null = null;

  showVariables = true;
  isLoading = true;
  isSubmitting = false;
  formGroup!: FormGroup;

  customFieldPrefix: '_' | 'store.' = '_';
  customFieldValue = '';

  private _initialValue: TcBuilder | undefined;

  private _destroyed$ = new Subject<void>();

  constructor(
    private _formulasService: TcFormulasService,
    @Optional() protected modalConfig?: ModalConfig,
    @Optional() protected modalReference?: ModalRef,
  ) {
    super(modalConfig, modalReference);
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.config?.data) {
      Object.assign(this, this.config.data);

      this._formulasService
        .getFormulas(this.type, this.id)
        .pipe(
          tap(({ item, title, tariff }) => {
            if (item) {
              this.itemAttrs = item;
            }

            this.modalReference?.setConfig({
              modalTitle: title,
              adminTags: [tariff.name],
            });
          }),
          finalize(() => (this.isLoading = false)),
        )
        .subscribe((data) => {
          this._initialValue = data;

          this.setData(data.calc_attrs);
        });
    }
  }

  onSubmit() {
    this.isSubmitting = true;

    const payload: Partial<CalculatedAttributes>[] = Object.entries<string>(
      this.formGroup.value,
    ).map(([field, formula]) => ({ field, formula }));

    this._formulasService
      .testFormulas(this.type, this.id, payload)
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe((data) => {
        this.setData(data.calc_attrs, false);
      });
  }

  onReset(selectedField: string) {
    const { calc_attrs } = this._initialValue || {};
    const field = calc_attrs?.find((obj) => obj.field === selectedField);

    if (field?.formula) {
      this.formGroup.get(selectedField)?.reset(field.formula);
    }
  }

  onRemoveCalcAttr(fieldId: string) {
    if (!fieldId) return;

    this.setData(
      this._calcAttrs.value.filter((attr) => attr.field !== fieldId),
    );
  }

  onAddCalcAttr(fieldId: string) {
    if (!fieldId) return;

    const calcAttrs = [...this._calcAttrs.value];
    const insertAt = fieldId.startsWith('_') ? 0 : calcAttrs.length;

    calcAttrs.splice(insertAt, 0, {
      field: fieldId,
      formula: '',
      result: null,
      variables:
        calcAttrs[insertAt > 0 ? insertAt - 1 : insertAt].variables ?? {},
    });

    if (fieldId.startsWith('_') || fieldId.startsWith('store.')) {
      this.customFieldValue = '';
    }

    this.setData(calcAttrs);
  }

  setData(
    calcAttrs: CalculatedAttributes[],
    resetSelectedCalcAttr: boolean = true,
  ) {
    this._calcAttrs.next(calcAttrs);
    this._buildForm(calcAttrs);

    // HACK: Timeout required to trigger change detection
    if (resetSelectedCalcAttr) {
      setTimeout(() => {
        this.selectedCalcAttr = calcAttrs[0];
      });
    }
  }

  private _buildForm(calcAttrs: CalculatedAttributes[] = []) {
    this.formGroup = new FormGroup({
      ...calcAttrs.reduce((acc, { field, formula }) => {
        acc[field] = new FormControl(formula);

        acc[field].valueChanges
          .pipe(
            // TODO: Every time _buildForm() is called, we need to unsubscribe
            // the previous subscriptions
            takeUntil(this._destroyed$),
            withLatestFrom(this.calcAttrs$),
            filter(([_, calcAttrs]) => {
              const calcAttr = calcAttrs.find((attr) => attr.field === field);
              return calcAttr?.result !== null;
            }),
          )
          .subscribe(([_, calcAttrs]) => {
            this._calcAttrs.next(
              calcAttrs.map((attr) =>
                attr.field === field ? { ...attr, result: null } : attr,
              ),
            );
          });

        return acc;
      }, {} as Record<string, FormControl>),
    });
  }
}
