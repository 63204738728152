import { Injectable } from '@angular/core';

import { FrameCollection } from '../../core/frame-collection';

@Injectable({
  providedIn: 'root',
})
export class BillingServiceEntryCollection extends FrameCollection {
  cache = 600;
  identifier = 'billing/service_entry';
  endpoint = '/api/finances/billing/service_entries/range';

  remoteDeleteValidation = true;
}
