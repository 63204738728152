import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Deserializer, ExclusionPolicy, Strategy } from 'typeserializer';

import { parseDate } from '@timecount/utils';

import { ApiErrorService } from '../core/api-error.service';
import { Cable } from '../core/cable';
import { CurrentUserService } from '../core/current-user.service';
import { FrameCollection } from '../core/frame-collection';
import { StoreService } from '../core/store.service';
import { DispoFocusService } from '../dispo/dispo-focus.service';
import { LocalSettingsService } from '../core/local-settings.service';

import { BalanceTypeCollection } from './balance_type.collection';
import { ComparableStat } from './comparable-stat.model';

@Strategy(ExclusionPolicy.NONE)
export class TimeBalanceStats extends ComparableStat {
  work_day_count: number;
  work_hours: number;
  sickness_day_count: number;
  sickness_hours: number;
  vacation_day_count: number;
  vacation_hours: number;
  custom_day_count: number;
  custom_hours: number;
  transfer_day_count: number;
  transfer_hours: number;
  unpaid_sickness_day_count: number;
  unpaid_sickness_hours: number;
  unpaid_vacation_day_count: number;
  unpaid_vacation_hours: number;
  education_day_count: number;
  education_hours: number;
  holiday_day_count: number;
  holiday_hours: number;
  absence_day_count: number;
  absence_hours: number;

  surcharges: Record<string, number>;
  supplements: Record<string, number>;
}

@Strategy(ExclusionPolicy.NONE)
export class TimeBalance extends ComparableStat {
  id: number;
  type: string;
  balance_type_id: number;

  auto_balance_id?: number;

  source_type?: string;
  source_id?: number;

  desription: string;

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;

  hours: number;
  modifier: number;

  surcharges: Record<string, number>;
  supplements: Record<string, number>;
  store: Record<string, unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class TimebalanceCollection extends FrameCollection {
  type = TimeBalance;
  cache = 600;
  identifier = 'time_balance';
  endpoint = '/api/time_balances/range';

  remoteValidation = true;
  remoteDeleteValidation = true;

  constructor(
    http: HttpClient,
    cable: Cable,
    dispoFocus: DispoFocusService,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    balanceTypeCollection: BalanceTypeCollection,
    localSettingsService: LocalSettingsService,
  ) {
    super(
      http,
      cable,
      dispoFocus,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
    );

    this.decorators = [balanceTypeCollection.all()];
  }

  decorate(time_balance, [types]) {
    time_balance.balance_type = types.find(
      (t) => t.id === time_balance.balance_type_id,
    );

    return time_balance;
  }

  forResource(resource) {
    const filterFunc = (x) =>
      x.resource_id === resource.id && x.resource_type === resource.type;
    const filterLookup = `resource_id: ${resource.id}|resource_type: ${resource.type}`;

    return this.filter(filterFunc, filterLookup);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TimeBalanceDayStatCollection extends FrameCollection {
  type = TimeBalanceStats;
  refresh = 300;
  identifier = 'time_balance_stats_day';
  endpoint = '/api/time_balances/range';
  query = {
    stats: 'day',
  };
}

@Injectable({
  providedIn: 'root',
})
export class TimeBalanceWeekStatCollection extends FrameCollection {
  type = TimeBalanceStats;
  refresh = 300;
  step = 60 * 60 * 24 * 30;
  identifier = 'time_balance_stats_week';
  endpoint = '/api/time_balances/range';
  query = {
    stats: 'week',
  };
}
