import { Pipe, PipeTransform } from '@angular/core';

import { isObject } from './object-helpers';

@Pipe({
  standalone: true,
  name: 'keysList',
})
export class TcKeysListPipe implements PipeTransform {
  transform(value: unknown): string[] | unknown {
    if (!value || !isObject(value)) {
      return value;
    }

    return Object.keys(value);
  }
}
