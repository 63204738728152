import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

import { TcCustomPickerBaseComponent } from './base/custom-picker-base.component';
import { TcNzPickerBaseComponent } from './base/nz-picker-base.component';
import { TcColorPickerComponent } from './color-picker/color-picker.component';
import { TcDatePickerHighlightedDayDirective } from './date-picker/date-picker-highlighted-day.directive';
import { TcDatePickerComponent } from './date-picker/date-picker.component';
import { TcTimePickerComponent } from './time-picker/time-picker.component';

@NgModule({
  declarations: [
    TcDatePickerComponent,
    TcTimePickerComponent,
    TcDatePickerHighlightedDayDirective,
    TcColorPickerComponent,
    TcCustomPickerBaseComponent,
    TcNzPickerBaseComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    NzListModule,
    NzButtonModule,
    NzDatePickerModule,
    NzTimePickerModule,
  ],
})
export class TcFormPickersModule {}
