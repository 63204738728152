import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

@Injectable({
  providedIn: 'root',
})
export class AttachmentReleaseCollection extends SetsCollection {
  identifier = 'attachments/release';
  endpoint = '/api/attachments/:attachment_id/releases';

  cache = 60 * 60 * 12;

  forAttachment(attachment_id) {
    return this.query(
      {
        attachment_id: attachment_id,
      },
      (a) => {
        return a.attachment_id === attachment_id;
      },
    );
  }
}
