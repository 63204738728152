import {
  deserialize,
  Deserializer,
  ExclusionPolicy,
  Strategy,
  Type,
} from 'typeserializer';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { dateToTzUnix, parseDate } from '@timecount/utils';

import { FrameCollection } from '../../core/frame-collection';
import { dateToString, integerToDate } from '../../core/helpers';
import { Cable } from '../../core/cable';
import { StoreService } from '../../core/store.service';
import { generateDateRanges } from '../../core/date_ranges';
import { ApiErrorService } from '../../core/api-error.service';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoFocusService } from '../dispo-focus.service';
import { LocalSettingsService } from '../../core/local-settings.service';

import {
  AvailabilityImpact,
  AvailabilityType,
  DispoAvailabilityTypeCollection,
} from './availability_type';
import {
  AvailabilitySource,
  DispoAvailabilitySourceCollection,
} from './availability_source';
import { DispoShift, DispoShiftCollection } from './shift';

const DAY_IN_S = 24 * 60 * 60;

export enum AvailabilityState {
  unconfirmed = 'unconfirmed',
  confirmed = 'confirmed',
  declined = 'declined',
}

@Strategy(ExclusionPolicy.NONE)
export class Availability {
  id: number;
  hash?: string;

  date?: string;
  marker: [number, number];
  offset: number;
  length: number;

  state: AvailabilityState;
  impact: AvailabilityImpact;

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;

  interval_type: string;
  interval_days: number[];
  interval_shifts: number[];

  resource_id: number;
  resource_type: string;

  note: string;
  title: string;

  shift?: DispoShift;
  shifts?: DispoShift[];
  base?: Availability;

  type_id: number;
  type: AvailabilityType;

  source_id: number;
  source: AvailabilitySource;

  @Type(Object)
  store: any;
}

@Injectable({
  providedIn: 'root',
})
export class DispoAvailabilityCollection extends FrameCollection {
  cache = 0;
  identifier = 'tc_dispo/availability';
  endpoint = '/api/dispo/availabilities/range';
  shifts: DispoShift[] = [];
  type = Availability;

  remoteValidation = true;
  remoteDeleteValidation = true;

  removeDataOnSync = true;

  constructor(
    http: HttpClient,
    cable: Cable,
    dispoFocus: DispoFocusService,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    availabilityTypeCollection: DispoAvailabilityTypeCollection,
    availabilitySourceCollection: DispoAvailabilitySourceCollection,
    shiftCollection: DispoShiftCollection,
    localSettingsService: LocalSettingsService,
  ) {
    super(
      http,
      cable,
      dispoFocus,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
    );

    shiftCollection.all().subscribe((shifts) => {
      this.shifts = shifts;
    });

    this.decorators = [
      availabilityTypeCollection.visible().all(),
      availabilitySourceCollection.all(),
      shiftCollection.all(),
    ];
  }

  decorate(availability, [types, sources, shifts]) {
    availability.type = Object.assign(
      {},
      types.find((t) => t.id === availability.type_id) || {},
    );
    // hack for keeping correct queries for avail.type.impact until we refactor them
    // TODO: remove once frontend config relies on avail.impact instead of avail.type.impact
    availability.type.impact =
      availability.state === 'confirmed'
        ? availability.type.impact_confirmed
        : availability.state === 'declined'
        ? availability.type.impact_declined
        : availability.type.impact;

    availability.source = sources.find((s) => s.id === availability.source_id);
    availability.shifts = shifts.filter(
      (s) => availability.interval_shifts.indexOf(s.id) !== -1,
    );
    availability.impact =
      availability.state === 'confirmed'
        ? availability.type?.impact_confirmed
        : availability.state === 'declined'
        ? availability.type?.impact_declined
        : availability.type?.impact;

    return availability;
  }

  /* overwrite observe function to return
   * base record instead of splitted records
   * observing interval avails will return the
   * base record
   */
  observeItems(currItems) {
    return super.observeItems(currItems).pipe(
      map((items) => {
        const result = [];
        const map = new Map();
        items.forEach((avail) => {
          if (!map.has(avail.id)) {
            result.push(avail.base || avail);
            map.set(avail.id, true);
          }
        });

        return result;
      }),
    );
  }

  /* overwrite observe function to return
   * base record instead of first splitted record
   */
  observeItem(currItem) {
    return super.observeItem(currItem).pipe(
      map((item) => {
        if (item && item.base) {
          return item.base;
        }

        return item;
      }),
    );
  }

  transform(entry: Availability) {
    entry = deserialize(JSON.stringify(entry), this.type);

    if (
      entry.interval_type === 'range' ||
      (entry.interval_type === 'interval' &&
        entry.interval_days.length === 0 &&
        entry.interval_shifts.length === 0)
    ) {
      return this.transformRangeEntry(entry);
    } else {
      return this.transformIntervalEntry(entry);
    }
  }

  transformRangeEntry(entry: Availability) {
    entry = Object.assign(new Availability(), entry);
    entry.starts_at = new Date(entry.starts_at.getTime());
    entry.ends_at = new Date(entry.ends_at.getTime());

    if (
      entry.offset === 0 &&
      (entry.length % DAY_IN_S < 120 || entry.length % DAY_IN_S > 86300)
    ) {
      entry.shift = Object.assign(new DispoShift(), {
        offset: 0,
        length: DAY_IN_S,
      });
    }

    return [entry];
  }

  transformIntervalEntry(entry: Availability) {
    const begin = dateToTzUnix(entry.starts_at);
    const end = dateToTzUnix(entry.ends_at);

    // FIX: on date change transformIntervalEntry is not being rerun
    // workaround for now, investigate and fix
    const from = begin;
    const to = end;

    const resp = [];

    const beginning_of_week = integerToDate(Math.max(from, begin));
    beginning_of_week.setDate(
      beginning_of_week.getDate() - beginning_of_week.getDay(),
    );
    beginning_of_week.setHours(0, 0, 0, 0);
    const beginning_of_week_stamp = dateToTzUnix(beginning_of_week);

    const end_date = integerToDate(Math.min(to, end));
    end_date.setDate(end_date.getDate() + 2);
    end_date.setHours(0, 0, 0, 0);
    const end_stamp = dateToTzUnix(end_date);

    const interval_days =
      entry.interval_days?.length === 0
        ? [0, 1, 2, 3, 4, 5, 6]
        : entry.interval_days;
    const interval_shifts =
      entry.shifts?.length === 0
        ? [{ offset: 0, length: DAY_IN_S }]
        : entry.shifts;

    let date_stamp = beginning_of_week_stamp;

    while (date_stamp < end_stamp) {
      const curr_week_stamp = date_stamp;

      interval_days.forEach((day) => {
        const curr_day_stamp = curr_week_stamp + day * DAY_IN_S;

        interval_shifts.forEach((shift) => {
          const shift_begin = curr_day_stamp + shift.offset;
          const shift_end = shift_begin + shift.length;

          const entry_begin_stamp = shift_begin;
          const entry_end_stamp = shift_end;

          if (entry_begin_stamp >= begin && entry_begin_stamp < end) {
            const entry_shift = Object.assign({}, entry);

            entry_shift.starts_at = integerToDate(entry_begin_stamp);
            entry_shift.ends_at = integerToDate(entry_end_stamp);
            entry_shift.date = dateToString(entry_shift.starts_at);
            entry_shift.hash = `${entry_shift.id}-${entry_shift.starts_at}`;
            entry_shift.shift =
              entry_begin_stamp !== shift_begin || entry_end_stamp !== shift_end
                ? undefined
                : shift;
            entry_shift.marker = [entry_begin_stamp, entry_end_stamp];
            entry_shift.base = entry;

            resp.push(entry_shift);
          }
        });
      });

      date_stamp = date_stamp + 7 * DAY_IN_S;
    }

    return resp
      .sort((a, b) => a.marker[0] - b.marker[0])
      .reduce((accumulatedShifts, nonAccumulatedShift) => {
        if (accumulatedShifts.length === 0) {
          return [nonAccumulatedShift];
        }

        const lastShift = accumulatedShifts[accumulatedShifts.length - 1];

        if (lastShift.marker[1] === nonAccumulatedShift.marker[0]) {
          lastShift.ends_at = nonAccumulatedShift.ends_at;
          lastShift.marker[1] = nonAccumulatedShift.marker[1];
          lastShift.shift = Object.assign({}, lastShift.shift || {});

          if (!lastShift.shift.subIds) {
            lastShift.shift.subIds = [];
          }

          if (!lastShift.shift.subIds.includes(nonAccumulatedShift.shift.id)) {
            lastShift.shift.subIds.push(nonAccumulatedShift.shift.id);
            lastShift.shift.title = [
              lastShift.shift.title,
              nonAccumulatedShift.shift.title,
            ]
              .filter((t) => t)
              .join(', ');
          }
        } else {
          accumulatedShifts.push(nonAccumulatedShift);
        }

        return accumulatedShifts;
      }, []);
  }

  transformEntryToGrid(entry, gridSize = DAY_IN_S, error = 0.005) {
    const begin = dateToTzUnix(entry.starts_at);
    const end = dateToTzUnix(entry.ends_at);

    if (entry.interval_type === 'interval' && end - begin <= gridSize) {
      return [entry];
    }

    const from = this.x;
    const to = this.y;

    const begin_stamp = Math.max(from, begin);
    const end_stamp = Math.min(to, end);

    let date_stamp = from;

    const resp = [];

    while (date_stamp <= end + gridSize) {
      const entry_begin_stamp = Math.max(date_stamp, begin);
      const entry_end_stamp = Math.min(date_stamp + gridSize, end_stamp);

      if (
        entry_begin_stamp <= end &&
        entry_end_stamp >= begin &&
        entry_end_stamp !== entry_begin_stamp
      ) {
        const grid_entry = Object.assign({}, entry);

        grid_entry.starts_at = integerToDate(entry_begin_stamp);
        grid_entry.ends_at = integerToDate(entry_end_stamp);
        grid_entry.date = dateToString(grid_entry.starts_at);
        grid_entry.shift =
          entry_begin_stamp === begin && entry_end_stamp === end
            ? grid_entry.shift
            : undefined;
        grid_entry.marker = [entry_begin_stamp, entry_end_stamp];
        grid_entry.hash = `${grid_entry.id}-${grid_entry.starts_at}`;
        grid_entry.base = entry;

        if (
          Math.abs((entry_end_stamp - entry_begin_stamp) / gridSize - 1) < error
        ) {
          grid_entry.shift = { full: true };
        }

        resp.push(grid_entry);
      }

      date_stamp = date_stamp + gridSize;
    }

    return resp;
  }

  forResource(resource) {
    const filterFunc = (x) =>
      x.resource_id === resource.id && x.resource_type === resource.type;
    const filterLookup = `resource_id: ${resource.id}|resource_type: ${resource.type}`;

    return this.filter(filterFunc, filterLookup);
  }

  fromTemplate(date, template) {
    date = new Date(date.getTime());

    const [starts_at, ends_at] = generateDateRanges(
      date,
      template.base,
      template.offset,
      template.length,
    );

    const obj = Object.assign(
      {},
      {
        interval_type: 'range',
      },
      template,
      {
        starts_at: starts_at,
        ends_at: ends_at,
        marker: [dateToTzUnix(starts_at), dateToTzUnix(ends_at)],
      },
    );

    delete obj.base;
    delete obj.offset;
    delete obj.length;

    return obj;
  }
}
