import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';
import { DispoSharedModule } from '../shared/dispo-shared.module';

import { DispoEmployeesCondensedComponent } from './condensed/dispo-employees-condensed.component';
import { DECEmployeeComponent } from './condensed/employee/employee.component';
import { DispoEmployeesComponent } from './expanded/dispo-employees-expanded.component';
import { DEEmployeeComponent } from './expanded/employee/employee.component';
import { DispoEmployeesSharedModule } from './shared/dispo-employees-shared.module';

@NgModule({
  imports: [SharedModule, DispoSharedModule, DispoEmployeesSharedModule],
  declarations: [
    DispoEmployeesComponent,
    DEEmployeeComponent,
    DispoEmployeesCondensedComponent,
    DECEmployeeComponent,
  ],
  exports: [
    DispoEmployeesComponent,
    DEEmployeeComponent,
    DispoEmployeesCondensedComponent,
    DECEmployeeComponent,
  ],
})
export class DispoEmployeesModule {}
