import { getWholeInterval, isValidDate } from '@timecount/utils';

import { TcIntervalList } from './interval-list.model';
import { TcIntervalWithBreak } from './interval-with-break.model';

export class TcIntervalSet {
  /**
   * Parse the times values from an API object
   *
   * @param [options.allowMultiBreaks]
   * If set to false or not set (the default), it will parse the value as a
   * `TcIntervalWithBreak`. If set to true it will parse as a `TcIntervalList`.
   */
  static parseFromApi(
    apiObject: unknown,
    options: {
      allowMultiBreaks?: boolean;
    } = {},
  ): TcIntervalWithBreak | Partial<Interval>[] {
    return options.allowMultiBreaks
      ? TcIntervalList.parseFromApi(apiObject)
      : TcIntervalWithBreak.parseFromApi(apiObject);
  }

  /**
   * Returns an `Interval` with the first `start` and the last `end` of the Set.
   */
  static getWholeSetInterval(
    object: unknown,
    options: { allowMultiBreaks?: boolean } = {},
  ): Interval {
    return options.allowMultiBreaks
      ? getWholeInterval(object as Interval[])
      : (object as TcIntervalWithBreak)?.mainInterval;
  }

  static timeSetToIntervalList(
    timesSet: TcIntervalWithBreak,
  ): Partial<Interval>[] {
    const { mainInterval, breakInterval } = timesSet;
    const intervalList: Partial<Interval>[] = [{ ...mainInterval }];

    if (isValidDate(breakInterval?.start)) {
      intervalList.push({ end: intervalList[0].end as Date });
      intervalList[0].end = breakInterval?.start as Date;
    }

    if (isValidDate(breakInterval?.end)) {
      intervalList[1].start = breakInterval?.end as Date;
    }

    return intervalList;
  }
}
