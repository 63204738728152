import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateInfo } from '@timecount/utils';

import { DispoMenuService } from '../menus/menu.service';
import { DispoFilterService } from '../../shared/filter/filter.service';
import { RemoteConfig } from '../../core/remote_config.service';
import { DispoDateInfoService } from '../dispo-date-info.service';
import { PlanService } from '../actions/plan.service';

import { DispoSchedulesService } from './dispo-schedules.service';
import { DSPlanComponent } from './plan/plan.component';

@Component({
  selector: 'tc-hub-dispo-schedules',
  templateUrl: './dispo-schedules.component.html',
  styleUrls: ['./dispo-schedules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispoSchedulesComponent implements OnInit, OnDestroy {
  @ViewChildren('planComponent') planComponents: QueryList<DSPlanComponent>;

  plans: Observable<any[]>;
  weeks$: Observable<DateInfo[][]>;

  style$ = new Subject();
  resizeObserver: ResizeObserver;

  showEmptyPlans$: Observable<boolean>;

  constructor(
    private remoteConfig: RemoteConfig,
    private elementRef: ElementRef,
    private filterService: DispoFilterService,
    private service: DispoSchedulesService,
    private contextMenuService: DispoMenuService,
    private dateInfoService: DispoDateInfoService,
    private planActions: PlanService,
    private zone: NgZone,
  ) {}

  ngOnInit() {
    this.plans = this.service.plans();
    this.weeks$ = this.dateInfoService.weeks();

    this.resizeObserver = new ResizeObserver((entries) => {
      this.zone.run(() => {
        this.style$.next({ 'width.px': entries[0].contentRect.width });
      });
    });
    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.showEmptyPlans$ = combineLatest(
      this.filterService.queryEmpty('dispo.schedules'),
      this.remoteConfig.getItem('view', 'dispo.schedules'),
    ).pipe(
      map(([queryEmpty, config]: [boolean, any]) => {
        if (queryEmpty) {
          return config?.data?.showEmpty;
        }

        return false;
      }),
    );
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.elementRef.nativeElement);
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  contextMenuHandler(event) {
    if (event.button === 2) {
      event.preventDefault();
      event.stopPropagation();
    }

    const contexts = event.menu_contexts || [];

    if (!contexts.find((i) => i.item_type === 'DispoPlan')) {
      contexts.push({ item_type: 'DispoPlan', item: [] });
    }

    if (!contexts.find((i) => i.item_type === 'DispoSchedule')) {
      contexts.push({ item_type: 'DispoSchedule', item: [] });
    }

    this.contextMenuService.onViewClick(event, 'dispo/schedules', contexts);
  }

  onSelect(e) {
    this.planComponents.map((s) => s.clearSelection());
  }

  createPlan() {
    this.planActions.createDefault();
  }
}
