import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { addSeconds } from 'date-fns';
import { combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  finalize,
  first,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { TcFieldSetIntervalSetService } from '@timecount/ui';
import { TcIntervalWithBreak } from '@timecount/core';

import { Action } from '../../../core/types/action';
import { ActionType } from '../../../core/types/action-type';
import { EntryType } from '../../../core/types/entry-type';
import { DispoAssignmentCollection } from '../../../dispo/collections/assignment';
import { Assignment } from '../../../dispo/collections/assignment.model';
import { Plan } from '../../../dispo/collections/plan';
import { DispoTaskCollection, Task } from '../../../dispo/collections/task';
import { ValidationService } from '../../../dispo/collections/validation.service';
import { Validation } from '../../../core/types/validation';
import { ValidationErrorLevel } from '../../../core/types/validation-error-level';

@Component({
  selector: 'tc-hub-dt-move-scheduled',
  templateUrl: './move-scheduled.component.html',
  styleUrls: ['./move-scheduled.component.scss'],
})
export class DTMoveScheduledComponent implements OnInit {
  @Input() tasks: Task[];
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  private translations: { [key: string]: string } = {};

  constructor(
    private taskCollection: DispoTaskCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private validationService: ValidationService,
    private formBuilder: UntypedFormBuilder,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
    private translateService: TranslateService,
    @Inject('Flash') private flash,
  ) {
    this.translateService
      .get([
        'errors.messages.validation_resolved',
        'errors.messages.validation_not_resolved',
        'dispo/assignment.errors.cannot_move_confirmed_assignment',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnInit() {
    this.buildForm();
  }

  validate() {
    // custom validators
  }

  // TODO: [DEV-1877] move this to a service and share behaviour with move-non-scheduled
  move() {
    this.loading = true;

    this.assignmentCollection
      .forTasks(this.tasks)
      .pipe(
        first(),
        mergeMap((allAssignments: Assignment[]) => {
          const tasks = [];
          const actions = [];
          const offsetValues =
            this.fieldSetIntervalSetService.formatValueToOffsetLength(
              this.form.value.times,
            );

          this.tasks.forEach((task) => {
            const newValues = Object.assign({}, task);

            const date = new Date(newValues.starts_at);
            date.setHours(0, 0, 0, 0);

            newValues.starts_at = addSeconds(
              new Date(date),
              offsetValues.offset,
            );
            newValues.ends_at = addSeconds(
              new Date(date),
              offsetValues.offset + offsetValues.length,
            );
            newValues.intermission_starts_at = offsetValues.intermission_offset
              ? addSeconds(new Date(date), offsetValues.intermission_offset)
              : undefined;
            newValues.intermission_ends_at = offsetValues.intermission_offset
              ? addSeconds(
                  new Date(date),
                  offsetValues.intermission_offset +
                    offsetValues.intermission_length,
                )
              : undefined;
            newValues.marker = [
              Math.round(newValues.starts_at.getTime() / 1000),
              Math.round(newValues.ends_at.getTime() / 1000),
            ];

            tasks.push(newValues);

            const assignments = allAssignments.filter(
              (a) => a.task_id === task.id,
            );
            const movedAssignments = assignments.map((a) =>
              this.assignmentCollection.move(a, a.position, newValues),
            );

            movedAssignments.forEach((assignment) => {
              actions.push(<Action>{
                id: uuid(),
                entry: assignment,
                entry_type: EntryType.Assignment,
                type: ActionType.confirm,
                errors: [],
                validations: (
                  item: Assignment,
                  _stack: Assignment[],
                ): Observable<Validation[]>[] => {
                  const validations$: Observable<Validation[]>[] = [];

                  if (item.times_state === 'confirmed') {
                    validations$.push(
                      of([
                        Object.assign(new Validation(), {
                          id: uuid(),
                          message:
                            this.translations[
                              'dispo/assignment.errors.cannot_move_confirmed_assignment'
                            ],
                          type: ValidationErrorLevel.critical,
                        }),
                      ]),
                    );
                  }

                  return validations$;
                },
              });
            });
          });

          if (actions.length) {
            return this.validationService.run(actions, false).pipe(
              mergeMap(() =>
                combineLatest(
                  tasks.map((t) => this.taskCollection.update(t.id, t)),
                ),
              ),
              tap(() => {
                this.flash.create(
                  this.translations['errors.messages.validation_resolved'],
                  'success',
                  6000,
                );
              }),
              map(() => 100),
              catchError((error: unknown) => {
                this.flash.create(
                  this.translations['errors.messages.validation_not_resolved'],
                  'error',
                  6000,
                );

                return of(error);
              }),
            );
          } else {
            return combineLatest(
              tasks.map((t) => this.taskCollection.update(t.id, t)),
            ).pipe(map(() => 100));
          }
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((delay: number) => {
        if (delay) {
          this.signal.emit({ action: 'close' });
        }
      });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      times: [TcIntervalWithBreak.parseFromApi(this.tasks[0])],
    });
  }
}
