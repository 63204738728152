import { Component, OnInit, ViewChild } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import {
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import { PanelMenu } from 'primeng/panelmenu';

import {
  convertToPrimeMenuItem,
  DispoMenuService,
  MenuItem,
} from '../menu.service';

@Component({
  selector: 'tc-hub-dispo-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
})
export class DispoActionMenuComponent implements OnInit {
  @ViewChild('menu') menu: PanelMenu;
  private _destroyed$: Subject<void> = new Subject();

  public items$ = new ReplaySubject(1);
  private menuSub: Subscription;

  constructor(private contextMenuService: DispoMenuService) {}

  ngOnInit() {
    this.contextMenuService.items
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (event: { originalEvent: MouseEvent; items: Observable<any[]>[] }) => {
          // TODO: investigate why only left clicks should change menu
          // if (event.originalEvent.button === 0) {
          this.updateMenu(event.originalEvent, event.items);
          // }
        },
      );
  }

  updateMenu(event, items: Observable<any[]>[]) {
    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }

    this.menuSub = combineLatest([...items])
      .pipe(
        takeUntil(this._destroyed$),
        map((items: MenuItem[][]) => {
          return convertToPrimeMenuItem(items.flat()).map((item, index) => {
            if (index === 0) {
              item.expanded = true;
            }

            return item;
          });
        }),
      )
      .subscribe((items: MenuItem[]): void => {
        this.menu.model = items;
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
