import type { Comment } from 'acorn';
import { parse } from 'acorn';
import { findNodeAfter } from 'acorn-walk';

import type { AcornNode } from './acorn-node';
import type { FormatFormulaOpts } from './format-formula-options';
import { escapeFormula, unescapeFormula } from './formula-helpers';
import { formatNode } from './format-node';

export function formatFormula(input: string, opts: FormatFormulaOpts = {}) {
  opts.fill ??= false;
  opts.prefix ??= '';
  opts.indent ??= true;

  const preparedFormula = escapeFormula(input);

  const comments: Comment[] = [];
  let ast = <AcornNode>{};

  try {
    ast = parseFormula(preparedFormula, comments);
  } catch (error) {
    if (error instanceof SyntaxError) {
      const result = handleSyntaxError(error, preparedFormula, opts);
      if (result === null) return input;
    }
    throw error;
  }

  const commentsByPos = processComments(comments, ast);
  const br = 86 - opts.prefix.length;
  const trailComment = commentsByPos['trail'];
  const formulaSlim =
    unescapeFormula(formatNode(ast, opts.indent, commentsByPos, br)) +
    prepareTrailComment(trailComment, opts);

  const ret = formatFormulaSlim(formulaSlim, opts, br);

  return ret.trim();
}

function parseFormula(preparedFormula: string, comments: Comment[]) {
  return JSON.parse(
    JSON.stringify(
      parse(preparedFormula, {
        ecmaVersion: 2020,
        onComment: comments,
      }),
    ),
  );
}

function handleSyntaxError(
  error: SyntaxError,
  preparedFormula: string,
  opts: FormatFormulaOpts,
) {
  const { pos, raisedAt } = JSON.parse(JSON.stringify(error));
  const msg = [
    `${error.message} "${unescapeFormula(
      preparedFormula.slice(pos, raisedAt),
    )}"`,
    `\u{1b}[32m${unescapeFormula(
      preparedFormula.slice(0, pos),
    )}\u{1b}[31m${preparedFormula.slice(
      pos,
      raisedAt,
    )}\u{1b}[0m${unescapeFormula(preparedFormula.slice(raisedAt))}`,
  ].join('\n');
  if (typeof opts.failures !== 'undefined') opts.failures.push(msg);

  return null;
}

function processComments(comments: Comment[], ast: AcornNode) {
  const commentsByPos: { [id: string]: string } = {};
  comments.forEach((c) => {
    const next = findNodeAfter(ast, c.start);
    const val = c.value.replace(/\s+/g, ' ').trim();
    if (next) commentsByPos[next.node.start] = val;
    else commentsByPos['trail'] = val;
  });

  return commentsByPos;
}

function prepareTrailComment(trailComment: string, opts: FormatFormulaOpts) {
  return trailComment ? `${opts.indent ? '\n' : ' '}/* ${trailComment} */` : '';
}

function formatFormulaSlim(
  formulaSlim: string,
  opts: FormatFormulaOpts,
  br: number,
) {
  return opts.indent && formulaSlim.includes('\n')
    ? `\n${formulaSlim
        .split('\n')
        .map(
          (f) =>
            `${opts.prefix}${opts.fill ? '  ' : ''}${f}${
              opts.fill && f.length < br
                ? ' '.repeat(br + 2 - f.length)
                : opts.fill
                ? '  '
                : ''
            }`,
        )
        .join('\n')}\n${opts.prefix}`
    : formulaSlim;
}
