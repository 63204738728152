<ion-item-group *rxLet="viewState$; let vm" [formGroup]="vm.formGroup">
  <ion-item
    lines="none"
    class="pickers"
    [class.align-right]="alignRight"
    [class.is-compact]="vm.isCompact"
    [class.no-title]="!title"
  >
    <ion-label
      slot="start"
      *ngIf="title"
      class="interval-label"
      [style.maxWidth.px]="vm.fieldWithDateOptions ? 50 : 104"
    >
      {{ title }}
    </ion-label>
    <div class="pickers-wrapper">
      <ng-container
        *ngFor="
          let fieldName of ['start', 'end'];
          first as isFirst;
          last as isLast
        "
      >
        <tc-date-time-picker-ionic
          [formControlName]="fieldName"
          [tcInitialValue]="pickerInitialValues[fieldName]"
          [fieldType]="
            vm.allowDoubledWorkingDay &&
            (vm.fieldWithDateOptions === fieldName ||
              vm.fieldWithDateOptions === 'both') &&
            (vm.alwaysVisibleDateOptions ||
              isFieldLimitLongerThan24h(fieldName))
              ? 'dateTime'
              : vm.fieldType
          "
          [tcConfig]="{
            required: vm.required,
            min: vm.fieldType === 'time' ? undefined : vm.min,
            max: vm.fieldType === 'time' ? undefined : vm.max,
            timePickerSteps: vm.timePickerSteps,
            limit:
              vm.fieldType === 'date'
                ? vm.limit
                : fieldName === 'start'
                ? startLimit
                : endLimit
          }"
          [tcShowDateOptions]="
            vm.allowDoubledWorkingDay &&
            (vm.fieldWithDateOptions === fieldName ||
              vm.fieldWithDateOptions === 'both')
              ? vm.alwaysVisibleDateOptions
                ? 'always'
                : 'if-needed'
              : null
          "
          [tcDateOptionsInterval]="vm.dateOptionsInterval"
          [tcDateOptionsRelative]="vm.relativeDateOptions"
          [isDurationExtended]="fieldName === 'end' && vm.isDurationExtended"
          [tcReferenceDate]="
            vm.fieldType !== 'date' && shouldConstrainInterval
              ? fieldName === 'start' || !vm.formGroup.value.start
                ? vm.refDate
                : vm.formGroup.value.start
              : null
          "
        ></tc-date-time-picker-ionic>

        <span *ngIf="isFirst"> - </span>
      </ng-container>
    </div>
    <tc-toggle-button
      *ngIf="vm.fieldWithDateOptions === null && vm.allowDoubledWorkingDay"
      class="btn-plus-24"
      [label]="'ASSIGNMENTS.TIME_TRACKING_PLUS24' | translate"
      [color]="'dark'"
      [disabled]="vm.formGroup.disabled"
      [tcCompact]="true"
      [ngModel]="vm.isDurationExtended"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onDurationExtendedChange($event)"
    >
    </tc-toggle-button>
    <tc-button-ionic
      *ngIf="allowClear"
      class="btn-clear"
      slot="end"
      size="small"
      color="dark"
      fill="clear"
      [tcCompact]="true"
      [disabled]="
        (!vm.formGroup.value?.start && !vm.formGroup.value?.end) ||
        vm.formGroup.disabled
      "
      (click)="reset()"
    >
      <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
    </tc-button-ionic>
  </ion-item>
</ion-item-group>
