import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';

import { DispoFocusService } from '../../dispo-focus.service';
import { DTDayComponent } from '../day/day.component';

@Component({
  selector: 'tc-hub-dt-paginate',
  templateUrl: './paginate.component.html',
  styleUrls: ['./paginate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DTPaginateComponent {
  @Input() showEmpty: Observable<boolean>;

  @Output() tcSelect: EventEmitter<'selected'> = new EventEmitter();

  @ViewChildren('dayComponent') dayComponents: QueryList<DTDayComponent>;

  dates: Observable<Date[]>;

  constructor(public dispoFocus: DispoFocusService) {
    this.dates = this.dispoFocus.dates();
  }

  // go(pos) {
  //   this.dispoFocus.date().pipe(first()).subscribe( (d) => {
  //     const movedDate = new Date(d);
  //     movedDate.setDate(movedDate.getDate() + pos);

  //     this.dispoFocus.setDate(movedDate);
  //   });
  // }

  onSelect() {
    this.tcSelect.emit('selected');
  }

  clearSelection() {
    this.dayComponents.map((d) => d.clearSelection());
  }

  trackByDate(index, item) {
    return item.getTime(); // or item.id
  }
}
