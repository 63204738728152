import * as _ from 'lodash-es';

import { toCamelCase } from '../string';

export function isObject(value: unknown): boolean {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function flattenObj(value: object): object {
  if (!isObject(value)) {
    throw new Error('An object is required.');
  }

  const res = {};

  for (const p in value) {
    if (isObject(value[p])) {
      const flatObj = flattenObj(value[p]);
      for (const q in flatObj) {
        res[p + '.' + q] = flatObj[q];
      }
    } else {
      res[p] = value[p];
    }
  }

  return res;
}

export function removeNullProperties(value: object): object {
  return _.omitBy(value, _.isNil);
}

export const keysToCamel = (value: object) => {
  if (isObject(value)) {
    const n = {};

    Object.keys(value).forEach((k) => {
      n[toCamelCase(k)] = keysToCamel(value[k]);
    });

    return n;
  } else if (Array.isArray(value)) {
    return value.map((i) => {
      return keysToCamel(i);
    });
  }

  return value;
};
