import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

import { TcFormValidationService } from '../form-validation.service';

@Directive({
  selector: '[tcFormControlValidator]',
})
export class TcFormControlValidatorDirective implements OnInit {
  @Input() private hasLabel = false;

  constructor(
    private el: ElementRef,
    private formControl: NgControl,
    private renderer: Renderer2,
    private formValidationService: TcFormValidationService,
  ) {}

  ngOnInit() {
    if (!this.formControl) {
      throw new Error('This directive must be added to a FormControl element.');
    }

    if (!this.hasLabel) {
      this.hasLabel =
        this.el.nativeElement?.parentElement?.tagName?.toLowerCase() ===
        'label';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const control = this.formControl.control as any;
    control.nativeElement = this.el.nativeElement;

    // Mark required input
    if (this.formValidationService.isControlRequired(control)) {
      this.markAsRequired(control);
    }

    if (this.hasLabel) {
      // Make sure to have the wrapper label tag with 100% width when the input is form-control
      if (control.nativeElement.classList.contains('form-control')) {
        this.renderer.setStyle(
          control.nativeElement.parentElement,
          'width',
          '100%',
        );
      }
    }

    (control as AbstractControl).statusChanges.subscribe((status) => {
      if (
        status === 'INVALID' &&
        this.formValidationService.isControlInvalid(control)
      ) {
        this.markAsInvalid(control);
      } else if (this.formValidationService.isControlRequired(control)) {
        this.markAsRequired(control);
      } else {
        this.markAsValid(control);
      }
    });
  }

  // ---------------
  // Private Methods
  // ---------------

  // HACK:
  // The below solutions are adding the `is-invalid` and `is-required`
  // classes to the element but they're not working with some HTML default
  // tags (e.g. input) with the bootstrap's form-control, so the border
  // styles are also being "forced" until a solution is found.

  private markAsInvalid(control): void {
    this.renderer.addClass(control.nativeElement, 'is-invalid');
    this.renderer.removeClass(control.nativeElement, 'is-required');

    this.renderer.setStyle(
      control.nativeElement,
      'border-color',
      'var(--tc-color-danger)',
    );
    this.renderer.setStyle(control.nativeElement, 'border-left-width', '4px');
  }

  private markAsRequired(control): void {
    this.renderer.removeClass(control.nativeElement, 'is-invalid');
    this.renderer.addClass(control.nativeElement, 'is-required');

    this.renderer.setStyle(
      control.nativeElement,
      'border',
      '1px solid #D2D2D2',
    );
    this.renderer.setStyle(
      control.nativeElement,
      'border-left',
      '4px solid var(--tc-color-warning)',
    );
  }

  private markAsValid(control): void {
    this.renderer.removeClass(control.nativeElement, 'is-invalid');
    this.renderer.removeClass(control.nativeElement, 'is-required');

    this.renderer.setStyle(
      control.nativeElement,
      'border',
      '1px solid #D2D2D2',
    );
  }
}
