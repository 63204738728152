import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { isEqual } from 'lodash-es';
import { filter, map, tap } from 'rxjs/operators';

import { User } from '../shared';

import { UserMenuData } from './types/user-menu.model';
import { Cable } from './cable';
import { Department, SelectableDepartment } from './types/department.model';
import { naturalSort } from './helpers';
@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private _user = new BehaviorSubject<User>(undefined);

  public user$: Observable<User> = this._user.asObservable();
  public departments$: Observable<unknown>;

  //properties used for legacy compatibility
  public user: User;
  public departments;

  public allDepartmentsIds;

  private actionMap = {
    update: ['manage', 'upsert'],
    create: ['manage', 'upsert'],
    index: ['manage', 'read', 'index'],
    show: ['manage', 'read', 'show'],
    delete: ['manage', 'delete'],
  };

  constructor(protected http: HttpClient, protected cable: Cable) {
    this._getUser();
    this.departments$ = this.http.get('/api/preferences/departments').pipe(
      map((resp) => resp[`data`]),
      tap((departments) => (this.departments = departments)),
    );
  }

  // --------------
  // Public Methods
  // --------------

  updateDepartments(department_ids: number[]) {
    const payload = { ...this.user, department_ids };

    this.http
      .put('api/preferences/user/' + this.user.id, payload)
      .subscribe((response) => {
        this.cable.triggerStale();
        this.user = response['data'];
        this._user.next(response['data']);
      });
  }

  getUserMenuData(allDepartments): Observable<UserMenuData> {
    this.allDepartmentsIds = allDepartments.map(
      (departments) => departments.id,
    );
    return this.user$
      ? this.user$.pipe(
          filter((user) => !!user),
          map((user) => {
            const availableDepartmentsIds = user['available_department_ids']
              .length
              ? user['available_department_ids']
              : allDepartments.map((department) => department.id);
            const departmentIds = user['department_ids'].length
              ? user['department_ids']
              : availableDepartmentsIds;
            return {
              name: user.first_name + ' ' + user.last_name,
              activeDepartmentsNumber: departmentIds.length,
              departments: allDepartments
                .filter((department: Department) =>
                  availableDepartmentsIds.includes(department.id),
                )
                .sort((a, b) => naturalSort(a.title, b.title))
                .map((department: Department): SelectableDepartment => {
                  return {
                    ...department,
                    isSelected: departmentIds.includes(department.id),
                  };
                }),
            };
          }),
        )
      : of({
          name: undefined,
          activeDepartmentsNumber: undefined,
          departments: undefined,
        });
  }

  departmentIds(): number[] {
    if (this.user.department_ids.length === 0) {
      return this.departments.map((d) => d.id);
    } else {
      return this.user.department_ids;
    }
  }

  hasAccessToRecord(record, recordType) {
    if (this.user.department_ids.length === 0) {
      return true;
    }

    if (Array.isArray(record.department_ids)) {
      return (
        record.department_ids.filter((id) => this.departmentIds().includes(id))
          .length !== 0
      );
    }

    if (typeof record.department_id !== 'undefined') {
      return this.departmentIds().indexOf(record.department_id) !== -1;
    }

    return true;
  }

  hasAccessToSection(section) {
    return !window.config.hidden_views.includes(section);
  }

  hasAccessToFeature(feature) {
    return window.config.features.includes(feature);
  }

  can(action, resource, conditionScope = {}) {
    return (this.actionMap[action] || [action]).some((alias) => {
      return (window.config.permissions[alias][resource] || []).some(
        (condition) => {
          return (
            JSON.stringify(condition) === '{}' ||
            isEqual(condition, conditionScope)
          );
        },
      );
    });
  }

  // ---------------
  // Private Methods
  // ---------------

  private _getUser() {
    this.http
      .get('/api/preferences/user')
      .pipe(map((resp) => resp[`data`][0]))
      .subscribe((user) => {
        this.user = user;
        this._user.next(user);
      });
  }
}
