import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TcApiResponse } from '@timecount/core';

import { PostConfigs } from './post.config';
import { Post } from './post.model';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  public postConfigs: PostConfigs = {
    'feature-add': {
      icon: 'tc:feature-add',
      color: 'var(--tc-color-success)',
      showAlert: false,
    },
    'feature-progress': {
      icon: 'tc:feature-progress',
      color: 'var(--tc-color-success)',
      showAlert: false,
    },
    system: {
      icon: 'setting',
      color: 'var(--tc-color-warning)',
      showAlert: false,
    },
    warning: {
      icon: 'warning',
      color: 'var(--tc-color-warning)',
      showAlert: true,
    },
    error: {
      icon: 'alert',
      color: 'var(--tc-color-danger)',
      showAlert: true,
    },
  };

  private _apiEndpoint = '/api/preferences/posts';
  private _posts$: Observable<Post[]>;

  constructor(private http: HttpClient) {
    this._posts$ = this.http
      .get(this._apiEndpoint)
      .pipe(
        map((resp: TcApiResponse<unknown[]>) =>
          resp.data.map((item) => Post.parseFromApi(item)),
        ),
      );
  }

  getAll(): Observable<Post[]> {
    return this._posts$;
  }

  getAlerts(): Observable<Post[]> {
    return this._posts$.pipe(
      map((posts) =>
        posts.filter((post) => this.postConfigs[post.type].showAlert === true),
      ),
    );
  }
}
