import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { DispoInvitationCollection } from '../../collections/invitation';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';
import { DispoTaskCollection } from '../../collections/task';
import { DispoResourceDataStructure } from '../../datastructures/resource.service';
import { DispoFilterService } from '../../../shared/filter/filter.service';
import { SetCollection } from '../../../core/sets-collection';
import { EmployeeCollection } from '../../../employees/employee.collection';

@Component({
  selector: 'tc-hub-dan-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css'],
})
export class DANInvitationsComponent implements OnInit {
  @Input() announcement: Announcement;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  public invitations$: Observable<any[]>;

  public loading = false;
  public invitations: any[] = [];
  public resourcesToRemove: { [key: string]: boolean } = {};
  public resourcesRemoveAll = false;
  public resourcesRemoveAllIndeterminate = false;

  private employeeService: SetCollection;

  constructor(
    private employeeSets: EmployeeCollection,
    private filterService: DispoFilterService,
    private invitationCollection: DispoInvitationCollection,
    private taskCollection: DispoTaskCollection,
    private announcementCollection: DispoAnnouncementCollection,
    private resourceDS: DispoResourceDataStructure,
  ) {
    this.employeeService = this.employeeSets.visible();
  }

  ngOnInit() {
    this.invitations$ = combineLatest(
      this.employeeService.all(),
      this.invitationCollection.forAnnouncement(this.announcement),
      this.announcementCollection.observeItem(this.announcement),
    ).pipe(
      map(([employees, invitations, announcement]) => {
        invitations = [...invitations];
        announcement.resources.forEach((resource) => {
          const invitation = invitations.find(
            (i) =>
              i.resource_type === resource.type &&
              i.resource_id === resource.id,
          );

          if (!invitation) {
            invitations.push({
              resource_id: resource.id,
              resource_type: resource.type,
              state: 'shallow',
            });
          }
        });

        invitations = invitations.map((invitation, index) => {
          invitation = Object.assign({}, invitation);
          invitation.resource = employees.find(
            (e) =>
              e.type === invitation.resource_type &&
              e.id === invitation.resource_id,
          );
          invitation.disabled = !!invitation.id;
          invitation.index = index;

          return invitation;
        });

        return invitations;
      }),
      map((invitations) => {
        this.invitations = invitations;
        this.updateAllResources();

        return invitations;
      }),
    );
  }

  delete(resource_ids: number[]) {
    this.loading = true;

    this.announcementCollection
      .observeItem(this.announcement)
      .pipe(first())
      .subscribe({
        next: (announcement) => {
          announcement = Object.assign({}, announcement);
          const resources = Object.assign([], announcement.resources);

          resource_ids.forEach((resource_id) => {
            const resourceIndex = resources.findIndex(
              (r) => r.type === 'Employee' && r.id === resource_id,
            );

            if (resourceIndex !== -1) {
              resources.splice(resourceIndex, 1);
            }

            delete this.resourcesToRemove[resource_id];
          });

          announcement.resources = resources;
          this.announcementCollection
            .update(announcement.id, announcement)
            .subscribe({
              next: (announcement) => {
                this.loading = false;
                this.announcement = <Announcement>announcement;
              },
              error: () => {
                this.loading = false;
              },
            });
        },
      });
  }

  deleteSelected() {
    this.loading = true;

    const selectedIds = [];

    Object.keys(this.resourcesToRemove).forEach((id) => {
      if (this.resourcesToRemove[id]) {
        selectedIds.push(parseInt(id, 10));
      }
    });

    this.delete(selectedIds);
  }

  updateAllResources() {
    this.resourcesRemoveAllIndeterminate = false;

    Object.keys(this.resourcesToRemove).forEach((id) => {
      delete this.resourcesToRemove[id];
    });

    this.invitations.forEach((invitation) => {
      if (!invitation.disabled) {
        this.resourcesToRemove[invitation.resource_id] =
          this.resourcesRemoveAll;
      }
    });
  }

  updateSingleResource() {
    if (Object.values(this.resourcesToRemove).every((checked) => !checked)) {
      this.resourcesRemoveAll = false;
      this.resourcesRemoveAllIndeterminate = false;
    } else if (
      Object.values(this.resourcesToRemove).every((checked) => checked)
    ) {
      this.resourcesRemoveAll = true;
      this.resourcesRemoveAllIndeterminate = true;
    } else {
      this.resourcesRemoveAllIndeterminate = true;
    }
  }

  trackByIndex(_: number, data: any): number {
    return data.index;
  }

  removeCount() {
    return Object.values(this.resourcesToRemove).filter((v) => v).length;
  }
}
