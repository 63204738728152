import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash-es';

import { AggregationCollection } from '../aggregation.collection';
import { Project } from '../../project.model';
import { Aggregation } from '../aggregation.model';
import { TcOptionsService } from '../../../core/options.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tc-hub-aggregation-layout-form',
  templateUrl: './aggregations-layout-form.component.html',
  styleUrls: ['./aggregations-layout-form.component.scss'],
})
export class TcAggregationsLayoutFormComponent implements OnInit, OnChanges {
  formGroup: FormGroup;

  @Input() tcInitialValue: Aggregation;
  @Input() tcProject: Project;

  @Input() triggerSave: boolean;
  @Output() updatedValues: EventEmitter<any> = new EventEmitter();

  groupingOptions = [];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _aggregationCollection: AggregationCollection,
    @Inject('ResourceConfig') private resourceConfig,
    private optionsService: TcOptionsService,
    private translateService: TranslateService,
  ) {
    this._initializeGroupingOptions();
  }
  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this._buildForm();
  }

  ngOnChanges({ triggerSave }: SimpleChanges): void {
    if (triggerSave?.currentValue) {
      this.save();
    }
  }

  // --------------
  // Public Methods
  // --------------
  save() {
    const {
      customerName,
      aggregationLayout,
      aggregationHandleLayout,
      ...formValues
    } = this.formGroup.getRawValue();

    const payload = {
      ...this.tcInitialValue,
      ...formValues,
      store: _.merge(
        this.tcInitialValue?.store,
        aggregationLayout,
        aggregationHandleLayout,
      ),
      customer: {
        ...this.tcInitialValue.customer,
        name: formValues.customerName,
      },
    };
    payload.customer.name = customerName;

    this._aggregationCollection
      .forProject(this.tcProject.id)
      .update(this.tcInitialValue.id, payload);
    this.updatedValues.emit(payload);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _initializeGroupingOptions() {
    const options = this.optionsService.options('aggregation.group_by');
    this.groupingOptions = options.map((option) => ({
      id: option.id,
      title: this.translateService.instant(option.title),
    }));

    this.resourceConfig.getClusterers().then((clusters) => {
      return clusters.forEach((cluster) => {
        const translatedTitle = this.translateService.instant(cluster.title);
        this.groupingOptions.push({ id: cluster.key, title: translatedTitle });
      });
    });
  }

  private _buildForm() {
    const {
      group_by,
      locked,
      subject,
      description,
      comments,
      customer,
      recipient,
      department,
      address_addon,
      first_name,
      last_name,
      street,
      street_no,
      zipcode,
      place,
      country,
      store,
    } = this.tcInitialValue;

    this.formGroup = this._formBuilder.group({
      group_by: [group_by],
      subject: [subject],
      description: [description],
      comments: [comments],
      customerName: [{ value: customer.name, disabled: true }],
      recipient: [recipient],
      department: [department],
      address_addon: [address_addon],
      first_name: [first_name],
      last_name: [last_name],
      street: [street],
      street_no: [street_no],
      zipcode: [zipcode],
      place: [place],
      country: [country],
      aggregationHandleLayout: [store],
      aggregationLayout: [store],
    });

    if (locked) {
      this.formGroup.disable();
    }
  }
}
