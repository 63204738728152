import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { Menu } from 'primeng/menu';

import { MenuComponent } from './menu.component';

@Component({
  selector: 'tc-hub-menu-item-content',
  templateUrl: './menu-item-content.component.html',
  styleUrls: ['./menu-item-content.component.scss'],
})
export class MenuItemContentComponent {
  @Input() item: MenuItem;

  menu: Menu;

  constructor(menu: MenuComponent) {
    this.menu = menu;
  }
}
