import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClickContext, DispoMenuService } from '../dispo/menus/menu.service';

@Directive({
  selector: '[tcHubDispoMenuActive]',
})
export class DispoMenuActiveDirective implements OnInit, OnDestroy {
  @Input() activeType: string;
  @Input() activeId: any = undefined;
  @Input() activeMatch = {};

  private _destroyed$: Subject<void> = new Subject();

  constructor(
    private contextMenuService: DispoMenuService,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.contextMenuService.contexts
      .pipe(takeUntil(this._destroyed$))
      .subscribe((contexts: ClickContext[]) => {
        contexts = [...contexts];
        const mainContext = contexts.splice(0, 1)[0];

        if (
          mainContext &&
          mainContext.item_type === this.activeType &&
          this.match(mainContext.item, contexts)
        ) {
          this.el.nativeElement.classList.add('menu-active');
        } else {
          this.el.nativeElement.classList.remove('menu-active');
        }
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private match(items: any[], otherContexts) {
    if (this.activeId) {
      return items.some((item) => item.id === this.activeId);
    }

    return items.some((item) => {
      return Object.keys(this.activeMatch).every(
        (key) => item[key] === this.activeMatch[key],
      );
    });
  }
}
