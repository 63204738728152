import {
  AsyncSubject,
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  bufferTime,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  flatMap,
  map,
  shareReplay,
  skipWhile,
  switchMap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { deserialize } from 'typeserializer';
import format from 'date-fns/format';
import FuzzySearch from 'fuzzy-search';

import {
  dateToTzUnix,
  DateUnicodeFormat,
  parseDate,
  removeNullProperties,
} from '@timecount/utils';

import { DispoFocusService } from '../dispo/dispo-focus.service';

import { Cable } from './cable';
import { debug, overlaps } from './helpers';
import { ApiErrorService } from './api-error.service';
import { StoreService } from './store.service';
import { CurrentUserService } from './current-user.service';
import { Validation } from './types/validation';
import { ActionType } from './types/action-type';
import { DeletableResponse } from './types/deletable-response.model';
import { ValidationErrorLevel } from './types/validation-error-level';
import { LocalSettingsService } from './local-settings.service';

@Injectable({
  providedIn: 'root',
})
export class FrameCollection {
  public step = 60 * 60 * 24 * 14;
  public refresh: number;
  public standby = true;
  public cache = 0;
  public type: any;
  public query: any;
  public identifier: string;
  public endpoint: string;
  public remoteValidation = false;
  public remoteDeleteValidation = false;
  public localSearch;
  public bindLabel;
  public removeDataOnSync = false;

  public client_id;

  public decorators: Observable<any>[] = [];

  public x: number;
  public y: number;

  protected filters = {};
  protected initialized = false;

  protected frames: Frame[] = [];
  protected frames$: BehaviorSubject<Frame[]> = new BehaviorSubject([]);
  protected framesResult$$: ReplaySubject<Observable<any[]>[]> =
    new ReplaySubject(1);
  protected framesLoadSignal$$: ReplaySubject<Observable<boolean>[]> =
    new ReplaySubject(1);
  protected framesSyncSignal$$: ReplaySubject<Observable<boolean>[]> =
    new ReplaySubject(1);

  protected result$: ReplaySubject<any[]> = new ReplaySubject(1);

  protected operations$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  protected loadSignal$: ReplaySubject<boolean> = new ReplaySubject(1);
  protected syncSignal$: ReplaySubject<boolean> = new ReplaySubject(1);
  protected syncItems$: Subject<any> = new Subject();

  constructor(
    protected http: HttpClient,
    protected cable: Cable,
    protected dispoFocus: DispoFocusService,
    protected store: StoreService,
    protected errorHandler: ApiErrorService,
    protected currentUser: CurrentUserService,
    protected localSettingsService: LocalSettingsService,
  ) {
    this.client_id = uuid();
  }

  init() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    // action cable sync
    this.cable.subscribe((sync) => {
      if (Array.isArray(sync.data) && sync.data.length === 0) {
        return;
      }

      if (
        sync.resource === this.identifier &&
        sync.client_id !== this.client_id
      ) {
        if (this.currentUser.hasAccessToRecord(sync.data, sync.resource)) {
          this.syncItems$.next(sync);
        }
      }
    });

    this.cable.connectionStale().subscribe(() => {
      this.refreshCollection();
    });

    this.syncItems$.pipe(bufferTime(200)).subscribe((syncs) => {
      syncs.forEach((sync) => {
        this.handleSync(sync);
      });
    });

    // init operations
    this.localSettingsService
      .get(this.identifier + '.operation', [])
      .pipe(first())
      .subscribe((operation: any) => {
        this.operations$.next(operation);
      });

    // init operations check
    if (!this.refresh) {
      this.operations$
        .pipe(
          debounceTime(2000),
          filter((ops) => ops.length > 3),
        )
        .subscribe((ops) => {
          ops = [...ops].sort();
          let missingOps = false;

          ops.reduce((lastOp, op) => {
            if (lastOp && lastOp !== op && lastOp < op - 1) {
              missingOps = true;
            }

            return op;
          });

          if (missingOps) {
            this.refreshCollection();
          } else {
            this.localSettingsService.set(this.identifier + '.operation', ops);
          }
        });
    }

    // init ranges
    this.dispoFocus
      .dates()
      .pipe(debounceTime(50))
      .subscribe((dates) => {
        const begin = new Date(dates[0].getTime());
        const end = new Date(dates[dates.length - 1].getTime());

        begin.setHours(0, 0, 0);
        end.setHours(23, 59, 59);

        this.setRanges(dateToTzUnix(begin), dateToTzUnix(end));
      });

    // init HigherOrder Observables
    this.frames$.subscribe((frames) => {
      this.framesLoadSignal$$.next(frames.map((f) => f.loaded()));
      this.framesSyncSignal$$.next(frames.map((f) => f.synced()));
      this.framesResult$$.next(frames.map((f) => f.all()));
    });

    // init load signal
    this.loadSignal$.next(false);
    this.framesLoadSignal$$
      .pipe(
        // unpack the latest signal Observables
        switchMap((loadSignals$) => combineLatest(...loadSignals$)),
        debounceTime(50),
      )
      .subscribe((loadSignals: boolean[]) => {
        if (loadSignals.indexOf(false) !== -1) {
          this.loadSignal$.next(false);
        } else {
          this.loadSignal$.next(true);
        }
      });

    // init sync signal
    this.syncSignal$.next(false);
    this.framesSyncSignal$$
      .pipe(
        // unpack the latest signal Observables
        switchMap((syncSignals$) => combineLatest(...syncSignals$)),
        debounceTime(50),
      )
      .subscribe((syncSignals: boolean[]) => {
        if (syncSignals.indexOf(false) === -1) {
          this.syncSignal$.next(true);
        } else {
          this.syncSignal$.next(false);
        }
      });

    this.framesResult$$
      .pipe(
        // unpack the latest frame results
        switchMap((results$) => combineLatest(...results$)),
        // concat frame results
        map((frameResults: any[][]): any[] => {
          const results = [];
          const ids = {};

          frameResults.forEach((frameResult) => {
            frameResult.forEach((item) => {
              const id = item.hash ? item.hash : item.id;
              if (!ids[id] || id === undefined) {
                ids[id] = true;
                results.push(item);
              }
            });
          });

          return results;
        }),
      )
      .subscribe((results) => {
        this.result$.next(results);
      });

    this.loadSignal$.subscribe((signal) => {
      debug(this.constructor.name + ' loaded: ' + signal);
    });

    this.syncSignal$.subscribe((signal) => {
      debug(this.constructor.name + ' synced: ' + signal);
    });
  }

  all(): Observable<any> {
    this.init();

    return this.result$;
  }

  label() {
    return this.bindLabel;
  }

  loaded(): Observable<boolean> {
    this.init();

    return this.loadSignal$;
  }

  synced(): Observable<boolean> {
    this.init();

    return this.syncSignal$;
  }

  filter(func, identifier): Observable<any[]> {
    this.init();

    if (!this.filters[identifier]) {
      const subject = new ReplaySubject(1);

      this.filters[identifier] = {
        filter: func,
        subject: subject,
        value: [],
      };

      this.loadSignal$.pipe(filter((signal) => signal)).subscribe((_) => {
        this.result$.pipe(first()).subscribe((result) => {
          result = result.filter(func);
          this.filters[identifier].value = result;
          subject.next(result);
        });
      });
    }

    return this.filters[identifier].subject;
  }

  find<T = unknown>(id: number, options = { request: {} }): AsyncSubject<T> {
    const url = `${this.endpoint}/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const response$ = new AsyncSubject<T>();

    if (id) {
      this.http
        .get(url, Object.assign(httpOptions, options.request))
        .pipe(first())
        .subscribe({
          next: (resp: any) => {
            this.processItem(resp.data).subscribe({
              next: (item) => {
                response$.next(item);
                response$.complete();
              },
            });
          },
          error: (error: any) => {
            response$.error(error);
            response$.complete();
          },
        });
    } else {
      response$.error('');
    }

    return response$;
  }

  get(id: number, options = { request: {} }) {
    this.init();

    const response$ = new AsyncSubject();
    this.all()
      .pipe(first())
      .subscribe({
        next: (items: any[]) => {
          const item = items.find((t) => t.id === id);

          if (item && id) {
            response$.next(item);
            response$.complete();
          } else {
            this.find(id, options).subscribe({
              next: (remoteItem) => {
                response$.next(remoteItem);
              },
              error: (error: any) => {
                response$.error(error);
              },
              complete: () => {
                response$.complete();
              },
            });
            response$.error('Not found');
          }
        },
        error: (error: any) => {
          response$.error(error);
        },
        complete: () => {
          response$.complete();
        },
      });

    return response$;
  }

  observeItem(item) {
    this.init();

    const itemId = item.id;
    return this.filter((item) => item.id === itemId, `id: ${itemId}`).pipe(
      map((items) => {
        return items[0];
      }),
      skipWhile((item) => !item),
    );
  }

  observeItems(items) {
    this.init();

    const itemIds = items
      .map((i) => i.id)
      .filter((id) => id)
      .sort();
    return this.filter(
      (item) => itemIds.includes(item.id),
      `ids: ${itemIds}`,
    ).pipe(map((items) => items.filter((item) => !!item)));
  }

  remoteSearch(query: string, options = { request: {} }) {
    if (!query) {
      query = ' ';
    }

    const url = `${this.endpoint}/search/${encodeURIComponent(query)}`;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const response$ = new AsyncSubject();

    this.http
      .get(url, Object.assign(httpOptions, options.request))
      .pipe(first())
      .subscribe({
        next: (resp: any) => {
          this.processItems(resp.data).subscribe({
            next: (item) => {
              response$.next(item);
              response$.complete();
            },
          });
        },
        error: (error: any) => {
          response$.error(error);
          response$.complete();
        },
      });

    return response$;
  }

  search(query: string, options = { request: {} }) {
    this.init();

    if (this.localSearch) {
      const response$ = new AsyncSubject();
      this.all()
        .pipe(first())
        .subscribe({
          next: (items: any[]) => {
            if (query && query.trim()) {
              const searcher = new FuzzySearch(items, this.localSearch, {
                sort: true,
              });
              items = searcher.search(query);
            } else {
              const sortAttribute = this.localSearch[0];
              items = items.sort((a, b) =>
                ('' + a[sortAttribute]).localeCompare(b[sortAttribute]),
              );
            }

            response$.next(items);
            response$.complete();
          },
          error: (error: any) => {
            response$.error(error);
          },
          complete: () => {
            response$.complete();
          },
        });

      return response$;
    } else {
      return this.remoteSearch(query, options);
    }
  }

  create(obj: any, options = { request: {} }) {
    this.init();

    const url = `${this.endpoint}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const request$ = this.http
      .post(
        url,
        this.transport(obj),
        Object.assign(httpOptions, options.request),
      )
      .pipe(first());
    const response$ = new AsyncSubject();

    obj.id = uuid();
    this.syncItems$.next({
      action: 'create',
      resource: this.identifier,
      data: obj,
      optimistic: true,
    });

    request$.subscribe(
      (resp: any) => {
        // delete optimistic obj
        this.syncItems$.next({
          action: 'delete',
          resource: this.identifier,
          data: obj,
        });

        this.syncItems$.next({
          action: 'create',
          resource: this.identifier,
          cycle: resp.cycle,
          data: resp.data,
        });

        this.processItem(resp.data).subscribe({
          next: (item) => {
            response$.next(item);
            response$.complete();
          },
        });
      },
      (resp) => {
        console.warn(resp);
        this.syncItems$.next({
          action: 'delete',
          resource: this.identifier,
          data: obj,
          optimistic: true,
        });

        this.errorHandler.handle(resp);
        response$.error(resp.error);
        response$.complete();
      },
    );

    return response$;
  }

  update(id: number, obj: any, options = { request: {} }) {
    this.init();

    const url = `${this.endpoint}/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const request$ = this.http
      .put(
        url,
        this.transport(obj),
        Object.assign(httpOptions, options.request),
      )
      .pipe(first());
    const response$ = new AsyncSubject();

    let prevItem;
    this.frames.forEach((f) => {
      const framePrevItems = f.getCurrentResult();
      if (Array.isArray(framePrevItems) && !prevItem) {
        prevItem = framePrevItems.find((i) => i.id === id);
      }
    });

    this.syncItems$.next({
      action: 'update',
      resource: this.identifier,
      data: obj,
      optimistic: true,
    });

    request$.subscribe(
      (resp: any) => {
        this.syncItems$.next({
          action: 'update',
          resource: this.identifier,
          data: resp.data,
          cycle: resp.cycle,
        });

        this.processItem(resp.data).subscribe({
          next: (item) => {
            response$.next(item);
            response$.complete();
          },
        });
      },
      (resp) => {
        console.warn(resp);

        this.syncItems$.next({
          action: 'update',
          resource: this.identifier,
          data: prevItem,
          optimistic: true,
        });

        this.errorHandler.handle(resp);
        response$.error(resp.error);
        response$.complete();
      },
    );

    return response$;
  }

  action(action: string, id: number, obj: any, options = { request: {} }) {
    this.init();

    const url = id
      ? `${this.endpoint}/${id}/${action}`
      : `${this.endpoint}/${action}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const request$ = this.http
      .put(
        url,
        this.transport(obj),
        Object.assign(httpOptions, options.request),
      )
      .pipe(first());
    const response$ = new AsyncSubject();

    request$.subscribe(
      (resp: any) => {
        const actionType = resp.data.id !== id ? 'create' : 'update';
        this.syncItems$.next({
          action: actionType,
          resource: this.identifier,
          data: resp.data,
          cycle: resp.cycle,
        });

        this.processItem(resp.data).subscribe({
          next: (item) => {
            response$.next(item);
            response$.complete();
          },
        });
      },
      (resp) => {
        console.warn(resp);

        this.errorHandler.handle(resp);
        response$.error(resp.error);
        response$.complete();
      },
    );

    return response$;
  }

  validate(
    obj: any,
    options = { request: {} },
  ): AsyncSubject<{
    valid: boolean;
    fatal: boolean;
    errors: any[];
    warnings: any[];
  }> {
    if (this.remoteValidation) {
      const url = `${this.endpoint}/validate`;
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      };

      const request$ = this.http
        .post(
          url,
          this.transport(obj),
          Object.assign(httpOptions, options.request),
        )
        .pipe(first());
      const response$: AsyncSubject<{
        valid: boolean;
        fatal: boolean;
        errors: any[];
        warnings: any[];
      }> = new AsyncSubject();

      request$.subscribe(
        (resp: any) => {
          response$.next({
            valid: true,
            fatal: false,
            errors: [],
            warnings: [],
          });
          response$.complete();
        },
        (resp) => {
          resp = resp.error;
          const errors = [].concat(...Object.values(resp.errors || {}));
          const warnings = [].concat(...Object.values(resp.warnings || {}));

          response$.next({
            valid: false,
            fatal: errors.length !== 0,
            errors: errors,
            warnings: warnings,
          });
          response$.complete();
        },
      );

      return response$;
    } else {
      const response$: AsyncSubject<{
        valid: boolean;
        fatal: boolean;
        errors: any[];
        warnings: any[];
      }> = new AsyncSubject();

      response$.next({ valid: true, fatal: false, errors: [], warnings: [] });
      response$.complete();

      return response$;
    }
  }

  deletable(
    id: number,
    obj: any,
    options = { request: {} },
  ): AsyncSubject<DeletableResponse> {
    if (this.remoteDeleteValidation) {
      const url = `${this.endpoint}/deletable/${id}`;
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      };

      const request$ = this.http
        .get(url, Object.assign(httpOptions, options.request))
        .pipe(first());
      const response$: AsyncSubject<DeletableResponse> = new AsyncSubject();

      request$.subscribe(
        (resp: any) => {
          const warnings = [].concat(...Object.values(resp.warnings || {}));

          response$.next({
            accepted: true,
            messages: Object.values(resp.warnings || {}),
            warnings: warnings,
            errors: [],
            item: obj,
          });
          response$.complete();
        },
        (resp) => {
          const errors = [].concat(...Object.values(resp.error?.errors || {}));
          const warnings = [].concat(
            ...Object.values(resp.error?.warnings || {}),
          );

          response$.next({
            accepted: false,
            messages: resp.error?.errors || [],
            warnings: warnings,
            errors: errors,
            item: obj,
          });
          response$.complete();
        },
      );

      return response$;
    } else {
      const response$: AsyncSubject<DeletableResponse> = new AsyncSubject();

      response$.next({
        accepted: true,
        item: obj,
        messages: [],
        errors: [],
        warnings: [],
      });
      response$.complete();

      return response$;
    }
  }

  delete(id: number, obj: any, options = { request: {} }) {
    this.init();

    const url = `${this.endpoint}/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const request$ = this.http
      .delete(url, Object.assign(httpOptions, options.request))
      .pipe(first());
    const response$ = new AsyncSubject();

    this.syncItems$.next({
      action: 'delete',
      resource: this.identifier,
      data: obj,
      optimistic: true,
    });

    request$.subscribe(
      (resp: any) => {
        this.syncItems$.next({
          action: 'delete',
          resource: this.identifier,
          data: resp.data,
          cycle: resp.cycle,
        });

        response$.next(resp);
        response$.complete();
      },
      (resp) => {
        console.warn(resp);
        this.syncItems$.next({
          action: 'update',
          resource: this.identifier,
          data: obj,
          optimistic: true,
        });

        this.errorHandler.handle(resp);
        response$.error(resp.error);
        response$.complete();
      },
    );

    return response$;
  }

  remoteValidations(
    item: any,
    stack: any[],
    action: ActionType,
  ): Observable<Validation[]>[] {
    let remoteAction;
    if (action === ActionType.delete) {
      remoteAction = this.deletable(item.id, item);
    } else if (action === ActionType.create || action === ActionType.update) {
      remoteAction = this.validate(item);
    } else {
      remoteAction = of({ warnings: [], errors: [] });
    }

    return [
      remoteAction.pipe(
        map((resp: any) => {
          const warnings = resp.warnings.map((warning) => {
            return Object.assign(new Validation(), {
              id: uuid(),
              message: warning,
              type: ValidationErrorLevel.warning,
            });
          });

          const errors = resp.errors.map((warning) => {
            return Object.assign(new Validation(), {
              id: uuid(),
              message: warning,
              type: ValidationErrorLevel.critical,
            });
          });

          return [...warnings, ...errors];
        }),
      ),
    ];
  }

  private processItems(unprocessedEntries: any[]) {
    return combineLatest(of(unprocessedEntries), ...this.decorators).pipe(
      map(([items, ...decorators]) => {
        return items.map((item) =>
          this.decorate(Object.assign({}, item), decorators),
        );
      }),
      map((items) => {
        return items.map((item) => this.transform(item));
      }),
      map((items) => {
        return [].concat(...items);
      }),
      first(),
    );
  }

  private processItem(unprocessedEntry) {
    return combineLatest(of(unprocessedEntry), ...this.decorators).pipe(
      map(([item, ...decorators]) => {
        return this.decorate(Object.assign({}, item), decorators);
      }),
      map((item) => {
        return this.transform(item);
      }),
      map((items) => {
        if (items.length === 1) {
          return items[0];
        } else {
          return items;
        }
      }),
      first(),
    );
  }

  refreshCollection() {
    this.init();

    this.operations$.next([]);
    this.localSettingsService.set(this.identifier + '.operation', []);
    this.frames.forEach((f) => f.refreshCollection());
  }

  transport(obj) {
    // if (this.type) {
    //   obj = Object.assign(new this.type(), obj);
    //   obj.client_id = this.client_id;

    //   obj = serialize(obj);
    // } else {

    //it is required to remove the properties without a value from the store,
    //as the backend sometimes is adding default values for missing properties
    if (obj.store) {
      obj.store = removeNullProperties(obj.store);
    }
    obj = Object.assign({}, obj, {
      id: Number.isInteger(obj.id) ? obj.id : undefined,
      transformed: false,
      decorated: false,
    });
    obj.client_id = this.client_id;

    obj = this.simple_transport(obj);
    // }

    return obj;
  }

  // compares two ranges
  forRange(begin?, end?) {
    if (!begin || !end) {
      return this.all();
    }
    const filterFunc = (x) => overlaps(x.marker, begin, end);
    const filterLookup = `range:${begin}|${end}`;

    return this.filter(filterFunc, filterLookup);
  }

  // only compares overlap between item.marker[0] and the range
  forDate(date) {
    const begin = new Date(date.getTime());
    begin.setHours(0, 0, 0, 0);
    const end = new Date(date.getTime());
    end.setHours(23, 59, 59, 0);

    const filterFunc = (x) =>
      overlaps(x.marker[0], dateToTzUnix(begin), dateToTzUnix(end));
    const filterLookup = `range:${begin}|${end}`;

    return this.filter(filterFunc, filterLookup);
  }

  transform(obj) {
    if (this.type) {
      obj = deserialize(JSON.stringify(obj), this.type);
      obj.identifier = this.identifier;
    } else {
      obj = this.simple_transform(obj);
    }

    return [obj];
  }

  decorate(obj, decorators = []) {
    return obj;
  }

  setRanges(x: number, y: number) {
    x = x - window.config.company.dispo_preload_days * 24 * 60 * 60;
    y = y + window.config.company.dispo_preload_days * 24 * 60 * 60;

    let framesChanged = false;
    const startFrame = Math.floor(x / this.step);
    const endFrame = Math.floor(y / this.step);

    const frames = [...Array(endFrame + 1 - startFrame)].map(
      (_, i) => i + startFrame,
    );
    const newFrames = frames.map((i) => {
      let frame = this.frames.find((f) => f.x === i * this.step);

      if (!frame) {
        frame = new Frame(
          i * this.step,
          (i + 1) * this.step,
          this.http,
          this.store,
          this.localSettingsService,
          this,
        );
        framesChanged = true;
        this.frames.push(frame);
      }

      return frame;
    });

    if (!this.standby) {
      this.frames.forEach((frame, index) => {
        if (newFrames.indexOf(frame) === -1) {
          framesChanged = true;
          frame.disable();
          this.frames.splice(index, 1);
        }
      });
    }

    const newX = frames[0] * this.step;
    const newY = frames[frames.length - 1] * this.step;

    if (this.x !== newX || this.y !== newY) {
      framesChanged = true;
    }

    this.x = newX;
    this.y = newY;

    if (framesChanged) {
      this.frames$.next(newFrames);
    }
  }

  sync(obj: any) {
    this.syncItems$.next({
      action: 'update',
      resource: this.identifier,
      data: obj,
    });
  }

  protected addOperation(op) {
    op = parseInt(op, 10);
    if (op) {
      const ops = this.operations$.value.concat([op]);
      this.operations$.next(ops);
    }
  }

  protected handleSync(sync) {
    let prevItems = [];
    if (this.operations$.value.indexOf(sync.cycle) !== -1) {
      return;
    }

    // TODO: test implications
    // removed as internal syncs come with
    // no cycle, so locks ui
    // prevents however double updates in UI
    // if (!sync.optimistic) {
    this.addOperation(sync.cycle);
    this.syncSignal$.next(false);
    // }

    this.frames.forEach((f) => {
      const framePrevItems = f.handleSync(sync);
      if (Array.isArray(framePrevItems)) {
        prevItems = prevItems.concat(framePrevItems.filter((i) => i));
      } else if (framePrevItems) {
        prevItems.push(framePrevItems);
      }
    });
    this.handleFilterSync(sync);

    return prevItems;
  }

  protected handleFilterSync(sync) {
    const data = Array.isArray(sync.data) ? sync.data : [sync.data];
    const ids = data.map((i) => i.id);

    this.syncSignal$
      .pipe(
        filter((s) => s),
        first(),
      )
      .subscribe({
        next: (_) => {
          this.result$.pipe(first()).subscribe((res) => {
            Object.values(this.filters).map((f: any) => {
              if (
                // filter does not apply
                data.filter(f.filter).length > 0 ||
                // special case -> filtered attribute changed
                f.value.filter((i) => ids.includes(i.id)).length > 0
              ) {
                const newFilterValue = res.filter(f.filter);
                f.value = newFilterValue;
                f.subject.next(newFilterValue);
              }
            });
          });
        },
      });
  }

  protected simple_transform(obj) {
    Object.keys(obj).forEach((key) => {
      const dates = [
        'starts_at',
        'ends_at',
        'intermission_starts_at',
        'intermission_ends_at',
      ];

      if (dates.indexOf(key) !== -1 && obj[key]) {
        obj[key] = parseDate(obj[key]);
      }
    });

    obj.identifier = this.identifier;

    return obj;
  }

  protected simple_transport(obj) {
    const resp = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] instanceof Date) {
        const date = obj[key];
        resp[key] = format(date, DateUnicodeFormat.apiDateTime);
      } else {
        resp[key] = obj[key];
      }
    });

    return JSON.stringify(resp);
  }
}

class Frame {
  private refresh;
  private init = false;
  private lastRefreshed = 0;
  private result: any[] = [];
  private subs = [];

  private loadSignal$: ReplaySubject<boolean> = new ReplaySubject(1);
  private syncSignal$: ReplaySubject<boolean> = new ReplaySubject(1);
  private cache$: ReplaySubject<any[]> = new ReplaySubject(1);

  protected all$: ReplaySubject<any[]> = new ReplaySubject(1);

  protected allResult$: Observable<any[]> = this.all$.pipe(
    // retrieve decorator collections
    flatMap((items) => {
      return combineLatest(of(items), ...this.parent.decorators);
    }),
    // decorate items
    map(([items, ...decorators]) => {
      return items.map((item) => {
        if (item.decorated) {
          return item;
        } else {
          return this.parent.decorate({ ...item, decorated: true }, decorators);
        }
      });
    }),
    // transform items
    map((items) => {
      const result = [];

      items.forEach((item) => {
        if (item.transformed) {
          result.push(item);
        } else {
          result.push(...this.parent.transform({ ...item, transformed: true }));
        }
      });

      return result;
    }),
    // trigger load and sync signal;
    map((items) => {
      setTimeout(() => {
        this.loadSignal$.next(true);
        this.syncSignal$.next(true);
      }, 10);

      this.result = items;

      return items;
    }),
    // cache items
    map((items: any[]): any[] => {
      this.cache$.next(items);
      return items;
    }),
    shareReplay(1),
  );

  constructor(
    public x: any,
    public y: any,
    protected http: HttpClient,
    protected store: StoreService,
    protected localSettingsService: LocalSettingsService,
    protected parent: FrameCollection,
  ) {
    this.loadSignal$.next(false);
    this.syncSignal$.next(false);
    this.refreshCollection = this.refreshCollection.bind(this);

    // init refresh
    if (parent.refresh) {
      this.refresh = setInterval(
        this.refreshCollection,
        parent.refresh * 1000 * (Math.random() / 3 + 1),
      );
    }

    // init cache
    if (parent.cache) {
      this.subs.push(
        this.cache$.pipe(debounceTime(3000)).subscribe((items) => {
          if (
            this.parent.cache &&
            this.localSettingsService.get('isCacheEnabled', false).getValue()
          ) {
            const timeout = this.lastRefreshed + this.parent.cache * 1000;
            this.store.setItem(this.cacheKey(), items, timeout);
          }
        }),
      );
    }
  }

  disable() {
    this.subs.forEach((sub) => sub.unsubscribe());

    if (this.refresh) {
      clearInterval(this.refresh);
    }

    if (
      this.parent.cache &&
      this.localSettingsService.get('isCacheEnabled', false).getValue()
    ) {
      this.store.setItem(this.cacheKey(), [], 0);
    }
  }

  loaded() {
    return this.loadSignal$.pipe(distinctUntilChanged());
  }

  synced() {
    return this.syncSignal$.pipe(distinctUntilChanged());
  }

  all(): Observable<any> {
    if (!this.init) {
      this.init = true;

      if (
        !this.localSettingsService.get('isCacheEnabled', false).getValue() ||
        !this.parent.cache
      ) {
        this.refreshCollection();
      } else {
        const cacheKey = this.cacheKey();

        this.store
          .getItem(cacheKey)
          .pipe(
            first(),
            map((cache: any) => {
              if (!cache) {
                debug(this.parent.endpoint + ' frame - no cache found');
                return null;
              }

              const validUntil = cache.timeout - new Date().getTime();

              if (validUntil < 0) {
                debug(
                  this.parent.endpoint + ' frame invalid - refreshing soon',
                );
                return null;
              }

              return [cache.data, cache.timeout, validUntil];
            }),
            catchError((error: unknown) => {
              this.refreshCollection();

              if (error instanceof Error) {
                debug(`${this.parent.endpoint} ${error.message}`);
              }

              return of(null);
            }),
          )
          .subscribe((result) => {
            if (result) {
              const [data, timeout, validUntil] = result;
              this.lastRefreshed = timeout - this.parent.cache * 1000;
              this.result = data;

              debug(
                this.parent.endpoint + ' set valid for ' + validUntil / 1000,
              );

              this.all$.next(this.result);
            } else {
              // Handle the case when cache is not available or invalid
              // You can choose to refresh the collection or take any other appropriate action
              this.refreshCollection();
            }
          });
      }
    }

    return this.allResult$;
  }

  getCurrentResult() {
    return this.result;
  }

  refreshCollection() {
    const query = Object.assign(
      {
        begin: this.x,
        end: this.y,
      },
      this.parent.query || {},
    );
    const options = { params: new HttpParams({ fromObject: <any>query }) };

    debug(this.parent.identifier + ' refreshing');
    this.loadSignal$.next(false);
    this.syncSignal$.next(false);
    this.http
      .get(this.parent.endpoint, options)
      .pipe(first())
      .subscribe((res: any) => {
        this.lastRefreshed = new Date().getTime();
        this.result = res.data;
        this.all$.next(this.result);
      });
  }

  handleSync(sync) {
    this.syncSignal$.next(false);

    if (this.parent.removeDataOnSync) {
      const prevResultLength = this.result.length;
      if (sync.data instanceof Array) {
        sync.data.forEach((data) => {
          this.result = this.result.filter((item) => item.id !== data.id);
        });
      } else {
        this.result = this.result.filter((item) => item.id !== sync.data.id);
      }

      if (prevResultLength !== this.result.length) {
        this.all$.next(this.result);
      }
    }

    if (sync.data instanceof Array) {
      const prevItems = sync.data.map((data) => {
        const singleDataSync = Object.assign({}, sync);
        singleDataSync.data = data;
        return this.handleSync(singleDataSync);
      });

      return prevItems;
    }

    if (sync.data.visibility && sync.data.visibility === 0) {
      sync.action = 'delete';
    }

    return this.insertSyncData(sync);
  }

  private insertSyncData(sync) {
    let prevItem;

    sync.data.decorated = false;
    sync.data.transformed = false;

    if (sync.action === 'delete') {
      const index = this.result.findIndex((i: any) => i.id === sync.data.id);

      if (index !== -1) {
        prevItem = this.result.splice(index, 1);
        this.all$.next(this.result);
      } else {
        this.syncSignal$.next(true);
      }
    }

    if (sync.action === 'update') {
      const index = this.result.findIndex((i: any) => i.id === sync.data.id);

      if (overlaps(sync.data.marker, this.x, this.y)) {
        if (index !== -1) {
          prevItem = this.result[index];
          this.result[index] = sync.data;
        } else {
          this.result.push(sync.data);
        }

        this.all$.next(this.result);
      } else {
        if (index !== -1) {
          prevItem = this.result.splice(index, 1);
          this.all$.next(this.result);
        } else {
          this.syncSignal$.next(true);
        }
      }
    }

    if (sync.action === 'create') {
      if (overlaps(sync.data.marker, this.x, this.y)) {
        const alreadyPresent = this.result.find(
          (i: any) => i.id === sync.data.id,
        );
        if (alreadyPresent) {
          return;
        }

        // const index = this.result.findIndex( (i: any) => i._id === sync.data._id);

        // if (index === -1 || !sync.data._id) {
        this.result.push(sync.data);
        // } else {
        //   this.result[index] = sync.data;
        // }

        this.all$.next(this.result);
      } else {
        this.syncSignal$.next(true);
      }
    }

    return prevItem;
  }

  private cacheKey() {
    return (
      this.parent.endpoint +
      '|' +
      JSON.stringify(this.parent.query) +
      '|' +
      this.x +
      '|' +
      this.y
    );
  }
}
