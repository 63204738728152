import { ISchema } from 'ngx-schema-form';
import * as _ from 'lodash-es';

import {
  FormDataSchema,
  FormDataSchemaCondition,
  FormDataSchemaProperty,
  FormDataSchemaSection,
} from './form-data';

export class JsonSchema {
  public type!: string;
  public order!: string[];
  public properties!: Record<string, ISchema>;
  public title?: string;
  public description?: string;
  public widget?: string;

  constructor(init: Partial<JsonSchema>) {
    Object.assign(this, init);
  }

  static fromFormData(value: FormDataSchema): JsonSchema {
    if (!value) {
      throw new Error('There is no schema provided');
    }

    const { schema, title } = value;

    // Convert the properties
    const properties: Record<string, ISchema> = _.mapValues(
      schema.properties,
      (property: FormDataSchemaProperty) => {
        let widget: string;

        if (Array.isArray(property.enum)) {
          widget = 'select';
        } else {
          switch (property.type) {
            case 'string':
              widget = property.format ?? property.type;
              break;
            case 'number':
              widget = property.format === 'currency' ? 'currency' : 'number';
              break;
            case 'boolean':
              widget = 'checkbox';
              break;
            default:
              widget = property.type;
          }
        }

        return <ISchema>{ ...property, widget, readOnly: property.readonly };
      },
    );

    const processItems = (
      items: (string | FormDataSchemaSection | FormDataSchemaCondition)[],
      parentCondition?: string,
    ): string[] => {
      return items.flatMap((item) => {
        if (typeof item === 'string') {
          return [item];
        } else if ('items' in item) {
          const htmlClass = item.htmlClass?.replace(
            /col-(?:sm|md|lg|xl)-(\d+)/,
            'col-$1',
          );
          if (htmlClass) {
            item.items.forEach((subItem) => {
              if (typeof subItem === 'string') {
                properties[subItem].htmlClass = htmlClass;
              }
            });
          }
          return processItems(item.items, item.condition);
        } else if ('key' in item) {
          const condition = item.condition ?? parentCondition;
          if (condition && properties[item.key]) {
            const parsedCondition = this.parseCondition(condition, item.key);
            if (parsedCondition) {
              properties[item.key].visibleIf = parsedCondition;
            }
          }

          return [item.key];
        }
        return [];
      });
    };

    const order: string[] = [
      ...new Set([...processItems(value.form), ...Object.keys(properties)]),
    ];

    return new JsonSchema({
      type: schema.type,
      order,
      properties,
      title,
    });
  }

  static parseCondition(condition: string, key: string) {
    if (condition === 'true') {
      return null;
    } else if (condition === 'false') {
      return {};
    } else {
      let match = condition.match(/^model\.(\w+)==(true|false)$/);
      if (match) {
        return { [match[1]]: match[2] === 'true' };
      }
      match = condition.match(/^!model\.(\w+)$/);
      if (match) {
        return { [match[1]]: false };
      }
    }
    return null;
  }

  static fromArray(schemas: JsonSchema[]): JsonSchema {
    return _.mergeWith({}, ...schemas, (destination, source, key) => {
      if (
        key === 'order' &&
        Array.isArray(destination) &&
        Array.isArray(source)
      ) {
        return destination.concat(source);
      }
    });
  }

  asReadOnly() {
    return {
      ...this,
      properties: Object.fromEntries(
        Object.entries(this.properties).map(([key, value]) => {
          return [key, { ...value, readOnly: true }];
        }),
      ),
    };
  }
}
