export function isUrl(value: string): boolean {
  const REGEXP = new RegExp(
    /^[\w-]+$|(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]{2,})+\.?(:\d+)?(\/\S*)?)/gi,
  );
  return REGEXP.test(value);
}

export function toTitleCase(value: string) {
  return value.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}

export function toCamelCase(value: string): string {
  return value
    .split(/[^A-Za-z0-9]/)
    .map((splitValue) => splitValue.toLowerCase())
    .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1));
}

export function removeExtension(value: string, extension: string) {
  const valueWithoutEndingDots = value.replace(/\.*$/, '');
  const splitValue = valueWithoutEndingDots.split(/\.(?=[^.]*$)/);
  return splitValue[1] === extension ? splitValue[0] : valueWithoutEndingDots;
}

export function truncate(value: string, maxLength = 100): string {
  return value.length > maxLength
    ? value
        .replace(new RegExp(`(.{${maxLength - 1}})..+`), '$1')
        .trim()
        .concat('…')
    : value;
}

export function isEmail(value: string, { headerType = false } = {}) {
  const simpleEmailRegex =
    "([\\w.!#$%&'*+-/=?^_`{|}~]+@[\\w-]+(?:\\.[\\w-]+)+)";

  let emailChecker = `^${simpleEmailRegex}$`;

  if (headerType) {
    emailChecker += `|(^\\s*([\\p{L}\\d\\s]+?)\\s*<${simpleEmailRegex}>\\s*)$`;
  }

  return new RegExp(emailChecker, 'u').test(value.trim());
}

/**
 * Returns the value of the object's property that matches the selector
 *
 * @param element the object to be reduced
 * @param selector The object path, separated by dots
 */
export function deepGetValue<T>(element: T, selector: string): unknown {
  return selector
    .split('.')
    .reduce((o, i) => (o as Record<string, unknown>)[i], element as unknown);
}

/**
 * Check if a String has any of the passed search strings
 *
 * @return true if at least one of the search strings is includes in the value
 */
export function stringIncludesAny(
  value: string,
  searchStrings: string[],
  ignoreCase = false,
): boolean {
  if (ignoreCase) {
    value = value?.toLowerCase() ?? '';
    searchStrings.map((searchStrings) => searchStrings?.toLowerCase() ?? '');
  }

  return !!searchStrings.find((searchString) => value?.includes(searchString));
}
