import { Pipe, PipeTransform } from '@angular/core';

import { TcFeedbackRequestType } from './feedback-request-type';
import { feedbackRequestsOfType } from './feedback-requests-helpers';
import { TcFeedbackRequest } from './feedback-request.model';

@Pipe({
  name: 'feedbackRequestsOfType',
})
export class TcFeedbackRequestsOfTypePipe implements PipeTransform {
  transform(
    feedbackRequests: TcFeedbackRequest[],
    types?: TcFeedbackRequestType | TcFeedbackRequestType[],
  ): TcFeedbackRequest[] {
    if (!Array.isArray(feedbackRequests)) {
      return feedbackRequests;
    }

    return feedbackRequestsOfType(feedbackRequests, types);
  }
}
