import { Directive, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TcIntervalWithBreak } from '@timecount/core';
import {
  isDateWithinInterval,
  isValidDate,
  isValidInterval,
} from '@timecount/utils';

import { TcFormGroupIntervalService } from '../../form-groups';
import { asyncStatusOfControl } from '../../shared';
import { TcFormValidationService } from '../../form-validation.service';

import { TcFieldsetIntervalWithBreakComponentConfig } from './interval-with-break.config';

@Directive()
export abstract class TcIntervalWithBreakBaseDirective
  implements ControlValueAccessor, AsyncValidator, OnInit
{
  @Input() tcConfig: TcFieldsetIntervalWithBreakComponentConfig;
  @Input() tcReferenceDate: Date;
  @Input() maxTotalDuration: Duration;

  innerForm: UntypedFormGroup;

  duration = 0;

  private destroyed$ = new Subject<void>();

  private _onChange: (_: unknown) => void;
  private _onTouched: () => void;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected formGroupIntervalService: TcFormGroupIntervalService,
    protected formValidationService: TcFormValidationService,
  ) {}

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this._setForm();
  }

  // ---------------------------------------------------------------------------
  // ControlValueAccessor Implementation
  // ---------------------------------------------------------------------------

  writeValue(value: TcIntervalWithBreak): void {
    if (this.innerForm instanceof UntypedFormGroup) {
      this.innerForm.reset(value ?? undefined);
    } else {
      this._setForm(value);
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    if (this.innerForm instanceof UntypedFormGroup) {
      this._setValueChangeHandler(fn);
    } else {
      this._onChange = fn;
    }
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.innerForm.disable() : this.innerForm.enable();
  }

  // ---------------------------------------------------------------------------
  // Validator Implementation
  // ---------------------------------------------------------------------------

  validate(): Promise<ValidationErrors | null> {
    return asyncStatusOfControl(this.innerForm, { needsTimeOut: true });
  }

  getDuration(): number {
    return TcIntervalWithBreak.getDuration(this.innerForm.value);
  }

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  private _setForm(initialValue?: TcIntervalWithBreak): void {
    const { mainInterval, breakInterval } = initialValue ?? {};

    this.innerForm = this.formBuilder.group({
      mainInterval: [mainInterval ?? { start: null, end: null }],
      breakInterval: [breakInterval ?? { start: null, end: null }],
    });

    this.innerForm
      .get('breakInterval')
      .setValidators([this._breakWithinMainValidator]);
    this.innerForm.get('breakInterval').updateValueAndValidity();

    if (this._onChange) {
      this._setValueChangeHandler(this._onChange);
    }
  }

  private _breakWithinMainValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    const { start, end } = control.value ?? {};
    const mainInterval = control.parent.get('mainInterval')?.value ?? {};

    if (
      isValidInterval(mainInterval) &&
      ((isValidDate(start) && !isDateWithinInterval(start, mainInterval)) ||
        (isValidDate(end) && !isDateWithinInterval(end, mainInterval)))
    ) {
      return { [`invalid_intermission_range`]: true };
    }

    return null;
  }

  private _setValueChangeHandler(fn: (_: unknown) => void): void {
    this.innerForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        fn(value);
      });
  }
}
