<div class="tc-modal-body" #modalBody>
  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="!single"
  >
    <nz-tab
      nzTitle="{{ 'dispo/plan.tab_type.general' | translate }}"
      *tcHubHide="'views.dispo.plan.general'"
    >
      <tc-hub-dp-general
        class="modal-container"
        [plan]="plan"
        (signal)="onSignal($event)"
      ></tc-hub-dp-general>
    </nz-tab>
  </nz-tabset>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'move'">
    <nz-tab
      nzTitle="{{ 'dispo/plan.tab_type.move' | translate }}"
      *tcHubHide="'views.dispo.plan.move'"
    >
      <ng-template nz-tab>
        <tc-hub-dp-move
          class="modal-container"
          [plan]="plan"
          (signal)="onSignal($event)"
        ></tc-hub-dp-move>
      </ng-template>
    </nz-tab>
  </nz-tabset>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'create'">
    <nz-tab
      nzTitle="{{ 'dispo/plan.tab_type.create' | translate }}"
      *tcHubHide="'views.dispo.plan.create'"
    >
      <tc-hub-dp-create
        class="modal-container"
        [plan]="plan"
        (signal)="onSignal($event)"
      ></tc-hub-dp-create>
    </nz-tab>
  </nz-tabset>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'delete'">
    <nz-tab
      nzTitle="{{ 'dispo/plan.tab_type.delete' | translate }}"
      *tcHubHide="'views.dispo.plan.delete'"
    >
      <tc-hub-dp-delete
        class="modal-container"
        [plan]="plan"
        (signal)="onSignal($event)"
      ></tc-hub-dp-delete>
    </nz-tab>
  </nz-tabset>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
