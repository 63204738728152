import { TcFormPickerType } from '../../shared/form-pickers/form-picker.model';

/**
 * All supported field types that don't have pickers
 */
export const inputType = ['text', 'email', 'number', 'password'] as const;
export type TcInputType = (typeof inputType)[number];

/**
 * All supported field types
 */
export type TcInputFieldWebType = 'integer' | 'percent' | 'currency';
export type TcInputFieldType =
  | TcInputType
  | TcFormPickerType
  | TcInputFieldWebType;
