<tc-form-wrapper
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  [shouldShowPanel]="false"
  (primaryButtonClick)="save()"
>
  <form [formGroup]="formGroup">
    <fieldset
      [tcPanel]
      [tcTitle]="'aggregation.headers.layout' | translate"
      tcContentClass="tc-grid tc-grid-1-col"
    >
      <tc-hub-select
        label="{{ 'aggregation.attrs.group_by' | translate }}"
        [formGroup]="formGroup"
        bindLabel="title"
        bindValue="id"
        controlName="group_by"
        [items]="groupingOptions"
        multiple="true"
      >
      </tc-hub-select>

      <tc-input-text-web
        label="{{ 'aggregation.attrs.subject' | translate }}"
        formControlName="subject"
      ></tc-input-text-web>

      <tc-input-text-web
        label="{{ 'aggregation.attrs.description' | translate }}"
        formControlName="description"
      ></tc-input-text-web>

      <label class="control-label">
        {{ 'aggregation.attrs.comments' | translate }}
      </label>
      <div class="textarea-group">
        <div class="textarea-controls">
          <tc-hub-placeholder
            [formGroup]="formGroup"
            target="comments"
            type="condition.placeholders"
          ></tc-hub-placeholder>
        </div>
        <div class="textarea-form">
          <textarea
            class="form-control"
            rows="2"
            formControlName="comments"
            name="comments"
            autosize
          ></textarea>
        </div>
      </div>
    </fieldset>

    <fieldset [tcPanel] [tcTitle]="'aggregation.headers.address' | translate">
      <div class="tc-grid tc-grid-2-col">
        <tc-input-text-web
          class="tc-col-12"
          *ngIf="tcInitialValue.customer_id !== tcProject.customer_id"
          label="{{ 'aggregation.attrs.customer_id' | translate }}"
          formControlName="customerName"
        ></tc-input-text-web>

        <tc-input-text-web
          class="tc-col-12"
          label="{{ 'aggregation.attrs.recipient' | translate }}"
          formControlName="recipient"
        ></tc-input-text-web>

        <tc-input-text-web
          label="{{ 'aggregation.attrs.department' | translate }}"
          formControlName="department"
        ></tc-input-text-web>

        <tc-input-text-web
          label="{{ 'aggregation.attrs.address_addon' | translate }}"
          formControlName="address_addon"
        ></tc-input-text-web>

        <tc-input-text-web
          label="{{ 'aggregation.attrs.first_name' | translate }}"
          formControlName="first_name"
        ></tc-input-text-web>

        <tc-input-text-web
          label="{{ 'aggregation.attrs.last_name' | translate }}"
          formControlName="last_name"
        ></tc-input-text-web>

        <tc-input-text-web
          class="tc-col-8"
          label="{{ 'aggregation.attrs.street' | translate }}"
          formControlName="street"
        ></tc-input-text-web>

        <tc-input-text-web
          class="tc-col-4"
          label="{{ 'aggregation.attrs.street_no' | translate }}"
          formControlName="street_no"
        ></tc-input-text-web>

        <tc-input-text-web
          class="tc-col-4"
          label="{{ 'aggregation.attrs.zipcode' | translate }}"
          formControlName="zipcode"
        ></tc-input-text-web>

        <tc-input-text-web
          class="tc-col-4"
          label="{{ 'aggregation.attrs.place' | translate }}"
          formControlName="place"
        ></tc-input-text-web>

        <tc-input-text-web
          class="tc-col-4"
          label="{{ 'aggregation.attrs.country' | translate }}"
          formControlName="country"
        ></tc-input-text-web>
      </div>
    </fieldset>

    <tc-hub-custom-fields
      section="aggregation.{{ tcInitialValue.type.handle }}.layout"
      formControlName="aggregationHandleLayout"
    >
    </tc-hub-custom-fields>

    <tc-hub-custom-fields
      section="aggregation.layout"
      formControlName="aggregationLayout"
    >
    </tc-hub-custom-fields>
  </form>
</tc-form-wrapper>
