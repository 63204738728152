import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DateInfo } from '@timecount/utils';

import { DispoMenuService } from '../menus/menu.service';
import { DispoDateInfoService } from '../dispo-date-info.service';

import { DispoAnnouncementsService } from './dispo-announcements.service';
import { DANPlanComponent } from './plan/plan.component';

@Component({
  selector: 'tc-hub-dispo-announcements',
  templateUrl: './dispo-announcements.component.html',
  styleUrls: ['./dispo-announcements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispoAnnouncementsComponent implements OnInit, OnDestroy {
  @ViewChildren('planComponent') planComponents: QueryList<DANPlanComponent>;

  plans: Observable<any[]>;
  weeks$: Observable<DateInfo[][]>;

  style$ = new Subject();
  resizeObserver: ResizeObserver;

  constructor(
    private service: DispoAnnouncementsService,
    private contextMenuService: DispoMenuService,
    private dateInfoService: DispoDateInfoService,
    private elementRef: ElementRef,
    private zone: NgZone,
  ) {}

  ngOnInit() {
    this.plans = this.service.plans();
    this.weeks$ = this.dateInfoService.weeks();

    this.resizeObserver = new ResizeObserver((entries) => {
      this.zone.run(() => {
        this.style$.next({ 'width.px': entries[0].contentRect.width });
      });
    });
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.elementRef.nativeElement);
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  contextMenuHandler(event) {
    if (event.button === 2) {
      event.preventDefault();
      event.stopPropagation();
    }

    const contexts = event.menu_contexts || [];

    if (!contexts.find((i) => i.item_type === 'DispoPlan')) {
      contexts.push({ item_type: 'DispoPlan', item: [] });
    }

    if (!contexts.find((i) => i.item_type === 'DispoSchedule')) {
      contexts.push({ item_type: 'DispoSchedule', item: [] });
    }

    this.contextMenuService.onViewClick(event, 'dispo/schedules', contexts);
  }

  onSelect(e) {
    this.planComponents.map((s) => s.clearSelection());
  }
}
