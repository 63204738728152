import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';

import {
  ModalConfig,
  ModalRef,
  TcFieldSetIntervalSetService,
} from '@timecount/ui';

import { ValidationService } from '../../../dispo/collections/validation.service';
import { Action } from '../../../core/types/action';
import { EntryType } from '../../../core/types/entry-type';
import { ActionType } from '../../../core/types/action-type';
import { ProjectTimesheetCollection, Timesheet } from '../../../timesheets';
import { TcProjectFormsBaseDirective } from '../../project-forms-base.directive';
import {
  generateFieldsForSupplements,
  generateSupplementsFromForm,
} from '../../../core/supplements';
import {
  Supplement,
  SupplementCollection,
} from '../../../core/collections/supplement';
import { ResourceTemplateCollection } from '../../../core/collections/resource_template';
import { ResourceTemplate } from '../../../shared';

@Component({
  selector: 'tc-hub-project-costs-form',
  templateUrl: './project-costs-form.component.html',
  styleUrls: ['./project-costs-form.component.scss'],
})
export class TcProjectCostsFormComponent
  extends TcProjectFormsBaseDirective<Timesheet>
  implements OnInit, OnDestroy
{
  public resourceTypes = ['Employee', 'Contractor'];
  public payGradeUrl$ = new ReplaySubject(1);

  supplements: Supplement[];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _intervalSetService: TcFieldSetIntervalSetService,
    private _validationService: ValidationService,
    private _timesheetCollection: ProjectTimesheetCollection,
    private _supplementCollection: SupplementCollection,
    private _resourceTemplateCollection: ResourceTemplateCollection,
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super(config, modalRef);
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    super.ngOnInit();

    if (this.tcInitialValue) {
      this.resourceTypes = [this.tcInitialValue.resource_type];
    }

    const supplements$ = this._supplementCollection.all().pipe(
      first(),
      map((supplements) =>
        supplements.filter(
          (s) => s.visibility === 'visible' || s.visibility === 'fields_only',
        ),
      ),
    );

    let resourceTemplate$: Observable<Partial<ResourceTemplate<Timesheet>>> =
      of({});
    if (!this.isModal) {
      resourceTemplate$ = this._resourceTemplateCollection
        .default<Timesheet>('timesheet')
        .pipe(first());
    }

    forkJoin([supplements$, resourceTemplate$]).subscribe(
      ([supplements, resourceTemplate]) => {
        this.supplements = supplements;
        this._buildForm(resourceTemplate?.template);
      },
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // Public Methods
  // ---------------------------------------------------------------------------

  onSave() {
    this._validationService.run(this._getActions());

    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  private _getActions() {
    const {
      resources,
      times,
      timesheetGeneral,
      timesheetExtended,
      ...valueWithoutResources
    } = this.formGroup.value;
    const { id, source_id, source_type } = this.tcInitialValue ?? {};
    let { project_id, resource_type } = this.tcInitialValue ?? {};

    const formattedTimes = this._intervalSetService.formatValueToApi(times);

    return (Array.isArray(resources) ? resources : [resources]).map(
      (resource) => {
        project_id ??= this.tcProjectId;
        resource_type = resource.type ?? resource_type;
        const resource_id = resource.id ?? resource;

        const timesheet: Partial<Timesheet> = {
          ...valueWithoutResources,
          resource_type,
          resource_id,
          project_id,
          source_id,
          source_type,
          supplements: generateSupplementsFromForm(
            this.formGroup.value,
            this.supplements,
          ),
          ...formattedTimes,
          store: _.merge(
            this.tcInitialValue?.store,
            timesheetGeneral,
            timesheetExtended,
          ),
        };

        let type: ActionType;

        if (id) {
          timesheet.id = id;
          type = ActionType.update;
        } else {
          type = ActionType.create;
        }

        return <Action>{
          id: uuid(),
          entry: timesheet,
          entry_type: EntryType.ProjectTimesheet,
          type,
          validations: (item, stack) => {
            return this._timesheetCollection
              .forProject(this.tcProjectId ?? project_id)
              .remoteValidations(item, stack, type);
          },
          errors: [],
        };
      },
    );
  }

  private _buildForm(resourceTemplate: Timesheet) {
    const mergedValues = _.merge({}, resourceTemplate, this.tcInitialValue);

    const {
      resource_id,
      venue_id,
      job_id,
      custom_pay_grade,
      tariff_id,
      pay_grade_id,
      description,
      store,
      state,
      gratis,
    } = mergedValues ?? {};

    const timesValue = this._intervalSetService.parseFromApi(mergedValues);

    this.formGroup = this._formBuilder.group({
      // Simple
      resources: [resource_id, Validators.required],
      state: [state ?? 'confirmed', Validators.required],
      job_id: [job_id, Validators.required],
      venue_id: [venue_id ?? this.tcVenueId],
      times: [timesValue, [Validators.required]],
      // Extended
      gratis: [gratis ?? false],
      custom_pay_grade: [custom_pay_grade ?? false],
      tariff_id: [
        { value: tariff_id, disabled: !custom_pay_grade },
        Validators.required,
      ],
      pay_grade_id: [
        { value: pay_grade_id, disabled: !custom_pay_grade || !tariff_id },
        Validators.required,
      ],
      description: [description],
      timesheetGeneral: [store],
      timesheetExtended: [store],
    });

    const supplementFields = generateFieldsForSupplements(
      mergedValues,
      this.supplements,
    );
    Object.keys(supplementFields).forEach((key) => {
      const controlConfig = supplementFields[key][0];
      this.formGroup.addControl(
        key,
        this._formBuilder.control(
          controlConfig.value,
          ...[controlConfig.validators || []],
        ),
      );
    });

    if (tariff_id) {
      this.payGradeUrl$.next(`/api/tariffs/${tariff_id}/pay_grades`);
    }

    this.formGroup
      .get('custom_pay_grade')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        const tariffControl = this.formGroup.get('tariff_id');
        const payGradeControl = this.formGroup.get('pay_grade_id');
        if (value) {
          tariffControl.enable();
          if (tariffControl.value) {
            payGradeControl.enable();
          }
        } else {
          tariffControl.disable();
          payGradeControl.disable();
        }
      });

    this.formGroup
      .get('tariff_id')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((tariffId) => {
        const payGradeControl = this.formGroup.get('pay_grade_id');
        payGradeControl.reset();
        if (tariffId) {
          payGradeControl.enable();
          this.payGradeUrl$.next(`/api/tariffs/${tariffId}/pay_grades`);
        } else {
          payGradeControl.disable();
        }
      });
  }
}
