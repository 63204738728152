import { Component } from '@angular/core';

import { TcConfigService } from '@timecount/core';

import { PostsService } from './posts/posts.service';

@Component({
  selector: 'tc-hub-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  postsAlerts$ = this.postsService.getAlerts();

  constructor(
    private configService: TcConfigService,
    private postsService: PostsService,
  ) {
    if (window?.config) {
      const { company, server } = window.config;

      this.configService.init({
        company,
        server,
      });
    }
  }
}
