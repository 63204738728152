<div class="modal-body">
  <nz-table
    nzVirtualScroll
    nzSize="small"
    nzVirtualItemSize="37"
    nzVirtualMinBufferPx="200"
    nzVirtualMaxBufferPx="400"
    [nzData]="invitations$ | async"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    [nzScroll]="{ x: '500px', y: '412px' }"
  >
    <tbody>
      <ng-template nz-virtual-scroll let-row let-index="index">
        <tr *ngIf="row.header">
          <td colspan="3">
            <strong>{{ row.title }}</strong> -
            <strong>{{ row.size[0] }}</strong
            ><strong *ngIf="row.size[1]">/{{ row.size[1] }}</strong>
          </td>
        </tr>
        <tr *ngIf="!row.header">
          <td>
            {{ row.resource?.name }}
          </td>
          <td>
            {{ row.read_at | date: 'short' }}
          </td>
          <td>
            <nz-button-group>
              <button
                nz-button
                nzType="secondary"
                nz-tooltip
                nzTooltipTitle="{{
                  'dispo/invitation.actions.delete' | translate
                }}"
                *tcHubHide="'views.dispo.invitation.delete'"
                (click)="delete(row)"
              >
                <i nz-icon nzType="delete"></i>
              </button>

              <ng-container *ngIf="row.state !== 'refused'">
                <button
                  nz-button
                  nzType="secondary"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.actions.refuse' | translate
                  }}"
                  *tcHubHide="'views.dispo.invitation.refuse'"
                  (click)="refuse(row)"
                >
                  <i nz-icon nzType="stop"></i>
                </button>
              </ng-container>

              <ng-container *ngIf="row.state !== 'accepted'">
                <button
                  nz-button
                  nzType="secondary"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.actions.accept' | translate
                  }}"
                  *tcHubHide="'views.dispo.invitation.accept'"
                  (click)="accept(row)"
                >
                  <i nz-icon nzType="check"></i>
                </button>
              </ng-container>

              <ng-container *ngIf="row.state === 'accepted'">
                <button
                  nz-button
                  nzType="secondary"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.actions.cancel' | translate
                  }}"
                  *tcHubHide="'views.dispo.invitation.cancel'"
                  (click)="cancel(row)"
                >
                  <i nz-icon nzType="close-circle"></i>
                </button>
              </ng-container>

              <ng-container *ngIf="!row.assignment">
                <button
                  nz-button
                  nzType="primary"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.actions.assign' | translate
                  }}"
                  *tcHubHide="'views.dispo.invitation.assign'"
                  (click)="assign(row)"
                >
                  <i nz-icon nzType="user-add"></i>
                </button>
              </ng-container>

              <ng-container *ngIf="row.assignment">
                <button
                  nz-button
                  nzType="primary"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.actions.unassign' | translate
                  }}"
                  *tcHubHide="'views.dispo.invitation.unassign'"
                  (click)="unassign(row)"
                >
                  <i nz-icon nzType="user-delete"></i>
                </button>
              </ng-container>
            </nz-button-group>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</div>
<div class="modal-footer"></div>
