import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ZoneGroupCollection } from '../../core/collections/zone_groups.collection';
import { ZoneGroup } from '../../core/types/zone-group.model';

import { FinancesEntriesService } from './finances-entries.service';
import { FinancesEntriesPlanComponent } from './plan/plan.component';

@Component({
  selector: 'tc-hub-finances-entries',
  templateUrl: './finances-entries.component.html',
  styleUrls: ['./finances-entries.component.scss'],
})
export class FinancesEntriesComponent implements OnInit, OnDestroy {
  @ViewChildren('planComponent')
  planComponents: QueryList<FinancesEntriesPlanComponent>;

  plans$: Observable<any[]>;
  items$: Observable<any[]>;
  zoneGroups$: Observable<ZoneGroup[]>;

  root = { checked: false, indeterminate: false };

  itemsChecked;
  itemsIntedeterminate;

  widthConfig = [
    '500px',
    '250px',
    '50px',
    '100px',
    '100px',
    '150px',
    '100px',
    '100px',
    '150px',
    '150px',
    '100px',
    '150px',
    '150px',
    '150px',
  ];

  private destroyed$ = new Subject<void>();

  constructor(
    private service: FinancesEntriesService,
    private zoneGroupCollection: ZoneGroupCollection,
  ) {}

  @HostListener('contextmenu', ['$event'])
  contextMenu(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.service
      .root()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((root) => {
        this.root = root;
      });

    this.items$ = this.service.items();
    this.zoneGroups$ = this.zoneGroupCollection.all();
    this.zoneGroups$.pipe(takeUntil(this.destroyed$)).subscribe((zones) => {
      const widthConfigBefore = ['500px', '250px', '50px', '100px', '100px'];
      const widthConfigAfter = [
        '100px',
        '150px',
        '100px',
        '150px',
        '100px',
        '100px',
        '100px',
        '100px',
        '100px',
      ];
      const dynamicWidths = new Array(zones.length).fill('150px');
      this.widthConfig = [
        ...widthConfigBefore,
        ...dynamicWidths,
        ...widthConfigAfter,
      ];
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackByIdAndType(index, item) {
    return `${item.type}|${item.id}`;
  }

  toggleItem(type, id) {
    this.service.toggleItem(type, id);
  }

  selectItem(type, id, state, event = new MouseEvent('click')) {
    this.service.selectItem(type, id, state, event);
  }
}
