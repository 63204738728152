import { Component } from '@angular/core';

import {
  EmployeesGridItemType,
  employeesGridItemTypes,
} from './employees-grid-item.model';
import { EmployeesGridItemTogglerService } from './employees-grid-item-toggler.service';

@Component({
  selector: 'tc-hub-employees-grid-item-toggler',
  templateUrl: './employees-grid-item-toggler.component.html',
  styleUrls: ['./employees-grid-item-toggler.component.scss'],
})
export class EmployeesGridItemTogglerComponent {
  itemTypes = employeesGridItemTypes;

  constructor(private togglerService: EmployeesGridItemTogglerService) {}

  public toggle(item: EmployeesGridItemType) {
    this.togglerService.toggle(item);
  }
}
