<form class="modal-container" [formGroup]="formGroup">
  <div class="modal-body">
    <div class="container">
      <tc-input-text-web
        class="form-item half-width"
        label="{{ 'aggregation_entry/work.batch.attrs.title' | translate }}"
        formControlName="title"
      >
      </tc-input-text-web>

      <tc-input-integer-web
        class="form-item half-width"
        label="{{ 'aggregation_entry/work.batch.attrs.size' | translate }}"
        formControlName="size"
      ></tc-input-integer-web>

      <tc-interval-web
        class="form-item"
        formControlName="dateInterval"
        [tcFieldType]="'date'"
        [tcLabels]="{
          start: ('aggregation_entry/work.batch.attrs.range_begin' | translate),
          end: ('aggregation_entry/work.batch.attrs.range_end' | translate)
        }"
        [tcConfig]="{
          fieldType: 'date'
        }"
        [tcAllowZeroDuration]="true"
      ></tc-interval-web>

      <tc-interval-with-break-web
        class="form-item"
        formControlName="times"
        [tcConfig]="{
          relativeDateOptions: true
        }"
      ></tc-interval-with-break-web>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" nz-button nzType="primary" (click)="prev()">
      {{ 'back' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      (click)="save()"
    >
      {{ 'create' | translate }}
    </button>
  </div>
</form>
