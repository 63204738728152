<div class="modal-container">
  <div class="modal-body">
    <nz-alert
      *ngFor="let message of messages"
      nzType="error"
      [nzMessage]="message"
    ></nz-alert>

    <nz-alert
      *ngIf="assignments.length !== 0"
      nzType="warning"
      nzMessage="{{ 'dispo/task.warnings.assignments_present' | translate }}"
    ></nz-alert>
    <nz-alert
      *ngIf="assignments.length === 0"
      nzType="warning"
      nzMessage="{{ 'dispo/task.warnings.delete' | translate }}"
  ></nz-alert>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      (click)="delete()"
    >
      {{ 'delete' | translate }}
    </button>
  </div>
</div>
