import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { TcInputValidators } from '@timecount/ui';

import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';

@Component({
  selector: 'tc-hub-dan-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
})
export class DANGeneralComponent implements OnInit {
  @Input() announcement: Announcement;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  private translations: { [key: string]: string } = {};

  form: UntypedFormGroup;
  taskSlots = [];
  loading = false;

  constructor(
    private announcementCollection: DispoAnnouncementCollection,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.translateService
      .get(['dispo/announcement.attrs.slot_pos'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnInit(): void {
    this.taskSlots = this.announcement.task_slots.map((pos) => {
      return {
        id: pos,
        title: `${this.translations['dispo/announcement.attrs.slot_pos']} ${
          pos + 1
        }`,
      };
    });

    this.form = this.buildForm(this.announcement);
  }

  toggleSize() {
    const auto_size = this.form.controls.auto_size.value;
    if (auto_size) {
      this.form.controls['size'].setValue(0);
      this.form.controls['size'].disable();
    } else {
      this.form.controls['size'].setValue(1);
      this.form.controls['size'].enable();
    }
  }

  save() {
    const formValues = this.form.value;
    const newValues = Object.assign({}, this.announcement, formValues);
    newValues.store = Object.assign(
      {},
      this.announcement.store,
      formValues.store,
    );
    newValues.size = formValues.auto_size ? 0 : newValues.size;

    this.loading = true;
    this.announcementCollection
      .update(this.announcement.id, newValues)
      .subscribe({
        next: (announcement: Announcement) => {
          this.loading = false;
          this.signal.emit({ action: 'close' });
          // this.buildForm(announcement);
          // this.signal.emit({ action: 'reload', items: { announcement: announcement } });
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  private buildForm(announcement) {
    return this.formBuilder.group({
      title: [announcement.title, [Validators.required]],
      plan_id: [
        { value: announcement.plan_id, disabled: true },
        [Validators.required],
      ],
      venue_id: [announcement.venue_id],
      task_slots: [{ value: announcement.task_slots, disabled: true }],
      auto_size: [!announcement.size],
      size: [
        { value: announcement.size, disabled: !announcement.size },
        [
          Validators.required,
          TcInputValidators.minMaxNumber(
            0,
            window.config.company.dispo_announcement_max_size,
          ),
        ],
      ],
      push_notifications: [announcement.push_notifications],
      description: [announcement.description],
      state: [announcement.state, [Validators.required]],
      message_template_id: [null],
      store: [announcement.store],
    });
  }
}
