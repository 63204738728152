import { ExclusionPolicy, Strategy } from 'typeserializer/dist';

@Strategy(ExclusionPolicy.NONE)
export class User {
  id: number;
  role_id: number;
  department_ids: number[];
  available_department_ids: number[];

  email: string;
  first_name: string;
  last_name: string;

  shorthand: string;
  locale: 'de' | 'en';
}
