import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { TcBinaryFromBoolean } from '@timecount/utils';

import { ModalComponent, TcModalModule } from '../modal';
import { TcPanelComponent } from '../panel';
import { TcNoPaddingDirective } from '../../shared';

@Component({
  selector: 'tc-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzSegmentedModule,
    TcBinaryFromBoolean,
    TcModalModule,
    TranslateModule,
    TcPanelComponent,
    NzTagModule,
  ],
  hostDirectives: [
    {
      directive: TcNoPaddingDirective,
      inputs: ['tcNoPadding'],
    },
  ],
})
export class TcFormWrapperComponent implements AfterViewInit, OnChanges {
  @Input() tcFormTitle: string;
  @Input() isBatch = false;
  @Input() isLoading = false;
  @Input() isSubmitting = false;
  @Input() isInvalid = false;
  @Input() shouldShowPanel = true;

  @Input()
  @HostBinding('class.is-modal')
  isModal = false;

  @Input() set tcExtended(value: boolean | undefined) {
    this._tcExtended = !!value;
  }
  get tcExtended(): boolean {
    return this._tcExtended;
  }
  private _tcExtended: boolean;
  @Output() tcExtendedChange = new EventEmitter<boolean>();

  @Input() primaryButtonLabelKey: 'save' | 'update' | 'copy' = 'save';
  @Output() primaryButtonClick = new EventEmitter();

  @Input() secondaryButtonLabelKey: 'cancel' | 'clear' | 'delete' = 'cancel';
  @Input() disableSecondaryButtonOnInvalid = true;
  @Output() secondaryButtonClick = new EventEmitter();

  @ViewChild('modalBody', { static: false, read: ElementRef })
  modalBody: ElementRef;
  @ViewChild('modalFooter', { static: false, read: ElementRef })
  modalFooter: ElementRef;

  constructor(@Optional() private modalComponent: ModalComponent) {}

  ngOnChanges({ isLoading }: SimpleChanges): void {
    if (isLoading) {
      const { firstChange, currentValue, previousValue } = isLoading;

      if (
        !firstChange &&
        currentValue !== previousValue &&
        this.modalComponent
      ) {
        setTimeout(() => {
          this.modalComponent.resize();
        }, 100);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.modalComponent) {
      this.modalComponent.modalBody = this.modalBody;
      this.modalComponent.modalFooter = this.modalFooter;
    }
  }

  onExtendedChange(index: number): void {
    this.tcExtendedChange.emit(Boolean(index));
  }
}
