<form class="form" [formGroup]="formGroup">
  <tc-interval-web
    *ngIf="importer !== 'dispo_availability'"
    formControlName="intervalFilter"
    [tcFieldType]="'dateTime'"
    [tcConfig]="{ maxItemDuration: { days: 365 }, fieldType: 'dateTime' }"
    [tcLabels]="{
      start: ('aggregation/import.attrs.starts_at' | translate),
      end: ('aggregation/import.attrs.ends_at' | translate)
    }"
  ></tc-interval-web>
  <tc-hub-interval-select
    (selectedInterval)="onIntervalTypeSelected($event)"
  ></tc-hub-interval-select>

  <tc-hub-option-select
    *ngIf="importer !== 'dispo_availability'"
    label="{{ 'aggregation.attrs.gratis_type' | translate }}"
    type="aggregation.gratis_type"
    [formGroup]="formGroup"
    controlName="gratis_type"
    bindLabel="title"
    bindValue="id"
  >
  </tc-hub-option-select>

  <tc-hub-option-select
    *ngIf="
      importer !== 'dispo_availability' &&
      importer !== 'dispo_job' &&
      importer !== 'dispo_slot'
    "
    label="{{ 'aggregation/import.attrs.resource_type' | translate }}"
    type="aggregation.resource_type"
    [formGroup]="formGroup"
    controlName="resource_type"
    bindLabel="title"
    bindValue="id"
  >
  </tc-hub-option-select>

  <tc-hub-collection-select
    *ngIf="importer !== 'dispo_job' && importer !== 'dispo_slot'"
    label="{{ 'aggregation/import.attrs.resources' | translate }}"
    [collectionTypes]="['Employee', 'Contractor']"
    [formGroup]="formGroup"
    controlName="resources"
    bindLabel="label"
    [searchable]="true"
    multiple="true"
  >
  </tc-hub-collection-select>

  <tc-hub-collection-select
    *ngIf="importer !== 'dispo_availability'"
    label="{{ 'aggregation/import.attrs.skus' | translate }}"
    [collectionTypes]="['Job', 'Material']"
    [formGroup]="formGroup"
    controlName="skus"
    bindLabel="title"
    [searchable]="true"
    multiple="true"
  >
  </tc-hub-collection-select>

  <tc-hub-option-select
    *ngIf="importer === 'aggregation' || importer === 'billing_entry'"
    label="{{ 'aggregation/import.attrs.entry_type' | translate }}"
    type="aggregation.entry_type"
    [formGroup]="formGroup"
    controlName="entry_type"
    bindLabel="title"
    bindValue="id"
  >
  </tc-hub-option-select>

  <tc-hub-remote-select
    *ngIf="importer === 'dispo_availability'"
    label="{{ 'aggregation/import.attrs.availability_types' | translate }}"
    url="/api/dispo/settings/availability_types"
    [formGroup]="formGroup"
    controlName="availability_types"
    bindLabel="title"
    bindValue="id"
    searchable="true"
    multiple="true"
  >
  </tc-hub-remote-select>

  <tc-hub-collection-select
    *ngIf="importer !== 'dispo_availability'"
    label="{{ 'aggregation/import.attrs.venues' | translate }}"
    [collectionTypes]="['Venue']"
    [formGroup]="formGroup"
    controlName="venues"
    bindLabel="title"
    bindValue="id"
    [searchable]="true"
    multiple="true"
  >
  </tc-hub-collection-select>

  <fieldset class="plans-fieldset">
    <!-- NOTE:
      The `url` is dynamically constructed using the `project_id`, but the
     `tcInitialValue` may be undefined due to unreproducible conditions. That
      is not a problem, though, as the select will handle the wrongly defined
      `url` and simply not display any data.
    -->
    <tc-hub-remote-select
      *ngIf="
        importer !== 'dispo_availability' &&
        importer !== 'aggregation' &&
        importer !== 'billing_entry'
      "
      label="{{ 'aggregation/import.attrs.events' | translate }}"
      url="/api/projects/{{ tcProjectId }}/events"
      [formGroup]="formGroup"
      controlName="events"
      bindLabel="title"
      bindValue="id"
      searchable="true"
      [multiple]="!formGroup.get('isSubFilter').value"
    >
    </tc-hub-remote-select>

    <label
      *ngIf="
        importer !== 'dispo_availability' &&
        importer !== 'aggregation' &&
        importer !== 'billing_entry'
      "
    >
      <nz-switch formControlName="isSubFilter"></nz-switch>
      {{ 'aggregation/import.attrs.event_for_sub_filter' | translate }}
    </label>

    <tc-hub-remote-select
      *ngIf="
        importer !== 'dispo_availability' &&
        importer !== 'aggregation' &&
        importer !== 'billing_entry'
      "
      label="{{ 'aggregation/import.attrs.schedules' | translate }}"
      [url]="schedulesUrl$ | async"
      [formGroup]="formGroup"
      controlName="schedules"
      bindLabel="title"
      bindValue="id"
      searchable="true"
      multiple="true"
    >
    </tc-hub-remote-select>

    <tc-hub-remote-select
      *ngIf="
        importer !== 'dispo_availability' &&
        importer !== 'aggregation' &&
        importer !== 'billing_entry'
      "
      label="{{ 'aggregation/import.attrs.tasks' | translate }}"
      [url]="tasksUrl$ | async"
      [formGroup]="formGroup"
      controlName="tasks"
      bindLabel="dateLabel"
      bindValue="id"
      [decorate]="taskDecorator"
      searchable="true"
      multiple="true"
    >
    </tc-hub-remote-select>
  </fieldset>

  <tc-input-integer-web
    *ngIf="importer !== 'billing_entry'"
    label="{{ 'aggregation/import.attrs.offset' | translate }}"
    formControlName="offset"
  ></tc-input-integer-web>

  <div class="checkbox-field" *ngIf="importer !== 'billing_entry'">
    <label nz-checkbox formControlName="storno">
      {{ 'aggregation/import.attrs.storno' | translate }}
    </label>
  </div>

  <div *ngIf="importer === 'dispo_assignment'" class="checkbox-field">
    <label nz-checkbox formControlName="target_time">
      {{ 'aggregation/import.attrs.target_time' | translate }}
    </label>
  </div>

  <div
    class="checkbox-field"
    *ngIf="importer !== 'dispo_availability' && importer !== 'billing_entry'"
  >
    <label nz-checkbox formControlName="gross_duration">
      {{ 'aggregation/import.attrs.gross_duration' | translate }}
    </label>
  </div>
</form>
