import { Component, OnInit } from '@angular/core';

import { BaseModalComponent } from '@timecount/ui';

import { DispoMessage } from '../collections/message';

@Component({
  selector: 'tc-hub-dispo-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class DispoMessageComponent
  extends BaseModalComponent
  implements OnInit
{
  messages: DispoMessage[];
  type: string;
  section = 'create';

  ngOnInit() {
    super.ngOnInit();
    this.messages = this.config.data.messages;
    this.type = this.config.data.type;
    this.section = this.config.data.section;
  }
}
