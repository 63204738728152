import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { combineLatest, of } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';

import { ModalService } from '@timecount/ui';

import { DispoLoaderService } from '../dispo/loader/loader.service';
import { Action } from '../core/types/action';
import { ActionType } from '../core/types/action-type';
import { EntryType } from '../core/types/entry-type';
import { ValidationService } from '../dispo/collections/validation.service';
import {
  DispoRangeDataStructure,
  DispoRangeDataStructureFactory,
} from '../dispo/datastructures/range.service';
import { DispoAssignmentCollection } from '../dispo/collections/assignment';
import { Assignment } from '../dispo/collections/assignment.model';
import { DispoPlanCollection, Plan } from '../dispo/collections/plan';
import { DispoTaskCollection, Task } from '../dispo/collections/task';
import { LogsComponent } from '../shared/logs/logs.component';

import { Timesheet } from './timesheet.model';
import { TimesheetCollection } from './timesheet.collection';
import { TimesheetComponent } from './timesheet.component';

@Injectable({
  providedIn: 'root',
})
export class TimesheetService {
  private translations: { [key: string]: string } = {};

  private rangeDataStructure: DispoRangeDataStructure;

  constructor(
    private modalService: ModalService,
    private loadingService: DispoLoaderService,
    private timesheetCollection: TimesheetCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private taskCollection: DispoTaskCollection,
    private planCollection: DispoPlanCollection,
    private translateService: TranslateService,
    private validationService: ValidationService,
    private rangeDataStructureFactory: DispoRangeDataStructureFactory,
  ) {
    this.rangeDataStructure = this.rangeDataStructureFactory.all();

    this.translateService
      .get([
        'timesheet.modal.title',
        'timesheet.confirms.delete',
        'timesheet.confirms.confirm',
        'timesheet.confirms.unconfirm',
        'trails.title',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  edit(sheet: any) {
    let timesheet$;

    if (sheet.identifier === 'timesheet') {
      timesheet$ = of(sheet);
    } else {
      if (sheet.tracked_time) {
        timesheet$ = this.timesheetCollection.forAssignment(sheet).pipe(
          mergeMap((items) => {
            return items[0] ? of(items[0]) : this.fromAssignment(sheet);
          }),
        );
      } else {
        timesheet$ = this.fromAssignment(sheet);
      }
    }

    timesheet$.pipe(first()).subscribe((timesheet) => {
      this.section([timesheet], 'general');
    });
  }

  multiForTask(task: Task) {
    const timesheets$ = this.assignmentCollection.forTask(task).pipe(
      first(),
      map((assignments: Assignment[]) => {
        return assignments.map((assignment) => {
          if (assignment.tracked_time) {
            return this.timesheetCollection.forAssignment(assignment).pipe(
              mergeMap((items) => {
                return items[0]
                  ? of({ ...items[0], position: assignment.position })
                  : this.fromAssignment(assignment);
              }),
            );
          } else {
            return this.fromAssignment(assignment);
          }
        });
      }),
      switchMap((timesheets$) => combineLatest(timesheets$)),
    );

    timesheets$.pipe(first()).subscribe((timesheets) => {
      this.section(timesheets, 'multi', true);
    });
  }

  multi(sheets: (Timesheet | Assignment)[]) {
    const timesheets$ = sheets.map((sheet: any) => {
      if (sheet.identifier === 'timesheet') {
        return of(sheet);
      } else {
        if (sheet.tracked_time) {
          return this.timesheetCollection.forAssignment(sheet).pipe(
            mergeMap((items) => {
              return items[0] ? of(items[0]) : this.fromAssignment(sheet);
            }),
          );
        } else {
          return this.fromAssignment(sheet);
        }
      }
    });

    combineLatest(timesheets$)
      .pipe(first())
      .subscribe((timesheets) => {
        this.section(timesheets, 'multi', true);
      });
  }

  confirm(sheets: (Timesheet | Assignment)[]) {
    const timesheets$ = sheets.map((sheet: any) => {
      if (sheet.identifier === 'timesheet') {
        return of(sheet);
      } else {
        if (sheet.tracked_time) {
          return this.timesheetCollection.forAssignment(sheet).pipe(
            mergeMap((items) => {
              return items[0] ? of(items[0]) : this.fromAssignment(sheet);
            }),
          );
        } else {
          return this.fromAssignment(sheet);
        }
      }
    });

    combineLatest(timesheets$)
      .pipe(first())
      .subscribe((timesheets) => {
        const actions = timesheets.map((timesheet) => {
          timesheet = Object.assign({}, timesheet, { state: 'confirmed' });

          return <Action>{
            id: uuid(),
            entry: timesheet,
            entry_type: EntryType.Timesheet,
            type: timesheet.shallow ? ActionType.create : ActionType.update,
            errors: [],
            validations: (item, stack) =>
              this.timesheetCollection.remoteValidations(
                item,
                stack,
                timesheet.shallow ? ActionType.create : ActionType.update,
              ),
          };
        });

        this.validationService.run(
          actions,
          true,
          this.translations['timesheet.confirms.confirm'],
        );
      });
  }

  unconfirm(timesheets: Timesheet[]) {
    const actions = timesheets.map((timesheet) => {
      timesheet = Object.assign({}, timesheet, { state: 'unconfirmed' });

      return <Action>{
        id: uuid(),
        entry: timesheet,
        entry_type: EntryType.Timesheet,
        type: ActionType.update,
        errors: [],
        validations: (item, stack) =>
          this.timesheetCollection.remoteValidations(
            item,
            stack,
            ActionType.update,
          ),
      };
    });

    this.validationService.run(
      actions,
      true,
      this.translations['timesheet.confirms.unconfirm'],
    );
  }

  delete(timesheets) {
    const actions = timesheets.map((timesheet) => {
      return <Action>{
        id: uuid(),
        entry: timesheet,
        entry_type: EntryType.Timesheet,
        type: ActionType.delete,
        errors: [],
        validations: (item, stack) =>
          this.timesheetCollection.remoteValidations(
            item,
            stack,
            ActionType.delete,
          ),
      };
    });

    this.validationService.run(
      actions,
      true,
      this.translations['timesheet.confirms.delete'],
    );
  }

  section(timesheets: any, section: string, single = false) {
    const ref = this.modalService.open(TimesheetComponent, {
      data: {
        timesheets: timesheets,
        section: section,
        single: single,
      },
      modalTitle: this.translations['timesheet.modal.title'],
    });
  }

  single(timesheets: any, section: string) {
    this.section(timesheets, section, true);
  }

  fromAssignment(assignment: Assignment) {
    return this.taskCollection.get(assignment.task_id).pipe(
      mergeMap((task: Task) => {
        return this.planCollection.get(task.plan_id).pipe(
          map((plan: Plan) => {
            return this.timesheetCollection.fromAssignment(
              assignment,
              task,
              plan,
            );
          }),
        );
      }),
    );
  }

  viewLogs(resource_id: number) {
    this.modalService.open(LogsComponent, {
      data: {
        resource_id,
        resource_type: 'Timesheet',
      },
      modalTitle: this.translations['trails.title'],
    });
  }
}
