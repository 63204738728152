import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'keyValueFilter',
})
export class TcKeyValueFilterPipe implements PipeTransform {
  transform<T>(
    variables: KeyValue<string, T>[],
    filterText = '',
  ): KeyValue<string, T>[] {
    if (!variables) return [];
    if (!filterText) return variables;

    return variables.filter(
      ({ key, value }) =>
        key.toLowerCase().includes(filterText.toLowerCase()) ||
        JSON.stringify(value).toLowerCase().includes(filterText.toLowerCase()),
    );
  }
}
