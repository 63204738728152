import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

import { TcFormGroupIntervalService } from '@timecount/ui';
import { TcInterval } from '@timecount/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-date-range-widget',
  templateUrl: './date-range-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './date-range-widget.component.scss',
  ],
})
export class DateRangeWidgetComponent
  extends TcControlWidgetBaseDirective
  implements OnInit, AfterViewInit
{
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formGroupIntervalService: TcFormGroupIntervalService,
  ) {
    super();
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit() {
    this.form = this._buildForm();

    this.form.get('interval').valueChanges.subscribe((value: TcInterval) => {
      this.formProperty.setValue(TcInterval.formatForApi(value), false);
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    //FIXME: avoid setting the formGroup to prevent it from being marked as 'dirty' prior to user interaction.
    //Issue key: DEV-1814
    this.formProperty.valueChanges
      .pipe(
        distinctUntilChanged(
          (prev, curr) => prev.start === curr.start && prev.end === curr.end,
        ),
      )
      .subscribe((value: number) => {
        this._setFormControlValue(value);
      });

    this._setFormControlValue(this.formProperty.value);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _setFormControlValue(value) {
    if (value && Object.keys(value).length > 0) {
      const { starts_at, ends_at } = value;
      this.form
        .get('interval')
        .patchValue(TcInterval.parseFromApi({ starts_at, ends_at }), {
          onlySelf: true,
          emitEvent: false,
        });
    }
  }

  private _buildForm() {
    return this.formBuilder.group({
      interval: [
        { value: undefined, disabled: this.schema.readOnly },
        this.schema[`isRequired`] ? Validators.required : null,
      ],
    });
  }
}
