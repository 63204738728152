<!--
  Ng-Zorro doesn't actually have a panel component, so we're using the collapse
  component instead, which has the looks of a panel (and is actually how they
  call each collapsible section).
-->
<nz-collapse
  *ngIf="shouldShow; else contentTemplate"
  class="wrapper"
  [class]="tcColor ? 'tc-' + tcColor : ''"
>
  <nz-collapse-panel
    #panel
    class="panel"
    [nzHeader]="hasHeaderTemplate ? headerStartTemplate : tcTitle"
    [nzDisabled]="!tcCollapsible"
    [nzShowArrow]="tcCollapsible"
    [nzExtra]="headerEndTemplate"
    [nzActive]="!tcCollapsed"
    (nzActiveChange)="onActiveChange($event)"
  >
    <div class="content" [class]="tcContentClass">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </nz-collapse-panel>
  <nz-collapse-panel
    #footer
    class="footer"
    [class.no-border]="hideFooter"
    [nzHeader]="footerStartTemplate"
    [nzActive]="false"
    [nzDisabled]="true"
    [nzShowArrow]="false"
    [nzExtra]="footerEndTemplate"
  ></nz-collapse-panel>
</nz-collapse>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-template #headerStartTemplate>
  <ng-content select="[tcHeaderStart]"></ng-content>
</ng-template>

<ng-template #headerEndTemplate>
  <ng-content select="[tcHeaderEnd]"></ng-content>
</ng-template>

<ng-template #footerStartTemplate>
  <ng-content select="[tcFooterStart]"></ng-content>
</ng-template>

<ng-template #footerEndTemplate>
  <ng-content select="[tcFooterEnd]"></ng-content>
</ng-template>
