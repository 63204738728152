import { Injectable } from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DispoLoaderService {
  loading$ = {
    task: new ReplaySubject(),
    schedule: new ReplaySubject(),
    announcement: new ReplaySubject(),
  };

  constructor() {
    this.loading$.task.next([]);
    this.loading$.schedule.next([]);
  }

  addAssignmentsToLoad(assignments, subjects) {
    this.addToLoad('task', assignments, 'task_id');
    this.addToLoad('schedule', assignments, 'schedule_id');

    combineLatest(...subjects).subscribe({
      next: () => {
        this.removeFromLoad('task', assignments, 'task_id');
        this.removeFromLoad('schedule', assignments, 'schedule_id');
      },
      error: () => {
        this.removeFromLoad('task', assignments, 'task_id');
        this.removeFromLoad('schedule', assignments, 'schedule_id');
      },
    });
  }

  addInvitationsToLoad(invitations, subjects) {
    this.addToLoad('announcement', invitations, 'announcement_id');

    combineLatest(...subjects).subscribe({
      next: () => {
        this.removeFromLoad('announcement', invitations, 'announcement_id');
      },
      error: () => {
        this.removeFromLoad('announcement', invitations, 'announcement_id');
      },
    });
  }

  addTasksToLoad(tasks, subjects) {
    this.addToLoad('task', tasks, 'id');
    this.addToLoad('schedule', tasks, 'schedule_id');

    combineLatest(...subjects).subscribe({
      next: () => {
        this.removeFromLoad('task', tasks, 'id');
        this.removeFromLoad('schedule', tasks, 'schedule_id');
      },
      error: () => {
        this.removeFromLoad('task', tasks, 'id');
        this.removeFromLoad('schedule', tasks, 'schedule_id');
      },
    });
  }

  loading(type, id) {
    const loader: ReplaySubject<number[]> = this.loading$[type];
    return loader.pipe(
      // distinctUntilChanged((prevIds , currIds) => {
      //   const prevLoading = prevIds.indexOf(task_id) !== -1;
      //   const nextLoading = currIds.indexOf(task_id) !== -1;

      //   if (prevLoading !== nextLoading) {
      //     return true;
      //   }

      //   return false;
      // }),
      map((ids) => {
        return ids.indexOf(id) !== -1;
      }),
    );
  }

  private addToLoad(type, entries, lookup) {
    const loader = this.loading$[type];
    const toLoad = [...new Set(entries.map((a) => a[lookup]))].filter((x) => x);

    loader.pipe(first()).subscribe((currentlyLoading) => {
      loader.next([...new Set(toLoad.concat(currentlyLoading))]);
    });
  }

  private removeFromLoad(type, entries, lookup) {
    const loader = this.loading$[type];
    const toUnload = [...new Set(entries.map((a) => a[lookup]))].filter(
      (x) => x,
    );

    loader.pipe(first()).subscribe((nowLoading) => {
      loader.next(nowLoading.filter((x) => !toUnload.includes(x)));
    });
  }
}
