<div class="wrapper" *ngIf="(announcements$ | async)?.length !== 0">
  <div class="row">
    <div class="row-header" [ngStyle]="{ 'background-color': plan.color }">
      <tc-hub-toggle
        [active$]="expanded$"
        (mouseup)="onTogglePlan($event)"
      ></tc-hub-toggle>
      <span class="title">{{ plan.title }}</span>
      <tc-hub-data-panel
        [data]="plan$ | async"
        section="dispo.announcements.plan"
        class="plan-stats"
      ></tc-hub-data-panel>
    </div>
    <div class="days">
      <ng-container *ngFor="let week of weeks$ | async">
        <div
          class="day"
          *ngFor="let day of week; let first = first; let last = last"
          [class.weekend]="isWeekend(day)"
          [ngClass]="{
            first: first,
            last: last,
            'day-included': includes(day)
          }"
          [ngStyle]="{
            'background-color': includes(day) ? plan.color : transparent
          }"
        ></div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="expanded$ | async">
    <!-- PERFORMANCE: refactor to use trackBy: trackById; does not track changes to announcement though ??? (enable/disable) -->
    <tc-hub-dan-announcement
      #announcementComponent
      *ngFor="
        let announcement of announcements$ | async;
        trackBy: trackById;
        let even = even;
        let odd = odd
      "
      [ngClass]="{ odd: odd, even: even }"
      [announcement]="announcement"
      [plan]="plan"
      [attr.data-announcementid]="announcement.id"
      (tcSelect)="onSelect($event)"
      tcHubDispoMenuActive
      activeType="DispoAnnouncement"
      [activeId]="announcement.id"
    ></tc-hub-dan-announcement>
  </ng-container>
</div>
