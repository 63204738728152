import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `
    button[nz-button][tabindex],
  `,
})
export class NzButtonTabindexSupportDirective implements AfterViewInit {
  @Input() tabindex;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.setAttribute('tabindex', this.tabindex);
  }
}
