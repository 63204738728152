<div
  class="tc-modal-overlay"
  [ngStyle]="overlayStyles"
  #modalOverlay
  *ngIf="config.backdrop"
  (click)="onOverlayClick($event)"
></div>

<div
  class="tc-modal"
  tabindex="-1"
  role="dialog"
  #modalRoot
  [south]="true"
  [east]="true"
  [southEast]="true"
  [minWidth]="config.minWidth"
  [minHeight]="config.minHeight"
  (resizing)="onResize($event)"
  [ngStyle]="dialogStyles"
  [ngClass]="{ 'tc-modal--maximized': maximized }"
  (mousedown)="moveOnTop()"
  (touchstart)="moveOnTop()"
  tcHubResizable
  cdkDrag
  cdkDragBoundary="body"
  [cdkDragFreeDragPosition]="config.position"
  (cdkDragEnded)="onMove($event)"
>
  <div class="tc-modal-header" #modalHeader cdkDragHandle>
    <div class="tc-modal-titlebar">
      <span class="tc-modal-title" *ngIf="config.modalTitle">{{
        config.modalTitle
      }}</span>
      <nz-tag
        class="tc-admin-tag"
        *ngFor="let tag of config.adminTags"
        nzColor="purple"
      >
        <span>{{ tag }}</span>
      </nz-tag>
    </div>
    <div class="tc-modal-controlbar">
      <span
        (click)="toggleMaximize($event)"
        class="tc-modal-controlbar-control"
      >
        <i *ngIf="!maximized" nz-icon nzType="fullscreen" nzTheme="outline"></i>
        <i
          *ngIf="maximized"
          nz-icon
          nzType="fullscreen-exit"
          nzTheme="outline"
        ></i>
      </span>
      <span
        (click)="close()"
        (mousedown)="onCloseIconClick($event)"
        (touchstart)="onCloseIconClick($event)"
        class="tc-modal-controlbar-control"
      >
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </span>
    </div>
  </div>

  <div
    class="tc-modal-content"
    [class.no-padding]="config.noPadding"
    #modalContent
  >
    <ng-template tcHubModalContent></ng-template>
  </div>
</div>
