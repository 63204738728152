<div class="modal-container">
  <div class="modal-body">
    <div class="filter-bar">
      <tc-hub-dispo-filter
        section="dispo.announcement.employees"
      ></tc-hub-dispo-filter>
    </div>
    <nz-table
      nzVirtualScroll
      nzSize="small"
      nzVirtualItemSize="27"
      nzVirtualMinBufferPx="200"
      nzVirtualMaxBufferPx="400"
      [nzData]="resources$ | async"
      [nzVirtualForTrackBy]="trackByIndex"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzScroll]="{ x: '500px', y: '392px' }"
    >
      <thead>
        <tr>
          <th
            nzWidth="50px"
            nzShowCheckbox
            [(nzChecked)]="resourcesAddAll"
            [nzIndeterminate]="resourcesAddAllIndeterminate"
            (nzCheckedChange)="updateAllResources()"
          ></th>
          <th nzWidth="350px">Name</th>
          <th nzWidth="100px"></th>
        </tr>
      </thead>
      <tbody>
        <ng-template nz-virtual-scroll let-resource let-index="index">
          <tr>
            <td
              nzWidth="50px"
              nzShowCheckbox
              [(nzChecked)]="resourcesToAdd[resource.id]"
              (nzCheckedChange)="updateSingleResource()"
            ></td>
            <td nzWidth="350px">{{ resource.name }}</td>
            <td nzWidth="100px">
              <tc-hub-data-panel
                [data]="resource$(resource.id) | async"
                section="dispo.announcements.resource"
                class="resource-stats"
              ></tc-hub-data-panel>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      (click)="createSelected()"
    >
      {{ 'dispo/invitation.actions.add_resources' | translate }}
      ({{ createCount() }})
    </button>
  </div>
</div>
