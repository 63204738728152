import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { dateToString } from '../../../core/helpers';
import { LocalSettingsService } from '../../../core/local-settings.service';
import { ScheduleService } from '../../actions/schedule.service';
import { TaskService } from '../../actions/task.service';
import { DispoPlanCollection } from '../../collections/plan';
import { PlanDS } from '../../../dispo/datastructures/plan-ds.model';
import {
  DispoTasksDayService,
  DispoTasksServiceFactory,
} from '../dispo-tasks.service';
import { DTTaskComponent } from '../task/task.component';

@Component({
  selector: 'tc-hub-dt-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DTPlanComponent implements OnInit {
  @Input() date: Date;
  @Input() plan: any;
  @Input() showEmpty: Observable<boolean>;

  @Output() tcSelect: EventEmitter<'selected'> = new EventEmitter();

  @ViewChildren('taskComponent') taskComponents: QueryList<DTTaskComponent>;

  service: DispoTasksDayService;
  tasks$: Observable<any[]>;
  plan$: Observable<PlanDS>;

  visible$: Observable<boolean>;
  expanded$: Observable<boolean>;

  constructor(
    private factory: DispoTasksServiceFactory,
    private localSettings: LocalSettingsService,
    private planCollection: DispoPlanCollection,
    private taskActions: TaskService,
    private scheduleActions: ScheduleService,
  ) {}

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({
      item_type: 'DispoPlanDay',
      item: [Object.assign({ date: dateToString(this.date) }, this.plan)],
    });
  }

  ngOnInit() {
    this.service = this.factory.forDate(this.date);
    this.tasks$ = this.service.planTasks(this.plan);
    this.plan$ = this.service.planDS(this.plan);
    const dateLookup = dateToString(this.date);
    this.expanded$ = this.localSettings.get(
      `dispo.planExpanded.${this.plan.id}|${dateLookup}`,
      true,
    );

    this.visible$ = combineLatest([this.showEmpty, this.tasks$]).pipe(
      map(([showEmpty, tasks]) => {
        if (showEmpty) {
          return true;
        } else {
          return tasks.length !== 0;
        }
      }),
    );
  }

  onMarkdownUpdate(markdown) {
    this.plan.note = markdown;
    this.planCollection.update(this.plan.id, this.plan);
  }

  onTogglePlan() {
    const dateLookup = dateToString(this.date);
    this.localSettings.toggle(
      `dispo.planExpanded.${this.plan.id}|${dateLookup}`,
      true,
    );
  }

  onSelect() {
    this.tcSelect.emit('selected');
  }

  createTask() {
    this.taskActions.createFromPlanDefault(this.date, this.plan);
  }

  createSchedule() {
    this.scheduleActions.createFromPlanDefault(this.plan);
  }

  clearSelection() {
    this.taskComponents.map((t) => t.clearSelection());
  }

  trackById(index, item) {
    return item.id;
  }
}
