import {
  AfterContentInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

import { TourService } from '../base/tc-tour/tour.service';
import { LocalSettingsService } from '../core/local-settings.service';

import { DispoFocusService } from './dispo-focus.service';
import { TaskService } from './actions/task.service';
import { PlanService } from './actions/plan.service';
import { ScheduleService } from './actions/schedule.service';
import { MessageService } from './actions/message.service';
import { ImportService } from './actions/import.service';

@Component({
  selector: 'tc-hub-dispo',
  templateUrl: './dispo.component.html',
  styleUrls: ['./dispo.component.scss'],
})
export class DispoComponent implements OnInit, OnDestroy, AfterContentInit {
  private begin: Date;
  private end: Date;

  public begin$: Observable<Date>;
  public end$: Observable<Date>;
  public info: any = {};
  public views: Array<string> = [];
  public viewsConfig = [];
  public tours$;

  constructor(
    public dispoFocus: DispoFocusService,
    public localSettings: LocalSettingsService,
    public tourService: TourService,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private ngSelectConfig: NgSelectConfig,
    private taskActions: TaskService,
    private planActions: PlanService,
    private scheduleActions: ScheduleService,
    private messageActions: MessageService,
    private importActions: ImportService,
  ) {
    this.onDragOver = this.onDragOver.bind(this);

    // move to app.component later
    this.translateService
      .get(['no_entries', 'type_to_search'])
      .subscribe((translations) => {
        this.ngSelectConfig.notFoundText = translations['no_entries'];
        this.ngSelectConfig.typeToSearchText = translations['type_to_search'];
        // this.ngSelectConfig.addTagText = translations['no_entries'];
        // this.ngSelectConfig.loadingText = translations['no_entries'];
        // this.ngSelectConfig.clearAllText = translations['no_entries'];
      });
  }

  ngOnInit() {
    this.begin$ = this.dispoFocus.begin();
    this.end$ = this.dispoFocus.end();

    this.begin$.subscribe({
      next: (date) => (this.begin = date),
    });

    this.end$.subscribe({
      next: (date) => (this.end = date),
    });

    this.tours$ = this.tourService.tours('dispo');

    this.localSettings.get('dispo.views').subscribe((views) => {
      // TODO: can be removed later - backwards compat with store
      if (!Array.isArray(views[0])) {
        views = views.map((v) => [v]);
      }
      this.viewsConfig = views;
      this.views = views.map((v) => v[0]);
    });
  }

  ngAfterContentInit() {
    this.elementRef.nativeElement.addEventListener('dragover', this.onDragOver);
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener(
      'dragover',
      this.onDragOver,
    );
  }

  private onDragOver(e) {
    e.preventDefault();
  }

  onDateChange(date) {
    this.dispoFocus.setDate(date);
  }

  createPlan() {
    const date = new Date(this.begin.getTime());
    this.planActions.createFromDateDefault(date);
  }

  createSchedule() {
    this.scheduleActions.createDefault();
  }

  createMessage() {
    this.messageActions.generalDefault();
  }

  openTour(tour) {
    this.tourService.start(tour.key);
  }

  sizeFor(view) {
    const sizeConfig = (this.viewsConfig.find((v) => v[0] === view) || [])[1];

    if (typeof sizeConfig !== 'undefined') {
      return sizeConfig;
    }

    const viewCount = this.views.length;
    const actionsPresent = this.views.indexOf('dispo.actions') !== -1;

    let config = {
      size3: {
        actions: 14,
        defaultWithActions: 43,
        default: 50,
      },
      size2: {
        actions: 14,
        defaultWithActions: 86,
        default: 50,
      },
      size1: {
        default: 100,
      },
    };

    config = config[`size${viewCount}`] || {};

    if (view === 'dispo.actions') {
      return config['actions'] || 0;
    }

    if (actionsPresent) {
      return config['defaultWithActions'] || 0;
    }

    return config['default'] || 0;
  }

  createTask() {
    const date = new Date(this.begin.getTime());

    this.taskActions.createFromDateDefault(date);
  }

  createImport() {
    const begin = new Date(this.begin.getTime());
    const end = new Date(this.end.getTime());

    this.importActions.createFromDateRange(begin, end);
  }
}
