<tc-form-wrapper
  [tcFormTitle]="translations['account/preference.legends.smtp_api']"
  [isModal]="false"
  [isSubmitting]="false"
  [primaryButtonLabelKey]="enabled ? 'update' : 'enable'"
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  (primaryButtonClick)="save()"
  (secondaryButtonClick)="delete()"
  [secondaryButtonLabelKey]="'delete'"
  [secondaryButtonLabelKey]="'disable'"
  [disableSecondaryButtonOnInvalid]="false"
>
  <div *ngIf="enabled !== undefined" tcFormExtras>
    <nz-tag class="status-tag" [nzColor]="enabled ? '#5cb85c' : '#d9534f'">{{
      enabled
        ? translations['account/preference.smtp_api.enabled']
        : translations['account/preference.smtp_api.disabled']
    }}</nz-tag>
  </div>

  <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="save()">
    <fieldset
      [tcPanel]
      [tcTitle]="'account/preference.legends.smtp_settings' | translate"
    >
      <tc-input-text-web
        label="{{ 'account/preference.attrs.smtp_api_address' | translate }}"
        formControlName="address"
      ></tc-input-text-web>

      <tc-input-integer-web
        label="{{ 'account/preference.attrs.smtp_api_port' | translate }}"
        formControlName="port"
      ></tc-input-integer-web>

      <tc-input-email-web
        label="{{ 'account/preference.attrs.smtp_api_user' | translate }}"
        formControlName="user"
      ></tc-input-email-web>

      <tc-input-password-web
        label="{{ 'account/preference.attrs.smtp_api_password' | translate }}"
        formControlName="password"
      ></tc-input-password-web>

      <tc-hub-option-select
        label="{{ 'account/preference.attrs.smtp_api_encryption' | translate }}"
        type="smtp_api.encryption"
        [formGroup]="formGroup"
        controlName="encryption"
        bindLabel="title"
        bindValue="id"
      >
      </tc-hub-option-select>

      <tc-hub-option-select
        label="{{ 'account/preference.attrs.smtp_api_auth' | translate }}"
        type="smtp_api.auth"
        [formGroup]="formGroup"
        controlName="auth"
        bindLabel="title"
        bindValue="id"
      >
      </tc-hub-option-select>
    </fieldset>

    <tc-hub-email-headers-fieldset
      formControlName="emailHeaders"
    ></tc-hub-email-headers-fieldset>
  </form>
</tc-form-wrapper>
