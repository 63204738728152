<ng-container>
  <div class="wrapper">
    <button
      nz-button
      nz-popover
      nzType="text"
      nzPopoverTrigger="click"
      nzPopoverPlacement="rightBottom"
      [nzPopoverContent]="popoverContent"
    >
      <span class="help-icon" nz-icon nzType="tc:help" nzTheme="outline"></span>
    </button>
  </div>

  <ng-template #popoverContent>
    <nz-list>
      <nz-list-item>
        <button nz-button nz-popover nzType="text" (click)="tcDocClick.emit()">
          {{ 'manual' | translate }}
        </button>
      </nz-list-item>
      <nz-list-item
        *ngFor="let issueCollector of issueCollectors">
        <button
          nz-button
          nz-popover
          nzType="text"
          (click)="showDialog(issueCollector)"
        >
          {{ issueCollector.label }}
        </button>
      </nz-list-item>
    </nz-list>
  </ng-template>
</ng-container>
