<div class="tc-modal-body" #modalBody>
  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'delete'">
    <nz-tab
      nzTitle="{{ 'dispo/invitation.tab_type.delete' | translate }}"
      *tcHubHide="'views.dispo.invitation.delete'"
    >
      <tc-hub-din-delete
        class="modal-container"
        [invitations]="invitations"
        (signal)="onSignal($event)"
      ></tc-hub-din-delete>
    </nz-tab>
  </nz-tabset>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
