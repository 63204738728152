import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { DispoScheduleCollection } from '../../collections/schedule';

@Component({
  selector: 'tc-hub-ds-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class DSCreateComponent {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  currentStep = 0;

  constructor(
    private scheduleService: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  onSignal(signal) {
    if (signal.action === 'next') {
      this.config = signal.config;
      this.next();
    }

    if (signal.action === 'prev') {
      this.config = signal.config;
      this.prev();
    }

    this.signal.emit(signal);
  }

  redraw() {
    this.signal.emit({ action: 'resize' });
  }

  prev(): void {
    this.currentStep -= 1;
  }

  next(): void {
    this.currentStep += 1;
  }
}
