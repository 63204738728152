<div class="tc-modal-body" #modalBody>
  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="!single"
  >
    <nz-tab
      nzTitle="{{ 'dispo/task.tab_type.general' | translate }}"
      *tcHubHide="'views.dispo.task.general'"
    >
      <ng-template nz-tab>
        <tc-hub-dt-general
          class="modal-container"
          [task]="task"
          [plan]="plan"
          (signal)="onSignal($event)"
        ></tc-hub-dt-general>
      </ng-template>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/task.tab_type.public' | translate }}"
      *tcHubHide="'views.dispo.task.public'"
    >
      <ng-template nz-tab>
        <tc-hub-dt-public
          class="modal-container"
          [task]="task"
          [plan]="plan"
          (signal)="onSignal($event)"
        ></tc-hub-dt-public>
      </ng-template>
    </nz-tab>

    <ng-container *ngIf="!task.schedule_id">
      <nz-tab
        nzTitle="{{ 'dispo/task.tab_type.slots' | translate }}"
        *tcHubHide="'views.dispo.task.slots'"
      >
        <ng-template nz-tab>
          <tc-hub-dt-slots
            class="modal-container"
            [task]="task"
            [plan]="plan"
            (signal)="onSignal($event)"
          ></tc-hub-dt-slots>
        </ng-template>
      </nz-tab>
    </ng-container>

    <ng-container *ngIf="!task.schedule_id">
      <nz-tab
        nzTitle="{{ 'dispo/task.tab_type.move' | translate }}"
        *tcHubHide="'views.dispo.task.move'"
      >
        <ng-template nz-tab>
          <tc-hub-dt-move
            class="modal-container"
            [task]="task"
            [plan]="plan"
            (signal)="onSignal($event)"
          ></tc-hub-dt-move>
        </ng-template>
      </nz-tab>
    </ng-container>

    <ng-container *tcHubHide="'views.dispo.task.invitations'">
      <nz-tab
        *ngFor="let announcement of announcements$ | async"
        nzTitle="{{ 'dispo/task.tab_type.invitations' | translate }} {{
          announcement.title
        }}"
      >
        <ng-template nz-tab>
          <tc-hub-dt-invitations
            class="modal-container"
            [task]="task"
            [announcement]="announcement"
            [plan]="plan"
            (signal)="onSignal($event)"
          ></tc-hub-dt-invitations>
        </ng-template>
      </nz-tab>
    </ng-container>
  </nz-tabset>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'create'">
    <nz-tab
      nzTitle="{{ 'dispo/task.tab_type.create' | translate }}"
      *tcHubHide="'views.dispo.task.create'"
    >
      <tc-hub-dt-create
        class="modal-container"
        [task]="task"
        [plan]="plan"
        (signal)="onSignal($event)"
      ></tc-hub-dt-create>
    </nz-tab>
  </nz-tabset>

  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    *ngIf="section === 'moveScheduled'"
  >
    <nz-tab
      nzTitle="{{ 'dispo/task.tab_type.move' | translate }}"
      *tcHubHide="'views.dispo.task.move_scheduled'"
    >
      <tc-hub-dt-move-scheduled
        class="modal-container"
        [tasks]="task"
        [plan]="plan"
        (signal)="onSignal($event)"
      ></tc-hub-dt-move-scheduled>
    </nz-tab>
  </nz-tabset>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'delete'">
    <nz-tab
      nzTitle="{{ 'dispo/task.tab_type.delete' | translate }}"
      *tcHubHide="'views.dispo.task.delete'"
    >
      <tc-hub-dt-delete
        class="modal-container"
        [task]="task"
        (signal)="onSignal($event)"
      ></tc-hub-dt-delete>
    </nz-tab>
  </nz-tabset>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
