<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <nz-table
      [nzData]="days_config.controls"
      nzFrontPagination="false"
      nzSize="small"
    >
      <thead formGroupName="days_headers">
        <tr>
          <th>
            <label>{{ 'dispo/schedule.attrs.slot' | translate }}</label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="1"
              [nzIndeterminate]="allDaysIndeterminate['1']"
            >
              {{ 'dispo/schedule.days_matrix.d1' | translate }}
            </label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="2"
              [nzIndeterminate]="allDaysIndeterminate['2']"
            >
              {{ 'dispo/schedule.days_matrix.d2' | translate }}
            </label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="3"
              [nzIndeterminate]="allDaysIndeterminate['3']"
            >
              {{ 'dispo/schedule.days_matrix.d3' | translate }}
            </label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="4"
              [nzIndeterminate]="allDaysIndeterminate['4']"
            >
              {{ 'dispo/schedule.days_matrix.d4' | translate }}
            </label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="5"
              [nzIndeterminate]="allDaysIndeterminate['5']"
            >
              {{ 'dispo/schedule.days_matrix.d5' | translate }}
            </label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="6"
              [nzIndeterminate]="allDaysIndeterminate['6']"
            >
              {{ 'dispo/schedule.days_matrix.d6' | translate }}
            </label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="0"
              [nzIndeterminate]="allDaysIndeterminate['0']"
            >
              {{ 'dispo/schedule.days_matrix.d0' | translate }}
            </label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="FA"
              [nzIndeterminate]="allDaysIndeterminate['FA']"
            >
              {{ 'dispo/schedule.days_matrix.dFA' | translate }}
            </label>
          </th>
          <th>
            <label
              nz-checkbox
              formControlName="FW"
              [nzIndeterminate]="allDaysIndeterminate['FW']"
            >
              {{ 'dispo/schedule.days_matrix.dFW' | translate }}
            </label>
          </th>
        </tr>
      </thead>
      <tbody formArrayName="days_config">
        <tr
          class="input-fields"
          *ngFor="let slot of days_config.controls; index as i"
          formGroupName="{{ i }}"
        >
          <td nzAlign="center">
            <label
              [formGroup]="slot"
              nz-checkbox
              formControlName="ALL"
              [nzIndeterminate]="allPositionsIndeterminate[i]"
              >{{ i + 1 }}</label
            >
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="1"></label>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="2"></label>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="3"></label>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="4"></label>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="5"></label>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="6"></label>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="0"></label>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="FA"></label>
          </td>
          <td nzAlign="center">
            <label [formGroup]="slot" nz-checkbox formControlName="FW"></label>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      (click)="prev()"
      [disabled]="form.invalid"
    >
      {{ 'back' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      (click)="next()"
      [disabled]="form.invalid"
    >
      {{ 'next' | translate }}
    </button>
  </div>
</form>
