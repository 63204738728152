import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { TcButtonModule } from './button';
import { TcDateTimePickerModule } from './date-time-picker';
import { TcFormFieldModule } from './form-field';
import { TcInputModule } from './input';
import { TcToggleButtonModule } from './toggle-button';
import { TcModalModule } from './modal';
import { TcMapModule } from './map';
import { TcPanelComponent } from './panel/panel.component';
import { TcFormWrapperComponent } from './form-wrapper';
import {
  TcCodeEditorComponent,
  TcCodeEditorModalComponent,
} from './code-editor';

@NgModule({
  imports: [
    SharedModule,
    TcButtonModule,
    TcCodeEditorComponent,
    TcCodeEditorModalComponent,
    TcDateTimePickerModule,
    TcFormFieldModule,
    TcFormWrapperComponent,
    TcInputModule,
    TcMapModule,
    TcModalModule,
    TcPanelComponent,
    TcToggleButtonModule,
  ],
  exports: [
    TcButtonModule,
    TcCodeEditorComponent,
    TcCodeEditorModalComponent,
    TcDateTimePickerModule,
    TcFormFieldModule,
    TcFormWrapperComponent,
    TcInputModule,
    TcMapModule,
    TcFormWrapperComponent,
    TcModalModule,
    TcPanelComponent,
    TcToggleButtonModule,
  ],
})
export class TcComponentsModule {}
