import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { first } from 'rxjs';

import { ModalConfig, ModalRef } from '@timecount/ui';
import { parseDate, stringifyDate } from '@timecount/utils';

import { ServiceEntry } from '../service-entry.model';
import { TcProjectFormsBaseDirective } from '../../project-forms-base.directive';
import { AggregationType } from '../../aggregations/aggregation_type.model';
import { ServiceEntryCollection } from '../service-entry.collection';
import { MaterialCollection } from '../../../core/collections/materials.collection';
import { Material } from '../../../core/types/material.model';
import { VatCollection } from '../../../core/collections/vat.collection';
import { ProjectCollection } from '../../project.collection';
import { ResourceTemplateCollection } from '../../../core/collections/resource_template';

@Component({
  selector: 'tc-hub-service-entry-form',
  templateUrl: './service-entry-form.component.html',
  styleUrls: ['./service-entry-form.component.scss'],
})
export class TcServiceEntryFormComponent
  extends TcProjectFormsBaseDirective<ServiceEntry>
  implements OnInit, OnDestroy
{
  @Input() tcAggregationType?: AggregationType;
  @Input() tcAggregationId?: number;
  @Input() tcProjectDate?: string;

  isBatch = false;
  targets = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _serviceEntryCollection: ServiceEntryCollection,
    private _materialCollection: MaterialCollection,
    private _projectCollection: ProjectCollection,
    private _vatCollection: VatCollection,
    private _resourceTemplateCollection: ResourceTemplateCollection,
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super(config, modalRef);
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.isModal) {
      this._resourceTemplateCollection
        .default<ServiceEntry>('service_entry')
        .pipe(first())
        .subscribe((resourceTemplate) => {
          this._buildForm(resourceTemplate?.template);
        });
    } else {
      this._buildForm({});
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // Public Methods
  // ---------------------------------------------------------------------------

  onSave() {
    if (this.isBatch) {
      this.targets.forEach((target) => {
        const { id, project_id, aggregation_id } = target;

        this._serviceEntryCollection
          .forProject(project_id, aggregation_id)
          .update(id, {
            ...target,
            ...this._getPayload(true),
          });
      });
    } else {
      const { projectId, aggregationId } = this._getIds();

      if (this.tcInitialValue?.id) {
        this._serviceEntryCollection
          .forProject(projectId, aggregationId)
          .update(this.tcInitialValue.id, this._getPayload());
      } else {
        this._serviceEntryCollection
          .forProject(projectId, aggregationId)
          .create(this._getPayload());
      }
    }

    this.modalRef?.close();
  }

  // ---------------
  // Private Methods
  // ---------------

  private _getIds() {
    const projectId = this.tcInitialValue?.project_id || this.tcProjectId;
    const aggregationId =
      this.tcInitialValue?.aggregation_id || this.tcAggregationId;

    return { projectId, aggregationId };
  }

  private _getPayload(isBatch = false): ServiceEntry {
    const {
      starts_at,
      unit_price,
      serviceEntryGeneral,
      serviceEntryHandleGeneral,
      ...formValues
    } = this.formGroup.value;

    const date = stringifyDate(starts_at);

    return {
      ...formValues,
      project_id: this.tcProjectId,
      aggregation_id: this.tcAggregationId,
      ...((!isBatch || starts_at) && { starts_at: date, ends_at: date }),
      ...((!isBatch || unit_price !== undefined) && {
        price: Number((unit_price * 100).toFixed(0)),
      }),
      ...(!isBatch && {
        store: _.merge(
          this.tcInitialValue?.store,
          serviceEntryGeneral,
          serviceEntryHandleGeneral,
        ),
      }),
    };
  }

  private async _buildForm(resourceTemplate: Partial<ServiceEntry>) {
    const disabled = this.isBatch;

    const mergedValues = _.merge({}, resourceTemplate, this.tcInitialValue);

    const {
      count,
      title,
      sku_id,
      venue_id,
      description,
      price,
      store,
      unit,
      starts_at,
    } = mergedValues ?? {};

    let { vat_id } = mergedValues ?? {};

    if (!this.isBatch && !vat_id) {
      const vat = await this._vatCollection.default().toPromise();

      vat_id = vat.id;
    }

    this.formGroup = this._formBuilder.group({
      // Simple
      count: [{ value: count ?? 1, disabled }, Validators.required],
      title: [{ value: title, disabled }, Validators.required],
      starts_at: [
        { value: starts_at ?? parseDate(this.tcProjectDate), disabled },
        Validators.required,
      ],
      vat_id: [{ value: vat_id, disabled }, Validators.required],
      unit_price: [{ value: price / 100, disabled }, Validators.required],
      sku_id: [{ value: sku_id, disabled }],
      serviceEntryGeneral: [{ value: store, disabled }],
      serviceEntryHandleGeneral: [{ value: store, disabled }],
      // Extended
      unit: [{ value: unit, disabled }],
      venue_id: [{ value: venue_id ?? this.tcVenueId, disabled }],
      description: [{ value: description, disabled }],
    });

    this.formGroup.get('sku_id').valueChanges.subscribe((id) => {
      this._materialCollection.find(id).subscribe((material: Material) => {
        this.formGroup.patchValue({
          ...material,
          unit_price: material.unit_price / 100,
          serviceEntryGeneral: material.store,
          serviceEntryHandleGeneral: material.store,
        });
      });
    });
  }
}
