import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { debugTap } from '../../../core/helpers';
import { SetCollection } from '../../../core/sets-collection';
import { EmployeeCollection } from '../../../employees/employee.collection';
import { SubtotalMonthCollection } from '../../../timebalances/subtotal.collection';
import { MonthCollection } from '../../../timebalances/month_collection';

@Component({
  selector: 'tc-hub-tbdoc-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class TBDOCStep2Component implements OnInit {
  @Input() document: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  public resources$: Observable<any[]>;
  public resourcesDS$: Observable<any[]>;

  public selectedResources = [];
  public valid = false;
  public loading = false;

  private employees$: Observable<any[]>;
  private subtotalCollection: SetCollection;

  constructor(
    private employeeSets: EmployeeCollection,
    private subtotalMonthSets: SubtotalMonthCollection,
    private monthSets: MonthCollection,
  ) {}

  ngOnInit(): void {
    this.employees$ = this.employeeSets.all();
    this.subtotalCollection = this.subtotalMonthSets.forMonth(
      this.document.date,
    );

    this.resources$ = combineLatest(
      this.employees$,
      this.subtotalCollection.all(),
    ).pipe(
      map(([employees, subtotals]) => {
        return subtotals
          .map((subtotal) => {
            return employees.find((e) => e.id === subtotal.employee_id);
          })
          .filter((e) => e);
      }),
      shareReplay(1),
    );

    this.resourcesDS$ = combineLatest(
      this.employees$,
      this.subtotalCollection.all(),
    ).pipe(
      map(([employees, subtotals]) => {
        return subtotals.map((subtotal) => {
          const employee =
            employees.find((e) => e.id === subtotal.employee_id) || {};
          return Object.assign({}, employee, { subtotal: subtotal });
        });
      }),
      debugTap('EmployeeDS'),
      shareReplay(1),
    );

    this.signal.emit({ action: 'resize' });
  }

  prev() {
    this.signal.emit({ document: this.document, action: 'prev' });
  }

  create() {
    const resources = this.selectedResources.map((e) => {
      return { type: 'Employee', id: e.id };
    });
    const document = Object.assign({}, this.document, { resources: resources });

    this.loading = true;

    this.monthSets.action('attachment', document.date, document).subscribe({
      next: () => {
        this.signal.emit({ action: 'close', status: 'created' });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  onSelect(resources) {
    this.selectedResources = resources;
    this.valid = this.selectedResources.length > 0;
  }
}
