import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Directive()
export class BaseSelectComponent implements OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() bindLabel: string;
  @Input() bindValue: string = null;
  @Input() searchable = false;
  @Input() loading = false;

  @Input() tcCanDisable = false;
  @Input() multiple = false;

  public get control(): AbstractControl {
    return this.formGroup.get(this.controlName);
  }

  public get disabled(): boolean {
    return this.control?.disabled ?? false;
  }

  public set disabled(shouldDisabled: boolean) {
    shouldDisabled ? this.control?.disable() : this.control?.enable();
  }

  ngOnChanges({ multiple }: SimpleChanges): void {
    //HACK: The ng-select is clearing the values of the select when multiple is changed
    //this is necessary for keeping the value when having a dynamic multiple
    if (multiple) {
      const control = this.formGroup.get(this.controlName);
      const { value } = control;
      setTimeout(() => {
        if (value) {
          if (
            multiple.previousValue === true &&
            multiple.currentValue === false
          ) {
            control.setValue(value[0]);
          } else if (
            multiple.previousValue === false &&
            multiple.currentValue === true
          ) {
            control.setValue([value]);
          }
        }
      }, 0);
    }
  }
}
