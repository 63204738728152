import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { TcFormGroupIntervalModule } from '../../form-groups';
import { TcFormFieldModule, TcPanelComponent } from '../../components';
import { TcIntervalModule } from '../interval/interval.module';

import { TcFielsetIntervalListIonicComponent } from './interval-list-ionic/interval-list-ionic.component';
import { TcFielsetIntervalListWebComponent } from './interval-list-web/interval-list-web.component';

@NgModule({
  declarations: [
    TcFielsetIntervalListIonicComponent,
    TcFielsetIntervalListWebComponent,
  ],
  exports: [
    TcFielsetIntervalListIonicComponent,
    TcFielsetIntervalListWebComponent,
  ],
  imports: [
    SharedModule,
    TcFormGroupIntervalModule,
    TcFormFieldModule,
    TcPanelComponent,
    TcIntervalModule,
  ],
})
export class TcIntervalListModule {}
