import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { IntervalSummary, TcIntervalList } from '@timecount/core';
import {
  TcFieldSetIntervalSetService,
  TcTimesSummaryService,
} from '@timecount/ui';

import { FinancesEntriesService } from '../finances-entries.service';
import { FinancesEntriesItemComponent } from '../item.component';

@Component({
  selector: 'tc-hub-finances-entries-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class FinancesEntriesTaskComponent
  extends FinancesEntriesItemComponent
  implements OnChanges
{
  @Input() item: any;

  taskSummary: IntervalSummary;
  parsedTaskTimes: Interval[];

  constructor(
    protected service: FinancesEntriesService,
    private intervalSetService: TcFieldSetIntervalSetService,
    private summaryService: TcTimesSummaryService,
  ) {
    super(service);
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    const targetButton = !!$event
      .composedPath()
      .find((item) => item.nodeName?.toLowerCase() === 'button');

    if ($event.type === 'mouseup' && $event.button === 2) {
      return;
    }
    if (targetButton) {
      return;
    }

    const nextState = $event.type === 'contextmenu' ? true : !this.item.checked;
    this.selectItem(this.item.type, this.item.id, nextState, $event);
  }

  ngOnChanges({ item }: SimpleChanges): void {
    if (item) {
      this.parsedTaskTimes = TcIntervalList.parseFromApi(this.item);

      const parsedTimes = this.intervalSetService.parseFromApi(this.item);
      this.taskSummary = this.summaryService.getSummary(parsedTimes);
    }
  }
}
