<ion-grid>
  <ion-row>
    <ion-col size="7" class="summary-item">
      <ion-label class="summary-item__label">
        {{ 'GENERAL.TIME.TIMEFRAME' | translate }}
      </ion-label>
      <ion-chip class="summary-item__value" color="dark">
        {{ intervalSummary.workInterval | interval : 'shortTime' }}
      </ion-chip>
    </ion-col>
    <ion-col size="5" class="summary-item">
      <ion-label class="summary-item__label">
        {{ 'GENERAL.SUMMARY.TOTAL_DURATION' | translate }}
      </ion-label>
      <ion-chip class="summary-item__value" color="dark">
        {{ intervalSummary.totalDuration | duration }}
      </ion-chip>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="7" class="summary-item">
      <ion-label class="summary-item__label">
        <strong>{{ intervalSummary.workPeriods }}</strong>
        {{ 'GENERAL.SUMMARY.WORK_PERIOD' | translate }}
      </ion-label>
      <ion-chip class="summary-item__value" color="dark">
        {{ intervalSummary.workDuration | duration }}
      </ion-chip>
    </ion-col>
    <ion-col size="5" class="summary-item">
      <ion-label class="summary-item__label">
        <strong>{{ intervalSummary.breakPeriods }}</strong>
        {{ 'GENERAL.SUMMARY.BREAKS' | translate }}
      </ion-label>
      <ion-chip class="summary-item__value" color="dark">
        {{ intervalSummary.breakDuration | duration }}
      </ion-chip>
    </ion-col>
  </ion-row>
</ion-grid>
