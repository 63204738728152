import { Pipe, PipeTransform } from '@angular/core';

import { FormDataSchema, FrontEndConfig, JsonSchema } from './';

@Pipe({
  name: 'frontEndConfig',
  standalone: true,
})
export class FrontEndConfigPipe implements PipeTransform {
  transform(config: FrontEndConfig<'form' | 'schema'>, forceFlat = false) {
    const { type, data } = config ?? {};

    let schema: JsonSchema;

    if (type === 'form') {
      schema = JsonSchema.fromFormData(data as FormDataSchema);
    } else {
      schema = data as JsonSchema;
    }

    return forceFlat ? <JsonSchema>{ ...schema, widget: 'flat' } : schema;
  }
}
