import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
  TranslateService,
} from '@ngx-translate/core';

import { defaultLanguage, Languages } from './languages';
import { TcMissingTranslationHandler } from './missing-translation-handler';
import { tcLoaderFactory } from './translate-loader';

const initialLanguage =
  document.querySelector('html').lang || defaultLanguage.code;

export const I18N_CONFIG: TranslateModuleConfig = {
  defaultLanguage: initialLanguage,
  loader: {
    provide: TranslateLoader,
    useFactory: tcLoaderFactory,
    deps: [HttpClient],
  },
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: TcMissingTranslationHandler,
  },
};

@NgModule({
  imports: [CommonModule, TranslateModule.forRoot(I18N_CONFIG)],
  exports: [TranslateModule],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: initialLanguage,
    },
  ],
})
export class TcLocaleModule {
  constructor(
    private translateService: TranslateService,
    @Optional() @SkipSelf() parentModule?: TcLocaleModule,
  ) {
    if (parentModule) {
      throw new Error(`
        TcLocaleModule is already loaded.
        Import it only once (e.g. in the AppModule or CoreModule only).
      `);
    }

    registerLocaleData(localeDe, Languages.DE_DE.isoCode, localeDeExtra);
    registerLocaleData(localeDe, Languages.DE_DE.code, localeDeExtra);
    registerLocaleData(localeEn, Languages.EN_US.isoCode, localeEnExtra);
    registerLocaleData(localeEn, Languages.EN_US.code, localeEnExtra);

    this.translateService.addLangs(['en', 'de']);
    this.translateService.use(initialLanguage);
  }
}
