import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { TcApiResponse } from '@timecount/core';
import { ModalService } from '@timecount/ui';

import { CalculatedAttributes, TcBuilder } from './builder-config';
import { TcFormulasType } from './formula-type';
import { TcFormulasComponent } from './formulas.component';

@Injectable({
  providedIn: 'root',
})
export class TcFormulasService {
  private _endpoint = '/api/administration/builders';
  private _translations: Record<string, string> = {};

  constructor(
    private _translateService: TranslateService,
    private _httpClient: HttpClient,
    private _modalService: ModalService,
  ) {
    this._translateService.get(['loading']).subscribe((translations) => {
      this._translations = translations;
    });
  }

  openFormulas(type: TcFormulasType, id: number) {
    this._modalService.open(TcFormulasComponent, {
      data: { type, id },
      modalTitle: `${this._translations['loading']}...`,
      minWidth: 1440,
      minHeight: 600,
      noPadding: true,
      closeOnEscape: false,
      disableEscKey: true,
    });
  }

  getFormulas(type: TcFormulasType, id: number) {
    return this._httpClient
      .get<TcApiResponse<TcBuilder>>(`${this._endpoint}/${type}/${id}`)
      .pipe(map(({ data }) => data));
  }

  testFormulas(
    type: TcFormulasType,
    id: number,
    calc_attrs: Partial<CalculatedAttributes>[],
  ) {
    return this._httpClient
      .put<TcApiResponse<TcBuilder>>(`${this._endpoint}/${type}/${id}`, {
        calc_attrs,
      })
      .pipe(map(({ data }) => data));
  }
}
