import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';

import { dateToTzUnix } from '@timecount/utils';

import { debugTap, integerToDate, sumObjectKeys } from '../../core/helpers';
import { DispoFocusService } from '../dispo-focus.service';
import { JobCollection } from '../../jobs/job.collection';
import { VenueCollection } from '../../venues/venue.collection';
import { ProjectCollection } from '../../projects/project.collection';
import { TimesheetCollection } from '../../timesheets';
import { DispoAssignmentCollection } from '../collections/assignment';
import { DispoTaskCollection, Task } from '../collections/task';
import { DispoAnnouncementCollection } from '../collections/announcement';
import { DispoInvitationCollection } from '../collections/invitation';
import { DispoPlanCollection } from '../collections/plan';
import { DispoResourceCollection } from '../collections/resource';
import { DispoScheduleCollection } from '../collections/schedule';
import { DispoCombinedTaskCollection } from '../collections/combined_task';
import { BillingWorkEntryCollection } from '../../projects/aggregations/work-entries/billing-work-entry.collection';
import { BillingServiceEntryCollection } from '../../projects/service-entry/billing-service-entry.collection';
import { BillingAggregationCollection } from '../../projects/aggregations/billing-aggregation.collection';
import { MaterialCollection } from '../../core/collections/materials.collection';
import { WorkEntry } from '../../projects/aggregations/work-entries/work_entry.model';

import { TaskDS } from './task-ds.model';
import { ScheduleDS } from './schedule-ds.model';
import { PlanDS } from './plan-ds.model';
import { AnnouncementDS } from './announcement-ds.model';
import { AssignmentDS } from './assignment-ds.model';
import { TimesheetDS } from './timesheet-ds.model';
import { WorkEntryDS } from './work-entry-ds.model';
import { ServiceEntryDS } from './service-entry-ds.model';
import { TasksStat } from './tasks-stat.model';

const reduceTasksStats = (tasksStats: TasksStat[]) => {
  return sumObjectKeys(
    tasksStats,
    [
      'slots',
      'slots_missing',
      'slots_filled',
      'slots_reserve',
      'announced',
      'announced_rejected',
      'announced_accepted',
      'announced_cancelled',
      'requested',
      'requested_confirmed',
      'times_tracked',
      'hours',
      'total_hours',
    ],
    new TasksStat(),
  );
};

@Injectable({
  providedIn: 'root',
})
export class DispoRangeDataStructureFactory {
  private services: { [key: string]: DispoRangeDataStructure } = {};

  public schedules = [];
  public plans = [];
  public tasks = [];
  public assignments = [];

  constructor(
    private focusService: DispoFocusService,
    private plansCollection: DispoPlanCollection,
    private schedulesCollection: DispoScheduleCollection,
    private tasksCollection: DispoTaskCollection,
    private assignmentsCollection: DispoAssignmentCollection,
    private announcementsCollection: DispoAnnouncementCollection,
    private invitationsCollection: DispoInvitationCollection,
    private resourcesCollection: DispoResourceCollection,
    private combinedTaskCollection: DispoCombinedTaskCollection,
    private timesheetCollection: TimesheetCollection,
    private billingWorkEntryCollection: BillingWorkEntryCollection,
    private billingServiceEntryCollection: BillingServiceEntryCollection,
    private billingAggregationCollection: BillingAggregationCollection,
    private projectCollection: ProjectCollection,
    private jobCollection: JobCollection,
    private materialCollection: MaterialCollection,
    private venueCollection: VenueCollection,
  ) {}

  forDate(date: Date) {
    const begin = new Date(date);
    begin.setHours(0, 0, 0, 0);

    const end = new Date(date);
    end.setHours(23, 59, 59);

    return this.get(dateToTzUnix(begin), dateToTzUnix(end));
  }

  all(): DispoRangeDataStructure {
    return this.get();
  }

  protected get(sliceBegin?, sliceEnd?): DispoRangeDataStructure {
    const lookup = `${sliceBegin}|${sliceEnd}`;

    if (!this.services[lookup]) {
      this.services[lookup] = new DispoRangeDataStructure(
        sliceBegin,
        sliceEnd,
        this.focusService,
        this.plansCollection,
        this.schedulesCollection,
        this.tasksCollection,
        this.assignmentsCollection,
        this.announcementsCollection,
        this.invitationsCollection,
        this.resourcesCollection,
        this.combinedTaskCollection,
        this.timesheetCollection,
        this.projectCollection,
        this.jobCollection,
        this.materialCollection,
        this.venueCollection,
        this.billingWorkEntryCollection,
        this.billingServiceEntryCollection,
        this.billingAggregationCollection,
      );
    }

    return this.services[lookup];
  }
}

export class DispoRangeDataStructure {
  private total$;
  private plans$;
  private schedules$;
  private tasks$;
  private announcements$;
  private assignments$;
  private timesheets$;
  private workSheets$;
  private aggregationEntries$;

  private lookups$ = {};

  constructor(
    private sliceBegin: number,
    private sliceEnd: number,
    private focusService: DispoFocusService,
    private plansService: DispoPlanCollection,
    private schedulesService: DispoScheduleCollection,
    private tasksService: DispoTaskCollection,
    private assignmentsService: DispoAssignmentCollection,
    private announcementService: DispoAnnouncementCollection,
    private invitationService: DispoInvitationCollection,
    private resourceService: DispoResourceCollection,
    private combinedTaskCollection: DispoCombinedTaskCollection,
    private timesheetCollection: TimesheetCollection,
    private projectCollection: ProjectCollection,
    private jobCollection: JobCollection,
    private materialCollection: MaterialCollection,
    private venueCollection: VenueCollection,
    private billingWorkEntryCollection: BillingWorkEntryCollection,
    private billingServiceEntryCollection: BillingServiceEntryCollection,
    private billingAggregationCollection: BillingAggregationCollection,
  ) {}

  // CAVEAT: tasks are filtered by overlapping with dates not actual ranges
  tasks(): Observable<TaskDS[]> {
    let taskModels$: Observable<Task[]>;
    if (!this.sliceBegin) {
      taskModels$ = this.combinedTaskCollection.all();
    } else {
      const date = integerToDate(this.sliceBegin);
      taskModels$ = this.combinedTaskCollection.forDate(date);
    }

    if (!this.tasks$) {
      this.tasks$ = combineLatest(
        taskModels$,
        this.assignmentsService.forRange(this.sliceBegin, this.sliceEnd),
        this.invitationService.forRange(this.sliceBegin, this.sliceEnd),
        this.announcementService.forRange(this.sliceBegin, this.sliceEnd),
        this.lookupsFor('project'),
        this.lookupsFor('plan'),
        this.lookupsFor('schedule'),
        this.lookupsFor('venue'),
        this.lookupsFor('job'),
        this.sliceBegin
          ? of([this.sliceBegin, this.sliceEnd])
          : this.focusService.range(),
      ).pipe(
        debounceTime(100),
        map(
          ([
            tasks,
            assignments,
            invitations,
            announcements,
            projectsAsHash,
            plansAsHash,
            schedulesAsHash,
            venuesAsHash,
            jobsAsHash,
            [begin, end],
          ]) => {
            const tasksAsHash = {};

            tasks.forEach((task) => {
              const plan = plansAsHash[task.plan_id];

              tasksAsHash[task.id] = {
                ...task,
                plan,
                project: projectsAsHash[plan?.project_id],
                schedule: task.schedule_id
                  ? schedulesAsHash[task.schedule_id]
                  : undefined,
                job: jobsAsHash[task.job_id],
                venue: task.venue_id ? venuesAsHash[task.venue_id] : undefined,
                assignments: [],
                announcements: [],
                invitations: [],
              };
            });

            assignments.forEach((assignment) => {
              const task = tasksAsHash[assignment.task_id];

              if (!task) {
                return;
              }

              task.assignments.push(assignment);
            });

            announcements.forEach((announcement) => {
              const announcementInvitations = invitations.filter(
                (i) => i.announcement_id === announcement.id,
              );

              announcement.task_ids.forEach((task_id) => {
                const task = tasksAsHash[task_id];

                if (!task) {
                  return;
                }

                task.announcements.push(announcement);
                task.invitations.push(...announcementInvitations);
              });
            });

            return Object.values(tasksAsHash).map((task: any) => {
              task.public = task.state === 'open';

              const slot_size = task.slots ? task.slots.length : task.size;

              // TODO: calculate assignment count which can be requested (user with app)
              const requested =
                task.state == 'open'
                  ? task.assignments.filter((a) => a.requested_at).length
                  : 0;
              const requested_confirmed =
                task.state == 'open'
                  ? task.assignments.filter((a) => {
                      return (
                        (a.read_at ? a.read_at.getTime() : 0) >=
                        (a.requested_at ? a.requested_at.getTime() : 0)
                      );
                    }).length
                  : 0;

              const length = task.net_duration * 60;

              task.stats = Object.assign(new TasksStat(), {
                slots: slot_size,
                slots_missing:
                  slot_size -
                  task.assignments.filter((a) => a.position < task.size).length,
                slots_filled: task.assignments.filter(
                  (a) => a.position < task.size,
                ).length,
                slots_reserve: task.assignments.filter(
                  (a) => a.position >= task.size,
                ).length,

                announced: task.announcements.reduce(
                  (sum, a) => ((a.sizes || {})[task.date] || 0) + sum,
                  0,
                ),
                announced_rejected: task.invitations.filter(
                  (i) => (i.states || {})[task.date] === 'refused',
                ).length,
                announced_accepted: task.invitations.filter(
                  (i) => (i.states || {})[task.date] === 'accepted',
                ).length,
                announced_cancelled: task.invitations.filter(
                  (i) => (i.states || {})[task.date] === 'cancelled',
                ).length,

                requested: requested,
                requested_confirmed: requested_confirmed,

                times_tracked: task.assignments.filter((a) => a.tracked_time)
                  .length,

                hours: length / 60,
                total_hours: (length * slot_size) / 60,
              });

              return task;
            });
          },
        ),
        debugTap(
          `TaskDS for ${integerToDate(this.sliceBegin)} -> ${integerToDate(
            this.sliceEnd,
          )}`,
        ),
        shareReplay(1),
      );
    }

    return this.tasks$;
  }

  task(task): Observable<TaskDS> {
    return this.tasks().pipe(
      map((tasks) => tasks.find((t) => t.id === task.id)),
    );
  }

  // CAVEAT: returns stats for scheduled and unscheduled tasks
  plans(): Observable<PlanDS[]> {
    if (!this.plans$) {
      this.plans$ = combineLatest(
        this.plansService.forRange(this.sliceBegin, this.sliceEnd),
        this.tasks(),
        this.lookupsFor('project'),
      ).pipe(
        debounceTime(100),
        map(([plans, tasks, projectsAsHash]) => {
          return plans.map((plan) => {
            plan = Object.assign({}, plan);
            plan.tasks = tasks.filter((t) => t.plan_id === plan.id);
            plan.project = projectsAsHash[plan.project_id];
            plan.stats = reduceTasksStats(plan.tasks.map((t) => t.stats));

            return plan;
          });
        }),
        debugTap(
          `PlanDS for ${integerToDate(this.sliceBegin)} -> ${integerToDate(
            this.sliceEnd,
          )}`,
        ),
        shareReplay(1),
      );
    }

    return this.plans$;
  }

  plan(plan): Observable<PlanDS> {
    return this.plans().pipe(
      map((plans) => plans.find((p) => p.id === plan.id)),
    );
  }

  schedules(): Observable<ScheduleDS[]> {
    if (!this.schedules$) {
      this.schedules$ = combineLatest(
        this.schedulesService.forRange(this.sliceBegin, this.sliceEnd),
        this.tasks(),
        this.lookupsFor('project'),
        this.lookupsFor('plan'),
        this.lookupsFor('venue'),
        this.lookupsFor('job'),
      ).pipe(
        debounceTime(100),
        map(
          ([
            schedules,
            tasks,
            projectsAsHash,
            plansAsHash,
            venuesAsHash,
            jobsAsHash,
          ]) => {
            return schedules.map((schedule) => {
              schedule = Object.assign({}, schedule);
              schedule.tasks = tasks.filter(
                (t) => t.schedule_id === schedule.id,
              );
              schedule.plan = plansAsHash[schedule.plan_id];
              schedule.project = projectsAsHash[schedule.plan?.project_id];

              schedule.job = jobsAsHash[schedule.job_id];
              schedule.venue = schedule.venue_id
                ? venuesAsHash[schedule.venue_id]
                : undefined;

              schedule.stats = reduceTasksStats(
                schedule.tasks.map((t) => t.stats),
              );

              return schedule;
            });
          },
        ),
        debugTap(
          `ScheduleDS for ${integerToDate(this.sliceBegin)} -> ${integerToDate(
            this.sliceEnd,
          )}`,
        ),
        shareReplay(1),
      );
    }

    return this.schedules$;
  }

  schedule(schedule): Observable<ScheduleDS> {
    return this.schedules().pipe(
      map((schedules) => schedules.find((p) => p.id === schedule.id)),
    );
  }

  announcements(): Observable<AnnouncementDS[]> {
    if (!this.announcements$) {
      this.announcements$ = combineLatest(
        this.announcementService.forRange(this.sliceBegin, this.sliceEnd),
        this.invitationService.forRange(this.sliceBegin, this.sliceEnd),
        this.tasks(),
        this.lookupsFor('project'),
        this.lookupsFor('plan'),
        this.lookupsFor('schedule'),
        this.lookupsFor('venue'),
      ).pipe(
        debounceTime(100),
        map(
          ([
            announcements,
            invitations,
            tasks,
            projectsAsHash,
            plansAsHash,
            schedulesAsHash,
            venuesAsHash,
          ]) => {
            const tasksAsHash = {};
            tasks.forEach((t) => (tasksAsHash[t.id] = t));

            return announcements.map((announcement) => {
              announcement = Object.assign({}, announcement);
              announcement.tasks = announcement.task_ids
                .map((id) => tasksAsHash[id])
                .filter((t) => t);
              announcement.invitations = invitations.filter(
                (a) => a.announcement_id === announcement.id,
              );
              announcement.plan = plansAsHash[announcement.plan_id];
              announcement.schedule = announcement.schedule_id
                ? schedulesAsHash[announcement.schedule_id]
                : undefined;
              announcement.venue = announcement.venue_id
                ? venuesAsHash[announcement.venue_id]
                : undefined;
              announcement.project =
                projectsAsHash[announcement.plan?.project_id];

              announcement.public = announcement.state === 'open';
              announcement.stats = reduceTasksStats(
                announcement.tasks.map((t) => t.stats),
              );

              return announcement;
            });
          },
        ),
        debugTap(
          `AnnouncementDS for ${integerToDate(
            this.sliceBegin,
          )} -> ${integerToDate(this.sliceEnd)}`,
        ),
        shareReplay(1),
      );
    }

    return this.announcements$;
  }

  announcement(announcement): Observable<AnnouncementDS> {
    return this.announcements().pipe(
      map((announcements) =>
        announcements.find((p) => p.id === announcement.id),
      ),
    );
  }

  assignments(): Observable<AssignmentDS[]> {
    if (!this.assignments$) {
      this.assignments$ = this.workSheets().pipe(
        map((items) => {
          return items.filter((i) => i.type === 'DispoAssignment');
        }),
        shareReplay(1),
      );
    }

    return this.assignments$;
  }

  assignment(
    assignment,
    constructDynamically = false,
  ): Observable<AssignmentDS> {
    if (constructDynamically) {
      return combineLatest(
        this.timesheetCollection.forRange(this.sliceBegin, this.sliceEnd),
        this.lookupsFor('project'),
        this.lookupsFor('plan'),
        this.lookupsFor('schedule'),
        this.lookupsFor('task'),
        this.lookupsFor('resource'),
        this.lookupsFor('venue'),
        this.lookupsFor('job'),
        this.sliceBegin
          ? of([this.sliceBegin, this.sliceEnd])
          : this.focusService.range(),
      ).pipe(
        debounceTime(100),
        map(
          ([
            timesheets,
            projectsAsHash,
            plansAsHash,
            schedulesAsHash,
            tasksAsHash,
            resourcesAsHash,
            venuesAsHash,
            jobsAsHash,
            [begin, end],
          ]) => {
            const constructedAssignment = this.decorateAssignment(
              assignment,
              projectsAsHash,
              plansAsHash,
              schedulesAsHash,
              tasksAsHash,
              resourcesAsHash,
              venuesAsHash,
              jobsAsHash,
            );
            constructedAssignment.timesheet = timesheets.find(
              (t) =>
                t.source_type === 'TcDispo::Assignment' &&
                t.source_id === assignment.id,
            );

            const mergedData = Object.assign(constructedAssignment, {
              task: Object.assign(
                {},
                constructedAssignment.task,
                assignment.task || {},
              ),
              schedule: Object.assign(
                {},
                constructedAssignment.schedule || {},
                assignment.schedule || {},
              ),
              slot: Object.assign(
                {},
                constructedAssignment.slot || {},
                assignment.slot || {},
              ),
            });

            return mergedData;
          },
        ),
      );
    } else {
      return this.assignments().pipe(
        map((items) => {
          return items.find((i) => i.id === assignment.id);
        }),
      );
    }
  }

  timesheets(): Observable<TimesheetDS[]> {
    if (!this.timesheets$) {
      this.timesheets$ = combineLatest(
        this.timesheetCollection.forRange(this.sliceBegin, this.sliceEnd),
        this.lookupsFor('project'),
        this.lookupsFor('resource'),
        this.lookupsFor('venue'),
        this.lookupsFor('job'),
        this.sliceBegin
          ? of([this.sliceBegin, this.sliceEnd])
          : this.focusService.range(),
      ).pipe(
        debounceTime(100),
        map(
          ([
            timesheets,
            projectsAsHash,
            resourcesAsHash,
            venuesAsHash,
            jobsAsHash,
            [begin, end],
          ]) => {
            return timesheets.map((timesheet) => {
              return this.decorateTimesheet(
                timesheet,
                projectsAsHash,
                resourcesAsHash,
                venuesAsHash,
                jobsAsHash,
              );
            });
          },
        ),
        shareReplay(1),
      );
    }

    return this.timesheets$;
  }

  timesheet(timesheet, constructDynamically = false): Observable<TimesheetDS> {
    if (constructDynamically) {
      return combineLatest(
        this.lookupsFor('project'),
        this.lookupsFor('resource'),
        this.lookupsFor('venue'),
        this.lookupsFor('job'),
        this.sliceBegin
          ? of([this.sliceBegin, this.sliceEnd])
          : this.focusService.range(),
      ).pipe(
        debounceTime(100),
        map(
          ([
            projectsAsHash,
            resourcesAsHash,
            venuesAsHash,
            jobsAsHash,
            [begin, end],
          ]) => {
            const constructedTimesheet = this.decorateTimesheet(
              timesheet,
              projectsAsHash,
              resourcesAsHash,
              venuesAsHash,
              jobsAsHash,
            );

            const mergedData = Object.assign(constructedTimesheet, {
              // if partial sub data should be kept merge here (project/resource etc.)
            });

            return mergedData;
          },
        ),
      );
    } else {
      return this.timesheets().pipe(
        map((items) => {
          return items.find((i) => i.id === timesheet.id);
        }),
      );
    }
  }

  workSheets(): Observable<(TimesheetDS | AssignmentDS)[]> {
    if (!this.workSheets$) {
      this.workSheets$ = combineLatest(
        this.assignmentsService.forRange(this.sliceBegin, this.sliceEnd),
        this.timesheetCollection.forRange(this.sliceBegin, this.sliceEnd),
        this.lookupsFor('project'),
        this.lookupsFor('plan'),
        this.lookupsFor('schedule'),
        this.lookupsFor('task'),
        this.lookupsFor('resource'),
        this.lookupsFor('venue'),
        this.lookupsFor('job'),
        this.sliceBegin
          ? of([this.sliceBegin, this.sliceEnd])
          : this.focusService.range(),
      ).pipe(
        debounceTime(100),
        map(
          ([
            assignments,
            timesheets,
            projectsAsHash,
            plansAsHash,
            schedulesAsHash,
            tasksAsHash,
            resourcesAsHash,
            venuesAsHash,
            jobsAsHash,
            [begin, end],
          ]) => {
            const assignmentsAsHash = {};
            const orphanedTimesheets = [];

            assignments.forEach((assignment) => {
              assignment = this.decorateAssignment(
                assignment,
                projectsAsHash,
                plansAsHash,
                schedulesAsHash,
                tasksAsHash,
                resourcesAsHash,
                venuesAsHash,
                jobsAsHash,
              );

              assignmentsAsHash[assignment.id] = assignment;
            });

            timesheets.forEach((timesheet) => {
              if (
                timesheet.source_type === 'TcDispo::Assignment' &&
                assignmentsAsHash[timesheet.source_id]
              ) {
                assignmentsAsHash[timesheet.source_id].timesheet = timesheet;
              } else {
                timesheet = this.decorateTimesheet(
                  timesheet,
                  projectsAsHash,
                  resourcesAsHash,
                  venuesAsHash,
                  jobsAsHash,
                );

                orphanedTimesheets.push(timesheet);
              }
            });

            return Object.values(assignmentsAsHash).concat(orphanedTimesheets);
          },
        ),
        debugTap(
          `WorksheetsDS for ${integerToDate(
            this.sliceBegin,
          )} -> ${integerToDate(this.sliceEnd)}`,
        ),
        shareReplay(1),
      );
    }

    return this.workSheets$;
  }

  aggregationEntries(): Observable<(ServiceEntryDS | WorkEntryDS)[]> {
    if (!this.aggregationEntries$) {
      this.aggregationEntries$ = combineLatest(
        this.billingWorkEntryCollection.forRange(
          this.sliceBegin,
          this.sliceEnd,
        ),
        this.billingServiceEntryCollection.forRange(
          this.sliceBegin,
          this.sliceEnd,
        ),
        this.lookupsFor('workSheet'),
        this.lookupsFor('billingAggregation'),
        this.lookupsFor('project'),
        this.lookupsFor('resource'),
        this.lookupsFor('venue'),
        this.lookupsFor('job'),
        this.lookupsFor('material'),
        this.sliceBegin
          ? of([this.sliceBegin, this.sliceEnd])
          : this.focusService.range(),
      ).pipe(
        debounceTime(100),
        map(
          ([
            workEntries,
            serviceEntries,
            workSheetsAsHash,
            aggregationsAsHash,
            projectsAsHash,
            resourcesAsHash,
            venuesAsHash,
            jobsAsHash,
            materialsAsHash,
            [_begin, _end],
          ]) => {
            const workEntriesDS = workEntries.map((workEntry: WorkEntry) => {
              return this.decorateWorkEntry(
                workEntry,
                workSheetsAsHash,
                resourcesAsHash,
                projectsAsHash,
                aggregationsAsHash,
                venuesAsHash,
                jobsAsHash,
              );
            });

            const serviceEntriesDS = serviceEntries.map((serviceEntry) => {
              return this.decorateServiceEntry(
                serviceEntry,
                projectsAsHash,
                aggregationsAsHash,
                venuesAsHash,
                materialsAsHash,
              );
            });

            return [...workEntriesDS, ...serviceEntriesDS];
          },
        ),
        debugTap(
          `aggregationEntriesDS for ${integerToDate(
            this.sliceBegin,
          )} -> ${integerToDate(this.sliceEnd)}`,
        ),
        shareReplay(1),
      );
    }

    return this.aggregationEntries$;
  }

  total() {
    if (!this.total$) {
      this.total$ = this.plans().pipe(
        map((plans) => {
          const total = { stats: undefined };
          total.stats = reduceTasksStats(plans.map((p) => p.stats));

          return total;
        }),
        debugTap(
          `TotalDS for ${integerToDate(this.sliceBegin)} -> ${integerToDate(
            this.sliceEnd,
          )}`,
        ),
        shareReplay(1),
      );
    }

    return this.total$;
  }

  private decorateAssignment(
    assignment,
    projectsAsHash,
    plansAsHash,
    schedulesAsHash,
    tasksAsHash,
    resourcesAsHash,
    venuesAsHash,
    jobsAsHash,
  ): AssignmentDS {
    assignment = Object.assign(new AssignmentDS(), assignment);
    assignment.type = 'DispoAssignment';
    assignment.task = tasksAsHash[assignment.task_id] || assignment.task;

    assignment.plan_id = assignment.task?.plan_id;
    assignment.plan = plansAsHash[assignment.task?.plan_id];
    assignment.schedule = assignment.schedule_id
      ? schedulesAsHash[assignment.schedule_id]
      : undefined;

    assignment.resource =
      resourcesAsHash[`${assignment.resource_type}${assignment.resource_id}`];

    assignment.project_id = assignment.plan?.project_id;
    assignment.project = projectsAsHash[assignment.plan?.project_id];

    assignment.venue = assignment.task?.venue_id
      ? venuesAsHash[assignment.task?.venue_id]
      : undefined;

    const slotConfig = assignment.schedule
      ? (assignment.schedule.slots_config || []).find(
          (s) => s.position === assignment.position,
        )
      : (assignment.task.slots_config || []).find(
          (s) => s.position === assignment.position,
        );

    assignment.slot = Object.assign(
      {
        position: assignment.position,
        qualification_ids: [],
        group_id: undefined,
        role_ids: [],
        store: {},
      },
      slotConfig || {},
      { job_id: slotConfig?.job_id || assignment.task.job_id },
    );

    assignment.job = jobsAsHash[assignment.slot.job_id];

    return assignment;
  }

  private decorateTimesheet(
    timesheet,
    projectsAsHash,
    resourcesAsHash,
    venuesAsHash,
    jobsAsHash,
  ): TimesheetDS {
    timesheet = Object.assign(new TimesheetDS(), timesheet);
    timesheet.type = 'Timesheet';

    timesheet.resource =
      resourcesAsHash[`${timesheet.resource_type}${timesheet.resource_id}`];
    timesheet.project = projectsAsHash[timesheet.project_id];

    timesheet.venue = timesheet.venue_id
      ? venuesAsHash[timesheet.venue_id]
      : undefined;

    timesheet.job = jobsAsHash[timesheet.job_id];

    return timesheet;
  }

  private decorateServiceEntry(
    serviceEntry,
    projectsAsHash,
    aggregationsAsHash,
    venuesAsHash,
    materialsAsHash,
  ): ServiceEntryDS {
    serviceEntry = Object.assign(new ServiceEntryDS(), serviceEntry);
    serviceEntry.type = 'ServiceEntry';

    serviceEntry.aggregation = aggregationsAsHash[serviceEntry.aggregation_id];
    serviceEntry.project = projectsAsHash[serviceEntry.project_id];

    serviceEntry.venue = serviceEntry.task?.venue_id
      ? venuesAsHash[serviceEntry.task?.venue_id]
      : undefined;

    serviceEntry.sku = materialsAsHash[serviceEntry.sku_id];

    return serviceEntry;
  }

  private decorateWorkEntry(
    workEntry,
    workSheetsAsHash,
    resourcesAsHash,
    projectsAsHash,
    aggregationsAsHash,
    venuesAsHash,
    jobsAsHash,
  ): WorkEntryDS {
    const workEntryDS = Object.assign(new WorkEntryDS(), workEntry);
    workEntryDS.type = 'WorkEntry';

    workEntryDS.aggregation = aggregationsAsHash[workEntry.aggregation_id];
    workEntryDS.project = projectsAsHash[workEntry.project_id];
    workEntryDS.resource =
      resourcesAsHash[`${workEntry.resource_type}${workEntry.resource_id}`];
    workEntryDS.venue = venuesAsHash[workEntry.venue_id];
    workEntryDS.sku = jobsAsHash[workEntry.sku_id];

    const timesheet: TimesheetDS =
      workSheetsAsHash[`Timesheet${workEntry.source_id}`];

    // NOTE: timesheet might be undefined if workEntry was billed but timesheet was deleted afterwards
    if (timesheet) {
      workEntryDS.timesheet = timesheet;
      const assignment: AssignmentDS =
        workSheetsAsHash[`DispoAssignment${timesheet.source_id}`];

      if (assignment) {
        workEntryDS.assignment = assignment;

        workEntryDS.task = assignment.task;
        workEntryDS.schedule = assignment.schedule;
        workEntryDS.plan = assignment.plan;
      }
    }

    return workEntryDS;
  }

  private lookupsFor(
    resource:
      | 'project'
      | 'plan'
      | 'schedule'
      | 'task'
      | 'resource'
      | 'venue'
      | 'job'
      | 'material'
      | 'workSheet'
      | 'billingAggregation',
  ) {
    if (!this.lookups$[resource]) {
      const mapToHash = () => {
        return map((items: any[]) => {
          const itemsAsHash = {};
          items.forEach((item) => {
            itemsAsHash[item.id] = item;
          });

          return itemsAsHash;
        });
      };

      const mapToTypeHash = () => {
        return map((items: any[]) => {
          const itemsAsHash = {};
          items.forEach((item) => {
            itemsAsHash[`${item.type}${item.id}`] = item;
          });

          return itemsAsHash;
        });
      };

      const availableLookups = {
        project: () =>
          this.projectCollection
            .forRange(this.sliceBegin, this.sliceEnd)
            .pipe(mapToHash(), shareReplay(1)),
        plan: () =>
          this.plansService
            .forRange(this.sliceBegin, this.sliceEnd)
            .pipe(mapToHash(), shareReplay(1)),
        schedule: () =>
          this.schedulesService
            .forRange(this.sliceBegin, this.sliceEnd)
            .pipe(mapToHash(), shareReplay(1)),
        task: () =>
          this.tasksService
            .forRange(this.sliceBegin, this.sliceEnd)
            .pipe(mapToHash(), shareReplay(1)),
        workSheet: () =>
          this.workSheets().pipe(
            map((workSheets) => {
              const workSheetsAsHash = {};
              workSheets.forEach((workSheet) => {
                workSheetsAsHash[`${workSheet.type}${workSheet.id}`] =
                  workSheet;
                if (workSheet instanceof AssignmentDS && workSheet.timesheet) {
                  workSheetsAsHash[`Timesheet${workSheet.timesheet.id}`] =
                    workSheet.timesheet;
                }
              });

              return workSheetsAsHash;
            }),
            shareReplay(1),
          ),
        billingAggregation: () =>
          this.billingAggregationCollection
            .forRange(this.sliceBegin, this.sliceEnd)
            .pipe(mapToHash(), shareReplay(1)),
        resource: () =>
          this.resourceService.all().pipe(mapToTypeHash(), shareReplay(1)),
        venue: () =>
          this.venueCollection.all().pipe(mapToHash(), shareReplay(1)),
        job: () => this.jobCollection.all().pipe(mapToHash(), shareReplay(1)),
        material: () =>
          this.materialCollection.all().pipe(mapToHash(), shareReplay(1)),
      };

      this.lookups$[resource] = availableLookups[resource]();
    }

    return this.lookups$[resource];
  }
}
