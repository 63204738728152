import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { v4 as uuid } from 'uuid';

import { ApiErrorService } from '../core/api-error.service';
import { Cable } from '../core/cable';
import { CurrentUserService } from '../core/current-user.service';
import { StoreService } from '../core/store.service';
import { FrameCollection } from '../core/frame-collection';
import { DispoFocusService } from '../dispo/dispo-focus.service';
import { TariffCollection } from '../tariffs/tariff.collection';
import { PayGradeCollection } from '../tariffs/pay-grade.collection';
import { Task } from '../dispo/collections/task';
import { Plan } from '../dispo/collections/plan';
import { Assignment } from '../dispo/collections/assignment.model';
import { LocalSettingsService } from '../core/local-settings.service';

@Injectable({
  providedIn: 'root',
})
export class TimesheetCollection extends FrameCollection {
  step = 60 * 60 * 24 * 14;
  cache = 600;
  identifier = 'timesheet';
  endpoint = '/api/timesheets/range';

  remoteValidation = true;
  remoteDeleteValidation = true;

  constructor(
    http: HttpClient,
    cable: Cable,
    dispoFocus: DispoFocusService,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    tariffCollection: TariffCollection,
    payGradeCollection: PayGradeCollection,
    localSettingsService: LocalSettingsService,
  ) {
    super(
      http,
      cable,
      dispoFocus,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
    );

    this.decorators = [
      tariffCollection.visible().all(),
      payGradeCollection.visible().all(),
    ];
  }

  decorate(obj, [tariffs, payGrades]) {
    obj.tariff = tariffs.find((t) => t.id === obj.tariff_id);
    obj.pay_grade = payGrades.find((t) => t.id === obj.pay_grade_id);

    return obj;
  }

  forResource(resource) {
    const filterFunc = (x) =>
      x.resource_id === resource.id && x.resource_type === resource.type;
    const filterLookup = `resource_id: ${resource.id}|resource_type: ${resource.type}`;

    return this.filter(filterFunc, filterLookup);
  }

  forAssignment(assignment) {
    const filterFunc = (x) =>
      x.source_id === assignment.id && x.source_type === 'TcDispo::Assignment';
    const filterLookup = `source_id: ${assignment.id}|source_type: TcDispo::Assignment`;

    return this.filter(filterFunc, filterLookup);
  }

  forAssignments(assignments: any[]) {
    const assignmentIds = assignments.map((a) => a.id);
    const filterFunc = (x) =>
      x.source_type === 'TcDispo::Assignment' &&
      assignmentIds.includes(x.source_id);
    const filterLookup = `source_ids: ${assignmentIds.join(
      ',',
    )}|source_type: TcDispo::Assignment`;

    return this.filter(filterFunc, filterLookup);
  }

  fromAssignment(assignment: Assignment, task: Task, plan: Plan) {
    const {
      id: source_id,
      resource_id,
      resource_type,
      store,
      supplements,
      tracked_time,
      position,
      gratis,
    } = assignment;

    const {
      starts_at,
      ends_at,
      intermission_starts_at,
      intermission_ends_at,
      times,
      marker,
    } = tracked_time ? assignment : task;

    const { venue_id, slots_config } = task;
    const { project_id } = plan;

    const job_id =
      slots_config.find((s) => s.position === assignment.position)?.job_id ??
      task.job_id;

    return {
      id: uuid(),
      shallow: true,
      marker,
      state: 'unconfirmed',
      resource_id,
      resource_type,
      venue_id,
      job_id,
      gratis,
      project_id,
      starts_at,
      ends_at,
      intermission_starts_at,
      intermission_ends_at,
      times,
      supplements: { ...supplements },
      source_type: 'TcDispo::Assignment',
      source_id,
      store,
      position,
    };
  }
}
