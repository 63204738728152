<button
  nz-button
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzPlacement="bottomRight"
  nzTrigger="click"
  nzClickHide="true"
  nzVisible="false"
  nzBackdrop="false"
>
  {{ 'dispo.view_settings' | translate }}
  <i nz-icon nzType="down"></i>
</button>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <ng-container *ngFor="let view of availableViews$ | async; let i = index">
      <li nz-menu-divider *ngIf="view.group && i !== 0"></li>
      <li nz-menu-group *ngIf="view.group">
        <span title
          ><i nz-icon [nzType]="view.icon" *ngIf="view.icon"></i>
          {{ view.label }}</span
        >
      </li>

      <li nz-menu-item (click)="updateViews(view)" *ngIf="!view.group">
        <span title
          ><i nz-icon [nzType]="view.icon" *ngIf="view.icon"></i>
          {{ view.label }}</span
        >
      </li>
    </ng-container>
    <li nz-menu-divider></li>
    <li nz-menu-item>
      <div class="slider">
        <label title>Anzahl Tage</label>
        <nz-slider
          [nzMin]="1"
          [nzMax]="50"
          [(ngModel)]="viewRange"
          (nzOnAfterChange)="updateRange($event)"
        ></nz-slider>
      </div>
    </li>
  </ul>
</nz-dropdown-menu>
