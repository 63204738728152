<div class="sf-group">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>

  <div class="sf-controls">
    <div
      *ngFor="let itemProperty of formProperty.properties; first as isFirst"
      class="sf-elements"
    >
      <sf-form-element
        [formProperty]="itemProperty"
        class="sf-element"
        [class.is-first]="isFirst"
      ></sf-form-element>
      <button
        (click)="removeItem(itemProperty)"
        nz-button
        nzType="secondary"
        class="sf-element-remove"
      >
        {{ 'remove' | translate }}
        <i nz-icon nzType="minus" nzTheme="outline"></i>
      </button>
    </div>
    <div class="sf-actions">
      <button (click)="addItem()" nz-button nzType="primary">
        {{ 'add' | translate }}
        <i nz-icon nzType="plus" nzTheme="outline"></i>
      </button>
    </div>
  </div>
</div>
