import { TcConfigTimes, TcConfigTimesApi } from './config-times.model';

export interface TcConfigCompanyApi extends TcConfigTimesApi {
  app_onsite_checkin: boolean;
  app_attendance: boolean;
  onsite_checkin_radius?: number;
}

export class TcConfigCompany {
  times: TcConfigTimes;
  allowOnsiteCheckin: boolean;
  allowAppAttendance: boolean;
  onSiteCheckInRadiusMeters?: number;

  constructor(init: TcConfigCompany) {
    Object.assign(this, init);
  }

  static fromApi(apiObject: TcConfigCompanyApi) {
    return new TcConfigCompany({
      times: TcConfigTimes.fromApi(apiObject),
      allowOnsiteCheckin: apiObject[`app_onsite_checkin`],
      allowAppAttendance: apiObject[`app_attendance`],
      onSiteCheckInRadiusMeters: apiObject[`onsite_checkin_radius`],
    });
  }
}
