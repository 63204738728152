import { Component, OnChanges, SimpleChanges } from '@angular/core';

import { TcTimesSummaryBaseDirective } from '../times-summary-base.directive';
import { TcTimesSummaryService } from '../times-summary.service';

@Component({
  selector: 'tc-times-summary-web',
  templateUrl: './times-summary-web.component.html',
  styleUrls: ['./times-summary-web.component.scss'],
})
export class TcTimesSummaryWebComponent
  extends TcTimesSummaryBaseDirective
  implements OnChanges
{
  constructor(summaryService: TcTimesSummaryService) {
    super(summaryService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
