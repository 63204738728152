import { Injectable } from '@angular/core';

import { BiasImpactColor } from '../biases/bias-impact-color';
import { Bias } from '../biases/bias.model';
import { SetsCollection } from '../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class EmployeeBiasCollection extends SetsCollection {
  type = Bias;
  identifier = 'bias';
  endpoint = '/api/employees/:employee_id/biases';

  forEmployee(employee_id) {
    return this.query(
      {
        employee_id: employee_id,
      },
      (t) => {
        return t.resource_type === 'Employee' && t.resource_id === employee_id;
      },
    );
  }

  decorate(bias: Bias, imports) {
    bias.color = BiasImpactColor[bias.impact];

    return bias;
  }
}
