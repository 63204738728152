import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DispoFilterService } from '../../shared/filter/filter.service';
import { DispoSelectorService } from '../selector/selector.service';
import { CurrentUserService } from '../../core/current-user.service';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuEmployee {
  private employeeFilters: any[] = [];
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private filterService: DispoFilterService,
    private selectorService: DispoSelectorService,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/employee.menu.filter',
        'dispo/employee.actions.file',
        'dispo/employee.menu.employee',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.filterService.getPresets('dispo.employees').subscribe((presets) => {
      this.employeeFilters = presets;
    });
  }

  items(employee: any): AsyncSubject<any[]> {
    const items$ = new AsyncSubject<any[]>();

    const filterActions: any[] = this.employeeFilters.map((preset) => {
      return {
        label: preset.label,
        icon: 'filter',
        command: () => {
          this.selectorService.setEmployee(employee);
          this.filterService.setPreset(preset.id);
        },
      };
    });

    const employeeActions = [
      {
        label: this.translations['dispo/employee.actions.file'],
        icon: 'tc:employees',
        command: () => {
          window.open(`/#/employees/${employee.id}/personal`, '_blank');
        },
      },
    ];

    const items = [
      ...(filterActions.length !== 0
        ? [
            { separator: true },
            {
              header: true,
              label: this.translations['dispo/employee.menu.filter'],
            },
            ...filterActions,
          ]
        : []),
      ...(employeeActions.length !== 0
        ? [
            { separator: true },
            {
              header: true,
              label: this.translations['dispo/employee.menu.employee'],
            },
            ...employeeActions,
          ]
        : []),
    ];

    items$.next(items);
    items$.complete();

    return items$;
  }
}
