import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash-es';

import { ModalService } from '@timecount/ui';

import { Action } from '../../core/types/action';
import { EntryType } from '../../core/types/entry-type';
import { ActionType } from '../../core/types/action-type';
import { ValidationService } from '../../dispo/collections/validation.service';
import { AggregationType } from '../aggregations/aggregation_type.model';

import { ServiceEntry } from './service-entry.model';
import { ServiceEntryCollection } from './service-entry.collection';
import { TcServiceEntryFormComponent } from './service-entry-form/service-entry-form.component';

@Injectable({
  providedIn: 'root',
})
export class ProjectServiceEntryService {
  private translations: { [key: string]: string } = {};
  constructor(
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _serviceEntryCollection: ServiceEntryCollection,
    private _validationService: ValidationService,
  ) {
    this._translateService
      .get(['modal.edit', 'modal.clone', 'modal.multi'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  openModal(
    object: ServiceEntry,
    type: AggregationType,
    action: 'edit' | 'clone' | 'multi',
  ) {
    let values;
    if (!Array.isArray(object)) {
      const { id, ...rest } = object;
      values = cloneDeep(rest);

      if (action === 'edit') {
        values.id = id;
      }
    }

    const data = {
      tcInitialValue: values,
      isModal: true,
      tcIsExtended: true,
      useColumns: false,
      tcAggregationType: type,
      ...(action === 'multi' && { isBatch: true, targets: object }),
    };

    this._modalService.open(TcServiceEntryFormComponent, {
      data,
      modalTitle: this.translations['modal.' + action],
    });
  }

  delete(object: ServiceEntry) {
    const { project_id, aggregation_id } = object;
    const collection = this._serviceEntryCollection.forProject(
      project_id,
      aggregation_id,
    );

    const action = <Action>{
      id: uuid(),
      entry: object,
      entry_type: EntryType.ServiceEntry,
      type: ActionType.delete,
      validations: (item, stack) => {
        return collection.remoteValidations(item, stack, ActionType.delete);
      },
      errors: [],
    };
    this._validationService.run([action], true);
  }
}
