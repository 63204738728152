import { Component, HostBinding, Input } from '@angular/core';

import { DateInfo } from '@timecount/utils';

@Component({
  selector: 'tc-hub-dispo-shift-grid-header-day',
  templateUrl: './shift-grid-header-day.component.html',
  styleUrls: ['./shift-grid-header-day.component.scss'],
})
export class DispoShiftGridHeaderDayComponent {
  @Input()
  day: DateInfo;

  @Input()
  headerType: 'short' | 'long' = 'long';

  @HostBinding(`class.is-short`)
  get isShort() {
    return this.headerType === `short`;
  }

  @HostBinding('class.is-weekend')
  get isWeekend() {
    return this.day.isWeekend;
  }

  @HostBinding('class.is-holiday')
  get isHoliday() {
    return this.day.isHoliday;
  }

  @HostBinding('class.is-first-of-month')
  get isFirstOfMonth() {
    return this.day.date.getDate() === 1;
  }

  getDateFormat(): string {
    return this.headerType === 'long'
      ? `d. LLL yyyy - 'KW' w`
      : this.isFirstOfMonth
      ? `LLL d`
      : `EEEEEE d`;
  }
}
