import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DispoAssignmentCollection } from '../../collections/assignment';
import { DispoLoaderService } from '../../loader/loader.service';

@Component({
  selector: 'tc-hub-da-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DADeleteComponent {
  @Input() assignments: any;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  constructor(
    private assignmentService: DispoAssignmentCollection,
    private loadingService: DispoLoaderService,
  ) {}

  cancel() {
    this.signal.emit({ action: 'close' });
  }

  delete() {
    const altAssigs = this.assignments.map((assignment) =>
      Object.assign({}, assignment),
    );
    const subjects = this.assignments.map((assignment) =>
      this.assignmentService.delete(assignment.id, assignment),
    );
    this.loadingService.addAssignmentsToLoad(altAssigs, subjects);
    this.signal.emit({ action: 'close' });
  }
}
