import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { ResourceTemplateCollection } from '../../../core/collections/resource_template';
import {
  Supplement,
  SupplementCollection,
} from '../../../core/collections/supplement';
import {
  generateFieldsForSupplements,
  generateSupplementsFromForm,
} from '../../../core/supplements';
import { DispoAssignmentCollection } from '../../../dispo/collections/assignment';
import { Assignment } from '../../../dispo/collections/assignment.model';
import { DispoTaskCollection } from '../../../dispo/collections/task';
import { TimebalanceCollection } from '../../../timebalances/timebalance.collection';

@Component({
  selector: 'tc-hub-da-timebalance',
  templateUrl: './timebalance.component.html',
  styleUrls: ['./timebalance.component.scss'],
})
export class DATimebalanceComponent implements OnInit, OnDestroy {
  @Input() assignments: Assignment[];

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;
  validated = false;

  type;
  errors: any[] = [];
  warnings: any[] = [];
  timeBalances: any[] = [];

  supplements: Supplement[];

  destroyed$ = new Subject<void>();

  constructor(
    private assignmentCollection: DispoAssignmentCollection,
    private taskCollection: DispoTaskCollection,
    private supplementCollection: SupplementCollection,
    private timebalanceCollection: TimebalanceCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    combineLatest(
      this.supplementCollection.all(),
      this.resourceTemplateCollection.default('dispo.time_balance'),
    )
      .pipe(first())
      .subscribe({
        next: ([supplements, template]: [Supplement[], any]) => {
          this.supplements = supplements.filter(
            (s) => s.visibility === 'visible' || s.visibility === 'fields_only',
          );

          template = template.template || {};
          this.type = template.type || 'sickness';
          this.form = this.buildForm(template);

          this.form.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
              this.validated = false;
            });

          this.form
            .get('type')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((type) => {
              this.type = type;

              if (type === 'vacation' || type === 'sickness') {
                this.form.get('hours').disable();
              } else {
                this.form.get('hours').enable();
              }
            });

          this.signal.emit({ action: 'resize' });
        },
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  validate() {
    const form = this.form.value;

    this.timeBalances = [];
    this.loading = true;

    this.assignments.forEach((assignment) => {
      const timeBalance: any = Object.assign(
        {},
        {
          type: form.type,
          date: assignment.date,
          hours: form.hours ? form.hours : 0,
          employee_id: assignment.resource_id,
          modifier: form.modifier,
          description: form.description,
          source_id: assignment.task_id,
          source_type: 'task',
          store: Object.assign(
            {},
            form.timeBalanceGeneral,
            form.timeBalanceTypeGeneral,
          ),
        },
      );

      timeBalance.supplements = Object.assign(
        {},
        generateSupplementsFromForm(form, this.supplements),
      );

      this.timeBalances.push(timeBalance);
    });

    combineLatest(
      this.timeBalances.map((tb) => this.timebalanceCollection.validate(tb)),
    ).subscribe((validations) => {
      this.errors = [...validations.map((v) => v.errors)];
      this.warnings = [...validations.map((v) => v.warnings)];
      this.validated = !validations.some((v) => v.fatal);
      this.loading = false;

      this.signal.emit({ action: 'resize' });

      if (validations.every((v) => v.valid)) {
        this.save();
      }
    });
  }

  save() {
    this.loading = true;

    combineLatest(
      this.timeBalances.map((tb) => this.timebalanceCollection.create(tb)),
    ).subscribe({
      next: (resp) => {
        combineLatest(
          this.assignments.map((a) =>
            this.assignmentCollection.delete(a.id, a),
          ),
        ).subscribe({
          next: () => {
            this.loading = false;
            this.signal.emit({ action: 'close' });
          },
          error: () => {
            this.loading = false;
          },
        });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(template) {
    let formConfig = {
      type: [template.type || 'sickness', [Validators.required]],
      hours: [
        {
          value: template.hours || 0,
          disabled: this.type === 'sickness' || this.type === 'vacation',
        },
        [Validators.required],
      ],
      modifier: [template.modifier || 1, [Validators.required]],
      description: [template.description],
      timeBalanceGeneral: [Object.assign({}, template.store || {})],
      timeBalanceTypeGeneral: [Object.assign({}, template.store || {})],
    };

    const supplementFields = generateFieldsForSupplements(
      template,
      this.supplements,
    );

    formConfig = Object.assign(formConfig, supplementFields);

    return this.formBuilder.group(formConfig);
  }
}
