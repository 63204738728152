import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';

import { ModalService } from '@timecount/ui';

import { Action } from '../core/types/action';
import { EntryType } from '../core/types/entry-type';
import { ActionType } from '../core/types/action-type';
import { ValidationService } from '../dispo/collections/validation.service';
import { Bias } from '../biases/bias.model';
import { VenueCollection } from '../venues/venue.collection';
import { JobCollection } from '../jobs/job.collection';
import { CustomerCollection } from '../customers/customer.collection';
import { QualificationCollection } from '../core/collections/qualification';

import { TcEmployeeBiasesFormComponent } from './employee-biases-form/employee-biases-form.component';
import { EmployeeBiasCollection } from './employee-biases.collection';

@Injectable({
  providedIn: 'root',
})
export class EmployeeBiasesService {
  private translations: { [key: string]: string } = {};
  constructor(
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _employeeBiasCollection: EmployeeBiasCollection,
    private _validationService: ValidationService,
    private _jobCollection: JobCollection,
    private _venueCollection: VenueCollection,
    private _customerCollection: CustomerCollection,
    private _qualificationCollection: QualificationCollection,
  ) {
    this._translateService
      .get(['modal.create', 'modal.edit', 'modal.clone'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  async openModal(
    employeeId: number,
    item: Bias,
    action: 'create' | 'edit' | 'clone',
  ) {
    const { id: _, ...itemWithoutId } = item ?? {};

    let target;

    if (item?.target_type) {
      const resourceCollection =
        this['_' + item.target_type.toLowerCase() + 'Collection'];

      target = await resourceCollection
        .find(item.target_id)
        .asObservable()
        .toPromise();
    }

    this._modalService.open(TcEmployeeBiasesFormComponent, {
      data: {
        initialValue: action === 'edit' ? item : itemWithoutId,
        target,
        employeeId,
      },
      modalTitle: this.translations['modal.' + action],
    });
  }

  delete(object: Bias) {
    const action = <Action>{
      id: uuid(),
      entry: object,
      entry_type: EntryType.Bias,
      type: ActionType.delete,
      validations: (item, stack) => {
        return this._employeeBiasCollection
          .forEmployee(object.resource_id)
          .remoteValidations(item, stack, ActionType.delete);
      },
      errors: [],
    };
    this._validationService.run([action], true);
  }
}
