import { ExclusionPolicy, Strategy } from 'typeserializer/dist';
@Strategy(ExclusionPolicy.NONE)
export class Department {
  id: number;
  default = false;

  title: string;
  key: string;
  icon: string;
  color: string;
  description: string;
}

export class SelectableDepartment extends Department {
  isSelected: boolean;
}
