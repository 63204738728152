import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { ScheduleService } from '../actions/schedule.service';
import { TaskService } from '../actions/task.service';
import { DispoRoleCollection } from '../collections/role';
import { QualificationCollection } from '../../core/collections/qualification';
import { DispoGroupCollection } from '../collections/group';
import { SlotPos, Task } from '../collections/task';
import { DispoScheduleCollection, Schedule } from '../collections/schedule';
import { DispoFilterService } from '../../shared/filter/filter.service';
import { DispoSelectorService } from '../selector/selector.service';
import { AssignmentService } from '../actions/assignment.service';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoCombinedTaskCollection } from '../collections/combined_task';
import { AnnouncementService } from '../actions/announcement.service';
import { Assignment } from '../collections/assignment.model';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuTaskSlot {
  private roles: any[] = [];
  private groups: any[] = [];
  private qualifications: any[] = [];
  private slotFilters: any[] = [];
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private scheduleActions: ScheduleService,
    private taskActions: TaskService,
    private assignmentActions: AssignmentService,
    private announcementActions: AnnouncementService,
    private filterService: DispoFilterService,
    private selectorService: DispoSelectorService,
    private roleCollection: DispoRoleCollection,
    private groupCollection: DispoGroupCollection,
    private qualiCollection: QualificationCollection,
    private scheduleCollection: DispoScheduleCollection,
    private combinedTaskCollection: DispoCombinedTaskCollection,
    private currentUser: CurrentUserService,
  ) {
    this.roleCollection.all().subscribe({
      next: (roles) => {
        this.roles = roles;
      },
    });

    this.groupCollection.all().subscribe({
      next: (groups) => {
        this.groups = groups;
      },
    });

    this.qualiCollection.all().subscribe({
      next: (qualifications) => {
        this.qualifications = qualifications;
      },
    });

    this.filterService.getPresets('dispo.slots').subscribe((presets) => {
      this.slotFilters = presets;
    });

    this.translateService
      .get([
        'dispo/task_slot.actions.disable',
        'dispo/task_slot.actions.enable',
        'dispo/task_slot.actions.assign_employee',
        'dispo/task_slot.actions.assign_contractor',
        'dispo/task_slot.actions.gratis',
        'dispo/task_slot.actions.invoiceable',
        'dispo/task_slot.actions.announce',
        'dispo/task_slot.menu.filter',
        'dispo/task_slot.menu.slots',
        'dispo/task_slot.menu.toggle_role',
        'dispo/task_slot.menu.toggle_group',
        'dispo/task_slot.menu.toggle_quali',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  itemsForSchedule(
    slots: SlotPos[],
    currSchedule: Schedule,
    assignments: Assignment[] = [],
  ): Observable<any[]> {
    return this.scheduleCollection.observeItem(currSchedule).pipe(
      map((schedule: Schedule) => {
        const filterActions = this.slotFilters.map((preset) => {
          return {
            label: preset.label,
            icon: 'filter',
            command: (event: any) => {
              // TODO: check if slots selection can be from another task
              this.selectorService.setSlots(slots);
              this.filterService.setPreset(preset.id);
            },
          };
        });

        const slotActions = [];
        if (slots && slots.length !== 0) {
          slotActions.push(
            {
              label:
                this.translations['dispo/task_slot.actions.assign_employee'],
              icon: 'user-add',
              visible: this.currentUser.hasAccessToSection(
                'views.dispo.assignment.assign_employee',
              ),
              command: (event: any) => {
                this.assignmentActions.assignToSchedule(
                  schedule,
                  slots,
                  'Employee',
                );
              },
            },
            {
              label:
                this.translations['dispo/task_slot.actions.assign_contractor'],
              icon: 'appstore-add',
              visible: this.currentUser.hasAccessToSection(
                'views.dispo.assignment.assign_contractor',
              ),
              command: (event: any) => {
                this.assignmentActions.assignToSchedule(
                  schedule,
                  slots,
                  'Contractor',
                );
              },
            },
          );

          slotActions.push({
            label: this.translations['dispo/task_slot.actions.disable'],
            icon: 'close',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.slot.disable',
            ),
            command: (event: any) => {
              this.scheduleActions.disableSlots(schedule, slots, assignments);
            },
          });

          slotActions.push({
            label: this.translations['dispo/task_slot.actions.enable'],
            icon: 'check',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.slot.enable',
            ),
            command: (event: any) => {
              this.scheduleActions.enableSlots(schedule, slots);
            },
          });

          slotActions.push({
            label: this.translations['dispo/task_slot.actions.announce'],
            icon: 'sound',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.announcement.append_slots',
            ),
            command: (event: any) => {
              this.announcementActions.appendSlots(schedule, slots);
            },
          });
        }

        const items = [
          ...(filterActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/task_slot.menu.filter'],
                },
                ...filterActions,
              ]
            : []),
          ...(slotActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/task_slot.menu.slots'],
                },
                ...slotActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }

  itemsForTask(
    slots: SlotPos[],
    currTask: Task,
    assignments: Assignment[] = [],
  ): Observable<any[]> {
    return this.combinedTaskCollection.observeItem(currTask).pipe(
      map((task: Task) => {
        // user has moved away -> task is not visible anymore
        if (!task) {
          return [];
        }

        const filterActions = this.slotFilters.map((preset) => {
          return {
            label: preset.label,
            icon: 'filter',
            command: (event: any) => {
              // TODO: check if slots selection can be from another task
              this.selectorService.setSlots(slots);
              this.filterService.setPreset(preset.id);
            },
          };
        });

        const slotActions = [];

        if (slots && slots.length !== 0) {
          slotActions.push(
            {
              label:
                this.translations['dispo/task_slot.actions.assign_employee'],
              icon: 'user-add',
              visible: this.currentUser.hasAccessToSection(
                'views.dispo.assignment.assign_employee',
              ),
              command: (event: any) => {
                this.assignmentActions.assignToTask(task, slots, 'Employee');
              },
            },
            {
              label:
                this.translations['dispo/task_slot.actions.assign_contractor'],
              icon: 'appstore-add',
              visible: this.currentUser.hasAccessToSection(
                'views.dispo.assignment.assign_contractor',
              ),
              command: (event: any) => {
                this.assignmentActions.assignToTask(task, slots, 'Contractor');
              },
            },
          );

          if (!task.schedule_id) {
            const filteredSlotsConfig = task.slots_config.filter(
              (slotConfig) => {
                return slots.some(
                  (slotPos) => slotPos.y === slotConfig.position,
                );
              },
            );

            const gratisSlots = new Map<number, boolean>(
              filteredSlotsConfig.map((slot) => [slot.position, slot.gratis]),
            );

            ['gratis', 'invoiceable'].forEach((action) => {
              const isGratis = action === 'invoiceable';
              slotActions.push({
                label: this.translations[`dispo/task_slot.actions.${action}`],
                icon: 'dollar',
                visible:
                  this.currentUser.hasAccessToSection(
                    'views.dispo.task.gratis',
                  ) &&
                  (filteredSlotsConfig.some(
                    (slotConfig) =>
                      gratisSlots.get(slotConfig.position) === isGratis,
                  ) ||
                    !filteredSlotsConfig.length),
                command: (event: any) => {
                  this.taskActions.setGratisState(task, slots, !isGratis);
                },
              });
            });
            const toggleRoleActions = this.roles.map((role) => {
              return {
                label: role.title,
                command: (event: any) => {
                  this.taskActions.toggleRole(task, role, slots);
                },
              };
            });
            slotActions.push({
              label: this.translations['dispo/task_slot.menu.toggle_role'],
              visible: this.currentUser.hasAccessToSection(
                'views.dispo.task.slots',
              ),
              items: toggleRoleActions,
            });

            const toggleGroupActions = this.groups.map((group) => {
              return {
                label: group.title,
                command: (event: any) => {
                  this.taskActions.toggleGroup(task, group, slots);
                },
              };
            });
            slotActions.push({
              label: this.translations['dispo/task_slot.menu.toggle_group'],
              visible: this.currentUser.hasAccessToSection(
                'views.dispo.task.slots',
              ),
              items: toggleGroupActions,
            });

            const toggleQualiActions = this.qualifications.map((quali) => {
              return {
                label: quali.title,
                command: (event: any) => {
                  this.taskActions.toggleQualification(task, quali, slots);
                },
              };
            });
            slotActions.push({
              label: this.translations['dispo/task_slot.menu.toggle_quali'],
              visible: this.currentUser.hasAccessToSection(
                'views.dispo.task.slots',
              ),
              items: toggleQualiActions,
            });
          }
        }

        const items = [
          ...(filterActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/task_slot.menu.filter'],
                },
                ...filterActions,
              ]
            : []),
          ...(slotActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/task_slot.menu.slots'],
                },
                ...slotActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }
}
