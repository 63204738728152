import './zone-flags';
import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import 'hammerjs';

// This has been moved from the polyfills.ts file to here to ensure that it is
// loaded before Angular bootstraps.
import 'zone.js';

import { AppModule } from './app/app.module';
import { TcConfigApi } from './app/shared';
import { environment } from './environments';
import { initSentry } from './sentry';
import { legacyBootstrapHandler } from './app/app-legacy.module';

declare global {
  interface Window {
    config: TcConfigApi;
    debug: boolean;
    jQuery: any;
    ATL_JQ_PAGE_PROPS: any;
  }
}

window.config = {
  ...window.config,
  frontend: environment.buildInfo,
};

if (environment.production) {
  enableProdMode();
}

if (environment.sentry) {
  initSentry();
}

platformBrowser()
  .bootstrapModule(AppModule)
  .then(legacyBootstrapHandler)
  .catch((err) => console.error(err));
