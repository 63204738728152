import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  getNumberFromPercent,
  getPercentageFromNumber,
} from '@timecount/utils';

@Component({
  selector: 'tc-hub-fieldset-rate',
  templateUrl: './fieldset-rate.component.html',
  styleUrls: ['./fieldset-rate.component.scss'],
})
export class FieldsetRateComponent implements OnInit, OnDestroy, OnChanges {
  formGroup: UntypedFormGroup;

  @Input()
  set rate(value) {
    //converting the value to decimal currency, as the backend works with cents
    this._rate = value / 100;
  }
  get rate() {
    return this._rate;
  }
  private _rate: number;

  @Input() items: { id: number; title: string }[];
  @Input() surcharges: Record<string, number>;

  selectedOption: 'currency' | 'percent' = 'percent';

  /**
   * Used only to sync the form value with the legacy app
   */
  @Output()
  tcValueChange = new EventEmitter();

  private _destroyed$ = new Subject<void>();

  get zoneGroups() {
    return this.formGroup.get('zoneGroups') as UntypedFormGroup;
  }

  constructor(private formBuilder: UntypedFormBuilder) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit(): void {
    this._setFormGroup();
  }

  ngOnChanges({ rate, surcharges }: SimpleChanges): void {
    if (this.formGroup instanceof UntypedFormGroup) {
      if (rate) {
        this.formGroup.get('rate').setValue(this.rate, { emitEvent: false });
      }
      if (surcharges) {
        this.zoneGroups.setValue(this.surcharges, { emitEvent: false });
      }
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }
  // ---------------
  // Events Handlers
  // ---------------
  onSelectOption(selected: 'currency' | 'percent') {
    this.selectedOption = selected;
    Object.entries(this.zoneGroups.controls).forEach(([key, control]) => {
      control.setValue(
        selected === 'currency'
          ? getNumberFromPercent(control.value, this.formGroup.value.rate)
          : getPercentageFromNumber(control.value, this.formGroup.value.rate),
      );
    });
  }
  // ---------------
  // Private Methods
  // ---------------

  private _setFormGroup() {
    this.formGroup = this.formBuilder.group({
      rate: new UntypedFormControl(this.rate, Validators.required),
      zoneGroups: this.formBuilder.group(
        Object.fromEntries(
          this.items.map((item) => [item.id, this.surcharges[item.id]]),
        ),
      ),
    });
    this.formGroup.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        if (this.selectedOption === 'currency') {
          Object.keys(value.zoneGroups).forEach((key) => {
            value.zoneGroups[key] = getPercentageFromNumber(
              value.zoneGroups[key],
              value.rate,
            );
          });
        }
        this.tcValueChange.emit(value);
      });
  }
}
