import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { RemoteConfigCollection } from './collections/remote_config';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfig {
  configs$;
  configs = [];

  constructor(
    private configCollection: RemoteConfigCollection,
    private http: HttpClient,
  ) {
    this.configs$ = configCollection.all();
    this.configs$.subscribe({
      next: (configs) => {
        this.configs = configs;
      },
    });
  }

  get(type, key?, spreadData = true) {
    return this.configs$.pipe(
      map((configs: any[]) => {
        return configs
          .filter((config) => {
            if (!key) {
              return config.type === type;
            }
            return config.type === type && config.key === key;
          })
          .sort((c1, c2) => c1.position - c2.position)
          .map((c) => {
            return spreadData ? Object.assign({}, c, c.data) : c;
          });
      }),
    );
  }

  getItem(type, key) {
    return this.configs$.pipe(
      map((configs: any[]) => {
        const config = configs.find((c) => c.type === type && c.key === key);

        if (config) {
          return Object.assign({}, config, config.data);
        } else {
          return undefined;
        }
      }),
    );
  }

  getLookupMap(key) {
    return this.http
      .get('api/preferences/maps/' + key)
      .pipe(map((response) => response['data']));
  }
}
