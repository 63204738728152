import { ValidationErrorLevel } from './validation-error-level';
import { ConflictingEntryType } from './conflicting-entry-type';

export class Validation {
  id: string;

  message: string;
  type: ValidationErrorLevel;

  conflicting_entry_type: ConflictingEntryType = ConflictingEntryType.None;
  conflicting_entry?: any;

  resolve?;
  resolve_message?;
}
