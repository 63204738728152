import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { DispoPlanCollection, Plan } from '../../../collections/plan';
import { ResourceTemplateCollection } from '../../../../core/collections/resource_template';
import { SetCollection } from '../../../../core/sets-collection';

@Component({
  selector: 'tc-hub-dsc-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class DSCStep1Component implements OnInit {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  templateCollection: SetCollection;

  constructor(
    private resourceTemplateCollection: ResourceTemplateCollection,
    private planCollection: DispoPlanCollection,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.templateCollection =
      this.resourceTemplateCollection.setForResource('dispo.tasks');
  }

  ngOnInit() {
    this.form = this.buildForm();
    this.signal.emit({ action: 'resize' });
  }

  validate() {
    // validate starts/ends in range
  }

  next() {
    const nextConfig = Object.assign({}, this.config, this.form.value);
    this.templateCollection.get(nextConfig.template_id).subscribe({
      next: (tpl: any) => {
        nextConfig.template = Object.assign(
          {
            state: 'draft',
            tracking_enabled: false,
            push_notifications: false,
          },
          tpl.template,
        );

        const offset = tpl.template.offset || 0;
        const length = tpl.template.length || 6 * 60 * 60;
        const intermission_offset = tpl.template.intermission_offset;
        const intermission_length = tpl.template.intermission_length;

        const baseDate = new Date();
        baseDate.setHours(0, 0, 0, 0);
        nextConfig.starts_at = new Date(baseDate.getTime() + offset * 1000);
        nextConfig.ends_at = new Date(
          baseDate.getTime() + offset * 1000 + length * 1000,
        );
        nextConfig.intermission_starts_at =
          typeof intermission_offset !== 'undefined'
            ? new Date(baseDate.getTime() + intermission_offset * 1000)
            : undefined;
        nextConfig.intermission_ends_at =
          typeof intermission_offset !== 'undefined'
            ? new Date(
                baseDate.getTime() +
                  intermission_offset * 1000 +
                  intermission_length * 1000,
              )
            : undefined;

        // TODO: unnest - how to combine two AsyncSubjects???
        this.planCollection.find(nextConfig.plan_id).subscribe({
          next: (plan: Plan) => {
            nextConfig.range_begin = plan[`starts_at`];
            nextConfig.range_end = plan[`ends_at`];

            this.signal.emit({ action: 'next', config: nextConfig });
          },
        });
      },
    });
  }

  private buildForm() {
    return this.formBuilder.group(
      {
        title: [this.config.title, [Validators.required]],
        plan_id: [this.config.plan_id, [Validators.required]],
        template_id: [this.config.template_id, [Validators.required]],
      },
      {
        validator: Validators.compose([this.validate.bind(this)]),
      },
    );
  }
}
