import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalSettingsService } from '../../../../core/local-settings.service';

import { EmployeesGridItemType } from './employees-grid-item.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeesGridItemTogglerService {
  private readonly _settingsKey = 'dispo.employees.hiddenItems';

  private _gridItemTypesHiddenState = new BehaviorSubject(
    new Map<EmployeesGridItemType, boolean>(),
  );

  constructor(private localSettings: LocalSettingsService) {
    this.localSettings
      .get(this._settingsKey, [])
      .pipe(map((hiddenStates) => new Map(hiddenStates)))
      .subscribe(this._gridItemTypesHiddenState);
  }

  public toggle(itemType: EmployeesGridItemType) {
    const { value } = this._gridItemTypesHiddenState;

    this.localSettings.set(this._settingsKey, [
      ...value.set(itemType, !value.get(itemType)),
    ]);
  }

  public isTypeVisible(itemType: EmployeesGridItemType): Observable<boolean> {
    return this._gridItemTypesHiddenState
      .asObservable()
      .pipe(map((hiddenStates) => !hiddenStates.get(itemType)));
  }
}
