import sift from 'sift';
import {
  addSeconds,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  eachDayOfInterval,
  endOfWeek,
  isSameWeek,
  startOfDay,
  startOfWeek,
  sub,
} from 'date-fns';

import { TcInterval } from '@timecount/core';
import {
  dateToTzUnix,
  intervalInMinutes,
  splitIntervalInDays,
} from '@timecount/utils';

import { JsonCalculator } from '../shared';

import { debug, dig, integerToDate } from './helpers';
import { SiftItemType } from './types/sift.model';

export const renderPartial = (partial, config) => {
  let partialAsString = JSON.stringify(partial);

  debug('Rendering Partial with vars', partial, config);

  partialAsString = partialAsString.replace(
    /"#\[([^\]]*)\]"/g,
    (match, configProp) => {
      const value = dig(config, configProp);

      if (value !== undefined && value !== null) {
        return JSON.stringify(value);
      } else {
        return null;
      }
    },
  );

  return JSON.parse(partialAsString);
};

export const siftFilter = (settings) =>
  sift(settings, {
    expressions: {
      // does array2 contain all values of array1
      $contains: function (array1: any, array2: any) {
        if (!array1) {
          return true;
        }
        if (!array2) {
          return false;
        }
        if (array1.length === 0) {
          return true;
        }
        if (array2.length === 0) {
          return false;
        }
        return array1.every((value) => array2.includes(value));
      },
      // does item overlap with a day of the week (0-6)
      // config: [dayOfWeek]
      $overlapsDay: function (config: unknown, item: SiftItemType) {
        const dayOfWeek =
          Array.isArray(config) && config.length ? config[0] : 0;
        const compareOnlyStart =
          Array.isArray(config) && config.length ? config[1] : false;

        const itemInterval = item.marker
          ? {
              start: integerToDate(item.marker[0]),
              end: integerToDate(item.marker[1]),
            }
          : TcInterval.parseFromApi(item);

        if (compareOnlyStart) {
          return itemInterval.start.getDay() === dayOfWeek;
        } else {
          return eachDayOfInterval(itemInterval).some((day) => {
            if (day.getDay() === dayOfWeek) {
              return true;
            }
          });
        }
      },
      $overlapsWeek: function (config: unknown, item: SiftItemType) {
        const date =
          Array.isArray(config) && config.length ? new Date(config[0]) : 0;
        const compareOnlyStart =
          Array.isArray(config) && config.length ? config[1] : false;

        const itemInterval = item.marker
          ? {
              start: integerToDate(item.marker[0]),
              end: integerToDate(item.marker[1]),
            }
          : TcInterval.parseFromApi(item);

        if (compareOnlyStart) {
          return isSameWeek(date, itemInterval.start, {
            weekStartsOn: 1,
          });
        } else {
          return (
            isSameWeek(date, itemInterval.start, {
              weekStartsOn: 1,
            }) ||
            isSameWeek(date, itemInterval.end, {
              weekStartsOn: 1,
            })
          );
        }
      },

      // does item overlap with the time range
      // config: [offset, length]
      $overlapsTime: function (config: unknown, item: SiftItemType) {
        const offset = Array.isArray(config) && config.length ? config[0] : 0;
        const length =
          Array.isArray(config) && config.length ? config[1] : 36400;

        const itemInterval = item.marker
          ? {
              start: integerToDate(item.marker[0]),
              end: integerToDate(item.marker[1]),
            }
          : TcInterval.parseFromApi(item);

        //if the duration of either the item interval or the config interval
        //exceeds 24 hours, they overlap
        if (intervalInMinutes(itemInterval) >= 1440 || length >= 86400) {
          return true;
        }

        return splitIntervalInDays(itemInterval).some((interval) => {
          const start = addSeconds(startOfDay(interval.start), offset);

          // if the interval is overnight, we need to check both days
          let dateBeforeOverlapping = false;
          if (offset + length > 86400) {
            const startPre = sub(start, { days: 1 });
            const configIntervalPre = {
              start: startPre,
              end: addSeconds(startPre, length),
            };

            dateBeforeOverlapping = areIntervalsOverlapping(
              interval,
              configIntervalPre,
            );
          }

          const configInterval = {
            start,
            end: addSeconds(start, length),
          };

          return (
            dateBeforeOverlapping ||
            areIntervalsOverlapping(interval, configInterval)
          );
        });
      },
      // does array contain value
      $includes: function (value: any, array: any) {
        if (!array) {
          return false;
        }
        if (array.length === 0) {
          return false;
        }
        return array.includes(value);
      },
      $notIncludes: function (value: any, array: any) {
        if (!array) {
          return true;
        }
        if (array.length === 0) {
          return true;
        }
        return !array.includes(value);
      },
      // used to check if two values are equal
      $only: function (config: [any, any]) {
        return config[0] === config[1];
      },
      // used to check if two values are not equal
      $except: function (config: [any, any]) {
        return config[0] !== config[1];
      },
      // does array2 not contain all values of array1
      $notContains: function (array1: any, array2: any) {
        if (!array1) {
          return false;
        }
        if (!array2) {
          return true;
        }
        if (array1.length === 0) {
          return false;
        }
        if (array2.length === 0) {
          return true;
        }
        return !array1.some((value) => !array2.includes(value));
      },
      // does item overlap with the time range
      // config: [starts_at, ends_at, padLeft = 0, padRight = padLeft]
      $overlaps: function (config: any, item: any) {
        if (!item) {
          return false;
        }

        const starts_at = new Date(config[0]);
        const ends_at = new Date(config[1]);
        const padLeft = config[2] || 0;
        const padRight = typeof config[3] === 'undefined' ? padLeft : config[3];

        if (item.marker) {
          return (
            item.marker[0] < dateToTzUnix(ends_at) + padRight &&
            item.marker[1] > dateToTzUnix(starts_at) - padLeft
          );
        }

        if (item.starts_at && item.ends_at) {
          return (
            item.starts_at.getTime() < ends_at.getTime() + padRight * 1000 &&
            item.ends_at.getTime() > starts_at.getTime() - padLeft * 1000
          );
        }
      },
      // does item not overlap with the time range
      // config: [starts_at, ends_at, padLeft = 0, padRight = padLeft]
      $notOverlaps: function (config: any, item: any) {
        if (!item) {
          return false;
        }
        const starts_at = new Date(config[0]);
        const ends_at = new Date(config[1]);
        const padLeft = config[2] || 0;
        const padRight = typeof config[3] === 'undefined' ? padLeft : config[3];

        if (item.marker) {
          return !(
            item.marker[0] < dateToTzUnix(ends_at) + padRight &&
            item.marker[1] > dateToTzUnix(starts_at) - padLeft
          );
        }

        if (item.starts_at && item.ends_at) {
          return !(
            item.starts_at.getTime() < ends_at.getTime() + padRight * 1000 &&
            item.ends_at.getTime() > starts_at.getTime() - padLeft * 1000
          );
        }
      },
      // does item overlap with all the time ranges
      // config: [Range[], padLeft = 0, padRight = padLeft]
      $overlapsAllRanges: function (config: any, item: any) {
        if (!item) {
          return false;
        }

        const ranges = config[0];
        const padLeft = config[1] || 0;
        const padRight = typeof config[2] === 'undefined' ? padLeft : config[2];

        if (!ranges || ranges.length === 0) {
          return false;
        }

        let itemBegin;
        let itemEnd;

        if (item.marker) {
          itemBegin = item.marker[0] * 1000;
          itemEnd = item.marker[1] * 1000;
        } else {
          itemBegin = item.starts_at.getTime();
          itemEnd = item.ends_at.getTime();
        }

        return ranges.every((range) => {
          return (
            itemBegin < range.ends_at + padRight * 1000 &&
            itemEnd > range.starts_at - padLeft * 1000
          );
        });
      },
      // does item overlap with none of the time ranges
      // config: [Range[], padLeft = 0, padRight = padLeft]
      $overlapsNoneRanges: function (config: any, item: any) {
        if (!item) {
          return false;
        }

        const ranges = config[0];
        const padLeft = config[1] || 0;
        const padRight = typeof config[2] === 'undefined' ? padLeft : config[2];

        if (!ranges || ranges.length === 0) {
          return false;
        }

        let itemBegin;
        let itemEnd;

        if (item.marker) {
          itemBegin = item.marker[0] * 1000;
          itemEnd = item.marker[1] * 1000;
        } else {
          itemBegin = item.starts_at.getTime();
          itemEnd = item.ends_at.getTime();
        }

        return !ranges.some((range) => {
          return (
            itemBegin < range.ends_at + padRight * 1000 &&
            itemEnd > range.starts_at - padLeft * 1000
          );
        });
      },
      // does item overlap with some of the time ranges
      // config: [Range[], padLeft = 0, padRight = padLeft]
      $overlapsSomeRanges: function (config: any, item: any) {
        if (!item) {
          return false;
        }

        const ranges = config[0];
        const padLeft = config[1] || 0;
        const padRight = typeof config[2] === 'undefined' ? padLeft : config[2];

        if (!ranges || ranges.length === 0) {
          return false;
        }

        let itemBegin;
        let itemEnd;

        if (item.marker) {
          itemBegin = item.marker[0] * 1000;
          itemEnd = item.marker[1] * 1000;
        } else {
          itemBegin = item.starts_at.getTime();
          itemEnd = item.ends_at.getTime();
        }

        return ranges.some((range) => {
          return (
            itemBegin < range.ends_at + padRight * 1000 &&
            itemEnd > range.starts_at - padLeft * 1000
          );
        });
      },
      // is there a minimum gap between this and the other items
      // config: [startsAt, endsAt, minGap, maxNetTotal, maxTotal, maxIncludedGap]
      $minimumGap: function (config: any, array: any) {
        const startsAt = dateToTzUnix(new Date(config[0]));
        const endsAt = dateToTzUnix(new Date(config[1]));
        const minGap = config[2];
        const maxNetTotal = config[3] ?? endsAt - startsAt;
        const maxTotal = config[4] ?? maxNetTotal;
        const maxIncludedGap = config[5] ?? maxTotal;

        if (!Array.isArray(array)) {
          // eslint-disable-next-line prefer-rest-params
          array = arguments[3];
        }

        const mergedItems: number[][] = [
          ...array,
          { marker: [startsAt, endsAt] },
        ]
          .sort((a, b) => a.marker[0] - b.marker[0]) // sort by starts_at
          .reduce((combined, next) => {
            // reduce overlapping items
            if (
              combined.length === 0 ||
              combined[combined.length - 1][1] < next.marker[0]
            ) {
              combined.push(next.marker);
            } else {
              const prev = combined.pop();
              combined.push([prev[0], Math.max(prev[1], next.marker[1])]);
            }
            return combined;
          }, []);

        let netTotal = 0;

        const nextGap = mergedItems.reduce(
          (last, [curr0, curr1]) => {
            if (last.marker0 && last.marker1) {
              return last;
            }
            if (curr0 <= endsAt && curr1 >= startsAt) netTotal += curr1 - curr0;
            if (curr1 <= endsAt) {
              return last;
            }
            if (last.marker0 + minGap <= curr0) {
              return { marker0: last.marker0, marker1: curr0 };
            }
            if (curr0 >= endsAt) netTotal += curr1 - curr0;
            return { marker0: curr1 };
          },
          {
            marker0: endsAt,
            marker1: null,
          },
        );

        const prevGap = mergedItems.reduceRight(
          (last, [curr0, curr1]) => {
            if (last.marker0 && last.marker1) {
              return last;
            }
            if (curr0 >= startsAt) {
              return last;
            }
            if (last.marker1 - minGap >= curr1) {
              return { marker0: curr1, marker1: last.marker1 };
            }
            if (curr1 <= startsAt) netTotal += curr1 - curr0;
            return { marker1: curr0 };
          },
          {
            marker0: null,
            marker1: startsAt,
          },
        );

        const total = nextGap.marker0 - prevGap.marker1;

        debug('$minimumGap Results', {
          prevGap: prevGap,
          nextGap: nextGap,
          total: total,
          netTotal: netTotal,
          arguments: config,
          visitedItems: mergedItems,
        });

        return (
          total <= maxTotal &&
          netTotal <= maxNetTotal &&
          total - netTotal <= maxIncludedGap
        );
      },
      // are there a maximum number of consecutive days within the items
      // config: [date, maxDays = 6]
      $maximumConsecutiveDays: function (
        config: unknown,
        items: SiftItemType[],
      ) {
        const date =
          Array.isArray(config) && config.length
            ? new Date(config[0])
            : new Date();
        const maxDays = Array.isArray(config) && config.length ? config[1] : 6;

        const dateOffsets: Set<number> = new Set();

        items.forEach((item) => {
          const itemDate = integerToDate(item.marker[0]);
          const diffInDays = differenceInCalendarDays(itemDate, date);

          dateOffsets.add(diffInDays);
        });

        let consecutiveDays = 1;
        let priorDay = -1;
        while (dateOffsets.has(priorDay)) {
          consecutiveDays++;
          priorDay--;
        }

        let laterDay = 1;
        while (dateOffsets.has(laterDay)) {
          consecutiveDays++;
          laterDay++;
        }

        return consecutiveDays <= maxDays;
      },
      // is there a minimum gap between this and the other items during the week
      // config: [startsAt, endsAt, minGapSeconds = 0]
      $minimumGapPerWeek: function (config: unknown, items: SiftItemType[]) {
        const startsAt =
          Array.isArray(config) && config.length
            ? new Date(config[0])
            : new Date();
        const endsAt =
          Array.isArray(config) && config.length
            ? new Date(config[1])
            : new Date();
        const minGap = Array.isArray(config) && config.length ? config[2] : 0;

        const rangesToCheck: number[][] = [
          [
            dateToTzUnix(startOfWeek(startsAt, { weekStartsOn: 1 })),
            dateToTzUnix(endOfWeek(startsAt, { weekStartsOn: 1 })),
          ],
        ];
        // endsAt is in the next week
        // thus both weeks need to be checked
        if (config[1] > rangesToCheck[0][1]) {
          rangesToCheck.push([
            dateToTzUnix(startOfWeek(endsAt, { weekStartsOn: 1 })),
            dateToTzUnix(endOfWeek(endsAt, { weekStartsOn: 1 })),
          ]);
        }

        return rangesToCheck.every((range) => {
          const mergedItems: number[][] = [
            ...items,
            { marker: [dateToTzUnix(startsAt), dateToTzUnix(endsAt)] },
          ]
            // only consider items that overlap with the week
            .filter(
              (item) =>
                item.marker[0] <= range[1] && item.marker[1] >= range[0],
            )
            .sort((a, b) => a.marker[0] - b.marker[0]) // sort by starts_at
            .reduce((combined, next) => {
              // reduce overlapping items
              if (
                combined.length === 0 ||
                combined[combined.length - 1][1] < next.marker[0]
              ) {
                combined.push(next.marker);
              } else {
                const prev = combined.pop();
                combined.push([prev[0], Math.max(prev[1], next.marker[1])]);
              }
              return combined;
            }, []);

          // convert array if worked times to array of gaps
          const gaps = mergedItems.reduce(
            (gaps: number[][], [starts, ends]) => {
              // initial case
              if (gaps.length === 0) {
                // starts prior to week -> hard cut to week start
                if (starts <= range[0]) {
                  gaps = [[ends]];
                  // starts in week -> add gap from week start to starts
                } else {
                  gaps = [[range[0], starts], [ends]];
                }
              } else {
                gaps[gaps.length - 1].push(starts);

                // ends during week
                if (ends < range[1]) {
                  gaps.push([ends]);
                }
              }

              return gaps;
            },
            [],
          );

          // fill last gap if missing
          if (gaps[gaps.length - 1].length === 1) {
            gaps[gaps.length - 1].push(range[1]);
          }

          debug('$minimumGapPerWeek Results', {
            arguments: config,
            visitedItems: mergedItems,
            gaps: gaps.map((g) => [
              integerToDate(g[0]),
              integerToDate(g[1]),
              g[1] - g[0],
            ]),
            range,
          });

          // check if any gap is larger/eq than minGap
          return gaps.some(([starts, ends]) => ends - starts >= minGap);
        });
      },
    },
  });

export const siftConfig = (baseTemplate, baseConfig: any) => {
  const queryTemplate = Object.assign({}, baseTemplate);
  queryTemplate.queries = [];

  const queryConfig = Object.assign({}, baseConfig);
  const jsonCalculator = new JsonCalculator(queryConfig);

  baseTemplate.queries.forEach((subQuery) => {
    const localConfig = queryConfig[subQuery.field];

    if (
      subQuery.multi &&
      Array.isArray(localConfig) &&
      localConfig.length !== 0
    ) {
      const multiQuery = {};
      const multiChain = (multiQuery[subQuery.chain || '$and'] = []);

      localConfig.forEach((lc, i) => {
        if (Array.isArray(lc) && lc.length === 0) {
          return;
        }
        if (typeof lc === 'object' && !lc.valid) {
          return;
        }

        queryConfig[`${subQuery.field}${i}`] = lc;

        let queryAsString = JSON.stringify(subQuery.query);
        queryAsString = queryAsString
          .split(`${subQuery.field}[]`)
          .join(`${subQuery.field}${i}`);

        multiChain.push(JSON.parse(queryAsString));
      });

      queryTemplate.queries.push({
        field: subQuery.field,
        query: multiQuery,
      });
    } else {
      queryTemplate.queries.push(subQuery);
    }
  });

  const allQueries = {};
  const chain = (allQueries[queryTemplate['chain'] || '$and'] = []);

  queryTemplate.queries.forEach((query) => {
    const localConfig = queryConfig[query.field];

    // DO NOT add query if the form value is undefined/null/false/NaN or empty string
    // DO add query if the value is 0
    if (!localConfig && localConfig !== 0) {
      return;
    }

    // DO NOT add query if the form value is an empty array
    if (Array.isArray(localConfig) && localConfig.length === 0) {
      return;
    }

    // DO NOT add query if the form value is an object which is not set as valid
    if (
      !Array.isArray(localConfig) &&
      typeof localConfig === 'object' &&
      !localConfig.valid
    ) {
      return;
    }

    // DO NOT add query if dependency fields are not set to the exp. value
    const dependencies = query.dependencies || [];
    if (
      dependencies.some((deps) => {
        const [depField, depVal] = deps.split('.');

        return queryConfig[depField] !== depVal;
      })
    ) {
      return;
    }

    if (query.query) {
      chain.push(query.query);
    }
  });

  return jsonCalculator.evaluateStructure(
    renderPartial(allQueries, queryConfig),
  );
};

export const siftConfigUnchained = (baseQueries, baseConfig: any) => {
  const queries = [];

  const queryConfig = Object.assign({}, baseConfig);
  const jsonCalculator = new JsonCalculator(queryConfig);

  baseQueries.forEach((subQuery) => {
    const localConfig = subQuery.field
      ? dig(queryConfig, subQuery.field)
      : undefined;

    if (
      subQuery.multi &&
      Array.isArray(localConfig) &&
      localConfig.length !== 0
    ) {
      const multiQuery = {};
      const multiChain = (multiQuery[subQuery.chain || '$and'] = []);

      localConfig.forEach((lc, i) => {
        if (Array.isArray(lc) && lc.length === 0) {
          return;
        }
        if (typeof lc === 'object' && !lc.valid) {
          return;
        }

        // queryConfig[`${subQuery.field}${i}`] = lc;

        let queryAsString = JSON.stringify(subQuery.query);
        queryAsString = queryAsString
          .split(`${subQuery.field}[]`)
          .join(`${subQuery.field}.${i}`);

        multiChain.push(JSON.parse(queryAsString));
      });

      queries.push(Object.assign({}, subQuery, { query: multiQuery }));
    } else {
      queries.push(subQuery);
    }
  });

  return queries.map((query) => {
    return Object.assign({}, query, {
      query: jsonCalculator.evaluateStructure(
        renderPartial(query.query, queryConfig),
      ),
    });
  });
};
