import { Deserializer, ExclusionPolicy, Strategy, Type } from 'typeserializer';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { dateToTzUnix, parseDate } from '@timecount/utils';
import { TcFeedbackRequestType } from '@timecount/core';

import { FrameCollection } from '../../core/frame-collection';
import { Cable } from '../../core/cable';
import { StoreService } from '../../core/store.service';
import { QualificationCollection } from '../../core/collections/qualification';
import { generateDateRanges } from '../../core/date_ranges';
import { ApiErrorService } from '../../core/api-error.service';
import { CurrentUserService } from '../../core/current-user.service';
import { Plan } from '../../dispo/collections/plan';
import { Job, JobCollection } from '../../jobs/job.collection';
import { DispoFocusService } from '../dispo-focus.service';
import { LocalSettingsService } from '../../core/local-settings.service';

import { DispoShift, DispoShiftCollection } from './shift';
import { Slot } from './task';
import { DispoRoleCollection } from './role';
import { DispoGroupCollection } from './group';

export const DAYS_VALUES = ['0', '1', '2', '3', '4', '5', '6', 'FA', 'FW'];

export class ScheduleTaskTemplate {
  title: string;
  description: string;

  size: number;
  job_id: number;
  venue_id?: number;

  offset: number;
  length: number;
  intermission_offset?: number;
  intermission_length?: number;

  state: string;
  tracking_enabled: boolean;
  push_notifications: boolean;
  response_requirements: { [key in TcFeedbackRequestType]: boolean };
}

@Strategy(ExclusionPolicy.NONE)
export class Schedule {
  id: number;
  plan_id: number;
  title: string;
  description: string;
  note: string;

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;

  net_duration: number;
  gross_duration: number;

  shift?: DispoShift;
  job: Job;

  marker: [number, number];

  slots_config: Slot[];
  @Type(Object)
  slots_matrix: any;

  @Type(Object)
  days_matrix: any;

  template: ScheduleTaskTemplate;

  @Type(Object)
  store: any;
}
@Injectable({
  providedIn: 'root',
})
export class DispoScheduleCollection extends FrameCollection {
  type = Schedule;
  cache = 600;
  identifier = 'tc_dispo/schedule';
  endpoint = '/api/dispo/schedules/range';

  remoteDeleteValidation = true;

  constructor(
    http: HttpClient,
    cable: Cable,
    dispoFocus: DispoFocusService,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    jobCollection: JobCollection,
    qualificationCollection: QualificationCollection,
    dispoGroupCollection: DispoGroupCollection,
    dispoRoleCollection: DispoRoleCollection,
    dispoShiftCollection: DispoShiftCollection,
    localSettingsService: LocalSettingsService,
  ) {
    super(
      http,
      cable,
      dispoFocus,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
    );

    this.decorators = [
      dispoShiftCollection.all(),
      jobCollection.all(),
      qualificationCollection.all(),
      dispoGroupCollection.all(),
      dispoRoleCollection.all(),
    ];
  }

  decorate(schedule: Schedule, [shifts, jobs, qualifications, groups, roles]) {
    (schedule.slots_config || []).forEach((slot) => {
      slot.job = slot.job_id
        ? jobs.find((g) => g.id === slot.job_id)
        : undefined;
      slot.qualifications = qualifications.filter(
        (r) => (slot.qualification_ids || []).indexOf(r.id) !== -1,
      );
      slot.group = slot.group_id
        ? groups.find((g) => g.id === slot.group_id)
        : undefined;
      slot.roles = roles.filter(
        (r) => (slot.role_ids || []).indexOf(r.id) !== -1,
      );
    });

    schedule.job = jobs.find((j) => j.id === schedule.template.job_id);
    schedule.shift = shifts.find(
      (s) =>
        s.offset === schedule.template.offset &&
        s.length === schedule.template.length,
    );

    return schedule;
  }

  forPlan(plan: Plan) {
    const filterFunc = (x) => x.plan_id === plan.id;
    const filterLookup = `plan_id:${plan.id}`;

    return this.filter(filterFunc, filterLookup);
  }

  fromTemplate(date: Date, template) {
    date = new Date(date.getTime());

    const [starts_at, ends_at] = generateDateRanges(
      date,
      template.base || 'week',
      template.offset,
      template.length,
    );

    const obj = Object.assign(
      {
        starts_at: starts_at,
        ends_at: ends_at,
        marker: [dateToTzUnix(starts_at), dateToTzUnix(ends_at)],
      },
      template,
    );

    delete obj.base;
    delete obj.offset;
    delete obj.length;

    return obj;
  }
}
