import { Component, OnInit } from '@angular/core';

import { BaseModalComponent } from '@timecount/ui';

import { Availability } from '../collections/availability';

@Component({
  selector: 'tc-hub-dispo-availability',
  templateUrl: './dispo-availability.component.html',
  styleUrls: ['./dispo-availability.component.scss'],
})
export class DispoAvailabilityComponent
  extends BaseModalComponent
  implements OnInit
{
  availability: Availability;
  tabs = {
    range: 0,
    interval: 1,
  };

  ngOnInit() {
    super.ngOnInit();
    this.availability = this.config.data.availability;
  }
}
