import { Injectable } from '@angular/core';

import { SetsCollection } from '../../core/sets-collection';
import { Department } from '../../core/types/department.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentCollection extends SetsCollection {
  identifier = 'department';
  endpoint = '/api/preferences/departments';

  cache = 60 * 60;

  localSearch = ['title', 'description'];

  type = Department;
}
