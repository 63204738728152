import { Inject, Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeeInfoColumnsService {
  columns$ = from(this.employeeInfoColumns()).pipe(map(Object.values));

  constructor(@Inject('EmployeeInfoColumns') private employeeInfoColumns) {}
}
