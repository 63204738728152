import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';

@Component({
  selector: 'tc-hub-dispo-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss'],
})
export class DispoFilterFormComponent {
  @ViewChild('menu') menu: NzDropdownMenuComponent;
  @ViewChild('menuDir') menuDir: ElementRef;
  @ViewChild('dispoFilterForm') form: ElementRef;
  @Input() section: string;

  @Input() set filter(value) {
    this._filter = value;
    this.updateForm();
  }
  get filter(): string {
    return this._filter;
  }

  @Input() set schema(value) {
    this._schema = value;
    this.updateForm();
  }
  get schema(): string {
    return this._schema;
  }

  @Output() requestFilter: EventEmitter<any> = new EventEmitter();

  private _filter: any;
  private _schema: any;

  innerValue: any;
  exportValue: any;

  menuVisible = false;

  constructor(private cd: ChangeDetectorRef) {}

  public hide() {
    this.menuVisible = false;
  }

  public toggle() {
    this.menuVisible = !this.menuVisible;

    if (this.menuVisible) {
      setTimeout(() => {
        this.form?.nativeElement.querySelector('input').focus();
      }, 500);
    }
  }

  menuVisibilityChanged(vis) {
    if (this.menuVisible !== vis) {
      this.menuVisible = vis;
    }
  }

  // updates the form with external data
  updateForm() {
    if (JSON.stringify(this.filter) !== JSON.stringify(this.innerValue)) {
      this.innerValue = Object.assign({}, this.filter);
      this.cd.markForCheck();
    }
  }

  applyFilter() {
    this.requestFilter.emit(Object.assign({}, this.exportValue));
    this.hide();
  }

  removeFilters($event) {
    this.requestFilter.emit({});
    this.hide();
  }

  onChange(data) {
    this.exportValue = data.value;
  }

  onKey(e) {
    const code = e.keyCode ? e.keyCode : e.which;

    if (code === 13) {
      this.applyFilter();
    }
  }
}
