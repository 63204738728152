import { Component, OnInit } from '@angular/core';

import { BaseModalComponent } from '@timecount/ui';

import { Invitation } from '../collections/invitation';

@Component({
  selector: 'tc-hub-dispo-invitation',
  templateUrl: './dispo-invitation.component.html',
  styleUrls: ['./dispo-invitation.component.css'],
})
export class DispoInvitationComponent
  extends BaseModalComponent
  implements OnInit
{
  invitations: Invitation[] = [];

  ngOnInit() {
    super.ngOnInit();
    this.invitations = this.config.data.invitations;
  }
}
