import { Injectable } from '@angular/core';

import { SetsCollection } from '../../core/sets-collection';

export class DispoRole {
  id: number;
  visibiltiy: number;
  title: string;
  icon: string;
  color: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class DispoRoleCollection extends SetsCollection {
  identifier = 'tc_dispo/settings/role';
  endpoint = '/api/dispo/settings/roles';

  localSearch = ['title'];

  cache = 60 * 60 * 12;
  type = DispoRole;

  visible() {
    return this.query({ 'filter[visibility]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visibility]': '0' }, (e) => e.visibility === 0);
  }
}
