<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-input-text-web
              label="{{ 'dispo/plan.attrs.title' | translate }}"
              formControlName="title"
            ></tc-input-text-web>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-collection-select
              label="{{ 'dispo/plan.attrs.project' | translate }}"
              [collectionTypes]="['Project']"
              [formGroup]="form"
              controlName="project_id"
              bindLabel="label"
              bindValue="id"
              [searchable]="true"
            >
            </tc-hub-collection-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <tc-interval-web
            formControlName="dateInterval"
            [tcFieldType]="'date'"
            [tcConfig]="{ limit: dateLimit, fieldType: 'date' }"
            [tcLabels]="{
              start: ('dispo/plan.attrs.starts_at' | translate),
              end: ('dispo/plan.attrs.ends_at' | translate)
            }"
            [tcAllowZeroDuration]="true"
          ></tc-interval-web>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <tc-input-color-web
            label="{{ 'dispo/plan.attrs.color' | translate }}"
            formControlName="color"
          ></tc-input-color-web>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">
              {{ 'dispo/plan.attrs.note' | translate }}
              <textarea
                tcFormControlValidator
                class="form-control"
                rows="5"
                name="note"
                formControlName="note"
                autosize
              >
              </textarea>
            </label>
          </div>
        </div>
      </div>

      <tc-hub-custom-fields
        section="plan.general"
        [formGroup]="form"
        formControlName="store"
      >
      </tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
