<tc-form-wrapper
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  [shouldShowPanel]="false"
  (primaryButtonClick)="save()"
>
  <form [formGroup]="formGroup">
    <fieldset [tcPanel] [tcTitle]="'aggregation.headers.general' | translate">
      <tc-input-text-web
        label="{{ 'aggregation.attrs.title' | translate }}"
        formControlName="title"
      ></tc-input-text-web>

      <tc-input-text-web
        label="{{ 'aggregation.attrs.type_id' | translate }}"
        formControlName="typeTitle"
      ></tc-input-text-web>

      <tc-input-text-web
        *ngIf="formGroup.controls['customerName']"
        label="{{ 'aggregation.attrs.customer_id' | translate }}"
        formControlName="customerName"
      ></tc-input-text-web>

      <tc-input-text-web
        *ngIf="formGroup.controls['tariffTitle']"
        label="{{ 'aggregation.attrs.tariff_id' | translate }}"
        formControlName="tariffTitle"
      ></tc-input-text-web>

      <tc-input-text-web
        *ngIf="formGroup.controls['payGradeTitle']"
        label="{{ 'aggregation.attrs.pay_grade_id' | translate }}"
        formControlName="payGradeTitle"
      ></tc-input-text-web>
    </fieldset>

    <tc-hub-custom-fields
      section="aggregation.{{ tcInitialValue.type.handle }}.general"
      formControlName="aggregationHandleGeneral"
    >
    </tc-hub-custom-fields>

    <tc-hub-custom-fields
      section="aggregation.general"
      formControlName="aggregationGeneral"
    >
    </tc-hub-custom-fields>
  </form>
</tc-form-wrapper>
