<div class="modal-container">
  <div class="modal-body">
    <tc-hub-tc-transfer
      [resources$]="resources$"
      [resourcesDS$]="resourcesDS$"
      [selected]="employeeRecipients"
      [section]="section"
      (tcSelect)="onSelect($event)"
    ></tc-hub-tc-transfer>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [disabled]="loading"
      (click)="prev()"
    >
      {{ 'back' | translate }}
    </button>
    <button
      *ngIf="estimatedSmsCount >= 50"
      type="button"
      nz-button
      nzType="primary"
      nzDanger
      [nzLoading]="loading"
      (click)="send()"
      nz-tooltip
      nzTooltipTitle="{{
        'dispo/message.warnings.sms_count'
          | translate: { count: estimatedSmsCount }
      }}"
    >
      <i nz-icon nzType="mail" nzTheme="outline"></i>
      {{ 'send' | translate }}
    </button>
    <button
      *ngIf="estimatedSmsCount < 50"
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      (click)="send()"
    >
      <i nz-icon nzType="mail" nzTheme="outline"></i>
      {{ 'send' | translate }}
    </button>
  </div>
</div>
