<ng-container *ngIf="visible$ | async">
  <div [style.backgroundColor]="plan.color" class="wrapper">
    <tc-hub-data-panel
      [data]="plan$ | async"
      section="dispo.tasks.plan"
      class="plan-stats"
    ></tc-hub-data-panel>
    <div class="header">
      <div class="title">
        <tc-hub-toggle
          class="content-toggle"
          [active$]="expanded$"
          (mouseup)="onTogglePlan($event)"
        ></tc-hub-toggle>
        <h4>{{ plan.title }}</h4>
      </div>
    </div>
    <div #content class="content" [class.collapsed]="(expanded$ | async) === false">
      <div class="note" *ngIf="plan.note">
        <tc-hub-markdown
          [data]="plan.note"
          (update)="onMarkdownUpdate($event)"
        ></tc-hub-markdown>
      </div>
      <div class="tasks">
        <ng-container *ngIf="expanded$ | async">
          <ng-container *ngFor="let task of tasks$ | async; trackBy: trackById">
            <tc-hub-dt-task
              #taskComponent
              [date]="date"
              [task]="task"
              [attr.data-taskid]="task.id"
              (select)="onSelect($event)"
              tcHubDispoMenuActive
              activeType="DispoTask"
              [activeId]="task.id"
            >
            </tc-hub-dt-task>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(tasks$ | async).length === 0">
          <ng-container *tcHubHide="'views.dispo.task.create.guided'">
            <button
              class="add-button"
              nz-button
              nzSize=""
              (click)="createTask()"
            >
              <i nz-icon nzType="group"></i>
              {{ 'dispo/task.actions.create' | translate }}
            </button>
          </ng-container>
          <ng-container *tcHubHide="'views.dispo.schedule.create.guided'">
            <button
              class="add-button"
              nz-button
              nzSize=""
              (click)="createSchedule()"
            >
              <i nz-icon nzType="calendar"></i>
              {{ 'dispo/schedule.actions.create' | translate }}
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
