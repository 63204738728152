import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  TcFieldSetIntervalSetService,
  TcTimesSummaryService,
} from '@timecount/ui';

import { SetsCollection } from '../core/sets-collection';
import { ApiErrorService } from '../core/api-error.service';
import { Cable } from '../core/cable';
import { UserCollection } from '../core/collections/user';
import { CurrentUserService } from '../core/current-user.service';
import { StoreService } from '../core/store.service';
import { TariffCollection } from '../tariffs/tariff.collection';
import { PayGradeCollection } from '../tariffs/pay-grade.collection';
import { LocalSettingsService } from '../core/local-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectTimesheetCollection extends SetsCollection {
  cache = 600;
  identifier = 'timesheet';
  endpoint = '/api/projects/:project_id/timesheets';

  remoteDeleteValidation = true;
  refreshOnStale = false;

  constructor(
    http: HttpClient,
    cable: Cable,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    userCollection: UserCollection,
    protected localSettingsService: LocalSettingsService,
    @Inject('Flash') flash,
    tariffCollection: TariffCollection,
    payGradeCollection: PayGradeCollection,
    private summaryService: TcTimesSummaryService,
    private intervalSetService: TcFieldSetIntervalSetService,
  ) {
    super(
      http,
      cable,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
      flash,
    );

    this.decorators = [
      tariffCollection.visible().all(),
      payGradeCollection.visible().all(),
    ];
  }

  decorate(obj, [tariffs, payGrades]) {
    obj.tariff = tariffs.find((t) => t.id === obj.tariff_id);
    obj.pay_grade = payGrades.find((t) => t.id === obj.pay_grade_id);
    obj.summary = this.summaryService.getSummary(
      this.intervalSetService.parseFromApi(obj),
    );

    return obj;
  }

  forProject(project_id) {
    return this.query(
      {
        project_id: project_id,
      },
      (t) => {
        return t.project_id === project_id;
      },
    );
  }
}
