import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';

import { Contractor } from './contractor.model';

@Injectable({
  providedIn: 'root',
})
export class ContractorCollection extends SetsCollection {
  identifier = 'contractor';
  endpoint = '/api/contractors';

  cache = 60 * 60;

  localSearch = ['title'];

  decorate(contractor: Contractor): Contractor {
    contractor.label = contractor.name;
    return contractor;
  }

  visible() {
    return this.query({ 'filter[visibility]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visibility]': '0' }, (e) => e.visibility === 0);
  }
}
