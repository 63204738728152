import { Component, OnInit } from '@angular/core';

import { BaseModalComponent } from '@timecount/ui';

import { Plan } from '../collections/plan';

export class DispoImport {
  begin: Date;
  end: Date;

  aggregation_id: number;
  project_id: number;
  projectChangeable = true;

  plan?: Plan;
  plan_id?: number;

  target = 'direct';

  entries: any[];

  template: any;
}

@Component({
  selector: 'tc-hub-dispo-import',
  templateUrl: './dispo-import.component.html',
  styleUrls: ['./dispo-import.component.scss'],
})
export class DispoImportComponent extends BaseModalComponent implements OnInit {
  currentStep = 0;
  import: DispoImport;

  ngOnInit() {
    super.ngOnInit();
    this.import = this.config.data.import;
  }

  onSignal(signal) {
    if (signal.action === 'next') {
      this.import = signal.import;
      this.next();
    }

    if (signal.action === 'prev') {
      this.import = signal.import;
      this.prev();
    }

    super.onSignal(signal);
  }

  prev(): void {
    this.currentStep -= 1;
  }

  next(): void {
    this.currentStep += 1;
  }
}
