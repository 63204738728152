import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { dateToString } from '../../core/helpers';
import { DispoAnnouncementCollection } from '../../dispo/collections/announcement';
import { DispoCombinedTaskCollection } from '../../dispo/collections/combined_task';
import { DispoPlanCollection } from '../../dispo/collections/plan';
import { DispoScheduleCollection } from '../../dispo/collections/schedule';
import { DispoFocusService } from '../../dispo/dispo-focus.service';
import { LocalSettingsService } from '../../core/local-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ToggleDispoResourcesService {
  translations: { [key: string]: string } = {};

  constructor(
    private localSettings: LocalSettingsService,
    private taskCollection: DispoCombinedTaskCollection,
    private planCollection: DispoPlanCollection,
    private scheduleCollection: DispoScheduleCollection,
    private announcementCollection: DispoAnnouncementCollection,
    private dispoFocusService: DispoFocusService,
    private translateService: TranslateService,
  ) {
    this.translateService
      .get([
        'dispo/task.plural',
        'dispo/plan.plural',
        'dispo/schedule.plural',
        'dispo/announcement.plural',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  getCurrentDispoResources(dispoViews): unknown[] {
    let items = [];

    if (dispoViews === 'dispo.tasks') {
      items = [
        {
          title: this.translations['dispo/plan.plural'],
          keys: combineLatest([
            this.planCollection.all(),
            this.dispoFocusService.dates(),
          ]).pipe(
            map(([plans, dates]) =>
              dates
                .map((date) => {
                  return plans.map(
                    (plan) =>
                      `dispo.planExpanded.${plan.id}|${dateToString(date)}`,
                  );
                })
                .flat(),
            ),
          ),
        },
        {
          title: this.translations['dispo/task.plural'],
          keys: this.taskCollection
            .all()
            .pipe(
              map((tasks) =>
                tasks.map((task) => `dispo.taskExpanded.${task.id}`),
              ),
            ),
        },
      ];
    } else if (dispoViews === 'dispo.schedules') {
      items = [
        {
          title: this.translations['dispo/plan.plural'],
          keys: this.planCollection
            .all()
            .pipe(
              map((plans) =>
                plans.map((plan) => `dispo.planExpanded.${plan.id}`),
              ),
            ),
        },
        {
          title: this.translations['dispo/schedule.plural'],
          keys: this.scheduleCollection
            .all()
            .pipe(
              map((schedules) =>
                schedules.map(
                  (schedule) => `dispo.scheduleExpanded.${schedule.id}`,
                ),
              ),
            ),
        },
      ];
    } else if (dispoViews === 'dispo.announcements') {
      items = [
        {
          title: this.translations['dispo/plan.plural'],
          keys: this.planCollection
            .all()
            .pipe(
              map((plans) =>
                plans.map((plan) => `dispo.planExpanded.${plan.id}`),
              ),
            ),
        },
        {
          title: this.translations['dispo/announcement.plural'],
          keys: this.announcementCollection
            .all()
            .pipe(
              map((announcements) =>
                announcements.map(
                  (announcement) =>
                    `dispo.announcementExpanded.${announcement.id}`,
                ),
              ),
            ),
        },
      ];
    }

    return items;
  }

  expandView(view) {
    view.keys.pipe(first()).subscribe((keys) => {
      keys.map((key) => {
        this.localSettings.toggleWithValue(key, true);
      });
    });
  }

  condenseView(view) {
    view.keys.pipe(first()).subscribe((keys) => {
      keys.map((key) => {
        this.localSettings.toggleWithValue(key, false);
      });
    });
  }
}
