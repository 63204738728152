import { ExclusionPolicy, Strategy } from 'typeserializer';
import { Injectable } from '@angular/core';
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfQuarter,
  startOfYear,
} from 'date-fns';

import { dateToTzUnix } from '@timecount/utils';

import { FrameCollection } from '../../core/frame-collection';
import { SetsCollection } from '../../core/sets-collection';
import { dateToString } from '../../core/helpers';
import { ComparableStat } from '../../timebalances/comparable-stat.model';

@Strategy(ExclusionPolicy.NONE)
export class AssignmentStats extends ComparableStat {
  planned_hours: number;
  planned_gross_hours;
  total_planned_hours;
  total_planned_gross_hours;

  tracked_hours: number;
  tracked_gross_hours: number;
  total_tracked_hours: number;
  total_tracked_gross_hours: number;

  assigned_count: number;
  assigned_distinct_count: number;
  assigned_count_sunday: number;
  assigned_count_saturday: number;
  assigned_count_mofr: number;
}

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentMonthStatCollection extends FrameCollection {
  type = AssignmentStats;
  step = 60 * 60 * 24 * 60;
  refresh = 300;
  identifier = 'tc_dispo/assignment_stats_month';
  endpoint = '/api/dispo/assignments/range';
  query = {
    stats: 'month',
  };
}

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentQuarterStatCollection extends SetsCollection {
  type = AssignmentStats;
  step = 60 * 60 * 24 * 60;
  refresh = 300;
  identifier = 'tc_dispo/assignment_stats_quarter';
  endpoint = '/api/dispo/assignments/range';

  forDate(date) {
    const compDate = dateToString(startOfQuarter(date));
    const begin = dateToTzUnix(startOfQuarter(date));
    const end = dateToTzUnix(endOfQuarter(date)) - 86400;
    return this.query(
      {
        begin: begin,
        end: end,
        stats: 'quarter',
      },
      (a) => {
        return a.month === compDate;
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentPartialQuarterStatCollection extends SetsCollection {
  type = AssignmentStats;
  refresh = 300;
  identifier = 'tc_dispo/assignment_stats_partial_quarter';
  endpoint = '/api/dispo/assignments/range';

  forDate(date) {
    const compDate = dateToString(startOfQuarter(date));
    const begin = dateToTzUnix(startOfQuarter(date));
    const end = dateToTzUnix(endOfMonth(date)) - 86400;

    return this.query(
      {
        begin: begin,
        end: end,
        stats: 'partial_quarter',
      },
      (a) => {
        return a.month === compDate;
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentYearStatCollection extends SetsCollection {
  type = AssignmentStats;
  step = 60 * 60 * 24 * 150;
  refresh = 300;
  identifier = 'tc_dispo/assignment_stats_year';
  endpoint = '/api/dispo/assignments/range';

  forDate(date) {
    const compDate = dateToString(startOfYear(date));
    const begin = dateToTzUnix(startOfYear(date));
    const end = dateToTzUnix(endOfYear(date)) - 86400;
    return this.query(
      {
        begin: begin,
        end: end,
        stats: 'year',
      },
      (a) => {
        return a.month === compDate;
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentPartialYearStatCollection extends SetsCollection {
  type = AssignmentStats;
  refresh = 300;
  identifier = 'tc_dispo/assignment_stats_partial_year';
  endpoint = '/api/dispo/assignments/range';

  forDate(date) {
    const compDate = dateToString(startOfYear(date));
    const begin = dateToTzUnix(startOfYear(date));
    const end = dateToTzUnix(endOfMonth(date)) - 86400;
    return this.query(
      {
        begin: begin,
        end: end,
        stats: 'partial_year',
      },
      (a) => {
        return a.month === compDate;
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentDayStatCollection extends FrameCollection {
  type = AssignmentStats;
  refresh = 300;
  identifier = 'tc_dispo/assignment_stats_day';
  endpoint = '/api/dispo/assignments/range';
  query = {
    stats: 'day',
  };
}

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentWeekStatCollection extends FrameCollection {
  type = AssignmentStats;
  step = 60 * 60 * 24 * 30;
  refresh = 300;
  identifier = 'tc_dispo/assignment_stats_day';
  endpoint = '/api/dispo/assignments/range';
  query = {
    stats: 'week',
  };
}
