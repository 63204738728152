import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { startOfDay } from 'date-fns';

import { isValidDate } from '@timecount/utils';

import { TcInputConfig } from './input.config';
import { TcInputDateTimeConfig } from './input-datetime.config';
import { TcInputValidators } from './input-validators';

@Injectable({ providedIn: 'root' })
export class TcInputService {
  getInputValidators(
    config: TcInputDateTimeConfig | TcInputConfig,
    extraValidators: ValidatorFn[] = [],
  ): ValidatorFn[] {
    if (config.noValidators) {
      return [];
    }

    const { fieldType, required } = config;
    const validators = [...extraValidators];

    if (required) {
      validators.push(Validators.required);
    }

    if ((config as TcInputDateTimeConfig).limit) {
      validators.push(TcInputValidators.limit(config as TcInputDateTimeConfig));
    }

    const inputValidator = TcInputValidators[fieldType];

    if (inputValidator) {
      validators.push(
        fieldType === 'email' ? inputValidator(config) : inputValidator,
      );
    }

    return validators;
  }

  getDatetimeFormControl(
    config: TcInputDateTimeConfig,
    extraValidators: ValidatorFn[] = [],
  ): AbstractControl {
    const { initialValue, noValidators, disabled, fieldType } = config;

    const value = isValidDate(initialValue)
      ? fieldType === 'date'
        ? startOfDay(initialValue)
        : initialValue
      : undefined;

    const formControl = new UntypedFormControl({ value, disabled });

    if (!noValidators) {
      formControl.setValidators(
        this.getInputValidators(config, extraValidators),
      );
    }

    return formControl;
  }
}
