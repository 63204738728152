import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightZeroLabel',
})
export class HighlightZeroLabelPipe implements PipeTransform {
  transform(number: number, color: 'danger' = 'danger'): string {
    if (isNaN(number) || number === 0) {
      return `label label-${color}`;
    }
    return '';
  }
}
