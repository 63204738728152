import { IntervalSummary } from '@timecount/core';

import { Contractor } from '../../../contractors/contractor.model';
import { Material } from '../../../core/types/material.model';
import { Employee } from '../../../employees/employee.model';
import { Job } from '../../../jobs/job.collection';
import { EntryBillingState } from '../../entry-billing-state.model';
import { ZoneCost } from '../../zone-cost.model';

export class WorkEntry {
  id: number;
  identifier = 'work_entry';
  shallow = false;

  aggregation_id: number;
  aggregation_type_id: number;
  project_id: number;

  main: boolean;
  in_main: boolean;
  locked: boolean;
  billing_state: EntryBillingState;

  invoice_id?: number;
  invoice_token?: string;

  count: number;
  title: string;
  unit?: string;
  gratis: boolean;
  group_id?: number;
  price?: number;
  total?: number;
  venue_id?: number;

  rate_type?: string;
  rate_id?: number;

  source_id?: number;
  source_type?:
    | 'Timesheet'
    | 'WorkEntry'
    | 'DispoAssignment'
    | 'DispoTask'
    | 'DispoSchedule'
    | 'DispoAvailability';

  zones?: ZoneCost[] = [];

  sku_id?: number;
  sku_type?: string;

  resource_id?: number;
  resource_type?: string;

  role_ids?: number[];
  roles?: unknown[];

  marker: [number, number];

  starts_at: Date;
  ends_at: Date;
  intermission_starts_at: Date;
  intermission_ends_at: Date;

  times: { [key: string]: string }[];

  summary?: IntervalSummary;

  duration?: string;
  description?: string;
  qualification_ids?: number[] = [];
  qualifications?: unknown[] = [];

  sku?: Job | Material;

  store?: Record<string, unknown>;

  resource?: Employee | Contractor;

  group?: Record<string, unknown>;

  venue?: Record<string, unknown>;
}
