import { NgModule } from '@angular/core';

import { TcButtonModule } from '../button/button.module';
import { SharedModule } from '../../shared/shared.module';

import { TcDateTimePickerIonicComponent } from './date-time-picker-ionic/date-time-picker-ionic.component';

@NgModule({
  declarations: [TcDateTimePickerIonicComponent],
  exports: [TcDateTimePickerIonicComponent],
  imports: [SharedModule, TcButtonModule],
})
export class TcDateTimePickerModule {}
