<tc-form-wrapper
  *ngIf="smtpEnabled$ | async"
  [tcFormTitle]="'account/preference.legends.smtp_custom_headers' | translate"
  [isModal]="false"
  [isSubmitting]="false"
  [isLoading]="targetformGroups.size === 0"
>
  <div *ngIf="targetformGroups.size > 0" class="tc-grid">
    <ul nz-menu nzMode="inline" [nzInlineIndent]="8" class="tc-col-4">
      <li
        nz-menu-item
        *ngFor="let target of targets; let i = index"
        [nzSelected]="selectedTarget === target"
        (click)="selectedTarget = target"
      >
        <span>{{
          'account/preference.smtp_targets.' + target | translate
        }}</span>
        <i
          nz-icon
          [nzType]="targetformGroups.get(target).value.id ? 'edit' : 'plus'"
          nzTheme="outline"
        ></i>
      </li>
    </ul>
    <form
      class="tc-col-8"
      *ngIf="selectedTarget"
      [formGroup]="targetformGroups.get(selectedTarget)"
    >
      <!-- Note: Using `[formControl]` instead of `formControlName` due to an error where Angular
        could not find the `FormControl` instance with name 'emailHeaders'. -->
      <tc-hub-email-headers-fieldset
        [shouldShowPanel]="false"
        [formControl]="targetformGroups.get(selectedTarget).get('emailHeaders')"
      ></tc-hub-email-headers-fieldset>

      <div class="buttons-wrapper">
        <button
          type="button"
          nz-button
          nzType="primary"
          [disabled]="
            targetformGroups.get(selectedTarget).pristine ||
            targetformGroups.get(selectedTarget).invalid
          "
          (click)="submit(selectedTarget)"
        >
          {{
            (targetformGroups.get(selectedTarget).value.id ? 'update' : 'save')
              | translate
          }}
        </button>
        <button
          type="button"
          nz-button
          nzType="primary"
          (click)="delete(selectedTarget)"
          [disabled]="!targetformGroups.get(selectedTarget).value.id"
        >
          {{ 'delete' | translate }}
        </button>
      </div>
    </form>
  </div>
</tc-form-wrapper>
