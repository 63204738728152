import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'tc-hub-tbdoc-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class TBDOCStep1Component implements OnInit, OnDestroy {
  @Input() document: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public release = true;

  private destroyed$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.document);
    this.signal.emit({ action: 'resize' });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggle() {
    this.release = !this.release;
    this.signal.emit({ action: 'resize' });
  }

  public valid() {
    return this.release ? this.form.valid : true;
  }

  next() {
    const { store, ...releaseSettings } = this.form.value;
    const document = Object.assign({}, this.document);
    document.store = store;

    if (this.release) document.release = releaseSettings;
    else {
      document.release = undefined;
    }

    this.signal.emit({ document: document, action: 'next' });
  }

  private buildForm(document) {
    const { attach_file, subject, body } = document?.release ?? {};
    return this.formBuilder.group({
      attach_file: [attach_file],
      subject: [subject, [Validators.required]],
      body: [body, [Validators.required]],
      message_template_id: [undefined],
      store: [{}],
    });
  }
}
