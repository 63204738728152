import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Deserializer,
  ExclusionPolicy,
  Strategy,
  Type,
} from 'typeserializer/dist';

import { parseDate } from '@timecount/utils';

import { Role } from '../core/types/account-roles.model';
import { AccountRoleCollection } from '../core/collections/account-roles-collection';
import { User } from '../shared/models';
import { ApiErrorService } from '../core/api-error.service';
import { Cable } from '../core/cable';
import { CurrentUserService } from '../core/current-user.service';
import { SetsCollection } from '../core/sets-collection';
import { StoreService } from '../core/store.service';
import { UserCollection } from '../core/collections/user';
import { LocalSettingsService } from '../core/local-settings.service';

export enum PinnableType {
  'Employee' = 'Employee',
  'Customer' = 'Customer',
  'Project' = 'Project',
  'Invoice' = 'Invoice',
}

export enum PinnableLevel {
  'info' = 'info',
  'warning' = 'warning',
  'error' = 'error',
}

export enum PinnableState {
  'backlog' = 'backlog',
  'inbox' = 'inbox',
  'progress' = 'progress',
  'complete' = 'complete',
}

@Strategy(ExclusionPolicy.NONE)
export class Pin {
  id: number;
  level: PinnableLevel;
  state: PinnableState;

  pinnable_type: PinnableType;
  pinnable_id: number;

  user_ids: number[] = [];
  user_group_ids: number[] = [];

  users: User[] = [];
  roles: Role[] = [];

  title: string;
  url: string;
  note: string;

  @Deserializer((m: string): Date => parseDate(m))
  visible_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  expires_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  created_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  updated_at: Date;

  @Type(Object)
  store: object;
}

@Injectable({
  providedIn: 'root',
})
export class PinCollection extends SetsCollection {
  identifier = 'pin';
  endpoint = '/api/pins';

  cache = 0;

  localSearch = ['title', 'note'];

  type = Pin;

  constructor(
    http: HttpClient,
    cable: Cable,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    userCollection: UserCollection,
    accountRoleCollection: AccountRoleCollection,
    protected localSettingsService: LocalSettingsService,
    @Inject('Flash') flash,
  ) {
    super(
      http,
      cable,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
      flash,
    );

    this.decorators = [userCollection.all(), accountRoleCollection.all()];
  }

  forType(type: PinnableType) {
    return this.query(
      { filter: { pinnable_type: type } },
      (e) => e.pinnable_type === type,
    );
  }

  decorate(obj, [users, roles]) {
    obj.users = users.filter((u) => obj.user_ids.includes(u.id));
    obj.roles = roles.filter((r) => obj.user_group_ids.includes(r.id));

    return obj;
  }
}
