import { Deserializer, ExclusionPolicy, Strategy } from 'typeserializer';
import { Injectable } from '@angular/core';

import { parseDate } from '@timecount/utils';

import { FrameCollection } from '../frame-collection';

@Strategy(ExclusionPolicy.NONE)
export class Holiday {
  id: number;
  tariff_ids: number[];
  title: string;

  @Deserializer((m: string): Date => parseDate(m))
  date: Date;
}
@Injectable({
  providedIn: 'root',
})
export class TariffHolidayCollection extends FrameCollection {
  type = Holiday;
  identifier = 'tariffs/holiday';
  endpoint = '/api/tariffs/holidays/range';
  cache = 0;
}
