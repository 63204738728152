import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'date-fns';

import { hasZeroDuration, isDurationType } from './interval-helpers';

/**
 * Transforms a Duration object or a number value in minutes to a human readable
 * string.
 */
@Pipe({
  name: 'duration',
})
@Injectable({ providedIn: 'root' })
export class DurationPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (
      (!value && value !== 0) ||
      (!Number.isInteger((value as Duration)[`hours`]) &&
        !Number.isInteger((value as Duration)[`minutes`]) &&
        !Number.isInteger(value))
    ) {
      return value;
    }

    if (
      (value as number) <= 0 ||
      (isDurationType(value) && hasZeroDuration(value))
    ) {
      return '00:00';
    }

    let hours = (value as Duration)?.hours ?? 0;

    let minutes = Number.isInteger(value)
      ? (value as number)
      : (value as Duration)?.minutes ?? 0;

    if (minutes > 59) {
      hours += Math.floor(minutes / 60);
      minutes = minutes % 60;
    }

    return `${this._getZeroLedTime(hours)}:${this._getZeroLedTime(minutes)}`;
  }

  private _getZeroLedTime(time: number): string {
    return time ? (time < 10 ? '0' + time.toString() : time.toString()) : '00';
  }
}
