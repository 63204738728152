import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash-es';

import { ModalService } from '@timecount/ui';

import { ProjectTimesheetCollection, Timesheet } from '../../timesheets';
import { ValidationService } from '../../dispo/collections/validation.service';
import { Action } from '../../core/types/action';
import { EntryType } from '../../core/types/entry-type';
import { ActionType } from '../../core/types/action-type';

import { TcProjectCostsFormComponent } from './project-costs-form/project-costs-form.component';

@Injectable({
  providedIn: 'root',
})
export class ProjectCostsService {
  private translations: { [key: string]: string } = {};
  constructor(
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _validationService: ValidationService,
    private _timesheetCollection: ProjectTimesheetCollection,
  ) {
    this._translateService
      .get(['modal.edit', 'modal.clone'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  openModal(object: Timesheet, action: 'edit' | 'clone') {
    let values;
    if (!Array.isArray(object)) {
      const { id, ...rest } = object;
      values = cloneDeep(rest);
      if (action === 'edit') {
        values.id = id;
      }

      if (action === 'clone') {
        values.source_id = undefined;
        values.source_type = undefined;
      }
    }

    this._modalService.open(TcProjectCostsFormComponent, {
      data: {
        tcInitialValue: values,
        isModal: true,
        tcIsExtended: true,
        useColumns: false,
      },
      modalTitle: this.translations['modal.' + action],
    });
  }

  delete(object: Timesheet) {
    const action = <Action>{
      id: uuid(),
      entry: object,
      entry_type: EntryType.ProjectTimesheet,
      type: ActionType.delete,
      validations: (item, stack) => {
        return this._timesheetCollection
          .forProject(object.project_id)
          .remoteValidations(item, stack, ActionType.delete);
      },
      errors: [],
    };
    this._validationService.run([action], true);
  }
}
