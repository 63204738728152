// TODO: FInd why this import is complaining about cyclic dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { durationInDays, hoursToDuration } from '@timecount/utils';

export interface TcConfigTimesApi {
  multi_breaks: boolean;
  max_times_duration_hours: number;
  max_times_frame_duration_hours: number;
  track_restrictions_enabled?: boolean;
  track_step_minutes?: number;
}

export class TcConfigTimes {
  public allowDoubledWorkingDay?: boolean;

  constructor(
    public allowMultiBreaks?: boolean,
    public maxTotalDuration?: Duration,
    public maxItemDuration?: Duration,
    public restrictTimeTracking?: boolean,
    public timePickerStepMinutes?: number,
  ) {
    /**
     * TODO: Refactor this to already expect the `maxTotalDuration` and
     * `maxItemDuration` properties to be limited if they are day related hours.
     *
     * This refactoring will require logic changes in all places that consume
     * both properties, but will allow the logic to be tested in a single place
     * with the reduced repetition.
     */
    const itemDurationInDays = durationInDays(
      hoursToDuration(maxItemDuration?.hours as number, {
        limitDayRelatedHours: true,
      }),
    );

    const totalDurationInDays = durationInDays(
      hoursToDuration(maxTotalDuration?.hours as number, {
        limitDayRelatedHours: true,
      }),
    );

    this.allowDoubledWorkingDay =
      (!allowMultiBreaks && itemDurationInDays > 0) ||
      (allowMultiBreaks && totalDurationInDays > 0);
  }

  static fromApi({
    multi_breaks,
    max_times_duration_hours,
    max_times_frame_duration_hours,
    track_restrictions_enabled,
    track_step_minutes,
  }: TcConfigTimesApi): TcConfigTimes {
    return new TcConfigTimes(
      multi_breaks,
      // Using 48h by default for compatibility with older back end versions
      { hours: max_times_duration_hours ?? 48 },
      { hours: max_times_frame_duration_hours ?? 48 },
      track_restrictions_enabled,
      track_step_minutes,
    );
  }
}
