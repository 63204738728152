import { Component, Input, OnInit } from '@angular/core';

import { BaseSelectComponent } from '../base-select.component';
import { TcOptionsService } from '../../../core/options.service';
import { TcOption } from '../../../shared';

@Component({
  selector: 'tc-hub-option-select',
  templateUrl: './option-select.component.html',
  styleUrls: [
    '../base-select.component.scss',
    './option-select.component.scss',
  ],
})
export class OptionSelectComponent
  extends BaseSelectComponent
  implements OnInit
{
  @Input() type: string;

  items: TcOption[];

  constructor(private optionsService: TcOptionsService) {
    super();
  }

  ngOnInit() {
    this.items = this.optionsService.options(this.type);
  }
}
