import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[tcFormHighlightedDay]',
})
export class TcDatePickerHighlightedDayDirective implements OnChanges {
  @Input('tcFormHighlightedDay')
  shouldHighlight: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { shouldHighlight } = changes;

    this.renderer.setStyle(
      this.el.nativeElement.parentElement,
      'background-color',
      shouldHighlight.currentValue ? 'lightgray' : 'transparent',
    );
  }
}
