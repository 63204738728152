import { Timesheet } from '../../timesheets';
import { Assignment } from '../collections/assignment.model';
import { Slot, Task } from '../collections/task';
import { Plan } from '../collections/plan';
import { Schedule } from '../collections/schedule';

export class AssignmentDS extends Assignment {
  readonly type = 'DispoAssignment';

  resource: any;
  project_id: number;
  project: any;

  plan_id: number;
  plan: Plan;

  schedule?: Schedule;
  task: Task;

  slot: Slot;
  job: any;
  venue?: any;
  timesheet?: Timesheet;
}
