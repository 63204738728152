import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';

import {
  DISPO_MESSAGE_TEMPLATES,
  DispoMessage,
  DispoMessageCollection,
} from '../../collections/message';

@Component({
  selector: 'tc-hub-dmsg-multi',
  templateUrl: './multi.component.html',
  styleUrls: ['./multi.component.css'],
})
export class DMSGMultiComponent implements OnInit, OnDestroy {
  @Input() messages: DispoMessage[];
  @Input() type: string;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  templateType: string;
  placeholderType: string;
  estimatedSmsCount = 0;
  loading = false;

  destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private messageCollection: DispoMessageCollection,
  ) {}

  ngOnInit(): void {
    this.placeholderType = DISPO_MESSAGE_TEMPLATES[this.type]['placeholder'];
    this.templateType = DISPO_MESSAGE_TEMPLATES[this.type]['template'];
    this.form = this.buildForm(this.messages[0]);

    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$), debounceTime(100))
      .subscribe(() => {
        const formValue = this.form.value;
        const condensedMessage = Object.assign(new DispoMessage(), {
          recipients: [],
          transmission_type: formValue.transmission_type,
          subject: formValue.subject,
          body: formValue.body,
          short_message_enabled: formValue.short_message_enabled,
        });

        this.messages.map((message) => {
          formValue.contact_ids.forEach((contactId) => {
            condensedMessage.recipients.push({
              type: 'Contact',
              id: contactId,
            });
          });
          condensedMessage.recipients.push(...message.recipients);
        });

        this.messageCollection.estimatedSmsCount(condensedMessage).subscribe({
          next: (count) => {
            this.estimatedSmsCount = count;
          },
        });
      });

    this.signal.emit({ action: 'resize' });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  send() {
    this.loading = true;

    const formValue = this.form.value;
    const messages$ = this.messages.map((message) => {
      formValue.contact_ids.forEach((contactId) => {
        if (
          !message.recipients.find(
            (r) => r.type === 'Contact' && r.id === contactId,
          )
        ) {
          message.recipients.push({ type: 'Contact', id: contactId });
        }
      });

      message = Object.assign({}, message, formValue);

      return this.messageCollection.create(message);
    });

    combineLatest(messages$)
      .pipe(first())
      .subscribe({
        next: () => {
          this.loading = false;
          this.signal.emit({ action: 'close' });
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  private buildForm(message: DispoMessage) {
    return this.formBuilder.group({
      contact_ids: [
        message.recipients.filter((t) => t.type === 'Contact').map((r) => r.id),
      ],
      transmission_type: [message.transmission_type, [Validators.required]],
      subject: [message.subject, [Validators.required]],
      body: [message.body, [Validators.required]],
      short_message_enabled: [message.short_message_enabled],
      message_template_id: [undefined],
    });
  }
}
