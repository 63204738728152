import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { TcOptionsService } from '../../../core/options.service';
import { BaseDropdownComponent } from '../base-dropdown/base-dropdown.component';

@Component({
  selector: 'tc-hub-placeholder',
  template: `
    <button nz-button nzType="text" nz-dropdown [nzDropdownMenu]="menu">
      {{ 'directives.placeholder.variable' | translate }}
      <span nz-icon nzType="down"></span>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul class="items" nz-menu>
        <li
          *ngFor="let item of items$ | async"
          [nzDisabled]="item.disabled"
          nz-menu-item
          (click)="itemClick($event, item)"
        >
          {{ item.label }}
        </li>
      </ul>
    </nz-dropdown-menu>
  `,
  styles: [
    `
      .items {
        height: 300px;
        overflow-y: scroll;
      }
    `,
  ],
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        }),
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        }),
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}')),
    ]),
  ],
})
export class PlaceholderComponent
  extends BaseDropdownComponent
  implements OnInit
{
  @Input() formGroup: UntypedFormGroup;
  @Input() target: string;
  @Input() type: string;

  items$: Observable<{ label: string; value: string }[]>;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public router: Router,
    public cd: ChangeDetectorRef,
    private optionsService: TcOptionsService,
    private translateService: TranslateService,
  ) {
    super(el, renderer, router, cd);

    this.translateService
      .get('directives.placeholder.variable')
      .subscribe((value) => {
        this.label = value;
      });
  }

  ngOnInit() {
    // HACK: This is a hack to get the placeholders to show up in the base
    // dropdown template, which requires the items to be async and already
    // translated, while the OptionsService.placeholders() method returns the
    // objects synchronously and with the title as the translation key.
    // TODO: Create a dropdown template (or update the base one) to handle sync
    // data and translation keys.
    this.items$ = of(this.optionsService.placeholders(this.type)).pipe(
      switchMap((items) =>
        this.translateService.get(items.map((item) => item.title)).pipe(
          map((translations) => {
            return Object.values(items).map(({ title, placeholder }) => ({
              label: translations[title],
              value: placeholder,
            }));
          }),
        ),
      ),
    );
  }

  itemClick($event, item) {
    this.add(item.value);
  }

  add(placeholder) {
    this.formGroup.controls[this.target].setValue(
      (this.formGroup.controls[this.target].value || '') + ' ' + placeholder,
    );
  }
}
