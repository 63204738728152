import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RemoteConfig } from '../../core/remote_config.service';

import { DataPanelWidget } from './data-panel-widget/data-panel-widget.model';

@Component({
  selector: 'tc-hub-data-panel',
  templateUrl: './data-panel.component.html',
  styleUrls: ['./data-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPanelComponent implements OnInit {
  @Input() data: any;
  @Input() section: string;
  @Input() actions: (string) => void;

  @HostBinding('class') @Input() size = 'small';

  widgets$: Observable<DataPanelWidget[]>;

  constructor(private remoteConfig: RemoteConfig) {}

  ngOnInit() {
    this.widgets$ = this.remoteConfig.get('widget', this.section).pipe(
      map((widgets: DataPanelWidget[]) => {
        return widgets.sort((a, b) => a.position - b.position);
      }),
    );
  }

  handleAction(action: string) {
    if (action && this.actions) {
      try {
        this.actions(action);
      } catch (error) {
        console.error('Could not execute action', action, error);
      }
    }
  }
}
