export class TasksStat {
  slots = 0;
  slots_missing = 0;
  slots_filled = 0;
  slots_reserve = 0;

  announced = 0;
  announced_rejected = 0;
  announced_accepted = 0;
  announced_cancelled = 0;

  requested = 0;
  requested_confirmed = 0;

  times_tracked = 0;

  hours = 0;
  total_hours = 0;
}
