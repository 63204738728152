<div class="sf-group tc-select">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>
  <div class="sf-controls">
    <div class="sf-elements">
      <tc-hub-schema-collection-select
        [formControl]="control"
        [type]="schema.collection"
        class="sf-element"
      ></tc-hub-schema-collection-select>
    </div>
  </div>
</div>
