<div>
  <div class="header" [class.is-first-of-month]="isFirstOfMonth()">
    <span
      ><strong>{{ date | date: 'E' }}</strong>
      {{ date | date: "d. LLL yyyy - 'KW' w" }}</span
    >
    <tc-hub-data-panel
      [data]="day$ | async"
      section="dispo.tasks.day"
    ></tc-hub-data-panel>
  </div>
  <div class="wrapper">
    <ng-container
      *ngFor="let plan of plans$ | async; let i = index; trackBy: trackById"
    >
      <tc-hub-dt-plan
        #planComponent
        class="plan-item"
        [plan]="plan"
        [date]="date"
        [showEmpty]="showEmpty"
        [attr.data-planid]="plan.id"
        (tcSelect)="onSelect($event)"
        tcHubDispoMenuActive
        activeType="DispoPlanDay"
        [activeMatch]="{ id: plan.id, date: lookupDate }"
      >
      </tc-hub-dt-plan>
    </ng-container>
    <ng-container *tcHubHide="'views.dispo.plan.create.guided'">
      <button
        class="add-button"
        nz-button
        nzSize=""
        nzType="primary"
        (click)="createPlan()"
      >
        <i nz-icon nzType="apartment"></i>
        {{ 'dispo/plan.actions.create' | translate }}
      </button>
    </ng-container>
  </div>
</div>
