import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { TcComponentsModule } from '../../components/components.module';

import { TcFormGroupIntervalIonicComponent } from './form-group-interval-ionic/form-group-interval-ionic.component';

@NgModule({
  declarations: [TcFormGroupIntervalIonicComponent],
  exports: [TcFormGroupIntervalIonicComponent],
  imports: [SharedModule, TcComponentsModule],
})
export class TcFormGroupIntervalModule {}
