import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';

export class TcMissingTranslationHandler implements MissingTranslationHandler {
  static storagePrefix = 'translations';
  private _translations;

  handle(params: MissingTranslationHandlerParams) {
    const { currentLang } = params.translateService;

    if (!this._translations) {
      this._translations = JSON.parse(
        localStorage.getItem(
          `${TcMissingTranslationHandler.storagePrefix}-${currentLang}`,
        ) ?? '{}',
      );
    }

    return (
      this._translations[params.key] ??
      params.interpolateParams?.['fallback'] ??
      params.key
    );
  }
}
