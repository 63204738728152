import { Deserializer, ExclusionPolicy, Strategy } from 'typeserializer';

import { parseDate } from '@timecount/utils';

@Strategy(ExclusionPolicy.NONE)
export class Project {
  id: number;
  customer_id: number;
  tariff_id: number;
  pay_grade_id: number;
  condition_id: number;
  state: number;
  department_id: number;

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;

  title: string;

  token?: string;
  department_ids_additional?: number[];
  equal_pay?: boolean;
  invoice_address_id?: number;
  invoice_contact_ids?: number[];
  token_prefix?: string;
  token_counter?: number;
  token_postfix?: string;
  visibility?: boolean;
  vat_identification?: string;
  debitor_identification?: string;
  pricing_notes?: string;
  planning_notes?: string;
  repeat_interval?: 'week' | 'month' | 'quarter';
  repeat_until?: string;
  repeat_includes?: string[];
  rate_source_enbled?: boolean;
  rate_source_id?: number;
  rate_source_type?: 'BaseRate' | 'Customer' | 'Project';
  billing_type_id?: number;
  billing_gross_duration?: boolean;
  billing_times_target?: boolean;
  store?: Record<string, unknown>;
  xre_settings?: Record<string, unknown>;
}
