import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Deserializer, ExclusionPolicy, Strategy, Type } from 'typeserializer';

import { TcFeedbackRequestType, TcIntervalList } from '@timecount/core';
import { dateToTzUnix, parseDate } from '@timecount/utils';

import { FrameCollection } from '../../core/frame-collection';
import { dateToString, integerToDate } from '../../core/helpers';
import { Cable } from '../../core/cable';
import { StoreService } from '../../core/store.service';
import {
  Qualification,
  QualificationCollection,
} from '../../core/collections/qualification';
import { generateDateRanges } from '../../core/date_ranges';
import { Job, JobCollection } from '../../jobs/job.collection';
import { DispoFocusService } from '../../dispo/dispo-focus.service';
import { ApiErrorService } from '../../core/api-error.service';
import { CurrentUserService } from '../../core/current-user.service';
import { SetsCollection } from '../../core/sets-collection';
import { LocalSettingsService } from '../../core/local-settings.service';

import { Assignment } from './assignment.model';
import { DispoGroupCollection } from './group';
import { DispoRoleCollection } from './role';
import { Schedule } from './schedule';
import { DispoShift, DispoShiftCollection } from './shift';
import { Announcement } from './announcement';
import { Plan } from './plan';

export class SlotPos {
  x: number; // dateInt
  y: number; // slotInt

  task_id?: number;
  schedule_id?: number;

  // not relevant but useful (?)
  plan_id?: number;
  assignment_id?: number;
  announcement_id?: number;
  invitation_id?: number;
  resource_id?: number;
  resource_type?: string;
}

export class Slot {
  job_id: number;
  position: number;
  group_id: number;
  role_ids: number[] = [];
  qualification_ids: number[] = [];
  gratis: boolean;

  job?: object = undefined;
  group?: object = undefined;
  roles?: object[] = [];
  qualifications?: Qualification[] = [];

  @Type(Object)
  store: any;
}

@Strategy(ExclusionPolicy.NONE)
export class Task {
  // TODO: should fix date serializer, but doesn't work'
  // @Serializer((date: Date): any => format(date, DateUnicodeFormat.apiDateTime))

  id: any;

  plan_id: number;
  schedule_id: number;
  venue_id: number;
  job_id: number;

  date: string;
  marker: [number, number];

  title: string;
  size: number;

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  intermission_starts_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  intermission_ends_at: Date;

  response_requirements: { [key in TcFeedbackRequestType]: boolean };

  times: { [key: string]: string }[];

  offset: number;
  length: number;
  shift?: DispoShift;
  job: Job;

  tracking_enabled = false;
  push_notifications = false;
  state = 'draft';

  slots: number[];
  slots_config: Slot[] = [];

  description: string;
  note: string;

  shallow: boolean;
  scheduled: boolean;
  shifted: boolean;

  net_duration: number;
  gross_duration: number;

  @Deserializer((m: string): Date => parseDate(m))
  requested_at: Date;
  requested?: boolean;

  @Type(Object)
  store: any;
}

@Injectable({
  providedIn: 'root',
})
export class DispoTaskCollection extends FrameCollection {
  type = Task;
  cache = 600;
  identifier = 'tc_dispo/task';
  endpoint = '/api/dispo/tasks/range';

  remoteDeleteValidation = true;

  constructor(
    http: HttpClient,
    cable: Cable,
    dispoFocus: DispoFocusService,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    jobCollection: JobCollection,
    qualificationCollection: QualificationCollection,
    dispoGroupCollection: DispoGroupCollection,
    dispoRoleCollection: DispoRoleCollection,
    dispoShiftCollection: DispoShiftCollection,
    localSettingsService: LocalSettingsService,
  ) {
    super(
      http,
      cable,
      dispoFocus,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
    );

    this.decorators = [
      dispoShiftCollection.all(),
      jobCollection.all(),
      qualificationCollection.all(),
      dispoGroupCollection.all(),
      dispoRoleCollection.all(),
    ];
  }

  decorate(task: Task, [shifts, jobs, qualifications, groups, roles]) {
    (task.slots_config || []).forEach((slot) => {
      slot.job = slot.job_id
        ? jobs.find((g) => g.id === slot.job_id)
        : undefined;
      slot.qualifications = qualifications.filter(
        (r) => (slot.qualification_ids || []).indexOf(r.id) !== -1,
      );
      slot.group = slot.group_id
        ? groups.find((g) => g.id === slot.group_id)
        : undefined;
      slot.roles = roles.filter(
        (r) => (slot.role_ids || []).indexOf(r.id) !== -1,
      );
    });

    if (task.schedule_id) {
      task.scheduled = true;
    } else {
      task.scheduled = false;
    }

    task.job = jobs.find((j) => j.id === task.job_id);
    task.shift = shifts.find(
      (s) => s.offset === task.offset && s.length === task.length,
    );
    task.shallow = false;

    return task;
  }

  forPlanAndDate(plan: Plan, date: Date) {
    const lookup = dateToString(date);
    const filterFunc = (x) => x.plan_id === plan.id && x.date === lookup;
    const filterLookup = `plan_id:${plan.id}|date:${lookup}`;

    return this.filter(filterFunc, filterLookup);
  }

  forPlan(plan: Plan) {
    const filterFunc = (x) => x.plan_id === plan.id;
    const filterLookup = `plan_id:${plan.id}`;

    return this.filter(filterFunc, filterLookup);
  }

  forSchedule(schedule: Schedule) {
    const filterFunc = (x) => x.schedule_id === schedule.id;
    const filterLookup = `schedule_id:${schedule.id}`;

    return this.filter(filterFunc, filterLookup);
  }

  // does not provide correct values after announcement was updated
  forAnnouncement(announcement: Announcement) {
    const filterFunc = (x) => announcement.task_ids.indexOf(x.id) !== -1;
    const filterLookup = `task_ids:${announcement.task_ids.join(',')}`;

    return this.filter(filterFunc, filterLookup);
  }

  fromSchedule(date: Date, schedule: Schedule) {
    date = new Date(date.getTime());
    date.setHours(0, 0, 0, 0);
    const lookupDate = dateToString(date);

    const slots = schedule.slots_matrix[lookupDate];
    const size = slots ? schedule.template.size : 0;
    const taskFromTemplate = this.fromTemplate(date, schedule.template);

    const task = Object.assign(new Task(), taskFromTemplate, {
      id: `${lookupDate}|${schedule.id}`,
      title: schedule.title,
      size: size,
      date: lookupDate,
      plan_id: schedule.plan_id,
      schedule_id: schedule.id,
      gross_duration: schedule.gross_duration,
      net_duration: schedule.net_duration,
      slots: slots || [],
      shallow: true,
      scheduled: true,
    });

    return task;
  }

  fromTemplate(date: Date, template) {
    date = new Date(date.getTime());

    let intermission_starts_at, intermission_ends_at;
    let times;

    const [starts_at, ends_at] = generateDateRanges(
      date,
      'day',
      template.offset,
      template.length,
    );

    if (template.intermission_offset) {
      [intermission_starts_at, intermission_ends_at] = generateDateRanges(
        date,
        'day',
        template.intermission_offset,
        template.intermission_length,
      );

      times = [
        {
          start: starts_at,
          end: intermission_starts_at,
        },
        {
          start: intermission_ends_at,
          end: ends_at,
        },
      ];
    } else {
      times = [
        {
          start: starts_at,
          end: ends_at,
        },
      ];
    }

    const obj = Object.assign(
      new Task(),
      {
        state: 'draft',
        slots_config: [],
        // TODO: add slots array ?
        push_notifications: false,
        tracking_enabled: false,
        response_requirements: {},
        size: 5,
        store: {},
      },
      template,
      {
        starts_at: starts_at,
        ends_at: ends_at,
        marker: [dateToTzUnix(starts_at), dateToTzUnix(ends_at)],
        intermission_starts_at: intermission_starts_at,
        intermission_ends_at: intermission_ends_at,
        times: TcIntervalList.formatForApi(times),
      },
    );

    delete obj.base;
    delete obj.offset;
    delete obj.length;
    delete obj.intermission_offset;
    delete obj.intermission_length;

    return obj;
  }

  fromPlan(date: Date, plan: Plan) {
    date = new Date(date.getTime());
    date.setHours(0, 0, 0, 0);

    const starts_at = new Date(date.getTime());

    return Object.assign(new Task(), {
      plan_id: plan.id,
      event_id: plan.id,
      state: 'draft',
      starts_at: starts_at,
      times: [
        {
          starts_at: starts_at,
          ends_at: undefined,
        },
      ],
      slots: [],
      push_notifications: false,
      tracking_enabled: false,
    });
  }

  openSlots(
    task: Task,
    assignments: Assignment[],
    slotCount = 1,
    startSlot = 0,
  ) {
    const openSlots = [];
    let position = startSlot;

    while (openSlots.length < slotCount) {
      if (assignments.filter((a) => a.position === position).length === 0) {
        if (task.schedule_id && position >= task.size) {
          openSlots.push(-1);
        } else {
          openSlots.push(position);
        }
      }
      position = position + 1;
    }

    return openSlots;
  }

  activateTasks(tasks: Task[]) {
    if (tasks.every((t) => !t.shallow)) {
      return of(tasks);
    }

    return this.all().pipe(
      first(),
      switchMap((allTasks) => {
        return combineLatest(
          ...tasks.map((task) => {
            const presentTask = allTasks.find((t) =>
              task.schedule_id
                ? t.schedule_id === task.schedule_id && t.date === task.date
                : task.id === t.id,
            );

            if (presentTask) {
              return of(presentTask);
            } else {
              return this.create(task);
            }
          }),
        );
      }),
    );
  }

  // improve: return task for each slot
  activateSlots(schedule: Schedule, slots: SlotPos[]) {
    const dates = [...new Set(slots.map((slot: SlotPos) => slot.x))].map((i) =>
      integerToDate(i),
    );

    return this.forSchedule(schedule).pipe(
      first(),
      map((tasks) => {
        const missingTasks = [];
        const createdTasks = [];

        dates.forEach((date: Date) => {
          const lookupDate = dateToString(date);
          const slotsPresent =
            (schedule.slots_matrix[lookupDate] || []).length !== 0;
          const task = tasks.find(
            (t) => t.schedule_id === schedule.id && t.date === lookupDate,
          );

          if (task) {
            createdTasks.push(task);
          } else if (slotsPresent) {
            missingTasks.push(this.fromSchedule(date, schedule));
          }
        });

        return [missingTasks, createdTasks];
      }),
      switchMap(([missingTasks, createdTasks]) => {
        const altActions$ = [];
        missingTasks.forEach((task) => altActions$.push(this.create(task)));
        createdTasks.forEach((task) => altActions$.push(of(task)));

        return combineLatest(...altActions$);
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class DispoScheduleTaskCollection extends SetsCollection {
  type = Task;
  cache = 600;
  identifier = 'tc_dispo/task';
  endpoint = '/api/dispo/schedules/:schedule_id/tasks';

  forSchedule(schedule_id) {
    return this.query(
      {
        schedule_id: schedule_id,
      },
      (t) => {
        return t.schedule_id === schedule_id;
      },
    );
  }
}
