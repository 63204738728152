<fieldset
  class="sf-fieldset"
  *ngFor="let fieldset of formProperty.schema.fieldsets"
>
  <ng-container
    *ngTemplateOutlet="
      isPanel ? panelTemplate : controlTemplate;
      context: { $implicit: fieldset }
    "
  ></ng-container>
</fieldset>

<ng-template #panelTemplate let-fieldset>
  <div [tcPanel] [tcTitle]="fieldset.title">
    <p *ngIf="fieldset.description">{{ fieldset.description }}</p>

    <ng-container
      *ngTemplateOutlet="controlTemplate; context: { $implicit: fieldset }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #controlTemplate let-fieldset>
  <div class="sf-controls sf-row">
    <div
      *ngFor="let fieldId of fieldset.fields"
      class="sf-elements sf-col"
      [ngClass]="formProperty.getProperty(fieldId).schema.htmlClass"
    >
      <sf-form-element
        class="sf-element"
        [formProperty]="formProperty.getProperty(fieldId)"
      ></sf-form-element>
    </div>
  </div>
</ng-template>
