import { PayGrade } from '../../tariffs/pay-grade.model';
import { Tariff } from '../../tariffs/tariff.model';
import { Attachment } from '../../core/types/attachment.model';
import { Customer } from '../../customers/customer.model';

import { AggregationImporterSettings } from './aggregation-importer-settings.model';
import { AggregationImporter } from './aggregation-importer.model';
import { AggregationType } from './aggregation_type.model';

export class Aggregation {
  id?: number;
  project_id: number;
  type_id: number;
  title?: string;
  label?: string;
  importer?: AggregationImporter = 'blank';
  importer_settings?: AggregationImporterSettings;
  type?: AggregationType;
  term?: number;
  vat_included?: boolean;
  reverse_charge?: boolean;
  vat_identification?: string;
  group_by?: string[];
  rate_source_id?: number;
  rate_source_type?: 'Customer' | 'BaseRate' | 'Project' | 'Aggregation';
  subject?: string;
  description?: string;
  comments?: string;
  recipient?: string;
  department?: string;
  first_name?: string;
  last_name?: string;
  address_addon?: string;
  street?: string;
  street_no?: number;
  zipcode?: number;
  country?: string;
  store?: Record<string, unknown>;
  xre_settings?: Record<string, unknown>;
  created_at?: string;
  updated_at?: string;
  aggregation_base_id?: number;
  aggregation_base_type?: string;
  attachment?: Attachment;
  attachment_id?: number;
  attachment_is_final?: boolean;
  customer?: Customer;
  customer_id?: number;
  deletable?: boolean;
  invoice_id?: number;
  invoice_state?: 'unbilled' | 'drafted' | 'cancelled' | 'billed' | 'paid';
  locked?: boolean;
  pay_grade?: PayGrade;
  pay_grade_id?: number;
  place?: string;
  state?: string;
  tariff?: Tariff;
  tariff_id?: number;
  token?: string;
  unlockable?: boolean;
  scheduled?: boolean;
  scheduled_at?: string;
  scheduled_target_state?: 'preview' | 'finalized' | 'billed' | 'shared';
  imported_at?: string;

  starts_at: string;
  ends_at: string;
  marker: number[];

  constructor(init: Partial<Aggregation>) {
    Object.assign(this, init);
  }
}
