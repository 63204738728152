import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';
import { Role } from '../types/account-roles.model';

@Injectable({
  providedIn: 'root',
})
export class AccountRoleCollection extends SetsCollection {
  identifier = 'accounts/roles';
  endpoint = '/api/accounts/roles';
  localSearch = ['title'];
  cache = 60 * 60 * 12;

  type = Role;
}
