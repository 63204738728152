import { isAfter } from 'date-fns';

import { getUTCDate, isValidDate, parseDate } from '@timecount/utils';

import { TcGeoCoordinates, TcInterval } from '../../../shared';

import { TcFeedbackRequestApi } from './feedback-request-api.model';
import { TcFeedbackRequestStatus } from './feedback-request-status';
import { TcFeedbackRequestType } from './feedback-request-type';

export class TcFeedbackResponse {
  responseAt!: Date;
  feedback!: boolean | TcGeoCoordinates;
}

export class TcFeedbackResponseRequirements {
  responseInterval?: Partial<Interval>;
  warningAt?: Date;
  location?: TcGeoCoordinates;
}

export class TcFeedbackRequest {
  public type!: TcFeedbackRequestType;
  public requirements?: TcFeedbackResponseRequirements;
  public response?: TcFeedbackResponse;

  constructor(init: Partial<TcFeedbackRequest>) {
    Object.assign(this, init);
  }

  // ---------------------------------------------------------------------------
  // Static Methods
  // ---------------------------------------------------------------------------

  static parseFromApi(
    apiObject: TcFeedbackRequestApi,
    defaults: {
      responseInterval?: TcInterval;
      location?: TcGeoCoordinates;
    } = {},
  ): TcFeedbackRequest {
    const {
      type,
      response_at,
      response,
      response_window,
      location = defaults.location,
    } = apiObject;
    const parsedValue: Partial<TcFeedbackRequest> = { type };

    if (response_at && isValidDate(getUTCDate(response_at))) {
      parsedValue.response = {
        responseAt: getUTCDate(response_at),
        feedback: response,
      };
    }

    const { warn_at, ...responseInterval } = response_window ?? {};
    const {
      start = defaults.responseInterval?.start,
      end = defaults.responseInterval?.end,
    } = TcInterval.parseFromApi(responseInterval);

    parsedValue.requirements = {
      responseInterval: { start, end },
      ...(!!warn_at && { warningAt: parseDate(warn_at) }),
    };

    if (type === 'onsite_checkin' && location) {
      parsedValue.requirements = {
        ...parsedValue.requirements,
        location,
      };
    }

    return new TcFeedbackRequest(parsedValue);
  }

  // ---------------------------------------------------------------------------
  // Getters / Setters
  // ---------------------------------------------------------------------------

  get status(): TcFeedbackRequestStatus {
    if (
      isValidDate(this.response?.responseAt) &&
      ((this.type === 'onsite_checkin' &&
        this.response?.feedback instanceof TcGeoCoordinates) ||
        !!this.response?.feedback)
    ) {
      return 'responded';
    } else {
      const { responseInterval, warningAt } = this.requirements ?? {};
      const { start, end } = responseInterval ?? {};

      if (isValidDate(end) && isAfter(new Date(), end as Date)) {
        return 'expired';
      } else {
        if (!isValidDate(start) || isAfter(new Date(), start as Date)) {
          if (isAfter(new Date(), warningAt as Date)) {
            return 'urgent';
          } else {
            return 'pending';
          }
        } else {
          return 'scheduled';
        }
      }
    }
  }
}
