import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  catchError,
  finalize,
  first,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

import { ValidationService } from '../../collections/validation.service';
import { EntryType } from '../../../core/types/entry-type';
import { Action } from '../../../core/types/action';
import { ActionType } from '../../../core/types/action-type';
import { Plan } from '../../../dispo/collections/plan';
import {
  DispoTaskCollection,
  Slot,
  Task,
} from '../../../dispo/collections/task';
import { DispoAssignmentCollection } from '../../../dispo/collections/assignment';
import { Assignment } from '../../../dispo/collections/assignment.model';

@Component({
  selector: 'tc-hub-dt-slots',
  templateUrl: './slots.component.html',
  styleUrls: ['./slots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DTSlotsComponent implements OnInit {
  @Input() task: Task;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading$ = new BehaviorSubject<boolean>(false);

  private translations: { [key: string]: string } = {};

  constructor(
    private taskCollection: DispoTaskCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private validationService: ValidationService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    @Inject('Flash') private flash,
  ) {
    this.translateService
      .get([
        'errors.messages.validation_resolved',
        'errors.messages.validation_not_resolved',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnInit() {
    this.form = this.buildForm(this.task);
  }

  set(field, value) {
    const formArrayControls = <UntypedFormArray>this.form.get('slots');
    formArrayControls.controls.forEach((control) => {
      control.get(field).setValue(value);
    });
  }

  save() {
    this.loading$.next(true);
    const task = { ...this.task };

    task.slots_config = this.form.value.slots.map((newConfig) => {
      const oldStore =
        task.slots_config.find(
          (oldConfig) => oldConfig.position === newConfig.position,
        )?.store ?? {};

      return {
        ...newConfig,
        store: {
          ...oldStore,
          ...newConfig.store,
        },
      };
    });

    this.assignmentCollection
      .forTask(this.task)
      .pipe(
        first(),
        mergeMap((assignments: Assignment[]) => {
          if (assignments.length) {
            const movedAssignments = assignments.map((a) =>
              this.assignmentCollection.move(a, a.position, task),
            );

            const actions = movedAssignments.map((assignment) => {
              return <Action>{
                id: uuid(),
                entry: assignment,
                entry_type: EntryType.Assignment,
                type: ActionType.confirm,
                errors: [],
                validations: (item, stack) => [],
              };
            });

            return this.validationService.run(actions, false).pipe(
              mergeMap(() => this.taskCollection.update(this.task.id, task)),
              tap(() => {
                this.flash.create(
                  this.translations['errors.messages.validation_resolved'],
                  'success',
                  6000,
                );
              }),
              map(() => 100),
              catchError((error: unknown) => {
                this.flash.create(
                  this.translations['errors.messages.validation_not_resolved'],
                  'error',
                  6000,
                );

                return of(error);
              }),
            );
          } else {
            return this.taskCollection
              .update(task.id, task)
              .pipe(map(() => 100));
          }
        }),
        finalize(() => {
          this.loading$.next(false);
        }),
      )
      .subscribe((delay: number) => {
        if (delay) {
          this.signal.emit({ action: 'close' });
        }
      });
  }

  get slots(): UntypedFormArray {
    return <UntypedFormArray>this.form.get('slots');
  }

  private buildForm(task: Task) {
    const allSlots = Array.from(new Array(task.size), (_val, position) => {
      return {
        ...((task.slots_config ?? []).find((s) => s.position === position) ?? {
          ...new Slot(),
          position,
        }),
      };
    });

    const slotsFormGroup = allSlots.map((slot) =>
      this.formBuilder.group({
        gratis: [slot.gratis],
        position: [slot.position],
        job_id: [slot.job_id],
        group_id: [slot.group_id],
        role_ids: [slot.role_ids],
        qualification_ids: [slot.qualification_ids],
        store: [slot.store],
      }),
    );

    return this.formBuilder.group({
      gratis: [],
      slots: this.formBuilder.array(slotsFormGroup),
      job_id: [],
      group_id: [],
      role_ids: [],
      qualification_ids: [],
      store: [],
    });
  }
}
