<button
  #menuDir
  nz-button
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzTrigger="click"
  nzClickHide="false"
  nzBackdrop="false"
>
  <span nz-icon nzType="eye" nzTheme="outline"></span>
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="dispo-filter-dropdown">
    <li
      class="item"
      nz-menu-item
      *ngFor="let itemType of itemTypes"
      (click)="toggle(itemType)"
    >
      <span
        nz-icon
        nzTheme="outline"
        [nzType]="
          (togglerService.isTypeVisible(itemType) | async)
            ? 'eye'
            : 'eye-invisible'
        "
      ></span>
      &nbsp; {{ 'dispo/employee.grid_items.' + itemType | translate }}
    </li>
  </ul>
</nz-dropdown-menu>
