import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DepartmentCollection } from '../department.collection';
import { Department } from '../../../core/types/department.model';

@Component({
  selector: 'tc-hub-departments-display',
  templateUrl: './departments-display.component.html',
  styleUrls: ['./departments-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentsDisplayComponent implements OnInit {
  @Input() ids: number[];

  public departments$: Observable<Department[]>;

  constructor(private departmentCollection: DepartmentCollection) {}

  ngOnInit(): void {
    this.departments$ = this.departmentCollection.all().pipe(
      map((departments) => {
        return departments.filter(
          (department) => this.ids.indexOf(department.id) !== -1,
        );
      }),
    );
  }
}
