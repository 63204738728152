import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  Injector,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { coerceElement } from '@angular/cdk/coercion';

import { ResizableDirective, ResizableEvent } from './lib/resizable';
import { findAncestor, maxZIndex } from './lib/utils';
import { ModalContentDirective } from './modal-content';
import { ModalConfig, TC_MODAL_CONFIG_DEFAULT } from './modal-config';
import { ModalRef } from './modal-ref';

interface Position {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  transform?: string;
}

const POSITION = {
  x: 0,
  y: 0,
};

@Component({
  selector: 'tc-hub-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() tcClose: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('modalRoot') modalRoot: ElementRef;
  @ViewChild('modalOverlay') modalOverlay: ElementRef;
  @ViewChild('modalHeader') modalHeader: ElementRef;
  @ViewChild('modalContent') modalContent: ElementRef;

  modalBody: ElementRef;
  modalFooter: ElementRef;

  @ViewChild(CdkDrag, { static: true }) drag: CdkDrag;
  @ViewChild(ResizableDirective, { static: true })
  resizable: ResizableDirective;

  @HostBinding('class.app-modal') cssClass = true;

  overlayZIndex: number;
  contentZIndex: number;
  visible = true;
  maximized: boolean;
  preMaximizeRootWidth: number;
  preMaximizeRootHeight: number;
  preMaximizeBodyHeight: number;
  preMaximizePageX: number;
  preMaximizePageY: number;
  preMaximizePageZ: number;

  position: Position = {
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    transform: '',
  };

  childComponentType: Type<any>;
  componentRef: ComponentRef<any>;
  @ViewChild(ModalContentDirective) insertionPoint: ModalContentDirective;
  boundary: HTMLElement;

  private _destroyed$ = new Subject<void>();

  constructor(
    private element: ElementRef,
    private cdr: ChangeDetectorRef,
    public config: ModalConfig,
    private modalRef: ModalRef,
    public zone: NgZone,
    public injector: Injector,
  ) {
    this.setConfig(config);
  }

  ngOnInit() {
    if (!this.overlayZIndex) {
      this.overlayZIndex = this.getMaxModalIndex();
      this.overlayZIndex = (this.overlayZIndex || this.config.baseZIndex) + 1;
    }
    this.contentZIndex = this.overlayZIndex + 1;
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cdr.detectChanges();
    this.setBoundary();

    this.calcBodyHeight();
    this.realign();

    setTimeout(() => {
      this.modalRoot.nativeElement?.focus();

      if (this.config.scrollTop && this.modalBody) {
        this.modalBody.nativeElement.scrollTop = 0;
      }
    }, 200);

    fromEvent(window, 'resize')
      .pipe(debounceTime(250), takeUntil(this._destroyed$))
      .subscribe((event) => {
        this.onWindowResize(event);
      });

    this.resizable.resizing
      .pipe(debounceTime(250), takeUntil(this._destroyed$))
      .subscribe((event) => {
        this.onResize(event);
      });

    // we have to filter by keyCode here, there's no 'keydown.esc' type style binding
    if (!this.config.disableEscKey) {
      fromEvent(document, 'keydown')
        .pipe(
          filter((e: KeyboardEvent) => e.keyCode === 27),
          distinctUntilChanged((x, y) => x.type === y.type),
          takeUntil(this._destroyed$),
        )
        .subscribe((event: KeyboardEvent) => {
          if (this.config.closeOnEscape) {
            event.preventDefault();
            event.stopPropagation();
            this.close();
          }
        });
    }
  }

  setConfig(config: Partial<ModalConfig>) {
    this.config = { ...TC_MODAL_CONFIG_DEFAULT, ...config };
  }

  onOverlayClick(event: MouseEvent) {
    if (this.config.dismissOnOverlayClick) {
      this.close();
    }
  }

  onCloseIconClick(event: Event) {
    event.stopPropagation();
  }

  loadChildComponent(componentType: Type<any>) {
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentType);

    this.modalBody = this.componentRef.instance.modalBody;
    this.modalFooter = this.componentRef.instance.modalFooter;
  }

  close() {
    this.visible = false;
    this.tcClose.emit(true);
    this.focusLastModal();
    this.modalRef.close();
  }

  realign() {
    if (this.config.align) {
      this[
        `align${this.config.align[0].toUpperCase()}${this.config.align.slice(
          1,
        )}`
      ]();
    } else {
      let elementWidth = this.modalContent.nativeElement.offsetWidth;
      let elementHeight = this.modalContent.nativeElement.offsetHeight;

      if (elementWidth === 0 && elementHeight === 0) {
        this.modalRoot.nativeElement.style.visibility = 'hidden';
        this.modalRoot.nativeElement.style.display = 'block';
        elementWidth = this.modalContent.nativeElement.offsetWidth;
        elementHeight = this.modalContent.nativeElement.offsetHeight;
        this.modalRoot.nativeElement.style.display = 'none';
        this.modalRoot.nativeElement.style.visibility = 'visible';
      }

      this.modalRoot.nativeElement.style.width = elementWidth + 'px';
      this.modalRoot.nativeElement.style.transform = '';
    }
  }

  alignLeft() {
    let elementWidth = this.modalContent.nativeElement.offsetWidth;
    let elementHeight = this.modalContent.nativeElement.offsetHeight;

    if (elementWidth === 0 && elementHeight === 0) {
      this.modalRoot.nativeElement.style.visibility = 'hidden';
      this.modalRoot.nativeElement.style.display = 'block';
      elementWidth = this.modalContent.nativeElement.offsetWidth;
      elementHeight = this.modalContent.nativeElement.offsetHeight;
      this.modalRoot.nativeElement.style.display = 'none';
      this.modalRoot.nativeElement.style.visibility = 'visible';
    }

    const x = this.config.boundarySpacing;
    const y = Math.max((this.boundary.offsetHeight - elementHeight) / 2, 0);
    const newWidth = Math.max(this.boundary.offsetWidth / 2, 0);

    this.modalRoot.nativeElement.style.width = newWidth + 'px';
    this.modalRoot.nativeElement.style.left = x + 'px';
    this.modalRoot.nativeElement.style.top = y + 'px';
    this.modalRoot.nativeElement.style.transform = '';

    this.memoizePosition();
  }

  alignCenter() {
    this.center();
    this.memoizePosition();
  }

  alignRight() {
    let elementWidth = this.modalContent.nativeElement.offsetWidth;
    let elementHeight = this.modalContent.nativeElement.offsetHeight;

    if (elementWidth === 0 && elementHeight === 0) {
      this.modalRoot.nativeElement.style.visibility = 'hidden';
      this.modalRoot.nativeElement.style.display = 'block';
      elementWidth = this.modalContent.nativeElement.offsetWidth;
      elementHeight = this.modalContent.nativeElement.offsetHeight;
      this.modalRoot.nativeElement.style.display = 'none';
      this.modalRoot.nativeElement.style.visibility = 'visible';
    }

    const x = this.config.boundarySpacing;
    const y = Math.max((this.boundary.offsetHeight - elementHeight) / 2, 0);
    const newWidth = Math.max(this.boundary.offsetWidth / 2, 0);

    this.modalRoot.nativeElement.style.width = newWidth + 'px';
    this.modalRoot.nativeElement.style.right = x + 'px';
    this.modalRoot.nativeElement.style.top = y + 'px';
    this.modalRoot.nativeElement.style.transform = '';

    this.memoizePosition();
  }

  center() {
    let elementWidth = this.modalContent.nativeElement.offsetWidth;
    let elementHeight = this.modalContent.nativeElement.offsetHeight;

    if (elementWidth === 0 && elementHeight === 0) {
      this.modalRoot.nativeElement.style.visibility = 'hidden';
      this.modalRoot.nativeElement.style.display = 'block';
      elementWidth = this.modalContent.nativeElement.offsetWidth;
      elementHeight = this.modalContent.nativeElement.offsetHeight;
      this.modalRoot.nativeElement.style.display = 'none';
      this.modalRoot.nativeElement.style.visibility = 'visible';
    }

    const x = Math.max((this.boundary.offsetWidth - elementWidth) / 2, 0);
    const y = Math.max((this.boundary.offsetHeight - elementHeight) / 2, 0);

    this.modalRoot.nativeElement.style.width = elementWidth + 'px';
    this.modalRoot.nativeElement.style.left = x + 'px';
    this.modalRoot.nativeElement.style.top = y + 'px';
    this.modalRoot.nativeElement.style.transform = '';
  }

  public resize() {
    if (this.modalRoot) {
      this.modalRoot.nativeElement.style.width = 'auto';
    }

    this.calcBodyHeight();
    this.realign();
    this.memoizePosition();
  }

  public onResize(event: ResizableEvent) {
    if (event.direction === 'vertical') {
      this.calcBodyHeight();
    }
    this.memoizePosition();
  }

  public onMove(event) {
    this.memoizePosition();
  }

  protected onWindowResize(event) {
    this.resize();
  }

  protected calcBodyHeight() {
    if (
      !this.boundary ||
      !this.modalBody ||
      !this.modalHeader ||
      !this.modalRoot
    ) {
      console.error('cannot calculate height - missing elements');
      return;
    }

    this.modalBody.nativeElement.style.height = '';
    this.modalBody.nativeElement.style.maxHeight = 'none';
    this.modalBody.nativeElement.style.overflow = 'auto';

    let diffHeight = this.modalHeader.nativeElement.offsetHeight;

    if (this.modalFooter) {
      diffHeight += this.modalFooter.nativeElement.offsetHeight;
    }

    let contentHeight;

    if (
      this.boundary.offsetHeight < this.modalRoot.nativeElement.offsetHeight
    ) {
      contentHeight = this.boundary.offsetHeight - diffHeight;
    } else {
      contentHeight = this.modalRoot.nativeElement.offsetHeight - diffHeight;
    }

    this.modalBody.nativeElement.style.height =
      contentHeight +
      this.config.safeGap -
      2 * this.config.boundarySpacing +
      'px';
    this.modalBody.nativeElement.style.maxHeight = '';
    this.modalBody.nativeElement.style.overflow = 'auto';
  }

  memoizePosition() {
    this.position.top = this.modalRoot.nativeElement.style.top;
    this.position.right = this.modalRoot.nativeElement.style.right;
    this.position.bottom = this.modalRoot.nativeElement.style.bottom;
    this.position.left = this.modalRoot.nativeElement.style.left;
    this.position.transform = this.modalRoot.nativeElement.style.transform;
  }

  getMaxModalIndex() {
    return maxZIndex('.tc-modal');
  }

  focusLastModal() {
    const modal = findAncestor(
      this.element.nativeElement.parentElement,
      this.element.nativeElement.className,
    );
    if (modal && modal.children[1]) {
      modal.children[1].focus();
    }
  }

  toggleMaximize(event) {
    if (this.maximized) {
      this.revertMaximize();
    } else {
      this.maximize();
    }

    event.preventDefault();
  }

  maximize() {
    this.memoizePosition();

    this.preMaximizeRootWidth = this.modalRoot.nativeElement.offsetWidth;
    this.preMaximizeRootHeight = this.modalRoot.nativeElement.offsetHeight;
    this.preMaximizeBodyHeight = this.modalBody.nativeElement.offsetHeight;

    this.modalRoot.nativeElement.style.top = '0px';
    this.modalRoot.nativeElement.style.left = '0px';
    this.modalRoot.nativeElement.style.width = '100vw';
    this.modalRoot.nativeElement.style.height = '100vh';

    let diffHeight = this.modalHeader.nativeElement.offsetHeight;

    if (this.modalFooter) {
      diffHeight += this.modalFooter.nativeElement.offsetHeight;
    }

    this.modalBody.nativeElement.style.height =
      'calc(100vh - ' + diffHeight + 'px)';
    this.modalBody.nativeElement.style.maxHeight = 'none';

    this.modalRoot.nativeElement.style.transform = '';

    this.maximized = true;
  }

  revertMaximize() {
    this.modalRoot.nativeElement.style.top = this.position.top;
    this.modalRoot.nativeElement.style.right = this.position.right;
    this.modalRoot.nativeElement.style.bottom = this.position.bottom;
    this.modalRoot.nativeElement.style.left = this.position.left;
    this.modalRoot.nativeElement.style.transform = this.position.transform;

    this.modalRoot.nativeElement.style.width = this.preMaximizeRootWidth + 'px';
    this.modalRoot.nativeElement.style.height =
      this.preMaximizeRootHeight + 'px';
    this.modalBody.nativeElement.style.height =
      this.preMaximizeBodyHeight + 'px';

    this.maximized = false;
  }

  moveOnTop() {
    const zIndex = this.getMaxModalIndex();

    if (this.contentZIndex <= zIndex) {
      this.contentZIndex = zIndex + 1;

      if (this.config.backdrop) {
        this.modalOverlay.nativeElement.style.zIndex = zIndex;
      }
    }
  }

  get dialogStyles() {
    return {
      display: this.visible ? 'block' : 'none',
      'z-index': this.contentZIndex,
      'width.px': this.config.width,
      'min-width.px': this.config.minWidth,
      'min-height.px': this.config.minHeight,
    };
  }

  get overlayStyles() {
    return {
      display: this.visible && this.config.backdrop ? 'block' : 'none',
      'z-index': this.overlayZIndex,
    };
  }

  // Gets the closest ancestor of an element that matches a selector.
  private getClosestMatchingAncestor(element: HTMLElement, selector: string) {
    let currentElement = element.parentElement as HTMLElement | null;

    while (currentElement) {
      // IE doesn't support `matches` so we have to fall back to `msMatchesSelector`.
      if (
        currentElement.matches
          ? currentElement.matches(selector)
          : (currentElement as any).msMatchesSelector(selector)
      ) {
        return currentElement;
      }

      currentElement = currentElement.parentElement;
    }

    return null;
  }

  private setBoundary(): void {
    let boundary;

    if (this.drag && this.drag.boundaryElement) {
      if (typeof this.drag.boundaryElement === 'string') {
        boundary = this.getClosestMatchingAncestor(
          this.modalRoot.nativeElement,
          this.drag.boundaryElement,
        );
      } else {
        boundary = this.drag.boundaryElement;
      }
    } else {
      boundary = this.modalRoot;
    }

    this.boundary = coerceElement(boundary);
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
