import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { CurrentUserService } from '../core/current-user.service';

@Directive({
  selector: '[tcHubHide]',
})
export class HideDirective {
  private hasView = false;

  @Input() set tcHubHide(section: string) {
    const visible = this.currentUser.hasAccessToSection(section);

    if (visible && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!visible && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private currentUser: CurrentUserService,
  ) {}
}
