import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { dateToTzUnix } from '@timecount/utils';

import { DispoFilterService } from '../../shared/filter/filter.service';
import { LocalSettingsService } from '../../core/local-settings.service';
import { DispoAssignService } from '../collections/assign.service';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoAssignmentCollection } from '../collections/assignment';
import { Assignment } from '../collections/assignment.model';
import { DispoTaskCollection, SlotPos, Task } from '../collections/task';
import { DispoPlanCollection } from '../collections/plan';
import { DispoResourceCollection } from '../collections/resource';
import {
  DispoRangeDataStructure,
  DispoRangeDataStructureFactory,
} from '../datastructures/range.service';
import { DispoCombinedTaskCollection } from '../collections/combined_task';

@Injectable({
  providedIn: 'root',
})
export class DispoTasksServiceFactory {
  private services: { [key: string]: DispoTasksDayService } = {};

  public plans = [];
  public tasks = [];
  public assignments = [];

  constructor(
    private translateService: TranslateService,
    private dispoRangeDataStructureFactory: DispoRangeDataStructureFactory,
    private filterService: DispoFilterService,
    private assignService: DispoAssignService,
    private localSettings: LocalSettingsService,
    private plansService: DispoPlanCollection,
    private tasksService: DispoTaskCollection,
    private combinedTasksService: DispoCombinedTaskCollection,
    private assignmentsService: DispoAssignmentCollection,
    private resourcesService: DispoResourceCollection,
    private currentUser: CurrentUserService,
  ) {
    this.plansService.all().subscribe((plans) => {
      this.plans = plans;
    });

    this.tasksService.all().subscribe((tasks) => {
      this.tasks = tasks;
    });

    this.assignmentsService.all().subscribe((assignments) => {
      this.assignments = assignments;
    });
  }

  forDate(date) {
    const lookup = date.toISOString().slice(0, 10);

    if (!this.services[lookup]) {
      this.services[lookup] = new DispoTasksDayService(
        date,
        this,
        this.translateService,
        this.dispoRangeDataStructureFactory.forDate(date),
        this.filterService,
        this.assignService,
        this.localSettings,
        this.plansService,
        this.tasksService,
        this.combinedTasksService,
        this.assignmentsService,
        this.resourcesService,
        this.currentUser,
      );
    }

    return this.services[lookup];
  }
}

export class DispoTasksDayService {
  private sliceBegin: number;
  private sliceEnd: number;

  private tasks$;

  private translations: { [key: string]: string } = {};

  constructor(
    public date: Date,
    private parent: DispoTasksServiceFactory,
    private translateService: TranslateService,
    private dateDataStructure: DispoRangeDataStructure,
    private filterService: DispoFilterService,
    private assignService: DispoAssignService,
    private localSettings: LocalSettingsService,
    private plansService: DispoPlanCollection,
    private tasksService: DispoTaskCollection,
    private combinedTasksService: DispoCombinedTaskCollection,
    private assignmentsService: DispoAssignmentCollection,
    private resourceService: DispoResourceCollection,
    private currentUser: CurrentUserService,
  ) {
    const begin = new Date(this.date);
    begin.setHours(0, 0, 0, 0);

    this.sliceBegin = dateToTzUnix(begin);

    const end = new Date(this.date);
    end.setHours(23, 59, 59);

    this.sliceEnd = dateToTzUnix(end);

    this.translateService
      .get([
        'dispo/assignment.errors.connected_with_invitation',
        'dispo/assignment.errors.no_slot_available',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  plans(): Observable<any[]> {
    return combineLatest(
      this.plansService.forRange(this.sliceBegin, this.sliceEnd),
      this.filterService.getSort('dispo.tasks', 'plans'),
    ).pipe(
      map(([plans, sort]) => {
        return plans.sort(sort);
      }),
    );
  }

  plan(id: number): Observable<any> {
    return this.plansService.find(id);
  }

  planTasks(plan): Observable<any[]> {
    return combineLatest([
      this.combinedTasksService.forDate(this.date),
      this.dateDataStructure.tasks(),
      this.taskQuery(),
      this.filterService.getSort('dispo.tasks', 'tasks'),
    ]).pipe(
      map(([tasks, tasksDS, query, sort]) => {
        const visibleTasksIds = tasksDS
          .filter((taskDS) => taskDS.plan_id === plan.id)
          .filter(query)
          .sort(sort)
          .map((s: any) => s.id);

        const sortOrder = {};
        visibleTasksIds.forEach((id, index) => {
          sortOrder[id] = index;
        });

        return tasks
          .filter(
            (s) =>
              s.plan_id === plan.id && visibleTasksIds.indexOf(s.id) !== -1,
          )
          .sort((a, b) => {
            return sortOrder[a.id] - sortOrder[b.id];
          });
      }),
      // distinctUntilChanged( (prev, curr) => {
      //   const currIds = curr.map(c => c.id);
      //   const prevIds = prev.map(p => p.id);

      //   if (JSON.stringify(currIds) === JSON.stringify(prevIds)) {
      //     return true;
      //   }

      //   return false;
      // }),
    );
  }

  taskSlots(currTask) {
    return combineLatest(
      this.combinedTasksService.observeItem(currTask),
      this.taskAssignments(currTask),
      this.resourceService.all(),
    ).pipe(
      map(([task, assignments, resources]) => {
        if (!task) {
          return [];
        }

        const maxAssignmentPosition: number =
          assignments.length === 0
            ? 0
            : Math.max(...assignments.map((a) => a.position)) + 1;
        const slotSize: number = task.schedule_id
          ? task.size
          : task.size > maxAssignmentPosition
          ? task.size
          : maxAssignmentPosition;

        return Array.from(new Array(slotSize), (_val, position) => {
          const assignment_slots = assignments.filter(
            (a) => a.position === position,
          );
          const assignment = assignment_slots[0];
          const slotConfig = (task.slots_config || []).find(
            (s) => s.position === position,
          ) || { store: {} };
          const slotPresent = task.schedule_id
            ? task.slots.findIndex((s) => s === position) !== -1
            : true;

          if (assignment) {
            const resource = resources.find(
              (r) =>
                r.type === assignment.resource_type &&
                r.id === assignment.resource_id,
            );

            return Object.assign(
              {
                resource: resource,
                resource_title: resource ? resource.name : '?',
                missing: false,
                blank: false,
                invited: !!assignment.invitation_id,
                tracked: assignment.tracked_time,
                times_state: assignment.times_state,
                overloaded: assignment_slots.length !== 1,
                requested: task.state === 'open' && !!assignment.requested_at,
                confirmable: !!resource?.user_id || false,
                confirmed:
                  assignment.requested_at &&
                  assignment.read_at &&
                  assignment.read_at.getTime() >=
                    assignment.requested_at.getTime(),
              },
              assignment,
              slotConfig,
              {
                store: Object.assign(
                  {},
                  slotConfig.store || {},
                  assignment.store || {},
                ),
              },
            );
          } else if (slotPresent) {
            // missing slot
            return Object.assign(
              {
                missing: true,
                blank: false,
                overloaded: false,
                invited: false,
                tracked: false,
                position: position,
                task_id: task.id,
              },
              slotConfig,
            );
          } else {
            // blank slot // no slot
            return {
              missing: false,
              blank: true,
              overloaded: false,
              invited: false,
              tracked: false,
              position: position,
              task_id: task.id,
            };
          }
        });
      }),
    );
  }

  taskAssignments(task) {
    return this.assignmentsService.forTask(task);
  }

  tasksDS(): Observable<any[]> {
    return this.dateDataStructure.tasks();
  }

  taskDS(task) {
    return this.dateDataStructure.task(task);
  }

  plansDS(): Observable<any[]> {
    return this.dateDataStructure.plans();
  }

  planDS(plan) {
    return this.dateDataStructure.plan(plan);
  }

  dayDS() {
    return this.dateDataStructure.total();
  }

  // REFACTOR: similar to dispo-schedules.service#handleInsert
  // type: Enum[timecount/dispo_assignments, timecount/resources, timecount/dispo_tasks]
  // data: { slots?: [{x: dateInt, y: position }], task?: Task, schedule?: Schedule }
  // target: Task|Plan
  // toPosition: {x: dateInt, y: position|targetId }
  // selectedSlots: [{x: dateInt, y: position }]
  handleInsert(type, data, target, toPosition, selectedSlots = []) {
    if (
      type === 'timecount/dispo_assignments' &&
      this.currentUser.hasAccessToSection('views.dispo.insert.assignment')
    ) {
      // drag to same position
      if (target.id === data.task.id && data.slots[0].y === toPosition.y) {
        return;
      }

      // use openSlots vs offset calculation
      // handling in case only one assignment gets dragged
      // different handling in case target assignment exists (?)
      const assignments = this.parent.assignments.filter(
        (a) => a.task_id === target.id,
      );
      const targetSlots = this.tasksService.openSlots(
        target,
        assignments,
        data.slots.length,
        toPosition.y || 0,
      );

      this.handleMissingTask(target).subscribe((task) => {
        this.handleAssignmentsInsert(data, task, targetSlots, data.action);
      });
    } else if (
      type === 'timecount/resources' &&
      this.currentUser.hasAccessToSection('views.dispo.insert.resource')
    ) {
      // use openSlots vs selected slots
      let targetSlots;
      const assignments = this.parent.assignments.filter(
        (a) => a.task_id === target.id,
      );

      if (data.resource.type === 'Employee') {
        targetSlots = this.tasksService.openSlots(
          target,
          assignments,
          1,
          toPosition.y || 0,
        );
      } else {
        targetSlots = this.tasksService.openSlots(
          target,
          assignments,
          selectedSlots.length,
          toPosition.y || 0,
        );
      }

      this.handleMissingTask(target).subscribe((task) => {
        this.handleResourcesInsert(data, task, targetSlots, data.action);
      });
    }
  }

  handleSelect(task: Task, slots: SlotPos[]) {
    // slots.map(slot => {
    //   slot.schedule_id = task.schedule_id;
    //   slot.task_id = task.id;
    // });
    // this.selectorService.setSlots(slots);
  }

  // REFACTOR: similar to dispo-schedules.service#handleAssignmentsInsert
  // source: { task: Task, slots: [{x: dateInt, y: position }] }
  // targetTask: Task
  // targetSlots: [position] -> refactor to Slot?
  // action: update,create[,delete]
  private handleAssignmentsInsert(source, targetTask, targetSlots, action) {
    const actions = [];

    source.slots.forEach((s, index) => {
      const sourcePos = s.y;
      const targetPos = targetSlots[index];

      const assignment = this.parent.assignments.find((sSlot) => {
        return sSlot.task_id === source.task.id && sSlot.position === sourcePos;
      });

      actions.push({
        assignment: assignment,
        task: targetTask,
        position: targetPos,
        type: 'update',
      });
    });

    this.assignService.run(actions);
  }

  // REFACTOR: similar to dispo-schedules.service#handleResourcesInsert
  // source: { resource: Resourcxe }
  // targetTask: Task
  // targetSlots: [position] -> refactor to Slot?
  // action: create[,update,delete]
  private handleResourcesInsert(
    source,
    targetTask,
    targetSlots,
    action = 'create',
  ) {
    const actions = [];
    targetSlots.forEach((targetPos) => {
      const assignment = Object.assign(new Assignment(), {
        resource_id: source.resource.id,
        resource_type: source.resource.type,
        tracked_time: false,
      });

      actions.push({
        assignment: assignment,
        task: targetTask,
        position: targetPos,
        type: 'create',
      });
    });

    this.assignService.run(actions);
  }

  // REFACTOR: use scheduleActions#activateSlots
  private handleMissingTask(targetTask) {
    return this.tasksService.activateTasks([targetTask]).pipe(
      map((tasks) => {
        return tasks[0];
      }),
    );
  }

  private taskQuery() {
    return this.filterService.getQuery('dispo.tasks');
  }
}
