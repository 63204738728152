import { NgModule } from '@angular/core';

import { TcButtonModule } from '../button/button.module';
import { SharedModule } from '../../shared/shared.module';

import { TcToggleButtonComponent } from './toggle-button-ionic/toggle-button.component';
import { TcToggleButtonGroupComponent } from './toggle-button-group.component';

@NgModule({
  imports: [SharedModule, TcButtonModule],
  declarations: [TcToggleButtonComponent, TcToggleButtonGroupComponent],
  exports: [TcToggleButtonComponent, TcToggleButtonGroupComponent],
})
export class TcToggleButtonModule {}
