import {
  Directive,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormGroupName, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TcFormGroupTimeSetComponentConfig } from './form-group-time-set.config';
import { TcFormGroupTimeSetService } from './form-group-time-set.service';
import { TcFormGroupTimeSetComponentState } from './form-group-time-set.state';

@Directive()
export abstract class TcFormGroupTimeSetBaseDirective {
  /**
   * The component's configuration
   */
  @Input()
  set tcConfig(config: TcFormGroupTimeSetComponentConfig) {
    this.state.setConfig(config);
  }

  /**
   * Defines the base day to be used by the inputted times.
   *
   * If not provided, the current day will be used.
   */
  @Input()
  set tcReferenceDate(referenceDate: Date) {
    this.state.set({ referenceDate });
  }

  @Output()
  tcValueChange = new EventEmitter();

  @Output()
  tcStatusChange = new EventEmitter();

  formGroup: UntypedFormGroup;

  readonly viewState$ = this.state.state$;

  private _destroyed$ = new Subject<void>();

  constructor(
    @Optional() public formGroupName: FormGroupName,
    public state: TcFormGroupTimeSetComponentState,
    public timeSetService: TcFormGroupTimeSetService,
  ) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit(): void {
    if (
      !!this.formGroupName &&
      !this.formGroupName.control.get('mainInterval')
    ) {
      throw new Error(`
            The TcFormGroupTimeSetWebComponent requires that the provided
            [formGroupName]'s control have a 'mainInterval' FormGroup.
          `);
    }

    this.state.set({
      formGroup:
        this.formGroupName?.control ??
        this.timeSetService.getTimeSetFormGroup(this.state.get().config),
    });

    const { formGroup } = this.state.get();

    this._valueChangesHandler(formGroup.value);

    formGroup.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        this._valueChangesHandler(value);
      });

    formGroup.statusChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((status) => {
        this.tcStatusChange.emit(status);
      });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _valueChangesHandler(value) {
    this.tcValueChange.emit(value);
    this.state.setDuration(value);
  }
}
