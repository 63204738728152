import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { isSameDay } from 'date-fns';

import { SlotPos } from '../collections/task';
import { Announcement } from '../collections/announcement';
import {
  DispoInvitationCollection,
  Invitation,
} from '../collections/invitation';
import { InvitationService } from '../actions/invitation.service';
import { DispoAssignmentCollection } from '../collections/assignment';
import { integerToDate, integerToString } from '../../core/helpers';
import { AssignmentService } from '../actions/assignment.service';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoMessage } from '../collections/message';
import { MessageService } from '../actions/message.service';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuInvitationSlot {
  private translations: { [key: string]: string } = {};
  private announcementMessageTemplates: any[] = [];

  constructor(
    private translateService: TranslateService,
    private invitationActions: InvitationService,
    private assignmentActions: AssignmentService,
    private invitationCollection: DispoInvitationCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private messageActions: MessageService,
    private currentUser: CurrentUserService,
  ) {
    this.resourceTemplateCollection
      .setForResource('dispo.messages.announcement')
      .all()
      .subscribe((templates) => {
        this.announcementMessageTemplates = templates;
      });

    this.translateService
      .get([
        'dispo/invitation_slot.actions.assign',
        'dispo/invitation_slot.actions.unassign',
        'dispo/invitation_slot.actions.accept',
        'dispo/invitation_slot.actions.cancel',
        'dispo/invitation_slot.actions.refuse',
        'dispo/invitation_slot.actions.delete',
        'dispo/invitation_slot.actions.message',
        'dispo/invitation_slot.menu.slots',
        'dispo/invitation_slot.menu.message',
        'trails.actions.resource',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  itemsForAnnouncement(
    slots: SlotPos[],
    announcement: Announcement,
    invitations: Invitation[],
  ): Observable<any[]> {
    return combineLatest(
      this.invitationCollection.observeItems(invitations),
      this.assignmentCollection.all(),
    ).pipe(
      map(([invitations, assignments]) => {
        const invitation_ids = invitations.map((i) => i.id);
        assignments = assignments.filter(
          (a) => invitation_ids.indexOf(a.invitation_id) !== -1,
        );

        slots = slots.map((slot) => {
          slot = Object.assign({}, slot);

          const assignment = assignments.find(
            (a) =>
              a.task_id === slot.task_id &&
              a.invitation_id === slot.invitation_id,
          );
          slot.assignment_id = assignment ? assignment.id : undefined;

          return slot;
        });

        const assignableSlots = slots.filter((s) => !s.assignment_id);
        const unassignableSlots = slots.filter((s) => !!s.assignment_id);

        const acceptableSlots = slots.filter((s) => {
          const invitation = invitations.find((i) => i.id === s.invitation_id);
          const lookup = integerToString(s.x);

          return invitation.states[lookup] !== 'accepted';
        });

        const cancellableSlots = slots.filter((s) => {
          const invitation = invitations.find((i) => i.id === s.invitation_id);
          const lookup = integerToString(s.x);

          return invitation.states[lookup] === 'accepted';
        });

        const refusableSlots = slots.filter((s) => {
          const invitation = invitations.find((i) => i.id === s.invitation_id);
          const lookup = integerToString(s.x);

          return invitation.states[lookup] !== 'refused';
        });

        const messagesFromSlots = () => {
          const messages = [];

          slots.forEach((slot) => {
            const invitation = invitations.find(
              (i) => i.id === slot.invitation_id,
            );
            const date = integerToDate(slot.x);

            let message = messages.find((m) => isSameDay(m.date, date));

            if (!message) {
              message = Object.assign(new DispoMessage(), {
                type: 'announcement',
                date: new Date(date.getTime()),
                announcement_id: announcement.id,
                recipients: [],
              });
              messages.push(message);
            }

            message.recipients.push({
              id: invitation.resource_id,
              type: invitation.resource_type,
            });
          });

          return messages;
        };

        const slotActions: any[] = [
          {
            label: this.translations['dispo/invitation_slot.actions.assign'],
            icon: 'user-add',
            visible:
              assignableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.assign',
              ),
            command: (event: any) => {
              this.invitationActions.assign(
                assignableSlots,
                announcement,
                invitations,
              );
            },
          },
          {
            label: this.translations['dispo/invitation_slot.actions.unassign'],
            icon: 'user-delete',
            visible:
              unassignableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.unassign',
              ),
            command: (event: any) => {
              this.invitationActions.unassign(
                unassignableSlots,
                announcement,
                invitations,
              );
            },
          },
          {
            label: this.translations['dispo/invitation_slot.actions.accept'],
            icon: 'check',
            visible:
              acceptableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.accept',
              ),
            command: (event: any) => {
              this.invitationActions.accept(acceptableSlots, invitations);
            },
          },
          {
            label: this.translations['dispo/invitation_slot.actions.cancel'],
            icon: 'close-circle',
            visible:
              cancellableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.cancel',
              ),
            command: (event: any) => {
              this.invitationActions.cancel(cancellableSlots, invitations);
            },
          },
          {
            label: this.translations['dispo/invitation_slot.actions.refuse'],
            icon: 'close',
            visible:
              refusableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.refuse',
              ),
            command: (event: any) => {
              this.invitationActions.refuse(refusableSlots, invitations);
            },
          },
          {
            label: this.translations['dispo/invitation_slot.actions.message'],
            icon: 'comment',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.invitation.message',
            ),
            command: (event: any) => {
              const defaultTemplate = this.announcementMessageTemplates.find(
                (t) => t.default,
              ) || { template: {} };
              this.messageActions.multi(
                messagesFromSlots(),
                'announcement',
                defaultTemplate.template,
              );
            },
          },
        ];

        if (invitations?.length === 1) {
          slotActions.push({
            label: this.translations['trails.actions.resource'],
            icon: 'audit',
            visible:
              this.currentUser.hasAccessToFeature('logs') &&
              this.currentUser.can('index', 'PaperTrail::Version', {
                item_type: 'TcDispo::Invitation',
              }),
            command: (_event: unknown) => {
              this.invitationActions.viewLogs(invitations[0].id);
            },
          });
        }

        const announcementMessageTemplates: any[] =
          this.announcementMessageTemplates
            .filter((t) => !t.default)
            .map((template) => {
              return {
                label: template.title,
                command: (event: any) => {
                  this.messageActions.multi(
                    messagesFromSlots(),
                    'announcement',
                    template.template,
                  );
                },
              };
            });

        slotActions.push({
          label: this.translations['dispo/invitation_slot.menu.message'],
          icon: 'copy',
          visible:
            announcementMessageTemplates.length > 0 &&
            this.currentUser.hasAccessToSection(
              'views.dispo.invitation.message',
            ),
          items: announcementMessageTemplates,
        });

        return [
          ...(slotActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/invitation_slot.menu.slots'],
                },
                ...slotActions,
              ]
            : []),
        ];
      }),
    );
  }
}
