import { Deserializer, ExclusionPolicy, Strategy, Type } from 'typeserializer';

import { parseDate } from '@timecount/utils';
import { IntervalSummary } from '@timecount/core';

import { PayGrade } from '../tariffs/pay-grade.model';
import { Tariff } from '../tariffs/tariff.model';

@Strategy(ExclusionPolicy.NONE)
export class Timesheet {
  id: number;
  identifier = 'timesheet';
  shallow = false;
  state;

  project_id: number;
  job_id: number;
  venue_id?: number;
  pay_grade_id?: number;
  tariff_id?: number;
  custom_pay_grade: boolean;
  gratis: boolean;

  resource_id: number;
  resource_type: string;

  source_id?: number;
  source_type?: string;

  marker: [number, number];

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  intermission_starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  intermission_ends_at: Date;

  duration?: string;
  description?: string;
  tags: string[] = [];

  @Type(Object)
  supplements: Record<string, unknown>;

  summary?: IntervalSummary;

  @Type(Object)
  store: Record<string, unknown>;

  @Type(Object)
  resource?: Record<string, unknown>;

  tariff?: Tariff;

  pay_grade?: PayGrade;

  @Type(Object)
  job?: Record<string, unknown>;

  @Type(Object)
  venue?: Record<string, unknown>;
}
