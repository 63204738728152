import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { SetCollection } from '../../../../core/sets-collection';
import {
  DispoMessage,
  DispoMessageCollection,
  DispoMessageRecipient,
} from '../../../collections/message';
import { DispoResourceDataStructure } from '../../../datastructures/resource.service';
import { EmployeeCollection } from '../../../../employees/employee.collection';

@Component({
  selector: 'tc-hub-dmsgc-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css'],
})
export class DMSGCStep2Component implements OnInit {
  @Input() message: DispoMessage;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  loading = false;
  section;
  estimatedSmsCount = 0;
  resources$: Observable<any[]>;
  resourcesDS$: Observable<any[]>;

  employeeRecipients: DispoMessageRecipient[];
  selectedEmployees: any[];

  private employeeService: SetCollection;

  constructor(
    private employeeSets: EmployeeCollection,
    private resourceDS: DispoResourceDataStructure,
    private messageCollection: DispoMessageCollection,
  ) {
    this.employeeService = this.employeeSets.visible();
  }

  ngOnInit(): void {
    this.section = `dispo.messages.${this.message.type}`;
    (this.resources$ = this.employeeService.all()),
      (this.resourcesDS$ = this.employeesDS());
    this.employeeRecipients = this.message.recipients.filter(
      (r) => r.type === 'Employee',
    );
    this.signal.emit({ action: 'resize' });
  }

  prev() {
    const message = Object.assign({}, this.message);

    this.signal.emit({ message: message, action: 'prev' });
  }

  send() {
    const contactRecipients = <DispoMessageRecipient[]>(
      this.message.recipients.filter((r) => r.type === 'Contact')
    );
    const employeeRecipients = <DispoMessageRecipient[]>(
      this.selectedEmployees.map((e) => ({ type: 'Employee', id: e.id }))
    );

    const message = Object.assign({}, this.message);

    message.recipients = contactRecipients.concat(employeeRecipients);

    this.loading = true;
    this.messageCollection.create(message).subscribe({
      next: () => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
      },
    });
  }

  onSelect(employees) {
    this.selectedEmployees = employees;

    const message = Object.assign(new DispoMessage(), this.message);
    message.recipients = [
      ...message.recipients,
      ...employees.map((emp) => {
        return { id: emp.id, type: 'Employee' };
      }),
    ];

    this.messageCollection.estimatedSmsCount(message).subscribe({
      next: (count) => {
        this.estimatedSmsCount = count;
      },
    });
  }

  private employeesDS() {
    return this.resourceDS.employees();
  }
}
