import { ExclusionPolicy, Strategy } from 'typeserializer';

@Strategy(ExclusionPolicy.NONE)
export class Tariff {
  id: number;
  title: string;

  locked: boolean;
  admin: boolean;
  auto_import: boolean;
  auto_import_country: string;

  calc_attrs_id: number;
  global_zone_group_ids: number[];

  created_at: Date;
}
