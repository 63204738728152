<div class="sf-group tc-select">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>
  <div class="sf-controls">
    <div class="sf-elements">
      <tc-hub-schema-remote-select
        [formControl]="control"
        [url]="schema.url"
        [bindPrefix]="schema.prefix"
        [bindLabel]="schema.label || 'title'"
        [bindSuffix]="schema.suffix"
        class="sf-element"
      ></tc-hub-schema-remote-select>
    </div>
  </div>
</div>
