import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DispoMenuService } from '../menus/menu.service';
import { RemoteConfig } from '../../core/remote_config.service';
import { DispoFilterService } from '../../shared/filter/filter.service';

import { DTPaginateComponent } from './paginate/paginate.component';
import { DTScrollComponent } from './scroll/scroll.component';

@Component({
  selector: 'tc-hub-dispo-tasks',
  templateUrl: './dispo-tasks.component.html',
  styleUrls: ['./dispo-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispoTasksComponent implements OnInit {
  @ViewChild('scrollComponent') scrollComponent: DTScrollComponent;
  @ViewChild('paginateComponent') paginateComponent: DTPaginateComponent;

  showEmptyPlans$: Observable<boolean>;

  constructor(
    private remoteConfig: RemoteConfig,
    private filterService: DispoFilterService,
    private contextMenuService: DispoMenuService,
  ) {}

  ngOnInit() {
    this.showEmptyPlans$ = combineLatest(
      this.filterService.queryEmpty('dispo.tasks'),
      this.remoteConfig.getItem('view', 'dispo.tasks'),
    ).pipe(
      map(([queryEmpty, config]: [boolean, any]) => {
        if (queryEmpty) {
          return config?.data?.showEmpty;
        }

        return false;
      }),
    );
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  contextMenuHandler(event) {
    if (event.button === 2) {
      event.preventDefault();
      event.stopPropagation();
    }

    const contexts = event.menu_contexts || [];

    if (!contexts.find((i) => i.item_type === 'DispoPlan')) {
      contexts.push({
        item_type: 'DispoPlan',
        item: [],
      });
    }

    this.contextMenuService.onViewClick(event, 'dispo/tasks', contexts);
  }

  onPaginateSelect(event) {
    this.paginateComponent.clearSelection();
  }
}
