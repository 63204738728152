import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { AttachableType, SmtpTarget } from '@timecount/core';

import { SmtpTargetsCollection } from '../smtp-targets.collection';
import { SmtpApiService } from '../smtp-config.service';

@Component({
  selector: 'tc-hub-custom-email-headers-form',
  templateUrl: './custom-email-headers.component.html',
  styleUrls: ['./custom-email-headers.component.scss'],
})
export class TcCustomEmailHeadersComponent implements OnInit, OnDestroy {
  targetformGroups: Map<string, FormGroup> = new Map();

  targets: AttachableType[] = [];
  selectedTarget: AttachableType;

  smtpEnabled$ = this.smtpConfigService.isSmtpEnabled$;
  destroyed$ = new Subject<void>();

  constructor(
    private smtpTargetsCollection: SmtpTargetsCollection,
    private smtpConfigService: SmtpApiService,
  ) {}

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this.targets = window.config.smtp_targets;
    this.selectedTarget = this.targets[0];

    this.smtpTargetsCollection
      .all()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((targetsValue: SmtpTarget[]) => {
        this._buildForm(targetsValue);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // --------------
  // Public Methods
  // --------------

  submit(target: AttachableType) {
    const { emailHeaders, id } = this.targetformGroups.get(target).value;

    const payload = {
      key: target,
      ...emailHeaders,
    };

    if (id) {
      this.smtpTargetsCollection.update(id, payload);
    } else {
      this.smtpTargetsCollection
        .create(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: SmtpTarget) => {
          this.targetformGroups.get(target).patchValue({ id: response.id });
        });
    }
  }

  delete(target: AttachableType) {
    const { emailHeaders, id } = this.targetformGroups.get(target).value;

    const payload = {
      id,
      key: target,
      ...emailHeaders,
    };

    this.smtpTargetsCollection
      .delete(id, payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.targetformGroups.get(target).setValue({
          id: null,
          emailHeaders: {},
        });
      });
  }

  // ---------------
  // Private Methods
  // ---------------

  private async _buildForm(targetsValue: SmtpTarget[]) {
    this.targets.forEach((targetKey) => {
      if (!this.targetformGroups.has(targetKey)) {
        const { key, id, ...emailHeaders } =
          targetsValue.find((value) => value.key === targetKey) ?? {};

        this.targetformGroups.set(
          targetKey,
          new FormGroup({
            id: new FormControl(id),
            emailHeaders: new FormControl(emailHeaders),
          }),
        );
      }
    });
  }
}
