import { Directive, Injector, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Directive({
  standalone: true,
})
export class ValueAccessorDirective<T>
  implements ControlValueAccessor, OnDestroy, OnInit
{
  private onChange: (_: unknown) => void;
  private onTouched: (_: unknown) => void;
  private valueSubject = new Subject<T>();
  private disabledSubject = new Subject<boolean>();
  readonly value = this.valueSubject.asObservable();
  readonly disabled = this.disabledSubject.asObservable();

  ngControl: NgControl;

  constructor(private injector: Injector) {}

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this.valueSubject.complete();
    this.disabledSubject.complete();
  }

  // ---------------------------------------------------------------------------
  // CVA Implementation
  // ---------------------------------------------------------------------------

  writeValue(obj: unknown): void {
    this.valueSubject.next(obj as T);
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledSubject.next(isDisabled);
  }

  // ---------------------------------------------------------------------------
  // Public Methods
  // ---------------------------------------------------------------------------

  valueChange(v: T) {
    if (typeof this.onChange === 'function') {
      this.onChange(v);
    }
  }

  touchedChange(v: boolean) {
    if (typeof this.onTouched === 'function') {
      this.onTouched(v);
    }
  }
}
