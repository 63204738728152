import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TcKeysListPipe } from './keys-list.pipe';

@NgModule({
  exports: [TcKeysListPipe],
  imports: [CommonModule, TcKeysListPipe],
})
export class ObjectModule {}
