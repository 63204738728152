import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { Slot } from '../../../../../../dispo/collections/task';

@Component({
  selector: 'tc-hub-batch-create-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
})
export class TcWorkEntryBatchStep4Component implements OnInit {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  formGroup: UntypedFormGroup;
  controlForm: UntypedFormGroup;

  get slots_config(): UntypedFormArray {
    return <UntypedFormArray>this.formGroup.get('slots_config');
  }

  constructor(private formBuilder: UntypedFormBuilder) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit() {
    this.formGroup = this._buildForm();
    this.signal.emit({ action: 'resize' });
  }

  // --------------
  // Public Methods
  // --------------

  set(field, value) {
    const formArrayControls = <UntypedFormArray>(
      this.formGroup.get('slots_config')
    );
    formArrayControls.controls.forEach((control) => {
      control.get(field).setValue(value);
    });
  }

  prev() {
    this.signal.emit({ action: 'prev', config: this.config });
  }

  next() {
    const form = this.formGroup.value;
    const nextConfig = Object.assign({}, this.config);
    const slotsConfig = form.slots_config;

    nextConfig.slots_config = slotsConfig;
    this.signal.emit({ action: 'next', config: nextConfig });
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm() {
    const baseSlots = this.config.template.slots_config || [];

    const allSlots = Array.from(
      new Array(this.config.template.size),
      (_val, position) => {
        return {
          ...((this.config.template.slots_config ?? []).find(
            (s) => s.position === position,
          ) ?? {
            ...new Slot(),
            position,
          }),
        };
      },
    );

    const slotsFormGroup = allSlots.map((slot) =>
      this.formBuilder.group({
        position: [slot.position],
        gratis: [slot.gratis],
        job_id: [slot.job_id],
        group_id: [slot.group_id],
        role_ids: [slot.role_ids],
        qualification_ids: [slot.qualification_ids],
        store: [slot.store],
      }),
    );

    return this.formBuilder.group({
      slots_config: this.formBuilder.array(slotsFormGroup),
      job_id: [],
      gratis: [],
      group_id: [],
      role_ids: [],
      qualification_ids: [],
      store: {},
    });
  }
}
