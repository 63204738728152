import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Pin, PinCollection, PinnableType } from '../../pins/pin';
import { SetCollection } from '../../core/sets-collection';
import { PinsService } from '../../pins/pins.service';

@Component({
  selector: 'tc-hub-pins-button',
  templateUrl: './pins-button.component.html',
  styleUrls: ['./pins-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinsButtonComponent implements OnInit {
  @Input() type: PinnableType;
  @Input() id: number;

  @Input() btnSize: 'large' | 'small' | 'default' = 'default';
  @Input() btnType: 'link' | 'primary' | 'default' = 'primary';

  public visible = false;
  public loading = false;
  public form: UntypedFormGroup;
  public pins$: Observable<Pin[]>;
  public activeCount$: Observable<number>;
  public inactiveCount$: Observable<number>;
  public style$: Observable<any>;

  private pinTypeCollection: SetCollection;

  constructor(
    private pinCollection: PinCollection,
    private pinsService: PinsService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.pinTypeCollection = this.pinCollection.forType(this.type);

    this.pins$ = this.pinTypeCollection.all().pipe(
      map((pins: Pin[]) => {
        return pins.filter((p) => p.pinnable_id === this.id);
      }),
    );

    this.activeCount$ = this.pins$.pipe(
      map((pins: Pin[]) => {
        return pins.filter((p) => p.state === 'inbox' || p.state === 'progress')
          .length;
      }),
    );

    this.inactiveCount$ = this.pins$.pipe(
      map((pins: Pin[]) => {
        return pins.filter(
          (p) => p.state === 'backlog' || p.state === 'complete',
        ).length;
      }),
    );

    this.style$ = this.pins$.pipe(
      map((pins: Pin[]) => {
        const errorPin = pins.find(
          (p) =>
            p.level === 'error' &&
            (p.state == 'inbox' || p.state == 'progress'),
        );
        const warningPin = pins.find(
          (p) =>
            p.level === 'warning' &&
            (p.state == 'inbox' || p.state == 'progress'),
        );

        if (errorPin) {
          return { backgroundColor: '#d9534f' };
        } else if (warningPin) {
          return { backgroundColor: '#f0ad4e' };
        } else {
          return { backgroundColor: '#38404a' };
        }
      }),
    );
  }

  onVisibleChange(visible) {
    if (visible) {
      this.form = this.buildForm();
    } else {
      this.form = undefined;
    }
  }

  create() {
    this.loading = true;
    const pin = Object.assign(new Pin(), this.form.value, {
      state: 'inbox',
      pinnable_id: this.id,
      pinnable_type: this.type,
    });

    this.pinsService.create(pin).subscribe({
      next: () => {
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  edit(pin) {
    this.visible = false;
    this.pinsService.edit(pin);
  }

  toggle(pin) {
    this.pinsService.toggle(pin);
  }

  state(pin, state) {
    this.pinsService.state(pin, state);
  }

  delete(pin) {
    this.pinsService.delete(pin);
  }

  trackById(index, pin) {
    return pin.id;
  }

  private buildForm() {
    return this.formBuilder.group({
      note: [undefined, [Validators.required]],
    });
  }
}
