<!-- when there are no controls, we should only show the admin header or panel if the tcIsAdmin is true -->
<ng-container
  *ngIf="
    formArray.controls?.length > 0 || !tcIsAdmin;
    else asHeader ? adminHeader : adminPanel
  "
  [formGroup]="formArray"
>
  <fieldset *ngFor="let control of formArray.controls; let i = index">
    <ng-container
      *ngTemplateOutlet="
        asHeader && tcIsAdmin ? adminHeader : null;
        context: { $implicit: configs[i] }
      "
    ></ng-container>

    <div class="form-wrapper">
      <ng-container
        *ngTemplateOutlet="
          tcIsAdmin && !asRow && !asHeader ? adminPanel : schemaForm;
          context: { $implicit: control, config: configs[i] }
        "
      ></ng-container>
    </div>
  </fieldset>
</ng-container>

<ng-template #adminHeader let-config>
  <fieldset [class.no-config]="!config">
    <header>
      <button
        type="button"
        class="icon-button header-button"
        nz-button
        nzSize="small"
        nzType="link"
        nz-dropdown
        [nzDropdownMenu]="config ? menu : null"
      >
        <span nz-icon nzType="control" nzTheme="outline"></span>
      </button>
    </header>

    <div *ngIf="!config" class="form-wrapper">
      <ng-container *ngTemplateOutlet="keyTag"></ng-container>
    </div>
  </fieldset>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <ng-container *ngTemplateOutlet="keyTag"></ng-container>
      </li>
      <li nz-menu-divider></li>
      <li
        nz-menu-item
        [nzDisabled]="configs?.length === 0"
        (click)="openSchema(config)"
      >
        <!-- NOTE:
          Using styles here to avoid over-complicated selectors trying to
          reach this element in the CDK's overlay container.
        -->
        <span nz-icon nzType="setting" style="margin-right: 8px"></span>
        <span>{{
          'frontend_config/schema.' +
            (configs?.length === 0
              ? 'state.no_config'
              : 'actions.preview_schema') | translate
        }}</span>
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-template>

<ng-template #adminPanel let-control let-config="config">
  <tc-panel class="panel" tcColor="admin">
    <!--
      NOTE: This has duplicated elements as the `adminHeader` template, because
      the `tcHeaderStart` and `tcHeaderEnd` directives won't be projected to the
      panel when in a `ng-container` with `*ngTemplateOutlet`.
    -->
    <header tcHeaderStart>
      <span
        class="icon-button"
        nz-icon
        nzType="control"
        nzTheme="outline"
      ></span>
      <span class="panel-title">{{ config?.data?.title }}</span>
    </header>

    <div tcHeaderEnd>
      <ng-container *ngTemplateOutlet="keyTag"></ng-container>
    </div>

    <div tcHeaderEnd *ngIf="config?.type === 'form'">
      <button
        type="button"
        class="icon-button"
        nz-button
        nzSize="small"
        nzType="link"
        nz-tooltip
        [nzTooltipTitle]="'frontend_config/schema.state.converted' | translate"
      >
        <span nz-icon nzType="warning" nzTheme="outline"></span>
      </button>
    </div>

    <div tcHeaderEnd [class.no-config]="!config">
      <button
        type="button"
        class="icon-button settings-button"
        nz-button
        nzSize="small"
        nzType="link"
        nz-tooltip
        [nzTooltipTitle]="'frontend_config/schema.state.no_config' | translate"
        [nzTooltipTrigger]="!config ? 'hover' : null"
        (click)="openSchema(config)"
      >
        <span nz-icon nzType="setting" nzTheme="outline"></span>
      </button>
    </div>

    <ng-container
      *ngTemplateOutlet="
        control ? schemaForm : null;
        context: { $implicit: control, config: config }
      "
    ></ng-container>
  </tc-panel>
</ng-template>

<ng-template #schemaForm let-control let-config="config">
  <sf-form
    [formControl]="control"
    [schema]="config | frontEndConfig : tcIsAdmin"
  ></sf-form>
</ng-template>

<ng-template #keyTag>
  <nz-tag class="key-tag" nzColor="purple" (click)="copySchemaKey()">
    <span nz-icon nzType="copy"></span>
    <span>{{ section }}</span>
  </nz-tag>
</ng-template>
