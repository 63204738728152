<div
  class="base"
  nz-tooltip
  nzTooltipTitle="{{ 'time_balance.type.' + timebalance.type | translate }}{{
    timebalance.description ? ' - ' + timebalance.description : ''
  }}"
  [ngClass]="{
    vacation:
      timebalance.type === 'custom_vacation' ||
      timebalance.type === 'vacation' ||
      timebalance.type === 'unpaid_vacation',
    sickness:
      timebalance.type === 'custom_sickness' ||
      timebalance.type === 'sickness' ||
      timebalance.type === 'unpaid_sickness',
    work: timebalance.type === 'work',
    custom: timebalance.type === 'custom',
    absence: timebalance.type === 'absence',
    education: timebalance.type === 'education',
    holiday: timebalance.type === 'holiday',
    transfer: timebalance.type === 'transfer'
  }"
></div>
