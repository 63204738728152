import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { delay, map, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { dateToTzUnix } from '@timecount/utils';

import { dateToString, integerToString, overlaps } from '../../core/helpers';
import { DispoFilterService } from '../../shared/filter/filter.service';
import { DispoAssignService } from '../collections/assign.service';
import { CurrentUserService } from '../../core/current-user.service';
import { ActionType } from '../../core/types/action-type';
import { DispoAssignmentCollection } from '../collections/assignment';
import { Assignment } from '../collections/assignment.model';
import { DispoPlanCollection } from '../collections/plan';
import { DispoTaskCollection, SlotPos, Task } from '../collections/task';
import { DispoScheduleCollection, Schedule } from '../collections/schedule';
import { DispoResourceCollection } from '../collections/resource';
import { DispoFocusService } from '../dispo-focus.service';
import { DispoRangeDataStructureFactory } from '../datastructures/range.service';
import { DispoAnnouncementCollection } from '../collections/announcement';
import { DispoCombinedTaskCollection } from '../collections/combined_task';

@Injectable({
  providedIn: 'root',
})
export class DispoSchedulesService {
  private _tasks: any[] = [];
  private _schedules: any[] = [];
  private _assignments: any[] = [];
  private _plans: any[] = [];

  private dataStructureService;
  private visibleScheduleIds$;

  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private dispoRangeDataStructureFactory: DispoRangeDataStructureFactory,
    private filterService: DispoFilterService,
    private dispoFocus: DispoFocusService,
    private plansService: DispoPlanCollection,
    private tasksService: DispoTaskCollection,
    private schedulesService: DispoScheduleCollection,
    private combinedTasksService: DispoCombinedTaskCollection,
    private announcementsService: DispoAnnouncementCollection,
    private assignmentsService: DispoAssignmentCollection,
    private resourcesService: DispoResourceCollection,
    private assignmentActionService: DispoAssignService,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/assignment.errors.connected_with_invitation',
        'dispo/assignment.errors.no_task_available',
        'dispo/assignment.errors.no_slot_available',
        'dispo/assignment.errors.target_slot_not_available',
        'dispo/assignment.errors.no_slot_available',
        'dispo/assignment.errors.no_slot_available',
        'dispo/assignment.errors.target_slot_not_available',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.dataStructureService = this.dispoRangeDataStructureFactory.all();

    this.schedulesService.all().subscribe((schedules) => {
      this._schedules = schedules;
    });

    this.combinedTasksService.all().subscribe((tasks) => {
      this._tasks = tasks;
    });

    this.assignmentsService.all().subscribe((assignments) => {
      this._assignments = assignments;
    });

    this.plansService.all().subscribe((plans) => {
      this._plans = plans;
    });
  }

  public plans(): Observable<any[]> {
    return combineLatest(
      this.plansService.all(),
      this.filterService.getSort('dispo.schedules', 'plans'),
      this.dispoFocus.range(),
    ).pipe(
      map(([plans, sort, [begin, end]]) => {
        return plans.filter((p) => overlaps(p.marker, begin, end)).sort(sort);
      }),
    );
  }

  public planDS(plan): Observable<any[]> {
    return this.dataStructureService.plan(plan);
  }

  public planSchedules(plan): Observable<any[]> {
    return combineLatest(
      this.schedulesService.filter(
        (t) => t.plan_id === plan.id,
        `plan_id: ${plan.id}`,
      ),
      this.visibleScheduleIds(),
      this.dispoFocus.range(),
    ).pipe(
      map(([schedules, visibleSchedulesIds, [begin, end]]) => {
        const sortOrder = {};
        visibleSchedulesIds.forEach((id, index) => {
          sortOrder[id] = index;
        });

        return schedules
          .filter((s) => overlaps(s.marker, begin, end))
          .filter((s) => visibleSchedulesIds.indexOf(s.id) !== -1)
          .sort((a, b) => {
            return sortOrder[a.id] - sortOrder[b.id];
          });
      }),
    );
  }

  public scheduleTasks(schedule): Observable<any> {
    return combineLatest(
      this.schedulesService.filter(
        (t) => t.id === schedule.id,
        `schedule_id: ${schedule.id}`,
      ),
      this.tasksService.filter(
        (t) => t.schedule_id === schedule.id,
        `schedule_id: ${schedule.id}`,
      ),
      this.announcementsService.filter(
        (t) => t.schedule_id === schedule.id,
        `schedule_id: ${schedule.id}`,
      ),
      this.scheduleAssignments(schedule),
      this.resourcesService.all(),
      this.dispoFocus.dates(),
    ).pipe(
      // debounceTime(100),
      map(
        ([[schedule], tasks, announcements, assignments, resources, dates]) => {
          if (!schedule) {
            return [];
          }

          return dates.map((date) => {
            const lookupDate = dateToString(date);
            const task = tasks.find((t) => t.date === lookupDate);
            const available_positions = schedule.slots_matrix[lookupDate];
            let taskAnnouncements = [];

            // refactor and return Task to use dispo-tasks.service#tasks ??
            if (task) {
              taskAnnouncements = announcements.filter(
                (a) => a.task_ids.indexOf(task.id) !== -1,
              );

              const slots = Array.from(Array(schedule.template.size || 0)).map(
                (_, position) => {
                  const assignmentSlots = assignments.filter(
                    (a) => a.position === position && a.date === lookupDate,
                  );
                  const assignment = assignmentSlots[0];
                  const slotPresent =
                    (available_positions || []).findIndex(
                      (s) => s === position,
                    ) !== -1;

                  if (assignment) {
                    const resource = resources.find(
                      (r) =>
                        r.type === assignment.resource_type &&
                        r.id === assignment.resource_id,
                    );

                    return Object.assign(
                      {
                        resource: resource,
                        resource_shorthand: resource?.shorthand || '?',
                        missing: false,
                        blank: false,
                        invited: !!assignment.invitation_id,
                        tracked: assignment.tracked_time,
                        times_state: assignment.times_state,
                        overloaded: assignmentSlots.length !== 1,
                        requested:
                          task.state === 'open' && !!assignment.requested_at,
                        confirmable: !!resource?.user_id || false,
                        confirmed:
                          assignment.requested_at &&
                          assignment.read_at &&
                          assignment.read_at.getTime() >=
                            assignment.requested_at.getTime(),
                      },
                      assignment,
                    );
                  } else if (slotPresent) {
                    // return blank assignment for slot config ?
                    return {
                      position: position,
                      missing: true,
                      blank: false,
                      invited: false,
                      tracked: false,
                      overloaded: false,
                    };
                  } else {
                    // return blank slot // no slot
                    return {
                      position: position,
                      missing: false,
                      blank: true,
                      invited: false,
                      tracked: false,
                      overloaded: false,
                    };
                  }
                },
              );

              const size = (available_positions || []).length;
              const assignmentSize = slots.filter((s) => s.id).length;

              return {
                id: task.id,
                date: lookupDate,
                marker: task.marker,
                slots: slots,
                size: size,
                announced: taskAnnouncements.length !== 0,
                assignment_size: assignmentSize,
                empty: assignmentSize === 0,
                unfilled: assignmentSize !== 0 && assignmentSize < size,
                filled: assignmentSize >= size,
                public: task.state === 'open',
                requested: !!task.requested_at,
                tracking_enabled:
                  task.state === 'open' && task.tracking_enabled,
                shifted: task.shifted,
                shallow: false,
                blank: false,
                weekend: date.getDay() === 0 || date.getDay() === 6,
                schedule_id: schedule.id,
                task: task,
              };
            } else if (available_positions) {
              const currDate = new Date(date.getTime());
              currDate.setHours(0, 0, 0, 0);

              const starts_at = new Date(
                currDate.getTime() + schedule.template.offset * 1000,
              );
              const ends_at = new Date(
                starts_at.getTime() + schedule.template.length * 1000,
              );
              const slots = Array.from(Array(schedule.template.size || 0)).map(
                (_, position) => {
                  const slotPresent =
                    available_positions.findIndex((s) => s === position) !== -1;

                  if (slotPresent) {
                    // return blank assignment for slot config ?
                    return {
                      position: position,
                      missing: true,
                      blank: false,
                      overloaded: false,
                    };
                  } else {
                    // return blank slot // no slot
                    return {
                      position: position,
                      missing: false,
                      blank: true,
                      overloaded: false,
                    };
                  }
                },
              );

              const size = available_positions.length;
              const task = this.tasksService.fromSchedule(currDate, schedule);

              return {
                id: task.id,
                date: lookupDate,
                marker: [dateToTzUnix(starts_at), dateToTzUnix(ends_at)],
                slots: slots,
                size: size,
                announced: false,
                assignment_size: 0,
                empty: true,
                unfilled: size !== 0,
                filled: size === 0,
                public: task.state === 'open',
                requested: false,
                tracking_enabled:
                  task.state === 'open' && task.tracking_enabled,
                shifted: false,
                shallow: false,
                weekend: date.getDay() === 0 || date.getDay() === 6,
                schedule_id: schedule.id,
                task: task,
              };
            } else {
              return {
                date: lookupDate,
                slots: [],
                size: 0,
                assignment_size: 0,
                public: false,
                requested: false,
                empty: true,
                unfilled: false,
                filled: false,
                shallow: false,
                blank: true,
                weekend: date.getDay() === 0 || date.getDay() === 6,
                schedule_id: schedule.id,
              };
            }
          });
        },
      ),
      shareReplay(1),
    );
  }

  public scheduleAssignments(schedule) {
    return this.assignmentsService.filter(
      (a) => a.schedule_id === schedule.id,
      `schedule_id:${schedule.id}`,
    );
  }

  public scheduleDS(schedule): Observable<any[]> {
    return this.dataStructureService.schedule(schedule);
  }

  public schedulesDS(): Observable<any[]> {
    return this.dataStructureService.schedules();
  }

  // REFACTOR: similar to dispo-tasks.service#handleInsert
  // type: Enum[timecount/dispo_assignments, timecount/resources]
  // data: { slots?: [{x: dateInt, y: position }], schedule?: Schedule }
  // target: Schedule
  // toPosition: {x: dateInt, y: position|targetId }
  // selectedSlots: [{x: dateInt, y: position }]
  public handleInsert(type, data, target, toPosition, selectedSlots = []) {
    if (
      type === 'timecount/dispo_assignments' &&
      this.currentUser.hasAccessToSection('views.dispo.insert.assignment')
    ) {
      const fromPosition = {
        x: data.x,
        y: data.y,
      };

      const offset = {
        x: toPosition.x - fromPosition.x,
        y: toPosition.y - fromPosition.y,
      };

      const targetSlots = data.slots.map((s) => {
        return {
          x: s.x + offset.x,
          y: s.y + offset.y,
        };
      });

      this.handleMissingTasks(target, targetSlots).subscribe((_) => {
        this.handleAssignmentsInsert(data, target, targetSlots, data.action);
      });
    } else if (
      type === 'timecount/resources' &&
      this.currentUser.hasAccessToSection('views.dispo.insert.resource')
    ) {
      const targetSlots =
        selectedSlots.length === 0 ? [toPosition] : selectedSlots;

      this.handleMissingTasks(target, targetSlots).subscribe((_) => {
        this.handleResourcesInsert(data, target, targetSlots, data.action);
      });
    }
  }

  public handleSelect(schedule: Schedule, slots: SlotPos[]) {
    // slots.map(slot => {
    //   slot.schedule_id = schedule.id;
    //   slot.task_id = (this._tasks.find(t => {
    //     return t.schedule_id === schedule.id &&
    //            t.date === integerToString(slot.x);
    //   }) || { id: undefined }).id;
    // });
    // this.selectorService.setSlots(slots);
  }

  private visibleScheduleIds(): Observable<any[]> {
    return (this.visibleScheduleIds$ ||= combineLatest(
      this.schedulesDS(),
      this.scheduleQuery(),
      this.filterService.getSort('dispo.schedules', 'schedules'),
      this.dispoFocus.range(),
    ).pipe(
      map(([schedulesDS, query, sort, [begin, end]]) => {
        return schedulesDS
          .filter(query)
          .sort(sort)
          .map((s: any) => s.id);
      }),
      shareReplay(),
    ));
  }

  // REFACTOR: similar to dispo-tasks.service#handleAssignmentsInsert
  // source: { schedule: Schedule, slots: [{x: dateInt, y: position }] }
  // targetSchedule: Schedule
  // targetSlots: [{x: dateInt, y: position }]
  // action: update,create[,delete]
  private handleAssignmentsInsert(
    source,
    targetSchedule: Schedule,
    targetSlots: SlotPos[],
    action,
  ) {
    if (
      source.schedule.id === targetSchedule.id &&
      source.slots[0].x === targetSlots[0].x &&
      source.slots[0].y === targetSlots[0].y
    ) {
      return;
    }

    const actions = source.slots.map((s, i) => {
      const sourceLookupDate = integerToString(s.x);
      const sourcePos = s.y;

      const assignment = <Assignment>this._assignments.find((assig) => {
        return (
          assig.schedule_id === source.schedule.id &&
          assig.position === sourcePos &&
          assig.date === sourceLookupDate
        );
      });

      const targetSlot = targetSlots[i];
      const targetLookupDate = integerToString(targetSlot.x);

      const targetTask = <Task>this._tasks.find((tTask) => {
        return (
          tTask.schedule_id === targetSchedule.id &&
          tTask.date === targetLookupDate
        );
      });

      if (targetSlot.y === -1) {
        const assignments = this._assignments.filter(
          (a) => a.task_id === targetTask.id,
        );
        const openSlots = this.tasksService.openSlots(
          targetTask,
          assignments,
          1,
          0,
        );
        targetSlot.y = openSlots[0];
      }

      return {
        assignment: assignment,
        task: targetTask,
        position: targetSlot.y,
        type: ActionType.update,
      };
    });

    return this.assignmentActionService.run(actions);
  }

  // REFACTOR: similar to dispo-tasks.service#handleResourcesInsert
  // source: { resource: Resource }
  // targetTask: Task
  // targetSlots: [{x: dateInt, y: position }]
  // action: create[,update,delete]
  private handleResourcesInsert(
    source,
    targetSchedule: Schedule,
    targetSlots: SlotPos[],
    action?,
  ) {
    const actions = targetSlots.map((targetSlot) => {
      const assignment = Object.assign(new Assignment(), {
        resource_id: source.resource.id,
        resource_type: source.resource.type,
        tracked_time: false,
      });

      const lookupDate = integerToString(targetSlot.x);
      const targetTask = <Task>this._tasks.find((tTask) => {
        return (
          tTask.schedule_id === targetSchedule.id && tTask.date === lookupDate
        );
      });

      if (targetSlot.y === -1) {
        const assignments = this._assignments.filter(
          (a) => a.task_id === targetTask.id,
        );
        const openSlots = this.tasksService.openSlots(
          targetTask,
          assignments,
          1,
          0,
        );
        targetSlot.y = openSlots[0];
      }

      return {
        assignment: assignment,
        task: targetTask,
        position: targetSlot.y,
        type: ActionType.create,
      };
    });

    return this.assignmentActionService.run(actions);
  }

  private handleMissingTasks(targetSchedule: Schedule, targetSlots: SlotPos[]) {
    return this.tasksService
      .activateSlots(targetSchedule, targetSlots)
      .pipe(delay(500));
  }

  private scheduleQuery() {
    return this.filterService.getQuery('dispo.schedules');
  }
}
