import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  DISPO_MESSAGE_TEMPLATES,
  DispoMessage,
} from '../../../collections/message';

@Component({
  selector: 'tc-hub-dmsgc-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css'],
})
export class DMSGCStep1Component implements OnInit {
  @Input() message: DispoMessage;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  templateType: string;
  placeholderType: string;
  hasDate: boolean;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.hasDate = DISPO_MESSAGE_TEMPLATES[this.message.type]['date'];
    this.placeholderType =
      DISPO_MESSAGE_TEMPLATES[this.message.type]['placeholder'];
    this.templateType = DISPO_MESSAGE_TEMPLATES[this.message.type]['template'];
    this.form = this.buildForm(this.message);

    this.signal.emit({ action: 'resize' });
  }

  next() {
    const formValue = this.form.value;
    formValue.contact_ids.forEach((contactId) => {
      if (
        !this.message.recipients.find(
          (r) => r.type === 'Contact' && r.id === contactId,
        )
      ) {
        this.message.recipients.push({ type: 'Contact', id: contactId });
      }
    });
    delete formValue.contact_ids;

    const message = Object.assign({}, this.message, formValue);

    this.signal.emit({ message: message, action: 'next' });
  }

  private buildForm(message: DispoMessage) {
    return this.formBuilder.group({
      contact_ids: [
        message.recipients.filter((t) => t.type === 'Contact').map((r) => r.id),
      ],
      transmission_type: [message.transmission_type, [Validators.required]],
      date: [
        { disabled: !this.hasDate, value: message.date },
        [Validators.required],
      ],
      subject: [message.subject, [Validators.required]],
      body: [message.body, [Validators.required]],
      short_message_enabled: [message.short_message_enabled],
      message_template_id: [undefined],
    });
  }
}
