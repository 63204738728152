import { NzTagModule } from 'ng-zorro-antd/tag';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { ResizableModule } from './lib/resizable';
import { ModalComponent } from './modal.component';
import { ModalContentDirective } from './modal-content';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    NzIconModule,
    NzTagModule,
    ResizableModule,
  ],
  declarations: [ModalComponent, ModalContentDirective],
  exports: [ModalComponent],
  providers: [],
})
export class TcModalModule {}
