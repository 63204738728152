import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule, JsonPipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';

import { TcFormWrapperComponent } from '../form-wrapper';
import { BaseModalComponent, ModalConfig, ModalRef } from '../modal';

import { TcCodeEditorComponent } from './code-editor.component';

@Component({
  selector: 'tc-code-editor-modal',
  templateUrl: './code-editor-modal.component.html',
  styleUrls: ['./code-editor-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TcFormWrapperComponent,
    TcCodeEditorComponent,
    NzAlertModule,
    TranslateModule,
  ],
})
export class TcCodeEditorModalComponent
  extends BaseModalComponent
  implements OnInit
{
  value: string;
  alertKey: string;
  readOnly = false;

  constructor(
    private _clipboard: Clipboard,
    private translateService: TranslateService,
    @Inject('Flash') protected flash,
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super(config, modalRef);
  }

  ngOnInit(): void {
    if (this.config?.data) {
      Object.assign(this, this.config.data);
    }
  }

  // ---------------------------------------------------------------------------
  // Public Methods
  // ---------------------------------------------------------------------------

  copyValueToClipboard(): void {
    this._clipboard.copy(new JsonPipe().transform(this.value));
    this.flash.create(this.translateService.instant('copied_to_clipboard'));
  }
}
