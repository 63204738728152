import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, map, Observable } from 'rxjs';

import { SetsCollection } from '../sets-collection';
import { JobRate } from '../types/job-rate.model';
import { Cable } from '../cable';
import { StoreService } from '../store.service';
import { ApiErrorService } from '../api-error.service';
import { CurrentUserService } from '../current-user.service';
import { JobCollection } from '../../jobs/job.collection';
import { Job } from '../types/job.model';
import { LocalSettingsService } from '../local-settings.service';

import { VatCollection } from './vat.collection';
import { ZoneGroupCollection } from './zone_groups.collection';

@Injectable({
  providedIn: 'root',
})
export class JobRateCollection extends SetsCollection {
  identifier = 'job_rate';
  endpoint = '/api/job_rates/:parent_type/:parent_id';

  cache = 0;

  constructor(
    protected http: HttpClient,
    protected cable: Cable,
    protected store: StoreService,
    protected errorHandler: ApiErrorService,
    protected currentUser: CurrentUserService,
    private _vatCollection: VatCollection,
    private _jobCollection: JobCollection,
    private _zoneGroupCollection: ZoneGroupCollection,
    protected localSettingsService: LocalSettingsService,
    @Inject('Flash') protected flash,
  ) {
    super(
      http,
      cable,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
      flash,
    );
  }

  forTypeAndId(parent_type: string, parent_id: number) {
    return this.query(
      {
        parent_type: parent_type,
        parent_id: parent_id,
      },
      (t) => {
        return t.parent_id === parent_id && t.parent_type === parent_type;
      },
    );
  }

  default(
    parent_type: string,
    parent_id: number,
  ): Observable<Partial<JobRate>> {
    const zoneGroups$ = this._zoneGroupCollection.all();
    const vat$ = this._vatCollection.default();
    const job$ = this._jobCollection
      .visible()
      .all()
      .pipe(map((jobs) => jobs[0]));

    return combineLatest([zoneGroups$, vat$, job$]).pipe(
      map(([zoneGroups, vat, job]) => {
        const base: Partial<JobRate> = {
          parent_id,
          parent_type,
          surcharges: {},
          job: job as Job,
          job_id: job.id,
          per_hour: 0,
          per_day: 0,
          daily_rate: false,
          vat: vat,
          vat_id: vat.id,
        };

        zoneGroups.forEach((zoneGroup) => {
          if (base.surcharges) {
            base.surcharges[zoneGroup.id] = 0;
          }
        });

        return base;
      }),
    );
  }
}
