import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import {
  DateUnicodeFormat,
  getMaxDurationInterval,
  isIntervalLongerThanDuration,
  isValidDate,
  isValidInterval,
  stringifyDate,
} from '@timecount/utils';

import { TcFormGroupIntervalComponentConfig } from './form-group-interval.config';

export class TcFormGroupIntervalValidators {
  /**
   * Validates an Interval form group
   *
   * For each date of the interval, it will mark it as required when it has no
   * value, but the other date has one. Otherwise, it will check if the value
   * is within a limit interval, if provided and valid.
   *
   * @param config The Component's configuration object
   */
  static interval(config: TcFormGroupIntervalComponentConfig): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const {
        noValidators,
        allowZeroDuration,
        exclusiveLimitDates,
        fieldType,
        required,
      } = config;

      if (!noValidators) {
        const { start, end } = control.value;

        if (start || end) {
          const { maxDuration } = config;
          // When both values are valid
          if (isValidDate(start) && isValidDate(end)) {
            // But interval is not valid
            if (!isValidInterval(control.value, allowZeroDuration)) {
              control.get('end').setErrors({
                [allowZeroDuration ? `date_same_or_after` : `date_after`]: true,
              });
              // When there's a max duration
            } else if (maxDuration !== 0) {
              // TODO Implement time max duration validation
              // Date interval is longer than max duration
              if (
                fieldType === 'date' &&
                maxDuration.days > 0 &&
                isValidInterval(control.value) &&
                isIntervalLongerThanDuration(control.value, maxDuration)
              ) {
                control.get('end').setErrors({
                  [`date_${!exclusiveLimitDates ? 'same_or_' : ''}before_date`]:
                    {
                      maxDate: stringifyDate(
                        getMaxDurationInterval(control.value, maxDuration)
                          .end as Date,
                        DateUnicodeFormat[config.fieldType],
                      ),
                    },
                });
              }
            }
          }
          const keys = ['start', 'end'];

          keys.forEach((key) => {
            const controlToValidate = control.get(key);
            const otherControl = control.get(keys.find((elem) => elem !== key));

            // When one has value but the other doesn't
            if (!controlToValidate.value && otherControl.value) {
              controlToValidate.setErrors({
                required: true,
              });
            }
          });
        } else if (!required) {
          control.get('start').setErrors(null);
          control.get('end').setErrors(null);
        }
      }

      return null;
    };
  }
}
