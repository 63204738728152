import { TcGeoCoordinates } from '@timecount/core';

type TcMapMarkerCircle = {
  radiusMeters: number;
  fillColor?: string;
  color?: string;
};

export class TcMapMarker {
  location: TcGeoCoordinates;

  // Red is default color for map markers
  color? = '0xFF0000';
  label?: string;
  circle?: TcMapMarkerCircle;

  constructor(init: TcMapMarker) {
    Object.assign(this, init);
  }

  public getPin?(): string {
    let pin: string;

    pin = `color:${this.color}|`;

    if (this.label) {
      pin += `label:${this.label[0]}|`;
    }

    pin += `${this.location.latitude},${this.location.longitude}`;

    return pin;
  }

  public hasCircle?(): boolean {
    // Zero radius means no circle.
    return !!this.circle?.radiusMeters;
  }

  public getEncodedCirclePath?(): string {
    if (!this.hasCircle()) {
      return;
    }

    // If there's no specific circle color, use the pin color, unless it is the
    // default primary color, which is red and would look like an error.
    const circleColor =
      this.circle.color ?? this.color === '0xFF0000' ? '0x3D4C5C' : this.color;

    return `fillcolor:${
      // If there's no specific circle fill color, use the same color as
      // the circle pin, but with 20% opacity (33 in Hex Alpha)
      this.circle.fillColor ?? circleColor + '33'
    }|color:${circleColor}|enc:${this._drawCirclePath(
      this.location,
      this.circle.radiusMeters / 1000,
    )}`;
  }

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  /**
   * @see {@link https://stackoverflow.com/questions/7316963/drawing-a-circle-google-static-maps Drawing a circle Google Static Maps}
   * @see {@link https://stackoverflow.com/questions/54677349/draw-a-circle-path-in-google-map-static-api Draw a circle path in google map static api}
   * @see {@link https://stackoverflow.com/questions/36506668/google-static-map-draw-a-circle Google Static Map - Draw a Circle}
   */
  private _drawCirclePath?(
    center: TcGeoCoordinates,
    radiusKm: number,
    detail = 8,
  ) {
    const EARTH_RADIUS_KM = 6371;
    const PI = Math.PI;

    const lat = (center.latitude * PI) / 180;
    const lng = (center.longitude * PI) / 180;
    const d = radiusKm / EARTH_RADIUS_KM;

    const points: TcGeoCoordinates[] = [];
    let i = 0;

    for (i = 0; i <= 360; i += detail) {
      const brng = (i * PI) / 180;

      let plat = Math.asin(
        Math.sin(lat) * Math.cos(d) +
          Math.cos(lat) * Math.sin(d) * Math.cos(brng),
      );
      const plng =
        ((lng +
          Math.atan2(
            Math.sin(brng) * Math.sin(d) * Math.cos(lat),
            Math.cos(d) - Math.sin(lat) * Math.sin(plat),
          )) *
          180) /
        PI;
      plat = (plat * 180) / PI;

      points.push({
        latitude: plat,
        longitude: plng,
      });
    }

    return this._createEncodings(points);
  }

  private _createEncodings?(coords: TcGeoCoordinates[]): string {
    let plat = 0;
    let plng = 0;

    let encoded_points = '';

    for (let i = 0; i < coords.length; ++i) {
      const { latitude, longitude } = coords[i];

      encoded_points += this._encodePoint(plat, plng, latitude, longitude);

      plat = latitude;
      plng = longitude;
    }

    return encoded_points;
  }

  private _encodePoint?(
    plat: number,
    plng: number,
    lat: number,
    lng: number,
  ): string {
    let signedLatitude = 0;
    let signedLongitude = 0;

    const late5 = Math.round(lat * 1e5);
    const plate5 = Math.round(plat * 1e5);

    const lnge5 = Math.round(lng * 1e5);
    const plnge5 = Math.round(plng * 1e5);

    signedLongitude = lnge5 - plnge5;
    signedLatitude = late5 - plate5;

    return (
      this._encodeSignedNumber(signedLatitude) +
      this._encodeSignedNumber(signedLongitude)
    );
  }

  private _encodeSignedNumber?(signedNumber: number): string {
    let encodedSignedNumber = signedNumber << 1;

    if (signedNumber < 0) {
      encodedSignedNumber = ~encodedSignedNumber;
    }

    return this._encodeNumber(encodedSignedNumber);
  }

  private _encodeNumber?(sourceNumber: number): string {
    let encodeString = '';

    while (sourceNumber >= 0x20) {
      encodeString += String.fromCharCode((0x20 | (sourceNumber & 0x1f)) + 63);
      sourceNumber >>= 5;
    }
    encodeString += String.fromCharCode(sourceNumber + 63);

    return encodeString;
  }
}
