import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import {
  DispoScheduleCollection,
  Schedule,
} from '../../../collections/schedule';

@Component({
  selector: 'tc-hub-dsc-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss'],
})
export class DSCStep5Component implements OnInit {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private scheduleService: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.signal.emit({ action: 'resize' });
  }

  prev() {
    this.signal.emit({ action: 'prev', config: this.config });
  }

  save() {
    const config = this.config;

    const schedule = Object.assign(new Schedule(), {
      plan_id: config.plan_id,
      title: config.title,
      starts_at: config.range_begin,
      ends_at: config.range_end,
      slots_config: config.slots_config,
      slots_matrix: undefined,
      days_matrix: config.days_matrix,
      template: config.template,
    });

    this.loading = true;
    this.scheduleService.create(schedule).subscribe({
      next: () => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
      },
    });
  }
}
