import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import { stringIncludesAny } from '@timecount/utils';

import { TcFeedbackRequest } from './feedback-requests/feedback-request.model';
import { feedbackRequestTypes } from './feedback-requests/feedback-request-type';
import { feedbackRequestStatuses } from './feedback-requests/feedback-request-status';
import { getFeedbackRequestWithHighestPriority } from './feedback-requests/feedback-requests-helpers';

@Directive({
  selector: '[tcAssignmentStateClasses]',
})
export class TcAssignmentsStateClassesDirective implements OnChanges {
  @Input('tcAssignmentStateClasses')
  feedbackRequests!: TcFeedbackRequest[];

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges({ feedbackRequests }: SimpleChanges): void {
    if (feedbackRequests) {
      this.elementRef.nativeElement.classList.forEach((className: string) => {
        // Always remove the current classes before adding the new ones. This is
        // to prevent that classes from removed feedback-request are kept.
        if (
          className === 'has-feedback-request' ||
          (stringIncludesAny(className, feedbackRequestTypes) &&
            stringIncludesAny(className, feedbackRequestStatuses))
        ) {
          this.renderer.removeClass(this.elementRef.nativeElement, className);
        }
      });

      const highestPriorityFeedbackRequest =
        getFeedbackRequestWithHighestPriority(this.feedbackRequests);

      if (highestPriorityFeedbackRequest) {
        this.renderer.addClass(
          this.elementRef.nativeElement,
          'has-feedback-request',
        );

        const feedbackRequest = new TcFeedbackRequest(
          highestPriorityFeedbackRequest,
        );

        this.renderer.addClass(
          this.elementRef.nativeElement,
          `${feedbackRequest.type}-${feedbackRequest.status}`,
        );
      }
    }
  }
}
