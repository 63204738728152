import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { TcBasePickerDirective } from '../base/base-picker.directive';
import { TcCustomPickerBaseComponent } from '../base/custom-picker-base.component';

@Component({
  selector: 'tc-form-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class TcColorPickerComponent
  extends TcBasePickerDirective
  implements AfterViewInit
{
  colors = [
    [
      '#F58870',
      '#8abcd7',
      '#cbcbbe',
      '#fbd474',
      '#8abcc2',
      '#95c078',
      '#ddc5e7',
      '#ff9abe',
    ],
    [
      '#f0c3a2',
      '#b7dbf3',
      '#e8e9db',
      '#feeb94',
      '#c1e0db',
      '#cde0cc',
      '#f6e5fe',
      '#ffccde',
    ],
  ];

  @ViewChild('colorPicker') colorPicker: TcCustomPickerBaseComponent;

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngAfterViewInit() {
    this.init(this.colorPicker);
  }

  onModelChange(value: string) {
    this.valueChange$.next(value);
    this.colorPicker.close();
  }
}
