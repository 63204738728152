import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { BehaviorSubject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';

import { TcKeyValueFilterPipe } from '@timecount/utils';

import { CalculatedAttributes, ItemField } from '../builder-config';

@Component({
  standalone: true,
  selector: 'tc-formulas-fields-list',
  templateUrl: './fields-list.component.html',
  styleUrls: ['./fields-list.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    TranslateModule,
    NzIconModule,
    NzInputModule,
    NzMenuModule,
    NzPopoverModule,
    TcKeyValueFilterPipe,
  ],
})
export class TcFieldsListComponent {
  @Input() fields: Record<string, ItemField> = {};
  @Input() calculatedAttributes: CalculatedAttributes[] = [];

  @Input() selectCalcAttr: CalculatedAttributes | null = null;
  @Output() selectCalcAttrChange =
    new BehaviorSubject<CalculatedAttributes | null>(null);

  @Output()
  addCalcAttr = new BehaviorSubject<string | null>(null);

  @Output()
  reorderCalcAttrs = new EventEmitter<CalculatedAttributes[]>();

  uncalculatedFields: ItemField[] = [];
  fieldsFilter = '';

  // TODO: Replace this with a pipe
  isFieldCalculated(field: string): boolean {
    return this.calculatedAttributes.some((attr) => attr.field === field);
  }

  onItemClick(field) {
    if (!field.value.writable) {
      return;
    }
    this.addCalcAttr.next(field.key);
  }

  reorder(event: CdkDragDrop<string[]>) {
    const newCalculatedAttributes = [...this.calculatedAttributes];

    moveItemInArray(
      newCalculatedAttributes,
      event.previousIndex,
      event.currentIndex,
    );

    this.reorderCalcAttrs.emit(newCalculatedAttributes);
  }
}
