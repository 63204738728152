import { PanelMenu } from 'primeng/panelmenu';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'tc-hub-panel-menu',
  templateUrl: './panelmenu.component.html',
  styleUrls: ['./panelmenu.component.scss'],
  animations: [
    trigger('rootItem', [
      state(
        'hidden',
        style({
          height: '0px',
        }),
      ),
      state(
        'void',
        style({
          height: '{{height}}',
        }),
        { params: { height: '0' } },
      ),
      state(
        'visible',
        style({
          height: '*',
        }),
      ),
      transition('visible => hidden', animate('{{transitionParams}}')),
      transition('hidden => visible', animate('{{transitionParams}}')),
      transition('void => hidden', animate('{{transitionParams}}')),
      transition('void => visible', animate('{{transitionParams}}')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PanelMenuComponent extends PanelMenu {}
