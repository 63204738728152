import { Injectable } from '@angular/core';
import { ExclusionPolicy, Strategy } from 'typeserializer';

import { SetsCollection } from '../../core/sets-collection';

export enum AvailabilityImpact {
  positive = 'positive',
  negative = 'negative',
  neutral = 'neutral',
}

@Strategy(ExclusionPolicy.NONE)
export class AvailabilityType {
  id: number;
  visibility: number;

  title: string;

  impact: AvailabilityImpact;
  impact_confirmed: AvailabilityImpact;
  impact_declined: AvailabilityImpact;

  color: string;

  job_id?: number;
  job?: {
    id: number;
    title: string;
  };

  balance_type_id?: number;
  balance_type?: {
    id: string;
    title: string;
  };

  employee_readable: boolean;
  employee_creatable: boolean;
  employee_updatable: boolean;
  employee_deletable: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DispoAvailabilityTypeCollection extends SetsCollection {
  identifier = 'tc_dispo/settings/availability_type';
  endpoint = '/api/dispo/settings/availability_types';
  type = AvailabilityType;

  refreshOnStale = false;
  cache = 60 * 60 * 12;

  localSearch = ['title'];

  visible() {
    return this.query({ 'filter[visibility]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visibility]': '0' }, (e) => e.visibility === 0);
  }
}
