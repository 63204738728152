<form class="modal-container" [formGroup]="form" *ngIf="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-collection-select
              label="{{ 'dispo/schedule.attrs.plan' | translate }}"
              [collectionTypes]="['DispoPlan']"
              [formGroup]="form"
              controlName="plan_id"
              bindLabel="label"
              bindValue="id"
              [searchable]="true"
            >
            </tc-hub-collection-select>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-input-text-web
              label="{{ 'dispo/schedule.attrs.title' | translate }}"
              formControlName="title"
            ></tc-input-text-web>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <tc-interval-web
            formControlName="dateInterval"
            [tcFieldType]="'date'"
            [tcConfig]="{ limit: dateLimit, fieldType: 'date' }"
            [tcLabels]="{
              start: ('dispo/schedule.attrs.range_begin' | translate),
              end: ('dispo/schedule.attrs.range_end' | translate)
            }"
            [tcAllowZeroDuration]="true"
          ></tc-interval-web>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="copy()"
    >
      {{ 'copy' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="move()"
    >
      {{ 'move' | translate }}
    </button>
  </div>
</form>
