<ng-container>
  <nz-list nzGrid>
    <div nz-row [nzGutter]="4">
      <div nz-col [nzSpan]="isCompact ? 12 : 16">
        <nz-list-item>
          <nz-card
            [nzTitle]="'GENERAL.TIME.TIMEFRAME' | translate"
            [nzExtra]="interval"
          >
          </nz-card>
        </nz-list-item>
      </div>
      <div nz-col [nzSpan]="isCompact ? 12 : 8">
        <nz-list-item>
          <nz-card
            [nzTitle]="labelPeriods"
            [nzExtra]="intervalSummary.workDuration | duration"
          >
          </nz-card>
        </nz-list-item>
      </div>
    </div>
    <div
      nz-row
      *ngIf="intervalSummary?.workPeriods > 1 && !!expanded"
      [nzGutter]="4"
    >
      <div nz-col [nzSpan]="isCompact ? 12 : 16">
        <nz-list-item>
          <nz-card
            [nzTitle]="'GENERAL.SUMMARY.TOTAL_DURATION' | translate"
            [nzExtra]="intervalSummary.totalDuration | duration"
          ></nz-card>
        </nz-list-item>
      </div>
      <div nz-col [nzSpan]="isCompact ? 12 : 8">
        <nz-list-item>
          <nz-card
            [nzTitle]="labelBreak"
            [nzExtra]="intervalSummary.breakDuration | duration"
          ></nz-card>
        </nz-list-item>
      </div>
    </div>
  </nz-list>
</ng-container>
<ng-template #interval>
  <span
    >{{
      intervalSummary.workInterval
        | interval : (isCompact ? 'shortTime' : 'short')
    }}<sup *ngIf="isCompact && endTimeOffset > 0" class="time-offset"
      >+ {{ endTimeOffset }}</sup
    ></span
  >
</ng-template>
<ng-template #labelPeriods>
  <label class="label-hours">
    <span *ngIf="intervalSummary.workPeriods > 1; else singleWorkPeriod">
      <strong>{{ intervalSummary.workPeriods }}</strong>
      {{ 'GENERAL.SUMMARY.WORK_PERIOD' | translate }}
    </span>
    <ng-template #singleWorkPeriod>
      {{ 'GENERAL.TIME.DURATION' | translate }}
    </ng-template>
  </label>
</ng-template>
<ng-template #labelBreak>
  <label class="label-hours">
    <strong>{{ intervalSummary.breakPeriods }}</strong>
    {{ 'GENERAL.SUMMARY.BREAKS' | translate }}
  </label></ng-template
>
