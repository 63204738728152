import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-checkbox-widget',
  templateUrl: './checkbox-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './checkbox-widget.component.scss',
  ],
})
export class CheckboxWidgetComponent extends TcControlWidgetBaseDirective {}
