import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { CurrentUserService } from '../../core/current-user.service';
import { SelectableDepartment } from '../../core/types/department.model';
import { DepartmentCollection } from '../../shared/departments/department.collection';

@Component({
  selector: 'tc-hub-user-department-select',
  templateUrl: './user-department-select.component.html',
  styleUrls: ['./user-department-select.component.scss'],
})
export class UserDepartmentSelectComponent implements OnInit, OnDestroy {
  constructor(
    private departmentCollection: DepartmentCollection,
    private currentUserService: CurrentUserService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  user$: Observable<unknown>;
  formGroup: UntypedFormGroup;
  destroyed$ = new Subject<void>();
  isFromAnotherAccount = window.config.user.from_another_account;

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit(): void {
    this._getUser();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackByMethod(_, department): number {
    return department.id;
  }

  // ---------------
  // Private Methods
  // ---------------

  private _getUser() {
    this.user$ = this.departmentCollection.all().pipe(
      switchMap((departments: SelectableDepartment[]) =>
        this.currentUserService.getUserMenuData(departments),
      ),
      tap((user) => {
        this._setFormGroup(user[`departments`]);
      }),
    );
  }

  private _setFormGroup(departments: SelectableDepartment[]) {
    const values = Object.fromEntries(
      departments.map((item) => [item.id, item.isSelected]),
    );

    if (this.formGroup instanceof UntypedFormGroup) {
      this.formGroup.get('departments').markAsPristine();
    } else {
      this.formGroup = this.formBuilder.group({
        departments: this.formBuilder.group(values),
      });

      this.formGroup
        .get('departments')
        .valueChanges.pipe(throttleTime(2000), takeUntil(this.destroyed$))
        .subscribe((value) => {
          this.currentUserService.updateDepartments(
            this._getDepartmentsValue(value),
          );
        });
    }
  }

  private _getDepartmentsValue(value) {
    return Object.keys(value)
      .filter((key) => value[key] === true)
      .map((key) => parseInt(key));
  }
}
