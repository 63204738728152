<div class="tc-modal-body" #modalBody>
  <ng-container *ngIf="section === 'create'">
    <tc-hub-dmsg-create
      class="modal-container"
      [message]="messages[0]"
      [type]="type"
      (signal)="onSignal($event)"
    ></tc-hub-dmsg-create>
  </ng-container>

  <ng-container *ngIf="section === 'multi'">
    <tc-hub-dmsg-multi
      class="modal-container"
      [messages]="messages"
      [type]="type"
      (signal)="onSignal($event)"
    ></tc-hub-dmsg-multi>
  </ng-container>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
