<div
  class="wrapper"
  tcHubDispoMenuActive
  activeType="DispoAvailability"
  [activeId]="availability.id"
  [ngClass]="availability.state"
  nz-popover
  [nzPopoverTitle]="availabilityDetailsTitle"
  [nzPopoverContent]="availabilityDetailsContent"
  nzPopoverOverlayClassName="tc-popover with-padding"
>
  <label class="label availability-label">
    <ng-container *ngIf="availability.shift; else availabilityInterval">
      {{ availability.shift.title }}
      {{ availability.title || availability.type?.title }}
      {{
        'dispo/availability.label.employees.' + availability.type.id
          | translate : { availability: availability, fallback: '' }
      }}
      {{
        'dispo/availability.label.employees'
          | translate : { availability: availability, fallback: '' }
      }}
    </ng-container>
    <ng-template #availabilityInterval>
      <span>
        {{ availability.starts_at | date : timeFormat }} -
        {{ availability.ends_at | date : timeFormat }}
        {{ availability.title || availability.type?.title }}
        {{
          'dispo/availability.label.employees'
            | translate : { availability: availability, fallback: '' }
        }}
        {{
          'dispo/availability.label.employees.' + availability.type.id
            | translate : { availability: availability, fallback: '' }
        }}
      </span>
    </ng-template>
  </label>

  <ng-template #availabilityDetailsTitle>
    <ng-container [ngSwitch]="availability.state">
      <i
        *ngSwitchCase="'confirmed'"
        nz-icon
        nzType="check-circle"
        nzTheme="outline"
      ></i>
      <i
        *ngSwitchCase="'declined'"
        nz-icon
        nzType="close-circle"
        nzTheme="outline"
      ></i>
      <i *ngSwitchDefault nz-icon nzType="info-circle" nzTheme="outline"></i>
    </ng-container>
    <span> {{ availability.title || availability.type?.title }}</span>
  </ng-template>
  <ng-template #availabilityDetailsContent>
    <span>
      {{ availability.starts_at | date : dateFormat }} -
      {{ availability.ends_at | date : dateFormat }}
    </span>
    <span *ngIf="availability.note"><br />{{ availability.note }}</span>
    <br />
    {{
      'dispo/availability.label.employees_tooltip'
        | translate : { availability: availability, fallback: '' }
    }}
    <br />
    {{
      'dispo/availability.label.employees_tooltip.' + availability.type.id
        | translate : { availability: availability, fallback: '' }
    }}
  </ng-template>
</div>
