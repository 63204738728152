<ul nz-menu [nzInlineIndent]="8" nzMode="inline" class="sider-menu">
  <li nz-submenu nzOpen nzTitle="{{ 'formulas.fields.customized' | translate }}">
    <ul cdkDropList (cdkDropListDropped)="reorder($event)">
      <li
        *ngFor="let calcAttr of calculatedAttributes; let isFirst = first"
        cdkDrag
        nz-menu-item
        [nzSelected]="calcAttr.field === selectCalcAttr?.field"
        (click)="selectCalcAttrChange.next(calcAttr)"
      >
        <span
          nz-icon
          [style.color]="
            !calcAttr.result
              ? 'orange'
              : calcAttr.result.success
              ? 'green'
              : 'red'
          "
          [nzType]="
            !calcAttr.result
              ? 'reload'
              : calcAttr.result.success
              ? 'check-circle'
              : 'close-circle'
          "
          nzTheme="outline"
        ></span>
        <span class="list-item__label">{{
          fields[calcAttr.field] ? fields[calcAttr.field].title : calcAttr.field
        }}</span>
        <span
          class="list-item__info-icon"
          nz-icon
          nzType="info-circle"
          nzTheme="outline"
          nz-popover
          [nzPopoverTitle]="calcAttr.field"
          [nzPopoverContent]="valueTemplate"
        ></span>
        <ng-template #valueTemplate>
          <pre class="value-popover">{{ calcAttr.result | json }}</pre>
        </ng-template>
      </li>
    </ul>
  </li>
  <li nz-submenu nzOpen nzTitle="{{ 'formulas.fields.pristine' | translate }}">
    <nz-input-group [nzSuffix]="variableClearTmpl">
      <input nz-input placeholder="{{ 'search' | translate }}" [(ngModel)]="fieldsFilter" />
    </nz-input-group>
    <ng-template #variableClearTmpl>
      <span
        nz-icon
        class="ant-input-clear-icon"
        nzTheme="fill"
        nzType="close-circle"
        *ngIf="fieldsFilter"
        (click)="fieldsFilter = ''"
      ></span>
    </ng-template>
    <ul>
      <ng-container
        *ngFor="let field of fields | keyvalue | keyValueFilter : fieldsFilter"
      >
        <li
          *ngIf="field.key !== 'store' && !isFieldCalculated(field.key)"
          nz-menu-item
          [nzDisabled]="field.value.writable === false"
          (click)="onItemClick(field)"
        >
          <span
            nz-icon
            [nzType]="field.value.writable ? 'plus-circle' : 'warning'"
            nzTheme="outline"
          ></span>
          <span class="list-item__label">
            {{ field.value.title }}
          </span>
          <span
            class="list-item__info-icon"
            nz-icon
            nzType="info-circle"
            nzTheme="outline"
            nz-popover
            [nzPopoverTitle]="field.key"
            [nzPopoverContent]="valueTemplate"
          ></span>
          <ng-template #valueTemplate>
            <pre class="value-popover">{{ field.value | json }}</pre>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>
