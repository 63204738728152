import { Injectable } from '@angular/core';
import { get } from 'lodash-es';

import { TcConfig, TcConfigApi, TcConfigCompany } from './models';

@Injectable({
  providedIn: 'root',
})
export class TcConfigService {
  set config(value: TcConfig) {
    this._config = value;

    // Store the config locally to make sure it's not lost on app refresh,
    // avoiding unnecessary http requests when it's API  provided.
    localStorage.setItem('tc-app-config', JSON.stringify(value));
  }
  get config(): TcConfig {
    // TODO: Add reviver function when release is merge back to develop
    return (
      this._config ??
      JSON.parse(localStorage.getItem('tc-app-config') ?? 'null')
    );
  }
  private _config!: TcConfig;

  // TODO: Add support for more configuration from the api
  init(apiConfig: TcConfigApi) {
    this.config = {
      company: TcConfigCompany.fromApi(apiConfig.company),
      server: apiConfig.server,
      lookupMaps: new Map(
        apiConfig.lookupMaps?.map((map) => [map.key, map.data]),
      ),
    };
  }

  get(path: string): unknown {
    return get(this.config, path);
  }
}
