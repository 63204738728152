import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';

import { AggregationCollection } from '../aggregation.collection';
import { Project } from '../../project.model';
import { Aggregation } from '../aggregation.model';
import { CurrentUserService } from '../../../core/current-user.service';

@Component({
  selector: 'tc-hub-aggregation-conditions-form',
  templateUrl: './aggregations-conditions-form.component.html',
  styleUrls: ['./aggregations-conditions-form.component.scss'],
})
export class TcAggregationsConditionsFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  formGroup: FormGroup;

  @Input() tcInitialValue: Aggregation;
  @Input() tcProject: Project;

  @Input() triggerSave: boolean;
  @Output() updatedValues: EventEmitter<any> = new EventEmitter();

  groupingOptions = [];

  destroyed$ = new Subject<void>();

  hasAccessToXrechnung =
    this._currentUserService.hasAccessToFeature('xrechnung');

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _aggregationCollection: AggregationCollection,
    private _currentUserService: CurrentUserService,
  ) {}
  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this._buildForm();
  }

  ngOnChanges({ triggerSave }: SimpleChanges): void {
    if (triggerSave?.currentValue) {
      this.save();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // --------------
  // Public Methods
  // --------------
  save() {
    const {
      aggregationConditionsXrechnung,
      aggregationConditions,
      aggregationHandleConditions,
      xre_bt3,
      xre_bt10,
      xre_bt11,
      xre_bt12,
      xre_bt13,
      xre_bt89,
      xre_bt44,
      xre_bt51,
      xre_bt163,
      xre_bt55,
      ...formValues
    } = this.formGroup.getRawValue();
    const payload = {
      ...this.tcInitialValue,
      ...formValues,
      store: _.merge(
        this.tcInitialValue?.store,
        aggregationConditionsXrechnung,
        aggregationHandleConditions,
        aggregationConditions,
      ),
      xre_settings: {
        ...this.tcInitialValue?.xre_settings,
        xre_bt3,
        xre_bt10,
        xre_bt11,
        xre_bt12,
        xre_bt13,
        xre_bt89,
        xre_bt44,
        xre_bt51,
        xre_bt163,
        xre_bt55,
      },
    };
    this._aggregationCollection
      .forProject(this.tcProject.id)
      .update(this.tcInitialValue.id, payload);
    this.updatedValues.emit(payload);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm() {
    const {
      xre_settings,
      term,
      vat_included,
      reverse_charge,
      vat_identification,
      locked,
      store,
    } = this.tcInitialValue ?? {};
    const {
      xre_bt3,
      xre_bt10,
      xre_bt11,
      xre_bt12,
      xre_bt13,
      xre_bt89,
      xre_bt44,
      xre_bt51,
      xre_bt163,
      xre_bt55,
    } = xre_settings ?? {};

    this.formGroup = this._formBuilder.group({
      term: [term],
      vat_included: [vat_included],
      reverse_charge: [reverse_charge],
      vat_identification: [
        { value: vat_identification, disabled: !reverse_charge },
      ],
      xre_bt3: [xre_bt3],
      xre_bt10: [xre_bt10],
      xre_bt11: [xre_bt11],
      xre_bt12: [xre_bt12],
      xre_bt13: [xre_bt13],
      xre_bt89: [xre_bt89],
      xre_bt44: [xre_bt44],
      xre_bt51: [xre_bt51],
      xre_bt163: [xre_bt163],
      xre_bt55: [xre_bt55],
      aggregationConditionsXrechnung: [store],
      aggregationHandleConditions: [store],
      aggregationConditions: [store],
    });

    if (locked) {
      this.formGroup.disable();
    } else {
      const includeControl = this.formGroup.get('vat_included');
      const reverseChargeControl = this.formGroup.get('reverse_charge');
      const vatNumberControl = this.formGroup.get('vat_identification');

      reverseChargeControl.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value) => {
          includeControl.setValue(!value, { emitEvent: false });
          if (value) {
            includeControl.disable();
            vatNumberControl.enable();
          } else {
            includeControl.enable();
            vatNumberControl.disable();
          }
        });
    }
  }
}
