import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { TcFieldSetIntervalSetService } from '@timecount/ui';
import { TcConfigService } from '@timecount/core';

import { CollectionType } from '../../../shared/models/collection-type.model';
import { CollectionService } from '../../../core/collection.service';
import { WorkEntryCollection } from '../../../projects/aggregations/work-entries/work_entry.collection';
import { DispoImport } from '../dispo-import.component';
import { WorkEntry } from '../../../projects';

@Component({
  selector: 'tc-hub-dim-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class DIMStep2Component implements OnInit {
  @Input() import: DispoImport;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;

  extendAll$ = new Subject();

  isJobOptionsLoading = true;
  jobOptions$: Observable<unknown[]>;

  isMulti = window.config.company.multi_breaks;
  allowsPlus24Hours =
    this.configService.config.company.times.allowDoubledWorkingDay;

  private entries: WorkEntry[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private collectionService: CollectionService,
    private workEntryCollection: WorkEntryCollection,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
    private configService: TcConfigService,
  ) {
    this._getOptions();
  }

  ngOnInit(): void {
    this._buildForm([]);

    const service = this.workEntryCollection.forProject(
      this.import.project_id,
      this.import.aggregation_id,
    );

    service.all().subscribe({
      next: (workEntries) => {
        this.entries = workEntries.map((entry) => {
          return {
            id: entry.id,
            title: entry.title,
            starts_at: new Date(entry.starts_at.getTime()),
            ends_at: new Date(entry.ends_at.getTime()),
            intermission_starts_at: entry.intermission_starts_at
              ? new Date(entry.intermission_starts_at.getTime())
              : undefined,
            intermission_ends_at: entry.intermission_ends_at
              ? new Date(entry.intermission_ends_at.getTime())
              : undefined,
            venue_id: entry.venue_id,
            sku_id: entry.sku_id,
            sku_type: entry.sku_type,
            count: entry.count,
            times: entry.times,
          };
        });

        this._buildForm(this.entries);

        this.signal.emit({ action: 'resize' });
      },
    });
  }

  validate() {
    return this.form?.value?.entries.some((entry) => entry.import)
      ? null
      : {
          entriesPresent: {
            valid: false,
          },
        };
  }

  prev() {
    this.signal.emit({ action: 'prev', import: this.import });
  }

  next() {
    const { description, entries } = this.form.value;

    const formattedEntries = entries
      .filter((entry) => !!entry.import)
      .map((entry, index: number) => {
        const initialValue = this.entries[index];
        const { date: _, ...formValue } = entry;

        return {
          ...initialValue,
          ...formValue,
          description,
        };
      });

    this.signal.emit({
      action: 'next',
      import: {
        ...this.import,
        entries: formattedEntries,
      },
    });
  }

  applyToAll(path: string) {
    const applyToAllControl = this.form.get(`applyToAll.${path}`);
    const entries = this.form.get('entries') as UntypedFormArray;

    const { value } = applyToAllControl;

    entries.controls.forEach((control) => {
      const specificControl = control.get(path);
      if (!specificControl?.disabled) {
        specificControl.setValue(value);

        if (path === 'import') {
          this.toggle(control);
        } else {
          // We need to mark the updated controls as touched for the validation
          // feedback to appear.
          specificControl.markAsTouched({ onlySelf: true });
        }
      }
    });

    applyToAllControl.reset();
  }

  toggle(entryControl) {
    const importTask = entryControl.value.import;
    const fields = ['count', 'sku_id', 'title', 'times'];

    fields.forEach((field) => {
      const control = entryControl.get(field);

      if (importTask) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }

  // ---------------
  // Private Methods
  // ---------------

  private _getOptions() {
    this.jobOptions$ = this.collectionService
      .get(CollectionType.Job)
      .all()
      .pipe(
        take(1),
        tap(() => {
          this.isJobOptionsLoading = false;
        }),
      );
  }

  private _buildForm(entries = []) {
    this.form = this.formBuilder.group(
      {
        applyToAll: this._getRowFormGroup(),
        entries: this.formBuilder.array(
          entries.map((entry) => this._getRowFormGroup(entry)),
        ),
      },
      {
        validator: Validators.compose([this.validate.bind(this)]),
      },
    );
  }

  private _getRowFormGroup(entry?): UntypedFormGroup {
    const { title, count, sku_id, store, starts_at } = entry ?? {};
    const validators = entry ? [Validators.required] : [];

    return this.formBuilder.group({
      import: [!!entry],
      title: [title, validators],
      count: [count, validators],
      sku_id: [sku_id, validators],
      date: [starts_at],
      times: entry
        ? [this.fieldSetIntervalSetService.parseFromApi(entry), validators]
        : [],
      store: [store],
    });
  }
}
