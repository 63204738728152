import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import {
  IntervalSummary,
  TcConfigService,
  TcIntervalSet,
  TcIntervalWithBreak,
} from '@timecount/core';
import { TcTimesSummaryService } from '@timecount/ui';

import { DispoAssignmentsService } from '../dispo-assignments.service';
import { DASItemComponent } from '../item.component';

@Component({
  selector: 'tc-hub-das-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class DASScheduleComponent
  extends DASItemComponent
  implements OnInit, OnChanges
{
  @Input() item: any;

  begin: string;
  end: string;

  scheduleSummary: IntervalSummary;
  parsedScheduleTimes: Interval[];

  constructor(
    protected service: DispoAssignmentsService,
    private summaryService: TcTimesSummaryService,
    private configService: TcConfigService,
  ) {
    super(service);
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    const targetButton = !!$event
      .composedPath()
      .find((item) => item.nodeName?.toLowerCase() === 'button');

    if ($event.type === 'mouseup' && $event.button === 2) {
      return;
    }
    if (targetButton) {
      return;
    }

    const nextState = $event.type === 'contextmenu' ? true : !this.item.checked;
    this.selectItem(this.item.type, this.item.id, nextState, $event);
  }

  ngOnInit() {
    const offset = this.item.template.offset;
    const length = this.item.template.length;
    const padNumber = (n: number) => (n + '').padStart(2, '0');

    this.begin = `${padNumber(Math.floor(offset / 3600))}:${padNumber(
      Math.floor((offset / 60) % 60),
    )}`;
    this.end = `${padNumber(
      Math.floor((offset + length) / 3600) % 24,
    )}:${padNumber(Math.floor(((offset + length) / 60) % 60))}`;
  }

  ngOnChanges({ item }: SimpleChanges): void {
    if (item) {
      this.parsedScheduleTimes = TcIntervalSet.timeSetToIntervalList(
        TcIntervalWithBreak.fromOffSetLength(this.item.template),
      ) as Interval[];
      const parsedTimes = this.configService.config.company.times
        .allowMultiBreaks
        ? this.parsedScheduleTimes
        : TcIntervalWithBreak.fromOffSetLength(this.item.template);

      this.scheduleSummary = this.summaryService.getSummary(parsedTimes);
    }
  }
}
