import {
  feedbackRequestStatusesPriority,
  TcFeedbackRequestStatus,
} from './feedback-request-status';
import {
  feedbackRequestTypesPriority,
  TcFeedbackRequestType,
} from './feedback-request-type';
import { TcFeedbackRequest } from './feedback-request.model';

export const feedbackRequestsOfType = (
  feedbackRequests: TcFeedbackRequest[],
  type?: TcFeedbackRequestType | TcFeedbackRequestType[],
) => {
  return (Array.isArray(feedbackRequests) ? feedbackRequests : [])
    .map((feedbackRequest) => new TcFeedbackRequest(feedbackRequest))
    .filter(
      (feedbackRequest) =>
        !type ||
        (Array.isArray(type) &&
          (type.length === 0 || type.includes(feedbackRequest.type))) ||
        type === feedbackRequest.type,
    );
};

export const feedbackRequestsOnStatus = (
  feedbackRequests: TcFeedbackRequest[],
  status: TcFeedbackRequestStatus | TcFeedbackRequestStatus[],
  type?: TcFeedbackRequestType | TcFeedbackRequestType[],
) => {
  return feedbackRequestsOfType(feedbackRequests, type).filter(
    (feedbackRequest) =>
      (Array.isArray(status) &&
        (status.length === 0 || status.includes(feedbackRequest.status))) ||
      status === feedbackRequest.status,
  );
};

export const hasFeedbackRequestsOnStatus = (
  feedbackRequests: TcFeedbackRequest[],
  status: TcFeedbackRequestStatus | TcFeedbackRequestStatus[],
  type?: TcFeedbackRequestType | TcFeedbackRequestType[],
): boolean => {
  return feedbackRequestsOnStatus(feedbackRequests, status, type).length > 0;
};

export const hasFeedbackRequestsOfType = (
  feedbackRequests: TcFeedbackRequest[],
  type?: TcFeedbackRequestType | TcFeedbackRequestType[],
): boolean => {
  return feedbackRequestsOfType(feedbackRequests, type).length > 0;
};

export const feedbackRequestsSortByPriority = (
  feedbackRequests: TcFeedbackRequest[],
): TcFeedbackRequest[] => {
  return (feedbackRequests ?? []).sort((a, b) => {
    const statusPriority =
      feedbackRequestStatusesPriority[a.status] -
      feedbackRequestStatusesPriority[b.status];

    // If one has a higher status priority then the other, we rearrange them
    if (statusPriority !== 0) return statusPriority;

    // If both are scheduled, we order by response start
    if (a.status === 'scheduled' && b.status === 'scheduled') {
      // const { start: aStart } = a.requirements?.responseInterval ?? {};
      const aStart = new Date(a.requirements?.responseInterval?.start as Date);
      // const { start: bStart } = b.requirements?.responseInterval ?? {};
      const bStart = new Date(b.requirements?.responseInterval?.start as Date);

      return aStart.getTime() - bStart.getTime();
    }

    return (
      feedbackRequestTypesPriority[a.type] -
      feedbackRequestTypesPriority[b.type]
    );
  });
};

export const getFeedbackRequestWithHighestPriority = (
  feedbackRequests: TcFeedbackRequest[],
): TcFeedbackRequest => {
  return feedbackRequestsSortByPriority(feedbackRequests)[0];
};
