import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { IntervalSummary, TcIntervalList } from '@timecount/core';
import {
  TcFieldSetIntervalSetService,
  TcTimesSummaryService,
} from '@timecount/ui';

import { FinancesEntriesService } from '../finances-entries.service';
import { FinancesEntriesItemComponent } from '../item.component';
import { BillingStateInfo } from '../../../projects/entry-billing-state.model';
import { ZoneGroup } from '../../../core/types/zone-group.model';
import { ZoneCost } from '../../../projects/zone-cost.model';

@Component({
  selector: 'tc-hub-finances-entries-work-entry',
  templateUrl: './work_entry.component.html',
  styleUrls: ['./work_entry.component.scss'],
})
export class FinancesEntriesWorkEntryComponent
  extends FinancesEntriesItemComponent
  implements OnChanges
{
  @Input() item: any;
  @Input() zoneGroups: ZoneGroup[];

  timesheetSummary: IntervalSummary;
  parsedTimesheetTimes: Interval[];
  billingStateInfo = BillingStateInfo;

  constructor(
    protected service: FinancesEntriesService,
    private intervalSetService: TcFieldSetIntervalSetService,
    private summaryService: TcTimesSummaryService,
  ) {
    super(service);
  }

  getZoneCostValue(zones: ZoneCost[], zoneGroup: ZoneGroup): ZoneCost | null {
    return zones?.find((zc) => parseInt(zc.zg_id) === zoneGroup.id) || null;
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    if ($event.type === 'mouseup' && $event.button === 2) {
      return;
    }

    const nextState = $event.type === 'contextmenu' ? true : !this.item.checked;
    this.selectItem(this.item.type, this.item.id, nextState, $event);
  }

  ngOnChanges({ item }: SimpleChanges): void {
    if (item) {
      this.parsedTimesheetTimes = TcIntervalList.parseFromApi(this.item);

      const parsedTimes = this.intervalSetService.parseFromApi(this.item);
      this.timesheetSummary = this.summaryService.getSummary(parsedTimes);
    }
  }
}
