import { Component, OnInit } from '@angular/core';

import { BaseModalComponent } from '@timecount/ui';

import { Plan } from '../collections/plan';

@Component({
  selector: 'tc-hub-dispo-plan',
  templateUrl: './dispo-plan.component.html',
  styleUrls: ['./dispo-plan.component.scss'],
})
export class DispoPlanComponent extends BaseModalComponent implements OnInit {
  plan: Plan;

  tabs = {
    general: 0,
  };

  ngOnInit() {
    super.ngOnInit();
    this.plan = this.config.data.plan;
  }
}
