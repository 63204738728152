import { Material } from '../../core/types/material.model';
import { Aggregation } from '../../projects/aggregations/aggregation.model';
import { Project } from '../../projects/project.model';
import { ServiceEntry } from '../../projects/service-entry/service-entry.model';

export class ServiceEntryDS extends ServiceEntry {
  readonly type = 'ServiceEntry';

  project: Project;
  aggregation?: Aggregation;

  sku?: Material;
  venue?: any;
}
