import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

import { DispoAssignmentCollection } from '../../../dispo/collections/assignment';
import { Assignment } from '../../../dispo/collections/assignment.model';
import { DispoAssignService } from '../../../dispo/collections/assign.service';
import { Task } from '../../../dispo/collections/task';
import { DispoCombinedTaskCollection } from '../../../dispo/collections/combined_task';

@Component({
  selector: 'tc-hub-da-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css'],
})
export class DAEmployeeComponent implements OnInit {
  @Input() assignments: Assignment[];

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private assignmentCollection: DispoAssignmentCollection,
    private combinedTaskCollection: DispoCombinedTaskCollection,
    private formBuilder: UntypedFormBuilder,
    private assignService: DispoAssignService,
  ) {}

  ngOnInit() {
    this.form = this.buildForm();
  }

  assign() {
    const formValues = this.form.value;

    this.loading = true;
    this.combinedTaskCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (tasks) => {
          this.loading = false;

          const actions = [];
          this.assignments.forEach((assignment) => {
            assignment = Object.assign(new Assignment(), assignment, {
              resource_id: formValues.employee_id,
              resource_type: 'Employee',
            });

            const task = <Task>tasks.find((t) => t.id === assignment.task_id);
            actions.push({
              assignment: assignment,
              task: task,
              position: assignment.position,
              type: assignment.id ? 'update' : 'create',
            });
          });

          this.assignService.run(actions);
          this.signal.emit({ action: 'close' });
        },
      });
  }

  private buildForm() {
    return this.formBuilder.group({
      employee_id: [undefined, [Validators.required]],
    });
  }
}
