<div class="modal-container">
  <div class="modal-body">
    <nz-alert
      *ngFor="let message of messages"
      nzType="error"
      [nzMessage]="message"
    ></nz-alert>

    <div *ngFor="let group of taskNonDeletables">
      <strong
        >{{ group.item.starts_at | date: 'd. LLL' }} {{ group.item.job.title }}
      </strong>
      <span
        >{{ group.item.starts_at | date: 'HH:mm' }} -
        {{ group.item.ends_at | date: 'HH:mm' }}</span
      >

      <nz-alert
        *ngFor="let message of group.messages"
        nzType="error"
        [nzMessage]="message"
      ></nz-alert>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="secondary"
      [nzLoading]="loading"
      (click)="cancel()"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="!deletable"
      (click)="delete()"
    >
      {{ 'delete' | translate }}
    </button>
  </div>
</div>
