import {
  DateUnicodeFormat,
  isValidDate,
  parseDate,
  stringifyDate,
} from '@timecount/utils';

export type TcIntervalApi = {
  [`starts_at`]: string;
  [`ends_at`]: string;
};

// TODO: Replace all `Interval` types definitions in the app/libs with
// `TcInterval` to avoid the need for `as Date` when calling methods that expect
// Date object as arguments.
export class TcInterval implements Interval {
  constructor(public start: Date, public end: Date) {}

  static parseFromApi(
    apiObject,
    options: { parseFormat?: DateUnicodeFormat } = {},
  ): TcInterval {
    let { starts_at: start, ends_at: end } = apiObject ?? {};
    const { parseFormat } = options;

    if (start && !isValidDate(start)) {
      start = parseDate(start, parseFormat);
    }
    if (end && !isValidDate(end)) {
      end = parseDate(end, parseFormat);
    }

    return { start, end };
  }

  static formatForApi(value: TcInterval) {
    const { start, end } = value;
    return {
      [`starts_at`]: stringifyDate(start as Date),
      [`ends_at`]: stringifyDate(end as Date),
    };
  }
}
