import { Component, Input, OnInit } from '@angular/core';

import { getBreakIntervalsFromIntervalList } from '@timecount/utils';

@Component({
  selector: 'tc-hub-breaks-list-table',
  templateUrl: './breaks-list-table.component.html',
  styleUrls: ['./breaks-list-table.component.scss'],
})
export class BreaksListTableComponent implements OnInit {
  @Input() timesList: Interval[];
  @Input() showDate = false;

  breaksList: Interval[] = [];

  ngOnInit(): void {
    this.breaksList = getBreakIntervalsFromIntervalList(this.timesList);
  }
}
