<ng-container *ngIf="visible">
  <div
    class="row"
    [class.expanded]="expanded$ | async"
    [class.loading]="loading"
  >
    <div class="col col-header">
      <div class="schedule-toggle">
        <div class="toggle-header">
          <tc-hub-toggle
            [active$]="expanded$"
            (mouseup)="onToggleSchedule()"
          ></tc-hub-toggle>
          <span class="title"
            nz-popover
            nzPopoverOverlayClassName="tc-popover with-padding"
            [nzPopoverContent]="schedulePopover">
            <ng-container *ngTemplateOutlet="scheduleTitle"></ng-container>
          </span>
        </div>
        <tc-hub-data-panel
          [data]="schedule$ | async"
          section="dispo.schedules.schedule"
          class="schedule-stats"
        ></tc-hub-data-panel>

        <ng-template #scheduleTitle>
          <span>{{ schedule.title }}</span>
          <span>
            {{ scheduleTimes() }}
          </span>
          <span *ngIf="(schedule$ | async).venue">
            @ {{ (schedule$ | async).venue.title }}
          </span>
        </ng-template>
        <ng-template #schedulePopover>
          <ng-container *ngTemplateOutlet="scheduleTitle"></ng-container>
          <tc-hub-data-panel
            [data]="schedule$ | async"
            section="dispo.schedules.schedule"
            class="schedule-stats"
          ></tc-hub-data-panel>
        </ng-template>
      </div>

      <ng-container *ngIf="expanded$ | async">
        <div
          class="slot"
          *ngFor="
            let slot of slots();
            let even = even;
            let odd = odd;
            let first = first;
            let last = last
          "
          [ngClass]="{ odd: odd, even: even, first: first, last: last }"
        >
          <div class="position-wrapper">
            <span class="position">{{ slot.position + 1 }}</span>
          </div>
          <span
            class="info"
            *ngIf="slot.gratis"
            nz-tooltip
            nzTooltipTitle="{{ 'dispo/assignment.tooltip.gratis' | translate }}"
            ><i
              nz-icon
              style="color: rgb(202, 3, 3)"
              nzType="dollar"
              nzTheme="outline"
            ></i
          ></span>
          <div class="content">
            <tc-hub-dispo-slot-labels [slot]="slot"></tc-hub-dispo-slot-labels>
            <tc-hub-data-panel
              [data]="slot"
              section="dispo.schedules.slot"
              class="slot-stats"
            ></tc-hub-data-panel>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="col-body select-content">
      <div
        class="col"
        *ngFor="
          let task of tasks$ | async;
          trackBy: trackByDateAndId;
          let first = first;
          let last = last
        "
        [ngClass]="{ first: first, last: last, weekend: task.weekend }"
      >
        <ng-container *ngIf="!task.blank">
          <div class="task">
            <div
              class="day day-header selectable"
              [ngClass]="{
                empty: task.empty,
                unfilled: task.unfilled,
                filled: task.filled
              }"
              [attr.data-hslot]="task.marker[0]"
              [attr.data-vslot]="-1"
              [attr.data-taskid]="task.id"
            >
              <div
                class="shifted"
                *ngIf="task.shifted"
                nz-tooltip
                nzTooltipTitle="{{
                  'dispo/task.tooltip.shifted' | translate
                }}: {{ task.task.starts_at | date : 'HH:mm' }} - {{
                  task.task.ends_at | date : 'HH:mm'
                }}"
              >
                <i nz-icon nzType="calendar" nzTheme="fill"></i>
              </div>
              <div class="size">
                <span class="assigned" *ngIf="!task.filled"
                  >{{ task.assignment_size }}/</span
                ><strong class="actual">{{ task.size }}</strong>
              </div>
              <div class="status">
                <span
                  class="info"
                  *ngIf="task.shallow"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/task.tooltip.shallow' | translate
                  }}"
                  ><i nz-icon nzType="border-inner"></i
                ></span>
                <span
                  class="info"
                  *ngIf="task.announced"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/task.tooltip.announced' | translate
                  }}"
                  ><i nz-icon nzType="sound"></i>
                </span>
                <span
                  class="info"
                  *ngIf="task.public"
                  nz-tooltip
                  nzTooltipTitle="{{ 'dispo/task.tooltip.public' | translate }}"
                  ><i nz-icon nzType="eye" nzTheme="fill"></i>
                </span>
                <span
                  class="info"
                  *ngIf="task.requested"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/task.tooltip.requested' | translate
                  }}"
                  ><i nz-icon nzType="wifi"></i>
                </span>
                <span
                  class="info"
                  *ngIf="task.tracking_enabled"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/task.tooltip.tracking_enabled' | translate
                  }}"
                  ><i nz-icon nzType="clock-circle"></i>
                </span>
              </div>
            </div>
            <ng-container *ngIf="expanded$ | async">
              <div
                class="day selectable"
                drag="timecount/dispo_assignments"
                *ngFor="
                  let slot of task.slots;
                  let first = first;
                  let last = last;
                  let even = even;
                  let odd = odd
                "
                [tcAssignmentStateClasses]="
                  slot.responses
                    | feedbackRequestsOnStatus
                      : ['urgent']
                      : ['attend', 'onsite_checkin']
                "
                [ngClass]="{
                  overloaded: slot.overloaded,
                  missing: slot.missing,
                  blank: slot.blank,
                  first: first,
                  last: last,
                  even: odd,
                  odd: even
                }"
                [attr.data-hslot]="task.marker[0]"
                [attr.data-vslot]="slot.position"
                [attr.data-taskid]="task.id"
              >
                <div class="name">
                  <span *ngIf="!slot.missing">
                    {{ slot.resource_shorthand }}
                  </span>
                  <i
                    nz-icon
                    nzType="plus"
                    nzTheme="outline"
                    *ngIf="slot.missing"
                  >
                  </i>
                  <i nz-icon nzType="stop" nzTheme="outline" *ngIf="slot.blank">
                  </i>
                </div>
                <div class="status">
                  <span
                    class="info"
                    *ngIf="slot.overloaded"
                    nz-tooltip
                    nzTooltipTitle="{{
                      'dispo/assignment.tooltip.overloaded' | translate
                    }}"
                    ><i nz-icon nzType="warning" nzTheme="fill"></i
                  ></span>
                  <span
                    class="info"
                    *ngIf="slot.note"
                    nz-tooltip
                    nzTooltipTitle="{{ slot.note }}"
                    ><i nz-icon nzType="info-circle"></i
                  ></span>
                  <span
                    class="info"
                    *ngIf="slot.invited"
                    nz-tooltip
                    nzTooltipTitle="{{
                      'dispo/assignment.tooltip.invited' | translate
                    }}"
                    ><i nz-icon nzType="link"></i
                  ></span>
                  <span
                    class="info"
                    *ngIf="slot.gratis"
                    nz-tooltip
                    nzTooltipTitle="{{
                      'dispo/assignment.tooltip.gratis' | translate
                    }}"
                    ><i
                      nz-icon
                      style="color: rgb(202, 3, 3)"
                      nzType="dollar"
                      nzTheme="outline"
                    ></i
                  ></span>
                  <span
                    class="info"
                    *ngIf="slot.tracked"
                    [ngClass]="{
                      'times-confirmed': slot.times_state === 'confirmed',
                      'times-not-confirmed': slot.times_state === 'unconfirmed'
                    }"
                    nz-tooltip
                    nzTooltipTitle="{{
                      'dispo/assignment.tooltip.tracked' | translate
                    }}: {{ slot.starts_at | date : 'd.M. HH:mm' }} - {{
                      slot.ends_at | date : 'd.M. HH:mm'
                    }}"
                    ><i nz-icon nzType="clock-circle" nzTheme="fill"></i
                  ></span>
                  <span
                    class="info"
                    *ngIf="
                      requestWithHighestPriority(slot.responses);
                      let request
                    "
                    [class.unconfirmable]="!slot.confirmable"
                    nz-tooltip
                    nzTooltipTrigger="hover"
                    nzTooltipTitle="{{
                      'dispo/assignment.tooltip.' +
                        (!slot.confirmable
                          ? 'not_confirmable'
                          : request.type + '_' + request.status) | translate
                    }}"
                  >
                    <i
                      nz-icon
                      [nzType]="
                        request.type === 'onsite_checkin'
                          ? 'environment'
                          : request.type === 'read'
                          ? !slot.confirmable
                            ? 'meh'
                            : 'wifi'
                          : 'carry-out'
                      "
                      [tcAssignmentStateClasses]="[request]"
                    ></i>
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!visible">
  <div
    class="row row-full"
    [class.expanded]="expanded$ | async"
    [class.loading]="loading"
    tcHubObserveVisibility
    [debounceTime]="300"
    [threshold]="0.05"
    (visible)="onVisible()"
  >
    <div class="col col-header">
      <div class="schedule-toggle">
        <div class="toggle-header">
          <span class="title">
            <span>loading...</span>
          </span>
        </div>
        <div class="schedule-stats">loading...</div>
      </div>

      <ng-container *ngIf="expanded$ | async">
        <div
          class="slot"
          *ngFor="
            let slot of slots();
            let even = even;
            let odd = odd;
            let first = first;
            let last = last
          "
          [ngClass]="{ odd: odd, even: even, first: first, last: last }"
        >
          <div class="position-wrapper">
            <span class="position">{{ slot.position + 1 }}</span>
          </div>
          <div class="content"></div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
