import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, forwardRef, Input, OnInit, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  combineLatest,
  from,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

export const REMOTE_SELECT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RemoteSelectComponent),
  multi: true,
};

@Component({
  selector: 'tc-hub-schema-remote-select',
  templateUrl: './remote-select.component.html',
  styleUrls: ['./remote-select.component.scss'],
  providers: [REMOTE_SELECT_VALUE_ACCESSOR],
})
export class RemoteSelectComponent implements ControlValueAccessor, OnInit {
  @Input() url: string;
  @Input() bindLabel = 'title';
  @Input() bindPrefix;
  @Input() bindSuffix;

  public items$;
  public typeahead$: Subject<any> = new Subject<any>();
  public loading = false;

  private currentId;
  private currentId$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private currentValue$: Observable<any>;

  private _innerValue: any[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: any[]) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {};

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.currentValue$ = merge(
      of(undefined),
      this.currentId$.pipe(
        switchMap((id) => {
          if (id) {
            const url = `${this.url}/${id}`;

            const httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            };

            return from(
              this.http.get(this.url + '/' + id, httpOptions).pipe(
                catchError(() =>
                  of({ data: { id: id, title: 'Nicht gefunden' } }),
                ),
                map((resp: any) => {
                  const item = resp.data;
                  item._label = [
                    item[this.bindPrefix],
                    item[this.bindLabel],
                    item[this.bindSuffix],
                  ]
                    .filter((i) => i)
                    .join(' ');
                  return item;
                }),
              ),
            );
          } else {
            return of(undefined);
          }
        }),
      ),
    );

    this.items$ = combineLatest(
      this.currentValue$,
      merge(
        of([]),
        this.typeahead$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => (this.loading = true)),
          switchMap((query) => {
            if (!query) {
              query = ' ';
            }
            const url = `${this.url}/search/${encodeURIComponent(query)}`;

            const httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            };

            return from(this.http.get(url, httpOptions)).pipe(
              catchError(() => of({ data: [] })),
              map((resp: any) => {
                const items = resp.data.filter(
                  (item) => item.id !== this.currentId,
                );

                return items.map((item) => {
                  item._label = [
                    item[this.bindPrefix],
                    item[this.bindLabel],
                    item[this.bindSuffix],
                  ]
                    .filter((i) => i)
                    .join(' ');
                  return item;
                });
              }),
            );
          }),
        ),
      ),
    ).pipe(
      tap(() => (this.loading = false)),
      map(([item, items]) => {
        // limit items to 50
        items = items.slice(0, 50);

        if (item) {
          return [item].concat(items);
        } else {
          return items;
        }
      }),
    );
  }

  public get innerValue(): any[] {
    return this._innerValue;
  }

  public set innerValue(newValue: any[]) {
    newValue = newValue || undefined;
    this._innerValue = newValue;

    this.currentId = newValue;
    this.currentId$.next(newValue);
    this.onChangeCallback(newValue);
  }

  public onBlur() {
    this.onTouchedCallback();
  }

  public writeValue(value: any[]) {
    if (value !== this.innerValue) {
      this.innerValue = value; // transform
    }
  }

  public registerOnChange(callback: (_: any[]) => void) {
    this.onChangeCallback = callback;
  }

  public registerOnTouched(callback: () => void) {
    this.onTouchedCallback = callback;
  }
}
