<ng-content></ng-content>

<ion-item-group *ngIf="(blurred || !isInputType) && errors; else hintsTemplate">
  <ion-item
    *ngFor="let errorKey of errors | keysList"
    class="error-message"
    color="danger"
    [lines]="'none'"
  >
    <ion-label class="ion-text-wrap" fixed>
      {{ getErrorMessage(errorKey, errors[errorKey]) | async }}
    </ion-label>
  </ion-item>
</ion-item-group>

<ng-template #hintsTemplate>
  <ion-item-divider class="hint" *ngIf="hints">
    <ion-note class="ion-text-wrap" *ngFor="let hint of hints">
      {{ hint }}
    </ion-note>
  </ion-item-divider>
</ng-template>
