export function isPositiveInteger(value) {
  return /^\+?[1-9]\d*$/.test(String(value));
}

export function noNegativeZero(value: number): number {
  return Number(value.toString().replace('-0', '0'));
}

export function getSteppedNumbersArray(
  step: number,
  options: { min?: number; max?: number } = { min: 0, max: 60 },
) {
  const numbers = [];
  for (let i = options.min; i <= options.max; i += step) {
    numbers.push(i);
  }
  return numbers;
}

export function getPercentageFromNumber(
  partialValue: number,
  totalValue: number,
) {
  if (partialValue < 0 || totalValue < 0) {
    throw new Error('The passed value must not be a negative number');
  }
  const percent = (100 * partialValue) / totalValue;
  return Math.floor(percent * 100000) / 100000;
}

export function getNumberFromPercent(percent: number, totalValue: number) {
  if (percent < 0) {
    throw new Error('Percent must not be negative');
  }
  if (totalValue < 0) {
    throw new Error('Total number must not be negative');
  }
  const number = (totalValue * percent) / 100;
  return Math.round(number * 100) / 100;
}
