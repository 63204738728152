<td
  [nzExpand]="item.expanded"
  (nzExpandChange)="toggleItem(item.type, item.id)"
>
  <label
    nz-checkbox
    [(nzChecked)]="item.checked"
    [nzIndeterminate]="item.indeterminate"
  ></label>
  {{ item.title }}

  <div class="data-panel">
    <tc-hub-data-panel
      [data]="item"
      section="dispo.assignments.task.title"
    ></tc-hub-data-panel>
  </div>
</td>
<td>
  {{ item.job?.title }}
</td>
<td>{{ item.size }}</td>
<td>
  {{ item.starts_at | date : 'd.MM.' }}
</td>
<td>
  {{ item.starts_at | date : 'HH:mm' }}
</td>
<td>
  {{ item.ends_at | date : 'HH:mm' }}
</td>
<td>
  <span>{{ taskSummary.breakPeriods }}</span>
  <span
    class="breaks-info"
    *ngIf="taskSummary.breakPeriods > 0"
    nz-popover
    [nzPopoverContent]="taskTimeframes"
  >
    <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>
  </span>
</td>
<ng-template #taskTimeframes>
  <tc-hub-breaks-list-table
    [timesList]="parsedTaskTimes"
    [showDate]="true"
  ></tc-hub-breaks-list-table>
</ng-template>
<td>
  <span [class]="taskSummary.workDuration | highlightDurationLabel">{{
    taskSummary.workDuration | duration
  }}</span>
</td>
<td>{{ taskSummary.breakDuration | duration }}</td>
<td>
  <span [class]="taskSummary.workDuration | highlightDurationLabel">{{
    taskSummary.totalDuration | duration
  }}</span>
</td>
<td>
  <tc-hub-data-panel
    [data]="item"
    section="dispo.assignments.task.status"
  ></tc-hub-data-panel>
</td>
