import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared';

import { AttachmentPlaceholderComponent } from './attachment-placeholder/attachment-placeholder.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { BaseDropdownComponent } from './base-dropdown/base-dropdown.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [
    AttachmentPlaceholderComponent,
    PlaceholderComponent,
    BaseDropdownComponent,
  ],
  exports: [
    AttachmentPlaceholderComponent,
    PlaceholderComponent,
    BaseDropdownComponent,
  ],
  providers: [],
})
export class TcDropdownsModule {}
