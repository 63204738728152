import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  first,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';

import { overlaps } from '../../core/helpers';
import { DispoFilterService } from '../../shared/filter/filter.service';
import {
  DispoRangeDataStructure,
  DispoRangeDataStructureFactory,
} from '../datastructures/range.service';
import { DispoFocusService } from '../dispo-focus.service';
import { DispoMenuService } from '../menus/menu.service';
import { WorkEntryDS } from '../datastructures/work-entry-ds.model';
import { ServiceEntryDS } from '../datastructures/service-entry-ds.model';

@Injectable({
  providedIn: 'root',
})
export class FinancesEntriesService {
  private dataStructureService: DispoRangeDataStructure;
  private visibleAggregationEntries$;
  private structure$;
  private items$;
  private updateSelectedItems$ = new Subject<
    (WorkEntryDS | ServiceEntryDS)[]
  >();
  private root$ = new ReplaySubject(1);

  private hiddenItems = {
    Project: {},
    DispoPlan: {},
    DispoSchedule: {},
    DispoTask: {},
    WorkEntryGroup: {},
    ServiceEntryGroup: {},
  };
  private hiddenItems$ = new ReplaySubject(1);

  private selectedItems = {
    WorkEntry: {},
    ServiceEntry: {},
  };
  private selectedItems$ = new ReplaySubject(1);

  constructor(
    private dispoRangeDataStructureFactory: DispoRangeDataStructureFactory,
    private filterService: DispoFilterService,
    private dispoFocus: DispoFocusService,
    private contextMenuService: DispoMenuService,
  ) {
    this.dataStructureService = this.dispoRangeDataStructureFactory.all();

    this.hiddenItems$.next(this.hiddenItems);
    this.selectedItems$.next(this.selectedItems);

    this.updateSelectedItems$
      .pipe(
        debounceTime(500),
        filter(() => this.root$.observed),
      )
      .subscribe((visibleAggregationEntries) => {
        const lookup = visibleAggregationEntries.reduce((acc, ws) => {
          acc[`${ws.type}${ws.id}`] = ws;
          return acc;
        }, {});

        Object.keys(this.selectedItems.WorkEntry).forEach((k) => {
          if (!lookup[`WorkEntry${k}`]) {
            this.selectedItems.WorkEntry[k] = false;
          }
        });

        Object.keys(this.selectedItems.ServiceEntry).forEach((k) => {
          if (!lookup[`ServiceEntry${k}`]) {
            this.selectedItems.ServiceEntry[k] = false;
          }
        });

        this.selectItem('null', 0, false, new MouseEvent('click'));
      });
  }

  public toggleItem(type, id) {
    this.hiddenItems[type][id] = !this.hiddenItems[type][id];
    this.hiddenItems$.next(this.hiddenItems);
  }

  public selectItem(type, id, state, event) {
    this.visibleAggregationEntries()
      .pipe(first())
      .subscribe((worksheets) => {
        const otherContexts = [];

        if (type === 'Root') {
          worksheets.forEach((ws) => {
            this.selectedItems[ws.type][ws.id] = state;
          });
        }

        if (type === 'Project') {
          worksheets
            .filter((ws) => ws.project_id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });
        }

        if (type === 'DispoPlan') {
          worksheets
            .filter((ws) => ws.plan?.id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });

          otherContexts.push({ item_type: type, item: [{ id: id }] });
        }

        if (type === 'DispoSchedule') {
          worksheets
            .filter((ws) => ws.schedule?.id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });

          otherContexts.push({ item_type: type, item: [{ id: id }] });
        }

        if (type === 'DispoTask') {
          worksheets
            .filter((ws) => ws.task?.id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });

          otherContexts.push({ item_type: type, item: [{ id: id }] });
        }

        if (type === 'WorkEntryGroup') {
          worksheets
            .filter(
              (ws) =>
                ws.type === 'WorkEntry' &&
                ws.project_id === id &&
                !ws.assignment,
            )
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });
        }

        if (type === 'ServiceEntryGroup') {
          worksheets
            .filter((ws) => ws.type === 'ServiceEntry' && ws.project_id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });
        }

        if (type === 'WorkEntry') {
          this.selectedItems[type][id] = state;
          this.selectedItems$.next(this.selectedItems);
        }

        if (type === 'ServiceEntry') {
          this.selectedItems[type][id] = state;
          this.selectedItems$.next(this.selectedItems);
        }

        const selectedWorkEntries = Object.keys(this.selectedItems.WorkEntry)
          .filter((key) => this.selectedItems.WorkEntry[key])
          .map((id) => {
            return { id: parseInt(id), identifier: 'work_entry' };
          });

        const selectedServiceEntries = Object.keys(
          this.selectedItems.ServiceEntry,
        )
          .filter((key) => this.selectedItems.ServiceEntry[key])
          .map((id) => {
            return { id: parseInt(id), identifier: 'service_entry' };
          });

        this.contextMenuService.onViewClick(event, 'finances.entries', [
          {
            item_type: 'AggregationEntry',
            item: [...selectedServiceEntries, ...selectedWorkEntries],
          },
          ...otherContexts,
        ]);

        this.selectedItems$.next(this.selectedItems);
      });
  }

  public root(): Observable<any> {
    return this.root$.asObservable();
  }

  public items(): Observable<any[]> {
    return (this.items$ ||= combineLatest(
      this.structure(),
      this.filterService.getSort('finances.entries', 'projects'),
      this.filterService.getSort('finances.entries', 'plans'),
      this.filterService.getSort('finances.entries', 'schedules'),
      this.filterService.getSort('finances.entries', 'tasks'),
      this.filterService.getSort('finances.entries', 'work_entries'),
      this.filterService.getSort('finances.entries', 'service_entries'),
      this.hiddenItems$,
      this.selectedItems$,
    ).pipe(
      debounceTime(100),
      map(
        ([
          structure,
          projectSort,
          planSort,
          scheduleSort,
          taskSort,
          workEntriesSort,
          serviceEntriesSort,
          hiddenItems,
          selectedItems,
        ]) => {
          const rootItem = { checked: false, indeterminate: false };
          let rootItemAllEnabled = true;
          let rootItemAllDisabled = true;

          const items = [];

          Object.values(structure)
            .sort(projectSort)
            .forEach((projectGroupItem: any) => {
              items.push(projectGroupItem);

              let projectGroupItemAllEnabled = true;
              let projectGroupItemAllDisabled = true;
              const showProjectLeafs =
                !hiddenItems['Project'][projectGroupItem.id];

              // Project -> Plans
              Object.values(projectGroupItem.plans)
                .sort(planSort)
                .forEach((planGroupItem: any) => {
                  if (showProjectLeafs) {
                    items.push(planGroupItem);
                  }

                  let planGroupItemAllEnabled = true;
                  let planGroupItemAllDisabled = true;
                  const showPlanLeafs =
                    showProjectLeafs &&
                    !hiddenItems['DispoPlan'][planGroupItem.id];

                  // Project -> Plan -> Tasks
                  Object.values(planGroupItem.tasks)
                    .sort(taskSort)
                    .forEach((taskGroupItem: any) => {
                      if (showPlanLeafs) {
                        items.push(taskGroupItem);
                      }

                      let taskGroupItemAllEnabled = true;
                      let taskGroupItemAllDisabled = true;
                      const showTaskLeafs =
                        showPlanLeafs &&
                        !hiddenItems['DispoTask'][taskGroupItem.id];

                      // Project -> Plan -> Task -> WorkEntry
                      const taskWorkEntries = taskGroupItem.work_entries;

                      Object.values(taskWorkEntries)
                        .sort(workEntriesSort)
                        .forEach((workEntryGroupItem: any) => {
                          if (
                            selectedItems['WorkEntry'][workEntryGroupItem.id]
                          ) {
                            workEntryGroupItem.checked = true;

                            rootItemAllDisabled = false;
                            projectGroupItemAllDisabled = false;
                            planGroupItemAllDisabled = false;
                            taskGroupItemAllDisabled = false;
                          } else {
                            workEntryGroupItem.checked = false;

                            rootItemAllEnabled = false;
                            projectGroupItemAllEnabled = false;
                            planGroupItemAllEnabled = false;
                            taskGroupItemAllEnabled = false;
                          }

                          if (showTaskLeafs) {
                            items.push(workEntryGroupItem);
                          }
                        });

                      taskGroupItem.expanded = showTaskLeafs;
                      taskGroupItem.checked = taskGroupItemAllEnabled;
                      taskGroupItem.indeterminate =
                        !taskGroupItemAllEnabled && !taskGroupItemAllDisabled;
                    });

                  // Project -> Plan -> Schedules
                  Object.values(planGroupItem.schedules)
                    .sort(scheduleSort)
                    .forEach((scheduleGroupItem: any) => {
                      if (showPlanLeafs) {
                        items.push(scheduleGroupItem);
                      }

                      let scheduleGroupItemAllEnabled = true;
                      let scheduleGroupItemAllDisabled = true;
                      const showScheduleLeafs =
                        showPlanLeafs &&
                        !hiddenItems['DispoSchedule'][scheduleGroupItem.id];

                      // Project -> Plan -> Schedule -> WorkEntry
                      Object.values(scheduleGroupItem.work_entries)
                        .sort(workEntriesSort)
                        .forEach((workEntryGroupItem: any) => {
                          if (
                            selectedItems['WorkEntry'][workEntryGroupItem.id]
                          ) {
                            workEntryGroupItem.checked = true;

                            rootItemAllDisabled = false;
                            projectGroupItemAllDisabled = false;
                            planGroupItemAllDisabled = false;
                            scheduleGroupItemAllDisabled = false;
                          } else {
                            workEntryGroupItem.checked = false;

                            rootItemAllEnabled = false;
                            projectGroupItemAllEnabled = false;
                            planGroupItemAllEnabled = false;
                            scheduleGroupItemAllEnabled = false;
                          }

                          if (showScheduleLeafs) {
                            items.push(workEntryGroupItem);
                          }
                        });

                      scheduleGroupItem.expanded = showScheduleLeafs;
                      scheduleGroupItem.checked = scheduleGroupItemAllEnabled;
                      scheduleGroupItem.indeterminate =
                        !scheduleGroupItemAllEnabled &&
                        !scheduleGroupItemAllDisabled;
                    });

                  planGroupItem.expanded = showPlanLeafs;
                  planGroupItem.checked = planGroupItemAllEnabled;
                  planGroupItem.indeterminate =
                    !planGroupItemAllEnabled && !planGroupItemAllDisabled;
                });

              const simpleWorkEntries = Object.values(
                projectGroupItem.work_entries,
              );
              // Project -> Work Entries
              if (simpleWorkEntries.length > 0) {
                const workEntyGroupItem = {
                  id: projectGroupItem.id,
                  type: 'WorkEntryGroup',
                  checked: false,
                  indeterminate: false,
                  expanded: true,
                };

                let workEntyGroupItemAllEnabled = true;
                let workEntyGroupItemAllDisabled = true;
                const showWorkEntyGroupLeafs =
                  showProjectLeafs &&
                  !hiddenItems['WorkEntryGroup'][projectGroupItem.id];

                if (showProjectLeafs) {
                  items.push(workEntyGroupItem);
                }

                simpleWorkEntries
                  .sort(workEntriesSort)
                  .forEach((workEntryItem: any) => {
                    if (selectedItems['WorkEntry'][workEntryItem.id]) {
                      workEntryItem.checked = true;
                      rootItemAllDisabled = false;
                      workEntyGroupItemAllDisabled = false;
                      projectGroupItemAllDisabled = false;
                    } else {
                      workEntryItem.checked = false;

                      rootItemAllEnabled = false;
                      workEntyGroupItemAllEnabled = false;
                      projectGroupItemAllEnabled = false;
                    }

                    if (showWorkEntyGroupLeafs) {
                      items.push(workEntryItem);
                    }
                  });
                workEntyGroupItem.expanded = showWorkEntyGroupLeafs;
                workEntyGroupItem.checked = workEntyGroupItemAllEnabled;
                workEntyGroupItem.indeterminate =
                  !workEntyGroupItemAllDisabled && !workEntyGroupItemAllEnabled;
              }

              // Project -> Service Entries
              const serviceEntries = Object.values(
                projectGroupItem.service_entries,
              );
              if (serviceEntries.length > 0) {
                const serviceEntyGroupItem = {
                  id: projectGroupItem.id,
                  type: 'ServiceEntryGroup',
                  checked: false,
                  indeterminate: false,
                  expanded: true,
                };

                let serviceEntyGroupItemAllEnabled = true;
                let serviceEntyGroupItemAllDisabled = true;
                const showServiceEntyGroupLeafs =
                  showProjectLeafs &&
                  !hiddenItems['ServiceEntryGroup'][projectGroupItem.id];

                if (showProjectLeafs) {
                  items.push(serviceEntyGroupItem);
                }

                serviceEntries
                  .sort(serviceEntriesSort)
                  .forEach((serviceEntryItem: any) => {
                    if (selectedItems['ServiceEntry'][serviceEntryItem.id]) {
                      serviceEntryItem.checked = true;
                      rootItemAllDisabled = false;
                      serviceEntyGroupItemAllDisabled = false;
                      projectGroupItemAllDisabled = false;
                    } else {
                      serviceEntryItem.checked = false;

                      rootItemAllEnabled = false;
                      serviceEntyGroupItemAllEnabled = false;
                      projectGroupItemAllEnabled = false;
                    }

                    if (showServiceEntyGroupLeafs) {
                      items.push(serviceEntryItem);
                    }
                  });
                serviceEntyGroupItem.expanded = showServiceEntyGroupLeafs;
                serviceEntyGroupItem.checked = serviceEntyGroupItemAllEnabled;
                serviceEntyGroupItem.indeterminate =
                  !serviceEntyGroupItemAllDisabled &&
                  !serviceEntyGroupItemAllEnabled;
              }

              projectGroupItem.expanded = showProjectLeafs;
              projectGroupItem.checked = projectGroupItemAllEnabled;
              projectGroupItem.indeterminate =
                !projectGroupItemAllEnabled && !projectGroupItemAllDisabled;
            });

          rootItem.checked = rootItemAllEnabled;
          rootItem.indeterminate = !rootItemAllEnabled && !rootItemAllDisabled;
          this.root$.next(rootItem);

          return items;
        },
      ),
    ));
  }

  private structure() {
    return (this.structure$ ||= this.visibleAggregationEntries().pipe(
      debounceTime(500),
      map((aggregationEntries) => {
        const structure = {};
        aggregationEntries.forEach((entry) => {
          let leaf = undefined;

          if (entry.type === 'WorkEntry') {
            if (!entry.project) {
              return;
            }
            if (!structure[entry.project.id]) {
              structure[entry.project.id] = Object.assign(
                {
                  type: 'Project',
                  plans: {},
                  work_entries: {},
                  service_entries: {},
                },
                entry.project,
              );
            }
            leaf = structure[entry.project.id]['plans'];

            // no plan -> put on project level
            if (!entry.plan) {
              leaf = structure[entry.project.id]['work_entries'];

              leaf[entry.id] = entry;
              return;
            }

            if (!leaf[entry.plan.id]) {
              leaf[entry.plan.id] = Object.assign(
                { type: 'DispoPlan', tasks: {}, schedules: {} },
                entry.plan,
              );
            }

            if (entry.schedule) {
              leaf = leaf[entry.schedule.plan_id]['schedules'];

              if (!leaf[entry.schedule.id]) {
                leaf[entry.schedule.id] = Object.assign(
                  { type: 'DispoSchedule', work_entries: {} },
                  entry.schedule,
                );
              }

              leaf[entry.schedule.id]['work_entries'][entry.id] = entry;
            } else {
              if (!entry.task) {
                return;
              }

              leaf = leaf[entry.task.plan_id]['tasks'];

              if (!leaf[entry.task.id]) {
                leaf[entry.task.id] = Object.assign(
                  { type: 'DispoTask', work_entries: {} },
                  entry.task,
                );
              }

              leaf[entry.task.id]['work_entries'][entry.id] = entry;
            }

            //leaf = structure[entry.project_id]['work_entries'];
            //leaf[entry.id] = entry;
          }

          if (entry.type === 'ServiceEntry') {
            if (!entry.project) {
              return;
            }
            if (!structure[entry.project_id]) {
              structure[entry.project_id] = Object.assign(
                {
                  type: 'Project',
                  plans: {},
                  work_entries: {},
                  service_entries: {},
                },
                entry.project,
              );
            }
            leaf = structure[entry.project_id]['service_entries'];

            leaf[entry.id] = entry;
          }
        });

        return structure;
      }),
      shareReplay(1),
    ));
  }

  private visibleAggregationEntries(): Observable<any[]> {
    return (this.visibleAggregationEntries$ ||= combineLatest(
      this.aggregationEntriesDS(),
      this.entriesQuery(),
      this.dispoFocus.range(),
    ).pipe(
      map(([aggregationEntriesDS, query, [begin, end]]) => {
        return aggregationEntriesDS
          .filter((s) => overlaps(s.marker[0], begin, end))
          .filter(query);
      }),
      tap((visibleAggregationEntries) => {
        this.updateSelectedItems$.next(visibleAggregationEntries);
      }),
      shareReplay(1),
    ));
  }

  private entriesQuery() {
    return this.filterService.getQuery('finances.entries');
  }

  private aggregationEntriesDS(): Observable<(WorkEntryDS | ServiceEntryDS)[]> {
    return this.dataStructureService.aggregationEntries();
  }
}
