<tc-form-wrapper
  [tcFormTitle]="'aggregation_entry/service.add' | translate"
  [isModal]="isModal"
  [isSubmitting]="isSubmitting"
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  [tcExtended]="tcIsExtended"
  (tcExtendedChange)="onExtendedChange($event)"
  (primaryButtonClick)="onSave()"
>
  <form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    (ngSubmit)="onSave()"
    class="tc-grid tc-grid-2-col"
  >
    <input *ngIf="formGroup?.valid" type="submit" class="hidden" value="" />

    <tc-hub-collection-select
      [tcCanDisable]="isBatch"
      class="form-item"
      label="{{ 'aggregation_entry/service.attrs.sku' | translate }}"
      [collectionTypes]="['Material']"
      [formGroup]="formGroup"
      controlName="sku_id"
      bindLabel="title"
      bindValue="id"
      [searchable]="true"
    >
    </tc-hub-collection-select>

    <tc-input-text-web
      [tcCanDisable]="isBatch"
      class="form-item"
      label="{{ 'aggregation_entry/service.attrs.title' | translate }}"
      formControlName="title"
      [tcConfig]="{
        required: true
      }"
    ></tc-input-text-web>

    <tc-input-number-web
      [tcCanDisable]="isBatch"
      class="form-item"
      label="{{ 'aggregation_entry/service.attrs.count' | translate }}"
      formControlName="count"
      [tcConfig]="{
        required: true
      }"
    ></tc-input-number-web>

    <tc-input-date-web
      [tcCanDisable]="isBatch"
      class="form-item"
      formControlName="starts_at"
      [label]="'aggregation_entry/service.attrs.date' | translate"
      [tcConfig]="{ required: true }"
    ></tc-input-date-web>

    <tc-input-currency-web
      [tcCanDisable]="isBatch"
      class="form-item"
      formControlName="unit_price"
      [label]="'aggregation_entry/service.attrs.price' | translate"
      [tcConfig]="{
        required: true
      }"
    ></tc-input-currency-web>

    <tc-hub-collection-select
      [tcCanDisable]="isBatch"
      class="form-item"
      label="{{ 'aggregation_entry/service.attrs.vat_id' | translate }}"
      [collectionTypes]="['Vat']"
      [formGroup]="formGroup"
      controlName="vat_id"
      bindLabel="title"
      bindValue="id"
      [searchable]="true"
    >
    </tc-hub-collection-select>

    <tc-hub-custom-fields
      *ngIf="!isBatch"
      class="tc-col-12"
      section="service_entry.general"
      formControlName="serviceEntryGeneral"
    >
    </tc-hub-custom-fields>

    <tc-hub-custom-fields
      *ngIf="!isBatch && tcAggregationId"
      class="tc-col-12"
      section="service_entry.{{ tcAggregationType.handle }}.general"
      formControlName="serviceEntryHandleGeneral"
    >
    </tc-hub-custom-fields>

    <ng-container *ngIf="tcIsExtended">
      <tc-input-text-web
        [tcCanDisable]="isBatch"
        label="{{ 'aggregation_entry/service.attrs.unit' | translate }}"
        formControlName="unit"
      ></tc-input-text-web>

      <tc-hub-collection-select
        [tcCanDisable]="isBatch"
        label="{{ 'aggregation_entry/service.attrs.venue_id' | translate }}"
        [collectionTypes]="['Venue']"
        [formGroup]="formGroup"
        controlName="venue_id"
        bindLabel="title"
        bindValue="id"
        [searchable]="true"
      >
      </tc-hub-collection-select>

      <div *ngIf="!isBatch" class="tc-col-12 form-description">
        <label
          >{{ 'aggregation_entry/service.attrs.description' | translate }}
        </label>
        <textarea formControlName="description" msd-elastic></textarea>
      </div>
    </ng-container>
  </form>
</tc-form-wrapper>
