import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DispoSelectorService } from '../selector/selector.service';
import { AvailabilityService } from '../actions/availability.service';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoFilterService } from '../../shared/filter/filter.service';
import {
  Availability,
  DispoAvailabilityCollection,
} from '../collections/availability';
import { DispoFocusService } from '../dispo-focus.service';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuAvailability {
  private employeeFilters: any[] = [];
  private translations: { [key: string]: string } = {};

  private availTemplates = [];
  private date = new Date();

  constructor(
    private translateService: TranslateService,
    private focusService: DispoFocusService,
    private filterService: DispoFilterService,
    private selectorService: DispoSelectorService,
    private availActions: AvailabilityService,
    private availCollection: DispoAvailabilityCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/availability.actions.edit',
        'dispo/availability.actions.delete',
        'dispo/availability.actions.create',
        'dispo/availability.actions.unconfirm',
        'dispo/availability.actions.confirm',
        'dispo/availability.actions.decline',
        'dispo/availability.menu.availability',
        'dispo/availability.menu.template',
        'trails.actions.resource',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.focusService.begin().subscribe((date) => {
      this.date = date;
    });

    this.resourceTemplateCollection
      .setForResource('dispo.availabilities')
      .all()
      .subscribe((templates) => {
        this.availTemplates = templates;
      });
  }

  itemsForResourceAndDate(currAvails, employee, date): Observable<any[]> {
    return this.availCollection.observeItems(currAvails).pipe(
      map((avails: Availability[]) => {
        const availActions = this.baseAvailMenu(avails);

        availActions.push({
          label: this.translations['dispo/availability.actions.create'],
          icon: 'plus',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.availability.general',
          ),
          command: (event: any) => {
            const defaultTemplate = this.availTemplates.find(
              (t) => t.default,
            ) || { template: {} };
            const templ = Object.assign(
              {
                interval_type: 'range',
                resource_type: 'Employee',
                resource_id: employee.id,
                employee_id: employee.id,
                source_id: 1,
              },
              defaultTemplate.template,
            );

            this.availActions.createFromDateAndTemplate(date, templ);
          },
        });

        const templateActions: any[] = this.availTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                const templ = Object.assign(
                  {
                    interval_type: 'range',
                    resource_type: 'Employee',
                    resource_id: employee.id,
                    employee_id: employee.id,
                    source_id: 1,
                  },
                  template.template,
                );

                this.availActions.createFromDateAndTemplate(date, templ);
              },
            };
          });

        const items = [
          ...(availActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label:
                    this.translations['dispo/availability.menu.availability'],
                },
                ...availActions,
              ]
            : []),
          ...(templateActions.length !== 0
            ? [
                {
                  icon: 'copy',
                  label: this.translations['dispo/availability.menu.template'],
                  visible: this.currentUser.hasAccessToSection(
                    'views.dispo.availability.general',
                  ),
                  items: templateActions,
                },
              ]
            : []),
        ];

        return items;
      }),
    );
  }

  itemsForResource(currAvails, employee): Observable<any[]> {
    return this.availCollection.observeItems(currAvails).pipe(
      map((avails: Availability[]) => {
        const availActions = this.baseAvailMenu(avails);

        availActions.push({
          label: this.translations['dispo/availability.actions.create'],
          icon: 'plus',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.availability.general',
          ),
          command: (event: any) => {
            const defaultTemplate = this.availTemplates.find(
              (t) => t.default,
            ) || { template: {} };
            const templ = Object.assign(
              {
                interval_type: 'range',
                resource_type: 'Employee',
                resource_id: employee.id,
                employee_id: employee.id,
                source_id: 1,
              },
              defaultTemplate.template,
            );

            this.availActions.createFromDateAndTemplate(this.date, templ);
          },
        });

        const templateActions: any[] = this.availTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                const templ = Object.assign(
                  {
                    interval_type: 'range',
                    resource_type: 'Employee',
                    resource_id: employee.id,
                    employee_id: employee.id,
                    source_id: 1,
                  },
                  template.template,
                );

                this.availActions.createFromDateAndTemplate(this.date, templ);
              },
            };
          });

        const items = [
          ...(availActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label:
                    this.translations['dispo/availability.menu.availability'],
                },
                ...availActions,
              ]
            : []),
          ...(templateActions.length !== 0
            ? [
                {
                  icon: 'copy',
                  visible: this.currentUser.hasAccessToSection(
                    'views.dispo.availability.general',
                  ),
                  label: this.translations['dispo/availability.menu.template'],
                  items: templateActions,
                },
              ]
            : []),
        ];

        return items;
      }),
    );
  }

  private baseAvailMenu(avails): any[] {
    const confirmableAvails = avails.filter((a: Availability) => {
      if (this.currentUser.can('manage', 'TcDispo::Availability')) {
        return a.state !== 'confirmed';
      } else {
        return a.state !== 'confirmed' && !a.type.balance_type_id;
      }
    });

    const unconfirmableAvails = avails.filter((a: Availability) => {
      if (this.currentUser.can('manage', 'TcDispo::Availability')) {
        return a.state !== 'unconfirmed';
      } else {
        return (
          a.state === 'declined' ||
          (a.state === 'confirmed' && !a.type.balance_type_id)
        );
      }
    });

    const declinableAvails = avails.filter((a: Availability) => {
      if (this.currentUser.can('manage', 'TcDispo::Availability')) {
        return a.state !== 'declined';
      } else {
        return (
          a.state === 'unconfirmed' ||
          (a.state === 'confirmed' && !a.type.balance_type_id)
        );
      }
    });

    const deletableAvails = avails.filter(
      (a: Availability) => a.state !== 'confirmed',
    );

    let availActions = [];
    if (avails && avails.length === 1) {
      const avail = avails[0];

      availActions = [
        {
          label: this.translations['dispo/availability.actions.edit'],
          icon: 'edit',
          visible:
            this.currentUser.hasAccessToSection(
              'views.dispo.availability.general',
            ) &&
            (avail.state === 'confirmed' && avail.type.balance_type_id
              ? this.currentUser.can('manage', 'TcDispo::Availability')
              : true),
          command: (event: any) => {
            this.availActions.edit(avail);
          },
        },
      ];
    }

    availActions.push({
      label: this.translations['dispo/availability.actions.confirm'],
      icon: 'check-circle',
      visible:
        this.currentUser.hasAccessToSection(
          'views.dispo.availability.confirm',
        ) && confirmableAvails.length > 0,
      command: (event: any) => {
        this.availActions.confirm(confirmableAvails);
      },
    });

    availActions.push({
      label: this.translations['dispo/availability.actions.unconfirm'],
      icon: 'pause-circle',
      visible:
        this.currentUser.hasAccessToSection(
          'views.dispo.availability.unconfirm',
        ) && unconfirmableAvails.length > 0,
      command: (event: any) => {
        this.availActions.unconfirm(unconfirmableAvails);
      },
    });

    availActions.push({
      label: this.translations['dispo/availability.actions.decline'],
      icon: 'close-circle',
      visible:
        this.currentUser.hasAccessToSection(
          'views.dispo.availability.decline',
        ) && declinableAvails.length > 0,
      command: (event: any) => {
        this.availActions.decline(declinableAvails);
      },
    });

    availActions.push({
      label: this.translations['dispo/availability.actions.delete'],
      icon: 'delete',
      visible:
        this.currentUser.hasAccessToSection(
          'views.dispo.availability.delete',
        ) && deletableAvails.length > 0,
      command: (event: any) => {
        this.availActions.delete(deletableAvails);
      },
    });

    if (avails?.length === 1) {
      availActions.push({
        label: this.translations['trails.actions.resource'],
        icon: 'audit',
        visible:
          this.currentUser.hasAccessToFeature('logs') &&
          this.currentUser.can('index', 'PaperTrail::Version', {
            item_type: 'TcDispo::Availability',
          }),
        command: (_event: unknown) => {
          this.availActions.viewLogs(avails[0].id);
        },
      });
    }

    return availActions;
  }
}
