import { Component, EventEmitter, Input, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { DispoLoaderService } from '../../loader/loader.service';
import {
  DispoInvitationCollection,
  Invitation,
} from '../../collections/invitation';
import { DispoAnnouncementCollection } from '../../collections/announcement';
import { DispoAssignmentCollection } from '../../collections/assignment';

@Component({
  selector: 'tc-hub-din-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css'],
})
export class DINDeleteComponent {
  @Input() invitations: Invitation[];

  @Output() signal: EventEmitter<any> = new EventEmitter();

  loading = false;

  constructor(
    private invitationCollection: DispoInvitationCollection,
    private announcementCollection: DispoAnnouncementCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private loadingService: DispoLoaderService,
  ) {}

  cancel() {
    this.signal.emit({ action: 'close' });
  }

  delete() {
    this.loading = true;

    combineLatest(
      this.announcementCollection.all(),
      this.assignmentCollection.all(),
    )
      .pipe(first())
      .subscribe({
        next: ([announcements, assignments]) => {
          const invitationIds = this.invitations.map((i) => i.id);
          const announcementIds = [
            ...new Set(this.invitations.map((i) => i.announcement_id)),
          ];

          announcements = announcements.filter(
            (a) => announcementIds.indexOf(a.id) !== -1,
          );
          assignments = assignments.filter(
            (a) =>
              a.invitation_id && invitationIds.indexOf(a.invitation_id) !== -1,
          );

          const altInvites = this.invitations.map((invitation) =>
            Object.assign({}, invitation),
          );
          const altAnnouncements = announcements.map((announcement) =>
            Object.assign({}, announcement),
          );
          const altAssignments = assignments.map((assignment) =>
            Object.assign({}, assignment, { invitation_id: undefined }),
          );

          const inviteSubjects = [];

          altInvites.forEach((invitation) => {
            const announcement = altAnnouncements.find(
              (a) => a.id === invitation.announcement_id,
            );

            announcement.resources = announcement.resources.filter(
              (r) =>
                !(
                  r.type === invitation.resource_type &&
                  r.id === invitation.resource_id
                ),
            );

            if (!invitation.shallow) {
              inviteSubjects.push(
                this.invitationCollection.delete(invitation.id, invitation),
              );
            }
          });

          const assignmentSubjects = altAssignments.map((assignment) =>
            this.assignmentCollection.update(assignment.id, assignment),
          );
          const announcementSubjects = altAnnouncements.map((announcement) =>
            this.announcementCollection.update(announcement.id, announcement),
          );

          this.loadingService.addAssignmentsToLoad(
            altAssignments,
            assignmentSubjects,
          );
          this.loadingService.addInvitationsToLoad(altInvites, inviteSubjects);

          this.loading = false;
          this.signal.emit({ action: 'close' });
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
