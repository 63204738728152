import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash-es';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-currency-widget',
  templateUrl: './currency-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './currency-widget.component.scss',
  ],
})
export class CurrencyWidgetComponent
  extends TcControlWidgetBaseDirective
  implements OnInit, AfterViewInit
{
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this.form = this._buildForm();

    this.form.get('currency').valueChanges.subscribe((value: number) => {
      this.formProperty.setValue(this._formatCurrencyValue(value), false);
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.formProperty.valueChanges.subscribe((value: number) => {
      this._setFormControlValue(value);
    });

    this._setFormControlValue(this.formProperty.value);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _setFormControlValue(value: number) {
    this.form.get('currency').setValue(this._parseCurrencyValue(value), {
      onlySelf: true,
      emitEvent: false,
    });
  }

  private _buildForm() {
    return this.formBuilder.group({
      currency: [
        {
          value: isNaN(this.formProperty.value / 100)
            ? ''
            : this.formProperty.value / 100,
          disabled: this.schema.readOnly,
        },
      ],
    });
  }

  private _parseCurrencyValue(value) {
    return _.isNumber(value) ? value / 100 : null;
  }

  private _formatCurrencyValue(value) {
    return _.isNumber(value) ? value * 100 : null;
  }
}
