export const feedbackRequestTypes = ['read', 'attend', 'onsite_checkin'];

const feedbackRequestTypesConst = [...feedbackRequestTypes] as const;

export type TcFeedbackRequestType = typeof feedbackRequestTypesConst[number];

// Lower number means higher priority
export const feedbackRequestTypesPriority: {
  [key in TcFeedbackRequestType]: number;
} = {
  onsite_checkin: 0,
  attend: 1,
  read: 2,
} as const;
