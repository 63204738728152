import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { DispoAssignmentCollection } from '../../../dispo/collections/assignment';
import { Assignment } from '../../../dispo/collections/assignment.model';
import { ValidationService } from '../../../dispo/collections/validation.service';

@Component({
  selector: 'tc-hub-da-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class DAGeneralComponent implements OnInit {
  @Input() assignment: Assignment;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private assignmentCollection: DispoAssignmentCollection,
    private validationService: ValidationService,
  ) {}

  ngOnInit() {
    this.buildForm(this.assignment);
    this.signal.emit({ action: 'resize' });
  }

  save() {
    this.loading = true;

    const form = this.form.value;
    const assignment = { ...this.assignment, note: form.note };

    assignment.store = _.merge(
      assignment.store,
      form.assignmentEdit,
      form.assignmentResourceEdit,
    );

    // TBD: will trigger assignment validation
    // this.validationService.run([
    //   <Action> {
    //     id: uuid(),
    //     entry: assignment,
    //     entry_type: EntryType.Assignment,
    //     type: ActionType.update,
    //     validations: (item, stack) => [],
    //     errors: []
    //   }
    // ]);
    // this.signal.emit({ action: 'close' });

    this.assignmentCollection.update(this.assignment.id, assignment).subscribe({
      next: (item: Assignment) => {
        const newFormValues = this.buildFormValues(item);
        this.form.patchValue(newFormValues);

        this.loading = false;
        this.signal.emit({ action: 'close' });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(assignment: Assignment) {
    const initialValues = this.buildFormValues(assignment);
    const formConfig = {
      assignmentEdit: [initialValues.assignmentEdit],
      assignmentResourceEdit: [initialValues.assignmentResourceEdit],
      note: [initialValues.note],
    };

    this.form = this.formBuilder.group(formConfig);
  }

  private buildFormValues(assignment: Assignment): { [key: string]: any } {
    return {
      note: assignment.note,
      assignmentEdit: assignment.store,
      assignmentResourceEdit: assignment.store,
    };
  }
}
