import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class CustomerCollection extends SetsCollection {
  cache = 60 * 60;
  identifier = 'customer';
  endpoint = '/api/customers';
  bindLabel = 'label';

  localSearch = ['title', 'token'];

  decorate(customer: any, imports) {
    customer.label = [customer.token, customer.title]
      .filter((a) => a)
      .join(' ');

    return customer;
  }

  visible() {
    return this.query(
      { 'filter[customer_visibility]': '1' },
      (e) => e.visibility === 1,
    );
  }

  invisible() {
    return this.query(
      { 'filter[customer_visibility]': '0' },
      (e) => e.visibility === 0,
    );
  }
}
