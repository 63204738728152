import { NgModule } from '@angular/core';

import { TcPanelComponent } from '../../components';
import { TcFormGroupIntervalModule } from '../../form-groups';
import { SharedModule } from '../../shared/shared.module';
import { TcIntervalModule } from '../interval/interval.module';

import { TcIntervalWithBreakWebComponent } from './interval-with-break-web/interval-with-break-web.component';

@NgModule({
  declarations: [TcIntervalWithBreakWebComponent],
  exports: [TcIntervalWithBreakWebComponent],
  imports: [
    SharedModule,
    TcFormGroupIntervalModule,
    TcPanelComponent,
    TcIntervalModule,
  ],
})
export class TcIntervalWithBreakModule {}
