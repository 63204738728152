import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { first, take, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';

import {
  ModalConfig,
  ModalRef,
  TcFieldSetIntervalSetService,
} from '@timecount/ui';

import { WorkEntryCollection } from '../work_entry.collection';
import { WorkEntry } from '../work_entry.model';
import { JobCollection } from '../../../../jobs/job.collection';
import { EmployeeCollection } from '../../../../employees/employee.collection';
import { ContractorCollection } from '../../../../contractors/contractor.collection';
import { TcProjectFormsBaseDirective } from '../../../../projects/project-forms-base.directive';
import { AggregationType } from '../../aggregation_type.model';
import { WorkEntriesBatchService } from '../work-entries-batch/work-entries-batch.service';
import { ResourceTemplateCollection } from '../../../../core/collections/resource_template';

@Component({
  selector: 'tc-hub-work-entries-form',
  templateUrl: './work-entries-form.component.html',
  styleUrls: ['./work-entries-form.component.scss'],
})
export class TcWorkEntriesFormComponent
  extends TcProjectFormsBaseDirective<WorkEntry>
  implements OnInit, OnDestroy
{
  @Input() tcAggregationId: number;

  @Input() tcAggregationType: AggregationType;

  isBatch = false;
  targets = [];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _intervalSetService: TcFieldSetIntervalSetService,
    private _workEntryCollection: WorkEntryCollection,
    private _skuCollection: JobCollection,
    private _employeeCollection: EmployeeCollection,
    private _contractorCollection: ContractorCollection,
    private _workEntriesBatchService: WorkEntriesBatchService,
    private _resourceTemplateCollection: ResourceTemplateCollection,
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super(config, modalRef);
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.isModal) {
      this._resourceTemplateCollection
        .default<WorkEntry>('work_entry')
        .pipe(first())
        .subscribe((resourceTemplate) => {
          this._buildForm(resourceTemplate?.template);
        });
    } else {
      this._buildForm({});
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // Public Methods
  // ---------------------------------------------------------------------------

  onGratisDisabledChange(disabled: boolean) {
    if (disabled) {
      this.formGroup.get('gratis').disable();
    } else {
      this.formGroup.get('gratis').enable();
    }
  }

  onSave() {
    if (this.isBatch) {
      this.targets.forEach((target) => {
        // The date field is only used for interface handling.
        const { _date, ...targetValues } = target;
        const { id, project_id, aggregation_id } = targetValues;
        const { times, ...formValues } = this.formGroup.value;

        const workEntry = {
          ...formValues,
          ...(times && this._intervalSetService.formatValueToApi(times)),
        };

        Object.keys(workEntry).forEach((key) => {
          targetValues[key] = workEntry[key];
        });

        this._workEntryCollection
          .forProject(project_id, aggregation_id)
          .update(id, targetValues);
      });
    } else {
      const { projectId, aggregationId } = this._getIds();
      const action = this._getActions();
      const workEntryCollection = this._workEntryCollection.forProject(
        projectId,
        aggregationId,
      );

      if (this.tcInitialValue?.id) {
        workEntryCollection.update(this.tcInitialValue.id, action);
      } else {
        workEntryCollection.create(action);
      }
    }

    this.modalRef?.close();
  }

  toggleTimes(disabled: boolean) {
    this.formGroup.get('times')[disabled ? 'disable' : 'enable']();
  }

  openBatchCreateModal(): void {
    this._workEntriesBatchService.openModal(
      this.tcProjectId,
      this.tcAggregationId,
    );
  }

  // ---------------
  // Private Methods
  // ---------------

  private _getIds() {
    const projectId = this.tcInitialValue?.project_id || this.tcProjectId;
    const aggregationId =
      this.tcInitialValue?.aggregation_id || this.tcAggregationId;

    return { projectId, aggregationId };
  }

  private _getActions() {
    const {
      resource,
      times,
      workEntryGeneral,
      workEntryHandleGeneral,
      ...valueWithoutResource
    } = this.formGroup.value;
    const formattedTimes = this._intervalSetService.formatValueToApi(times);
    const projectAndAggregationId = this.tcInitialValue ? this._getIds() : {};

    return {
      id: this.tcInitialValue,
      ...valueWithoutResource,
      ...(resource && {
        resource_type: resource.type,
        resource_id: typeof resource === 'number' ? resource : resource?.id,
      }),
      ...projectAndAggregationId,
      ...formattedTimes,
      store: _.merge(
        this.tcInitialValue?.store,
        workEntryGeneral,
        workEntryHandleGeneral,
      ),
    };
  }

  private async _buildForm(resourceTemplate: Partial<WorkEntry>) {
    const disabled = this.isBatch;

    const mergedValues = _.merge({}, resourceTemplate, this.tcInitialValue);

    const {
      count,
      sku_id,
      title,
      resource_id,
      venue_id,
      group_id,
      role_ids,
      resource_type,
      qualification_ids,
      description,
      store,
      unit,
      gratis,
    } = mergedValues ?? {};

    const timesValue = this._intervalSetService.parseFromApi(mergedValues);

    const dateValue = this._intervalSetService.getWholeSetInterval(timesValue);

    let resource = {};

    if (resource_type) {
      const resourceCollection =
        this['_' + resource_type.toLowerCase() + 'Collection'];

      resource = await resourceCollection
        .find(resource_id)
        .asObservable()
        .pipe(take(1))
        .toPromise();
    }

    this.formGroup = this._formBuilder.group({
      // Simple
      count: [{ value: count ?? 1, disabled }, Validators.required],
      sku_id: [{ value: sku_id, disabled }, Validators.required],
      title: [{ value: title, disabled }, Validators.required],
      date: [{ value: dateValue.start, disabled }, Validators.required],
      times: [{ value: timesValue, disabled }, [Validators.required]],
      // Extended
      gratis: [{ value: gratis, disabled }],
      resource: [{ value: resource, disabled }],
      group_id: [{ value: group_id, disabled }],
      role_ids: [{ value: role_ids, disabled }],
      qualification_ids: [{ value: qualification_ids, disabled }],
      unit: [{ value: unit, disabled }],
      venue_id: [{ value: venue_id ?? this.tcVenueId, disabled }],
      description: [{ value: description, disabled }],
      workEntryGeneral: [store],
      workEntryHandleGeneral: [store],
    });

    if (!this.isBatch) {
      this.formGroup
        .get('sku_id')
        .valueChanges.pipe(takeUntil(this.destroyed$))
        .subscribe(async (jobId) => {
          const job = await this._skuCollection.get(jobId).toPromise();
          this.formGroup.get('title').patchValue(job[`title`]);
        });
    }
  }
}
