import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import { isFirstDayOfMonth } from 'date-fns';

import { dateToString } from '../../../core/helpers';
import { PlanService } from '../../actions/plan.service';
import { DTPlanComponent } from '../plan/plan.component';
import {
  DispoTasksDayService,
  DispoTasksServiceFactory,
} from '../dispo-tasks.service';

@Component({
  selector: 'tc-hub-dt-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DTDayComponent implements OnInit {
  @Input() date: Date;
  @Input() showEmpty: Observable<boolean>;

  @Output() tcSelect: EventEmitter<'selected'> = new EventEmitter();

  @ViewChildren('planComponent') planComponents: QueryList<DTPlanComponent>;

  service: DispoTasksDayService;
  plans$: Observable<any[]>;
  day$: Observable<Record<string, unknown>>;
  lookupDate: string;

  constructor(
    private factory: DispoTasksServiceFactory,
    private planActions: PlanService,
  ) {}

  ngOnInit() {
    this.lookupDate = dateToString(this.date);
    this.service = this.factory.forDate(this.date);
    this.plans$ = this.service.plans();
    this.day$ = this.service.dayDS();
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({
      item_type: 'Date',
      item: [this.date],
    });

    $event.menu_contexts.push({
      item_type: 'DispoDate',
      item: [this.date],
    });
  }

  createPlan() {
    this.planActions.createFromDateDefault(this.date);
  }

  onSelect() {
    this.tcSelect.emit('selected');
  }

  clearSelection() {
    this.planComponents.map((p) => p.clearSelection());
  }

  trackById(index, item) {
    return item.id;
  }

  public onIntersection(plan) {
    console.log('visible', plan.id);
  }

  isFirstOfMonth() {
    return isFirstDayOfMonth(this.date);
  }
}
