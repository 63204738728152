import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { eachDayOfInterval, isSameDay, startOfDay } from 'date-fns';

import {
  isIntervalShorterThan24Hours,
  isValidDate,
  isValidInterval,
} from '@timecount/utils';

import { TcInputDateTimeConfig } from '../components/input/input-datetime.config';
import { TcInputFieldType } from '../components/input/input-field-type.model';

import { TcInputDateTimeReferenceDirective } from './input-date-time-ref.directive';

@Directive({
  selector: `
    tc-input-web[tcShowDateOptions][fieldType],
    tc-input-ionic[tcShowDateOptions][fieldType],
    tc-input-time-web[tcShowDateOptions],
    tc-input-time-ionic[tcShowDateOptions],
    tc-date-time-picker-ionic[tcShowDateOptions]
  `,
  exportAs: 'dateOptions',
})
export class TcInputTimeDateOptionsDirective implements OnChanges {
  /**
   * When the `[tcShowDateOptions]` input is set, it will display a a drop down
   * with the possible dates that a time input can be related to.
   *
   * if set to 'if-needed', the date options will appear when is not possible to
   * define which day the time input refers to. If there's only one option, it
   * will not be presented.
   *
   * If set to 'always', the date options will appear, even if there's only one.
   *
   * If not set, no date options will appear.
   */
  @Input()
  tcShowDateOptions: 'if-needed' | 'always';

  @Input()
  tcDateOptionsRelative: boolean;

  /**
   * The Interval of the possible date options
   */
  @Input()
  tcDateOptionsInterval: Interval;

  /**
   * @see TcInputBaseDirective for implementation
   */
  @Input() tcConfig: TcInputDateTimeConfig;

  /**
   * @see TcInputBaseDirective for implementation
   */
  @Input() fieldType: TcInputFieldType;

  public shouldShowDateOptions = false;

  /**
   * The list of possible dates that the input Interval can fit to on a time Interval
   */
  public dateOptions: Date[];

  /**
   * The currently selected index of the date options list.
   */
  public selectedOptionIndex = 0;

  constructor(
    private ngControl: NgControl,
    private refDateDir: TcInputDateTimeReferenceDirective,
  ) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnChanges({
    tcShowDateOptions,
    tcDateOptionsInterval,
    tcConfig,
  }: SimpleChanges): void {
    if (this.ngControl.disabled) {
      return;
    }

    // Keep track of previous value of `shouldShowDateOptions` before the super
    // class changes it.
    const previewShouldShowDateOptions = this.shouldShowDateOptions;

    if (
      this.fieldType.toLowerCase().includes('time') &&
      (tcDateOptionsInterval || tcShowDateOptions || tcConfig)
    ) {
      const limit = (this.tcDateOptionsInterval ??
        this.tcConfig.limit) as Interval;

      this.shouldShowDateOptions =
        this.tcShowDateOptions === 'always' ||
        (this.tcShowDateOptions === 'if-needed' &&
          isValidInterval(limit) &&
          !isIntervalShorterThan24Hours(limit));

      if (this.shouldShowDateOptions) {
        this._updateDateOptions(limit, tcConfig?.firstChange);
      }
      // HACK: Make sure to have a reference date if date options can be shown,
      // but they are not needed. This is necessary because the
      // form-group-interval component always sends a null reference date to the
      // breakInterval start field, when the date option is set to `if-needed`.
      else if (
        this.tcShowDateOptions === 'if-needed' &&
        isValidDate(limit?.start)
      ) {
        this.refDateDir.tcReferenceDate = limit.start as Date;
        this.refDateDir.constrainValue();
      }
      // Make sure to use the reference date when hiding the date options
      else if (previewShouldShowDateOptions) {
        this.onIndexSelect(0);
      }
    }
  }

  // ---------------
  // Events Handlers
  // ---------------

  onIndexSelect(index: number) {
    this.selectedOptionIndex =
      index >= 0 && index < this.dateOptions?.length ? index : 0;
    if (this.dateOptions) {
      this.refDateDir.tcReferenceDate =
        this.dateOptions[this.selectedOptionIndex];
      this.refDateDir.constrainValue();
    }
  }

  onDateSelect(date: Date) {
    const dateIndex = isValidDate(date)
      ? this.dateOptions.findIndex((option) => isSameDay(option, date))
      : 0;
    this.onIndexSelect(dateIndex);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _updateDateOptions(limit: Interval, isFirstChange = false) {
    this.dateOptions =
      isValidInterval(limit as Interval) && !isSameDay(limit.start, limit.end)
        ? eachDayOfInterval(limit as Interval)
        : isValidDate(limit?.start) ||
          isValidDate(this.refDateDir.tcReferenceDate)
        ? [
            startOfDay(
              isValidDate(limit?.start)
                ? limit?.start
                : this.refDateDir.tcReferenceDate,
            ),
          ]
        : null;

    const dateIndex = isFirstChange
      ? isValidDate(this.ngControl.value)
        ? this.dateOptions.findIndex((option) =>
            isSameDay(option, this.ngControl.value),
          )
        : 0
      : this.selectedOptionIndex;
    this.onIndexSelect(dateIndex);
  }
}
