import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@timecount/ui';

import { DispoTaskCollection, Task } from '../collections/task';
import { DispoAssignmentCollection } from '../collections/assignment';
import { DispoMessageComponent } from '../message/message.component';
import { DispoMessage, DispoMessageRecipient } from '../collections/message';
import { Plan } from '../collections/plan';
import { Schedule } from '../collections/schedule';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { Announcement } from '../collections/announcement';
import { DispoSelectorService } from '../selector/selector.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private translations: { [key: string]: string } = {};

  private taskMessageTemplates: any[];
  private announcementMessageTemplates: any[];
  private scheduleMessageTemplates: any[];
  private planMessageTemplates: any[];
  private messageTemplates: any[];

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private taskCollection: DispoTaskCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private selectorService: DispoSelectorService,
  ) {
    this.translateService
      .get([
        'dispo/message.header.task',
        'dispo/message.header.announcement',
        'dispo/message.header.schedule',
        'dispo/message.header.plan',
        'dispo/message.header.general',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.task')
      .all()
      .subscribe((templates) => {
        this.taskMessageTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.announcement')
      .all()
      .subscribe((templates) => {
        this.announcementMessageTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.schedule')
      .all()
      .subscribe((templates) => {
        this.scheduleMessageTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.plan')
      .all()
      .subscribe((templates) => {
        this.planMessageTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages')
      .all()
      .subscribe((templates) => {
        this.messageTemplates = templates;
      });
  }

  generalDefault() {
    const defaultTemplate = this.messageTemplates.find((t) => t.default) || {
      template: {},
    };

    this.general(defaultTemplate.template);
  }

  general(template = {}) {
    const message = Object.assign(new DispoMessage(), template);
    message.recipients = [];
    message.type = 'general';

    this.modal([message], 'general');
  }

  taskDefault(task: Task) {
    const defaultTemplate = this.taskMessageTemplates.find(
      (t) => t.default,
    ) || { template: {} };
    this.task(task, defaultTemplate.template);
  }

  task(task: Task, template = {}) {
    this.taskCollection
      .activateTasks([task])
      .pipe(first())
      .subscribe({
        next: ([generatedTask]) => {
          this.assignmentCollection
            .forTask(generatedTask)
            .pipe(first())
            .subscribe({
              next: (assignments) => {
                const recipients = assignments
                  .map((a) => ({ id: a.resource_id, type: a.resource_type }))
                  .filter((a) => a.type === 'Employee');

                const message = Object.assign(new DispoMessage(), template);
                message.recipients = recipients;
                message.type = 'task';
                message.task_id = generatedTask.id;

                this.selectorService.setTasks([generatedTask]);
                this.modal([message], 'task');
              },
            });
        },
      });
  }

  announcementDefault(announcement: Announcement, date: Date) {
    const defaultTemplate = this.announcementMessageTemplates.find(
      (t) => t.default,
    ) || { template: {} };

    this.announcement(announcement, date, defaultTemplate.template);
  }

  announcement(announcement: Announcement, date: Date, template = {}) {
    const recipients = <DispoMessageRecipient[]>(
      announcement.resources
        .map((a) => ({ id: a.id, type: a.type }))
        .filter((a) => a.type === 'Employee')
    );

    const message = Object.assign(new DispoMessage(), template);
    message.recipients = recipients;
    message.type = 'announcement';
    message.announcement_id = announcement.id;
    message.date = date;

    this.selectorService.setAnnouncement(announcement);
    this.modal([message], 'announcement');
  }

  planDefault(plan: Plan, date: Date) {
    const defaultTemplate = this.planMessageTemplates.find(
      (t) => t.default,
    ) || { template: {} };

    this.plan(plan, date, defaultTemplate.template);
  }

  plan(plan: Plan, date: Date, template = {}) {
    combineLatest(
      this.taskCollection.forPlanAndDate(plan, date),
      this.assignmentCollection.all(),
    )
      .pipe(first())
      .subscribe({
        next: ([tasks, assignments]) => {
          const taskIds = tasks.map((t) => t.id);
          assignments = assignments.filter(
            (a) => taskIds.indexOf(a.task_id) !== -1,
          );

          const recipients = assignments
            .map((a) => ({ id: a.resource_id, type: a.resource_type }))
            .filter((a) => a.type === 'Employee');

          const message = Object.assign(new DispoMessage(), template);
          message.recipients = recipients;
          message.type = 'plan';
          message.plan_id = plan.id;
          message.date = date;

          this.selectorService.setPlan(plan);
          this.modal([message], 'plan');
        },
      });
  }

  scheduleDefault(schedule: Schedule) {
    const defaultTemplate = this.scheduleMessageTemplates.find(
      (t) => t.default,
    ) || { template: {} };

    this.schedule(schedule, defaultTemplate.template);
  }

  schedule(schedule: Schedule, template = {}) {
    combineLatest(
      this.taskCollection.forSchedule(schedule),
      this.assignmentCollection.all(),
    )
      .pipe(first())
      .subscribe({
        next: ([tasks, assignments]) => {
          const taskIds = tasks.map((t) => t.id);
          assignments = assignments.filter(
            (a) => taskIds.indexOf(a.task_id) !== -1,
          );

          const recipients = assignments
            .map((a) => ({ id: a.resource_id, type: a.resource_type }))
            .filter((a) => a.type === 'Employee');

          const message = Object.assign(new DispoMessage(), template);
          message.recipients = recipients;
          message.type = 'schedule';
          message.schedule_id = schedule.id;

          this.selectorService.setSchedule(schedule);
          this.modal([message], 'schedule');
        },
      });
  }

  multi(messages, type, template = {}) {
    messages = messages.map((msg) => {
      return Object.assign({}, msg, template, {
        recipients: msg.recipients,
      });
    });

    this.modal(messages, type, 'multi');
  }

  private modal(messages: DispoMessage[], type: string, section = 'create') {
    const ref = this.modalService.open(DispoMessageComponent, {
      data: {
        type: type,
        messages: messages,
        section: section,
      },
      modalTitle: this.translations[`dispo/message.header.${type}`],
    });
  }
}
