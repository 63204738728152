<div class="row">
  <tc-hub-dispo-employees-grid-header
    class="header"
    [employee]="employee"
  ></tc-hub-dispo-employees-grid-header>

  <div class="days">
    <tc-hub-dispo-shift-grid
      class="grid-expanded"
      [dates]="dates$"
      [dateWidth]="gridDateWidth"
      [resolution]="gridResolution"
    >
      <ng-container
        *ngIf="gridItemTogglerService.isTypeVisible('TimeBalance') | async"
      >
        <tc-hub-dispo-employee-grid-time-balance
          class="grid-day-items grid-time-balance"
          *ngFor="let timebalance of timebalances$ | async"
          [timebalance]="timebalance"
          tcHubDispoShiftGridItem
          [begin]="timebalance.starts_at"
          [end]="timebalance.ends_at"
          [panelBegin]="panelBegin"
          [panelEnd]="panelEnd"
          [resolution]="gridResolution"
          row="1"
          (contextmenu)="onMenu($event, 'TimeBalance', timebalance)"
          (mouseup)="onMenu($event, 'TimeBalance', timebalance)"
          (mouseover)="onMouseOver('TimeBalance', timebalance)"
        ></tc-hub-dispo-employee-grid-time-balance>
      </ng-container>

      <ng-container
        *ngIf="gridItemTogglerService.isTypeVisible('Invitation') | async"
      >
        <tc-hub-dispo-employee-grid-invitation
          class="grid-day-items"
          *ngFor="let invitation of invitations$ | async"
          tcHubDispoShiftGridItem
          [begin]="invitation.task?.starts_at"
          [end]="invitation.task?.ends_at"
          [panelBegin]="panelBegin"
          [panelEnd]="panelEnd"
          [resolution]="gridResolution"
          row="2"
          [invitation]="invitation"
          [expanded]="true"
          [overflowsLeft]="invitation.task?.starts_at < panelBegin"
          [overflowsRight]="invitation.task?.ends_at > panelEnd"
          [style]="
            getIntervalOffset(
              invitation.task?.starts_at,
              invitation.task?.ends_at
            )
          "
        ></tc-hub-dispo-employee-grid-invitation>
      </ng-container>

      <ng-container
        *ngIf="gridItemTogglerService.isTypeVisible('Assignment') | async"
      >
        <tc-hub-dispo-employee-grid-assignment
          class="grid-day-items"
          *ngFor="let assignment of assignments$ | async"
          tcHubDispoShiftGridItem
          [begin]="assignment.task?.starts_at"
          [end]="assignment.task?.ends_at"
          [panelBegin]="panelBegin"
          [panelEnd]="panelEnd"
          [resolution]="gridResolution"
          row="2"
          [assignment]="assignment"
          [expanded]="true"
          [overflowsLeft]="assignment.task?.starts_at < panelBegin"
          [overflowsRight]="assignment.task?.ends_at > panelEnd"
          [style]="
            getIntervalOffset(
              assignment.task?.starts_at,
              assignment.task?.ends_at
            )
          "
        ></tc-hub-dispo-employee-grid-assignment>
      </ng-container>
    </tc-hub-dispo-shift-grid>

    <tc-hub-dispo-shift-grid
      class="grid-availabilities"
      [dates]="dates$"
      [dateWidth]="gridDateWidth"
      [resolution]="gridResolution"
    >
      <ng-container
        *ngIf="gridItemTogglerService.isTypeVisible('Availability') | async"
      >
        <div
          *ngFor="
            let availability of availabilities$ | async | orderBy: 'starts_at'
          "
          tcHubDispoShiftGridItem
          class="grid-item grid-item-availability"
          [begin]="availability.starts_at"
          [end]="availability.ends_at"
          [panelBegin]="panelBegin"
          [panelEnd]="panelEnd"
          [resolution]="gridResolution"
          (contextmenu)="onMenu($event, 'DispoAvailability', availability)"
          (mouseup)="onMenu($event, 'DispoAvailability', availability)"
          (mouseover)="onMouseOver('DispoAvailability', availability)"
          style="z-index: 5"
        >
          <tc-hub-dispo-employee-grid-availability
            class="slot-availability"
            [availability]="availability"
            [overflowsLeft]="availability.starts_at < panelBegin"
            [overflowsRight]="availability.ends_at > panelEnd"
            [expanded]="true"
          ></tc-hub-dispo-employee-grid-availability>
        </div>
      </ng-container>
    </tc-hub-dispo-shift-grid>
  </div>
</div>
