import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanToBinary',
  standalone: true,
})
export class TcBinaryFromBoolean implements PipeTransform {
  transform(value: unknown): number | unknown {
    if (value !== true && value !== false) {
      return value;
    }

    return Number(value);
  }
}
