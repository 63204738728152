import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { dateToString, overlaps } from '../../core/helpers';
import { DispoFilterService } from '../../shared/filter/filter.service';
import { DispoSelectorService } from '../selector/selector.service';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../collections/announcement';
import { DispoInvitationCollection } from '../collections/invitation';
import { CurrentUserService } from '../../core/current-user.service';
import {
  DispoRangeDataStructure,
  DispoRangeDataStructureFactory,
} from '../datastructures/range.service';
import { AnnouncementDS } from '../datastructures/announcement-ds.model';
import { PlanDS } from '../datastructures/plan-ds.model';
import { DispoFocusService } from '../dispo-focus.service';
import { DispoResourceCollection } from '../collections/resource';
import { DispoTaskCollection, SlotPos } from '../collections/task';
import { DispoPlanCollection } from '../collections/plan';
import { DispoAssignmentCollection } from '../collections/assignment';

@Injectable({
  providedIn: 'root',
})
export class DispoAnnouncementsService {
  private _tasks: any[] = [];

  private dataStructureService: DispoRangeDataStructure;

  constructor(
    private dispoRangeDataStructureFactory: DispoRangeDataStructureFactory,
    private filterService: DispoFilterService,
    private selectorService: DispoSelectorService,
    private dispoFocus: DispoFocusService,
    private plansService: DispoPlanCollection,
    private tasksService: DispoTaskCollection,
    private assignmentsService: DispoAssignmentCollection,
    private announcementsService: DispoAnnouncementCollection,
    private invitationsService: DispoInvitationCollection,
    private resourcesService: DispoResourceCollection,
    private currentUser: CurrentUserService,
  ) {
    this.dataStructureService = this.dispoRangeDataStructureFactory.all();

    this.tasksService.all().subscribe((tasks) => {
      this._tasks = tasks;
    });
  }

  public plans(): Observable<PlanDS[]> {
    return combineLatest(
      this.plansService.all(),
      this.filterService.getSort('dispo.announcements', 'plans'),
    ).pipe(
      map(([plans, sort]) => {
        return plans.sort(sort);
      }),
    );
  }

  public planDS(plan): Observable<PlanDS> {
    return this.dataStructureService.plan(plan);
  }

  public planAnnouncements(plan): Observable<any[]> {
    return combineLatest(
      this.announcementsService.filter(
        (a) => a.plan_id === plan.id,
        `plan_id: ${plan.id}`,
      ),
      this.announcementsDS(),
      this.announcementQuery(),
      this.filterService.getSort('dispo.announcements', 'announcements'),
      this.dispoFocus.range(),
    ).pipe(
      map(
        ([
          announcements,
          announcementsDS,
          query,
          sort,
          [beginStamp, endStamp],
        ]) => {
          const visibleAnnouncementsIds = announcementsDS
            .filter((announcementDS) => announcementDS.plan_id === plan.id)
            .filter((announcementDS) => {
              return overlaps(
                announcementDS.plan?.marker || announcementDS.marker,
                beginStamp,
                endStamp,
              );
            })
            .filter(query)
            .sort(sort)
            .map((s: any) => s.id);

          const sortOrder = {};
          visibleAnnouncementsIds.forEach((id, index) => {
            sortOrder[id] = index;
          });

          return announcements
            .filter((s) => visibleAnnouncementsIds.indexOf(s.id) !== -1)
            .sort((a, b) => {
              return sortOrder[a.id] - sortOrder[b.id];
            });
        },
      ),
    );
  }

  public announcementTasks(announcement: Announcement): Observable<any> {
    return combineLatest(
      this.announcementsService.filter(
        (t) => t.id === announcement.id,
        `announcement_id: ${announcement.id}`,
      ),
      this.tasksService.filter(
        (t) => t.plan_id === announcement.plan_id,
        `plan_id: ${announcement.plan_id}`,
      ),
      this.announcementInvitations(announcement),
      this.assignmentsService.all(),
      this.dispoFocus.dates(),
    ).pipe(
      // debounceTime(100),
      map(([[announcement], tasks, invitations, assignments, dates]) => {
        if (!announcement) {
          return [];
        }

        return dates.map((date) => {
          const lookupDate = dateToString(date);
          const task = tasks.find(
            (t) =>
              t.date === lookupDate &&
              announcement.task_ids.indexOf(t.id) !== -1,
          );
          let slots = [];

          if (task) {
            slots = invitations.map((invitation, position) => {
              const state = invitation.states[lookupDate] || 'unknown';

              const same_day_assignments = assignments.filter(
                (a) =>
                  a.date === lookupDate &&
                  a.resource_type === invitation.resource_type &&
                  a.resource_id === invitation.resource_id,
              );

              const match_assignment = same_day_assignments.find(
                (a) =>
                  (a.invitation_id === invitation.id ||
                    a.task_id === task.id) &&
                  a.resource_type === invitation.resource_type &&
                  a.resource_id === invitation.resource_id,
              );

              return Object.assign({
                missing: state !== 'accepted',
                assigned: !!match_assignment,
                collision: same_day_assignments.length > 1,
                unavailable:
                  !match_assignment && same_day_assignments.length === 1,
                cancelled: state === 'cancelled',
                blank: false,
                weekend: date.getDay() === 0 || date.getDay() === 6,
                state: state,
                task_id: task.id,
                invitation_id: invitation.id,
                assignment_id: match_assignment
                  ? match_assignment.id
                  : undefined,
                invitation: invitation,
              });
            });

            const accepted_size = slots.filter(
              (s) => !s.missing && !s.unavailable,
            ).length;
            const size = announcement.sizes[lookupDate];
            const filled = accepted_size >= size;
            const empty = accepted_size === 0;
            const unfilled = !filled && !empty;

            return {
              id: task.id,
              date: lookupDate,
              marker: task.marker,
              slots: slots,
              accepted_size: accepted_size,
              size: size,
              filled: filled,
              unfilled: unfilled,
              empty: empty,
              blank: false,
              weekend: date.getDay() === 0 || date.getDay() === 6,
              announcement_id: announcement.id,
              task: task,
            };
          } else {
            return {
              date: lookupDate,
              blank: true,
              weekend: date.getDay() === 0 || date.getDay() === 6,
              announcement_id: announcement.id,
            };
          }
        });
      }),
      shareReplay(1),
    );
  }

  public announcementInvitations(announcement: Announcement) {
    return combineLatest(
      this.invitationsService.forAnnouncement(announcement),
      this.assignmentsService.all(),
      this.resourcesService.all(),
    ).pipe(
      map(([invitations, assignments, resources]) => {
        return invitations.map((invitation) => {
          const resource = resources.find(
            (r) =>
              r.type === invitation.resource_type &&
              r.id === invitation.resource_id,
          );

          assignments = assignments.filter(
            (a) =>
              a.resource_type === invitation.resource_type &&
              a.resource_id === invitation.resource_id,
          );

          invitation = Object.assign({}, invitation);
          invitation.resource = resource || { name: '?' };
          invitation.assignments = assignments.filter(
            (a) => a.invitation_id === invitation.id,
          );

          return invitation;
        });
      }),
      map((invitations) => {
        return invitations.sort(
          (a, b) =>
            b.accepted_size +
            2 * b.assignments.length -
            (a.accepted_size + 2 * a.assignments.length),
        );
      }),
    );
  }

  public announcementDS(
    announcement: Announcement,
  ): Observable<AnnouncementDS> {
    return this.dataStructureService.announcement(announcement);
  }

  public announcementsDS(): Observable<AnnouncementDS[]> {
    return this.dataStructureService.announcements();
  }

  // REFACTOR: similar to dispo-tasks.service#handleInsert
  // type: Enum[timecount/dispo_announcements, timecount/resources]
  // data: { slots?: [{x: dateInt, y: position }], schedule?: Schedule }
  // target: Schedule
  // toPosition: {x: dateInt, y: position|targetId }
  // selectedSlots: [{x: dateInt, y: position }]
  public handleInsert(type, data, target, toPosition) {
    if (
      type === 'timecount/resources' &&
      this.currentUser.hasAccessToSection('views.dispo.insert.resource')
    ) {
      this.handleResourcesInsert(data, target, data.action);
    }
  }

  public handleSelect(announcement: Announcement, slots: SlotPos[]) {
    slots.map((slot) => {
      slot.announcement_id = announcement.id;
      slot.task_id = (
        this._tasks.find((t) => {
          return announcement.task_ids.indexOf(t.id) !== -1;
        }) || { id: undefined }
      ).id;
    });

    this.selectorService.setSlots(slots);
  }

  // REFACTOR: similar to dispo-tasks.service#handleResourcesInsert
  // source: { resource: Resource }
  // targetTask: Task
  // targetSlots: [{x: dateInt, y: position }]
  // action: create[,update,delete]
  private handleResourcesInsert(
    source,
    targetAnnouncement: Announcement,
    action?,
  ) {
    const actions$ = [];

    // create an invitation for the resource if none present ... run validators
  }

  private announcementQuery() {
    return this.filterService.getQuery('dispo.announcements');
  }
}
