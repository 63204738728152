<form class="modal-container" [formGroup]="form" *ngIf="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-6">
          <label
            class="checkbox__tracked-time"
            nz-checkbox
            formControlName="tracked_time"
            (ngModelChange)="toggle()"
          >
            {{ 'dispo/assignment.attrs.tracked_time' | translate }}
          </label>
        </div>
        <div class="col-xs-6">
          <label nz-checkbox formControlName="gratis">
            {{ 'timesheet.attrs.gratis' | translate }}
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <tc-hub-option-select
              label="{{ 'timesheet.attrs.state' | translate }}"
              type="timesheet.state"
              [formGroup]="form"
              controlName="state"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <tc-hub-collection-select
              [formGroup]="form"
              [collectionTypes]="['Job']"
              controlName="job_id"
              bindLabel="title"
              bindValue="id"
              searchable="true"
            >
            </tc-hub-collection-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <tc-interval-set-web
            [tcShowDateInput]="true"
            [tcConfig]="{
              required: true,
              disabled: !timeTrackingPossible,
              limit: {
                start: timesheet.starts_at | dfnsStartOfDay | dfnsSubDays : 1,
                end:
                  timesheet.starts_at
                  | dfnsEndOfDay
                  | dfnsAddDays : (allowsPlus24Hours ? 2 : 1)
              }
            }"
            formControlName="times"
          ></tc-interval-set-web>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-md-6" *ngFor="let supplement of supplements">
          <div class="form-group">
            <label>
              {{ supplement.display_key }}
              <input
                tcFormControlValidator
                class="form-control"
                type="number"
                step="0.01"
                formControlName="supplement_{{ supplement.id }}"
              />
            </label>
          </div>
        </div>
      </div>

      <tc-hub-custom-fields
        section="timesheet.general"
        [formGroup]="form"
        formControlName="store"
      >
      </tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
