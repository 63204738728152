<div class="sf-group" [formGroup]="form">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>
  <div class="sf-controls">
    <div class="sf-elements" [formGroup]="form">
      <tc-input-currency-web
        class="currency-field"
        formControlName="currency"
        [tcConfig]="{
          disabled: schema.readOnly
        }"
      ></tc-input-currency-web>
    </div>
  </div>
</div>
