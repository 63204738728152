<label
  *ngIf="label?.length; else fieldTemplate"
  [for]="labelFor"
  class="tc-label"
>
  <span class="label-text">{{ label }}</span>

  <ng-container *ngTemplateOutlet="fieldTemplate"></ng-container>
</label>

<ng-template #fieldTemplate>
  <nz-input-group>
    <div nz-row *ngIf="tcCanDisable; else contentTemplate">
      <div nz-col nzFlex="40px" class="toggler-wrapper">
        <label
          class="toggler"
          nz-checkbox
          [ngModel]="!disabled"
          (ngModelChange)="disabledChange.emit(!$event)"
        ></label>
      </div>
      <div nz-col nzFlex="auto">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </div>
  </nz-input-group>
</ng-template>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="errors; let errors">
  <nz-alert
    class="errors"
    *ngFor="let errorKey of errors | keysList"
    nzType="error"
    [nzMessage]="
      getErrorMessage(errorKey, errors[errorKey]) | async | titlecase
    "
    nzShowIcon
  ></nz-alert>
</ng-container>
