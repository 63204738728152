import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-remote-select-widget',
  templateUrl: './remote-select-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './remote-select-widget.component.scss',
  ],
})
export class RemoteSelectWidgetComponent extends TcControlWidgetBaseDirective {}
