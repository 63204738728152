export enum DateISOFormat {
  YEAR = 'YYYY',
  YEAR_MONTH = 'YYYY-MM',
  DATE = 'YYYY-MM-DD',
  DATE_TIME = 'YYYY-MM-DDTHH:mm',
  TIME = 'HH:mm',
  UTC_TIMEZONE = 'YYYY-MM-DDTHH:mm:ssTZD',
}

export enum DateISODisplayFormat {
  date = 'DD.MM.YYYY',
  dateTime = 'DD.MM.YYYY HH:mm',
  time = 'HH:mm',
}

export enum DateUnicodeFormat {
  year = 'yyyy',
  yearMonth = 'LLLL yyyy',
  date = 'dd.MM.yyyy',
  dateLong = 'eeeeee dd.MM.yyyy',
  dateTime = 'dd.MM.yyyy HH:mm',
  time = 'HH:mm',
  urlMonth = 'yyyy-MM-dd',
  apiDateTime = 'yyyy-MM-dd HH:mm',
  apiDate = 'yyyy-MM-dd',
}
