<div class="wrapper" *ngIf="visible$ | async">
  <div class="row">
    <div class="row-header" [ngStyle]="{ 'background-color': plan.color }">
      <tc-hub-toggle
        [active$]="expanded$"
        (mouseup)="onTogglePlan($event)"
      ></tc-hub-toggle>
      <span class="title"
        nz-popover
        nzPopoverOverlayClassName="tc-popover with-padding"
        [nzPopoverContent]="planPopover">
        <ng-container *ngTemplateOutlet="planTitle"></ng-container>
        {{ plan.title }}
      </span>
      <tc-hub-data-panel
        [data]="plan$ | async"
        section="dispo.schedules.plan"
        class="plan-stats"
      ></tc-hub-data-panel>

      <ng-template #planTitle>
        {{ plan.title }}
      </ng-template>
      <ng-template #planPopover>
        {{ (plan$ | async ).project?.token }} {{ (plan$ | async ).project?.title }} <br />
        <ng-container *ngTemplateOutlet="planTitle"></ng-container>
        <tc-hub-data-panel
          [data]="plan$ | async"
          section="dispo.schedules.plan"
          class="plan-stats"
        ></tc-hub-data-panel>
      </ng-template>
    </div>
    <div class="days">
      <ng-container *ngFor="let week of weeks$ | async">
        <div
          class="day"
          *ngFor="let day of week; let first = first; let last = last"
          [class.day-included]="includes(day)"
          [class.weekend]="isWeekend(day)"
          [ngClass]="{ first: first, last: last }"
          [ngStyle]="{
            'background-color': includes(day) ? plan.color : 'transparent'
          }"
        ></div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="expanded$ | async">
    <!-- PERFORMANCE: refactor to use trackBy: trackById; does not track changes to schedule though ??? (enable/disable) -->
    <tc-hub-ds-schedule
      #scheduleComponent
      *ngFor="
        let schedule of schedules$ | async;
        trackBy: trackById;
        let even = even;
        let odd = odd
      "
      [ngClass]="{ odd: odd, even: even }"
      [schedule]="schedule"
      [plan]="plan"
      [attr.data-scheduleid]="schedule.id"
      (tcSelect)="onSelect($event)"
      tcHubDispoMenuActive
      activeType="DispoSchedule"
      [activeId]="schedule.id"
    ></tc-hub-ds-schedule>

    <ng-container *ngIf="(schedules$ | async).length === 0">
      <ng-container *tcHubHide="'views.dispo.schedule.create.guided'">
        <div class="row row-full">
          <div class="row-header">
            <button
              class="add-button"
              nz-button
              nzSize=""
              (click)="createSchedule()"
            >
              <i nz-icon nzType="calendar"></i>
              {{ 'dispo/schedule.actions.create' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
