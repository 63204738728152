<td>
  <label nz-checkbox [(nzChecked)]="item.checked"></label>

  {{ item.resource?.name }}

  <div class="data-panel">
    <tc-hub-data-panel
      [data]="item"
      section="dispo.assignments.assignment.resource"
    ></tc-hub-data-panel>
  </div>

  <div class="status">
    <span
      class="times-confirmed"
      *ngIf="item.timesheet && item.timesheet.state === 'confirmed'"
      nzTooltipTitle="{{
        'dispo/assignment.tooltip.times_confirmed' | translate
      }}"
      nz-tooltip
      ><i nz-icon nzType="clock-circle" nzTheme="fill"></i
    ></span>
    <span
      class="times-not-confirmed"
      *ngIf="item.timesheet && item.timesheet.state === 'unconfirmed'"
      nzTooltipTitle="{{
        'dispo/assignment.tooltip.times_unconfirmed' | translate
      }}"
      nz-tooltip
      ><i nz-icon nzType="clock-circle" nzTheme="fill"></i
    ></span>
    <span
      class="times-not-tracked"
      *ngIf="!item.timesheet && item.task.ends_at < now"
      nzTooltipTitle="{{
        'dispo/assignment.tooltip.times_untracked' | translate
      }}"
      nz-tooltip
      ><i nz-icon nzType="clock-circle" nzTheme="fill"></i
    ></span>
  </div>
</td>
<td>
  <ng-container *ngIf="item.timesheet">
    {{ item.timesheet.job?.title }}
    <span
      class="critical-mismatch"
      *ngIf="item.timesheet && item.timesheet.job_id !== item.slot.job_id"
      nzTooltipTitle="{{ item.job?.title }}"
      nz-tooltip
    >
      <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>
    </span>
  </ng-container>
  <ng-container *ngIf="!item.timesheet">
    {{ item.job?.title }}
  </ng-container>
</td>
<td>{{ item.position + 1 }}</td>
<td>
  <ng-container *ngIf="item.timesheet">
    {{ item.timesheet.starts_at | date : 'd.MM.' }}
  </ng-container>
  <ng-container *ngIf="!item.timesheet">
    {{ item.task.starts_at | date : 'd.MM.' }}
  </ng-container>
</td>
<td>
  <ng-container *ngIf="item.timesheet">
    {{ item.timesheet.starts_at | date : 'HH:mm' }}
    <span
      class="mismatch"
      *ngIf="
        !([item.timesheet.starts_at, item.task.starts_at] | dfnsIsSameMinute)
      "
      nzTooltipTitle="{{ item.task.starts_at | date : 'd.MM HH:mm' }}"
      nz-tooltip
    >
      <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>
    </span>
  </ng-container>
</td>
<td>
  <ng-container *ngIf="item.timesheet">
    {{ item.timesheet.ends_at | date : 'HH:mm' }}
    <span
      class="mismatch"
      *ngIf="!([item.timesheet.ends_at, item.task.ends_at] | dfnsIsSameMinute)"
      nzTooltipTitle="{{ item.task.ends_at | date : 'd.MM HH:mm' }}"
      nz-tooltip
    >
      <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>
    </span>
  </ng-container>
</td>
<td>
  <span>{{ assignmentSummary.breakPeriods }}</span>
  <span
    class="breaks-info"
    *ngIf="assignmentSummary.breakPeriods > 0"
    nz-popover
    [nzPopoverContent]="assignmentTimeframes"
    nzPopoverOverlayClassName="tc-popover with-padding"
  >
    <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  </span>
</td>
<ng-template #assignmentTimeframes>
  <tc-hub-breaks-list-table
    [timesList]="parsedAssignmentTimes"
    [showDate]="true"
  ></tc-hub-breaks-list-table>
</ng-template>
<td>
  <span [class]="assignmentSummary.workDuration | highlightDurationLabel">{{
    assignmentSummary.workDuration | duration
  }}</span>
  <span
    class="mismatch"
    *ngIf="
      item.timesheet &&
      durationInMinutes(assignmentSummary.workDuration) !==
        durationInMinutes(taskSummary.workDuration)
    "
    nzTooltipTitle="{{ taskSummary.workDuration | duration }}"
    nz-tooltip
  >
    <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>
  </span>
</td>
<td>
  <span>{{ assignmentSummary.breakDuration | duration }}</span>
  <span
    class="mismatch"
    *ngIf="
      item.timesheet &&
      durationInMinutes(assignmentSummary.breakDuration) !==
        durationInMinutes(taskSummary.breakDuration)
    "
    nzTooltipTitle="{{ taskSummary.breakDuration | duration }}"
    nz-tooltip
  >
    <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>
  </span>
</td>
<td>
  <span [class]="assignmentSummary.workDuration | highlightDurationLabel">{{
    assignmentSummary.totalDuration | duration
  }}</span>
</td>
<td>
  <tc-hub-data-panel
    [data]="item"
    section="dispo.assignments.assignment.status"
  ></tc-hub-data-panel>
</td>
