import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { combineLatest, first, map, of } from 'rxjs';

import { ModalService } from '@timecount/ui';

import { ValidationService } from '../../dispo/collections/validation.service';
import { EntryType } from '../../core/types/entry-type';
import { ActionType } from '../../core/types/action-type';
import { Action } from '../../core/types/action';
import { AggregationTypeCollection } from '../../core/collections/aggregation-type.collection';
import { ProjectServiceEntryService } from '../service-entry/service-entry.service';
import { ServiceEntry } from '../service-entry/service-entry.model';
import { Timesheet, TimesheetCollection } from '../../timesheets';

import { BillingWorkEntryCollection } from './work-entries/billing-work-entry.collection';
import { AggregationWorkEntriesService } from './work-entries/work-entries.service';
import { WorkEntry } from './work-entries/work_entry.model';
import { AggregationType } from './aggregation_type.model';

@Injectable({
  providedIn: 'root',
})
export class AggregationEntryService {
  private translations: { [key: string]: string } = {};

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private validationService: ValidationService,
    private workEntryActions: AggregationWorkEntriesService,
    private serviceEntryActions: ProjectServiceEntryService,
    private workEntryCollection: BillingWorkEntryCollection,
    private timesheetCollection: TimesheetCollection,
    private aggregationTypeCollection: AggregationTypeCollection,
  ) {
    this.translateService
      .get([
        'aggregation_entry.confirms.gratis',
        'aggregation_entry.confirms.paid',
        'aggregation_entry.confirms.delete',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  edit(entry: ServiceEntry | WorkEntry) {
    this.aggregationTypeCollection
      .get(entry.aggregation_type_id)
      .pipe(first())
      .subscribe((type: AggregationType) => {
        if (entry.identifier === this.workEntryCollection.identifier) {
          this.workEntryActions.openModal(<WorkEntry>entry, type, 'edit');
        } else {
          this.serviceEntryActions.openModal(<ServiceEntry>entry, type, 'edit');
        }
      });
  }

  setGratisState(entries: (WorkEntry | ServiceEntry)[], value: boolean) {
    const entries$ = entries.map((entry) => {
      if (entry.identifier === this.workEntryCollection.identifier) {
        if (entry.source_type === 'Timesheet') {
          return this.timesheetCollection.get(entry.source_id).pipe(
            map((ts: Timesheet) => {
              return { ...ts, gratis: value };
            }),
          );
        }
      }

      return of({
        ...entry,
        gratis: value,
      });
    });

    combineLatest(entries$)
      .pipe(first())
      .subscribe((entries) => {
        const actions = entries.map((entry) => {
          return <Action>{
            id: uuid(),
            entry: entry,
            entry_type:
              entry.identifier === this.timesheetCollection.identifier
                ? EntryType.Timesheet
                : entry.identifier === this.workEntryCollection.identifier
                ? EntryType.WorkEntry
                : EntryType.ServiceEntry,
            type: ActionType.update,
            errors: [],
            validations: (item, stack) => [],
          };
        });

        this.validationService.run(
          actions,
          true,
          value
            ? this.translations['aggregation_entry.confirms.gratis']
            : this.translations['aggregation_entry.confirms.paid'],
        );
      });
  }

  delete(entries: (WorkEntry | ServiceEntry)[]) {
    const actions = entries.map((entry) => {
      return <Action>{
        id: uuid(),
        entry: entry,
        entry_type:
          entry.identifier === this.workEntryCollection.identifier
            ? EntryType.WorkEntry
            : EntryType.ServiceEntry,
        type: ActionType.delete,
        errors: [],
        validations: (item, stack) => [],
      };
    });

    this.validationService.run(
      actions,
      true,
      this.translations['aggregation_entry.confirms.delete'],
    );
  }
}
