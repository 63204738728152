import { ExclusionPolicy, Strategy, Type } from 'typeserializer';
import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';
import { Visibility } from '../core/types/visibility';

@Strategy(ExclusionPolicy.NONE)
export class Job {
  id: number;
  title: string;

  category_id: number;
  visibibility: Visibility;

  @Type(Object)
  store: any;
}

@Injectable({
  providedIn: 'root',
})
export class JobCollection extends SetsCollection {
  type = Job;
  identifier = 'job';
  endpoint = '/api/jobs';

  cache = 60 * 60;

  localSearch = ['title'];

  visible() {
    return this.query({ 'filter[visible]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visible]': '0' }, (e) => e.visibility === 0);
  }
}
