<ion-card *rxLet="viewState$; let vm">
  <ion-list [formGroup]="vm.formGroup">
    <tc-form-field-ionic
      [tcErrors]="[
        vm.formGroup.controls['mainInterval'].controls.start.errors,
        vm.formGroup.controls['mainInterval'].controls.end.errors
      ]"
    >
      <tc-form-group-interval-ionic
        formGroupName="mainInterval"
        [tcFieldType]="'time'"
        [title]="'ASSIGNMENTS.TIME_TRACKING_WORK' | translate"
        [alignRight]="true"
        [tcConfig]="{
          required: true,
          allowZeroDuration: true,
          timePickerSteps: vm.config.timePickerSteps,
          fieldWithDateOptions: null,
          startLimit: vm.config.limit
        }"
        [tcRefDate]="vm.referenceDate"
      ></tc-form-group-interval-ionic>
    </tc-form-field-ionic>

    <tc-form-field-ionic
      [blurred]="true"
      [tcErrors]="[
        vm.formGroup.controls['breakInterval'].controls.start.errors,
        vm.formGroup.controls['breakInterval'].controls.end.errors
      ]"
    >
      <tc-form-group-interval-ionic
        formGroupName="breakInterval"
        [tcFieldType]="'time'"
        [title]="'ASSIGNMENTS.TIME_TRACKING_INTER' | translate"
        [allowClear]="true"
        [alignRight]="true"
        [tcConfig]="{
          allowZeroDuration: true,
          timePickerSteps: vm.config.timePickerSteps,
          startLimit: vm.formGroup.value?.mainInterval,
          fieldWithDateOptions: 'start'
        }"
        [tcRefDate]="vm.formGroup.value.mainInterval.start ?? vm.referenceDate"
      ></tc-form-group-interval-ionic>
    </tc-form-field-ionic>

    <ion-item lines="none">
      <ion-note slot="start">
        {{ 'GENERAL.TIME.DURATION' | translate }}
      </ion-note>
      <ion-note slot="end">
        {{ vm.duration | duration }}
      </ion-note>
    </ion-item>
  </ion-list>
</ion-card>
