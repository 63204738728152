<tc-form-field-ionic
  [blurred]="blurred"
  [hints]="hints"
  [isInputType]="isInputType"
  [tcErrors]="[ngControl.control.errors]"
>
  <ion-item
    [class.is-invalid]="ngControl.invalid"
    [class.is-required]="ngControl.errors?.required"
    [lines]="'none'"
    [disabled]="isDisabled"
  >
    <ion-label fixed>{{ label }}</ion-label>

    <ion-input
      *ngIf="isInputType"
      [type]="revealPassword ? 'text' : fieldType"
      [value]="value"
      [inputmode]="inputType === 'password' ? 'text' : inputType"
      [autofocus]="autofocus"
      [placeholder]="placeholder ?? undefined"
      (ionChange)="value = $event.detail.value"
      (ionBlur)="blurred = true"
    ></ion-input>

    <div *ngIf="hasAsyncValidation">
      <ion-spinner *ngIf="ngControl.pending" name="crescent"></ion-spinner>

      <ion-icon
        *ngIf="ngControl.valid"
        name="checkmark-circle-sharp"
        color="success"
      ></ion-icon>
    </div>

    <ion-button
      *ngIf="fieldType === 'password'"
      slot="end"
      fill="clear"
      color="dark"
      (click)="revealPassword = !revealPassword"
      [disabled]="isDisabled"
    >
      <ion-icon
        slot="icon-only"
        [name]="'eye-' + (revealPassword ? 'off-' : '') + 'outline'"
      ></ion-icon>
    </ion-button>

    <tc-date-time-picker-ionic
      *ngIf="!isInputType"
      [(ngModel)]="value"
      [ngModelOptions]="{ standalone: true }"
      [tcInitialValue]="pickerInitialValue"
      [fieldType]="fieldType"
      [tcConfig]="tcConfig"
      [disabled]="isDisabled"
    ></tc-date-time-picker-ionic>
  </ion-item>
</tc-form-field-ionic>
