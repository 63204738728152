import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { format } from 'date-fns';

import { TcConfigService } from '@timecount/core';

import { CollectionService } from '../../../core/collection.service';
import { DispoTaskCollection, Task } from '../../collections/task';
import { Plan } from '../../collections/plan';

@Component({
  selector: 'tc-hub-dt-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class DTPublicComponent implements OnInit, AfterViewInit {
  @Input() task: Task;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  attachmentRecipientTitle: string;

  allowOnsiteCheckin = this.configService.config.company.allowOnsiteCheckin;
  allowAppAttendance = this.configService.config.company.allowAppAttendance;

  constructor(
    private taskService: DispoTaskCollection,
    private formBuilder: UntypedFormBuilder,
    private collectionService: CollectionService,
    private translate: TranslateService,
    private configService: TcConfigService,
  ) {}

  ngOnInit() {
    this.translate
      .get('dispo/task.attachment_releases.recipient_title', {
        event_title: this.plan ? this.plan.title : '',
        job_title: this.task.title,
        date: format(this.task.starts_at, 'dd.MM.yyyy'),
      })
      .subscribe((recipientTitle) => {
        this.attachmentRecipientTitle = recipientTitle;
      });
    this.form = this.buildForm(this.task);
  }

  ngAfterViewInit() {
    from(
      this.collectionService['MessageTemplate']
        .byType('dispo_task', 'dispo_task')
        .all(),
    ).subscribe((templates: any[]) => {
      const messageTemplate = templates.find((template) => {
        return template.body === this.task.description;
      });

      if (messageTemplate) {
        this.form.get('message_template_id').setValue(messageTemplate.id);
      }
    });
  }

  validate() {
    // validate intermission in range
  }

  save() {
    this.loading = true;
    const { onsite_checkin_enabled, attendance_enabled, ...rest } =
      this.form.value;
    const newValues = Object.assign({}, this.task, rest);
    newValues.response_requirements = {
      onsite_checkin: onsite_checkin_enabled,
      attend: attendance_enabled,
    };

    this.taskService.update(this.task.id, newValues).subscribe({
      next: (task: Task) => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
        // this.form = this.buildForm(task);
        // this.signal.emit({ action: 'reload', items: { task: task } });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(task) {
    return this.formBuilder.group({
      tracking_enabled: [
        { value: task.tracking_enabled, disabled: task.state !== 'open' },
      ],
      onsite_checkin_enabled: [
        task.response_requirements?.onsite_checkin ?? null,
      ],
      attendance_enabled: [task.response_requirements?.attend ?? null],
      push_notifications: [task.push_notifications],
      description: [task.description],
      state: [task.state, Validators.required],
      message_template_id: [null],
    });
  }
}
