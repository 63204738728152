<ul
  [ngClass]="{
    'ui-widget-content ui-corner-all ui-submenu-list ui-shadow': !root
  }"
  class="ui-menu-list"
>
  <ng-template ngFor let-child [ngForOf]="root ? item : item.items">
    <li
      *ngIf="child.separator"
      class="ui-menu-separator ui-widget-content"
      [ngClass]="{ 'ui-helper-hidden': child.visible === false }"
      role="separator"
    ></li>
    <li
      *ngIf="!child.separator"
      #item
      [ngClass]="{
        'ui-menuitem ui-corner-all': true,
        'ui-menuitem-active': item === activeItem,
        'ui-helper-hidden': child.visible === false
      }"
      (mouseenter)="onItemMouseEnter($event, item, child)"
      role="none"
    >
      <a
        *ngIf="!child.routerLink"
        [attr.href]="child.url"
        [attr.target]="child.target"
        [attr.title]="child.title"
        [attr.id]="child.id"
        [attr.tabindex]="child.tabindex ? child.tabindex : '0'"
        (click)="itemClick($event, child)"
        [ngClass]="{
          'ui-menuitem-link ui-corner-all': true,
          'ui-state-disabled': child.disabled,
          'has-submenu': child.items
        }"
        [ngStyle]="child.style"
        [class]="child.styleClass"
        [attr.aria-haspopup]="item.items !== null"
        [attr.aria-expanded]="item === activeItem"
      >
        <span class="ui-submenu-icon" *ngIf="child.items">
          <i nz-icon nzType="caret-right"></i>
        </span>
        <span class="ui-menuitem-icon" *ngIf="child.icon">
          <i
            nz-icon
            [nzType]="child.icon"
            [nzTheme]="child.iconNzTheme ?? 'outline'"
          ></i>
        </span>
        <span class="ui-menuitem-text">{{ child.label }}</span>
      </a>
      <a
        *ngIf="child.routerLink"
        [routerLink]="child.routerLink"
        [queryParams]="child.queryParams"
        [routerLinkActive]="'ui-menuitem-link-active'"
        role="menuitem"
        [routerLinkActiveOptions]="
          child.routerLinkActiveOptions || { exact: false }
        "
        [attr.target]="child.target"
        [attr.title]="child.title"
        [attr.id]="child.id"
        [attr.tabindex]="child.tabindex ? child.tabindex : '0'"
        (click)="itemClick($event, child)"
        [ngClass]="{
          'ui-menuitem-link ui-corner-all': true,
          'ui-state-disabled': child.disabled,
          'has-submenu': child.items
        }"
        [ngStyle]="child.style"
        [class]="child.styleClass"
        [fragment]="child.fragment"
        [queryParamsHandling]="child.queryParamsHandling"
        [preserveFragment]="child.preserveFragment"
        [skipLocationChange]="child.skipLocationChange"
        [replaceUrl]="child.replaceUrl"
        [state]="child.state"
      >
        <span class="ui-submenu-icon" *ngIf="child.items">
          <i nz-icon nzType="caret-right"></i>
        </span>
        <span class="ui-menuitem-icon" *ngIf="child.icon">
          <i
            nz-icon
            [nzType]="child.icon"
            [nzTheme]="child.iconNzTheme ?? 'outline'"
          ></i>
        </span>
        <span class="ui-menuitem-text">{{ child.label }}</span>
      </a>
      <tc-hub-context-menu-sub
        class="ui-submenu"
        [parentActive]="item === activeItem"
        [item]="child"
        *ngIf="child.items"
      ></tc-hub-context-menu-sub>
    </li>
  </ng-template>
</ul>
