import { AfterViewInit, Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-object-widget',
  templateUrl: './object-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './object-widget.component.scss',
  ],
})
export class ObjectWidgetComponent
  extends TcControlWidgetBaseDirective
  implements AfterViewInit
{
  isPanel = false;

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.isPanel =
      this.schema.widget.id === 'panel' ||
      (this.schema.widget.id !== 'flat' &&
        Boolean(this.schema.title?.length || this.schema.description?.length));
  }
}
