import { AfterViewInit, Component } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';
import { RemoteConfig } from '../../../../core/remote_config.service';

@Component({
  selector: 'tc-hub-select-widget',
  templateUrl: './select-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './select-widget.component.scss',
  ],
})
export class SelectWidgetComponent
  extends TcControlWidgetBaseDirective
  implements AfterViewInit
{
  items$: Observable<unknown>;
  bindLabel = 'value';
  bindValue = 'key';

  constructor(private remoteConfig: RemoteConfig) {
    super();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.schema.widget.id === 'map-select') {
      this.items$ = this.remoteConfig.getLookupMap(this.schema.mapKey).pipe(
        map((map) => {
          if (this.schema.type === 'integer') {
            const transformedData = Object.keys(map.data).reduce((acc, key) => {
              acc[parseInt(key, 10)] = map.data[key];
              return acc;
            }, {});

            return { ...map, data: transformedData };
          }
          return map.data;
        }),
      );
    } else if (this.schema.oneOf) {
      //converting the array to an object in order to use the keyvalue pipe
      this.items$ = of(
        this.schema.oneOf.reduce(
          (acc, { description, enum: [key] }) => ({
            ...acc,
            [key]: description,
          }),
          {},
        ),
      );
    } else {
      this.items$ = of(this.schema.enum);
      this.bindLabel = this.bindValue = undefined;
    }
  }
}
