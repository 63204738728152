import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { startOfDay } from 'date-fns';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TcIntervalWithBreak } from '@timecount/core';
import { TcFieldSetIntervalSetService, TcInputValidators } from '@timecount/ui';

import { DispoPlanCollection, Plan } from '../../../collections/plan';
import { DispoResourceService } from '../../../dispo-resource.service';

@Component({
  selector: 'tc-hub-dsc-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class DSCStep2Component implements OnInit, OnDestroy {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  plan: Plan;

  initialDateRangeValue: Interval;

  private destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private planCollection: DispoPlanCollection,
    private dispoResourceService: DispoResourceService,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
  ) {}

  ngOnInit() {
    this.buildForm();
    this.planCollection
      .find(this.config.plan_id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: Plan) => {
        this._setDefaultVenue(value.project_id);
      });
    this.signal.emit({ action: 'resize' });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  prev() {
    this.signal.emit({ action: 'prev', config: this.config });
  }

  next() {
    const { dateInterval, times, ...formValues } = this.form.value;

    const nextConfig = {
      ...this.config,
      [`range_begin`]: dateInterval.start,
      [`range_end`]: dateInterval.end,
      ...TcIntervalWithBreak.formatForApi(times),
      template: {
        ...this.config.template,
        ...formValues,
        ...this.fieldSetIntervalSetService.formatValueToOffsetLength(times),
      },
    };

    this.signal.emit({ action: 'next', config: nextConfig });
  }

  private buildForm() {
    this.initialDateRangeValue = {
      start: startOfDay(this.config[`range_begin`]),
      end: startOfDay(this.config[`range_end`]),
    };

    this.form = this.formBuilder.group({
      dateInterval: [this.initialDateRangeValue],
      times: [
        TcIntervalWithBreak.parseFromApi(this.config),
        [Validators.required],
      ],
      job_id: [this.config.template.job_id, [Validators.required]],
      size: [
        this.config.template.size || 5,
        [
          Validators.required,
          TcInputValidators.minMaxNumber(
            1,
            window.config.company.dispo_schedule_max_size,
          ),
        ],
      ],
      venue_id: [this.config.template.venue_id],
      note: [this.config.template.note],
    });
  }

  private _setDefaultVenue(projectId: number) {
    this.dispoResourceService
      .venues('project', projectId)
      .pipe(take(1))
      .subscribe((venue_roles) => {
        this.form.get('venue_id').setValue(venue_roles[0]?.venue.id);
      });
  }
}
