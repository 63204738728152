<div class="active-filters">
  <i nz-icon nzType="search" nzTheme="outline"></i>
  <div class="filter-tags">
    <ng-container *ngFor="let filter of currentFilters$ | async | keyvalue">
      <nz-tag
        nzMode="closeable"
        nzColor="#515962"
        (nzOnClose)="removeFilter($event, filter)"
      >
        {{ filter.value.description }}:
        {{ filterDescription(filter.value.value) | shorten: 15:'...' }}
      </nz-tag>
    </ng-container>
  </div>

  <i
    nz-icon
    nzType="close-circle"
    nzTheme="outline"
    (click)="removeFilters($event)"
  ></i>
</div>
