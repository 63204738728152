import { Injectable } from '@angular/core';

import OPTIONS from '../shared/options/options.json';
import PLACEHOLDERS from '../shared/options/placeholders.json';
import { TcBaseOptionType, TcOption, TcPlaceholder } from '../shared';

// TODO: Use the core lib's config service to get the hidden options
const HIDDEN_OPTIONS = window.config?.hidden_options;

@Injectable({
  providedIn: 'root',
})
export class TcOptionsService {
  private _options: Record<string, TcOption[]>;
  private _optionsTitles = new Map<string, string>();

  // TODO: Although the placeholders have similar structure to the options, they
  // are not the same thing and might need to be separated into a different
  // service for better separation of concerns.
  private _placeholders: Record<string, TcPlaceholder[]>;

  constructor() {
    this._setOptionsMap();
  }

  // ---------------------------------------------------------------------------
  // Public Methods
  // ---------------------------------------------------------------------------

  /**
   * Get the options translation keys for a given section.
   */
  options(section: string): TcOption[] {
    return this._options[section];
  }

  /**
   * Get the title translation key for a given option.
   */
  title(section: string, key: string) {
    return this._optionsTitles.get(`${section}${key}`);
  }

  /**
   * The the placeholders translation keys for a given section.
   */
  placeholders(section: string) {
    return this._placeholders[section];
  }

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  private _setOptionsMap() {
    this._options = this._getVisibleItems<TcOption>(
      OPTIONS,
      'id',
      ([section, options]) => {
        options.forEach((option) => {
          this._optionsTitles.set(`${section}${option.id}`, option.title);
        });
      },
    );

    this._placeholders = this._getVisibleItems<TcPlaceholder>(
      PLACEHOLDERS,
      'title',
    );
  }

  private _getVisibleItems<T extends TcBaseOptionType>(
    items: Record<string, T[]>,
    hiddenOptionsKey: string,
    optionHandler?: (entry: [string, T[]]) => void,
  ): Record<string, T[]> {
    return Object.fromEntries(
      Object.entries(items).map(([key, options]) => {
        if (optionHandler) {
          optionHandler([key, options]);
        }

        return [
          key,
          // Filter out hidden options
          options.filter(
            (option) =>
              !option.hidden &&
              !(HIDDEN_OPTIONS[key] ?? []).includes(
                option[hiddenOptionsKey] as string,
              ),
          ),
        ];
      }),
    );
  }
}
