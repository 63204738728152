<tc-form-wrapper
  [isModal]="isModal"
  [isSubmitting]="isSubmitting"
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  (primaryButtonClick)="save()"
  [tcFormTitle]="'aggregation.import' | translate"
>
  <form class="form" [formGroup]="formGroup">
    <nz-tabset
      nzAnimated="false"
      nzSize="small"
      [nzSelectedIndex]="selectedIndex"
      *ngIf="!single"
    >
      <nz-tab nzTitle="{{ 'aggregation.tabs.create' | translate }}">
        <tc-hub-option-select
          label="{{ 'aggregation.attrs.importer' | translate }}"
          [type]="
            tcInitialValue.type.handle === 'bill'
              ? 'aggregation.type_bill.importer'
              : 'aggregation.importer'
          "
          [formGroup]="formGroup"
          controlName="importer"
          bindLabel="title"
          bindValue="id"
        >
        </tc-hub-option-select>

        <tc-input-text-web
          *ngIf="!tcInitialValue.id"
          label="{{ 'aggregation.attrs.title' | translate }}"
          formControlName="title"
        ></tc-input-text-web>

        <tc-hub-collection-select
          *ngIf="
            importer !== 'blank' &&
            importer !== 'dispo_availability' &&
            importer !== 'billing_entry'
          "
          label="{{ 'aggregation/import.attrs.project' | translate }}"
          [collectionTypes]="['ProjectSets']"
          [formGroup]="formGroup"
          controlName="project_id"
          bindLabel="label"
          bindValue="id"
          searchable="true"
        >
        </tc-hub-collection-select>

        <tc-interval-web
          *ngIf="formGroup?.controls?.interval"
          formControlName="interval"
          [tcFieldType]="'dateTime'"
          [tcConfig]="{
            maxItemDuration: { days: 365 },
            required: true,
            fieldType: 'dateTime'
          }"
          [tcLabels]="{
            start: ('aggregation/import.attrs.starts_at' | translate),
            end: ('aggregation/import.attrs.ends_at' | translate)
          }"
        ></tc-interval-web>

        <tc-hub-remote-select
          *ngIf="importer === 'aggregation'"
          label="{{ 'aggregation/import.attrs.aggregation' | translate }}"
          url="/api/projects/{{ formGroup.value.project_id }}/aggregations"
          [formGroup]="formGroup"
          [decorate]="aggregationDecorator"
          controlName="aggregation_id"
          bindLabel="label"
          bindValue="id"
          searchable="true"
        >
        </tc-hub-remote-select>

        <div
          class="checkbox-field"
          *ngIf="
            importer !== 'blank' &&
            importer !== 'dispo_availability' &&
            importer !== 'billing_entry'
          "
        >
          <label
            nz-checkbox
            class="aggregate-checkbox"
            formControlName="aggregate"
          >
            {{ 'aggregation/import.attrs.aggregate' | translate }}
          </label>
        </div>

        <fieldset class="billing-entry" *ngIf="importer !== 'billing_entry'">
          <label>{{ 'aggregation/import.attrs.includes' | translate }}</label>
          <label nz-checkbox formControlName="includeServiceEntries">
            {{
              'aggregation/import.attrs.includes_billing_service_entries'
                | translate
            }}
          </label>

          <label
            class="checkbox-field"
            nz-checkbox
            formControlName="includeWorkEntries"
          >
            {{
              'aggregation/import.attrs.includes_billing_work_entries'
                | translate
            }}
          </label>
        </fieldset>

        <tc-hub-custom-fields
          section="aggregation.import"
          [formGroup]="formGroup"
          formControlName="aggregationImport"
        >
        </tc-hub-custom-fields>

        <tc-hub-custom-fields
          section="aggregation.{{ tcInitialValue.type.handle }}.import"
          [formGroup]="formGroup"
          formControlName="aggregationHandleImport"
        >
        </tc-hub-custom-fields>
      </nz-tab>

      <nz-tab
        *ngIf="importer !== 'blank'"
        nzTitle="{{ 'aggregation.tabs.filter' | translate }}"
      >
        <tc-hub-aggregations-import-filter-form
          [importer]="importer"
          [tcProjectId]="formGroup.value.project_id"
          formControlName="filterControl"
        ></tc-hub-aggregations-import-filter-form>
      </nz-tab>
      <nz-tab
        *ngIf="canScheduleAggregations"
        nzTitle="{{ 'aggregation.tabs.schedule' | translate }}"
      >
        <tc-hub-aggregations-schedule-form
          [aggregationType]="tcInitialValue.type"
          formControlName="scheduleControl"
        ></tc-hub-aggregations-schedule-form>
      </nz-tab>
    </nz-tabset>
  </form>
</tc-form-wrapper>
