<form class="modal-container" [formGroup]="form" *ngIf="form" novalidate>
  <div class="modal-body">
    <nz-table
      [nzData]="form.controls.entries.controls"
      nzFrontPagination="false"
      nzSize="small"
    >
      <thead>
        <tr>
          <th nzWidth="40px">{{ 'dispo/import.attrs.import' | translate }}</th>
          <th nzWidth="60px">{{ 'dispo/task.attrs.slot' | translate }}</th>
          <th nzWidth="200px">{{ 'dispo/task.attrs.job' | translate }}</th>
          <th nzWidth="130px">{{ 'dispo/task.attrs.date' | translate }}</th>
          <ng-container *ngIf="!isMulti; else multiHeader">
            <th nzWidth="180px">
              {{ 'dispo/task.attrs.starts_at' | translate }}
            </th>
            <th [nzWidth]="allowsPlus24Hours ? '130px' : '180px'">
              {{ 'dispo/task.attrs.ends_at' | translate }}
            </th>
            <th *ngIf="allowsPlus24Hours" nzWidth="50px">+24h</th>
            <th nzWidth="180px">
              {{ 'dispo/task.attrs.intermission_starts_at_short' | translate }}
            </th>
            <th nzWidth="180px">
              {{ 'dispo/task.attrs.intermission_ends_at_short' | translate }}
            </th>
            <th nzWidth="50px">
              {{ 'dispo/task.attrs.duration' | translate }}
            </th>
          </ng-container>
          <ng-template #multiHeader>
            <th nzWidth="190px">
              {{ 'dispo/task.attrs.starts_at' | translate }}
            </th>
            <th nzWidth="190px">
              {{ 'dispo/task.attrs.ends_at' | translate }}
            </th>
            <th nzWidth="90px">{{ 'actions.plural' | translate }}</th>
          </ng-template>
          <th nzWidth="100px">{{ 'dispo/task.attrs.title' | translate }}</th>
          <th formGroupName="applyToAll">
            <tc-hub-custom-fields
              class="tc-form-table"
              section="dispo.tasks.import"
              [formGroup]="form.controls.applyToAll"
              formControlName="store"
              asHeader="true"
            ></tc-hub-custom-fields>
          </th>
        </tr>
      </thead>
      <tbody class="table-multi-actions">
        <tr class="input-fields" formGroupName="applyToAll">
          <td class="input-field multi-actions-item">
            <label
              class="checkbox__extended"
              nz-checkbox
              formControlName="import"
            ></label>
          </td>
          <td class="input-field">
            <div class="form-group">
              <div class="input-group multi-actions-item">
                <div class="input-group-item">
                  <tc-input-integer-web
                    formControlName="count"
                  ></tc-input-integer-web>
                </div>
              </div>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <div class="input-group multi-actions-item">
                <div class="input-group-item">
                  <tc-hub-select
                    [items]="jobOptions$ | async"
                    [loading]="isJobOptionsLoading"
                    [formGroup]="form.controls.applyToAll"
                    controlName="sku_id"
                    bindLabel="title"
                    bindValue="id"
                    searchable="true"
                  >
                  </tc-hub-select>
                </div>
              </div>
            </div>
          </td>
          <td class="input-field">
            <tc-input-date-web formControlName="date"></tc-input-date-web>
          </td>
          <td
            class="input-field"
            [colSpan]="isMulti ? '3' : allowsPlus24Hours ? '6' : '5'"
          >
            <div class="form-group">
              <div class="input-group multi-actions-item time-set date">
                <div class="input-group-item">
                  <tc-interval-set-web
                    [formControl]="
                      form.controls['applyToAll'].controls['times']
                    "
                    [tcIsHorizontal]="true"
                    [tcIsCompact]="true"
                    [tcHideLabels]="true"
                    [tcConfig]="{
                      noValidators: true
                    }"
                  ></tc-interval-set-web>
                </div>
              </div>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <div class="input-group multi-actions-item">
                <div class="input-group-item">
                  <tc-input-text-web
                    [formGroup]="form"
                    formControlName="title"
                  ></tc-input-text-web>
                </div>
              </div>
            </div>
          </td>
          <td></td>
        </tr>
        <tr class="input-fields" formGroupName="applyToAll">
          <td class="input-field multi-actions-item">
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="applyToAll('import')"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <div class="input-group multi-actions-item">
                <div
                  class="btn btn-small btn-secondary btn-block"
                  (click)="applyToAll('count')"
                >
                  <i
                    nz-icon
                    nzType="vertical-align-bottom"
                    nzTheme="outline"
                  ></i>
                </div>
              </div>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <div class="input-group multi-actions-item">
                <div
                  class="btn btn-small btn-secondary btn-block"
                  (click)="applyToAll('sku_id')"
                >
                  <i
                    nz-icon
                    nzType="vertical-align-bottom"
                    nzTheme="outline"
                  ></i>
                  {{ 'apply_to_all' | translate }}
                </div>
              </div>
            </div>
          </td>
          <td class="input-field">
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="applyToAll('date')"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
            </div>
          </td>
          <td
            class="input-field"
            [colSpan]="isMulti ? '3' : allowsPlus24Hours ? '6' : '5'"
          >
            <div class="form-group">
              <div class="input-group multi-actions-item time-set date">
                <div class="input-group-item">
                  <button
                    class="btn btn-small btn-secondary btn-block"
                    (click)="applyToAll('times')"
                  >
                    <span
                      nz-icon
                      nzType="vertical-align-bottom"
                      nzTheme="outline"
                    ></span>
                    {{ 'apply_to_all' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <div class="input-group multi-actions-item">
                <div
                  class="btn btn-small btn-secondary btn-block"
                  (click)="applyToAll('title')"
                >
                  <i
                    nz-icon
                    nzType="vertical-align-bottom"
                    nzTheme="outline"
                  ></i>
                  {{ 'apply_to_all' | translate }}
                </div>
              </div>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
      <tbody formArrayName="entries">
        <tr
          class="input-fields"
          *ngFor="let entry of form.controls.entries.controls; index as i"
          formGroupName="{{ i }}"
        >
          <td nzAlign="center" class="import-check">
            <label
              nz-checkbox
              formControlName="import"
              [formGroup]="entry"
              (change)="toggle(entry)"
            ></label>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-input-integer-web
                [formGroup]="entry"
                formControlName="count"
              ></tc-input-integer-web>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-select
                [items]="jobOptions$ | async"
                [loading]="isJobOptionsLoading"
                [formGroup]="entry"
                controlName="sku_id"
                bindLabel="title"
                bindValue="id"
                searchable="true"
              >
              </tc-hub-select>
            </div>
          </td>
          <td class="input-field">
            <tc-input-date-web
              formControlName="date"
              [tcConfig]="{
                required: true
              }"
            ></tc-input-date-web>
          </td>
          <td
            class="input-field"
            [colSpan]="isMulti ? '3' : allowsPlus24Hours ? '6' : '5'"
          >
            <tc-interval-set-web
              [tcReferenceDate]="entry.value?.date"
              [tcIsHorizontal]="true"
              [tcIsCompact]="true"
              [tcHideLabels]="true"
              [tcConfig]="{
                required: true
              }"
              [style.width.%]="100"
              formControlName="times"
            ></tc-interval-set-web>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-input-text-web
                [formGroup]="entry"
                formControlName="title"
              ></tc-input-text-web>
            </div>
          </td>

          <td class="input-field">
            <tc-hub-custom-fields
              class="tc-form-table"
              section="dispo.tasks.import"
              [formGroup]="entry"
              formControlName="store"
              asRow="true"
            >
            </tc-hub-custom-fields>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="modal-footer">
    <button type="button" nz-button nzType="primary" (click)="prev()">
      {{ 'back' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [disabled]="form.controls.entries.invalid"
      (click)="next()"
    >
      {{ 'next' | translate }}
    </button>
  </div>
</form>
