import { Injectable } from '@angular/core';

import { SetsCollection } from '../../core/sets-collection';

export class DispoGroup {
  id: number;
  visibiltiy: number;
  title: string;
  icon: string;
  color: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class DispoGroupCollection extends SetsCollection {
  identifier = 'tc_dispo/settings/group';
  endpoint = '/api/dispo/settings/groups';

  localSearch = ['title'];

  cache = 60 * 60 * 12;

  type = DispoGroup;

  visible() {
    return this.query({ 'filter[visibility]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visibility]': '0' }, (e) => e.visibility === 0);
  }
}
