import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { combineLatest } from 'rxjs';

import { Pin, PinCollection } from '../../../pins/pin';
import { UserCollection } from '../../../core/collections/user';
import { AccountRoleCollection } from '../../../core/collections/account-roles-collection';
import { User } from '../../../shared';
import { Role } from '../../../core/types/account-roles.model';

@Component({
  selector: 'tc-hub-pi-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
})
export class PIGeneralComponent implements OnInit {
  @Input() pin: Pin;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private pinCollection: PinCollection,
    private formBuilder: UntypedFormBuilder,
    private accountRoleCollection: AccountRoleCollection,
    private userCollection: UserCollection,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  save() {
    this.loading = true;
    const { resources, ...rest } = this.form.value;

    const user_ids = resources
      .filter(
        (resource) => resource instanceof User || resource.type === 'User',
      )
      .map((user) => user.id);

    const user_group_ids = resources
      .filter(
        (resource) =>
          resource instanceof Role || resource.type === 'AccountRole',
      )
      .map((accountRole) => accountRole.id);

    const newValues = {
      ...new Pin(),
      ...this.pin,
      ...rest,
      user_group_ids,
      user_ids,
    };

    this.pinCollection.update(this.pin.id, newValues).subscribe({
      next: () => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm() {
    const {
      note,
      level,
      state,
      visible_at,
      expires_at,
      user_ids,
      store,
      user_group_ids,
    } = this.pin ?? {};

    this.form = this.formBuilder.group({
      note: [note, [Validators.required]],
      level: [level],
      state: [state],
      resources: [[]],
      visible_at: [visible_at],
      expires_at: [expires_at],
      store: [store],
    });

    const resourceCollections = [
      ...user_ids.map((userId) =>
        this.userCollection.find(userId).asObservable(),
      ),
      ...user_group_ids.map((groupId) =>
        this.accountRoleCollection.find(groupId).asObservable(),
      ),
    ];

    combineLatest(resourceCollections).subscribe((collections) => {
      const currentValue = this.form.get('resources').value as any[];
      this.form.get('resources').setValue([...currentValue, ...collections]);
    });
  }
}
