import { NgModule } from '@angular/core';

import {
  TcFormFieldModule,
  TcInputModule,
  TcPanelComponent,
} from '../../components';
import { SharedModule } from '../../shared/shared.module';
import { ValueAccessorDirective } from '../../shared';

import { TcIntervalWebComponent } from './interval-web/interval-web.component';

@NgModule({
  declarations: [TcIntervalWebComponent],
  exports: [TcIntervalWebComponent],
  imports: [
    SharedModule,
    TcPanelComponent,
    TcFormFieldModule,
    ValueAccessorDirective,
    TcInputModule,
  ],
})
export class TcIntervalModule {}
