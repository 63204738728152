import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { DispoTaskCollection, Task } from '../../../dispo/collections/task';
import { DispoAssignmentCollection } from '../../../dispo/collections/assignment';
import { Assignment } from '../../../dispo/collections/assignment.model';

@Component({
  selector: 'tc-hub-dt-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DTDeleteComponent implements OnInit {
  @Input() task: Task;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  loading = true;
  messages: string[] = [];
  assignments: Assignment[] = [];

  constructor(
    private taskCollection: DispoTaskCollection,
    private assignmentCollection: DispoAssignmentCollection,
  ) {}

  ngOnInit() {
    combineLatest(
      this.taskCollection.deletable(this.task.id, this.task),
      this.assignmentCollection.forTask(this.task),
    )
      .pipe(first())
      .subscribe({
        next: ([deletable, assignments]) => {
          this.loading = false;
          this.messages = Object.values(deletable.messages);
          this.assignments = assignments;

          this.signal.emit({ action: 'resize' });
        },
      });
  }

  delete() {
    this.loading = true;

    this.assignmentCollection
      .forTask(this.task)
      .pipe(first())
      .subscribe({
        next: (assignments) => {
          if (assignments.length === 0) {
            this.taskCollection.delete(this.task.id, this.task).subscribe({
              next: () => {
                this.loading = false;
                this.signal.emit({ action: 'close' });
              },
            });
          } else {
            combineLatest(
              assignments.map((assignment) =>
                this.assignmentCollection.delete(assignment.id, assignment),
              ),
            ).subscribe({
              next: (_) => {
                this.taskCollection.delete(this.task.id, this.task).subscribe({
                  next: () => {
                    this.loading = false;
                    this.signal.emit({ action: 'close' });
                  },
                });
              },
            });
          }
        },
      });
  }
}
