import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { LetModule } from '@rx-angular/template/let';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { TcUtilsModule } from '@timecount/utils';

import { TcCompactDirective } from './compact.directive';
import { TcFormControlValidatorDirective } from './form-control-validator.directive';
import { TcFormPickersModule } from './form-pickers/form-pickers.module';
import { TcInputDateTimeReferenceDirective } from './input-date-time-ref.directive';
import { TcInputTimeDateOptionsDirective } from './input-time-date-options.directive';
import { NzButtonTabindexSupportDirective } from './nzbutton-tabindex-support.directive';
import {
  TcTimesSummaryIonicComponent,
  TcTimesSummaryWebComponent,
} from './times-summary';

@NgModule({
  declarations: [
    NzButtonTabindexSupportDirective,
    TcCompactDirective,
    TcFormControlValidatorDirective,
    TcInputDateTimeReferenceDirective,
    TcInputTimeDateOptionsDirective,
    TcTimesSummaryIonicComponent,
    TcTimesSummaryWebComponent,
  ],
  exports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // 3rd Party
    TranslateModule,
    RxReactiveFormsModule,
    NgSelectModule,
    NzAlertModule,
    NzButtonModule,
    NzCheckboxModule,
    NzIconModule,
    NzInputModule,
    NzPopoverModule,
    NzSegmentedModule,
    NzSelectModule,
    NzRadioModule,
    IonicModule,
    LetModule,
    NzButtonModule,
    NzGridModule,
    NzListModule,
    NzCollapseModule,
    NzCardModule,
    NzDividerModule,

    // Lib
    NzButtonTabindexSupportDirective,
    TcFormPickersModule,
    TcInputDateTimeReferenceDirective,
    TcInputTimeDateOptionsDirective,
    TcFormControlValidatorDirective,
    TcTimesSummaryIonicComponent,
    TcTimesSummaryWebComponent,
    TcUtilsModule,
  ],

  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // 3rd Party
    TranslateModule,
    RxReactiveFormsModule,
    NgSelectModule,
    NzAlertModule,
    NzButtonModule,
    NzCheckboxModule,
    NzIconModule,
    NzInputModule,
    NzPopoverModule,
    NzSegmentedModule,
    NzSelectModule,
    IonicModule,
    NzRadioModule,
    LetModule,
    NzButtonModule,
    NzGridModule,
    NzListModule,
    NzCollapseModule,
    NzDividerModule,
    NzCardModule,

    // Lib
    TcUtilsModule,
    TcFormPickersModule,
  ],
})
export class SharedModule {}
