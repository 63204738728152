import { Injectable } from '@angular/core';

import { FrameCollection } from '../../../core/frame-collection';

@Injectable({
  providedIn: 'root',
})
export class BillingWorkEntryCollection extends FrameCollection {
  cache = 600;
  identifier = 'billing/work_entry';
  endpoint = '/api/finances/billing/work_entries/range';

  remoteDeleteValidation = true;
}
