import { Injectable } from '@angular/core';
import { ExclusionPolicy, Strategy, Type } from 'typeserializer';

import { FrameCollection } from '../../core/frame-collection';

class Resource {
  type: string;
  id: number;
}

@Strategy(ExclusionPolicy.NONE)
export class Announcement {
  id: number;

  venue_id: number;
  plan_id: number;
  schedule_id: number;
  task_ids: number[] = [];
  task_slots: number[] = [];

  marker: [number, number];

  title: string;
  description: string;

  size: number;

  push_notifications = false;
  resources: Resource[] = [];

  @Type(Object)
  store: object;

  @Type(Object)
  sizes: object;

  // task specific state
  accepted_size = 0;
  state = undefined;
}

@Injectable({
  providedIn: 'root',
})
export class DispoAnnouncementCollection extends FrameCollection {
  type: Announcement;
  cache = 600;
  identifier = 'tc_dispo/announcement';
  endpoint = '/api/dispo/announcements/range';

  remoteDeleteValidation = true;

  forTask(task) {
    return this.filter(
      (a) => a.task_ids.indexOf(task.id) !== -1,
      `task_id: ${task.id}`,
    );
  }

  fromTemplate(template) {
    const obj = Object.assign(new Announcement(), template);

    return obj;
  }
}
