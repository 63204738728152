<div class="row item" data-itemtype="Employee" [attr.data-itemid]="employee.id">
  <tc-hub-dispo-employees-grid-header
    class="header"
    [employee]="employee"
  ></tc-hub-dispo-employees-grid-header>

  <div class="days">
    <tc-hub-dispo-shift-grid
      class="grid-condensed"
      [dates]="dates$"
      [dateWidth]="gridDateWidth"
      [resolution]="gridResolution"
    >
      <ng-container
        *ngIf="gridItemTogglerService.isTypeVisible('TimeBalance') | async"
      >
        <div
          class="slot slot-timebalances"
          *ngFor="let timebalanceGroup of timebalances$ | async"
          tcHubDispoShiftGridItem
          [begin]="timebalanceGroup[0]"
          [panelBegin]="panelBegin"
          [panelEnd]="panelEnd"
          [resolution]="gridResolution"
          row="1"
        >
          <tc-hub-dispo-employee-grid-time-balance
            class="timebalance-item"
            *ngFor="let timebalance of timebalanceGroup[1]"
            [timebalance]="timebalance"
            (contextmenu)="onMenu($event, 'TimeBalance', timebalance)"
            (mouseup)="onMenu($event, 'TimeBalance', timebalance)"
            (mouseover)="onMouseOver('TimeBalance', timebalance)"
          ></tc-hub-dispo-employee-grid-time-balance>
        </div>
      </ng-container>
      <div
        class="grid-day-items"
        *ngFor="let dayCards of cards$ | async"
        row="2"
        tcHubDispoShiftGridItem
        [begin]="dayCards[0]?.task?.starts_at"
        [panelBegin]="panelBegin"
        [panelEnd]="panelEnd"
        [resolution]="gridResolution"
        [class.is-stacked]="dayCards.length > 1"
      >
        <ng-container
          *ngFor="
            let item of dayCards | tcOrderBy: ['type', 'state', '-starts_at'];
            let i = index
          "
        >
          <ng-container
            *ngIf="gridItemTogglerService.isTypeVisible('Assignment') | async"
          >
            <tc-hub-dispo-employee-grid-assignment
              class="grid-item grid-item-assignment"
              *ngIf="item.type === 'DispoAssignment'"
              [assignment]="item"
              [popoverPlacement]="dayCards.length > 1 ? 'right' : 'top'"
              [style.transform]="
                'translate(' +
                4 * (dayCards.length - 1 - i) +
                'px, -' +
                96 * i +
                '%)'
              "
              [style.z-index]="20 + dayCards.length - 1 - i"
            ></tc-hub-dispo-employee-grid-assignment>
          </ng-container>

          <ng-container
            *ngIf="gridItemTogglerService.isTypeVisible('Invitation') | async"
          >
            <tc-hub-dispo-employee-grid-invitation
              class="grid-item grid-item-assignment"
              *ngIf="item.type === 'DispoInvitation'"
              [invitation]="item"
              [popoverPlacement]="dayCards.length > 1 ? 'right' : 'top'"
              [style.transform]="
                'translate(' +
                4 * (dayCards.length - 1 - i) +
                'px, -' +
                96 * i +
                '%)'
              "
              [style.z-index]="20 + dayCards.length - 1 - i"
            ></tc-hub-dispo-employee-grid-invitation>
          </ng-container>
        </ng-container>
      </div>
    </tc-hub-dispo-shift-grid>

    <tc-hub-dispo-shift-grid
      class="grid-availabilities"
      [dates]="dates$"
      [dateWidth]="gridDateWidth"
      [resolution]="gridResolution"
    >
      <ng-container
        *ngIf="gridItemTogglerService.isTypeVisible('Availability') | async"
      >
        <div
          *ngFor="
            let availability of availabilities$ | async | orderBy: 'starts_at'
          "
          tcHubDispoShiftGridItem
          class="grid-item grid-item-availability"
          [begin]="availability.starts_at"
          [end]="availability.ends_at"
          [panelBegin]="panelBegin"
          [panelEnd]="panelEnd"
          [cutoffStart]="availability.cutoffStart"
          [cutoffEnd]="availability.cutoffEnd"
          [resolution]="gridResolution"
          (contextmenu)="onMenu($event, 'DispoAvailability', availability)"
          (mouseup)="onMenu($event, 'DispoAvailability', availability)"
          (mouseover)="onMouseOver('DispoAvailability', availability)"
          style="z-index: 5"
        >
          <tc-hub-dispo-employee-grid-availability
            class="slot-availability"
            [availability]="availability"
            [overflowsLeft]="
              availability.starts_at < panelBegin && !availability.cutoffStart
            "
            [overflowsRight]="
              availability.ends_at > panelEnd && !availability.cutoffEnd
            "
            [cutoffStart]="availability.cutoffStart"
            [cutoffEnd]="availability.cutoffEnd"
          ></tc-hub-dispo-employee-grid-availability>
        </div>
      </ng-container>
    </tc-hub-dispo-shift-grid>
  </div>
</div>
