import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {
  IntervalSummary,
  TcConfigService,
  TcIntervalList,
} from '@timecount/core';
import {
  TcFieldSetIntervalSetService,
  TcTimesSummaryService,
} from '@timecount/ui';

import { FinancesEntriesService } from '../finances-entries.service';
import { FinancesEntriesItemComponent } from '../item.component';
import { BillingStateInfo } from '../../../projects/entry-billing-state.model';

@Component({
  selector: 'tc-hub-finances-entries-service-entry',
  templateUrl: './service_entry.component.html',
  styleUrls: ['./service_entry.component.scss'],
})
export class FinancesEntriesServiceEntryComponent
  extends FinancesEntriesItemComponent
  implements OnChanges
{
  @Input() item: any;

  timesheetSummary: IntervalSummary;
  parsedTimesheetTimes: Interval[];
  billingStateInfo = BillingStateInfo;

  constructor(
    protected service: FinancesEntriesService,
    private configService: TcConfigService,
    private intervalSetService: TcFieldSetIntervalSetService,
    private summaryService: TcTimesSummaryService,
  ) {
    super(service);
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    if ($event.type === 'mouseup' && $event.button === 2) {
      return;
    }

    const nextState = $event.type === 'contextmenu' ? true : !this.item.checked;
    this.selectItem(this.item.type, this.item.id, nextState, $event);
  }

  ngOnChanges({ item }: SimpleChanges): void {
    if (item) {
      this.parsedTimesheetTimes = TcIntervalList.parseFromApi(this.item);

      const parsedTimes = this.intervalSetService.parseFromApi(this.item);
      this.timesheetSummary = this.summaryService.getSummary(parsedTimes);
    }
  }
}
