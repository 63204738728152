import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { DateUnicodeFormat } from '@timecount/utils';

@Component({
  selector: 'tc-hub-dispo-employee-grid-availability',
  templateUrl: './employee-grid-availability.component.html',
  styleUrls: ['./employee-grid-availability.component.scss'],
})
export class EmployeeGridAvailabilityComponent implements OnInit {
  @Input() availability: any;
  @Input() expanded: boolean;
  @Input() timeFormat = 'H';

  @Input()
  @HostBinding('class.overflows-left')
  overflowsLeft: boolean;

  @Input()
  @HostBinding('class.overflows-right')
  overflowsRight: boolean;

  @Input()
  @HostBinding('class.cutoff-start')
  cutoffStart: boolean;

  @Input()
  @HostBinding('class.cutoff-end')
  cutoffEnd: boolean;

  @HostBinding('style')
  get style() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--availability-color: ${this.availability.type?.color || 'lightgray'}`,
    );
  }

  dateFormat = DateUnicodeFormat.dateTime;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.expanded) {
      this.timeFormat += ':mm';
    }
  }
}
