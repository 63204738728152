<div class="tc-modal-body" #modalBody>
  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="!single"
    (nzSelectedIndexChange)="redraw()"
  >
    <nz-tab
      nzTitle="{{ 'dispo/schedule.tab_type.general' | translate }}"
      *tcHubHide="'views.dispo.schedule.general'"
    >
      <tc-hub-ds-general
        class="modal-container"
        [schedule]="schedule"
        [plan]="plan"
        (signal)="onSignal($event)"
      ></tc-hub-ds-general>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/schedule.tab_type.times' | translate }}"
      *tcHubHide="'views.dispo.schedule.times'"
    >
      <ng-template nz-tab>
        <tc-hub-ds-times
          class="modal-container"
          [schedule]="schedule"
          [plan]="plan"
          (signal)="onSignal($event)"
        ></tc-hub-ds-times>
      </ng-template>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/schedule.tab_type.public' | translate }}"
      *tcHubHide="'views.dispo.schedule.public'"
    >
      <ng-template nz-tab>
        <tc-hub-ds-public
          class="modal-container"
          [schedule]="schedule"
          [plan]="plan"
          (signal)="onSignal($event)"
        ></tc-hub-ds-public>
      </ng-template>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/schedule.tab_type.days' | translate }}"
      *tcHubHide="'views.dispo.schedule.days'"
    >
      <ng-template nz-tab>
        <tc-hub-ds-days
          class="modal-container"
          [schedule]="schedule"
          [plan]="plan"
          (signal)="onSignal($event)"
        ></tc-hub-ds-days>
      </ng-template>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/schedule.tab_type.slots' | translate }}"
      *tcHubHide="'views.dispo.schedule.slots'"
    >
      <ng-template nz-tab>
        <tc-hub-ds-slots
          class="modal-container"
          [schedule]="schedule"
          [plan]="plan"
          (signal)="onSignal($event)"
        ></tc-hub-ds-slots>
      </ng-template>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/schedule.tab_type.move' | translate }}"
      *tcHubHide="'views.dispo.schedule.move'"
    >
      <ng-template nz-tab>
        <tc-hub-ds-move
          class="modal-container"
          [schedule]="schedule"
          [plan]="plan"
          (signal)="onSignal($event)"
        ></tc-hub-ds-move>
      </ng-template>
    </nz-tab>
  </nz-tabset>

  <ng-container *ngIf="section === 'create'">
    <tc-hub-ds-create
      class="modal-container"
      [config]="schedule"
      (signal)="onSignal($event)"
      *tcHubHide="'views.dispo.schedule.create'"
    ></tc-hub-ds-create>
  </ng-container>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'delete'">
    <nz-tab
      nzTitle="{{ 'dispo/schedule.tab_type.delete' | translate }}"
      *tcHubHide="'views.dispo.schedule.delete'"
    >
      <tc-hub-ds-delete
        class="modal-container"
        [schedule]="schedule"
        (signal)="onSignal($event)"
      ></tc-hub-ds-delete>
    </nz-tab>
  </nz-tabset>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
