import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SetsCollection } from '../sets-collection';
import { Cable } from '../cable';
import { StoreService } from '../store.service';
import { ApiErrorService } from '../api-error.service';
import { CurrentUserService } from '../current-user.service';
import { User } from '../../shared/models';
import { LocalSettingsService } from '../local-settings.service';

@Injectable({
  providedIn: 'root',
})
export class UserCollection extends SetsCollection {
  identifier = 'accounts/user';
  endpoint = '/api/accounts/users';

  cache = 60 * 60;

  localSearch = ['last_name', 'first_name', 'email', 'shorthand'];

  type = User;

  constructor(
    http: HttpClient,
    cable: Cable,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    protected localSettingsService: LocalSettingsService,
    @Inject('Flash') flash,
  ) {
    super(
      http,
      cable,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
      flash,
    );

    this.decorators = [];
  }

  decorate(obj, decorators): User {
    obj.title = obj.label = [obj.shorthand, obj.first_name, obj.last_name]
      .filter((a) => a)
      .join(' ');

    return obj;
  }
}
