import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzTimePickerComponent } from 'ng-zorro-antd/time-picker';

/**
 * This component controls already implemented ng-zorro pickers, like time and
 * date, in order to allow their use with the custom `tc-input-*` and as
 * general interface pickers (non-input).
 */
@Component({
  selector: 'tc-form-nz-picker-base',
  template: '<ng-content></ng-content>',
})
export class TcNzPickerBaseComponent implements AfterViewInit {
  @Input()
  picker!: NzDatePickerComponent | NzTimePickerComponent;

  /**
   * HACK:
   *
   * We are forcibly checking for a 'click outside' of the date picker, because
   * after upgrading ng-zorro to version 12, the `focusOut` event doesn't seem
   * to be triggered when manually opening the picker.
   *
   * @see https://github.com/NG-ZORRO/ng-zorro-antd/blob/65fdbc8a5442fed0d4b131ff8147b5b8f10f9f38/components/date-picker/date-picker.component.ts#L426-L432
   */
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: MouseEvent): void {
    if (
      this.picker instanceof NzDatePickerComponent &&
      !document
        .querySelector('.ant-picker-wrapper')
        ?.contains(event.target as HTMLElement)
    ) {
      this.picker.checkAndClose();
      this.picker.renderClass(false);
    }
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngAfterViewInit() {
    this.picker.open();
  }
}
