import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  TcFieldSetIntervalSetService,
  TcTimesSummaryService,
} from '@timecount/ui';

import { SetsCollection } from '../../../core/sets-collection';
import { ApiErrorService } from '../../../core/api-error.service';
import { Cable } from '../../../core/cable';
import { CurrentUserService } from '../../../core/current-user.service';
import { LocalSettingsService } from '../../../core/local-settings.service';
import { StoreService } from '../../../core/store.service';

import { WorkEntry } from './work_entry.model';

@Injectable({
  providedIn: 'root',
})
export class WorkEntryCollection extends SetsCollection {
  identifier = 'projects/aggregations/work_entry';
  endpoint =
    '/api/projects/:project_id/aggregations/:aggregation_id/work_entries';

  cache = 0;
  remoteDeleteValidation = true;
  refreshOnStale = false;

  constructor(
    private summaryService: TcTimesSummaryService,
    private intervalSetService: TcFieldSetIntervalSetService,
    protected http: HttpClient,
    protected cable: Cable,
    protected store: StoreService,
    protected errorHandler: ApiErrorService,
    protected currentUser: CurrentUserService,
    protected localSettingsService: LocalSettingsService,
    @Inject('Flash') protected flash,
  ) {
    super(
      http,
      cable,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
      flash,
    );
  }

  forProject(project_id, aggregation_id: number | string = 'billing') {
    return this.query(
      {
        project_id: project_id,
        aggregation_id: aggregation_id,
      },
      (a) => {
        return Number.isInteger(parseInt(aggregation_id.toString(), 10))
          ? a.aggregation_id === aggregation_id
          : a.project_id === project_id && a.main;
      },
    );
  }

  decorate(work_entry: WorkEntry): WorkEntry {
    work_entry.summary = this.summaryService.getSummary(
      this.intervalSetService.parseFromApi(work_entry),
    );
    return work_entry;
  }
}
