<form class="modal-container" [formGroup]="formGroup" novalidate>
  <div class="modal-body">
    <nz-table
      [nzData]="slots_config.controls"
      nzFrontPagination="false"
      nzSize="small"
    >
      <thead>
        <tr>
          <th>{{ 'aggregation_entry/work.batch.attrs.slot' | translate }}</th>
          <th>{{ 'aggregation_entry/work.batch.attrs.gratis' | translate }}</th>
          <th>{{ 'aggregation_entry/work.batch.attrs.job' | translate }}</th>
          <th>{{ 'aggregation_entry/work.batch.attrs.group' | translate }}</th>
          <th>{{ 'aggregation_entry/work.batch.attrs.roles' | translate }}</th>
          <th>
            {{
              'aggregation_entry/work.batch.attrs.qualifications' | translate
            }}
          </th>
          <th>
            <tc-hub-custom-fields
              section="work_entry.batch.slot"
              [formGroup]="formGroup"
              formControlName="store"
              asHeader="true"
            ></tc-hub-custom-fields>
          </th>
        </tr>
      </thead>
      <tbody class="table-multi-actions">
        <tr class="input-fields">
          <td>{{ 'apply_to_all' | translate }}</td>
          <td nzAlign="center">
            <label
              [formGroup]="formGroup"
              nz-checkbox
              formControlName="gratis"
            ></label>

            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="set('gratis', formGroup.get('gratis').value)"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
              {{ 'set_all' | translate }}
            </div>
          </td>
          <td></td>
          <td class="input-field">
            <tc-hub-collection-select
              [formGroup]="formGroup"
              [collectionTypes]="['DispoGroup']"
              controlName="group_id"
              bindLabel="title"
              bindValue="id"
              searchable="true"
            >
            </tc-hub-collection-select>
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="set('group_id', formGroup.get('group_id').value)"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
              {{ 'set_all' | translate }}
            </div>
          </td>
          <td class="input-field">
            <tc-hub-collection-select
              [formGroup]="formGroup"
              [collectionTypes]="['DispoRole']"
              controlName="role_ids"
              bindLabel="title"
              bindValue="id"
              multiple="true"
              searchable="true"
            >
            </tc-hub-collection-select>
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="set('role_ids', formGroup.get('role_ids').value)"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
              {{ 'set_all' | translate }}
            </div>
          </td>
          <td class="input-field">
            <tc-hub-collection-select
              [formGroup]="formGroup"
              [collectionTypes]="['Qualification']"
              controlName="qualification_ids"
              bindLabel="title"
              bindValue="id"
              searchable="true"
              multiple="true"
            >
            </tc-hub-collection-select>
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="
                set(
                  'qualification_ids',
                  formGroup.get('qualification_ids').value
                )
              "
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
              {{ 'set_all' | translate }}
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
      <tbody formArrayName="slots_config">
        <tr
          class="input-fields"
          *ngFor="let slot of slots_config.controls; index as i"
          formGroupName="{{ i }}"
        >
          <td class="input-field">
            <div class="form-group">
              <strong>{{ slot.controls.position.value + 1 }}</strong>
            </div>
          </td>
          <td nzAlign="center">
            <label
              [formGroup]="slot"
              nz-checkbox
              formControlName="gratis"
            ></label>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-collection-select
                [formGroup]="slot"
                [collectionTypes]="['Job']"
                controlName="job_id"
                bindLabel="title"
                bindValue="id"
                searchable="true"
              >
              </tc-hub-collection-select>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-collection-select
                [formGroup]="slot"
                [collectionTypes]="['DispoGroup']"
                controlName="group_id"
                bindLabel="title"
                bindValue="id"
                searchable="true"
              >
              </tc-hub-collection-select>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-collection-select
                [formGroup]="slot"
                [collectionTypes]="['DispoRole']"
                controlName="role_ids"
                bindLabel="title"
                bindValue="id"
                multiple="true"
                searchable="true"
              >
              </tc-hub-collection-select>
            </div>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-collection-select
                [formGroup]="slot"
                [collectionTypes]="['Qualification']"
                controlName="qualification_ids"
                bindLabel="title"
                bindValue="id"
                searchable="true"
                multiple="true"
              >
              </tc-hub-collection-select>
            </div>
          </td>
          <td class="input-field">
            <tc-hub-custom-fields
              section="work_entry.batch.slot"
              [formGroup]="slot"
              formControlName="store"
              asRow="true"
            ></tc-hub-custom-fields>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      (click)="prev()"
      [disabled]="formGroup.invalid"
    >
      {{ 'back' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      (click)="next()"
      [disabled]="formGroup.invalid"
    >
      {{ 'next' | translate }}
    </button>
  </div>
</form>
