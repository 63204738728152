<div
  tcHubDispoMenuActive
  activeType="DispoInvitationSlot"
  [activeMatch]="{
    x: invitation.task.marker[0],
    announcement_id: invitation.announcement_id,
    invitation_id: invitation.id
  }"
  class="wrapper"
  nz-popover
  [nzPopoverContent]="invitationDetails"
  [nzPopoverPlacement]="popoverPlacement"
  nzPopoverOverlayClassName="tc-popover with-padding"
  [class.is-expanded]="expanded"
  (contextmenu)="onMenu($event, 'DispoInvitationSlot', invitation)"
  (mouseup)="onMenu($event, 'DispoInvitationSlot', invitation)"
>
  <label class="label">
    <div class="status">
      <i
        nz-icon
        nzType="question-circle"
        *ngIf="invitation.state === 'open'"
        nz-tooltip
        nzTooltipTitle="{{ 'dispo/invitation.tooltip.open' | translate }}"
      ></i>
      <i
        nz-icon
        nzType="check-circle"
        nzTheme="fill"
        *ngIf="invitation.state === 'accepted'"
        nz-tooltip
        nzTooltipTitle="{{ 'dispo/invitation.tooltip.accepted' | translate }}"
      ></i>
      <i
        nz-icon
        nzType="close-circle"
        *ngIf="invitation.state === 'cancelled'"
        nz-tooltip
        nzTooltipTitle="{{ 'dispo/invitation.tooltip.cancelled' | translate }}"
      ></i>
      <i
        nz-icon
        nzType="stop"
        *ngIf="invitation.state === 'refused'"
        nz-tooltip
        nzTooltipTitle="{{ 'dispo/invitation.tooltip.refused' | translate }}"
      ></i>
      <i
        nz-icon
        nzType="link"
        *ngIf="invitation.assigned"
        nz-tooltip
        nzTooltipTitle="{{ 'dispo/invitation.tooltip.assigned' | translate }}"
      ></i>
      <i
        nz-icon
        nzType="warning"
        *ngIf="invitation.collision"
        nz-tooltip
        nzTooltipTitle="{{ 'dispo/invitation.tooltip.collision' | translate }}"
      ></i>
      <i
        nz-icon
        nzType="close"
        *ngIf="invitation.unavailable"
        nz-tooltip
        nzTooltipTitle="{{
          'dispo/invitation.tooltip.unavailable' | translate
        }}"
      ></i>
    </div>

    <ng-container *ngIf="expanded; then expandedLabel; else condensedLabel">
    </ng-container>

    <ng-template #expandedLabel>
      {{
        'dispo/invitation.label.employees_expanded'
          | translate: { invitation: invitation }
      }}
    </ng-template>

    <ng-template #condensedLabel>
      {{
        'dispo/invitation.label.employees_condensed'
          | translate: { invitation: invitation }
      }}
    </ng-template>
  </label>
</div>

<ng-template #invitationDetails>
  <span class="details-popover">
    {{ invitation.task?.title }} @ {{ invitation.plan?.title }}<br />
    <tc-times-summary-web
      [intervalList]="timesList"
      [expanded]="true"
      [isCompact]="true"
    ></tc-times-summary-web>
  </span>
</ng-template>
