import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import { DispoAssignmentCollection } from '../../../dispo/collections/assignment';
import { Assignment } from '../../../dispo/collections/assignment.model';
import { DispoAvailabilityCollection } from '../../../dispo/collections/availability';
import { DispoTaskCollection } from '../../../dispo/collections/task';
import { ResourceTemplateCollection } from '../../../core/collections/resource_template';

@Component({
  selector: 'tc-hub-da-avail',
  templateUrl: './avail.component.html',
  styleUrls: ['./avail.component.scss'],
})
export class DAAvailComponent implements OnInit {
  @Input() assignments: Assignment[];

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private assignmentCollection: DispoAssignmentCollection,
    private taskCollection: DispoTaskCollection,
    private availCollection: DispoAvailabilityCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.resourceTemplateCollection
      .default('dispo.availabilities.assignment')
      .pipe(first())
      .subscribe({
        next: (template: any) => {
          this.form = this.buildForm(template.template || {});
          this.signal.emit({ action: 'resize' });
        },
      });
  }

  save() {
    const formValues = this.form.value;
    const assignments = this.assignments;
    const avails = [];

    this.loading = true;

    this.taskCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (tasks) => {
          assignments.forEach((assignment) => {
            const task = tasks.find((t) => t.id === assignment.task_id);

            avails.push({
              employee_id: assignment.resource_id,
              resource_id: assignment.resource_id,
              resource_type: assignment.resource_type,
              starts_at: task.starts_at,
              ends_at: task.ends_at,
              interval_type: 'range',
              interval_days: [],
              interval_shifts: [],
              type_id: formValues.type_id,
              source_id: formValues.source_id,
              note: formValues.note,
            });
          });

          combineLatest(
            avails.map((a) => this.availCollection.create(a)),
          ).subscribe({
            next: (items) => {
              this.loading = false;
              this.signal.emit({ action: 'close' });
            },
            error: () => {
              this.loading = false;
            },
          });
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  private buildForm(template) {
    return this.formBuilder.group({
      type_id: [template.type_id, [Validators.required]],
      source_id: [template.source_id, [Validators.required]],
      note: [template.note],
    });
  }
}
