import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiErrorService {
  constructor(@Inject('ApiError') private apiError) {}

  handle(resp) {
    resp.data = resp.error;
    this.apiError.handle(resp);
  }
}
