import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { endOfDay, startOfDay } from 'date-fns';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

import { isValidDate } from '@timecount/utils';

import { DispoFocusService } from '../../../../dispo/dispo-focus.service';
import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-date-range-filter-widget',
  templateUrl: './date-range-filter-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './date-range-filter-widget.component.scss',
  ],
})
export class DateRangeFilterWidgetComponent
  extends TcControlWidgetBaseDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  private subs = [];

  public interval: Interval;
  public limit: Interval;
  public disabled = false;

  form: UntypedFormGroup;

  constructor(
    private dispoFocus: DispoFocusService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit() {
    this.subs.push(
      this.dispoFocus.dates().subscribe((dates) => {
        this.limit = {
          start: startOfDay(dates[0]),
          end: endOfDay(dates[dates.length - 1]),
        };
      }),
    );

    const { starts_at, ends_at } = this.formProperty.value;

    this.form = this._buildForm({
      start: new Date(starts_at ?? this.limit.start),
      end: new Date(ends_at ?? this.limit.end),
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.formProperty.valueChanges
      .pipe(
        distinctUntilChanged(
          (prev, curr) => prev.start === curr.start && prev.end === curr.end,
        ),
      )
      .subscribe((value) => {
        this.form.get('range').patchValue(
          {
            start: new Date(value.starts_at),
            end: new Date(value.ends_at),
          },
          { onlySelf: true, emitEvent: false },
        );
      });

    this.form.get('range').valueChanges.subscribe((value: Interval) => {
      this.formProperty.setValue(
        {
          starts_at: isValidDate(value?.start)
            ? (value.start as Date).getTime()
            : undefined,
          ends_at: isValidDate(value?.end)
            ? (value.end as Date).getTime()
            : undefined,
          valid: true,
        },
        false,
      );
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm(initialValue: Interval) {
    return this.formBuilder.group({
      range: [
        { value: initialValue, disabled: this.disabled },
        [Validators.required],
      ],
    });
  }
}
