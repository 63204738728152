<button
  class="select-btn"
  nz-button
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzTrigger="click"
>
  {{ 'directives.range_select.title' | translate }}
</button>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li
      nz-menu-item
      *ngFor="let interval of intervals"
      (click)="selectRange(interval)"
    >
      {{ 'directives.range_select.' + interval.name | translate }}
    </li>
  </ul>
</nz-dropdown-menu>
