import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@timecount/ui';

import { PinComponent } from '../pins/pin/pin.component';
import { PinCollection } from '../pins/pin';

@Injectable({
  providedIn: 'root',
})
export class PinsService {
  private translations: { [key: string]: string } = {};

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private pinCollection: PinCollection,
  ) {
    this.translateService.get(['pin.modal.title']).subscribe((value) => {
      Object.assign(this.translations, value);
    });
  }

  create(pin) {
    pin = Object.assign({}, pin);
    return this.pinCollection.create(pin);
  }

  edit(pin) {
    pin = Object.assign({}, pin);
    const ref = this.modalService.open(PinComponent, {
      data: {
        section: 'edit',
        pin: pin,
      },
      modalTitle: this.translations['pin.modal.title'],
    });

    return ref;
  }

  delete(pin) {
    pin = Object.assign({}, pin);
    return this.pinCollection.delete(pin.id, pin);
  }

  toggle(pin) {
    pin = Object.assign({}, pin);
    pin.pinned = !pin.pinned;
    return this.pinCollection.update(pin.id, pin);
  }

  state(pin, state) {
    pin = Object.assign({}, pin);
    pin.state = state;
    return this.pinCollection.update(pin.id, pin);
  }
}
