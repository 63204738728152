import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { isPositiveInteger } from './number-helpers';

@Pipe({ name: 'ordinal' })
export class OrdinalNumberPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(int: unknown, ..._args: unknown[]): unknown {
    if (!isPositiveInteger(int)) {
      return int;
    }

    let ordinalNumber = int;

    if (this.locale === 'de') {
      ordinalNumber = this._deOrdinal(Number(int));
    }

    if (this.locale === 'en') {
      ordinalNumber = this._enOrdinal(Number(int));
    }

    return ordinalNumber;
  }

  // ---------------
  // Private Methods
  // ---------------

  private _deOrdinal(int: number): string {
    return `${int}.`;
  }

  private _enOrdinal(int: number): string {
    const ones = +int % 10,
      tens = (+int % 100) - ones;
    return int + ['th', 'st', 'nd', 'rd'][tens === 10 || ones > 3 ? 0 : ones];
  }
}
