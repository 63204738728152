import { Pipe, PipeTransform } from '@angular/core';

import { formatFormula } from './format-formula';

@Pipe({
  standalone: true,
  name: 'dentaku',
})
export class TcDentakuPipe implements PipeTransform {
  transform(value: string): string {
    return formatFormula(value);
  }
}
