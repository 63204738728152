<div class="container">
  <div class="row">
    <div class="col-sm-5">
      <div class="filter-bar">
        {{ 'transfer.selected.' + section | translate }}
      </div>
      <div class="wrapper">
        <nz-table
          nzVirtualScroll
          nzSize="small"
          nzVirtualItemSize="27"
          nzVirtualMinBufferPx="200"
          nzVirtualMaxBufferPx="400"
          [nzData]="resourcesRemove$ | async"
          [nzVirtualForTrackBy]="trackByIndex"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          [nzScroll]="{ x: '500px', y: '412px' }"
        >
          <thead>
            <tr>
              <th
                nzWidth="50px"
                nzShowCheckbox
                [(nzChecked)]="resourcesRemoveAll"
                [nzIndeterminate]="resourcesRemoveAllIndeterminate"
                (nzCheckedChange)="updateAllRemoveResources()"
              ></th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-template nz-virtual-scroll let-resource let-index="index">
              <tr>
                <td
                  nzWidth="50px"
                  nzShowCheckbox
                  [nzDisabled]="resource.disabled"
                  [(nzChecked)]="resourcesToRemove[resource.id]"
                  (nzCheckedChange)="updateSingleRemoveResource()"
                ></td>
                <td>{{ resource.name }}</td>
                <td>
                  <tc-hub-data-panel
                    [data]="resource$(resource.id) | async"
                    [section]="section"
                    class="resource-stats"
                  ></tc-hub-data-panel>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </nz-table>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="transfer-actions">
        <div class="transfer-buttons">
          <button
            type="button"
            nz-button
            nzType="secondary"
            (click)="removeSelected()"
          >
            {{ removeCount() }}
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </button>

          <button
            type="button"
            nz-button
            nzType="secondary"
            (click)="addSelected()"
          >
            <i nz-icon nzType="left" nzTheme="outline"></i>
            {{ addCount() }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="filter-bar">
        <tc-hub-dispo-filter [section]="section"></tc-hub-dispo-filter>
      </div>
      <div class="wrapper">
        <nz-table
          nzVirtualScroll
          nzSize="small"
          nzVirtualItemSize="27"
          nzVirtualMinBufferPx="200"
          nzVirtualMaxBufferPx="400"
          [nzData]="resourcesAdd$ | async"
          [nzVirtualForTrackBy]="trackByIndex"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          [nzScroll]="{ x: '500px', y: '392px' }"
        >
          <thead>
            <tr>
              <th
                nzWidth="50px"
                nzShowCheckbox
                [(nzChecked)]="resourcesAddAll"
                [nzIndeterminate]="resourcesAddAllIndeterminate"
                (nzCheckedChange)="updateAllAddResources()"
              ></th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-template nz-virtual-scroll let-resource let-index="index">
              <tr>
                <td
                  nzWidth="50px"
                  nzShowCheckbox
                  [nzDisabled]="
                    shouldDisableResources &&
                    resource.type === 'Employee' &&
                    !resource.email &&
                    !resource.user_id
                  "
                  nz-tooltip
                  [nzTooltipTrigger]="
                    shouldDisableResources &&
                    resource.type === 'Employee' &&
                    !resource.email &&
                    !resource.user_id
                      ? 'hover'
                      : null
                  "
                  [nzTooltipTitle]="
                    'errors.messages.person_is_not_reachable' | translate
                  "
                  [(nzChecked)]="resourcesToAdd[resource.id]"
                  (nzCheckedChange)="updateSingleAddResource()"
                ></td>
                <td>{{ resource.name }}</td>
                <td>
                  <tc-hub-data-panel
                    [data]="resource$(resource.id) | async"
                    [section]="section"
                    class="resource-stats"
                  ></tc-hub-data-panel>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
