import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

import { ResourceTemplateCollection } from '../../../../../../core/collections/resource_template';
import { ResourceTemplate } from '../../../../../../shared';
import { WorkEntry } from '../../../work_entry.model';

@Component({
  selector: 'tc-hub-batch-create-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class TcWorkEntryBatchStep1Component implements OnInit {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  formGroup: UntypedFormGroup;

  constructor(
    private resourceTemplateCollection: ResourceTemplateCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit() {
    this.resourceTemplateCollection
      .default<WorkEntry>('work_entry.batch')
      .pipe(first())
      .subscribe((resourceTemplate) => {
        this._buildForm(resourceTemplate);
        this.signal.emit({ action: 'resize' });
      });
  }

  // --------------
  // Public Methods
  // --------------

  next() {
    this.resourceTemplateCollection
      .setForResource('work_entry.batch')
      .get(this.formGroup.value.template_id)
      .subscribe((resourceTemplate: ResourceTemplate<WorkEntry>) => {
        const nextConfig = {
          ...this.formGroup.value,
          template: resourceTemplate.template,
          project: this.config.project,
          aggregation_id: this.config.aggregation_id,
        };
        this.signal.emit({ action: 'next', config: nextConfig });
      });
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm(resourceTemplate: ResourceTemplate<WorkEntry>) {
    this.formGroup = this.formBuilder.group({
      title: [this.config.title, [Validators.required]],
      template_id: [this.config.template_id ?? resourceTemplate?.id],
    });
  }
}
