import { Pipe, PipeTransform } from '@angular/core';

import { hasZeroDuration, isDurationType } from '@timecount/utils';

@Pipe({
  name: 'highlightDurationLabel',
})
export class HighlightDurationLabelPipe implements PipeTransform {
  transform(duration: unknown, color: 'danger' = 'danger'): string {
    if (!isDurationType(duration) || !hasZeroDuration(duration)) {
      return '';
    }
    return `label label-${color}`;
  }
}
