<div class="tc-modal-body" #modalBody>
  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="!single"
  >
    <nz-tab nzTitle="{{ 'value_set.tab_type.general' | translate }}">
      <tc-hub-vs-general
        class="modal-container"
        [valueSet]="valueSet"
        (signal)="onSignal($event)"
      ></tc-hub-vs-general>
    </nz-tab>
  </nz-tabset>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
