import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RxState } from '@rx-angular/state';

import { TcIntervalWithBreak } from '@timecount/core';

import { TcFormGroupTimeSetComponentConfig } from './form-group-time-set.config';

export interface TcFormGroupTimeSetComponentStateModel {
  config: TcFormGroupTimeSetComponentConfig;
  formGroup?: UntypedFormGroup;
  duration: number;
  referenceDate?: Date;
}

@Injectable()
export class TcFormGroupTimeSetComponentState extends RxState<TcFormGroupTimeSetComponentStateModel> {
  readonly state$ = this.select();

  private _initState: TcFormGroupTimeSetComponentStateModel = {
    config: {
      isHorizontal: false,
      hideLabels: false,
      isCompact: false,
    },
    duration: 0,
  };

  constructor(private translateService: TranslateService) {
    super();

    this.set(this._initState);
  }

  // -------
  // Actions
  // -------

  setConfig(config: Partial<TcFormGroupTimeSetComponentConfig>): void {
    this.set({
      config: {
        ...this.get().config,
        ...config,
      },
    });

    if (this.get().config.initialValue) {
      this.setDuration(this.get().config.initialValue);
    }
  }

  setDuration(value: TcIntervalWithBreak): void {
    this.set({
      duration: TcIntervalWithBreak.getDuration(value),
    });
  }
}
