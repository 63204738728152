import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-integer-widget',
  templateUrl: './integer-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './integer-widget.component.scss',
  ],
})
export class IntegerWidgetComponent extends TcControlWidgetBaseDirective {}
