import { Injectable } from '@angular/core';
import { startOfWeek } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

const STORE: { [key: string]: BehaviorSubject<any> } = {};

@Injectable({
  providedIn: 'root',
})
export class LocalSettingsService {
  broadcastIndex = 0;

  constructor() {
    // on reload set a dummy flag to trigger check if local storage is full
    this.setItem('reload.' + new Date().getTime(), 'true');

    const dispoFocusDate = new Date();
    dispoFocusDate.setHours(12, 0, 0, 0);
    this.init('dispo.focusDate', startOfWeek(dispoFocusDate).getTime()); // move to module

    const dispoFocusLength = 6;
    this.init('dispo.focusLength', dispoFocusLength);

    this.init('dispo.taskFilter', {});
    this.init('dispo.taskFilterSift', { $and: [] });

    this.init('dispo.scheduleFilter', {});
    this.init('dispo.scheduleFilterSift', { $and: [] });

    this.init('dispo.employeeFilter', {});
    this.init('dispo.employeeFilterSift', { $and: [] });

    this.init('dispo.views', [
      ['dispo.tasks'],
      ['dispo.employees'],
      ['dispo.actions'],
    ]);

    // Broadcast functionality
    // allows to sync with other clients
    const broadCast = localStorage.getItem('broadcast');
    if (broadCast && broadCast.length < 500) {
      this.broadcastIndex = JSON.parse(broadCast).length;
    } else {
      this.setItem('broadcast', '[]');
    }

    // check if new broadcasts have been added to broadcast field
    setInterval(() => {
      let currBroadCasts: string[] = JSON.parse(
        localStorage.getItem('broadcast'),
      );

      if (!currBroadCasts) {
        this.setItem('broadcast', '[]');
        currBroadCasts = [];
      }

      if (currBroadCasts.length === 0) {
        this.broadcastIndex = 0;
      }

      if (currBroadCasts.length > this.broadcastIndex) {
        const broadCasts = currBroadCasts.slice(
          this.broadcastIndex,
          currBroadCasts.length,
        );

        broadCasts.forEach((key) => {
          const value = JSON.parse(localStorage.getItem(key));
          this.set(key, value);
        });

        this.broadcastIndex = currBroadCasts.length;
      }
    }, 500);
  }

  init(key: string, defaultValue: any = null): void {
    if (!STORE[key]) {
      const store = localStorage.getItem(key);
      if (store) {
        STORE[key] = new BehaviorSubject<any>(JSON.parse(store));
      } else {
        STORE[key] = new BehaviorSubject<any>(defaultValue);
      }

      STORE[key].subscribe((value) => {
        this.setItem(key, JSON.stringify(value));
      });
    }
  }

  get(key: string, defaultValue: any = null): BehaviorSubject<any> {
    this.init(key, defaultValue);

    return STORE[key];
  }

  set(key: string, value: any, withSync?: boolean): void {
    this.init(key, value);

    if (withSync) {
      const currBroadCasts: string[] = JSON.parse(
        localStorage.getItem('broadcast'),
      );

      currBroadCasts.push(key);
      this.setItem('broadcast', JSON.stringify(currBroadCasts));
      this.setItem(key, JSON.stringify(value));
    } else {
      STORE[key].next(value);
    }
  }

  recover(key) {
    return localStorage.getItem(key);
  }

  toggle(key: string, defaultValue: boolean = false): void {
    this.init(key, defaultValue);

    STORE[key].next(!STORE[key].value);
  }

  toggleWithValue(key: string, value: boolean): void {
    this.init(key, value);

    STORE[key].next(value);
  }

  private setItem(key: string, value: string): void {
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      this.clearOverloadedLocalStorage();
    }
  }

  private clearOverloadedLocalStorage() {
    console.warn('Local storage is full, clearing unnecessary data');

    Object.keys(localStorage).forEach((key) => {
      if (
        key.startsWith('dispo.taskExpanded') ||
        key.startsWith('dispo.planExpanded') ||
        key.startsWith('dispo.scheduleExpanded') ||
        key.startsWith('dispo.announcementExpanded') ||
        key.startsWith('reload.') ||
        key.startsWith('broadcast')
      ) {
        localStorage.removeItem(key);
      }
    });
  }
}
