import { Component, OnInit } from '@angular/core';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

import { ValueSet } from '../../core/collections/value_set';

@Component({
  selector: 'tc-hub-value-set',
  templateUrl: './value-set.component.html',
  styleUrls: ['./value-set.component.scss'],
})
export class ValueSetComponent extends BaseModalComponent implements OnInit {
  private valueSet: ValueSet;

  tabs = {
    general: 0,
  };

  constructor(protected config: ModalConfig, protected modalRef: ModalRef) {
    super(config, modalRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.valueSet = this.config.data.valueSet;
  }
}
