import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

export class Tag {
  id: string;
  title: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class TagCollection extends SetsCollection {
  identifier = 'preferences/tags';
  endpoint = '/api/preferences/tags';

  bindLabel = 'title';

  cache = 60 * 60;

  localSearch = ['title'];

  employee() {
    return this.query(
      { type: 'employee_tags', detail: true },
      (e) => e.type === 'employee_tags',
    );
  }

  drivingLicences() {
    return this.query(
      { type: 'driving_licenses', detail: true },
      (e) => e.type === 'driving_licenses',
    );
  }
}
