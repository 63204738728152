import { Injectable } from '@angular/core';
import { delay, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@timecount/ui';

import { DispoScheduleCollection, Schedule } from '../collections/schedule';
import { DispoScheduleComponent } from '../dispo-schedule/dispo-schedule.component';
import { DispoTaskCollection, SlotPos } from '../collections/task';
import { DispoAssignmentCollection } from '../collections/assignment';
import { integerToString } from '../../core/helpers';
import { DispoRangeDataStructureFactory } from '../datastructures/range.service';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { DispoCombinedTaskCollection } from '../collections/combined_task';
import { LogsComponent } from '../../shared/logs/logs.component';

import { AssignmentService } from './assignment.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  private translations: { [key: string]: string } = {};
  private templates: any[];

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private assignmentService: AssignmentService,
    private assignmentCollection: DispoAssignmentCollection,
    private taskCollection: DispoTaskCollection,
    private scheduleCollection: DispoScheduleCollection,
    private rangeDataServiceFactory: DispoRangeDataStructureFactory,
    private combinedTaskCollection: DispoCombinedTaskCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
  ) {
    this.translateService
      .get(['dispo/schedule.modal.title'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.resourceTemplateCollection
      .setForResource('dispo.schedules')
      .all()
      .subscribe((templates) => {
        this.templates = templates;
      });
  }

  general(schedule: Schedule) {
    this.section(schedule, 'general');
  }

  create(schedule: Schedule) {
    this.single(schedule, 'create');
  }

  move(schedule: Schedule) {
    this.section(schedule, 'move');
  }

  createDefault() {
    const defaultTemplate = this.templates.find((t) => t.default) || {
      template: {},
    };
    this.create(Object.assign(new Schedule(), defaultTemplate.template));
  }

  createFromPlanDefault(plan) {
    const defaultTemplate = this.templates.find((t) => t.default) || {
      template: {},
    };
    this.create(
      Object.assign(new Schedule(), defaultTemplate.template, {
        plan_id: plan.id,
      }),
    );
  }

  delete(schedule: Schedule) {
    this.single(schedule, 'delete');
  }

  section(schedule: Schedule, section: string, single = false) {
    const ref = this.modalService.open(DispoScheduleComponent, {
      data: {
        schedule: schedule,
        section: section,
        single: single,
      },
      modalTitle: this.translations['dispo/schedule.modal.title'],
    });
  }

  single(schedule: Schedule, section: string) {
    this.section(schedule, section, true);
  }

  setTasksTrackable(schedule: Schedule) {
    this.combinedTaskCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (tasks) => {
          tasks = tasks.filter((t) => t.schedule_id === schedule.id);

          tasks.forEach((task) => {
            if (task.state !== 'open' || !task.tracking_enabled) {
              task.state = 'open';
              task.tracking_enabled = true;
              task.shallow
                ? this.taskCollection.create(task)
                : this.taskCollection.update(task.id, task);
            }
          });
        },
      });
  }

  setTasksHidden(schedule: Schedule) {
    this.combinedTaskCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (tasks) => {
          tasks = tasks.filter((t) => t.schedule_id === schedule.id);

          tasks.forEach((task) => {
            if (task.state !== 'draft') {
              task.state = 'draft';
              task.shallow
                ? this.taskCollection.create(task)
                : this.taskCollection.update(task.id, task);
            }
          });
        },
      });
  }

  setTasksPublic(schedule: Schedule) {
    this.combinedTaskCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (tasks) => {
          tasks = tasks.filter((t) => t.schedule_id === schedule.id);

          tasks.forEach((task) => {
            if (task.state !== 'open') {
              task.state = 'open';
              task.shallow
                ? this.taskCollection.create(task)
                : this.taskCollection.update(task.id, task);
            }
          });
        },
      });
  }

  requestTasksConfirmation(schedule: Schedule) {
    this.combinedTaskCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (tasks) => {
          tasks = tasks.filter((t) => t.schedule_id === schedule.id);

          tasks.forEach((task) => {
            task.state = 'open';
            task.requested = true;
            task.shallow
              ? this.taskCollection.create(task)
              : this.taskCollection.update(task.id, task);
          });
        },
      });
  }

  enableSlots(schedule: Schedule, slots: SlotPos[]) {
    const slotsMatrix = Object.assign({}, schedule.slots_matrix);

    slots.map((pos) => {
      const lookup = integerToString(pos.x);
      const index = slotsMatrix[lookup].indexOf(pos.y);

      if (index === -1) {
        slotsMatrix[lookup].push(pos.y);
      }
    });

    schedule.slots_matrix = slotsMatrix;
    this.scheduleCollection.update(schedule.id, schedule);
  }

  disableSlots(schedule: Schedule, slots: SlotPos[], assignments = []) {
    if (assignments.length !== 0) {
      of(this.assignmentService.delete(assignments))
        .pipe(delay(500), first())
        .subscribe({
          next: () => {
            this.disableSlots(schedule, slots);
          },
        });
      return;
    }

    const slotsMatrix = Object.assign({}, schedule.slots_matrix);

    slots.map((pos) => {
      const lookup = integerToString(pos.x);
      const index = slotsMatrix[lookup].indexOf(pos.y);

      if (index !== -1) {
        slotsMatrix[lookup].splice(index, 1);
      }
    });

    schedule.slots_matrix = slotsMatrix;
    this.scheduleCollection.update(schedule.id, schedule);
  }

  viewLogs(resource_id: number) {
    this.modalService.open(LogsComponent, {
      data: {
        resource_id,
        resource_type: 'TcDispo::Schedule',
      },
      modalTitle: this.translations['trails.title'],
    });
  }
}
