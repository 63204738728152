import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';

import { ModalService } from '@timecount/ui';

import {
  Availability,
  DispoAvailabilityCollection,
} from '../collections/availability';
import { DispoLoaderService } from '../loader/loader.service';
import { DispoAvailabilityComponent } from '../dispo-availability/dispo-availability.component';
import { Action } from '../../core/types/action';
import { ActionType } from '../../core/types/action-type';
import { EntryType } from '../../core/types/entry-type';
import { ValidationService } from '../collections/validation.service';
import { LogsComponent } from '../../shared/logs/logs.component';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  private translations: { [key: string]: string } = {};

  constructor(
    private modalService: ModalService,
    private loadingService: DispoLoaderService,
    private availCollection: DispoAvailabilityCollection,
    private validationService: ValidationService,
    private translateService: TranslateService,
  ) {
    this.translateService
      .get([
        'dispo/availability.modal.title',
        'dispo/availability.confirms.delete',
        'dispo/availability.confirms.confirm',
        'dispo/availability.confirms.unconfirm',
        'dispo/availability.confirms.decline',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  range(avail) {
    this.section(avail, 'range');
  }

  interval(avail) {
    this.section(avail, 'interval');
  }

  create(avail) {
    this.edit(avail);
  }

  createFromDateAndTemplate(date, template) {
    const avail = this.availCollection.fromTemplate(date, template);

    this.edit(avail);
  }

  edit(avail) {
    if (avail.interval_type === 'interval') {
      this.section(avail, 'interval');
    } else {
      this.section(avail, 'range');
    }
  }

  delete(avails: Availability[]) {
    const actions = avails.map((avail) => {
      return <Action>{
        id: uuid(),
        entry: avail,
        entry_type: EntryType.Availability,
        type: ActionType.delete,
        errors: [],
        validations: (item, stack) =>
          this.availCollection.remoteValidations(
            item,
            stack,
            ActionType.delete,
          ),
      };
    });

    this.validationService.run(
      actions,
      true,
      this.translations['dispo/availability.confirms.delete'],
    );
  }

  confirm(avails: Availability[]) {
    const actions = avails.map((avail) => {
      avail = Object.assign({}, avail, { state: 'confirmed' });

      return <Action>{
        id: uuid(),
        entry: avail,
        entry_type: EntryType.Availability,
        type: ActionType.update,
        errors: [],
        validations: (item, stack) =>
          this.availCollection.remoteValidations(
            item,
            stack,
            ActionType.update,
          ),
      };
    });

    this.validationService.run(
      actions,
      true,
      this.translations['dispo/availability.confirms.confirm'],
    );
  }

  unconfirm(avails: Availability[]) {
    const actions = avails.map((avail) => {
      avail = Object.assign({}, avail, { state: 'unconfirmed' });

      return <Action>{
        id: uuid(),
        entry: avail,
        entry_type: EntryType.Availability,
        type: ActionType.update,
        errors: [],
        validations: (item, stack) =>
          this.availCollection.remoteValidations(
            item,
            stack,
            ActionType.update,
          ),
      };
    });

    this.validationService.run(
      actions,
      true,
      this.translations['dispo/availability.confirms.unconfirm'],
    );
  }

  decline(avails: Availability[]) {
    const actions = avails.map((avail) => {
      avail = Object.assign({}, avail, { state: 'declined' });

      return <Action>{
        id: uuid(),
        entry: avail,
        entry_type: EntryType.Availability,
        type: ActionType.update,
        errors: [],
        validations: (item, stack) =>
          this.availCollection.remoteValidations(
            item,
            stack,
            ActionType.update,
          ),
      };
    });

    this.validationService.run(
      actions,
      true,
      this.translations['dispo/availability.confirms.decline'],
    );
  }

  section(avail: any, section: string, single = false) {
    const ref = this.modalService.open(DispoAvailabilityComponent, {
      data: {
        availability: avail,
        section: section,
        single: single,
      },
      modalTitle: this.translations['dispo/availability.modal.title'],
    });
  }

  single(avail: any, section: string) {
    this.section(avail, section, true);
  }

  viewLogs(resource_id: number) {
    this.modalService.open(LogsComponent, {
      data: {
        resource_id,
        resource_type: 'TcDispo::Availability',
      },
      modalTitle: this.translations['trails.title'],
    });
  }
}
