import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import {
  TcFormGroupIntervalComponentConfig,
  TcFormGroupIntervalService,
} from '../form-group-interval';

import { TcFormGroupTimeSetComponentConfig } from './form-group-time-set.config';
import { TcFormGroupTimeSetValidators } from './form-group-time-set.validators';

@Injectable({
  providedIn: 'root',
})
export class TcFormGroupTimeSetService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private formGroupIntervalService: TcFormGroupIntervalService,
  ) {}

  /**
   * Get a FormGroup for a TcIntervalWithBreak.
   *
   * It will automatically update values when necessary
   *
   * @see {@link TcFormGroupTimeSetConfig}
   */
  getTimeSetFormGroup(
    options: TcFormGroupTimeSetComponentConfig = {},
  ): UntypedFormGroup {
    const { initialValue, disabled, noValidators, limit } = options;

    const sharedIntervalConfig: TcFormGroupIntervalComponentConfig = {
      fieldType: 'time',
      allowZeroDuration: true,
      disabled,
      noValidators,
    };

    const mainGroup = this.formGroupIntervalService.getIntervalFormGroup({
      initialValue: initialValue?.mainInterval,
      limit,
      ...sharedIntervalConfig,
    });

    const breakGroup = this.formGroupIntervalService.getIntervalFormGroup({
      initialValue: initialValue?.breakInterval,
      required: false,
      limit: initialValue?.mainInterval,
      ...sharedIntervalConfig,
    });

    const formGroup = this.formBuilder.group({
      mainInterval: mainGroup,
      breakInterval: breakGroup,
    });

    if (!noValidators) {
      formGroup.setValidators(TcFormGroupTimeSetValidators.timeSet);
    }

    if (options.disabled) {
      formGroup.disable();
    }

    return formGroup;
  }
}
