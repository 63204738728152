import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { BaseSelectComponent } from '../base-select.component';
import { CollectionService } from '../../../core/collection.service';
import { CollectionType } from '../../../shared/models/collection-type.model';

import { SelectCollectionItem } from './collection-select-item.model';

@Component({
  selector: 'tc-hub-collection-select',
  templateUrl: './collection-select.component.html',
  styleUrls: [
    '../base-select.component.scss',
    './collection-select.component.scss',
  ],
})
export class CollectionSelectComponent
  extends BaseSelectComponent
  implements OnInit
{
  @Input() collectionTypes: CollectionType[];

  @Output() selectionChange = new EventEmitter();

  items$: Observable<any[]>;
  typeahead$: Subject<any> = new Subject<any>();

  private groups: Record<string, string>;
  private selectedItem: [number, string?];

  constructor(
    private collectionService: CollectionService,
    private translateService: TranslateService,
  ) {
    super();
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit() {
    this._validateTypes();

    const { value } = this.formGroup.get(this.controlName);

    if (value) {
      this.selectedItem =
        this.collectionTypes.length > 1
          ? [value?.id, value?.type]
          : [Array.isArray(value) ? value[0] : value];
    } else {
      this.selectedItem = [undefined];
    }

    if (this.collectionTypes.length > 1) {
      this.translateService
        .get([
          ...this.collectionTypes.map(
            (type) => `${type.toLowerCase()}.singular`,
          ),
        ])
        .subscribe((translations) => {
          this.groups = translations;
        });
    }

    this._setItems();
  }

  // ---------------------------------------------------------------------------
  // Public Methods
  // ---------------------------------------------------------------------------

  // This function must be an arrow function, so that it can access the `this`
  // scope of the component instead of the one from the ng-select component.
  groupByHandler = ({ type }) => {
    return this.collectionTypes?.length > 1
      ? this.groups[`${type.toLowerCase()}.singular`]
      : null;
  };

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  private _validateTypes() {
    if (!this.collectionTypes?.length) {
      throw new Error(
        'The collectionTypes input is required for the collection select component.',
      );
    } else if (this.collectionTypes.length === 1 && !this.bindValue) {
      throw new Error(
        'The bindValue input is required when just 1 collection type is provided.',
      );
    } else if (
      this.collectionTypes.filter((item) => !CollectionType[item]).length > 0
    ) {
      throw new Error(
        'The collectionTypes input accepts only value of the `CollectionType` enum.',
      );
    } else if (
      this.collectionTypes.length > 1 &&
      this.collectionTypes.filter(
        (item) => !Object.values(CollectionType).includes(item),
      ).length > 0
    ) {
      throw new Error(
        `The collectionTypes input currently only supports the following values: \
          ${Object.values(CollectionType).join(', ')}.
        `,
      );
    }
  }

  private _setItems() {
    const selectedItemCollection =
      this.collectionTypes.length > 1
        ? this.selectedItem[1]
        : this.collectionTypes[0];

    this.items$ = combineLatest([
      this.selectedItem[0]
        ? from(
            this.collectionService[selectedItemCollection]?.get(
              this.selectedItem[0],
              {
                request: { silent: true },
              },
            ) ?? of(false),
          ).pipe(catchError(() => of(false)))
        : of(false),
      this.typeahead$.pipe(
        startWith(' '),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.loading = true)),
        switchMap((term) => {
          return combineLatest([
            ...this.collectionTypes.map((collectionType) =>
              from(
                this.collectionService[collectionType].search(term).pipe(
                  map((items: Record<string, unknown>[]) => {
                    return items.map((item) => {
                      return this.collectionTypes.length > 1
                        ? // TODO: Handle multiple non-Resource collection types.
                          SelectCollectionItem.fromCollectionItem(
                            item,
                            collectionType,
                          )
                        : item;
                    });
                  }),
                ),
              ),
            ),
          ]);
        }),
        catchError(() => of([])),
        map((items: unknown[]) => {
          return (
            items
              // the Contact collection returns an array of arrays,
              // so the flat depth was increased
              .flat(2)
              .filter((item: Record<string, unknown>) =>
                this.collectionTypes.length > 1
                  ? !(
                      this.selectedItem[0] === item.id &&
                      this.selectedItem[1] === item.type
                    )
                  : this.selectedItem[0] !== item.id,
              )
          );
        }),
      ),
    ]).pipe(
      tap(() => (this.loading = false)),
      map(([item, items]) => {
        if (item) {
          items.unshift(item);
        }

        return items;
      }),
    );
  }
}
