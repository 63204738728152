import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { DispoFilterService } from '../../shared/filter/filter.service';
import { ScheduleService } from '../actions/schedule.service';
import { DispoSelectorService } from '../selector/selector.service';
import { SlotPos } from '../collections/task';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { MessageService } from '../actions/message.service';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoScheduleCollection, Schedule } from '../collections/schedule';

import { MenuItem } from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuSchedule {
  private scheduleFilters: any[] = [];
  private scheduleMessageTemplates: any[] = [];
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private scheduleActions: ScheduleService,
    private messageActions: MessageService,
    private filterService: DispoFilterService,
    private selectorService: DispoSelectorService,
    private scheduleCollection: DispoScheduleCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/schedule.actions.edit',
        'dispo/schedule.actions.delete',
        'dispo/schedule.actions.move',
        'dispo/schedule.menu.filter',
        'dispo/schedule.menu.schedule',
        'dispo/schedule.actions.message',
        'dispo/task.menu.message',
        'dispo/schedule.actions.show_all_tasks',
        'dispo/schedule.actions.hide_all_tasks',
        'dispo/schedule.actions.track_all_tasks',
        'dispo/schedule.actions.request_all_tasks',
        'dispo/schedule.menu.public',
        'trails.actions.resource',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.filterService.getPresets('dispo.schedules').subscribe((presets) => {
      this.scheduleFilters = presets;
    });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.schedule')
      .all()
      .subscribe((templates) => {
        this.scheduleMessageTemplates = templates;
      });
  }

  itemsForSlots(currSchedule: any, slots: SlotPos[]): Observable<any[]> {
    return this.scheduleCollection.observeItem(currSchedule).pipe(
      map((schedule: Schedule) => {
        if (!schedule) {
          return [];
        }

        const scheduleActions = [
          {
            label: this.translations['dispo/schedule.actions.edit'],
            icon: 'edit',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.general',
            ),
            command: (event: any) => {
              this.scheduleActions.general(schedule);
            },
          },
          {
            label: this.translations['dispo/schedule.actions.delete'],
            icon: 'delete',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.delete',
            ),
            command: (event: any) => {
              this.scheduleActions.delete(schedule);
            },
          },
        ];

        const filterActions: any[] = this.scheduleFilters.map((preset) => {
          return {
            label: preset.label,
            icon: 'filter',
            command: (event: any) => {
              this.selectorService.setSchedule(schedule);
              this.filterService.setPreset(preset.id);
            },
          };
        });

        const items: MenuItem[] = [
          ...(filterActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/schedule.menu.filter'],
                },
                ...filterActions,
              ]
            : []),
          ...(scheduleActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/schedule.menu.schedule'],
                },
                ...scheduleActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }

  items(currSchedule: any): Observable<any[]> {
    return this.scheduleCollection.observeItem(currSchedule).pipe(
      map((schedule: Schedule) => {
        if (!schedule) {
          return [];
        }

        const scheduleActions = [
          {
            label: this.translations['dispo/schedule.actions.edit'],
            icon: 'edit',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.general',
            ),
            command: (event: any) => {
              this.scheduleActions.general(schedule);
            },
          },
          {
            label: this.translations['dispo/schedule.actions.move'],
            icon: 'calendar',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.move',
            ),
            command: (event: any) => {
              this.scheduleActions.move(schedule);
            },
          },
          {
            label: this.translations['dispo/schedule.actions.delete'],
            icon: 'delete',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.delete',
            ),
            command: (event: any) => {
              this.scheduleActions.delete(schedule);
            },
          },
          {
            label: this.translations['trails.actions.resource'],
            icon: 'audit',
            visible:
              this.currentUser.hasAccessToFeature('logs') &&
              this.currentUser.can('index', 'PaperTrail::Version', {
                item_type: 'TcDispo::Schedule',
              }),
            command: (_event: unknown) => {
              this.scheduleActions.viewLogs(schedule.id);
            },
          },
        ];

        const messageTemplates: any[] = this.scheduleMessageTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                const templ = Object.assign({}, template.template);
                this.messageActions.schedule(schedule, templ);
              },
            };
          });

        const publicActions = [
          {
            label: this.translations['dispo/schedule.actions.message'],
            icon: 'comment',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.message',
            ),
            command: (event) => {
              this.messageActions.scheduleDefault(schedule);
            },
          },
          {
            label: this.translations['dispo/task.menu.message'],
            icon: 'copy',
            visible:
              messageTemplates.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.schedule.message',
              ),
            items: messageTemplates,
          },
          {
            label: this.translations['dispo/schedule.actions.show_all_tasks'],
            icon: 'eye',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.show_all',
            ),
            command: (event: any) => {
              this.scheduleActions.setTasksPublic(schedule);
            },
          },
          {
            label: this.translations['dispo/schedule.actions.hide_all_tasks'],
            icon: 'eye-invisible',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.hide_all',
            ),
            command: (event: any) => {
              this.scheduleActions.setTasksHidden(schedule);
            },
          },
          {
            label: this.translations['dispo/schedule.actions.track_all_tasks'],
            icon: 'clock-circle',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.track_all',
            ),
            command: (event: any) => {
              this.scheduleActions.setTasksTrackable(schedule);
            },
          },
          {
            label:
              this.translations['dispo/schedule.actions.request_all_tasks'],
            icon: 'wifi',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.schedule.request_all',
            ),
            command: (event: any) => {
              this.scheduleActions.requestTasksConfirmation(schedule);
            },
          },
        ];

        const filterActions: any[] = this.scheduleFilters.map((preset) => {
          return {
            label: preset.label,
            icon: 'filter',
            command: (event: any) => {
              this.selectorService.setSchedule(schedule);
              this.filterService.setPreset(preset.id);
            },
          };
        });

        const items: MenuItem[] = [
          ...(filterActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/schedule.menu.filter'],
                },
                ...filterActions,
              ]
            : []),
          ...(scheduleActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/schedule.menu.schedule'],
                },
                ...scheduleActions,
              ]
            : []),
          ...(publicActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/schedule.menu.public'],
                },
                ...publicActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }
}
