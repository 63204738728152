import { AsyncSubject, combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  finalize,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';

import { ModalService } from '@timecount/ui';
import { dateToTzUnix, parseDate } from '@timecount/utils';

import { RemoteConfig } from '../../core/remote_config.service';
import { siftConfigUnchained, siftFilter } from '../../core/sift';
import { dig } from '../../core/helpers';
import { EntryType } from '../../core/types/entry-type';
import { ActionType } from '../../core/types/action-type';
import { Action } from '../../core/types/action';
import { Validation } from '../../core/types/validation';
import { WorkEntryCollection } from '../../projects/aggregations/work-entries/work_entry.collection';
import { EmployeeBiasCollection } from '../../employees/employee-biases.collection';
import {
  ProjectTimesheetCollection,
  TimesheetCollection,
} from '../../timesheets';
import { DispoLoaderService } from '../loader/loader.service';
import {
  DispoRangeDataStructure,
  DispoRangeDataStructureFactory,
} from '../datastructures/range.service';
import { DispoResourceDataStructure } from '../datastructures/resource.service';
import { DispoConflictResolverComponent } from '../dispo-conflict-resolver/dispo-conflict-resolver.component';
import { ServiceEntryCollection } from '../../projects/service-entry/service-entry.collection';
import { AggregationCollection } from '../../projects/aggregations/aggregation.collection';
import { JobRateCollection } from '../../core/collections/job-rate.collection';

import { DispoAvailabilityCollection } from './availability';
import { DispoAssignmentCollection } from './assignment';

// TODO: Move this to a helper library
const asyncSubjectCatchAndFinalize =
  (subject: AsyncSubject<unknown>) =>
  <T>(source: Observable<T>) => {
    return source.pipe(
      catchError((error: unknown) => {
        subject.error(false);

        return of(error);
      }),
      finalize(() => subject.complete()),
    );
  };

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private translations: { [key: string]: string } = {};
  private validationConfigs: any[];

  private rangeDataStructure: DispoRangeDataStructure;

  constructor(
    private remoteConfig: RemoteConfig,
    private translateService: TranslateService,
    private loadingService: DispoLoaderService,
    private modalService: ModalService,
    private availabilityCollection: DispoAvailabilityCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private timesheetCollection: TimesheetCollection,
    private workEntryCollection: WorkEntryCollection,
    private jobRateCollection: JobRateCollection,
    private serviceEntryCollection: ServiceEntryCollection,
    private aggregationCollection: AggregationCollection,
    private employeeBiasCollection: EmployeeBiasCollection,
    private projectTimesheetCollection: ProjectTimesheetCollection,
    private resourceDS: DispoResourceDataStructure,
    private rangeDataStructureFactory: DispoRangeDataStructureFactory,
  ) {
    this.remoteConfig
      .get('validation_query')
      .pipe(first())
      .subscribe({
        next: (validations) => {
          this.validationConfigs = validations;
        },
      });

    this.translateService.get(['modal.conflict']).subscribe((value) => {
      Object.assign(this.translations, value);
    });
  }

  run(
    actions: Action[],
    confirmationRequired = false,
    confirmationMessage?,
  ): AsyncSubject<boolean> {
    const result: AsyncSubject<boolean> = new AsyncSubject();

    this.buildActionHandler(actions)
      .pipe(
        switchMap((actions) => {
          const hasErrors = Boolean(
            actions.find((action) => action.errors?.length),
          );

          return hasErrors || confirmationRequired
            ? this.resolveConflicts(
                actions,
                confirmationRequired,
                confirmationMessage,
              )
            : this.commit(actions);
        }),
        asyncSubjectCatchAndFinalize(result),
      )
      .subscribe(() => {
        result.next(true);
        result.complete();
      });

    return result;
  }

  runIfPossible(actions: Action[]): AsyncSubject<boolean> {
    const result: AsyncSubject<boolean> = new AsyncSubject();

    this.buildActionHandler(actions)
      .pipe(
        tap((actions) => {
          if (actions.find((action) => action.errors?.length)) {
            throw new Error('Not Possible!');
          }
        }),
        switchMap((actions) => this.commit(actions)),
        asyncSubjectCatchAndFinalize(result),
      )
      .subscribe(() => {
        result.next(true);
        result.complete();
      });

    return result;
  }

  private buildActionHandler(actions: Action[]) {
    const actions$ = actions.map((undecoratedAction) =>
      this.decorate(undecoratedAction),
    );

    return combineLatest([...actions$]).pipe(
      switchMap((actions: Action[]) => {
        return combineLatest(
          actions.map((action) => {
            const entry = action.entry;
            const staticValidations$: Observable<Validation[]>[] =
              action.validations(
                entry,
                actions.map((a) => a.entry),
              );
            const dynamicValidations$ = this.dynamicValidations(
              action,
              actions.map((a) => a.entry),
            );
            const validations$ = [...staticValidations$, dynamicValidations$];

            if (validations$.length > 0) {
              return combineLatest(validations$).pipe(
                first(),
                map((validations: Validation[][]) => {
                  action.errors = [].concat(...validations);
                  return action;
                }),
              );
            } else {
              return of(action);
            }
          }),
        );
      }),
    );
  }

  private resolveConflicts(
    actions: Action[],
    confirmationRequired,
    confirmationMessage,
  ) {
    const response = new AsyncSubject();

    const ref = this.modalService.open(DispoConflictResolverComponent, {
      data: {
        actions: actions,
        confirmationRequired: confirmationRequired,
        confirmationMessage: confirmationMessage,
      },
      modalTitle: this.translations['modal.conflict'],
    });

    ref.tcClose
      .pipe(
        tap((resolvedActions: Action[]) => {
          if (!resolvedActions?.length) {
            throw new Error('No Action was resolved!');
          }
        }),
        concatMap((resolvedActions) => {
          return this.commit(resolvedActions).pipe(map(() => resolvedActions));
        }),
        tap((resolvedActions) => {
          if (!actions.every((action) => resolvedActions.includes(action))) {
            throw new Error('Not all Actions were resolved!');
          }
        }),
        asyncSubjectCatchAndFinalize(response),
      )
      .subscribe(() => {
        response.next(true);
        response.complete();
      });

    return response;
  }

  private commit(actions: Action[] = []): AsyncSubject<any> {
    const subjects: AsyncSubject<any>[] = [];
    const assignmentSubjects: AsyncSubject<any>[] = [];
    const assignments = [];
    const response = new AsyncSubject();

    actions.forEach((action) => {
      const item = action.entry;
      let subject;

      switch (action.type) {
        case ActionType.update:
          subject = this.collectionFor(action).update(
            action.entry.id,
            action.entry,
          );
          break;
        case ActionType.create:
          subject = this.collectionFor(action).create(action.entry);
          break;
        case ActionType.delete:
          subject = this.collectionFor(action).delete(
            action.entry.id,
            action.entry,
          );
          break;
        case ActionType.noop:
          subject = of(action.entry);
          break;
        case ActionType.confirm:
          subject = of(action.entry);
          break;
        default:
          console.error('no such action for ', action);
          break;
      }

      if (subject) {
        subjects.push(subject);
      }

      if (
        action.entry_type === EntryType.Assignment &&
        action.type !== ActionType.noop &&
        action.type !== ActionType.confirm
      ) {
        assignments.push(Object.assign({}, action.entry));
        assignmentSubjects.push(subject);
      }
    });

    this.loadingService.addAssignmentsToLoad(assignments, assignmentSubjects);

    combineLatest(subjects)
      .pipe(asyncSubjectCatchAndFinalize(response))
      .subscribe(() => response.next(true));

    return response;
  }

  private dynamicValidations(
    action: Action,
    stack = [],
  ): Observable<Validation[]> {
    const entry = action.entry;
    const resource_type = entry.resource_type;
    const resource_id = entry.resource_id;
    const validationKey = this.validationKeysFor(action, resource_type);

    stack = stack.filter(
      (i) => i.resource_type === resource_type && i.resource_id === resource_id,
    );
    const stackIds = stack.map((i) => i.id);

    const marker = entry.marker || [
      entry.starts_at ? dateToTzUnix(parseDate(entry.starts_at)) : 0,
      entry.ends_at ? dateToTzUnix(parseDate(entry.ends_at)) : 0,
    ];

    let validationDS$;
    if (resource_type === 'Employee') {
      validationDS$ = this.resourceDS.employeeValidation(resource_id, marker);
    } else if (resource_type === 'Contractor') {
      validationDS$ = this.resourceDS.contractorValidation(resource_id, marker);
    } else {
      validationDS$ = of({});
    }

    return validationDS$.pipe(
      first(),
      map((validationDS) => {
        // decorate entry with complete resource
        entry.resource = {
          ...entry.resource,
          ...(validationDS['resource'] ? validationDS['resource'][0] : {}),
        };

        validationDS = Object.assign(
          {
            resource: [],
          },
          validationDS,
        );

        // replace entrys with entrys on action stack
        validationDS[action.entry_type] = (
          validationDS[action.entry_type] || []
        ).filter((a) => stackIds.indexOf(a.id) === -1);
        validationDS[action.entry_type] = [
          ...(validationDS[action.entry_type] || []),
          ...stack.filter((s) => s.id !== entry.id),
        ];

        validationDS['targets'] = [entry];

        if (validationDS['resource'][0]) {
          validationDS['resource'][0] = Object.assign(
            {},
            validationDS['resource'][0],
          );
          validationDS['resource'][0][action.entry_type] =
            validationDS[action.entry_type];

          validationDS['resource'][0]['targets'] = entry;
        }

        return this.dynamicValidationsFor(entry, validationKey)
          .map((collConfig) => {
            return this.dynamicValidate(validationDS, collConfig, entry);
          })
          .flat();
      }),
    );
  }

  private decorate(action: Action): Observable<Action> {
    if (action.entry_type === EntryType.Assignment) {
      this.rangeDataStructure ??= this.rangeDataStructureFactory.all();

      return this.rangeDataStructure.assignment(action.entry, true).pipe(
        first(),
        map((entryDS) => {
          return Object.assign({}, action, { entry: entryDS });
        }),
      );
    } else if (action.entry_type === EntryType.Timesheet) {
      this.rangeDataStructure ??= this.rangeDataStructureFactory.all();

      return this.rangeDataStructure.timesheet(action.entry, true).pipe(
        first(),
        map((entryDS) => {
          return Object.assign({}, action, { entry: entryDS });
        }),
      );
    } else {
      return of(action);
    }
  }

  private dynamicValidate(validationDS, config, resource): Validation[] {
    // prevent validations for empty fields
    if (config.field) {
      const field = dig(resource, config.field);
      if (
        typeof field === 'undefined' ||
        field === null ||
        (Array.isArray(field) && field.length === 0)
      ) {
        return [];
      }
    }

    const entries = validationDS[config.target] || [];
    const query = config.query;
    const res = entries.filter(siftFilter(query));

    if (config.invert && res.length > 0) {
      return [];
    } else if (config.invert) {
      return [
        Object.assign(new Validation(), {
          id: uuid(),
          type: config.error_type,
          message: config.message,
          conflicting_entry: validationDS['resource'],
          conflicting_entry_type: 'resource',
        }),
      ];
    } else {
      return res.map((entry) => {
        return Object.assign(new Validation(), {
          id: uuid(),
          type: config.error_type,
          message: config.message,
          conflicting_entry: entry,
          conflicting_entry_type: config.target,
        });
      });
    }
  }

  private dynamicValidationsFor(resource, type) {
    const validationConfigs = this.validationConfigs.filter(
      (c: any) => c.key === type,
    );
    const validations = validationConfigs.reduce((memo, config) => {
      return memo.concat(config.validations);
    }, []);

    return siftConfigUnchained(validations, resource);
  }

  private collectionFor(action: Action) {
    let collection;

    switch (action.entry_type) {
      case EntryType.Assignment:
        collection = this.assignmentCollection;
        break;
      case EntryType.Timesheet:
        collection = this.timesheetCollection;
        break;
      case EntryType.WorkEntry:
        collection = this.workEntryCollection.forProject(
          action.entry.project_id,
          action.entry.aggregation_id,
        );
        break;
      case EntryType.ServiceEntry:
        collection = this.serviceEntryCollection.forProject(
          action.entry.project_id,
          action.entry.aggregation_id,
        );
        break;
      case EntryType.Availability:
        collection = this.availabilityCollection;
        break;
      case EntryType.ProjectTimesheet:
        collection = this.projectTimesheetCollection.forProject(
          action.entry.project_id,
        );
        break;
      case EntryType.Aggregation:
        collection = this.aggregationCollection.forProject(
          action.entry.project_id,
        );
        break;
      case EntryType.Bias:
        collection = this.employeeBiasCollection.forEmployee(
          action.entry.resource_id,
        );
        break;
      case EntryType.JobRate:
        collection = this.jobRateCollection.forTypeAndId(
          action.entry.parent_type,
          action.entry.parent_id,
        );
    }

    return collection;
  }

  private validationKeysFor(action: Action, resource_type) {
    if (action.entry_type === EntryType.Assignment) {
      if (action.validation_scope) {
        return `dispo.assignments.${resource_type.toLowerCase()}.${
          action.validation_scope
        }`;
      } else if (
        action.type === ActionType.create ||
        action.type === ActionType.update ||
        action.type === ActionType.noop ||
        action.type === ActionType.confirm
      ) {
        return `dispo.assignments.${resource_type.toLowerCase()}`;
      } else {
        return `dispo.assignments.${resource_type.toLowerCase()}.delete`;
      }
    } else if (action.entry_type === EntryType.Timesheet) {
      if (action.validation_scope) {
        return `timesheets.${resource_type.toLowerCase()}.${
          action.validation_scope
        }`;
      } else if (
        action.type === ActionType.create ||
        action.type === ActionType.update ||
        action.type === ActionType.noop ||
        action.type === ActionType.confirm
      ) {
        return `timesheets.${resource_type.toLowerCase()}`;
      } else {
        return `timesheets.${resource_type.toLowerCase()}.delete`;
      }
    } else if (action.entry_type === EntryType.Availability) {
      if (action.validation_scope) {
        return `dispo.availabilities.${resource_type.toLowerCase()}.${
          action.validation_scope
        }`;
      } else if (
        action.type === ActionType.create ||
        action.type === ActionType.update ||
        action.type === ActionType.noop ||
        action.type === ActionType.confirm
      ) {
        return `dispo.availabilities.${resource_type.toLowerCase()}`;
      } else {
        return `dispo.availabilities.${resource_type.toLowerCase()}.delete`;
      }
    }
  }
}
