import { Injectable } from '@angular/core';

import { SetsCollection } from '../../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class ServiceEntryCollection extends SetsCollection {
  identifier = 'projects/aggregations/service_entry';
  endpoint =
    '/api/projects/:project_id/aggregations/:aggregation_id/service_entries';

  cache = 0;
  refreshOnStale = false;
  remoteDeleteValidation = true;

  forProject(project_id, aggregation_id: number | string = 'billing') {
    return this.query(
      {
        project_id: project_id,
        aggregation_id: aggregation_id,
      },
      (a) => {
        return Number.isInteger(parseInt(aggregation_id.toString(), 10))
          ? a.aggregation_id === aggregation_id
          : a.project_id === project_id && a.main;
      },
    );
  }
}
