import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ArrayModule } from './array/array.module';
import { DateModule } from './date/date.module';
import { NumberModule } from './number/number.module';
import { ObjectModule } from './object/object.module';
import { MarkdownStringPipe } from './string';

@NgModule({
  declarations: [MarkdownStringPipe],
  imports: [CommonModule, DateModule, ObjectModule, NumberModule, ArrayModule],
  exports: [
    DateModule,
    ObjectModule,
    NumberModule,
    ArrayModule,
    MarkdownStringPipe,
  ],
})
export class TcUtilsModule {}
