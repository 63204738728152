import { Component, Input, OnChanges } from '@angular/core';

import { TcFormFieldBaseDirective } from '../form-field.directive';

@Component({
  selector: 'tc-form-field-ionic',
  templateUrl: './form-field-ionic.component.html',
  styleUrls: ['./form-field-ionic.component.scss'],
})
export class TcFormFieldIonicComponent
  extends TcFormFieldBaseDirective
  implements OnChanges
{
  @Input() blurred = false;

  @Input() isInputType = false;

  @Input() hints;
}
