import { Component, Input } from '@angular/core';

import { TcCompactDirective } from '../../../shared';

@Component({
  selector: 'tc-button-ionic',
  templateUrl: './button-ionic.component.html',
  styleUrls: ['./button-ionic.component.scss'],
})
export class TcButtonIonicComponent extends TcCompactDirective {
  @Input() disabled = false;
  @Input() size: 'small' | 'default' | 'large' = 'default';
  @Input() fill: 'clear' | 'outline' | 'solid' = 'solid';
  @Input() expand: 'block' | 'full' | null = null;
  @Input() color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'dark'
    | 'medium'
    | 'light' = 'primary';

  get buttonStyle(): Record<string, string> {
    return {
      [`--background`]: `var(--ion-color-${
        this.fill === 'solid' ? this.color : 'transparent'
      })`,
      [`--border-color`]: `var(--ion-color-${this.color})`,
      [`--color`]: `var(--ion-color-${this.color}${
        this.fill === 'solid' ? '-contrast' : ''
      })`,
    };
  }
}
