<div class="view-body">
  <tc-hub-dt-day
    #dayComponent
    *ngFor="let date of dates | async; trackBy: trackByDate"
    [date]="date"
    [showEmpty]="showEmpty"
    (tcSelect)="onSelect($event)"
    tcHubDispoMenuActive
    activeType="DispoDate"
  ></tc-hub-dt-day>
</div>
