import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';

import { FrameCollection } from '../../core/frame-collection';
import { debug } from '../../core/helpers';
import { Cable } from '../../core/cable';
import { StoreService } from '../../core/store.service';
import { ApiErrorService } from '../../core/api-error.service';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoFocusService } from '../dispo-focus.service';
import { AssignmentDS } from '../datastructures/assignment-ds.model';
import { LocalSettingsService } from '../../core/local-settings.service';

import {
  DispoAssignmentDayStatCollection,
  DispoAssignmentMonthStatCollection,
  DispoAssignmentPartialQuarterStatCollection,
  DispoAssignmentPartialYearStatCollection,
  DispoAssignmentQuarterStatCollection,
  DispoAssignmentWeekStatCollection,
  DispoAssignmentYearStatCollection,
} from './assignment_stats';
import { Assignment } from './assignment.model';
import { Schedule } from './schedule';
import { Slot, Task } from './task';

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentCollection extends FrameCollection {
  type = Assignment;
  identifier = 'tc_dispo/assignment';
  endpoint = '/api/dispo/assignments/range';
  cache = 0;

  remoteDeleteValidation = true;

  constructor(
    http: HttpClient,
    cable: Cable,
    dispoFocus: DispoFocusService,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    dayStatCollection: DispoAssignmentDayStatCollection,
    weekStatCollection: DispoAssignmentWeekStatCollection,
    monthStatCollection: DispoAssignmentMonthStatCollection,
    quarterStatCollection: DispoAssignmentQuarterStatCollection,
    partialQuarterStatCollection: DispoAssignmentPartialQuarterStatCollection,
    yearStatCollection: DispoAssignmentYearStatCollection,
    partialYearStatCollection: DispoAssignmentPartialYearStatCollection,
    localSettingsService: LocalSettingsService,
  ) {
    super(
      http,
      cable,
      dispoFocus,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
    );

    this.syncSignal$
      .pipe(debounceTime(20000 + Math.random() * 20000))
      .subscribe(() => {
        debug('resyncing stats...');

        monthStatCollection.refreshCollection();
        if (currentUser.hasAccessToFeature('day_stats')) {
          dayStatCollection.refreshCollection();
        }
        if (currentUser.hasAccessToFeature('week_stats')) {
          weekStatCollection.refreshCollection();
        }
        if (currentUser.hasAccessToFeature('quarter_stats')) {
          quarterStatCollection.refreshCollection();
        }
        if (currentUser.hasAccessToFeature('partial_quarter_stats')) {
          partialQuarterStatCollection.refreshCollection();
        }
        if (currentUser.hasAccessToFeature('year_stats')) {
          yearStatCollection.refreshCollection();
        }
        if (currentUser.hasAccessToFeature('partial_year_stats')) {
          partialYearStatCollection.refreshCollection();
        }
      });
  }

  forResource(resource) {
    const filterFunc = (x) =>
      x.resource_id === resource.id && x.resource_type === resource.type;
    const filterLookup = `resource_id: ${resource.id}|resource_type: ${resource.type}`;

    return this.filter(filterFunc, filterLookup);
  }

  forTask(task) {
    const filterFunc = (x) => x.task_id === task.id;
    const filterLookup = `task_id: ${task.id}`;

    return this.filter(filterFunc, filterLookup);
  }

  forTasks(tasks) {
    const taskIds = tasks.map((t) => t.id);
    const filterFunc = (x) => taskIds.includes(x.task_id);
    const filterLookup = `task_ids: ${taskIds.join(',')}`;

    return this.filter(filterFunc, filterLookup);
  }

  move(
    assignment: Assignment,
    position: number,
    task: Task,
    schedule?: Schedule,
  ): AssignmentDS {
    const { id: task_id, marker, schedule_id, date } = task;
    const { tracked_time = false } = assignment;

    const slot: Slot = {
      ...new Slot(),
      ...(((schedule ?? task).slots_config || []).find(
        (s) => s.position === position,
      ) ?? {}),
    };
    return {
      ...new AssignmentDS(),
      ...assignment,
      task,
      schedule,
      marker,
      schedule_id,
      task_id,
      position,
      date,
      tracked_time,
      slot,
      gratis: !!slot?.gratis,
    };
  }
}
