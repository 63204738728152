<div class="tc-modal-body" #modalBody>
  <div class="modal-container">
    <div class="modal-body">
      <div class="action" *ngFor="let action of actions; trackBy: trackById">
        <ng-container *ngIf="action.errors.length !== 0">
          <div class="action-wrapper">
            <div
              class="action-header"
              *ngIf="action.entry_type === 'assignments'"
            >
              <strong>{{ action.entry.resource.name }}</strong>
              {{ 'dispo/task.attrs.slot' | translate }}
              <strong>{{ action.entry.position + 1 }}</strong>
              {{ 'on' | translate }}
              <strong>{{
                action.entry.task.starts_at | date: 'dd.MM.yyyy'
              }}</strong>
            </div>

            <div
              class="action-header"
              *ngIf="action.entry_type === 'timesheets'"
            >
              <strong>{{ action.entry.resource?.name }}</strong>
              {{ 'on' | translate }}
              <strong>{{ action.entry.starts_at | date: 'dd.MM.yyyy' }}</strong>
            </div>

            <ng-container *ngFor="let error of action.errors">
              <div class="error-wrapper">
                <ng-container *ngIf="error.conflicting_entry_type === 'none'">
                  <div class="error" ngClass="error-{{ error.type }}">
                    <div class="message">{{ error.message }}</div>
                    <div class="controls" *ngIf="error.resolve">
                      <button
                        nz-button
                        nzType="secondary"
                        [nzLoading]="loading"
                        (click)="resolve(error)"
                      >
                        {{
                          error.resolve_message ||
                            ('dispo.conflicts.resolve' | translate)
                        }}
                      </button>
                    </div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="error.conflicting_entry_type === 'assignments'"
                >
                  <div
                    class="error assignment"
                    ngClass="error-{{ error.type }}"
                  >
                    <div class="message">{{ error.message }}</div>
                    <span class="time"
                      >{{
                        error.conflicting_entry.task.starts_at
                          | date: 'dd.MM.yyyy HH:mm'
                      }}
                      -
                      {{
                        error.conflicting_entry.task.ends_at
                          | date: 'dd.MM.yyyy HH:mm'
                      }}</span
                    >
                    <i class="separator" nz-icon nzType="arrow-right"></i>
                    <span class="title">{{
                      error.conflicting_entry.task.title
                    }}</span>
                    <div class="controls">
                      <button
                        nz-button
                        nzType="secondary"
                        [nzLoading]="loading"
                        (click)="deleteAssignment(error.conflicting_entry)"
                      >
                        {{ 'dispo.conflicts.delete_assignment' | translate }}
                      </button>
                    </div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="error.conflicting_entry_type === 'invitations'"
                >
                  <div
                    class="error invitation"
                    ngClass="error-{{ error.type }}"
                  >
                    <div class="message">{{ error.message }}</div>
                    <span class="title">{{
                      error.conflicting_entry.announcement.title
                    }}</span>
                    <div class="controls">
                      <button
                        nz-button
                        nzType="secondary"
                        [nzLoading]="loading"
                        (click)="cancelInvitation(error.conflicting_entry)"
                      >
                        {{ 'dispo.conflicts.cancel_invitation' | translate }}
                      </button>
                    </div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="error.conflicting_entry_type === 'availabilities'"
                >
                  <div
                    class="error availability"
                    ngClass="error-{{ error.type }}"
                  >
                    <div class="message">{{ error.message }}</div>
                    <span class="time"
                      >{{
                        error.conflicting_entry.starts_at
                          | date: 'dd.MM.yyyy HH:mm'
                      }}
                      -
                      {{
                        error.conflicting_entry.ends_at
                          | date: 'dd.MM.yyyy HH:mm'
                      }}</span
                    >
                    <i class="separator" nz-icon nzType="arrow-right"></i>
                    <span class="title">{{
                      error.conflicting_entry.title ||
                        error.conflicting_entry.type.title
                    }}</span>
                    <div class="controls">
                      <button
                        nz-button
                        nzType="secondary"
                        [nzLoading]="loading"
                        (click)="deleteAvailability(error.conflicting_entry)"
                      >
                        {{ 'dispo.conflicts.delete_availability' | translate }}
                      </button>
                    </div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="error.conflicting_entry_type === 'timebalances'"
                >
                  <div
                    class="error timebalance"
                    ngClass="error-{{ error.type }}"
                  >
                    <div class="message">{{ error.message }}</div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="error.conflicting_entry_type === 'subtotals'"
                >
                  <div class="error subtotals" ngClass="error-{{ error.type }}">
                    <div class="message">{{ error.message }}</div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="error.conflicting_entry_type === 'biases'"
                >
                  <div class="error biases" ngClass="error-{{ error.type }}">
                    <div class="message">{{ error.message }}</div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="error.conflicting_entry_type === 'resource'"
                >
                  <div class="error resource" ngClass="error-{{ error.type }}">
                    <div class="message">{{ error.message }}</div>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="error.conflicting_entry_type === 'targets'"
                >
                  <div class="error targets" ngClass="error-{{ error.type }}">
                    <div class="message">{{ error.message }}</div>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <div
              class="controls"
              *ngIf="
                action.type === actionType.update ||
                action.type === actionType.create
              "
            >
              <button nz-button nzType="secondary" (click)="exclude(action)">
                {{ 'save_not' | translate }}
              </button>
              <button
                nz-button
                nzType="secondary"
                (click)="ignoreErrors(action)"
                *ngIf="!criticalErrorsPresent([action])"
              >
                {{ 'save_still' | translate }}
              </button>
            </div>

            <div class="controls" *ngIf="action.type === actionType.delete">
              <button nz-button nzType="secondary" (click)="exclude(action)">
                {{ 'delete_not' | translate }}
              </button>
              <button
                nz-button
                nzType="secondary"
                (click)="ignoreErrors(action)"
                *ngIf="!criticalErrorsPresent([action])"
              >
                {{ 'delete_still' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="confirmationMessage && !errorsPresent()">
        <div class="action">
          <div class="error" ngClass="error-notice">
            <div class="message">{{ confirmationMessage }}</div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button nz-button nzType="secondary" (click)="abort()">
        {{ 'cancel' | translate }}
      </button>
      <button
        [disabled]="criticalErrorsPresent()"
        [nzLoading]="loading"
        *ngIf="commitableActionsPresent()"
        nz-button
        nzType="primary"
        (click)="commit()"
      >
        {{ 'commit' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
