import { Injectable } from '@angular/core';

import {
  TcConfigService,
  TcIntervalSet,
  TcIntervalWithBreak,
} from '@timecount/core';
import {
  getBreakIntervalsFromIntervalList,
  getMinutesFromIntervalList,
  getTotalMinutesFromIntervalList,
  getWholeInterval,
  hasZeroDuration,
  minutesToDuration,
} from '@timecount/utils';

@Injectable({ providedIn: 'root' })
export class TcTimesSummaryService {
  constructor(private configService: TcConfigService) {}

  /**
   * Used to connect the legacy with the interval-helper method
   * The legacy uses this method to display the danger label on 0 durations
   */
  hasZeroDuration(duration: Duration): boolean {
    return hasZeroDuration(duration);
  }

  getSummary(
    times: Partial<Interval>[] | TcIntervalWithBreak,
    options: { allowMultiBreaks?: boolean } = {},
  ) {
    const allowMulti =
      options.allowMultiBreaks ??
      this.configService.config.company.times.allowMultiBreaks;

    const workPeriodsList = allowMulti
      ? (times as Interval[])
      : (TcIntervalSet.timeSetToIntervalList(
          times as TcIntervalWithBreak,
        ) as Interval[]);
    const breakPeriodsList = getBreakIntervalsFromIntervalList(workPeriodsList);
    return {
      totalDuration: minutesToDuration(
        getTotalMinutesFromIntervalList(workPeriodsList),
      ),
      workDuration: minutesToDuration(
        getMinutesFromIntervalList(workPeriodsList),
      ),
      breakDuration: minutesToDuration(
        getMinutesFromIntervalList(breakPeriodsList),
      ),
      workInterval: getWholeInterval(workPeriodsList),
      workPeriods: workPeriodsList.length,
      breakPeriods: breakPeriodsList.length,
    };
  }
}
