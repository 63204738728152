import { Observable, Subject } from 'rxjs';

import { ModalConfig } from './modal-config';

export class ModalRef {
  private readonly _close = new Subject<any>();
  tcClose: Observable<any> = this._close.asObservable();

  private readonly _resize = new Subject<any>();
  tcResize: Observable<any> = this._resize.asObservable();

  private readonly _setConfig = new Subject<Partial<ModalConfig>>();
  tcSetConfig = this._setConfig.asObservable();

  close(result?: any) {
    this._close.next(result);
  }

  resize(result?: any) {
    this._resize.next(result);
  }

  setConfig(config: Partial<ModalConfig>) {
    this._setConfig.next(config);
    this._resize.next(true);
  }
}
