import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { FormGroupName } from '@angular/forms';
import { add } from 'date-fns';

import {
  isIntervalShorterThan24Hours,
  isValidDate,
  isValidInterval,
} from '@timecount/utils';

import { TcFormGroupIntervalBaseDirective } from '../form-group-interval-base.directive';
import { TcFormGroupIntervalService } from '../form-group-interval.service';
import { TcFormGroupIntervalComponentState } from '../form-group-interval.state';

@Component({
  selector: `tc-form-group-interval-ionic`,
  templateUrl: './form-group-interval-ionic.component.html',
  styleUrls: ['./form-group-interval-ionic.component.scss'],
  providers: [TcFormGroupIntervalComponentState],
})
export class TcFormGroupIntervalIonicComponent
  extends TcFormGroupIntervalBaseDirective
  implements OnInit
{
  @Input()
  title: string;

  @Input()
  allowClear = false;

  @Input()
  set defaultLength(defaultLength: Duration) {
    this.state.set({ defaultLength });
  }

  @Input()
  shouldConstrainInterval = true;

  @Input()
  alignRight = false;

  get pickerInitialValues(): Interval {
    const { formGroup, defaultLength, limit } = this.state.get();
    const { start } = formGroup.value;

    return {
      start: isValidDate(limit?.start) ? limit.start : null,
      end: !isValidDate(start)
        ? null
        : defaultLength
        ? add(start, defaultLength)
        : start,
    };
  }

  constructor(
    public state: TcFormGroupIntervalComponentState,
    public intervalService: TcFormGroupIntervalService,
    public cdr: ChangeDetectorRef,
    @Optional() public formGroupName?: FormGroupName,
  ) {
    super(state, intervalService, formGroupName);
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit(): void {
    super.ngOnInit();
  }

  reset() {
    this.state.get().formGroup.patchValue({ start: null, end: null });
  }

  isFieldLimitLongerThan24h(fieldName: 'start' | 'end'): boolean {
    const limit = this[`${fieldName}Limit`] as Interval;

    return isValidInterval(limit) && !isIntervalShorterThan24Hours(limit);
  }

  onDurationExtendedChange(isDurationExtended: boolean) {
    this.state.set({ isDurationExtended });
    this.cdr.detectChanges();
  }
}
