import { Component, Input } from '@angular/core';
import {
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from '@angular/forms';

import { TcIntervalWithBreakBaseDirective } from '../interval-with-break.directive';
import { TcFormGroupIntervalService } from '../../../form-groups';
import { TcFormValidationService } from '../../../form-validation.service';

@Component({
  selector: 'tc-interval-with-break-web',
  templateUrl: './interval-with-break-web.component.html',
  styleUrls: ['./interval-with-break-web.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TcIntervalWithBreakWebComponent,
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TcIntervalWithBreakWebComponent,
      multi: true,
    },
  ],
})
export class TcIntervalWithBreakWebComponent extends TcIntervalWithBreakBaseDirective {
  @Input() tcHideLabels = false;
  @Input() tcIsCompact = false;
  @Input() tcIsHorizontal = false;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected formGroupIntervalService: TcFormGroupIntervalService,
    protected formValidationService: TcFormValidationService,
  ) {
    super(formBuilder, formGroupIntervalService, formValidationService);
  }
}
