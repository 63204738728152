<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <tc-interval-web
            formControlName="range"
            [tcFieldType]="'dateTime'"
            [tcLabels]="{
              start: ('dispo/availability.attrs.starts_at' | translate),
              end: ('dispo/availability.attrs.ends_at' | translate)
            }"
            [tcConfig]="{
              required: true,
              maxItemDuration: { days: 365 },
              fieldType: 'dateTime'
            }"
            [tcAllowZeroDuration]="true"
          ></tc-interval-web>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-2 col-sm-1">
          <div class="form-group">
            <div class="checkbox lightswitch" style="margin-top: 32px">
              <label>
                <input
                  type="checkbox"
                  formControlName="custom_title"
                  [formGroup]="form"
                  (change)="toggleTitle()"
                />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-xs-10 col-sm-11">
          <div class="form-group">
            <tc-input-text-web
              label="{{ 'dispo/availability.attrs.title' | translate }}"
              formControlName="title"
            ></tc-input-text-web>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">
              {{ 'dispo/availability.attrs.note' | translate }}
              <textarea
                tcFormControlValidator
                class="form-control"
                rows="3"
                name="note"
                formControlName="note"
                autosize
              >
              </textarea>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <tc-hub-collection-select
              label="{{ 'dispo/availability.attrs.type' | translate }}"
              [collectionTypes]="['DispoAvailabilityType']"
              [formGroup]="form"
              controlName="type_id"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-collection-select>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group">
            <tc-hub-collection-select
              label="{{ 'dispo/availability.attrs.source' | translate }}"
              [collectionTypes]="['DispoAvailabilitySource']"
              [formGroup]="form"
              controlName="source_id"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-collection-select>
          </div>
        </div>
      </div>

      <tc-hub-custom-fields
        section="availability.range"
        [formGroup]="form"
        formControlName="store"
      >
      </tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
