import { Injectable } from '@angular/core';

import {
  TcConfigService,
  TcInterval,
  TcIntervalApi,
  TcIntervalList,
  TcIntervalSet,
  TcIntervalWithBreak,
  TcIntervalWithBreakApi,
  TcOffsetLengthWithBreakApi,
} from '@timecount/core';
import { dateToTzUnix } from '@timecount/utils';

@Injectable({ providedIn: 'root' })
export class TcFieldSetIntervalSetService {
  get timesConfig() {
    return this.configService.config.company.times;
  }

  constructor(private configService: TcConfigService) {}

  /**
   * Returns an `Interval` with the first `start` and the last `end` of the Set.
   */
  getWholeSetInterval(object: unknown): Interval {
    const { allowMultiBreaks } = this.timesConfig;

    return TcIntervalSet.getWholeSetInterval(object, { allowMultiBreaks });
  }

  /**
   * Parse the times values from an API object
   */
  parseFromApi(apiObject: object): TcIntervalWithBreak | Partial<Interval>[] {
    const { allowMultiBreaks } = this.timesConfig;

    return TcIntervalSet.parseFromApi(apiObject, { allowMultiBreaks });
  }

  /**
   * Formats the times values to an API object, considering the
   * `allowMultiBreaks` configuration.
   *
   * @param times
   * The times to be formatted. Can be a `TcIntervalWithBreak` or a list of
   * `TcInterval`.
   *
   * @param [options.includeMarker=false]
   * Set to `true` to include board markers array in the returned object.
   *
   * @param [options.ignoreWholeIntervalInList=false]
   * Set to `true` to ignore the whole interval when formatting the object. This
   * property is required as some parts of the API need the whole interval along
   * with the list of intervals, while other parts require that only one of them
   * exists (e.g. PUT v2/assignments/:id/time-tracking).
   */
  formatValueToApi(
    times: TcIntervalWithBreak | TcInterval[],
    options: {
      includeMarker?: boolean;
      ignoreWholeIntervalInList?: boolean;
    } = {},
  ):
    | {
        times: TcIntervalApi[];
        marker?: number[];
      }
    | (TcIntervalWithBreakApi & { marker?: number[] }) {
    const { allowMultiBreaks } = this.timesConfig;
    const { includeMarker, ignoreWholeIntervalInList } = options;

    const wholeInterval = this.getWholeSetInterval(times);

    const object = allowMultiBreaks
      ? {
          times: TcIntervalList.formatForApi(times as TcInterval[]),
          // Only include the whole interval if it should not be ignored
          ...(!ignoreWholeIntervalInList &&
            TcInterval.formatForApi(wholeInterval as TcInterval)),
        }
      : TcIntervalWithBreak.formatForApi(times as TcIntervalWithBreak);

    if (includeMarker) {
      object[`marker`] = [
        dateToTzUnix(wholeInterval.start as Date),
        dateToTzUnix(wholeInterval.end as Date),
      ];
    }
    return object;
  }

  parseFromOffsetLength(
    source: TcOffsetLengthWithBreakApi,
    baseDate?: Date,
  ): TcIntervalWithBreak {
    return TcIntervalWithBreak.fromOffSetLength(source, baseDate);
  }

  formatValueToOffsetLength(
    formObject: TcIntervalWithBreak,
  ): TcOffsetLengthWithBreakApi {
    return TcIntervalWithBreak.toOffsetLengthApi(formObject);
  }
}
