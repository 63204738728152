import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  getDaysInMonth,
  isValid,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

export const DateRanges = {
  Heute: [startOfDay(new Date()), endOfDay(new Date())],
  Morgen: [
    addDays(startOfDay(new Date()), 1),
    addDays(endOfDay(new Date()), 1),
  ],
  'Diese Woche': [
    startOfDay(startOfWeek(new Date(), { weekStartsOn: 1 })),
    endOfDay(endOfWeek(new Date(), { weekStartsOn: 1 })),
  ],
  'Nächste Woche': [
    addDays(startOfDay(startOfWeek(new Date(), { weekStartsOn: 1 })), 7),
    addDays(endOfDay(endOfWeek(new Date(), { weekStartsOn: 1 })), 7),
  ],
  'Dieser Monat': [
    startOfDay(startOfMonth(new Date())),
    startOfDay(endOfMonth(new Date())),
  ],
  'Nächster Monat': [
    addMonths(startOfDay(startOfMonth(new Date())), 1),
    addMonths(endOfDay(endOfMonth(new Date())), 1),
  ],
};

export const generateDateRanges = (date, base = 'day', offset = 0, length) => {
  const timestamp = date.getTime();
  date = new Date(timestamp);

  if (base === 'year') {
    date = startOfYear(date);
    length = length || endOfYear(date).getTime() / 1000 - date.getTime() / 1000;
  } else if (base === 'month') {
    date = startOfMonth(date);
    length = length || getDaysInMonth(date) * 24 * 60 * 60 - 1;
  } else if (base === 'week') {
    date = startOfWeek(date, { weekStartsOn: 1 });
    length = length || 7 * 24 * 60 * 60 - 1;
  } else if (base === 'hour') {
    date = startOfHour(date);
    length = length || 60 * 60;
  } else {
    date = startOfDay(date);
    length = length || 24 * 60 * 60;
  }

  const dateTZOffset = date.getTimezoneOffset();

  let startsAt = new Date(date.getTime() + offset * 1000);
  let startsAtTZOffset = startsAt.getTimezoneOffset();

  if (dateTZOffset !== startsAtTZOffset) {
    startsAt = new Date(
      startsAt.getTime() - (dateTZOffset - startsAtTZOffset) * 60 * 1000,
    );
    startsAtTZOffset = startsAt.getTimezoneOffset();
  }

  let endsAt = new Date(startsAt.getTime() + length * 1000);
  const endsAtTZOffset = endsAt.getTimezoneOffset();

  if (startsAtTZOffset !== endsAtTZOffset) {
    endsAt = new Date(
      endsAt.getTime() - (startsAtTZOffset - endsAtTZOffset) * 60 * 1000,
    );
  }

  return [startsAt, endsAt];
};

export const generateRangesFromForm = (obj) => {
  const new_starts_at = new Date(obj.starts_at_date);
  new_starts_at.setHours(
    obj.starts_at_time.getHours(),
    obj.starts_at_time.getMinutes(),
    0,
    0,
  );

  const new_ends_at = new Date(obj.starts_at_date);
  new_ends_at.setHours(
    obj.ends_at_time.getHours(),
    obj.ends_at_time.getMinutes(),
    0,
    0,
  );

  if (new_ends_at.getTime() <= new_starts_at.getTime()) {
    new_ends_at.setDate(new_ends_at.getDate() + 1);
  }

  let new_intermission_starts_at;
  let new_intermission_ends_at;

  if (
    obj.intermission_starts_at_time instanceof Date &&
    isValid(obj.intermission_starts_at_time)
  ) {
    new_intermission_starts_at = new Date(obj.starts_at_date);
    new_intermission_starts_at.setHours(
      obj.intermission_starts_at_time.getHours(),
      obj.intermission_starts_at_time.getMinutes(),
      0,
      0,
    );

    if (new_intermission_starts_at.getTime() < new_starts_at.getTime()) {
      new_intermission_starts_at.setDate(
        new_intermission_starts_at.getDate() + 1,
      );
    }

    if (
      obj.intermission_ends_at_time instanceof Date &&
      isValid(obj.intermission_ends_at_time)
    ) {
      new_intermission_ends_at = new Date(obj.starts_at_date);
      new_intermission_ends_at.setHours(
        obj.intermission_ends_at_time.getHours(),
        obj.intermission_ends_at_time.getMinutes(),
        0,
        0,
      );

      if (
        new_intermission_ends_at.getTime() <=
        new_intermission_starts_at.getTime()
      ) {
        new_intermission_ends_at.setDate(
          new_intermission_ends_at.getDate() + 1,
        );
      }
    }
  }

  if (
    new_intermission_starts_at &&
    new_intermission_ends_at &&
    (new_intermission_starts_at.getTime() > new_ends_at.getTime() ||
      new_intermission_ends_at.getTime() > new_ends_at.getTime())
  ) {
    new_intermission_starts_at = undefined;
    new_intermission_ends_at = undefined;
    // error intermission not in range
  }

  return {
    starts_at: new_starts_at,
    ends_at: new_ends_at,
    intermission_starts_at: new_intermission_starts_at,
    intermission_ends_at: new_intermission_ends_at,
  };
};
