import { Component, Input, OnInit } from '@angular/core';

import { ToggleDispoResourcesService } from './toggle-dispo-resourced.service';

@Component({
  selector: 'tc-hub-dispo-toggle-resources',
  templateUrl: './toggle-dispo-resources.component.html',
  styleUrls: ['./toggle-dispo-resources.component.scss'],
})
export class DispoToggleResourcesComponent implements OnInit {
  @Input() dispoSection;

  currentDispoViews = [];

  constructor(private toggleViewsService: ToggleDispoResourcesService) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit(): void {
    this.currentDispoViews = this.toggleViewsService.getCurrentDispoResources(
      this.dispoSection,
    );
  }

  // ---------------
  // Events Handlers
  // ---------------

  onShowClick(selectedDispoView) {
    this.toggleViewsService.expandView(selectedDispoView);
  }

  onHideClick(selectedDispoView) {
    this.toggleViewsService.condenseView(selectedDispoView);
  }
}
