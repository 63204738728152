import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DispoImport } from '../dispo-import.component';

@Component({
  selector: 'tc-hub-dim-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class DIMStep3Component implements OnInit {
  @Input() import: DispoImport;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.buildForm(this.import);

    this.signal.emit({ action: 'resize' });
  }

  prev() {
    this.signal.emit({ action: 'prev', import: this.import });
  }

  next() {
    const formValue = this.form.value;

    const dispoImport = Object.assign({}, this.import, {
      template: {
        note: this.import.template.note,
        description: formValue.description,
        tracking_enabled: formValue.tracking_enabled,
        push_notifications: formValue.push_notifications,
        state: formValue.state,
        store: formValue.store,
      },
    });

    this.signal.emit({ action: 'next', import: dispoImport });
  }

  private buildForm(dispoImport: DispoImport) {
    this.form = this.formBuilder.group({
      message_template_id: [],
      description: [this.import.template.description],
      tracking_enabled: [this.import.template.tracking_enabled],
      push_notifications: [this.import.template.push_notifications],
      state: [this.import.template.state || 'draft', [Validators.required]],
      store: [],
    });
  }
}
