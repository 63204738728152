import { Deserializer, ExclusionPolicy, Strategy, Type } from 'typeserializer';

import { parseDate } from '@timecount/utils';

import { BiasImpactColor } from './bias-impact-color';

@Strategy(ExclusionPolicy.NONE)
export class Bias {
  id: number;

  resource_id: number;
  resource_type: 'Employee';

  target_type: string;
  target_id: number;

  marker?: number[];

  @Deserializer((m: string): Date => parseDate(m))
  starts_at?: Date;

  @Deserializer((m: string): Date => parseDate(m))
  ends_at?: Date;

  impact: 'favourite' | 'positive' | 'negative' | 'blocked';
  impact_numeric?: number;
  color?: BiasImpactColor;

  note?: string;

  @Type(Object)
  store: Record<string, unknown>;
}
