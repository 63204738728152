<div class="wrapper">
  <div class="filter-bar">
    <tc-hub-dispo-filter section="dispo.tasks"></tc-hub-dispo-filter>
  </div>
  <div class="panel">
    <tc-hub-dt-paginate
      #paginateComponent
      [showEmpty]="showEmptyPlans$"
      (tcSelect)="onPaginateSelect($event)"
    ></tc-hub-dt-paginate>
    <!-- <tc-hub-dt-scroll #scrollComponent
      *ngIf="(viewSettings | async).paginate === 'scroll'"
      (tcSelect)="onScrollSelect($event)"></tc-hub-dt-scroll> -->
  </div>
</div>
