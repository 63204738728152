import {
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

@Directive()
export abstract class TcProjectFormsBaseDirective<
  T,
> extends BaseModalComponent {
  @Input() tcInitialValue: T;
  @Input() tcProjectId: number;
  @Input() tcVenueId: number;

  // TODO: Migrate the extended flags from legacy `ngStorage` to a proper state
  // management solution.
  // Jira issue: DEV-1882
  @Input() tcIsExtended = false;
  @Output() tcIsExtendedChange = new EventEmitter<boolean>();

  isModal = false;

  @HostBinding('class.use-columns')
  useColumns = true;

  public formGroup: UntypedFormGroup;
  public isSubmitting = false;

  destroyed$: Subject<void>;

  constructor(
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super(config, modalRef);
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit(): void {
    if (this.config?.data) {
      Object.assign(this, this.config.data);
    }
  }

  onExtendedChange(value: boolean): void {
    this.tcIsExtendedChange.emit(value);
  }
}
