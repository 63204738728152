import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { DispoScheduleCollection, Schedule } from '../../collections/schedule';
import { Plan } from '../../collections/plan';
import { Slot } from '../../collections/task';

@Component({
  selector: 'tc-hub-ds-slots',
  templateUrl: './slots.component.html',
  styleUrls: ['./slots.component.css'],
})
export class DSSlotsComponent implements OnInit {
  @Input() schedule: Schedule;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private scheduleService: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.buildForm(this.schedule);
  }

  set(field, value) {
    const formArrayControls = <UntypedFormArray>this.form.get('slots');
    formArrayControls.controls.forEach((control) => {
      control.get(field).setValue(value);
    });
  }

  save() {
    this.loading = true;

    const schedule = { ...this.schedule };

    schedule.slots_config = this.form.value.slots.map((newConfig) => {
      const oldStore =
        schedule.slots_config.find(
          (oldConfig) => oldConfig.position === newConfig.position,
        )?.store ?? {};

      return {
        ...newConfig,
        store: {
          ...oldStore,
          ...newConfig.store,
        },
      };
    });
    this.scheduleService.update(this.schedule.id, schedule).subscribe({
      next: (schedule: Schedule) => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
        // this.form = this.buildForm(schedule);
        // this.signal.emit({ action: 'reload', items: { schedule: schedule }});
      },
    });
  }

  get slots(): UntypedFormArray {
    return <UntypedFormArray>this.form.get('slots');
  }

  private buildForm(schedule: Schedule) {
    const allSlots = Array.from(
      new Array(schedule.template.size || 0),
      (_val, position) => {
        return {
          ...((schedule.slots_config ?? []).find(
            (s) => s.position === position,
          ) ?? {
            ...new Slot(),
            position,
          }),
        };
      },
    );

    const slotsFormGroup = allSlots.map((slot: Slot) =>
      this.formBuilder.group({
        position: [slot.position],
        gratis: [slot.gratis],
        job_id: [slot.job_id],
        group_id: [slot.group_id],
        role_ids: [slot.role_ids],
        qualification_ids: [slot.qualification_ids],
        store: [slot.store],
      }),
    );

    return this.formBuilder.group({
      slots: this.formBuilder.array(slotsFormGroup),
      gratis: [],
      job_id: [],
      group_id: [],
      role_ids: [],
      qualification_ids: [],
      store: [],
    });
  }
}
