import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { add, startOfDay, sub } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { setTimeToDate } from '@timecount/utils';

import { ScheduleService } from '../../actions/schedule.service';
import { TaskService } from '../../actions/task.service';
import { DispoPlanCollection, Plan } from '../../collections/plan';
import { ProjectCollection } from '../../../projects/project.collection';

@Component({
  selector: 'tc-hub-dp-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class DPCreateComponent implements OnInit, OnDestroy {
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;
  created = false;

  dateLimit: Interval;

  destroyed$ = new Subject<void>();

  constructor(
    private planService: DispoPlanCollection,
    private formBuilder: UntypedFormBuilder,
    private projectCollection: ProjectCollection,
    private taskActions: TaskService,
    private scheduleActions: ScheduleService,
  ) {}

  ngOnInit() {
    this.buildForm(this.plan);
    this.signal.emit({ action: 'resize' });
    const overlapDays = window.config.company.dispo_project_overlap_days;

    this.form
      .get('project_id')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (project_id) => {
          this.projectCollection.get(project_id).subscribe((project: any) => {
            this.dateLimit = {
              start: sub(project.starts_at, { days: overlapDays }),
              end: add(project.ends_at, { days: overlapDays }),
            };
            this.form.get('title').setValue(project.title);
            this.form.get('dateInterval').patchValue({
              start: startOfDay(project.starts_at),
              end: startOfDay(project.ends_at),
            });
          });
        },
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  cancel() {
    this.signal.emit({ action: 'close' });
  }

  createSchedule() {
    setTimeout(() => {
      this.scheduleActions.createFromPlanDefault(this.plan);
    }, 200);

    this.signal.emit({ action: 'close' });
  }

  createTask() {
    setTimeout(() => {
      this.taskActions.createFromPlanDefault(this.plan.starts_at, this.plan);
    }, 200);

    this.signal.emit({ action: 'close' });
  }

  save() {
    const { dateInterval, ...formValues } = this.form.value;

    const newValues = {
      ...this.plan,
      ...formValues,
      [`starts_at`]: dateInterval.start,
      [`ends_at`]: dateInterval.end,
    };

    newValues.store = Object.assign({}, this.plan.store, formValues.store);

    this.loading = true;

    this.planService.create(newValues).subscribe({
      next: (plan) => {
        this.loading = false;
        this.created = true;
        this.plan = <Plan>plan;
        this.signal.emit({ action: 'resize' });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(plan) {
    // HACK: While the date helpers and the forms group service can't handle
    // well date only (without time) comparisons, we are setting the dates here
    // to midnoght to make sure the validators will be compare them correctly.
    const todayAtMidnight = new Date().setHours(0, 0, 0, 0);

    const initialDateIntervalValue = {
      start: setTimeToDate(plan[`starts_at`], todayAtMidnight),
      end: setTimeToDate(plan[`ends_at`], todayAtMidnight),
    };

    this.form = this.formBuilder.group({
      title: [this.plan.title, [Validators.required]],
      project_id: [this.plan.project_id, [Validators.required]],
      dateInterval: [initialDateIntervalValue, Validators.required],
      color: [this.plan.color || '#000000'],
      note: [this.plan.note],
      store: [this.plan.store],
    });
  }
}
