<div
  tcHubDispoMenuActive
  activeType="DispoAssignment"
  class="wrapper"
  [activeId]="assignment.id"
  [tcAssignmentStateClasses]="
    assignment.responses
      | feedbackRequestsOnStatus : ['urgent'] : ['attend', 'onsite_checkin']
  "
  nz-popover
  [nzPopoverContent]="assignmentDetails"
  [nzPopoverPlacement]="popoverPlacement"
  nzPopoverOverlayClassName="tc-popover with-padding"
  [class.is-expanded]="expanded"
  (contextmenu)="onMenu($event, 'DispoAssignment', assignment)"
  (mouseup)="onMenu($event, 'DispoAssignment', assignment)"
>
  <label class="label">
    <ng-container *ngIf="expanded; then titleLabel; else timeLabel">
    </ng-container>

    <ng-template #titleLabel>
      {{
        'dispo/assignment.label.employees_expanded'
          | translate : { assignment: assignment }
      }}
    </ng-template>

    <ng-template #timeLabel>
      <ng-container *ngIf="assignment.task?.shift; else assignmentInterval">
        {{ assignment.task?.shift.title }}<br />
        {{
          'dispo/assignment.label.employees_condensed'
            | translate : { assignment: assignment }
        }}
      </ng-container>
      <ng-template #assignmentInterval>
        <span class="small">
          {{ assignment.task?.starts_at | date : 'H:mm' }} -
          {{ assignment.task?.ends_at | date : 'H:mm' }}<br />
          {{
            'dispo/assignment.label.employees_condensed'
              | translate : { assignment: assignment }
          }}
        </span>
      </ng-template>
    </ng-template>
  </label>
</div>

<ng-template #assignmentDetails>
  <span class="details-popover">
    {{ assignment.task?.title }} @ {{ assignment.plan?.title }}<br />
    <i nz-icon nzType="clock-circle"></i>
    <span class="times-type">{{ 'dispo/task.time' | translate }}</span>
    <tc-times-summary-web
      [intervalList]="timesList"
      [expanded]="true"
      [isCompact]="true"
    ></tc-times-summary-web>
    <ng-container *ngIf="assignment.tracked_time">
      <i
        nz-icon
        nzType="clock-circle"
        [ngClass]="{
          'times-confirmed': assignment.times_state === 'confirmed',
          'times-not-confirmed': assignment.times_state === 'unconfirmed'
        }"
        nzTheme="fill"
      ></i>
      <span class="times-type">{{
        'dispo/assignment.tooltip.tracked' | translate
      }}</span>
      <tc-times-summary-web
        [isTracked]="true"
        [intervalList]="trackedTimesList"
        [expanded]="true"
        [isCompact]="true"
      ></tc-times-summary-web>
    </ng-container>
    <ng-container
      *ngFor="
        let request of assignment.responses
          | feedbackRequestsOnStatus : ['responded', 'expired']
      "
    >
      <div>
        <i
          nz-icon
          [nzType]="
            request.type === 'onsite_checkin'
              ? 'environment'
              : request.type === 'read'
              ? 'wifi'
              : 'carry-out'
          "
          [tcAssignmentStateClasses]="[request]"
        ></i>
        <span class="times-type">{{
          'dispo/assignment.tooltip.' + request.type + '_' + request.status
            | translate
        }}</span>
      </div>
      <span *ngIf="request.response?.feedback">{{
        request.response?.responseAt | date : 'short'
      }}</span>
    </ng-container>
  </span>
</ng-template>
