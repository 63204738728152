<ng-select
  [(ngModel)]="innerValue"
  [items]="collection | async"
  [bindLabel]="bindLabel"
  [disabled]="isDisabled"
  bindValue="id"
  searchable="true"
  [inputAttrs]="{ class: 'form-control' }"
>
</ng-select>
