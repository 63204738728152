import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { TcFormGroupIntervalModule } from '../form-group-interval/form-group-interval.module';
import { TcFormFieldModule } from '../../components';

import { TcFormGroupTimeSetIonicComponent } from './form-group-time-set-ionic/form-group-time-set-ionic.component';

@NgModule({
  declarations: [TcFormGroupTimeSetIonicComponent],
  exports: [TcFormGroupTimeSetIonicComponent],
  imports: [SharedModule, TcFormGroupIntervalModule, TcFormFieldModule],
})
export class TcFormGroupTimeSetModule {}
