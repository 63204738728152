import { Component, ElementRef, NgZone, Renderer2 } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';

@Component({
  selector: 'tc-hub-context-menu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss'],
})
export class ContextMenuComponent extends ContextMenu {
  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public zone: NgZone,
  ) {
    super(el, renderer, zone);
  }
}
