<td>
  <label
    nz-checkbox
    [(nzChecked)]="item.checked"
    [nzIndeterminate]="item.indeterminate"
  ></label>

  {{ item.title }}

  <div class="data-panel">
    <tc-hub-data-panel
      [data]="item"
      section="finance.entries.service_entry.resource"
    ></tc-hub-data-panel>
  </div>
  <span
    *ngIf="billingStateInfo[item.billing_state]?.icon"
    nz-icon
    [class]="item.billing_state"
    nz-tooltip
    nzTooltipTitle="{{
      'aggregation_entry.billing_state.' + item.billing_state | translate
    }}"
    ><i nz-icon [nzType]="'tc:' + billingStateInfo[item.billing_state].icon"></i
  ></span>
</td>
<td>
  {{ item.sku?.title }}
</td>
<td>{{ item.count }}</td>
<td></td>
<td>
  <span [class]="item.price | highlightZeroLabel">
    {{
      item.price / 100
        | currency : ('angular.NUMBER_FORMATS.CURRENCY_SYM' | translate)
    }}
  </span>
</td>
<td [colSpan]="zoneGroupNumber"></td>
<td>
  <span [class]="item.total | highlightZeroLabel">
    {{
      (item.gratis ? 0 : item.total) / 100
        | currency : ('angular.NUMBER_FORMATS.CURRENCY_SYM' | translate)
    }}
  </span>
</td>
<td>
  <strong>{{ item.invoice_token }} </strong>
  <span
    *ngIf="item.aggregation?.attachment?.releases?.count > 0"
    nz-icon
    nz-tooltip
    nzTooltipTitle="{{ 'aggregation_entry.shared' | translate }} {{
      item.aggregation?.attachment?.release?.count
    }}"
  >
    <i nz-icon nzType="tc:share"></i
  ></span>
</td>
<td>
  {{ item.gratis ? ('aggregation_entry.attrs.gratis' | translate) : null }}
</td>
<td>
  {{ item.starts_at | date : 'd.MM.' }}
</td>
<td colspan="5"></td>
<td>
  <tc-hub-data-panel
    [data]="item"
    section="finance.entries.service_entry.status"
  ></tc-hub-data-panel>
</td>
