<div class="resource"></div>
<div class="day-wrapper">
  <div
    *ngFor="let day of dates | async"
    class="day"
    [class.is-first-of-month]="isFirstOfMonth(day)"
  >
    <tc-hub-dispo-shift-grid-header-day
      class="date"
      [day]="day"
      headerType="long"
    ></tc-hub-dispo-shift-grid-header-day>

    <div class="shift-wrapper">
      <div
        *ngFor="let shift of shiftHeaders$ | async"
        class="shift"
        [attr.from]="shift.x"
        [attr.to]="shift.y"
        [style.left]="shift.left"
        [style.right]="shift.right"
      >
        {{ shift.title }}
      </div>
    </div>
  </div>
</div>
