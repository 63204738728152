export const supportedLanguageCodes = ['en', 'de'] as const;
export const supportedLanguageIsoCodes = ['en-US', 'de-DE'] as const;
export const supportedLanguageRegions = ['US', 'DE'] as const;

export type SupportedLanguageCode = typeof supportedLanguageCodes[number];
export type SupportedLanguageIsoCode = typeof supportedLanguageIsoCodes[number];
export type SupportedLanguageRegion = typeof supportedLanguageRegions[number];

// Load the translation files
import enTranslations from '../assets/i18n/en.json';
import deTranslations from '../assets/i18n/de.json';

// This type is used to ensure that all translation files are aligned with the
// `en` one. This limits the risks of inconsistencies between translation files
// (but won't avoid all mistakes)
type TranslationsSchemaType = typeof import('../assets/i18n/en.json');

/**
 * Available translations, keyed by Language code.
 */
export const translationsMap: {
  [key in SupportedLanguageCode]: TranslationsSchemaType;
} = {
  en: enTranslations,
  de: deTranslations,
};
