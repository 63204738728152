import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';

import { PayGrade } from './pay-grade.model';

@Injectable({
  providedIn: 'root',
})
export class PayGradeCollection extends SetsCollection {
  identifier = 'tariffs/pay_grade';
  endpoint = '/api/tariffs/pay_grades';

  cache = 60 * 60;

  localSearch = ['title'];

  type = PayGrade;

  visible() {
    return this.query({ 'filter[visibility]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visibility]': '0' }, (e) => e.visibility === 0);
  }
}
