import { Injectable } from '@angular/core';

import { TcIssueCollector, TcIssueCollectorType } from '../../shared';

@Injectable({ providedIn: 'root' })
export class TcMenuItemHelpService {
  issueCollectors = window.config.issue_collectors;

  private _isInternal =
    window.config.user.support ||
    window.config.user.superadmin ||
    window.config.env !== 'production';

  private _sharedConfig = {
    baseUrl: 'https://timecount.atlassian.net',
    fieldValues: {
      fullname: window.config.user.name,
      email: window.config.user.email,
      environment: window.config.env,
    },
  };

  private _descriptionTemplates: { [key in TcIssueCollectorType]: string } = {
    bug: `h3. Steps to Reproduce
#

h3. Expected Behaviour _(if not obvious)_


h3. Dev Credentials _(if not reproducible in a test environment)_
- Email:
- Password:
`,
    feature: `h3. What‘s missing?

h3. Desired solution to be considered

h3. Possible alternative solutions
-

h3. Additional Context
`,
  };

  private _collectorBaseURl =
    'https://timecount.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/6i46lu/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=de-DE&collectorId=';

  constructor() {
    window.ATL_JQ_PAGE_PROPS ??= {};
  }

  showDialog({ id, type }: TcIssueCollector): void {
    if (window.ATL_JQ_PAGE_PROPS[id]) {
      window.ATL_JQ_PAGE_PROPS[id].show();
    } else {
      window.jQuery
        .ajax({
          url: this._collectorBaseURl + id,
          type: 'get',
          cache: true,
          dataType: 'script',
        })
        .success(() => {
          setTimeout(() => {
            window.ATL_JQ_PAGE_PROPS[id].show();
          }, 1000);
        });

      window.ATL_JQ_PAGE_PROPS[id] = {
        triggerFunction: (showCollectorDialog) => {
          window.ATL_JQ_PAGE_PROPS[id].show = showCollectorDialog;
        },
        fieldValues: {
          ...this._sharedConfig.fieldValues,
          ...(this._isInternal
            ? {
                ...(!!type && {
                  description: this._descriptionTemplates[type],
                }),
              }
            : {
                account: window.config.company.subdomain,
                backend_version: window.config.server.commit,
                frontend_version: window.config.frontend.commit,
              }),
        },
      };
    }
  }
}
