import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Plan } from '../../collections/plan';
import {
  DAYS_VALUES,
  DispoScheduleCollection,
  Schedule,
} from '../../collections/schedule';

@Component({
  selector: 'tc-hub-ds-days',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.scss'],
})
export class DSDaysComponent implements OnInit, OnDestroy {
  @Input() schedule: Schedule;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;
  allDaysIndeterminate = {};
  allPositionsIndeterminate = {};

  destroyed$ = new Subject<void>();

  constructor(
    private scheduleCollection: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.buildForm(this.schedule);

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (form) => {
        const daysConfig = form.days_config;
        const daysMatrix = {};

        DAYS_VALUES.forEach((day) => {
          daysMatrix[day] = [];
          [...Array(this.schedule.template.size).keys()].forEach((pos) => {
            daysMatrix[day].push(daysConfig[pos][day]);
          });
        });

        DAYS_VALUES.forEach((day) => {
          const matrix = daysMatrix[day];

          const allToggled = matrix.every((x) => x);
          const noneToggled = matrix.every((x) => !x);
          const indeterminate = !allToggled && !noneToggled;

          if (indeterminate) {
            this.allDaysIndeterminate[day] = true;
          } else {
            this.allDaysIndeterminate[day] = false;
            this.form
              .get(['days_headers', day])
              .setValue(allToggled ? true : false, { emitEvent: false });
          }
        });

        daysConfig.forEach((matrix, pos) => {
          matrix = DAYS_VALUES.map((day) => matrix[day]);

          const allToggled = matrix.every((x) => x);
          const noneToggled = matrix.every((x) => !x);
          const indeterminate = !allToggled && !noneToggled;

          if (indeterminate) {
            this.allPositionsIndeterminate[pos] = true;
          } else {
            this.allPositionsIndeterminate[pos] = false;
            this.form
              .get(['days_config', pos, 'ALL'])
              .setValue(allToggled ? true : false, { emitEvent: false });
          }
        });
      },
    });

    (this.form.get('days_config') as UntypedFormArray).controls.forEach(
      (formGroup) => {
        formGroup
          .get('ALL')
          .valueChanges.pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (val) => {
              DAYS_VALUES.forEach((key) => {
                formGroup.get(key).setValue(val);
              });
            },
          });
      },
    );

    DAYS_VALUES.forEach((key) => {
      this.form
        .get(['days_headers', key])
        .valueChanges.pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (val) => {
            const formArray = this.form.get([
              'days_config',
            ]) as UntypedFormArray;

            formArray.controls.forEach((formGroup) => {
              formGroup.get(key).setValue(val);
            });
          },
        });
    });

    this.form.get('init').setValue(true);
    this.signal.emit({ action: 'resize' });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  validate() {
    // validate intermission in range
  }

  get days_config(): UntypedFormArray {
    return <UntypedFormArray>this.form.get('days_config');
  }

  save(...sync) {
    this.loading = true;

    const form = this.form.value;
    const size = this.schedule.template.size;
    const daysMatrix = {};

    DAYS_VALUES.forEach((day) => {
      daysMatrix[day] = [];
      [...Array(size).keys()].forEach((pos) => {
        if (form.days_config[pos][day]) {
          daysMatrix[day].push(pos);
        }
      });
    });

    const payload = {
      ...this.schedule,
      days_matrix: daysMatrix,
      sync: sync,
    };

    this.scheduleCollection.update(this.schedule.id, payload).subscribe({
      next: (schedule) => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
        // this.form = this.buildForm(schedule);
        // this.signal.emit({ action: 'reload', items: { schedule: schedule }});
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(schedule) {
    const formGroups = [...Array(schedule.template.size).keys()].map((pos) => {
      const values = DAYS_VALUES.map((day) => {
        if (schedule.days_matrix && schedule.days_matrix[day]) {
          return schedule.days_matrix[day].includes(pos);
        } else {
          return true;
        }
      });

      return this.formBuilder.group({
        ALL: [false],
        '0': [values[0]],
        '1': [values[1]],
        '2': [values[2]],
        '3': [values[3]],
        '4': [values[4]],
        '5': [values[5]],
        '6': [values[6]],
        FA: [values[7]],
        FW: [values[8]],
      });
    });

    return this.formBuilder.group(
      {
        init: [false],
        days_config: this.formBuilder.array(formGroups),
        days_headers: this.formBuilder.group({
          '0': [false],
          '1': [false],
          '2': [false],
          '3': [false],
          '4': [false],
          '5': [false],
          '6': [false],
          FA: [false],
          FW: [false],
        }),
      },
      {
        validator: Validators.compose([this.validate.bind(this)]),
      },
    );
  }
}
