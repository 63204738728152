import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { ResourceTemplateCollection } from '../../../core/collections/resource_template';
import { DispoImport } from '../dispo-import.component';

@Component({
  selector: 'tc-hub-dim-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class DIMStep1Component implements OnInit, OnDestroy {
  @Input() import: DispoImport;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup;
  public aggregationUrl$ = new ReplaySubject(1);

  private destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private resourceTemplateCollection: ResourceTemplateCollection,
  ) {}

  ngOnInit(): void {
    if (this.import.project_id) {
      this.aggregationUrl$.next(
        `/api/projects/${this.import.project_id}/aggregations`,
      );
    }

    this.resourceTemplateCollection
      .setForResource('dispo.tasks')
      .all()
      .pipe(first())
      .subscribe((templates) => {
        const defaultTemplate = (
          templates.find((t) => t.default) || { template: {} }
        ).template;

        this.import.template = {
          note: defaultTemplate.note,
          description: defaultTemplate.description,
          tracking_enabled: defaultTemplate.tracking_enabled || false,
          push_notifications: defaultTemplate.push_notifications || false,
          state: defaultTemplate.state || 'draft',
          store: defaultTemplate.store || {},
        };
      });

    this.form = this.buildForm(this.import);

    this.form
      .get('project_id')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (project_id) => {
          this.form.get('aggregation_id').reset();

          if (project_id) {
            this.form.get('aggregation_id').enable();
            this.aggregationUrl$.next(
              `/api/projects/${project_id}/aggregations`,
            );
          } else {
            this.form.get('aggregation_id').disable();
            this.aggregationUrl$.next(undefined);
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  next() {
    const formValue = this.form.value;
    const dispoImport = Object.assign({}, this.import, formValue);

    this.signal.emit({ import: dispoImport, action: 'next' });
  }

  aggregationDecorator(agg) {
    agg = Object.assign({}, agg);
    agg.label = [agg.token, agg.title].filter((a) => a).join(' ');

    return agg;
  }

  private buildForm(dispoImport: DispoImport) {
    return this.formBuilder.group({
      project_id: [dispoImport.project_id, [Validators.required]],
      aggregation_id: [
        {
          value: dispoImport.aggregation_id,
          disabled: !dispoImport.project_id,
        },
        [Validators.required],
      ],
      target: [dispoImport.target, [Validators.required]],
    });
  }
}
