import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { DispoTaskCollection, Task } from '../../collections/task';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';
import { DispoAssignmentCollection } from '../../collections/assignment';

@Component({
  selector: 'tc-hub-dan-append-tasks',
  templateUrl: './append-tasks.component.html',
  styleUrls: ['./append-tasks.component.css'],
})
export class DANAppendTasksComponent implements OnInit {
  @Input() tasks: Task[];
  @Output() signal: EventEmitter<any> = new EventEmitter();

  loading = false;
  announcements$: Observable<Announcement[]>;

  constructor(
    private announcementCollection: DispoAnnouncementCollection,
    private taskCollection: DispoTaskCollection,
    private assignmentCollection: DispoAssignmentCollection,
  ) {}

  ngOnInit() {
    const plan_id = this.tasks[0].plan_id;
    const schedule_id = this.tasks[0].schedule_id;

    this.announcements$ = combineLatest(
      this.announcementCollection.all(),
      this.taskCollection.all(),
    ).pipe(
      map(([announcements, tasks]) => {
        announcements = announcements
          .filter((a) => a.plan_id === plan_id)
          .filter((a) => a.schedule_id === schedule_id)
          .filter((a) => a.state !== 'closed')
          .map((announcement) => {
            announcement = Object.assign({}, announcement);
            announcement.tasks = tasks
              .filter((t) => announcement.task_ids.indexOf(t.id) !== -1)
              .sort((t1, t2) => t1.marker[0] - t2.marker[0]);

            return announcement;
          })
          .filter((announcement) => {
            const announcement_tasks_dates = announcement.tasks.map(
              (t) => t.date,
            );
            const new_tasks_dates = this.tasks.map((t) => t.date);

            const date_overlaps = new_tasks_dates.filter(
              (d) => announcement_tasks_dates.indexOf(d) !== -1,
            );

            return date_overlaps.length === 0;
          });

        return announcements;
      }),
    );
  }

  appendTo(announcement: Announcement) {
    const newValues = {
      ...announcement,
      task_ids: [...announcement.task_ids],
      sizes: { ...announcement.sizes },
    };

    this.loading = true;

    this.assignmentCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (assignments) => {
          this.taskCollection
            .activateTasks(this.tasks)
            .pipe(first())
            .subscribe({
              next: (tasks) => {
                tasks.forEach((task) => {
                  let size;

                  if (!announcement.size) {
                    const taskAssignments = assignments.filter(
                      (a) =>
                        a.task_id === task.id &&
                        a.position < task.size &&
                        (announcement.task_slots.length === 0 ||
                          announcement.task_slots.indexOf(a.position) !== -1),
                    );

                    const taskSlots =
                      announcement.task_slots.length !== 0
                        ? task.slots.filter(
                            (s) => announcement.task_slots.indexOf(s) !== -1,
                          )
                        : task.slots;

                    size = taskSlots.length - taskAssignments.length;
                  } else {
                    size = newValues.size;
                  }

                  newValues.sizes[task.date] = size;
                  newValues.task_ids.push(task.id);
                });

                this.announcementCollection
                  .update(announcement.id, newValues)
                  .subscribe({
                    next: (announcement: Announcement) => {
                      this.loading = false;
                      this.signal.emit({ action: 'close' });
                    },
                    error: () => {
                      this.loading = false;
                    },
                  });
              },
            });
        },
      });
  }
}
