import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AsyncValidator,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  UntypedFormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { TcEmailHeaders } from '@timecount/core';

import { ValueAccessorDirective } from '../../shared/value-accessor.directive';
import { asyncStatusOfControl } from '../../shared/form-control-validator.helpers';
import { SharedModule } from '../../shared/shared.module';
import { TcComponentsModule } from '../../components';

@Component({
  selector: 'tc-hub-email-headers-fieldset',
  standalone: true,
  templateUrl: './email-headers-fieldset.component.html',
  styleUrls: ['./email-headers-fieldset.component.scss'],
  hostDirectives: [ValueAccessorDirective],
  imports: [SharedModule, TcComponentsModule],
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TcEmailHeadersFieldsetComponent,
      multi: true,
    },
  ],
})
export class TcEmailHeadersFieldsetComponent
  implements OnInit, AsyncValidator, OnDestroy
{
  formGroup: FormGroup;
  private destroyed$ = new Subject<void>();

  @Input() shouldShowPanel = true;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public valueAccessor: ValueAccessorDirective<TcEmailHeaders>,
  ) {}

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this.valueAccessor.value.subscribe((value: TcEmailHeaders) => {
      if (this.formGroup instanceof FormGroup) {
        if (Object.keys(value).length === 0) {
          this.formGroup.reset();
        } else {
          this.formGroup.patchValue(value);
        }
      } else {
        this._buildForm(value);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // Validator Implementation
  // ---------------------------------------------------------------------------

  validate(): Promise<ValidationErrors | null> {
    return asyncStatusOfControl(this.formGroup);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm(value: TcEmailHeaders) {
    const { sender, carbon_copy, blind_carbon_copy } = value ?? {};
    this.formGroup = this._formBuilder.group({
      sender: [sender, Validators.required],
      carbon_copy: [carbon_copy],
      blind_carbon_copy: [blind_carbon_copy],
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.valueAccessor.valueChange(value);
        this.valueAccessor.touchedChange(true);
      });
  }
}
