import {
  Directive,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ValidationErrors } from '@angular/forms';

import { TcFormValidationService } from '../../form-validation.service';

@Directive()
export class TcFormFieldBaseDirective {
  @Input() tcErrors: ValidationErrors[];
  errors: ValidationErrors;

  constructor(private formValidationService: TcFormValidationService) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnChanges({ tcErrors }: SimpleChanges) {
    if (tcErrors?.currentValue) {
      const errorsList = this.tcErrors.filter(
        (error) => error && !error.required,
      );

      this.errors = errorsList.length
        ? errorsList.reduce((acc, curr) => ({ ...acc, ...curr }), {})
        : undefined;
    }
  }

  // --------------
  // Public Methods
  // --------------

  getErrorMessage(errorKey: string, interpolateParams?): Observable<string> {
    return this.formValidationService.getValidatorErrorMessage(
      errorKey,
      interpolateParams,
    );
  }
}
