<form class="modal-container" [formGroup]="form" *ngIf="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">
              {{ 'dispo/assignment.attrs.note' | translate }}
              <textarea
                tcFormControlValidator
                class="form-control"
                rows="5"
                name="note"
                formControlName="note"
                autosize
              ></textarea>
            </label>
          </div>
        </div>
      </div>

      <tc-hub-custom-fields
        section="assignment.edit"
        [formGroup]="form"
        formControlName="assignmentEdit"
      >
      </tc-hub-custom-fields>

      <tc-hub-custom-fields
        *ngIf="assignment.resource_type === 'Contractor'"
        section="assignment.contractor.edit"
        [formGroup]="form"
        formControlName="assignmentResourceEdit"
      >
      </tc-hub-custom-fields>

      <tc-hub-custom-fields
        *ngIf="assignment.resource_type === 'Employee'"
        section="assignment.employee.edit"
        [formGroup]="form"
        formControlName="assignmentResourceEdit"
      >
      </tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
