import * as Sentry from '@sentry/angular';
import { Angular as AngularIntegration } from '@sentry/integrations';
import { Integrations as TracingIntegration } from '@sentry/tracing';
import { Integration } from '@sentry/types';

import { environment } from './environments';

const integrations: Integration[] = [
  new TracingIntegration.BrowserTracing(),
  new AngularIntegration(),
];

export const initSentry = () => {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: window.config.env ?? environment.name,
    release: `timecount-hub@${environment.buildInfo.version}-${environment.buildInfo.commit}`,
    integrations: integrations,
    tracesSampleRate: 0.2,
    ignoreErrors: ['QuotaExceededError', 'Non-Error exception captured'],
    beforeSend(event, hint) {
      // @es-ignore
      if (
        event.extra &&
        event.extra.__serialized__ &&
        event.extra.__serialized__['report']
      ) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          lang: window.config.lang,
          user: {
            email: window.config.user.email,
            name: window.config.user.name,
          },
        });
      }
      return event;
    },
  });

  Sentry.setUser({
    id: window.config.user.id,
    email: window.config.user.email,
    username: window.config.user.name,
  });

  Sentry.setTag('account', window.config.company.subdomain);
  Sentry.setTag('commit', environment.buildInfo.commit);
  Sentry.setTag('version', environment.buildInfo.version);
};
