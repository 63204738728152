import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { TcMapComponent } from './map.component';

@NgModule({
  declarations: [TcMapComponent],
  imports: [SharedModule],
  exports: [TcMapComponent],
  providers: [],
})
export class TcMapModule {}
