import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';

import { TcInputDateTimeConfig } from '../../components/input/input-datetime.config';

import {
  dateTimePickerType,
  TcDateTimePickerType,
  TcFormPickerType,
} from './form-picker.model';
import { TcColorPickerComponent } from './color-picker/color-picker.component';
import { TcDatePickerComponent } from './date-picker/date-picker.component';
import { TcTimePickerComponent } from './time-picker/time-picker.component';

type PickerComponent =
  | TcDatePickerComponent
  | TcTimePickerComponent
  | TcColorPickerComponent;

@Injectable({
  providedIn: 'root',
})
export class TcFormPickersService {
  private pickerComponents = {
    dateTime: TcDatePickerComponent,
    date: TcDatePickerComponent,
    time: TcTimePickerComponent,
    color: TcColorPickerComponent,
  };

  private overlayPositions: ConnectionPositionPair[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 1,
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 0,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: 0,
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: 0,
    },
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  open(
    type: TcFormPickerType,
    target: ViewContainerRef,
    options?: TcInputDateTimeConfig | { initialValue?: Date },
  ): PickerComponent {
    const factory: ComponentFactory<PickerComponent> =
      this.componentFactoryResolver.resolveComponentFactory(
        <Type<PickerComponent>>this.pickerComponents[type],
      );

    const { instance } = target.createComponent<PickerComponent>(factory);

    instance.value = options.initialValue;
    instance.origin = new CdkOverlayOrigin(target.element);
    instance.overlayPositions = this.overlayPositions;

    if (type === 'dateTime') {
      (instance as TcDatePickerComponent).showTime = true;
    }

    if (dateTimePickerType.includes(type as TcDateTimePickerType)) {
      (instance as TcDatePickerComponent).limit = (
        options as TcInputDateTimeConfig
      ).limit as Interval;
    }

    return instance;
  }
}
