// Dependencies
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SchemaFormModule, WidgetRegistry } from 'ngx-schema-form';

import { TcFormsModule, TcPanelComponent } from '@timecount/ui';
import { TcLocaleModule } from '@timecount/locale';

import { TcZorroAntdModule } from '../tc-zorro-antd.module';

import {
  CollectionFilterComponent,
  CollectionSelectComponent,
  MaskComponent,
  RemoteSelectComponent,
} from './controls';
import {
  ArrayWidgetComponent,
  BooleanWidgetComponent,
  CheckboxWidgetComponent,
  CollectionFilterWidgetComponent,
  CollectionSelectWidgetComponent,
  CurrencyWidgetComponent,
  DateRangeFilterWidgetComponent,
  DateRangeWidgetComponent,
  DatetimeWidgetComponent,
  EmailWidgetComponent,
  IntegerWidgetComponent,
  MaskWidgetComponent,
  NumberWidgetComponent,
  ObjectWidgetComponent,
  RemoteSelectWidgetComponent,
  SelectWidgetComponent,
  StringWidgetComponent,
  TcControlWidgetBaseDirective,
  TcWidgetRegistry,
} from './widgets';

@NgModule({
  imports: [
    CommonModule,
    TcLocaleModule,
    TcFormsModule,
    SchemaFormModule.forRoot(),
    TcZorroAntdModule,
    TcPanelComponent,
  ],
  declarations: [
    TcControlWidgetBaseDirective,
    CollectionFilterComponent,
    CollectionSelectComponent,
    RemoteSelectComponent,
    MaskComponent,
    EmailWidgetComponent,
    MaskWidgetComponent,
    ArrayWidgetComponent,
    CheckboxWidgetComponent,
    CollectionFilterWidgetComponent,
    CollectionSelectWidgetComponent,
    RemoteSelectWidgetComponent,
    CurrencyWidgetComponent,
    DateRangeFilterWidgetComponent,
    DateRangeWidgetComponent,
    IntegerWidgetComponent,
    StringWidgetComponent,
    ObjectWidgetComponent,
    SelectWidgetComponent,
    BooleanWidgetComponent,
    NumberWidgetComponent,
    DatetimeWidgetComponent,
  ],
  exports: [
    SchemaFormModule,
    CollectionFilterComponent,
    CollectionSelectComponent,
    RemoteSelectComponent,
    MaskComponent,
    MaskWidgetComponent,
    EmailWidgetComponent,
    ArrayWidgetComponent,
    CheckboxWidgetComponent,
    CurrencyWidgetComponent,
    CollectionFilterWidgetComponent,
    DatetimeWidgetComponent,
    CollectionSelectWidgetComponent,
    RemoteSelectWidgetComponent,
    DateRangeFilterWidgetComponent,
    DateRangeWidgetComponent,
    IntegerWidgetComponent,
    StringWidgetComponent,
    ObjectWidgetComponent,
    SelectWidgetComponent,
    BooleanWidgetComponent,
    NumberWidgetComponent,
    TcPanelComponent,
  ],
  providers: [],
})
export class TcSchemaFormModule {
  static forRoot(): ModuleWithProviders<TcSchemaFormModule> {
    return {
      ngModule: TcSchemaFormModule,
      providers: [
        {
          provide: WidgetRegistry,
          useClass: TcWidgetRegistry,
        },
      ],
    };
  }
}
