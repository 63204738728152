<div
  [class]="styleClass"
  [ngStyle]="style"
  [ngClass]="'ui-panelmenu ui-widget'"
>
  <ng-container *ngFor="let item of model; let f = first; let l = last">
    <div
      class="ui-panelmenu-panel"
      [ngClass]="{ 'ui-helper-hidden': item.visible === false }"
    >
      <div
        [ngClass]="{
          'ui-widget ui-panelmenu-header ui-state-default': true,
          'ui-corner-top': f,
          'ui-corner-bottom': l && !item.expanded,
          'ui-state-active': item.expanded,
          'ui-state-disabled': item.disabled
        }"
        [class]="item.styleClass"
        [ngStyle]="item.style"
      >
        <a
          *ngIf="!item.routerLink"
          [href]="item.url || '#'"
          (click)="handleClick($event, item)"
          [attr.tabindex]="item.tabindex ? item.tabindex : '0'"
          [attr.id]="item.id"
          [attr.target]="item.target"
          [attr.title]="item.title"
          class="ui-panelmenu-header-link"
          [attr.aria-expanded]="item.expanded"
          [attr.id]="item.id + '_header'"
          [attr.aria-controls]="item.id + '_content'"
        >
          <span *ngIf="item.items" class="ui-panelmenu-icon">
            <i nz-icon nzType="caret-right" *ngIf="!item.expanded"></i>
            <i nz-icon nzType="caret-down" *ngIf="item.expanded"></i>
          </span>
          <span class="ui-menuitem-icon" *ngIf="item.icon">
            <i nz-icon [nzType]="item.icon"></i>
          </span>
          <span class="ui-menuitem-text">{{ item.label }}</span>
        </a>
        <a
          *ngIf="item.routerLink"
          [routerLink]="item.routerLink"
          [queryParams]="item.queryParams"
          [routerLinkActive]="'ui-menuitem-link-active'"
          [routerLinkActiveOptions]="
            item.routerLinkActiveOptions || { exact: false }
          "
          (click)="handleClick($event, item)"
          [attr.target]="item.target"
          [attr.title]="item.title"
          class="ui-panelmenu-header-link"
          [attr.id]="item.id"
          [attr.tabindex]="item.tabindex ? item.tabindex : '0'"
          [fragment]="item.fragment"
          [queryParamsHandling]="item.queryParamsHandling"
          [preserveFragment]="item.preserveFragment"
          [skipLocationChange]="item.skipLocationChange"
          [replaceUrl]="item.replaceUrl"
          [state]="item.state"
        >
          <span *ngIf="item.items" class="ui-panelmenu-icon">
            <i nz-icon nzType="caret-right" *ngIf="!item.expanded"></i>
            <i nz-icon nzType="caret-down" *ngIf="item.expanded"></i>
          </span>
          <span class="ui-menuitem-icon" *ngIf="item.icon">
            <i nz-icon [nzType]="item.icon"></i>
          </span>
          <span class="ui-menuitem-text">{{ item.label }}</span>
        </a>
      </div>
      <div
        *ngIf="item.items"
        class="ui-panelmenu-content-wrapper"
        [@rootItem]="
          item.expanded
            ? {
                value: 'visible',
                params: {
                  transitionParams: animating ? transitionOptions : '0ms',
                  height: '*'
                }
              }
            : {
                value: 'hidden',
                params: { transitionParams: transitionOptions, height: '0' }
              }
        "
        (@rootItem.done)="onToggleDone()"
        [ngClass]="{
          'ui-panelmenu-content-wrapper-overflown': !item.expanded || animating
        }"
      >
        <div
          class="ui-panelmenu-content ui-widget-content"
          role="region"
          [attr.id]="item.id + '_content'"
          [attr.aria-labelledby]="item.id + '_header'"
        >
          <tc-hub-panel-menu-sub
            [item]="item"
            [expanded]="true"
            [transitionOptions]="transitionOptions"
            class="ui-panelmenu-root-submenu"
          ></tc-hub-panel-menu-sub>
        </div>
      </div>
    </div>
  </ng-container>
</div>
