import { v4 as uuid } from 'uuid';

import { CollectionType } from '../../../shared/models/collection-type.model';

export class SelectCollectionItem {
  id?: number;
  type: CollectionType;
  token: string;
  name: string;
  title: string;
  label: string;
  uuid?: string;

  static fromCollectionItem(item, type: CollectionType): SelectCollectionItem {
    const resource: SelectCollectionItem = {
      id: item.id,
      type,
      title: item.title,
      label: item.label,
      name:
        type === CollectionType.Employee
          ? item.first_name + ' ' + item.last_name
          : item.name,
      token: type === CollectionType.Employee ? item.token : '',
    };

    if (type === CollectionType.Contractor) {
      resource.uuid = uuid();
    }

    return resource;
  }
}
