<div class="tc-modal-content" #modalContent>
  <div class="tc-modal-body" #modalBody>
    <div class="modal-container">
      <ng-container *ngIf="projectLoaded; else loading">
        <nz-steps [nzCurrent]="currentStep" (nzIndexChange)="redraw()">
          <nz-step
            nzTitle="{{
              'aggregation_entry/work.batch.tab_type.create-s1' | translate
            }}"
          ></nz-step>
          <nz-step
            nzTitle="{{
              'aggregation_entry/work.batch.tab_type.create-s2' | translate
            }}"
          ></nz-step>
          <nz-step
            nzTitle="{{
              'aggregation_entry/work.batch.tab_type.create-s3' | translate
            }}"
          ></nz-step>
          <nz-step
            nzTitle="{{
              'aggregation_entry/work.batch.tab_type.create-s4' | translate
            }}"
          ></nz-step>
          <nz-step
            nzTitle="{{
              'aggregation_entry/work.batch.tab_type.create-s5' | translate
            }}"
          ></nz-step>
        </nz-steps>

        <div class="steps">
          <div class="modal-container" *ngIf="currentStep === 0">
            <tc-hub-batch-create-step1
              class="modal-container"
              [config]="config"
              (signal)="onNavigate($event)"
            ></tc-hub-batch-create-step1>
          </div>
          <div class="modal-container" *ngIf="currentStep === 1">
            <tc-hub-batch-create-step2
              class="modal-container"
              [config]="config"
              (signal)="onNavigate($event)"
            ></tc-hub-batch-create-step2>
          </div>
          <div class="modal-container" *ngIf="currentStep === 2">
            <tc-hub-batch-create-step3
              class="modal-container"
              [config]="config"
              (signal)="onNavigate($event)"
            ></tc-hub-batch-create-step3>
          </div>
          <div class="modal-container" *ngIf="currentStep === 3">
            <tc-hub-batch-create-step4
              class="modal-container"
              [config]="config"
              (signal)="onNavigate($event)"
            ></tc-hub-batch-create-step4>
          </div>
          <div class="modal-container" *ngIf="currentStep === 4">
            <tc-hub-batch-create-step5
              class="modal-container"
              [config]="config"
              (signal)="onNavigate($event)"
            ></tc-hub-batch-create-step5>
          </div>
        </div>
      </ng-container>
      <ng-template #loading>
        <span nz-icon nzType="loading" nzTheme="outline"></span>
      </ng-template>
    </div>
  </div>
  <div class="tc-modal-footer" #modalFooter></div>
</div>
