import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@timecount/ui';

import { DispoTaskCollection, Slot, SlotPos, Task } from '../collections/task';
import { DispoRole } from '../collections/role';
import { Qualification } from '../../core/collections/qualification';
import { DispoGroup } from '../collections/group';
import { DispoAssignmentCollection } from '../collections/assignment';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { CurrentUserService } from '../../core/current-user.service';
import { TimesheetService } from '../../timesheets/timesheet.service';
import { DispoTaskComponent } from '../dispo-task/dispo-task.component';
import { LogsComponent } from '../../shared/logs/logs.component';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private translations: { [key: string]: string } = {};
  private templates: any[];

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private taskCollection: DispoTaskCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private currentUser: CurrentUserService,
    private timesheetService: TimesheetService,
  ) {
    this.translateService.get(['dispo/task.modal.title']).subscribe((value) => {
      Object.assign(this.translations, value);
    });

    this.resourceTemplateCollection
      .setForResource('dispo.tasks')
      .all()
      .subscribe((templates) => {
        this.templates = templates;
      });
  }

  delete(task: Task) {
    this.single(task, 'delete');
  }

  general(task: Task) {
    this.taskCollection
      .activateTasks([task])
      .pipe(first())
      .subscribe({
        next: ([generatedTask]) => {
          this.section(generatedTask, 'general');
        },
      });
  }

  move(task: Task) {
    if (this.currentUser.hasAccessToSection('views.dispo.task.move')) {
      this.taskCollection
        .activateTasks([task])
        .pipe(first())
        .subscribe({
          next: ([generatedTask]) => {
            this.section(generatedTask, 'move');
          },
        });
    }
  }

  moveScheduled(tasks: Task[]) {
    if (
      this.currentUser.hasAccessToSection('views.dispo.task.move_scheduled')
    ) {
      this.taskCollection
        .activateTasks(tasks)
        .pipe(first())
        .subscribe({
          next: (generatedTasks) => {
            this.single(generatedTasks, 'moveScheduled');
          },
        });
    }
  }

  times(task: Task) {
    if (this.currentUser.hasAccessToSection('views.timesheet.multi')) {
      this.timesheetService.multiForTask(task);
    }
  }

  public(task: Task) {
    this.taskCollection
      .activateTasks([task])
      .pipe(first())
      .subscribe({
        next: ([generatedTask]) => {
          this.section(generatedTask, 'public');
        },
      });
  }

  slots(task: Task) {
    this.taskCollection
      .activateTasks([task])
      .pipe(first())
      .subscribe({
        next: ([generatedTask]) => {
          this.section(generatedTask, 'slots');
        },
      });
  }

  create(task: Task) {
    this.single(task, 'create');
  }

  invitationsForTask(task: Task, announcement) {
    this.section(task, `invitations${announcement.id}`);
  }

  activate(tasks: Task[]) {
    tasks.forEach((task) => {
      if (task.shallow) {
        this.taskCollection.create(task);
      }
    });
  }

  setHidden(tasks: Task[]) {
    if (this.currentUser.hasAccessToSection('views.dispo.task.hide')) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.state = 'draft';
          this.taskCollection.create(task);
        } else {
          if (task.state !== 'draft') {
            task.state = 'draft';
            this.taskCollection.update(task.id, task);
          }
        }
      });
    }
  }

  setPublic(tasks: Task[]) {
    if (this.currentUser.hasAccessToSection('views.dispo.task.show')) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.state = 'open';
          this.taskCollection.create(task);
        } else {
          if (task.state !== 'open') {
            task.state = 'open';
            this.taskCollection.update(task.id, task);
          }
        }
      });
    }
  }

  requestConfirmation(tasks: Task[]) {
    if (this.currentUser.hasAccessToSection('views.dispo.task.confirm')) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.state = 'open';
          task.requested = true;
          this.taskCollection.create(task);
        } else {
          task.state = 'open';
          task.requested = true;
          this.taskCollection.update(task.id, task);
        }
      });
    }
  }

  enableTimeTracking(tasks: Task[]) {
    if (this.currentUser.hasAccessToSection('views.dispo.task.track_time')) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.state = 'open';
          task.tracking_enabled = true;
          this.taskCollection.create(task);
        } else {
          task.state = 'open';
          task.tracking_enabled = true;
          this.taskCollection.update(task.id, task);
        }
      });
    }
  }

  disableTimeTracking(tasks: Task[]) {
    if (this.currentUser.hasAccessToSection('views.dispo.task.track_time')) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.tracking_enabled = false;
          this.taskCollection.create(task);
        } else {
          task.tracking_enabled = false;
          this.taskCollection.update(task.id, task);
        }
      });
    }
  }

  enableOnsiteCheckin(tasks: Task[]) {
    if (
      this.currentUser.hasAccessToSection('views.dispo.task.onsite_checkin')
    ) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.state = 'open';
          task.response_requirements.onsite_checkin = true;
          this.taskCollection.create(task);
        } else {
          task.state = 'open';
          task.response_requirements.onsite_checkin = true;
          this.taskCollection.update(task.id, task);
        }
      });
    }
  }

  disableOnsiteCheckin(tasks: Task[]) {
    if (
      this.currentUser.hasAccessToSection('views.dispo.task.onsite_checkin')
    ) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.response_requirements.onsite_checkin = false;
          this.taskCollection.create(task);
        } else {
          task.response_requirements.onsite_checkin = false;
          this.taskCollection.update(task.id, task);
        }
      });
    }
  }

  enableAttendance(tasks: Task[]) {
    if (this.currentUser.hasAccessToSection('views.dispo.task.attendance')) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.state = 'open';
          task.response_requirements.attend = true;
          this.taskCollection.create(task);
        } else {
          task.state = 'open';
          task.response_requirements.attend = true;
          this.taskCollection.update(task.id, task);
        }
      });
    }
  }

  disableAttendance(tasks: Task[]) {
    if (this.currentUser.hasAccessToSection('views.dispo.task.attendance')) {
      tasks.forEach((task) => {
        if (task.shallow) {
          task.response_requirements.attend = false;
          this.taskCollection.create(task);
        } else {
          task.response_requirements.attend = false;
          this.taskCollection.update(task.id, task);
        }
      });
    }
  }

  setGratisState(task: Task, slots: SlotPos[], value: boolean) {
    slots.forEach((pos) => {
      let config = task.slots_config.find((c) => c.position === pos.y);
      if (!config) {
        config = {
          ...new Slot(),
          position: pos.y,
        };
        task.slots_config.push(config);
      }
      config.gratis = value;
    });

    this.taskCollection.update(task.id, task);
  }

  toggleRole(task: Task, role: DispoRole, slots: SlotPos[]) {
    task = Object.assign({}, task);
    const slots_config = Object.assign([], task.slots_config);
    slots.forEach((pos) => {
      let config = slots_config.find((c) => c.position === pos.y);

      if (!config) {
        config = {
          position: pos.y,
          role_ids: [role.id],
          qualification_ids: [],
          store: {},
        };
        slots_config.push(config);
      } else {
        if (!config.role_ids) {
          config.role_ids = [];
        }
        const index = config.role_ids.indexOf(role.id);

        if (index === -1) {
          config.role_ids.push(role.id);
        } else {
          config.role_ids.splice(index, 1);
        }
      }
    });

    task.slots_config = slots_config;
    this.taskCollection.update(task.id, task);
  }

  toggleQualification(task: Task, quali: Qualification, slots: SlotPos[]) {
    task = Object.assign({}, task);
    const slots_config = Object.assign([], task.slots_config);
    slots.forEach((pos) => {
      let config = slots_config.find((c) => c.position === pos.y);

      if (!config) {
        config = {
          position: pos.y,
          role_ids: [],
          qualification_ids: [quali.id],
          store: {},
        };
        slots_config.push(config);
      } else {
        if (!config.qualification_ids) {
          config.qualification_ids = [];
        }
        const index = config.qualification_ids.indexOf(quali.id);

        if (index === -1) {
          config.qualification_ids.push(quali.id);
        } else {
          config.qualification_ids.splice(index, 1);
        }
      }
    });

    task.slots_config = slots_config;
    this.taskCollection.update(task.id, task);
  }

  toggleGroup(task: Task, group: DispoGroup, slots: SlotPos[]) {
    task = Object.assign({}, task);
    const slots_config = Object.assign([], task.slots_config);
    slots.forEach((pos) => {
      let config = slots_config.find((c) => c.position === pos.y);

      if (!config) {
        config = {
          position: pos.y,
          group_id: group.id,
          role_ids: [],
          qualification_ids: [],
          store: {},
        };
        slots_config.push(config);
      } else {
        if (config.group_id === group.id) {
          config.group_id = undefined;
        } else {
          config.group_id = group.id;
        }
      }
    });

    task.slots_config = slots_config;
    this.taskCollection.update(task.id, task);
  }

  createFromDateDefault(date) {
    const defaultTemplate = this.templates.find((t) => t.default) || {
      template: {},
    };
    const task = this.taskCollection.fromTemplate(
      date,
      defaultTemplate.template,
    );

    this.create(task);
  }

  createFromPlanDefault(date, plan) {
    const defaultTemplate = this.templates.find((t) => t.default) || {
      template: {},
    };
    const task = this.taskCollection.fromTemplate(
      date,
      defaultTemplate.template,
    );

    task.plan_id = plan.id;
    task.event_id = plan.id;

    this.create(task);
  }

  createFromPlan(date, plan, template) {
    const task = this.taskCollection.fromTemplate(date, template);

    task.plan_id = plan.id;
    task.event_id = plan.id;

    this.create(task);
  }

  section(task: Task | Task[], section: string, single = false) {
    const ref = this.modalService.open(DispoTaskComponent, {
      data: {
        task: task,
        section: section,
        single: single,
      },
      modalTitle: this.translations['dispo/task.modal.title'],
    });
  }

  single(task: Task | Task[], section: string) {
    this.section(task, section, true);
  }

  viewLogs(resource_id: number) {
    this.modalService.open(LogsComponent, {
      data: {
        resource_id,
        resource_type: 'TcDispo::Job',
      },
      modalTitle: this.translations['trails.title'],
    });
  }
}
