import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';

import { DispoFilterService } from './filter.service';
import { DispoFilterFormComponent } from './filter-form/filter-form.component';

@Component({
  selector: 'tc-hub-dispo-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class DispoFilterComponent implements OnInit {
  @ViewChild('form') form: DispoFilterFormComponent;

  @Input() section: string;

  public schema: any;
  public filter: any;
  public sort: any;
  public presets: any = [];
  public sorts: any = [];

  constructor(private filterService: DispoFilterService) {}

  ngOnInit(): void {
    this.filterService
      .getStandAlonePresets(this.section)
      .pipe(first())
      .subscribe((presets) => {
        this.presets = presets;
      });

    this.filterService
      .getSorts(this.section)
      .pipe(first())
      .subscribe((sorts) => {
        this.sorts = sorts;
      });

    this.filterService
      .getSchema(this.section)
      .pipe(first())
      .subscribe((s) => {
        if (s) {
          this.schema = s.data || {};
        }
      });

    this.filterService.getFilterRequest(this.section).subscribe({
      next: (filter) => {
        this.requestFilter(filter);
      },
    });
  }

  toggleForm() {
    this.form.toggle();
  }

  requestFilter(filter) {
    if (JSON.stringify(filter) !== JSON.stringify(this.filter)) {
      this.filter = Object.assign({}, filter);
      this.filterService.setQuery(this.section, this.filter);
    }
  }

  requestSort(sort) {
    this.sort = Object.assign({}, sort);
    this.filterService.setSort(this.section, this.sort);
  }

  requestPreset(preset: any) {
    this.filterService.setPreset(preset.id);
  }
}
