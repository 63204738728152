import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-string-widget',
  templateUrl: './string-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './string-widget.component.scss',
  ],
})
export class StringWidgetComponent extends TcControlWidgetBaseDirective {}
