import { Injectable } from '@angular/core';

import { DepartmentCollection } from '../shared/departments/department.collection';
import { CollectionType } from '../shared/models/collection-type.model';
import { DispoShiftCollection } from '../dispo/collections/shift';
import { JobCollection } from '../jobs/job.collection';
import { VenueCollection } from '../venues/venue.collection';
import { TariffCollection } from '../tariffs/tariff.collection';
import { EmployeeCollection } from '../employees/employee.collection';
import { ContractorCollection } from '../contractors/contractor.collection';
import { CustomerCollection } from '../customers/customer.collection';
import { DispoResourceCollection } from '../dispo/collections/resource';
import { DispoPlanCollection } from '../dispo/collections/plan';
import { DispoScheduleCollection } from '../dispo/collections/schedule';
import { DispoTaskCollection } from '../dispo/collections/task';
import { DispoAnnouncementCollection } from '../dispo/collections/announcement';
import { DispoAssignmentCollection } from '../dispo/collections/assignment';
import { DispoAvailabilityCollection } from '../dispo/collections/availability';
import { DispoInvitationCollection } from '../dispo/collections/invitation';
import { DispoRoleCollection } from '../dispo/collections/role';
import { DispoGroupCollection } from '../dispo/collections/group';
import { DispoAvailabilitySourceCollection } from '../dispo/collections/availability_source';
import { DispoAvailabilityTypeCollection } from '../dispo/collections/availability_type';
import { ProjectCollection } from '../projects/project.collection';
import { ProjectSetsCollection } from '../projects/project-sets.collection';
import { ContactCollection } from '../contacts/contact.collection';

import { VatCollection } from './collections/vat.collection';
import { MaterialCollection } from './collections/materials.collection';
import { ResourceTemplateCollection } from './collections/resource_template';
import { QualificationCollection } from './collections/qualification';
import { UserCollection } from './collections/user';
import { AccountRoleCollection } from './collections/account-roles-collection';
import { SetCollection } from './sets-collection';
import { MessageTemplateCollection } from './collections/message_template';
import { AttachmentCollection } from './collections/attachment';
import { AttachmentReleaseCollection } from './collections/attachment_release';
import { TagCollection } from './collections/tag';
import { EmploymentModeCollection } from './collections/employment_mode';

@Injectable({
  providedIn: 'root',
})
export class CollectionService {
  private DispoTaskTemplate: SetCollection;
  private WorkEntryBatchTemplate: SetCollection;
  private EmployeeTag: SetCollection;
  private DrivingLicenseTag: SetCollection;
  private Employee: SetCollection;
  private Customer: SetCollection;
  private Tariff: SetCollection;
  private Job: SetCollection;
  private Venue: SetCollection;
  private Contractor: SetCollection;
  private Contact: SetCollection;
  private Qualification: SetCollection;
  private DispoAvailabilityType: SetCollection;
  private DispoRole: SetCollection;
  private DispoGroup: SetCollection;
  private EmploymentMode: SetCollection;
  private ProjectSets: SetCollection;
  private Material: SetCollection;

  constructor(
    private Department: DepartmentCollection,
    private JobAll: JobCollection,
    private VenueAll: VenueCollection,
    private TariffAll: TariffCollection,
    private EmployeeAll: EmployeeCollection,
    private ContractorAll: ContractorCollection,
    private CustomerAll: CustomerCollection,
    private ContactAll: ContactCollection,
    private QualificationAll: QualificationCollection,
    private Project: ProjectCollection,
    private ProjectSetsAll: ProjectSetsCollection,
    private DispoResource: DispoResourceCollection,
    private DispoPlan: DispoPlanCollection,
    private DispoSchedule: DispoScheduleCollection,
    private DispoTask: DispoTaskCollection,
    private ResourceTemplate: ResourceTemplateCollection,
    private DispoAnnouncement: DispoAnnouncementCollection,
    private DispoAssignment: DispoAssignmentCollection,
    private DispoInvitation: DispoInvitationCollection,
    private DispoAvailability: DispoAvailabilityCollection,
    private DispoGroupAll: DispoGroupCollection,
    private AccountRole: AccountRoleCollection,
    private DispoRoleAll: DispoRoleCollection,
    private DispoShift: DispoShiftCollection,
    private DispoAvailabilityTypeAll: DispoAvailabilityTypeCollection,
    private DispoAvailabilitySource: DispoAvailabilitySourceCollection,
    private MessageTemplate: MessageTemplateCollection,
    private Attachment: AttachmentCollection,
    private AttachmentRelease: AttachmentReleaseCollection,
    private Tag: TagCollection,
    private User: UserCollection,
    private EmploymentModeAll: EmploymentModeCollection,
    private MaterialAll: MaterialCollection,
    private Vat: VatCollection,
  ) {
    this.DispoTaskTemplate =
      this.ResourceTemplate.setForResource('dispo.tasks');
    this.WorkEntryBatchTemplate =
      this.ResourceTemplate.setForResource('work_entry.batch');
    this.EmployeeTag = this.Tag.employee();
    this.Employee = this.EmployeeAll.visible();
    this.Tariff = this.TariffAll.visible();
    this.Customer = this.CustomerAll.visible();
    this.Venue = this.VenueAll.visible();
    this.Job = this.JobAll.visible();
    this.Contractor = this.ContractorAll.visible();
    this.Contact = this.ContactAll.visible();
    this.Qualification = this.QualificationAll.visible();
    this.DispoAvailabilityType = this.DispoAvailabilityTypeAll.visible();
    this.DispoRole = this.DispoRoleAll.visible();
    this.DispoGroup = this.DispoGroupAll.visible();
    this.EmploymentMode = this.EmploymentModeAll.visible();
    this.DrivingLicenseTag = this.Tag.drivingLicences();
    this.ProjectSets = this.ProjectSetsAll.visible();
    this.Material = this.MaterialAll.visible();
  }

  get(collectionType: CollectionType) {
    return this[collectionType];
  }
}
