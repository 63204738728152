export function escapeFormula(input: string) {
  return input
    ?.replace(
      /([a-zA-z$_]\w*)((\.\d+)+)/g,
      (_m, p1, p2) => p1 + p2.replace(/\./g, 'a9232ffd'),
    )
    .replace(/(if)/gi, '_$1')
    .replace(/ = /g, ' == ')
    .replace(/(default)/gi, '_$1')
    .replace(/(switch)/gi, '_$1')
    .replace(/\{\{/g, 'd3fffddf')
    .replace(/\}\}/g, 'f231371b');
}

export function unescapeFormula(input: string) {
  return input
    ?.replace(/a9232ffd/g, '.')
    .replace(/_(if)/gi, '$1')
    .replace(/_(default)/gi, '$1')
    .replace(/_(switch)/gi, '$1')
    .replace(/d3fffddf/g, '{{')
    .replace(/f231371b/g, '}}');
}
