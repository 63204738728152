<form class="modal-container" [formGroup]="form" *ngIf="form" novalidate>
  <div class="modal-body">
    <nz-table
      [nzData]="form.controls.timesheets.controls"
      nzFrontPagination="false"
      nzSize="small"
    >
      <thead>
        <tr>
          <th>
            {{ 'dispo/task.attrs.slot' | translate }}
          </th>
          <th nzWidth="170px">
            {{ 'timesheet.attrs.resource_title' | translate }}
          </th>
          <th>{{ 'timesheet.attrs.tracked_time_short' | translate }}</th>
          <th nzWidth="200px">
            {{ 'timesheet.attrs.state_short' | translate }}
          </th>
          <th>{{ 'timesheet.attrs.gratis' | translate }}</th>
          <th nzWidth="200px">{{ 'timesheet.attrs.job_short' | translate }}</th>
          <th nzWidth="130px" class="time-set date">
            {{ 'timesheet.attrs.date' | translate }}
          </th>
          <ng-container *ngIf="!isMulti; else multiHeader">
            <th nzWidth="180px" class="time-set main-start first">
              {{ 'timesheet.attrs.starts_at' | translate }}
            </th>
            <th
              [nzWidth]="allowsPlus24Hours ? '130px' : '180px'"
              class="time-set main-end"
            >
              {{ 'timesheet.attrs.ends_at' | translate }}
            </th>
            <th
              *ngIf="allowsPlus24Hours"
              nzWidth="50px"
              class="time-set extended"
            >
              +24h
            </th>
            <th nzWidth="180px" class="time-set break-start">
              {{ 'timesheet.attrs.intermission_starts_at_short' | translate }}
            </th>
            <th nzWidth="180px" class="time-set break-end">
              {{ 'timesheet.attrs.intermission_ends_at_short' | translate }}
            </th>
            <th nzWidth="50px" class="time-set duration">
              {{ 'timesheet.attrs.duration' | translate }}
            </th>
          </ng-container>
          <ng-template #multiHeader>
            <th nzWidth="190px" class="time-set main-start first">
              {{ 'timesheet.attrs.starts_at' | translate }}
            </th>
            <th nzWidth="190px" class="time-set main-end">
              {{ 'timesheet.attrs.ends_at' | translate }}
            </th>
            <th nzWidth="90px">{{ 'actions.plural' | translate }}</th>
          </ng-template>
          <th nzWidth="80px" *ngFor="let supplement of supplements">
            {{ supplement.display_key }}
          </th>
          <th>
            <tc-hub-custom-fields
              class="tc-form-table"
              section="timesheet.multi"
              [formControl]="form.controls['applyToAll'].controls['store']"
              asHeader="true"
            ></tc-hub-custom-fields>
          </th>
        </tr>
      </thead>
      <tbody class="table-multi-actions" [hidden]="timesheets.length <= 1">
        <tr class="input-fields" formGroupName="applyToAll">
          <td></td>
          <td></td>
          <td class="input-field">
            <label
              class="checkbox__tracked-time"
              nz-checkbox
              formControlName="tracked_time"
            ></label>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-option-select
                label=""
                type="timesheet.state"
                [formGroup]="form.controls.applyToAll"
                controlName="state"
                bindLabel="title"
                bindValue="id"
              >
              </tc-hub-option-select>
            </div>
          </td>
          <td class="input-field">
            <label
              class="checkbox__gratis"
              nz-checkbox
              formControlName="gratis"
            ></label>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-select
                [items]="jobOptions$ | async"
                [loading]="isJobOptionsLoading"
                [formGroup]="form.controls.applyToAll"
                controlName="job_id"
                bindLabel="title"
                bindValue="id"
                searchable="true"
              >
              </tc-hub-select>
            </div>
          </td>
          <td class="input-field">
            <tc-input-date-web formControlName="date"></tc-input-date-web>
          </td>
          <td
            class="input-field"
            [colSpan]="isMulti ? '3' : allowsPlus24Hours ? '6' : '5'"
          >
            <div class="form-group">
              <div class="input-group multi-actions-item time-set date">
                <div class="input-group-item">
                  <tc-interval-set-web
                    [formControl]="
                      form.controls['applyToAll'].controls['times']
                    "
                    [tcIsHorizontal]="true"
                    [tcIsCompact]="true"
                    [tcHideLabels]="true"
                    [tcConfig]="{
                      noValidators: true
                    }"
                  ></tc-interval-set-web>
                </div>
              </div>
            </div>
          </td>
          <td class="input-field" *ngFor="let supplement of supplements">
            <nz-input-group class="custom-field">
              <input
                autocomplete="off"
                nz-input
                type="number"
                class="tc-input"
                step="0.01"
                formControlName="supplement_{{ supplement.id }}"
              />
            </nz-input-group>
          </td>
          <td></td>
        </tr>
        <tr class="input-fields" formGroupName="applyToAll">
          <td></td>
          <td class="input-field apply-label">
            {{ 'apply_to_all' | translate }}
          </td>
          <td class="input-field">
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="applyToAll('tracked_time')"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
            </div>
          </td>
          <td class="input-field">
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="applyToAll('state')"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
            </div>
          </td>
          <td class="input-field">
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="applyToAll('gratis')"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
            </div>
          </td>
          <td class="input-field">
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="applyToAll('job_id')"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
            </div>
          </td>
          <td class="input-field">
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="applyToAll('date')"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
            </div>
          </td>
          <td
            class="input-field"
            [colSpan]="isMulti ? '3' : allowsPlus24Hours ? '6' : '5'"
          >
            <div class="form-group">
              <div class="input-group multi-actions-item time-set date">
                <div class="input-group-item">
                  <button
                    type="button"
                    class="btn btn-small btn-secondary btn-block"
                    (click)="applyToAll('times')"
                  >
                    <span
                      nz-icon
                      nzType="vertical-align-bottom"
                      nzTheme="outline"
                    ></span>
                    {{ 'apply_to_all' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </td>
          <td class="input-field" *ngFor="let supplement of supplements">
            <div
              class="btn btn-small btn-secondary btn-block"
              (click)="applyToAll('supplement_' + supplement.id)"
            >
              <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody formArrayName="timesheets">
        <tr
          class="input-fields"
          *ngFor="
            let timesheet of form.controls.timesheets.controls;
            index as i
          "
          formGroupName="{{ i }}"
        >
          <td>{{ timesheet.controls.position.value }}</td>
          <td class="input-field">
            <tc-hub-select
              label=""
              [items]="
                (timesheet.controls.resource_type.value === 'Employee'
                  ? resourceOptionsEmployees$
                  : resourceOptionsContractors$
                ) | async
              "
              [loading]="isResourceOptionsLoading"
              [formGroup]="timesheet"
              controlName="resource_id"
              bindLabel="name"
              bindValue="id"
            >
            </tc-hub-select>
          </td>
          <td class="input-field">
            <label
              class="checkbox__tracked-time"
              nz-checkbox
              formControlName="tracked_time"
              (ngModelChange)="toggle(timesheet)"
            ></label>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-option-select
                label=""
                type="timesheet.state"
                [formGroup]="timesheet"
                controlName="state"
                bindLabel="title"
                bindValue="id"
              >
              </tc-hub-option-select>
            </div>
          </td>
          <td class="input-field">
            <label
              class="checkbox__gratis"
              nz-checkbox
              formControlName="gratis"
            ></label>
          </td>
          <td class="input-field">
            <div class="form-group">
              <tc-hub-select
                [items]="jobOptions$ | async"
                [loading]="isJobOptionsLoading"
                [formGroup]="timesheet"
                controlName="job_id"
                bindLabel="title"
                bindValue="id"
                searchable="true"
              >
              </tc-hub-select>
            </div>
          </td>
          <td class="input-field">
            <tc-input-date-web
              formControlName="date"
              [tcConfig]="{
                required: true,
                limit: {
                  start:
                    timesheets[i].starts_at | dfnsStartOfDay | dfnsSubDays : 1,
                  end:
                    timesheets[i].starts_at
                    | dfnsEndOfDay
                    | dfnsAddDays : (allowsPlus24Hours ? 2 : 1)
                }
              }"
            ></tc-input-date-web>
          </td>
          <td
            class="input-field time-set"
            [colSpan]="isMulti ? '3' : allowsPlus24Hours ? '6' : '5'"
          >
            <tc-interval-set-web
              [tcReferenceDate]="timesheet.value?.date"
              [tcIsHorizontal]="true"
              [tcIsCompact]="true"
              [tcHideLabels]="true"
              formControlName="times"
              [tcConfig]="{
                required: true,
                limit: {
                  start:
                    timesheets[i].starts_at | dfnsStartOfDay | dfnsSubDays : 1,
                  end:
                    timesheets[i].starts_at
                    | dfnsEndOfDay
                    | dfnsAddDays : (allowsPlus24Hours ? 2 : 1)
                }
              }"
            ></tc-interval-set-web>
          </td>
          <td class="input-field" *ngFor="let supplement of supplements">
            <nz-input-group class="custom-field">
              <input
                autocomplete="off"
                nz-input
                type="number"
                class="tc-input"
                step="0.01"
                formControlName="supplement_{{ supplement.id }}"
              />
            </nz-input-group>
          </td>
          <td>
            <tc-hub-custom-fields
              class="tc-form-table"
              section="timesheet.multi"
              [formControl]="timesheet.controls['store']"
              asRow="true"
            ></tc-hub-custom-fields>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [disabled]="form.controls.timesheets.invalid"
      [nzLoading]="loading"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
