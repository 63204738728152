import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class MonthCollection extends SetsCollection {
  identifier = 'months';
  endpoint = '/api/months';

  cache = 60 * 60 * 12;
}
