import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TcFieldSetIntervalSetService } from '@timecount/ui';

import { Plan } from '../../collections/plan';
import { DispoScheduleCollection, Schedule } from '../../collections/schedule';

@Component({
  selector: 'tc-hub-ds-times',
  templateUrl: './times.component.html',
  styleUrls: ['./times.component.scss'],
})
export class DSTimesComponent implements OnInit, OnDestroy {
  @Input() schedule: Schedule;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  destroyed$ = new Subject<void>();

  constructor(
    private scheduleCollection: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
  ) {}

  ngOnInit() {
    this.form = this.buildForm(this.schedule);
    this.signal.emit({ action: 'resize' });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save() {
    this.loading = true;

    const { times } = this.form.value;

    const payload = {
      ...this.schedule,
      template: {
        ...this.schedule.template,
        ...this.fieldSetIntervalSetService.formatValueToOffsetLength(times),
      },
      sync: ['times'],
    };

    this.scheduleCollection
      .update(this.schedule.id, payload)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.loading = false)),
      )
      .subscribe(() => {
        this.signal.emit({ action: 'close' });
      });
  }

  private buildForm(schedule: Schedule) {
    return this.formBuilder.group({
      times: [
        this.fieldSetIntervalSetService.parseFromOffsetLength(
          schedule.template,
        ),
      ],
    });
  }
}
