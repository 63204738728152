import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { first, map, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';

import {
  convertToPrimeMenuItem,
  DispoMenuService,
  MenuItem,
} from '../menu.service';

@Component({
  selector: 'tc-hub-dispo-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class DispoContextMenuComponent implements OnInit, OnDestroy {
  @ViewChild('contextMenu') contextMenu: ContextMenu;

  private menuSub: Subscription;
  private _destroyed$: Subject<void> = new Subject();

  constructor(private contextMenuService: DispoMenuService) {}

  ngOnInit() {
    this.contextMenuService.items
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        (event: { originalEvent: MouseEvent; items: Observable<any[]>[] }) => {
          if (event.originalEvent.type === 'contextmenu') {
            this.showMenu(event.originalEvent, event.items);
          }
        },
      );
  }

  showMenu(event, items: Observable<any[]>[]) {
    if (this.contextMenu.hide) {
      this.contextMenu.hide();
    }

    combineLatest([...items])
      .pipe(
        first(),
        map((items: MenuItem[][]) => {
          const unwrappedItems = [];

          convertToPrimeMenuItem(items.flat()).forEach((item, index) => {
            if ((index === 0 || item.expanded) && item.items) {
              unwrappedItems.push(...item.items);
            } else {
              unwrappedItems.push(item);
            }
          });

          return unwrappedItems;
        }),
      )
      .subscribe((items: MenuItem[]): void => {
        this.contextMenu.model = items;
        this.contextMenu.show(event);
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
