import { Pipe, PipeTransform } from '@angular/core';

import { isValidDate } from './date-helpers';
import { LocalizedDatePipe } from './localized-date.pipe';

@Pipe({
  name: 'interval',
})
export class IntervalPipe extends LocalizedDatePipe implements PipeTransform {
  placeholder = {
    short: '__.__.____ __:__',
    shortDate: '__.__.____',
    shortTime: '__:__',
  };

  transform(
    value: unknown,
    format: 'short' | 'shortDate' | 'shortTime' | string = 'short',
  ): string {
    if (!value) {
      return;
    }

    const { start, end } = value as Interval;

    const formattedStart = isValidDate(start)
      ? super.transform(start, format)
      : this.placeholder[format];

    const formattedEnd = isValidDate(end)
      ? super.transform(end, format)
      : this.placeholder[format];

    return `${formattedStart} - ${formattedEnd}`;
  }
}
