import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  add,
  areIntervalsOverlapping,
  differenceInMinutes,
  isSameDay,
  sub,
} from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  getIntervalDays,
  groupBy,
  isIntervalLongerThanDuration,
  isValidDate,
  isValidInterval,
} from '@timecount/utils';

import { LocalSettingsService } from '../../../../core/local-settings.service';
import { DispoResourceDataStructure } from '../../../datastructures/resource.service';
import { DispoFocusService } from '../../../dispo-focus.service';
import { EmployeesGridItemTogglerService } from '../../shared/employees-grid-item-toggler/employees-grid-item-toggler.service';

@Component({
  selector: 'tc-hub-dec-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DECEmployeeComponent implements OnInit {
  @Input() employee: any;
  @Input() panelBegin: Date;
  @Input() panelEnd: Date;

  gridDateWidth = 60;
  gridResolution = 1;

  cutoffStart = false;
  cutoffEnd = false;

  // This will later be provided by the API
  startOffset = window.config.company.dispo_availabilities_cutoff_begin;
  endOffset = window.config.company.dispo_availabilities_cutoff_end;

  dates$: Observable<any[]>;
  resource$: Observable<any>;

  availabilities$: Observable<any>;
  cards$: Observable<any>;
  timebalances$: Observable<any>;

  constructor(
    public gridItemTogglerService: EmployeesGridItemTogglerService,
    private dispoFocus: DispoFocusService,
    private localSettings: LocalSettingsService,
    private dispoResourceDS: DispoResourceDataStructure,
  ) {}

  ngOnInit() {
    this.dates$ = this.dispoFocus.dates();
    this.resource$ = this.dispoResourceDS.employee(this.employee.id);

    this.availabilities$ = this.resource$.pipe(
      map((resource) =>
        resource.availabilities
          .map((item) => this._addAvailabilityCutoffs(item))
          .filter((item) => this._isAvailabilityInPanel(item)),
      ),
    );

    this.cards$ = this.resource$.pipe(
      map((resource) => [
        ...groupBy(
          [
            ...resource.assignments.filter((item) => this._isCardInPanel(item)),
            ...resource.invitations.filter((item) => this._isCardInPanel(item)),
          ],
          (item) => item.date,
        ).values(),
      ]),
    );

    this.timebalances$ = this.resource$.pipe(
      map((resource) => {
        return [
          ...groupBy(
            resource.timebalances.filter(
              (item) =>
                item.starts_at.getTime() >= this.panelBegin.getTime() &&
                item.starts_at.getTime() <= this.panelEnd.getTime(),
            ),
            (item) => (item[`starts_at`] as Date).toISOString(),
          ).entries(),
        ].map((item) => [new Date(item[0]), item[1]]);
      }),
    );
  }

  onMenu($event, item_type, item) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({
      item_type: item_type,
      item: [item],
    });
  }

  onMouseOver(type, item) {
    this.localSettings.set('dispoInfo.item', { type: type, item: item });
  }

  private _isAvailabilityInPanel(
    {
      starts_at,
      ends_at,
      cutoffStart,
      cutoffEnd,
      ..._args
    }: {
      starts_at: Date;
      ends_at: Date;
      cutoffStart: boolean;
      cutoffEnd: boolean;
    } = {
      starts_at: undefined,
      ends_at: undefined,
      cutoffStart: false,
      cutoffEnd: false,
    },
  ): boolean {
    const hideBegin =
      -differenceInMinutes(this.panelBegin, ends_at) <= this.endOffset &&
      cutoffEnd;

    const hideEnd =
      -differenceInMinutes(starts_at, this.panelEnd) < this.startOffset &&
      cutoffStart;

    const itemInterval = { start: starts_at, end: ends_at };
    const panelInterval = {
      start: sub(this.panelBegin, { minutes: this.startOffset }),
      end: add(this.panelEnd, { minutes: this.endOffset }),
    };

    return (
      isValidInterval(itemInterval) &&
      isValidInterval(panelInterval) &&
      areIntervalsOverlapping(itemInterval, panelInterval, {
        inclusive: true,
      }) &&
      !hideBegin &&
      !hideEnd
    );
  }

  private _isCardInPanel(item): boolean {
    return (
      isValidDate(item.task?.starts_at) &&
      item.task?.starts_at.getTime() >= this.panelBegin.getTime() &&
      item.task?.starts_at.getTime() <= this.panelEnd.getTime()
    );
  }

  // TODO: Move this to a Service
  private _addAvailabilityCutoffs(availability) {
    const itemInterval = {
      start: availability[`starts_at`],
      end: availability[`ends_at`] ?? availability[`starts_at`],
    };

    const cutoffIntervalDays = getIntervalDays(itemInterval, {
      startOffset: this.startOffset,
      endOffset: this.endOffset,
    });

    const wasStartCutoff = !cutoffIntervalDays.find((item) =>
      isSameDay(item, itemInterval.start),
    );

    const wasEndCutoff = !cutoffIntervalDays.find((item) =>
      isSameDay(item, itemInterval.end),
    );

    const cutoffPanelBegin = {
      start: itemInterval.start,
      end: this.panelBegin,
    };

    const cutoffPanelEnd = {
      start: this.panelEnd,
      end: itemInterval.end,
    };

    availability[`cutoffStart`] =
      wasStartCutoff &&
      !(
        isValidInterval(cutoffPanelBegin) &&
        isIntervalLongerThanDuration(cutoffPanelBegin, {
          minutes: this.startOffset,
        })
      );

    availability[`cutoffEnd`] =
      wasEndCutoff &&
      !(
        isValidInterval(cutoffPanelEnd) &&
        isIntervalLongerThanDuration(cutoffPanelEnd, {
          minutes: this.endOffset,
        })
      );

    return availability;
  }
}
