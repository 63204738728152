import { Injectable } from '@angular/core';
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfQuarter,
  startOfYear,
} from 'date-fns';

import { dateToTzUnix } from '@timecount/utils';

import { FrameCollection } from '../core/frame-collection';
import { dateToString } from '../core/helpers';
import { SetsCollection } from '../core/sets-collection';

import { TimeBalanceStats } from './timebalance.collection';

export class SubtotalStats extends TimeBalanceStats {
  total_target_hours: number;
  total_hours: number;
  overtime_hours: number;
  total: number;
  accumulates: boolean;
}

export class Subtotal extends SubtotalStats {
  employee: {
    token: string;
    name: string;
    employment_mode_key: string;
    employment_mode_id: number;
    employee_tags: string[];
  };

  month: {
    id: number;
    date: string;
    lock_state: 'unlocked' | 'locked' | 'hard_locked';
    locked: boolean;
  };

  store: Record<string, unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class SubtotalCollection extends FrameCollection {
  type: Subtotal;
  step = 60 * 60 * 24 * 60;
  cache = 600;
  identifier = 'subtotal';
  endpoint = '/api/subtotals/range';
  query = {
    'filter[employee_visibility]': '1',
  };

  forResource(resource) {
    const filterFunc = (x) =>
      x.resource_id === resource.id && x.resource_type === resource.type;
    const filterLookup = `resource_id: ${resource.id}|resource_type: ${resource.type}`;

    return this.filter(filterFunc, filterLookup);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SubtotalMonthCollection extends SetsCollection {
  type: SubtotalStats;
  cache = 600;
  identifier = 'subtotal';
  endpoint = '/api/months/:month/subtotals/';

  forMonth(date) {
    return this.query(
      {
        month: date,
      },
      (a) => {
        return a.month === date;
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SubtotalQuarterStatCollection extends SetsCollection {
  type: SubtotalStats;
  refresh = 300;
  identifier = 'subtotal_stats_quarter';
  endpoint = '/api/subtotals/range';

  forDate(date) {
    const compDate = dateToString(startOfQuarter(date));
    const begin = dateToTzUnix(startOfQuarter(date));
    const end = dateToTzUnix(endOfQuarter(date)) - 86400;
    return this.query(
      {
        begin: begin,
        end: end,
        stats: 'quarter',
        'filter[employee_visibility]': '1',
      },
      (a) => {
        return a.month === compDate;
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SubtotalPartialQuarterStatCollection extends SetsCollection {
  type: SubtotalStats;
  refresh = 300;
  identifier = 'subtotal_stats_partial_quarter';
  endpoint = '/api/subtotals/range';

  forDate(date) {
    const compDate = dateToString(startOfQuarter(date));
    const begin = dateToTzUnix(startOfQuarter(date));
    const end = dateToTzUnix(endOfMonth(date)) - 86400;
    return this.query(
      {
        begin: begin,
        end: end,
        stats: 'partial_quarter',
        'filter[employee_visibility]': '1',
      },
      (a) => {
        return a.month === compDate;
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SubtotalYearStatCollection extends SetsCollection {
  type: SubtotalStats;
  refresh = 300;
  identifier = 'subtotal_stats_year';
  endpoint = '/api/subtotals/range';

  forDate(date) {
    const compDate = dateToString(startOfYear(date));
    const begin = dateToTzUnix(startOfYear(date));
    const end = dateToTzUnix(endOfYear(date)) - 86400;
    return this.query(
      {
        begin: begin,
        end: end,
        stats: 'year',
        'filter[employee_visibility]': '1',
      },
      (a) => {
        return a.month === compDate;
      },
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SubtotalPartialYearStatCollection extends SetsCollection {
  type: SubtotalStats;
  refresh = 300;
  identifier = 'subtotal_stats_partial_year';
  endpoint = '/api/subtotals/range';

  forDate(date) {
    const compDate = dateToString(startOfYear(date));
    const begin = dateToTzUnix(startOfYear(date));
    const end = dateToTzUnix(endOfMonth(date)) - 86400;
    return this.query(
      {
        begin: begin,
        end: end,
        stats: 'partial_year',
        'filter[employee_visibility]': '1',
      },
      (a) => {
        return a.month === compDate;
      },
    );
  }
}
