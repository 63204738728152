import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { TcInterval } from '@timecount/core';
import { TcFieldSetIntervalSetService } from '@timecount/ui';

import { WorkEntryBatchCollection } from '../../../work_entry_batch.collection';
import { WorkEntryBatch } from '../../../work_entry_batch.model';

@Component({
  selector: 'tc-hub-batch-create-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss'],
})
export class TcWorkEntryBatchStep5Component implements OnInit {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  formGroup: UntypedFormGroup;
  loading = false;

  constructor(
    private workEntryBatchCollection: WorkEntryBatchCollection,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit() {
    this._buildForm();
    this.signal.emit({ action: 'resize' });
  }

  // --------------
  // Public Methods
  // --------------

  prev() {
    this.signal.emit({ action: 'prev', config: this.config });
  }

  save() {
    const {
      title,
      slots_config,
      days_matrix,
      template,
      project,
      aggregation_id,
      dateInterval,
    } = this.config ?? {};
    const formattedDateInterval = TcInterval.formatForApi(dateInterval);

    const workEntryBatch = Object.assign(new WorkEntryBatch(), {
      title: title,
      starts_at: formattedDateInterval.starts_at,
      ends_at: formattedDateInterval.ends_at,
      slots_config: slots_config,
      days_matrix: days_matrix,
      template: {
        ...template,
        ...this.fieldSetIntervalSetService.formatValueToOffsetLength(
          this.formGroup.value.times,
        ),
      },
    });

    this.loading = true;
    this.workEntryBatchCollection
      .forProject(project.id, aggregation_id)
      .create(workEntryBatch)
      .subscribe({
        next: () => {
          this.loading = false;
          this.signal.emit({ action: 'close' });
        },
      });
  }

  private _buildForm() {
    this.formGroup = this.formBuilder.group({
      dateInterval: [{ value: this.config.dateInterval, disabled: true }],
      times: [
        { value: this.config.template.times, disabled: true },
        [Validators.required],
      ],
      size: [{ value: this.config.template.size, disabled: true }],
      title: [{ value: this.config.title, disabled: true }],
    });
  }
}
