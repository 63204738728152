import { Injectable } from '@angular/core';

import { SetsCollection } from '../../../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class WorkEntryBatchCollection extends SetsCollection {
  cache = 600;
  identifier = 'projects/aggregations/work_entry';
  endpoint =
    'api/projects/:project_id/aggregations/:aggregation_id/work_entries/batch';

  remoteDeleteValidation = true;
  refreshOnStale = false;

  forProject(project_id, aggregation_id) {
    return this.query(
      {
        project_id: project_id,
        aggregation_id: aggregation_id,
      },
      (a) => {
        return a.aggregation_id === aggregation_id;
      },
    );
  }
}
