import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { isValidDate } from '@timecount/utils';
import { ModalService } from '@timecount/ui';

import { DispoTaskCollection } from '../collections/task';
import { DispoPlanComponent } from '../dispo-plan/dispo-plan.component';
import { DispoPlanCollection, Plan } from '../collections/plan';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { DispoCombinedTaskCollection } from '../collections/combined_task';
import { LogsComponent } from '../../shared/logs/logs.component';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  private translations: { [key: string]: string } = {};
  private templates: any[];

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private planCollection: DispoPlanCollection,
    private taskCollection: DispoTaskCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private combinedTaskCollection: DispoCombinedTaskCollection,
  ) {
    this.translateService.get(['dispo/plan.modal.title']).subscribe((value) => {
      Object.assign(this.translations, value);
    });

    this.resourceTemplateCollection
      .setForResource('dispo.plans')
      .all()
      .subscribe((templates) => {
        this.templates = templates;
      });
  }

  general(plan: any) {
    this.section(plan, 'general');
  }

  move(plan: any) {
    this.single(plan, 'move');
  }

  delete(plan: any) {
    this.single(plan, 'delete');
  }

  create(plan: any) {
    this.single(plan, 'create');
  }

  createDefault() {
    const defaultTemplate = this.templates.find((t) => t.default) || {
      template: {},
    };
    this.create(Object.assign({}, defaultTemplate.template));
  }

  createFromDateDefault(date) {
    const defaultTemplate = this.templates.find((t) => t.default) || {
      template: {},
    };
    this.createFromDateAndTemplate(
      date,
      Object.assign({}, defaultTemplate.template),
    );
  }

  createFromDateAndTemplate(date, template) {
    const plan = this.planCollection.fromTemplate(date, template);

    this.create(plan);
  }

  setTasksTrackable(plan: Plan, date: Date) {
    const taskModels$ = this.combinedTaskCollection.forDate(date);

    taskModels$.pipe(first()).subscribe({
      next: (tasks) => {
        tasks = tasks.filter((t) => t.plan_id === plan.id);

        tasks.forEach((task) => {
          if (task.state !== 'open' || !task.tracking_enabled) {
            task.state = 'open';
            task.tracking_enabled = true;
            task.shallow
              ? this.taskCollection.create(task)
              : this.taskCollection.update(task.id, task);
          }
        });
      },
    });
  }

  setTasksHidden(plan: Plan, date?: Date) {
    const taskModels$ = isValidDate(date)
      ? this.combinedTaskCollection.forDate(date)
      : this.combinedTaskCollection.all();

    taskModels$.pipe(first()).subscribe({
      next: (tasks) => {
        tasks = tasks.filter((t) => t.plan_id === plan.id);

        tasks.forEach((task) => {
          if (task.state !== 'draft') {
            task.state = 'draft';
            task.shallow
              ? this.taskCollection.create(task)
              : this.taskCollection.update(task.id, task);
          }
        });
      },
    });
  }

  setTasksPublic(plan: Plan, date?: Date) {
    const taskModels$ = isValidDate(date)
      ? this.combinedTaskCollection.forDate(date)
      : this.combinedTaskCollection.all();

    taskModels$.pipe(first()).subscribe({
      next: (tasks) => {
        tasks = tasks.filter((t) => t.plan_id === plan.id);

        tasks.forEach((task) => {
          if (task.state !== 'open') {
            task.state = 'open';
            task.shallow
              ? this.taskCollection.create(task)
              : this.taskCollection.update(task.id, task);
          }
        });
      },
    });
  }

  requestTasksConfirmation(plan: Plan, date?: Date) {
    const taskModels$ = isValidDate(date)
      ? this.combinedTaskCollection.forDate(date)
      : this.combinedTaskCollection.all();

    taskModels$.pipe(first()).subscribe({
      next: (tasks) => {
        tasks = tasks.filter((t) => t.plan_id === plan.id);

        tasks.forEach((task) => {
          task.state = 'open';
          task.requested = true;

          task.shallow
            ? this.taskCollection.create(task)
            : this.taskCollection.update(task.id, task);
        });
      },
    });
  }

  section(plan: any, section: string, single = false) {
    const ref = this.modalService.open(DispoPlanComponent, {
      data: {
        plan: plan,
        section: section,
        single: single,
      },
      modalTitle: this.translations['dispo/plan.modal.title'],
    });
  }

  single(plan: any, section: string) {
    this.section(plan, section, true);
  }

  viewLogs(resource_id: number) {
    this.modalService.open(LogsComponent, {
      data: {
        resource_id,
        resource_type: 'TcDispo::Event',
      },
      modalTitle: this.translations['trails.title'],
    });
  }
}
