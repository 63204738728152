import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { TcKeyValueFilterPipe, TcTypeofPipe } from '@timecount/utils';

@Component({
  standalone: true,
  selector: 'tc-formulas-variables-list',
  templateUrl: './variables-list.component.html',
  styleUrls: ['./variables-list.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzIconModule,
    NzInputModule,
    NzTableModule,
    TcKeyValueFilterPipe,
    TcTypeofPipe,
  ],
})
export class TcVariablesListComponent {
  @Input()
  variables: Record<string, unknown> = {};

  searchVariable = '';
  expandedValues = new Set<string>();

  // ---------------------------------------------------------------------------
  // Events Handlers
  // ---------------------------------------------------------------------------

  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandedValues.add(id);
    } else {
      this.expandedValues.delete(id);
    }
  }
}
