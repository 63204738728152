import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { SetsCollection } from '../../core/sets-collection';
import { ApiErrorService } from '../../core/api-error.service';
import { Cable } from '../../core/cable';
import { CurrentUserService } from '../../core/current-user.service';
import { StoreService } from '../../core/store.service';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { LocalSettingsService } from '../../core/local-settings.service';

import { AggregationType } from './aggregation_type.model';
import { Aggregation } from './aggregation.model';
import { AggregationImporterSettings } from './aggregation-importer-settings.model';

@Injectable({
  providedIn: 'root',
})
export class AggregationCollection extends SetsCollection {
  identifier = 'projects/aggregations';
  endpoint = '/api/projects/:project_id/aggregations';

  cache = 0;
  refreshOnStale = false;
  remoteDeleteValidation = true;

  constructor(
    protected http: HttpClient,
    protected cable: Cable,
    protected store: StoreService,
    protected errorHandler: ApiErrorService,
    protected currentUser: CurrentUserService,
    protected resourceTemplate: ResourceTemplateCollection,
    protected localSettingsService: LocalSettingsService,
    @Inject('Flash') protected flash,
  ) {
    super(
      http,
      cable,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
      flash,
    );
  }

  decorate(aggregation: Aggregation): Aggregation {
    aggregation.label = [aggregation.token, aggregation.title]
      .filter((a) => a)
      .join(' ');

    return aggregation;
  }

  forProject(project_id) {
    return this.query(
      {
        project_id: project_id,
      },
      (a) => {
        return a.project_id === project_id;
      },
    );
  }

  fromType(type: AggregationType, project): Observable<Aggregation> {
    const base: Partial<Aggregation> = {
      project_id: project.id,
      type,
      type_id: type.id,
      title: type.title,
      importer_settings: new AggregationImporterSettings({
        project_id: project.id,
        project: {
          id: project.id,
          title: project.title,
          token: project.token,
        },
      }),
    };
    return this.resourceTemplate
      .default<Aggregation>(`aggregation.${type.handle}`)
      .pipe(
        map(({ template }) => {
          const mergedAggregation = new Aggregation(_.merge(base, template));

          return mergedAggregation;
        }),
      );
  }
}
