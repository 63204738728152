import { Component, Input } from '@angular/core';

@Component({
  selector: 'tc-hub-dispo-slot-labels',
  templateUrl: './dispo-slot-labels.component.html',
  styleUrls: ['./dispo-slot-labels.component.scss'],
})
export class DispoSlotLabelsComponent {
  @Input() slot;
}
