import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DispoResourceDataStructure } from '../../../datastructures/resource.service';

@Component({
  selector: 'tc-hub-dispo-employees-grid-header',
  templateUrl: './employees-grid-header.component.html',
  styleUrls: ['./employees-grid-header.component.scss'],
})
export class EmployeesGridHeaderComponent implements OnInit {
  @Input() employee: any;

  resource$: Observable<any>;

  constructor(private dispoResourceDS: DispoResourceDataStructure) {}

  ngOnInit(): void {
    this.resource$ = this.dispoResourceDS.employee(this.employee.id);
  }
}
