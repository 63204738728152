import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { addMonths, startOfMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { ModalService } from '@timecount/ui';

import { ValueSet, ValueSetCollection } from '../core/collections/value_set';
import { RemoteConfig } from '../core/remote_config.service';
import { SetCollection } from '../core/sets-collection';

import { ValueSetComponent } from './value-set/value-set.component';

@Component({
  selector: 'tc-hub-value-sets-btn',
  templateUrl: './value-sets-btn.component.html',
  styleUrls: ['./value-sets-btn.component.scss'],
})
export class ValueSetsBtnComponent implements OnInit, OnDestroy {
  @Input() targetType: string;
  @Input() targetId: number;
  @Input() section: string;

  private valueSetCollection: SetCollection;
  private destroyed$ = new Subject<void>();

  public valueSets$;
  public schema$;
  public count$;
  public visible = false;

  private translations: { [key: string]: string } = {};

  constructor(
    private valueSets: ValueSetCollection,
    private config: RemoteConfig,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {
    this.translateService.get(['value_set.modal.title']).subscribe((value) => {
      Object.assign(this.translations, value);
    });
  }

  ngOnInit(): void {
    this.valueSetCollection = this.valueSets.forTargetType(this.targetType);
    this.schema$ = this.config.getItem('schema', this.section);

    this.schema$.pipe(first()).subscribe({
      next: (schema) => {
        if (schema) {
          this.valueSets$ = this.valueSetCollection.all().pipe(
            map((valueSets: ValueSet[]) => {
              return valueSets
                .filter(
                  (valueSet) =>
                    valueSet.target_id === this.targetId &&
                    valueSet.schema_key === schema.key,
                )
                .sort((a, b) => b.starts_at.getTime() - a.starts_at.getTime());
            }),
          );

          this.count$ = this.valueSets$.pipe(
            map((valueSets: ValueSet[]) => {
              return valueSets.filter(
                (valueSet) => valueSet.starts_at > new Date(),
              ).length;
            }),
          );

          this.visible = true;
        }
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  create() {
    const valueSet = Object.assign(new ValueSet(), {
      target_id: this.targetId,
      target_type: this.targetType,
      schema_key: this.section,
      starts_at: startOfMonth(addMonths(new Date(), 1)),
      data: {},
    });

    const ref = this.modalService.open(ValueSetComponent, {
      data: {
        section: 'general',
        valueSet: valueSet,
      },
      modalTitle: this.translations['value_set.modal.title'],
    });

    return ref;
  }

  edit(valueSet) {
    valueSet = Object.assign({}, valueSet);
    const ref = this.modalService.open(ValueSetComponent, {
      data: {
        section: 'general',
        valueSet: valueSet,
      },
      modalTitle: this.translations['value_set.modal.title'],
    });

    return ref;
  }
}
