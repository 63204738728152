import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TcFormFieldBaseDirective } from '../form-field.directive';

@Component({
  selector: 'tc-form-field-web',
  templateUrl: './form-field-web.component.html',
  styleUrls: ['./form-field-web.component.scss'],
})
export class TcFormFieldWebComponent extends TcFormFieldBaseDirective {
  @Input() label: string;
  @Input() labelFor: string = null;

  @Input() tcCanDisable = false;

  @Input() disabled = false;
  @Output() disabledChange = new EventEmitter();
}
