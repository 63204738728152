/* eslint-disable import/order */

import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { AngularSplitModule } from 'angular-split';

import { UpgradeModule } from '@angular/upgrade/static';

import 'reflect-metadata';

/*
 * Import Libs
 */

import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';

import { AutosizeModule } from 'ngx-autosize';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { NgXCableModule } from 'ngx-cable';
import { HotkeyModule } from 'angular2-hotkeys';

/*
 * Import Modules
 */
import { PostsModule } from './posts';
import { TcHubProjectsModule } from './projects';
import { TcHubEmployeesModule } from './employees/employees.module';
import { AppRoutingModule } from './app-routing.module';
import { TcPrimengModule } from './base/tc-primeng';
import { DispoEmployeesModule } from './dispo/dispo-employees/dispo-employees.module';

/*
 * Import Forms
 */

import { TcDropdownsModule } from './base/tc-dropdowns';
import { TcSelectsModule } from './base/tc-selects';

/*
 * Import Components
 */
import { AppComponent } from './app.component';
import { TcTransferComponent } from './base/tc-transfer/tc-transfer.component';

// Time Tracking
import { TrackStartDateDirective } from './base/track-time/track-start-date.directive';
import { TrackStartTimeDirective } from './base/track-time/track-start-time.directive';
import { TrackEndTimeDirective } from './base/track-time/track-end-time.directive';
import { TrackIntermissionStartTimeDirective } from './base/track-time/track-intermission-start-time.directive';
import { TrackIntermissionEndTimeDirective } from './base/track-time/track-intermission-end-time.directive';
import { TrackTimeDirective } from './base/track-time/track-time.directive';
import { TrackDurationDirective } from './base/track-time/track-duration.directive';

// panels and toggles
import { ToggleComponent } from './base/toggle/toggle.component';

// Pins
import { PinComponent } from './pins/pin/pin.component';
import { PIGeneralComponent } from './pins/pin/general/general.component';

// TimeBalances
import { TimebalanceDocumentComponent } from './timebalances/document/document.component';
import { TBDOCButtonComponent } from './timebalances/document/button/button.component';
import { TBDOCStep1Component } from './timebalances/document/step1/step1.component';
import { TBDOCStep2Component } from './timebalances/document/step2/step2.component';

// Dispo
import { DispoComponent } from './dispo/dispo.component';

// Dispo Dates
import { DispoDateComponent } from './dispo/dispo-date/dispo-date.component';

// Dispo View Settings
import { DispoViewSettingsComponent } from './dispo/dispo-view-settings/dispo-view-settings.component';

// Dispo Menu
import { DispoContextMenuComponent } from './dispo/menus/context-menu/context-menu.component';
import { DispoActionMenuComponent } from './dispo/menus/action-menu/action-menu.component';

// Dispo Message
import { DispoMessageComponent } from './dispo/message/message.component';
import { DMSGMultiComponent } from './dispo/message/multi/multi.component';
import { DMSGCreateComponent } from './dispo/message/create/create.component';
import { DMSGCStep1Component } from './dispo/message/create/step1/step1.component';
import { DMSGCStep2Component } from './dispo/message/create/step2/step2.component';

// Markdown
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { MarkdownOptionsFactory } from './base/markdown/markdown-options-factory';
import { MarkdownComponent } from './base/markdown/markdown.component';

// Conflict Resolver Component
import { DispoConflictResolverComponent } from './dispo/dispo-conflict-resolver/dispo-conflict-resolver.component';

// Dispo Tasks
import { DispoTasksComponent } from './dispo/dispo-tasks/dispo-tasks.component';
import { DTDayComponent } from './dispo/dispo-tasks/day/day.component';
import { DTPlanComponent } from './dispo/dispo-tasks/plan/plan.component';
import { DTTaskComponent } from './dispo/dispo-tasks/task/task.component';
import { DTScrollComponent } from './dispo/dispo-tasks/scroll/scroll.component';
import { DTPaginateComponent } from './dispo/dispo-tasks/paginate/paginate.component';

// Dispo Task
import { DispoTaskComponent } from './dispo/dispo-task/dispo-task.component';
import { DTMoveComponent } from './dispo/dispo-task/move/move.component';
import { DTMoveScheduledComponent } from './dispo/dispo-task/move-scheduled/move-scheduled.component';
import { DTInvitationsComponent } from './dispo/dispo-task/invitations/invitations.component';
import { DTGeneralComponent } from './dispo/dispo-task/general/general.component';
import { DTPublicComponent } from './dispo/dispo-task/public/public.component';
import { DTCreateComponent } from './dispo/dispo-task/create/create.component';
import { DTDeleteComponent } from './dispo/dispo-task/delete/delete.component';
import { DTSlotsComponent } from './dispo/dispo-task/slots/slots.component';

// Dispo Assignment
import { DispoAssignmentComponent } from './dispo/dispo-assignment/dispo-assignment.component';
import { DAGeneralComponent } from './dispo/dispo-assignment/general/general.component';
import { DAInfoComponent } from './dispo/dispo-assignment/info/info.component';
import { DADeleteComponent } from './dispo/dispo-assignment/delete/delete.component';
import { DAAvailComponent } from './dispo/dispo-assignment/avail/avail.component';
import { DAContractorComponent } from './dispo/dispo-assignment/contractor/contractor.component';
import { DAEmployeeComponent } from './dispo/dispo-assignment/employee/employee.component';
import { DATimebalanceComponent } from './dispo/dispo-assignment/timebalance/timebalance.component';

// Dispo Availability
import { DispoAvailabilityComponent } from './dispo/dispo-availability/dispo-availability.component';
import { DAVRangeComponent } from './dispo/dispo-availability/range/range.component';
import { DAVIntervalComponent } from './dispo/dispo-availability/interval/interval.component';

// Dispo Plan
import { DispoPlanComponent } from './dispo/dispo-plan/dispo-plan.component';
import { DPGeneralComponent } from './dispo/dispo-plan/general/general.component';
import { DPCreateComponent } from './dispo/dispo-plan/create/create.component';
import { DPMoveComponent } from './dispo/dispo-plan/move/move.component';
import { DPDeleteComponent } from './dispo/dispo-plan/delete/delete.component';

// Dispo Schedule
import { DispoScheduleComponent } from './dispo/dispo-schedule/dispo-schedule.component';
import { DSCreateComponent } from './dispo/dispo-schedule/create/create.component';
import { DSCStep1Component } from './dispo/dispo-schedule/create/step1/step1.component';
import { DSCStep2Component } from './dispo/dispo-schedule/create/step2/step2.component';
import { DSCStep3Component } from './dispo/dispo-schedule/create/step3/step3.component';
import { DSCStep4Component } from './dispo/dispo-schedule/create/step4/step4.component';
import { DSCStep5Component } from './dispo/dispo-schedule/create/step5/step5.component';
import { DSDeleteComponent } from './dispo/dispo-schedule/delete/delete.component';
import { DSGeneralComponent } from './dispo/dispo-schedule/general/general.component';
import { DSSlotsComponent } from './dispo/dispo-schedule/slots/slots.component';
import { DSTimesComponent } from './dispo/dispo-schedule/times/times.component';
import { DSDaysComponent } from './dispo/dispo-schedule/days/days.component';
import { DSPublicComponent } from './dispo/dispo-schedule/public/public.component';
import { DSMoveComponent } from './dispo/dispo-schedule/move/move.component';

// Dispo Schedules
import { DispoSchedulesComponent } from './dispo/dispo-schedules/dispo-schedules.component';
import { DSPlanComponent } from './dispo/dispo-schedules/plan/plan.component';
import { DSScheduleComponent } from './dispo/dispo-schedules/schedule/schedule.component';

// Dispo Announcements
import { DispoAnnouncementsComponent } from './dispo/dispo-announcements/dispo-announcements.component';
import { DANPlanComponent } from './dispo/dispo-announcements/plan/plan.component';
import { DANAnnounceComponent } from './dispo/dispo-announcements/announce/announce.component';

// Dispo Announcement
import { DispoAnnouncementComponent } from './dispo/dispo-announcement/dispo-announcement.component';
import { DANGeneralComponent } from './dispo/dispo-announcement/general/general.component';
import { DANInvitationsComponent } from './dispo/dispo-announcement/invitations/invitations.component';
import { DANInviteComponent } from './dispo/dispo-announcement/invite/invite.component';
import { DANAppendTasksComponent } from './dispo/dispo-announcement/append-tasks/append-tasks.component';
import { DANAppendSlotsComponent } from './dispo/dispo-announcement/append-slots/append-slots.component';
import { DANDeleteComponent } from './dispo/dispo-announcement/delete/delete.component';
import { DANCreateComponent } from './dispo/dispo-announcement/create/create.component';
import { DANDetachTasksComponent } from './dispo/dispo-announcement/detach-tasks/detach-tasks.component';

// Dispo Invitation
import { DispoInvitationComponent } from './dispo/dispo-invitation/dispo-invitation.component';
import { DINDeleteComponent } from './dispo/dispo-invitation/delete/delete.component';

// Dispo Import
import { DispoImportComponent } from './dispo/dispo-import/dispo-import.component';
import { DIMStep1Component } from './dispo/dispo-import/step1/step1.component';
import { DIMStep2Component } from './dispo/dispo-import/step2/step2.component';
import { DIMStep3Component } from './dispo/dispo-import/step3/step3.component';
import { DIMStep4Component } from './dispo/dispo-import/step4/step4.component';

import { DIMButtonComponent } from './dispo/dispo-import/button/button.component';

import { SharedModule } from './shared/shared.module';
import { DispoSharedModule } from './dispo/shared/dispo-shared.module';

import { ObserveVisibilityDirective } from './base/observe-visibility.directive';
import { ValueSetsBtnComponent } from './value-sets/value-sets-btn.component';
import { ValueSetComponent } from './value-sets/value-set/value-set.component';
import { VSGeneralComponent } from './value-sets/value-set/general/general.component';
import { HideDirective } from './base/hide.directive';
import { TcTourComponent } from './base/tc-tour/tc-tour.component';

import { DispoAssignmentsComponent } from './dispo/dispo-assignments/dispo-assignments.component';
import { DASPlanComponent } from './dispo/dispo-assignments/plan/plan.component';
import { DASTaskComponent } from './dispo/dispo-assignments/task/task.component';
import { DASScheduleComponent } from './dispo/dispo-assignments/schedule/schedule.component';
import { DASProjectComponent } from './dispo/dispo-assignments/project/project.component';
import { DASAssignmentComponent } from './dispo/dispo-assignments/assignment/assignment.component';
import { DASTimesheetComponent } from './dispo/dispo-assignments/timesheet/timesheet.component';
import { DASTimesheetGroupComponent } from './dispo/dispo-assignments/timesheet-group/timesheet-group.component';
import { TcDepartmentInfoComponent } from './base/user-department-select/department-info/department-info.component';

import { FinancesEntriesComponent } from './dispo/finances-entries/finances-entries.component';
import { FinancesEntriesPlanComponent } from './dispo/finances-entries/plan/plan.component';
import { FinancesEntriesTaskComponent } from './dispo/finances-entries/task/task.component';
import { FinancescheduleComponent } from './dispo/finances-entries/schedule/schedule.component';
import { FinancesEntriesProjectComponent } from './dispo/finances-entries/project/project.component';
import { FinancesEntriesWorkEntryComponent } from './dispo/finances-entries/work_entry/work_entry.component';
import { FinancesEntriesServiceEntryComponent } from './dispo/finances-entries/service_entry/service_entry.component';
import { FinancesEntriesWorkEntryGroupComponent } from './dispo/finances-entries/work_entry-group/work_entry-group.component';
import { FinancesEntriesServiceEntryGroupComponent } from './dispo/finances-entries/service_entry-group/service_entry-group.component';
import { TcSmtpConfigFormComponent } from './preferences/apis/smtp-config-form/smtp-config-form.component';
import { TcCustomEmailHeadersComponent } from './preferences/apis/custom-email-headers/custom-email-headers.component';

import {
  TimesheetComponent,
  TSGeneralComponent,
  TSMultiComponent,
} from './timesheets';

import { MenuItemHelpComponent } from './base/issue-reporter/menu-item-help.component';

import { UserDepartmentSelectComponent } from './base/user-department-select/user-department-select.component';
import { legacyProviders } from './app-legacy.module';

const dbConfig: DBConfig = {
  name: 'timecount',
  version: 1,
  objectStoresMeta: [
    {
      store: 'cache',
      storeConfig: { keyPath: 'key', autoIncrement: false },
      storeSchema: [
        { name: 'data', keypath: 'data', options: { unique: false } },
        { name: 'timeout', keypath: 'timeout', options: { unique: false } },
        { name: 'meta', keypath: 'meta', options: { unique: false } },
      ],
    },
    {
      store: 'settings',
      storeConfig: { keyPath: 'key', autoIncrement: false },
      storeSchema: [
        { name: 'data', keypath: 'data', options: { unique: false } },
      ],
    },
  ],
};

@NgModule({
  declarations: [
    AppComponent,
    DispoComponent,
    DispoDateComponent,
    DispoTasksComponent,
    DTScrollComponent,
    DTPaginateComponent,
    DTDayComponent,
    DTPlanComponent,
    DTTaskComponent,
    DispoViewSettingsComponent,
    DispoSchedulesComponent,
    DSScheduleComponent,
    DSPlanComponent,
    DispoContextMenuComponent,
    DispoActionMenuComponent,
    DispoTaskComponent,
    DTMoveComponent,
    DTMoveScheduledComponent,
    DTGeneralComponent,
    DTPublicComponent,
    DTCreateComponent,
    DTDeleteComponent,
    DTSlotsComponent,
    DTInvitationsComponent,
    DispoConflictResolverComponent,
    MarkdownComponent,
    TrackStartDateDirective,
    TrackStartTimeDirective,
    TrackEndTimeDirective,
    TrackIntermissionStartTimeDirective,
    TrackIntermissionEndTimeDirective,
    TrackTimeDirective,
    TrackDurationDirective,
    ToggleComponent,
    DispoPlanComponent,
    DPGeneralComponent,
    DPCreateComponent,
    DPMoveComponent,
    DPDeleteComponent,
    DispoScheduleComponent,
    DSCreateComponent,
    DSCStep1Component,
    DSCStep2Component,
    DSCStep3Component,
    DSCStep4Component,
    DSCStep5Component,
    DSDeleteComponent,
    DSGeneralComponent,
    DSSlotsComponent,
    DSTimesComponent,
    DSDaysComponent,
    DSPublicComponent,
    DSMoveComponent,
    DispoAvailabilityComponent,
    DAVRangeComponent,
    MenuItemHelpComponent,
    DAVIntervalComponent,
    DispoAssignmentComponent,
    DAGeneralComponent,
    DAInfoComponent,
    DADeleteComponent,
    DAAvailComponent,
    DATimebalanceComponent,
    DispoAnnouncementsComponent,
    DANPlanComponent,
    DANAnnounceComponent,
    DispoAnnouncementComponent,
    DANInvitationsComponent,
    DANGeneralComponent,
    DANInviteComponent,
    TcDepartmentInfoComponent,
    DANAppendTasksComponent,
    DANAppendSlotsComponent,
    DANDeleteComponent,
    DANCreateComponent,
    DANDetachTasksComponent,
    DispoMessageComponent,
    DMSGCreateComponent,
    DMSGMultiComponent,
    DMSGCStep1Component,
    DMSGCStep2Component,
    TcTransferComponent,
    DispoImportComponent,
    DIMStep1Component,
    DIMStep2Component,
    DIMStep3Component,
    DIMStep4Component,
    DIMButtonComponent,
    DispoInvitationComponent,
    DINDeleteComponent,
    DAContractorComponent,
    DAEmployeeComponent,
    PinComponent,
    PIGeneralComponent,
    ObserveVisibilityDirective,
    ValueSetsBtnComponent,
    ValueSetComponent,
    VSGeneralComponent,
    TimebalanceDocumentComponent,
    TBDOCButtonComponent,
    TBDOCStep1Component,
    TBDOCStep2Component,
    HideDirective,
    DispoAssignmentsComponent,
    DASPlanComponent,
    DASTaskComponent,
    DASScheduleComponent,
    DASProjectComponent,
    DASAssignmentComponent,
    DASTimesheetComponent,
    DASTimesheetGroupComponent,
    TimesheetComponent,
    TSGeneralComponent,
    TSMultiComponent,
    TcTourComponent,
    UserDepartmentSelectComponent,
    FinancesEntriesComponent,
    FinancesEntriesPlanComponent,
    FinancesEntriesTaskComponent,
    FinancescheduleComponent,
    FinancesEntriesProjectComponent,
    FinancesEntriesWorkEntryComponent,
    FinancesEntriesWorkEntryGroupComponent,
    FinancesEntriesServiceEntryComponent,
    FinancesEntriesServiceEntryGroupComponent,
    TcSmtpConfigFormComponent,
    TcCustomEmailHeadersComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NoopAnimationsModule,
    UpgradeModule,
    CommonModule,

    SharedModule,

    // This needs to be moved to the DispoModule, when created.
    DispoSharedModule,

    PostsModule,
    TcHubProjectsModule,
    TcHubEmployeesModule,

    AppRoutingModule,
    AngularSplitModule,
    ScrollingModule,
    DragDropModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    NgXCableModule,
    HotkeyModule.forRoot(),
    MenuModule,
    PanelMenuModule,
    AutosizeModule,
    TcPrimengModule,

    // TODO: Move to Forms library
    TcDropdownsModule,
    TcSelectsModule,

    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: MarkdownOptionsFactory,
      },
    }),

    // Dispo Modules
    DispoEmployeesModule,
  ],
  providers: [...legacyProviders],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public upgrade: UpgradeModule) {}
}
