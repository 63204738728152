import { NgModuleRef } from '@angular/core';
import {
  downgradeComponent,
  downgradeInjectable,
  setAngularJSGlobal,
} from '@angular/upgrade/static';
import * as angular from 'angular';

import { TcConfigService } from '@timecount/core';
import {
  TcFieldsetIntervalSetComponent,
  TcFieldSetIntervalSetService,
  TcMapComponent,
  TcTimesSummaryService,
} from '@timecount/ui';
import { DurationPipe } from '@timecount/utils';
import { TcFormulasService } from '@timecount/config';

import { DataPanelComponent } from './base/data-panel/data-panel.component';
import { UserDepartmentSelectComponent } from './base/user-department-select/user-department-select.component';
import { Cable } from './core/cable';
import { TcLinksService } from './core/links.service';
import { DIMButtonComponent } from './dispo/dispo-import/button/button.component';
import { DispoComponent } from './dispo/dispo.component';
import { FieldsetRateComponent } from './shared/fieldsets/fieldset-rate/fieldset-rate.component';
import { PinsButtonComponent } from './shared/pins-button/pins-button.component';
import { TBDOCButtonComponent } from './timebalances/document/button/button.component';
import { ValueSetsBtnComponent } from './value-sets/value-sets-btn.component';
import { MenuItemHelpComponent } from './base/issue-reporter/menu-item-help.component';
import { AppModule } from './app.module';
import { TcHubPostsWidgetComponent } from './posts';
import { TcOptionsService } from './core/options.service';
import {
  AggregationWorkEntriesService,
  ProjectCostsService,
  TcProjectCostsFormComponent,
  TcWorkEntriesFormComponent,
} from './projects';
import { TcServiceEntryFormComponent } from './projects/service-entry/service-entry-form/service-entry-form.component';
import { ProjectServiceEntryService } from './projects/service-entry/service-entry.service';
import { EmployeeBiasesService } from './employees/employee-biases.service';
import { ProjectTimesheetCollection } from './timesheets/project-timesheet.collection';
import { WorkEntryCollection } from './projects/aggregations/work-entries/work_entry.collection';
import { ServiceEntryCollection } from './projects/service-entry/service-entry.collection';
import { EmployeeBiasCollection } from './employees/employee-biases.collection';
import { WorkEntriesBatchService } from './projects/aggregations/work-entries/work-entries-batch/work-entries-batch.service';
import { AggregationService } from './projects/aggregations/aggregations.service';
import { AggregationCollection } from './projects/aggregations/aggregation.collection';
import { TcAggregationsBaseFormComponent } from './projects/aggregations/base-form/aggregations-base-form.component';
import { TcAggregationsImportFormComponent } from './projects/aggregations/aggregations-import-form/aggregations-import-form.component';
import { TcAggregationsLayoutFormComponent } from './projects/aggregations/layout-form/aggregations-layout-form.component';
import { TcAggregationsConditionsFormComponent } from './projects/aggregations/conditions-form/aggregations-conditions-form.component';
import { JobRatesService } from './shared/job-rates-form/job-rates.service';
import { JobRateCollection } from './core/collections/job-rate.collection';
import { TcSmtpConfigFormComponent } from './preferences/apis/smtp-config-form/smtp-config-form.component';
import { TcCustomEmailHeadersComponent } from './preferences/apis/custom-email-headers/custom-email-headers.component';
import { LocalSettingsService } from './core/local-settings.service';

// Set Up AngularJS
declare const angular: any;
setAngularJSGlobal(angular);

// Get Legacy module
const TcLegacyAppModule = angular.module('tc');

// Legacy bootstrap handler for the AppModule
export const legacyBootstrapHandler = (ref: NgModuleRef<AppModule>) => {
  ref.instance.upgrade.bootstrap(
    document.documentElement,
    [TcLegacyAppModule.name],
    { strictDi: true },
  );
};

// -----------------------------------------------------------------------------
// Downgraded Components and Services
// -----------------------------------------------------------------------------

TcLegacyAppModule.service('Cable', downgradeInjectable(Cable) as any)
  .service(
    'IntervalSetService',
    downgradeInjectable(TcFieldSetIntervalSetService),
  )
  .service('ConfigService', downgradeInjectable(TcConfigService))
  .service('ProjectCostsService', downgradeInjectable(ProjectCostsService))
  .service('EmployeeBiasesService', downgradeInjectable(EmployeeBiasesService))
  .service(
    'AggregationWorkEntriesService',
    downgradeInjectable(AggregationWorkEntriesService),
  )
  .service(
    'ProjectServiceEntryService',
    downgradeInjectable(ProjectServiceEntryService),
  )
  .service('LinksService', downgradeInjectable(TcLinksService))
  .service('SummaryService', downgradeInjectable(TcTimesSummaryService))
  .service('OptionsService', downgradeInjectable(TcOptionsService))
  .service('DurationPipe', downgradeInjectable(DurationPipe))
  .service('JobRatesService', downgradeInjectable(JobRatesService))
  .service(
    'ProjectTimesheetCollection',
    downgradeInjectable(ProjectTimesheetCollection),
  )
  .service('WorkEntryCollection', downgradeInjectable(WorkEntryCollection))
  .service('JobRateCollection', downgradeInjectable(JobRateCollection))
  .service('AggregationCollection', downgradeInjectable(AggregationCollection))
  .service('LocalSettingsService', downgradeInjectable(LocalSettingsService))
  .service(
    'ServiceEntryCollection',
    downgradeInjectable(ServiceEntryCollection),
  )
  .service(
    'WorkEntriesBatchService',
    downgradeInjectable(WorkEntriesBatchService),
  )
  .service('AggregationService', downgradeInjectable(AggregationService))
  .service(
    'EmployeeBiasCollection',
    downgradeInjectable(EmployeeBiasCollection),
  )
  .service('FormulasService', downgradeInjectable(TcFormulasService))
  .directive(
    'tcHubDashboardPosts',
    downgradeComponent({
      component: TcHubPostsWidgetComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubProjectCostsForm',
    downgradeComponent({
      component: TcProjectCostsFormComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubSmtpConfigForm',
    downgradeComponent({
      component: TcSmtpConfigFormComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubCustomEmailHeaders',
    downgradeComponent({
      component: TcCustomEmailHeadersComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubWorkEntriesForm',
    downgradeComponent({
      component: TcWorkEntriesFormComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubServiceEntryForm',
    downgradeComponent({
      component: TcServiceEntryFormComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubAggregationBaseForm',
    downgradeComponent({
      component: TcAggregationsBaseFormComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubAggregationLayoutForm',
    downgradeComponent({
      component: TcAggregationsLayoutFormComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubAggregationConditionsForm',
    downgradeComponent({
      component: TcAggregationsConditionsFormComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubAggregationImportForm',
    downgradeComponent({
      component: TcAggregationsImportFormComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcIntervalSetWeb',
    downgradeComponent({
      component: TcFieldsetIntervalSetComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubFieldsetRate',
    downgradeComponent({
      component: FieldsetRateComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubMap',
    downgradeComponent({
      component: TcMapComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubUserDepartmentSelect',
    downgradeComponent({
      component: UserDepartmentSelectComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubMenuItemHelp',
    downgradeComponent({
      component: MenuItemHelpComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubDispo',
    downgradeComponent({
      component: DispoComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubDispoImportButton',
    downgradeComponent({
      component: DIMButtonComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubPinsButton',
    downgradeComponent({
      component: PinsButtonComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubDataPanel',
    downgradeComponent({
      component: DataPanelComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubValueSetsBtn',
    downgradeComponent({
      component: ValueSetsBtnComponent,
    }) as angular.IDirectiveFactory,
  )
  .directive(
    'tcHubTimebalanceDocumentButton',
    downgradeComponent({
      component: TBDOCButtonComponent,
    }) as angular.IDirectiveFactory,
  );

// -----------------------------------------------------------------------------
// Upgraded Services
// -----------------------------------------------------------------------------

export const FLASH_SERVICE = 'Flash';

export function flashServiceFactory(i: any) {
  return i.get('Flash');
}

export const flashServiceProvider = {
  provide: FLASH_SERVICE,
  useFactory: flashServiceFactory,
  deps: ['$injector'],
};

export const API_ERROR_SERVICE = 'ApiError';

export function apiErrorServiceFactory(i: any) {
  return i.get('ApiError');
}

export const apiErrorServiceProvider = {
  provide: API_ERROR_SERVICE,
  useFactory: apiErrorServiceFactory,
  deps: ['$injector'],
};

export const RESOURCE_CONFIG_SERVICE = 'ResourceConfig';

export function resourceConfigServiceFactory(i: any) {
  return i.get('ResourceConfig');
}

export const resourceConfigServiceProvider = {
  provide: RESOURCE_CONFIG_SERVICE,
  useFactory: resourceConfigServiceFactory,
  deps: ['$injector'],
};

export const EMPLOYEE_INFO_COLUMNS_SERVICE = 'EmployeeInfoColumns';

export function employeeInfoColumnsServiceFactory(i: any) {
  return i.get('EmployeeInfoColumns');
}

export const employeeInfoColumnsServiceProvider = {
  provide: EMPLOYEE_INFO_COLUMNS_SERVICE,
  useFactory: employeeInfoColumnsServiceFactory,
  deps: ['$injector'],
};

export const ATTACHMENT_RELEASE_SERVICE = 'AttachmentReleaseMessage';

export function attachmentReleasesServiceFactory(i: any) {
  return i.get('AttachmentReleaseMessage');
}

export const attachmentReleasesServiceProvider = {
  provide: ATTACHMENT_RELEASE_SERVICE,
  useFactory: attachmentReleasesServiceFactory,
  deps: ['$injector'],
};

export const legacyProviders = [
  flashServiceProvider,
  apiErrorServiceProvider,
  employeeInfoColumnsServiceProvider,
  resourceConfigServiceProvider,
  attachmentReleasesServiceProvider,
];
