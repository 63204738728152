import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { TcInputValidators } from '@timecount/ui';

import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';
import { DispoAssignmentCollection } from '../../collections/assignment';
import { Task } from '../../collections/task';

@Component({
  selector: 'tc-hub-dan-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class DANCreateComponent implements OnInit {
  @Input() announcement: Announcement;
  @Input() tasks: Task[] = [];
  @Output() signal: EventEmitter<any> = new EventEmitter();

  private translations: { [key: string]: string } = {};

  form: UntypedFormGroup;
  taskSlots = [];
  loading = false;

  constructor(
    private announcementCollection: DispoAnnouncementCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.translateService
      .get(['dispo/announcement.attrs.slot_pos'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnInit(): void {
    if (this.tasks[0]) {
      this.announcement.plan_id = this.tasks[0].plan_id;
      this.announcement.schedule_id = this.tasks[0].schedule_id;
      this.announcement.venue_id = this.tasks[0].venue_id;
      this.announcement.task_ids = this.tasks.map((t) => t.id);
    }

    const maxTaskSize = Math.max(...this.tasks.map((t) => t.size));
    this.taskSlots = [...Array(maxTaskSize).keys()].map((pos) => {
      return {
        id: pos,
        title: `${this.translations['dispo/announcement.attrs.slot_pos']} ${
          pos + 1
        }`,
      };
    });

    this.form = this.buildForm();
  }

  toggleSize() {
    const auto_size = this.form.controls.auto_size.value;
    if (auto_size) {
      this.form.controls['size'].setValue(0);
      this.form.controls['size'].disable();
    } else {
      this.form.controls['size'].setValue(1);
      this.form.controls['size'].enable();
    }
  }

  create() {
    const newValues = Object.assign({}, this.announcement, this.form.value);

    this.loading = true;

    this.assignmentCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (assignments) => {
          const sizes = {};

          this.tasks.forEach((task) => {
            let size;

            if (!newValues.size) {
              const taskAssignments = assignments.filter(
                (a) =>
                  a.task_id === task.id &&
                  a.position < task.size &&
                  (newValues.task_slots.length === 0 ||
                    newValues.task_slots.indexOf(a.position) !== -1),
              );

              const taskSlots =
                newValues.task_slots.length !== 0
                  ? task.slots.filter(
                      (s) => newValues.task_slots.indexOf(s) !== -1,
                    )
                  : task.slots;

              size = taskSlots.length - taskAssignments.length;
            } else {
              size = newValues.size;
            }

            sizes[task.date] = size;
          });

          newValues.sizes = sizes;
          newValues.size = newValues.size || 0;

          this.announcementCollection.create(newValues).subscribe({
            next: (announcement: Announcement) => {
              this.loading = false;
              this.signal.emit({
                action: 'reload',
                items: {
                  single: false,
                  section: 'invite',
                  selectedIndex: 1,
                  announcement: announcement,
                },
              });
            },
            error: () => {
              this.loading = false;
            },
          });
        },
      });
  }

  private buildForm() {
    return this.formBuilder.group({
      title: [this.announcement.title, [Validators.required]],
      plan_id: [
        { value: this.announcement.plan_id, disabled: true },
        [Validators.required],
      ],
      venue_id: [this.announcement.venue_id],
      task_slots: [this.announcement.task_slots],
      auto_size: [!this.announcement.size],
      size: [
        { value: this.announcement.size, disabled: !this.announcement.size },
        [
          Validators.required,
          TcInputValidators.minMaxNumber(
            0,
            window.config.company.dispo_announcement_max_size,
          ),
        ],
      ],
      push_notifications: [this.announcement.push_notifications],
      description: [this.announcement.description],
      state: [this.announcement.state, [Validators.required]],
      message_template_id: [null],
    });
  }
}
