import { Injectable } from '@angular/core';
import { AsyncSubject, combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { debug, dig, naturalSort } from '../../core/helpers';
import { LocalSettingsService } from '../../core/local-settings.service';
import { RemoteConfig } from '../../core/remote_config.service';
import { renderPartial, siftConfig, siftFilter } from '../../core/sift';
import { DispoFocusService } from '../../dispo/dispo-focus.service';
import { DispoSelectorService } from '../../dispo/selector/selector.service';

@Injectable({
  providedIn: 'root',
})
export class DispoFilterService {
  private selection = {};
  private queries = [];
  private presets = [];
  private focusRange = [];

  private queriesLoaded = new AsyncSubject();

  constructor(
    private localSettings: LocalSettingsService,
    private selectorService: DispoSelectorService,
    private remoteConfig: RemoteConfig,
    private focusService: DispoFocusService,
  ) {
    remoteConfig
      .get('data_query')
      .pipe(first())
      .subscribe({
        next: (queries) => {
          this.queries = queries;
          this.queriesLoaded.next(queries);
          this.queriesLoaded.complete();
        },
      });

    remoteConfig
      .get('filter_preset')
      .pipe(first())
      .subscribe({
        next: (presets) => {
          this.presets = presets;
        },
      });

    this.focusService.range().subscribe({
      next: (range) => {
        this.focusRange = range;
      },
    });
  }

  // request a filter trigger -> will trigger setQuery / setFilter
  public requestFilter(section, filterRequest) {
    this.localSettings.set(
      'filter.' + section + '.request',
      filterRequest,
      true,
    );
  }

  public recoverFilter(section) {
    const lastSort = this.localSettings.recover('sort.' + section);
    const lastFilter = this.localSettings.recover('filter.' + section) || '{}';

    this.localSettings.set('filter.' + section + '.request', {});

    try {
      this.queriesLoaded.subscribe(() => {
        this.requestFilter(section, JSON.parse(lastFilter));
        this.setSort(section, JSON.parse(lastSort));
      });
    } catch (error) {
      console.error(error);
      this.setQuery(section, {});
      this.setSort(section, undefined);
    }
  }

  // request a preset -> will trigger setQuery / setFilter
  public setPreset(preset) {
    const presetConfig = this.presets.find((c) => c.id === preset);
    const dataSource =
      this.getSelection(presetConfig.source || presetConfig.key) || {};
    const formObject = renderPartial(presetConfig.filters, dataSource);

    // standalone filters with one target only apply to current screen
    if (presetConfig.standalone && presetConfig.targets === 1) {
      this.localSettings.set(
        'filter.' + presetConfig.targets[0] + '.request',
        formObject,
      );
      // all other filters are global to support multiscreens
    } else {
      presetConfig.targets.forEach((target) =>
        this.requestFilter(target, formObject),
      );
    }
  }

  // request a sort -> will trigger
  public setSort(section, sort) {
    this.localSettings.set('sort.' + section, sort);
    // const presetConfig = this.presets.find((c) => c.id === sort);
    // const dataSource =
    //   this.getSelection(presetConfig.source || presetConfig.key) || {};
    // const formObject = renderPartial(presetConfig.filters, dataSource);

    // presetConfig.targets.forEach((target) =>
    //   this.requestFilter(target, formObject)
    // );
  }

  public getSort(section, target) {
    return combineLatest(
      this.getDefaultSort(section),
      this.localSettings.get('sort.' + section),
      this.localSettings.get('filter.' + section),
    ).pipe(
      map(([defaultSort, sort, filter]) => {
        if (sort) {
          return this.sortConfig(sort.sorts[target], filter);
        } else {
          return this.sortConfig(defaultSort.sorts[target], filter);
        }
      }),
    );
  }

  public getFilter(section) {
    return this.localSettings.get('filter.' + section, {});
  }

  // listen do get updated sift query
  public getQuery(section): Observable<any> {
    return this.localSettings
      .get('filter.' + section + '.sift', this.siftConfig(section, {}))
      .pipe(map((q) => siftFilter(q)));
  }

  public queryEmpty(section): Observable<boolean> {
    return this.localSettings
      .get('filter.' + section + '.sift', this.siftConfig(section, {}))
      .pipe(map((q) => q?.$and?.length === 0 || q?.$or?.length === 0));
  }

  public getPresets(section): Observable<any> {
    return this.remoteConfig.get('filter_preset', section).pipe(
      map((presets: any[]) => {
        return presets.filter((p) => !p.standalone);
      }),
    );
  }

  public getSorts(section): Observable<any> {
    return this.remoteConfig.get('filter_sort', section);
  }

  public getDefaultSort(section): Observable<any> {
    return this.remoteConfig.get('filter_sort', section).pipe(
      map((sorts: any[]) => {
        return (
          sorts.find((s) => s.default) || {
            label: '',
            sorts: {},
            default: true,
          }
        );
      }),
    );
  }

  public getStandAlonePresets(section): Observable<any> {
    return this.remoteConfig.get('filter_preset', section).pipe(
      map((presets: any[]) => {
        return presets.filter((p) => p.standalone);
      }),
    );
  }

  // internal getter only used by filter component
  getSchema(section): Observable<any> {
    return this.remoteConfig.getItem('schema', section);
  }

  // internal getter only used by filter component
  getFilterRequest(section) {
    return this.localSettings.get('filter.' + section + '.request');
  }

  // internal setter only used by filter component
  setQuery(section, filter) {
    filter = Object.assign({}, filter);
    filter.focus = {
      valid: true,
      starts_at: this.focusRange[0] * 1000,
      ends_at: this.focusRange[1] * 1000,
    };

    // remember last filter
    this.localSettings.set('filter.' + section, filter);

    debug('Filter Model', section, filter);
    const config = this.siftConfig(section, filter);
    debug('Sift', section, config);
    this.localSettings.set('filter.' + section + '.sift', config);
  }

  // internal
  getSelection(section) {
    return this.selectorService.getSelection(section);
  }

  private siftConfig(key, filter) {
    const baseConfig = this.queries.find((q) => q.key === key) || {
      chain: '$and',
      queries: [],
    };

    return siftConfig(baseConfig, filter);
  }

  private sortConfig(sortCriteria, filter) {
    const sortConfig = (sortCriteria || ['id']).map((s) => s.split(' '));

    return (a, b) => {
      return sortConfig.reduce((comparator: number, [attr, sortDir]) => {
        if (comparator === 0) {
          if (attr === 'bias') {
            comparator = this.compareBiases(a, b, filter);
          } else {
            comparator = this.compareAttrs(a, b, attr);
          }

          comparator =
            comparator && sortDir === 'DESC' ? comparator * -1 : comparator;
        }

        return comparator;
      }, 0);
    };
  }

  private compareBiases(a, b, biasSettings) {
    let comparator;
    let valA = 0;
    let valB = 0;

    ['Customer', 'Venue', 'Qualification', 'Job'].forEach((section) => {
      valA += a.biases
        .filter(
          (bias) =>
            bias.target_type === section &&
            (biasSettings[`${section.toLowerCase()}_bias_ids`] || []).includes(
              bias.target_id,
            ),
        )
        .reduce((partialSum, bias) => partialSum + bias['impact_numeric'], 0);

      valB += b.biases
        .filter(
          (bias) =>
            bias.target_type === section &&
            (biasSettings[`${section.toLowerCase()}_bias_ids`] || []).includes(
              bias.target_id,
            ),
        )
        .reduce((partialSum, bias) => partialSum + bias['impact_numeric'], 0);
    });

    if (valA === valB) {
      return 0;
    }

    if (valA > valB) {
      comparator = 1;
    } else if (valA < valB) {
      comparator = -1;
    }

    return comparator;
  }

  private compareAttrs(a, b, attr) {
    let comparator;
    let valA = dig(a, attr);
    let valB = dig(b, attr);

    if (valA === valB) {
      return 0;
    }

    // fix comparison with undefined
    if (typeof valA === 'number' || typeof valB === 'number') {
      if (typeof valA !== 'number') {
        valA = parseFloat(valA) || -Infinity;
      }
      if (typeof valB !== 'number') {
        valB = parseFloat(valB) || -Infinity;
      }

      if (valA > valB) {
        comparator = 1;
      } else if (valA < valB) {
        comparator = -1;
      }
    } else if (valA instanceof Date || valB instanceof Date) {
      if (!(valA instanceof Date)) {
        valA = new Date('');
      }
      if (!(valB instanceof Date)) {
        valB = new Date('');
      }

      valA = valA.getTime();
      valB = valB.getTime();

      if (valA > valB) {
        comparator = 1;
      } else if (valA < valB) {
        comparator = -1;
      }
    } else {
      comparator = naturalSort(valA, valB);
    }

    return comparator;
  }
}
