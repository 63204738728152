<tc-form-wrapper
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  [shouldShowPanel]="false"
  (primaryButtonClick)="save()"
>
  <form [formGroup]="formGroup">
    <fieldset
      [tcPanel]
      [tcTitle]="'aggregation.headers.vat' | translate"
      tcContentClass="tc-grid tc-grid-1-col"
    >
      <div>
        <label class="vat-checkbox" nz-checkbox formControlName="vat_included">
          {{ 'aggregation.attrs.vat_included' | translate }}
        </label>
        <label nz-checkbox formControlName="reverse_charge">
          {{ 'aggregation.attrs.reverse_charge' | translate }}
        </label>
      </div>
      <tc-input-text-web
        [label]="'aggregation.attrs.vat_identification' | translate"
        formControlName="vat_identification"
      ></tc-input-text-web>
    </fieldset>

    <fieldset [tcPanel] [tcTitle]="'aggregation.headers.term' | translate">
      <tc-input-number-web
        [label]="'aggregation.attrs.term' | translate"
        formControlName="term"
      >
      </tc-input-number-web>
    </fieldset>

    <fieldset
      *ngIf="hasAccessToXrechnung"
      [tcPanel]
      [tcTitle]="'aggregation.headers.xrechnung' | translate"
      tcContentClass="tc-grid tc-grid-2-col"
    >
      <tc-hub-option-select
        class="tc-col-12"
        [label]="'aggregation.attrs.xre_bt3' | translate"
        type="aggregation.xre_bt3"
        [formGroup]="formGroup"
        controlName="xre_bt3"
        bindLabel="title"
        bindValue="id"
      >
      </tc-hub-option-select>

      <tc-input-text-web
        [label]="'aggregation.attrs.xre_bt10' | translate"
        formControlName="xre_bt10"
      ></tc-input-text-web>

      <tc-input-text-web
        [label]="'aggregation.attrs.xre_bt12' | translate"
        formControlName="xre_bt12"
      ></tc-input-text-web>

      <tc-input-text-web
        [label]="'aggregation.attrs.xre_bt11' | translate"
        formControlName="xre_bt11"
      ></tc-input-text-web>

      <tc-input-text-web
        [label]="'aggregation.attrs.xre_bt13' | translate"
        formControlName="xre_bt13"
      ></tc-input-text-web>

      <tc-input-text-web
        [label]="'aggregation.attrs.xre_bt89' | translate"
        formControlName="xre_bt89"
      ></tc-input-text-web>

      <tc-input-text-web
        class="tc-col-wrap"
        [label]="'aggregation.attrs.xre_bt44' | translate"
        formControlName="xre_bt44"
      ></tc-input-text-web>

      <tc-input-text-web
        [label]="'aggregation.attrs.xre_bt51' | translate"
        formControlName="xre_bt51"
      ></tc-input-text-web>

      <tc-input-text-web
        [label]="'aggregation.attrs.xre_bt163' | translate"
        formControlName="xre_bt163"
      ></tc-input-text-web>

      <tc-input-text-web
        [label]="'aggregation.attrs.xre_bt55' | translate"
        formControlName="xre_bt55"
      ></tc-input-text-web>

      <tc-hub-custom-fields
        class="tc-col-12"
        section="aggregation.conditions.xrechnung"
        formControlName="aggregationConditionsXrechnung"
      >
      </tc-hub-custom-fields>
    </fieldset>

    <tc-hub-custom-fields
      section="aggregation.{{ tcInitialValue.type.handle }}.conditions"
      formControlName="aggregationHandleConditions"
    >
    </tc-hub-custom-fields>

    <tc-hub-custom-fields
      section="aggregation.conditions"
      formControlName="aggregationConditions"
    >
    </tc-hub-custom-fields>
  </form>
</tc-form-wrapper>
