<div class="modal-container">
  <div class="modal-body"></div>
  <div class="modal-footer">
    <button type="button" nz-button nzType="secondary" (click)="cancel()">
      {{ 'cancel' | translate }}
    </button>
    <button type="button" nz-button nzType="primary" (click)="delete()">
      {{ 'delete' | translate }}
    </button>
  </div>
</div>
