<ng-container *ngIf="isModal; else noModalWrapper">
  <div class="tc-modal-body" #modalBody>
    <div class="modal-container">
      <div slot="body" class="modal-body">
        <ng-container *ngTemplateOutlet="form"></ng-container>
      </div>
      <div slot="footer" class="modal-footer">
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </div>
    </div>
  </div>
  <div class="tc-modal-footer" #modalFooter></div>
</ng-container>

<ng-template #noModalWrapper>
  <tc-panel
    [tcNoPadding]="tcNoPadding"
    *ngIf="shouldShowPanel; else form"
    [tcTitle]="tcFormTitle"
  >
    <nz-segmented
      *ngIf="tcExtended !== undefined"
      tcHeaderEnd
      [ngModel]="tcExtended | booleanToBinary"
      [nzOptions]="['base' | translate, 'extended' | translate]"
      (nzValueChange)="onExtendedChange($event)"
    ></nz-segmented>

    <ng-container tcHeaderEnd>
      <ng-content select="[tcFormExtras]"></ng-content>
    </ng-container>

    <ng-container *ngTemplateOutlet="form"></ng-container>
    <div
      *ngIf="
        primaryButtonClick.observers.length > 0 ||
        secondaryButtonClick.observers.length > 0
      "
      tcFooterEnd
    >
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
  </tc-panel>
</ng-template>

<ng-template #form>
  <ng-container *ngIf="!isLoading; else loading">
    <ng-content></ng-content>
  </ng-container>

  <ng-template #loading>
    <label class="loading-label">{{ 'loading' | translate }}</label>
  </ng-template>
</ng-template>

<ng-template #buttons>
  <button
    *ngIf="secondaryButtonClick.observers.length > 0"
    type="button"
    nz-button
    nzType="secondary"
    [nzLoading]="isSubmitting"
    [disabled]="isLoading || (disableSecondaryButtonOnInvalid && isInvalid)"
    (click)="secondaryButtonClick.emit($event)"
  >
    <span nz-icon nzType="tc:delete"></span
    >{{ secondaryButtonLabelKey | translate }}
  </button>
  <button
    type="button"
    class="save-btn"
    nz-button
    nzType="primary"
    [nzLoading]="isSubmitting"
    [disabled]="isLoading || isInvalid"
    (click)="primaryButtonClick.emit($event)"
  >
    <span nz-icon nzType="tc:save"></span
    >{{ (isBatch ? 'update' : primaryButtonLabelKey) | translate }}
  </button>
</ng-template>
