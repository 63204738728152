import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  defaultIfEmpty,
  finalize,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';

import { DispoPlanCollection, Plan } from '../../collections/plan';
import { DispoTaskCollection } from '../../collections/task';
import { DispoScheduleCollection } from '../../collections/schedule';
import { DeletableResponse } from '../../../core/types/deletable-response.model';

// TODO: component currently checks only for deletable tasks
// should also check for deletable announcements and schedules

@Component({
  selector: 'tc-hub-dp-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css'],
})
export class DPDeleteComponent implements OnInit {
  @Input() plan: Plan;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  loading = true;
  deletable = false;
  messages: string[] = [];
  taskNonDeletables = [];

  constructor(
    private taskCollection: DispoTaskCollection,
    private planCollection: DispoPlanCollection,
    private scheduleCollection: DispoScheduleCollection,
  ) {}

  ngOnInit() {
    const deletableTasks$ = this.taskCollection.forPlan(this.plan).pipe(
      map((tasks) => {
        if (tasks.length === 0) {
          return [of({ accepted: true })];
        } else {
          return tasks.map((t) => this.taskCollection.deletable(t.id, t));
        }
      }),
      switchMap((deletables$: Observable<DeletableResponse>[]) =>
        combineLatest(deletables$),
      ),
      map((taskDeletables) => {
        return taskDeletables
          .filter((t) => !t.accepted)
          .map((deletable) => {
            deletable.messages = Object.values(deletable.messages);
            return deletable;
          });
      }),
    );

    combineLatest([
      deletableTasks$,
      this.planCollection.deletable(this.plan.id, this.plan),
      this.scheduleCollection.forPlan(this.plan),
    ])
      .pipe(first())
      .subscribe({
        next: ([deletableTasks, deletable, schedules]) => {
          this.loading = false;
          this.deletable =
            deletableTasks.length === 0 && schedules.length === 0;
          this.taskNonDeletables = deletableTasks;
          this.messages = Object.values(deletable.messages);

          this.signal.emit({ action: 'resize' });
        },
      });
  }

  cancel() {
    this.signal.emit({ action: 'close' });
  }

  delete() {
    if (!this.deletable) {
      return;
    }

    this.loading = true;

    this.taskCollection
      .forPlan(this.plan)
      .pipe(
        first(),
        switchMap((tasks) =>
          forkJoin([
            ...tasks.map((task) => this.taskCollection.delete(task.id, task)),
          ]).pipe(defaultIfEmpty(null)),
        ),
        finalize(() => {
          this.loading = false;
          this.signal.emit({ action: 'close' });
        }),
      )
      .subscribe(() => {
        this.planCollection.delete(this.plan.id, this.plan);
      });
  }
}
