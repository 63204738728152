import { Component, HostListener, Input } from '@angular/core';

import { FinancesEntriesService } from '../finances-entries.service';
import { FinancesEntriesItemComponent } from '../item.component';

@Component({
  selector: 'tc-hub-finances-entries-work-entry-group',
  templateUrl: './work_entry-group.component.html',
  styleUrls: ['./work_entry-group.component.scss'],
})
export class FinancesEntriesWorkEntryGroupComponent extends FinancesEntriesItemComponent {
  @Input() item: any;

  constructor(protected service: FinancesEntriesService) {
    super(service);
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    const targetButton = !!$event
      .composedPath()
      .find((item) => item.nodeName?.toLowerCase() === 'button');

    if ($event.type === 'mouseup' && $event.button === 2) {
      return;
    }
    if (targetButton) {
      return;
    }

    const nextState = $event.type === 'contextmenu' ? true : !this.item.checked;
    this.selectItem(this.item.type, this.item.id, nextState, $event);
  }
}
