import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash-es';

import { ModalService } from '@timecount/ui';

import { ValidationService } from '../../dispo/collections/validation.service';
import { Action } from '../../core/types/action';
import { EntryType } from '../../core/types/entry-type';
import { ActionType } from '../../core/types/action-type';
import { JobRateCollection } from '../../core/collections/job-rate.collection';
import { JobRate } from '../../core/types/job-rate.model';
import { ZoneGroup } from '../../core/types/zone-group.model';

import { TcJobRatesFormComponent } from './job-rates-form.component';

@Injectable({
  providedIn: 'root',
})
export class JobRatesService {
  private translations: { [key: string]: string } = {};
  constructor(
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _validationService: ValidationService,
    private _jobRateCollection: JobRateCollection,
  ) {
    this._translateService
      .get(['modal.create', 'modal.edit', 'modal.clone'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  openModal(
    object: JobRate,
    zoneGroups: ZoneGroup[],
    action: 'create' | 'edit' | 'clone',
  ) {
    const modalObject = cloneDeep(object);

    if (action === 'clone') {
      delete modalObject.id;
    }

    const data = {
      tcInitialValue: modalObject,
      zoneGroups,
      isModal: true,
    };

    this._modalService.open(TcJobRatesFormComponent, {
      data,
      modalTitle: this.translations['modal.' + action],
    });
  }

  delete(object: JobRate) {
    const action = <Action>{
      id: uuid(),
      entry: object,
      entry_type: EntryType.JobRate,
      type: ActionType.delete,
      validations: (item, stack) => {
        return this._jobRateCollection
          .forTypeAndId(object.parent_type, object.parent_id)
          .remoteValidations(item, stack, ActionType.delete);
      },
      errors: [],
    };
    this._validationService.run([action], true);
  }
}
