import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NzTimePickerComponent } from 'ng-zorro-antd/time-picker';

import { DateUnicodeFormat } from '@timecount/utils';

import { TcBasePickerDirective } from '../base/base-picker.directive';
import { TcDateTimePicker } from '../form-picker-datetime';

@Component({
  selector: 'tc-form-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TcTimePickerComponent
  extends TcBasePickerDirective
  implements TcDateTimePicker, AfterViewInit
{
  format = DateUnicodeFormat.time;

  limit: Interval;

  // To make sure that the Zorro picker won't select the current hour or minute
  // Only Hour and Minute are relevant
  defaultOpenValue = new Date('2015-10-21T00:00');

  @ViewChild('timePicker') timePickerComponent: NzTimePickerComponent;

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngAfterViewInit() {
    super.init(this.timePickerComponent);
  }

  // ---------------
  // Events Handlers
  // ---------------

  onModelChange(date: Date) {
    this.valueChange$.next(date);
  }
}
