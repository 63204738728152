import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import {
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';

import { SetCollection } from '../../core/sets-collection';
import { DispoPlanCollection, Plan } from '../collections/plan';
import { DispoScheduleCollection, Schedule } from '../collections/schedule';
import { DispoTaskCollection, Task } from '../collections/task';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../collections/announcement';
import { DispoAssignmentCollection } from '../collections/assignment';
import {
  Availability,
  DispoAvailabilityCollection,
} from '../collections/availability';
import {
  DispoInvitationCollection,
  Invitation,
  InvitationState,
} from '../collections/invitation';
import {
  QualificationAccount,
  QualificationAccountCollection,
} from '../../core/collections/qualification_account';
import {
  BlankCombinedSubtotalStat,
  CombinedSubtotalStat,
  DispoSubtotalCollection,
} from '../collections/subtotal';
import {
  dateToString,
  debugTap,
  integerToDate,
  overlaps,
} from '../../core/helpers';
import { DispoFocusService } from '../dispo-focus.service';
import {
  TimeBalance,
  TimebalanceCollection,
} from '../../timebalances/timebalance.collection';
import { Employee } from '../../employees/employee.model';
import { EmployeeCollection } from '../../employees/employee.collection';
import { ContractorCollection } from '../../contractors/contractor.collection';
import { BiasCollection } from '../../biases/bias.collection';
import { Bias } from '../../biases/bias.model';
import { Assignment } from '../collections/assignment.model';

import { AssignmentDS } from './assignment-ds.model';
import { InvitationDS } from './invitation-ds.model';

export class EmployeeValidationDS {
  assignments: AssignmentDS[];
  invitations: InvitationDS[];
  availabilities: Availability[];
  timebalances: TimeBalance[];
  qualifications: QualificationAccount[];
  biases: Bias[];
  subtotal: CombinedSubtotalStat[];
  subtotal_day: CombinedSubtotalStat[];
  subtotal_week: CombinedSubtotalStat[];
  subtotal_quarter: CombinedSubtotalStat[];
  subtotal_partial_quarter: CombinedSubtotalStat[];
  subtotal_year: CombinedSubtotalStat[];
  subtotal_partial_year: CombinedSubtotalStat[];

  resource: EmployeeDS[];
}

export class EmployeeDS extends Employee {
  tasks: Record<number, Task> = {};
  assignments: AssignmentDS[] = [];
  invitations: InvitationDS[] = [];
  availabilities: Availability[] = [];
  timebalances: TimeBalance[] = [];
  qualifications: QualificationAccount[] = [];
  qualification_ids: number[] = [];
  biases: Bias[] = [];

  subtotal?: CombinedSubtotalStat;
  subtotals: CombinedSubtotalStat[] = [];

  subtotal_day?: CombinedSubtotalStat;
  subtotals_day: CombinedSubtotalStat[] = [];

  subtotal_week?: CombinedSubtotalStat;
  subtotals_week: CombinedSubtotalStat[] = [];

  subtotal_quarter?: CombinedSubtotalStat;
  subtotals_quarter: CombinedSubtotalStat[] = [];

  subtotal_partial_quarter?: CombinedSubtotalStat;
  subtotals_partial_quarter: CombinedSubtotalStat[] = [];

  subtotal_year?: CombinedSubtotalStat;
  subtotals_year: CombinedSubtotalStat[] = [];

  subtotal_partial_year?: CombinedSubtotalStat;
  subtotals_partial_year: CombinedSubtotalStat[] = [];
}

@Injectable({
  providedIn: 'root',
})
export class DispoResourceDataStructure {
  private employeeService: SetCollection;
  private contractorService: SetCollection;

  private employeesDS$: Observable<EmployeeDS[]>;
  private contractorsDS$: Observable<any[]>;

  constructor(
    private focusService: DispoFocusService,
    private planService: DispoPlanCollection,
    private scheduleService: DispoScheduleCollection,
    private taskService: DispoTaskCollection,
    private announcementService: DispoAnnouncementCollection,
    private assignmentService: DispoAssignmentCollection,
    private availabilityService: DispoAvailabilityCollection,
    private invitationService: DispoInvitationCollection,
    private timebalanceService: TimebalanceCollection,
    private subtotalService: DispoSubtotalCollection,
    private qualificationAccountService: QualificationAccountCollection,
    private biasService: BiasCollection,
    private employeeSets: EmployeeCollection,
    private contractorSets: ContractorCollection,
  ) {
    this.employeeService = this.employeeSets.visible();
    this.contractorService = this.contractorSets.visible();
  }

  employee(id): Observable<EmployeeDS | null> {
    return this.employees().pipe(
      debounceTime(200),
      map((employees: any[]) => {
        return employees.find((e) => e.id === id);
      }),
    );
  }

  employeeValidation(
    id: number,
    marker: [number, number],
  ): Observable<EmployeeValidationDS> {
    return this.employee(id).pipe(
      map((employeeDS) => {
        const validationDS = new EmployeeValidationDS();

        if (employeeDS) {
          const fallBackStat = [
            Object.assign(new BlankCombinedSubtotalStat(), {
              resource_type: 'Employee',
              resource_id: id,
              marker: marker,
              date: dateToString(integerToDate(marker[0])),
            }),
          ];

          validationDS['assignments'] = employeeDS.assignments;
          validationDS['invitations'] = employeeDS.invitations;
          validationDS['availabilities'] = employeeDS.availabilities;
          validationDS['timebalances'] = employeeDS.timebalances;
          validationDS['qualifications'] = employeeDS.qualifications;
          validationDS['biases'] = employeeDS.biases;
          validationDS['subtotal'] = employeeDS.subtotals.filter((s) =>
            overlaps(s.marker, ...marker),
          );
          if (validationDS['subtotal'].length === 0) {
            validationDS['subtotal'] = fallBackStat;
          }

          validationDS['subtotal_day'] = employeeDS.subtotals_day.filter((s) =>
            overlaps(s.marker, ...marker),
          );
          if (validationDS['subtotal_day'].length === 0) {
            validationDS['subtotal_day'] = fallBackStat;
          }

          validationDS['subtotal_week'] = employeeDS.subtotals_week.filter(
            (s) => overlaps(s.marker, ...marker),
          );
          if (validationDS['subtotal_week'].length === 0) {
            validationDS['subtotal_week'] = fallBackStat;
          }

          validationDS['subtotal_quarter'] =
            employeeDS.subtotals_quarter.filter((s) =>
              overlaps(s.marker, ...marker),
            );
          if (validationDS['subtotal_quarter'].length === 0) {
            validationDS['subtotal_quarter'] = fallBackStat;
          }

          validationDS['subtotal_partial_quarter'] =
            employeeDS.subtotals_partial_quarter.filter((s) =>
              overlaps(s.marker, ...marker),
            );
          if (validationDS['subtotal_partial_quarter'].length === 0) {
            validationDS['subtotal_partial_quarter'] = fallBackStat;
          }

          validationDS['subtotal_year'] = employeeDS.subtotals_year.filter(
            (s) => overlaps(s.marker, ...marker),
          );
          if (validationDS['subtotal_year'].length === 0) {
            validationDS['subtotal_year'] = fallBackStat;
          }

          validationDS['subtotal_partial_year'] =
            employeeDS.subtotals_partial_year.filter((s) =>
              overlaps(s.marker, ...marker),
            );
          if (validationDS['subtotal_partial_year'].length === 0) {
            validationDS['subtotal_partial_year'] = fallBackStat;
          }

          validationDS['resource'] = [employeeDS];
        }

        return validationDS;
      }),
      debugTap('EmployeeValidationDS'),
    );
  }

  contractor(id: number) {
    return this.contractors().pipe(
      debounceTime(200),
      map((contractors: any[]) => {
        return contractors.find((e) => e.id === id);
      }),
      distinctUntilChanged((contractorBefore, contractorAfter) => {
        return isEqual(contractorBefore, contractorAfter);
      }),
    );
  }

  contractorValidation(id, _marker: [number, number]) {
    return combineLatest(
      this.contractorService.all(),
      this.contractor(id),
    ).pipe(
      map(([contractors, contractorDS]) => {
        const validationDS = {};
        const contractor = contractors.find((r) => r.id === id);

        validationDS['assignments'] = contractorDS.assignments;
        validationDS['resource'] = [contractor];

        return validationDS;
      }),
      debugTap('ContractorValidationDS'),
    );
  }

  employees(): Observable<EmployeeDS[]> {
    if (!this.employeesDS$) {
      this.employeesDS$ = combineLatest(
        this.focusService.date(),
        this.employeeService.all(),
        this.planService.all(),
        this.scheduleService.all(),
        this.taskService.all(),
        this.announcementService.all(),
        this.assignmentService.all(),
        this.availabilityService.all(),
        this.invitationService.all(),
        this.timebalanceService.all(),
        this.biasService.all(),
        this.qualificationAccountService.all(),
        this.subtotalService.dayStats(),
        this.subtotalService.weekStats(),
        this.subtotalService.monthStats(),
        this.subtotalService.quarterStats(),
        this.subtotalService.partialQuarterStats(),
        this.subtotalService.yearStats(),
        this.subtotalService.partialYearStats(),
      ).pipe(
        debounceTime(100),
        map(
          ([
            date,
            employees,
            plans,
            schedules,
            tasks,
            announcements,
            assignments,
            availabilities,
            invitations,
            timebalances,
            biases,
            qualification_accounts,
            subtotalDayStats,
            subtotalWeekStats,
            subtotalMonthStats,
            subtotalQuarterStats,
            subtotalPartialQuarterStats,
            subtotalYearStats,
            subtotalPartialYearStats,
          ]: [
            Date,
            Employee[],
            Plan[],
            Schedule[],
            Task[],
            Announcement[],
            Assignment[],
            Availability[],
            Invitation[],
            TimeBalance[],
            Bias[],
            QualificationAccount[],
            CombinedSubtotalStat[],
            CombinedSubtotalStat[],
            CombinedSubtotalStat[],
            CombinedSubtotalStat[],
            CombinedSubtotalStat[],
            CombinedSubtotalStat[],
            CombinedSubtotalStat[],
          ]) => {
            const currWeek = dateToString(
              startOfWeek(date, { weekStartsOn: 1 }),
            );
            const currMonth = dateToString(startOfMonth(date));
            const currQuarter = dateToString(startOfQuarter(date));
            const currYear = dateToString(startOfYear(date));

            const tasksAsHash: Record<number, Task> = {};
            tasks.forEach((t) => (tasksAsHash[t.id] = t));
            const plansAsHash: Record<number, Plan> = {};
            plans.forEach((p) => (plansAsHash[p.id] = p));
            const schedulesAsHash: Record<number, Schedule> = {};
            schedules.forEach((s) => (schedulesAsHash[s.id] = s));
            const announcementsAsHash: Record<number, Announcement> = {};
            announcements.forEach((s) => (announcementsAsHash[s.id] = s));

            const employeesAsHash: Record<number, EmployeeDS> = {};

            employees.forEach((employee: Employee) => {
              const employeeDs: EmployeeDS = {
                ...new EmployeeDS(),
                ...employee,
              };

              employeesAsHash[employee.id] = employeeDs;
            });

            assignments.forEach((assignment) => {
              if (
                assignment.resource_type === 'Employee' &&
                employeesAsHash[assignment.resource_id]
              ) {
                const assignmentDS = {
                  ...new AssignmentDS(),
                  ...assignment,
                };
                assignmentDS.slot = assignmentDS.schedule_id
                  ? (
                      schedulesAsHash[assignmentDS.schedule_id]?.slots_config ||
                      []
                    ).find((i) => i.position === assignmentDS.position)
                  : (
                      tasksAsHash[assignmentDS.task_id]?.slots_config || []
                    ).find((i) => i.position === assignmentDS.position);
                assignmentDS.task = tasksAsHash[assignmentDS.task_id];
                assignmentDS.plan = assignmentDS.task
                  ? plansAsHash[assignmentDS.task.plan_id]
                  : undefined;
                assignmentDS.schedule = assignmentDS.schedule_id
                  ? schedulesAsHash[assignmentDS.schedule_id]
                  : undefined;

                employeesAsHash[assignment.resource_id].assignments.push(
                  assignmentDS,
                );
                employeesAsHash[assignment.resource_id].tasks[
                  assignmentDS.task_id
                ] = assignmentDS.task;
              }
            });

            invitations.forEach((invitation) => {
              if (
                invitation.resource_type === 'Employee' &&
                employeesAsHash[invitation.resource_id]
              ) {
                const announcement =
                  announcementsAsHash[invitation.announcement_id];

                if (announcement) {
                  announcement.task_ids.map((id) => {
                    const task = tasksAsHash[id];

                    if (task) {
                      const invitationDS = {
                        ...new InvitationDS(),
                        ...invitation,
                      };
                      invitationDS.state =
                        invitation.states[task.date] || InvitationState.open;
                      invitationDS.date = task.date;
                      invitationDS.task = task;
                      invitationDS.assigned =
                        !!employeesAsHash[invitation.resource_id].tasks[id];

                      invitationDS.announcement = announcement;
                      invitationDS.schedule = announcement.schedule_id
                        ? schedulesAsHash[announcement.schedule_id]
                        : undefined;
                      invitationDS.plan = plansAsHash[announcement.plan_id];

                      employeesAsHash[invitation.resource_id].invitations.push(
                        invitationDS,
                      );
                    }
                  });
                }
              }
            });

            availabilities.forEach((availability) => {
              if (
                availability.resource_type === 'Employee' &&
                employeesAsHash[availability.resource_id]
              ) {
                employeesAsHash[availability.resource_id].availabilities.push(
                  availability,
                );
              }
            });

            timebalances.forEach((timebalance) => {
              if (
                timebalance.resource_type === 'Employee' &&
                employeesAsHash[timebalance.resource_id]
              ) {
                employeesAsHash[timebalance.resource_id].timebalances.push(
                  timebalance,
                );
              }
            });

            biases.forEach((bias) => {
              if (bias.resource_type === 'Employee') {
                employeesAsHash[bias.resource_id]?.biases.push(bias);
              }
            });

            qualification_accounts.forEach((qualification_account) => {
              if (
                qualification_account.resource_type === 'Employee' &&
                employeesAsHash[qualification_account.resource_id]
              ) {
                employeesAsHash[
                  qualification_account.resource_id
                ].qualification_ids.push(
                  ...qualification_account.qualification_ids,
                );
                employeesAsHash[
                  qualification_account.resource_id
                ].qualifications.push(qualification_account);
              }
            });

            subtotalMonthStats.forEach((subtotal) => {
              if (
                subtotal.resource_type === 'Employee' &&
                employeesAsHash[subtotal.resource_id]
              ) {
                employeesAsHash[subtotal.resource_id].subtotals.push(subtotal);

                if (currMonth === subtotal.date) {
                  employeesAsHash[subtotal.resource_id].subtotal = subtotal;
                }
              }
            });

            subtotalDayStats.forEach((subtotal) => {
              if (
                subtotal.resource_type === 'Employee' &&
                employeesAsHash[subtotal.resource_id]
              ) {
                employeesAsHash[subtotal.resource_id].subtotals_day.push(
                  subtotal,
                );
              }
            });

            subtotalWeekStats.forEach((subtotal) => {
              if (
                subtotal.resource_type === 'Employee' &&
                employeesAsHash[subtotal.resource_id]
              ) {
                employeesAsHash[subtotal.resource_id].subtotals_week.push(
                  subtotal,
                );

                if (currWeek === subtotal.date) {
                  employeesAsHash[subtotal.resource_id].subtotal_week =
                    subtotal;
                }
              }
            });

            subtotalQuarterStats.forEach((subtotal) => {
              if (
                subtotal.resource_type === 'Employee' &&
                employeesAsHash[subtotal.resource_id]
              ) {
                employeesAsHash[subtotal.resource_id].subtotals_quarter.push(
                  subtotal,
                );

                if (currQuarter === subtotal.date) {
                  employeesAsHash[subtotal.resource_id].subtotal_quarter =
                    subtotal;
                }
              }
            });

            subtotalPartialQuarterStats.forEach((subtotal) => {
              if (
                subtotal.resource_type === 'Employee' &&
                employeesAsHash[subtotal.resource_id]
              ) {
                employeesAsHash[
                  subtotal.resource_id
                ].subtotals_partial_quarter.push(subtotal);

                if (currQuarter === subtotal.date) {
                  employeesAsHash[
                    subtotal.resource_id
                  ].subtotal_partial_quarter = subtotal;
                }
              }
            });

            subtotalYearStats.forEach((subtotal) => {
              if (
                subtotal.resource_type === 'Employee' &&
                employeesAsHash[subtotal.resource_id]
              ) {
                employeesAsHash[subtotal.resource_id].subtotals_year.push(
                  subtotal,
                );

                if (currYear === subtotal.date) {
                  employeesAsHash[subtotal.resource_id].subtotal_year =
                    subtotal;
                }
              }
            });

            subtotalPartialYearStats.forEach((subtotal) => {
              if (
                subtotal.resource_type === 'Employee' &&
                employeesAsHash[subtotal.resource_id]
              ) {
                employeesAsHash[
                  subtotal.resource_id
                ].subtotals_partial_year.push(subtotal);

                if (currYear === subtotal.date) {
                  employeesAsHash[subtotal.resource_id].subtotal_partial_year =
                    subtotal;
                }
              }
            });

            return Object.values(employeesAsHash);
          },
        ),
        debugTap('EmployeeDS'),
        shareReplay(1),
      );
    }

    return this.employeesDS$;
  }

  contractors() {
    if (!this.contractorsDS$) {
      this.contractorsDS$ = combineLatest(
        this.contractorService.all(),
        this.planService.all(),
        this.scheduleService.all(),
        this.taskService.all(),
        this.assignmentService.all(),
      ).pipe(
        map(([contractors, plans, schedules, tasks, assignments]) => {
          return contractors.map((contractor) => {
            contractor = Object.assign({}, contractor);

            contractor.assignments = assignments
              .filter(
                (a) =>
                  a.resource_id === contractor.id &&
                  a.resource_type === 'Contractor',
              )
              .map((assignment) => {
                assignment = Object.assign({}, assignment);
                assignment.task = tasks.find(
                  (t) => t.id === assignment.task_id,
                );
                assignment.plan = plans.find(
                  (p) => p.id === assignment.task.plan_id,
                );
                assignment.schedule = schedules.find(
                  (s) => s.id === assignment.schedule_id,
                );

                return assignment;
              });

            return contractor;
          });
        }),
        debugTap('ContractorDS'),
        shareReplay(1),
      );
    }

    return this.contractorsDS$;
  }
}
