import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { DateUnicodeFormat } from '@timecount/utils';

import { DispoAvailabilityCollection } from '../../collections/availability';
import { DateRanges } from '../../../core/date_ranges';
import { DispoShiftCollection } from '../../collections/shift';
import { Action } from '../../../core/types/action';
import { ActionType } from '../../../core/types/action-type';
import { EntryType } from '../../../core/types/entry-type';
import { ValidationService } from '../../collections/validation.service';

@Component({
  selector: 'tc-hub-dav-interval',
  templateUrl: './interval.component.html',
  styleUrls: ['./interval.component.scss'],
})
export class DAVIntervalComponent implements OnInit {
  @Input() availability: any;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  shifts$: Observable<any[]>;
  days$ = of([1, 2, 3, 4, 5, 6, 0]);

  nzRanges: any;
  nzFormat: string;

  private translations: { [key: string]: string } = {};

  constructor(
    private availService: DispoAvailabilityCollection,
    private shiftService: DispoShiftCollection,
    private formBuilder: UntypedFormBuilder,
    private validationService: ValidationService,
    private translateService: TranslateService,
    @Inject('Flash') private flash,
  ) {
    this.translateService
      .get([
        'errors.messages.validation_resolved',
        'errors.messages.validation_not_resolved',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnInit() {
    this.shifts$ = this.shiftService.all();
    this.nzRanges = DateRanges;
    this.nzFormat = DateUnicodeFormat.dateTime;
    this.form = this.buildForm();
  }

  blank(key) {
    return (this.form.value[key] || []).length === 0;
  }

  includes(key, val) {
    return (this.form.value[key] || []).indexOf(val) !== -1;
  }

  set(key, val) {
    this.form.controls[key].setValue(val);
  }

  toggle(key, val) {
    const fieldVal = this.form.value[key] || [];
    const index = fieldVal.indexOf(val);

    if (index === -1) {
      fieldVal.push(val);
    } else {
      fieldVal.splice(index, 1);
    }

    this.form.controls[key].setValue(fieldVal);
  }

  toggleTitle() {
    const custom_title = this.form.controls.custom_title.value;
    if (custom_title) {
      this.form.controls['title'].enable();
    } else {
      this.form.controls['title'].disable();
    }
  }

  validate() {
    // validate intermission in range
  }

  save() {
    const formValues = this.form.value;
    const newValues = Object.assign({}, this.availability, {
      interval_type: 'interval',
      interval_days: formValues.interval_days || [],
      interval_shifts: formValues.interval_shifts || [],
      starts_at: formValues.range.start,
      ends_at: formValues.range.end,
      type_id: formValues.type_id,
      source_id: formValues.source_id,
      note: formValues.note,
      title: formValues.custom_title ? formValues.title : '',
    });

    newValues.store = Object.assign(
      {},
      this.availability.store,
      formValues.store,
    );

    const actionType = this.availability.id
      ? ActionType.update
      : ActionType.create;

    this.validationService
      .run([
        <Action>{
          id: uuid(),
          entry: newValues,
          entry_type: EntryType.Availability,
          type: actionType,
          validations: (item, stack) =>
            this.availService.remoteValidations(item, stack, actionType),
          errors: [],
        },
      ])
      .pipe(
        tap(() => {
          this.flash.create(
            this.translations['errors.messages.validation_resolved'],
            'success',
            6000,
          );
        }),
        map(() => 100),
        catchError((error: unknown) => {
          this.flash.create(
            this.translations['errors.messages.validation_not_resolved'],
            'error',
            6000,
          );

          return of(error);
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((delay: number) => {
        if (delay) {
          this.signal.emit({ action: 'close' });
        }
      });

    this.signal.emit({ action: 'close' });
  }

  private buildForm() {
    const { starts_at: start, ends_at: end } =
      this.availability.base ?? this.availability;
    const initialRange = { start, end };

    return this.formBuilder.group(
      {
        range: [initialRange],
        type_id: [this.availability.type_id, [Validators.required]],
        source_id: [this.availability.source_id, [Validators.required]],
        note: [this.availability.note],
        title: [
          {
            value: this.availability.title,
            disabled: !this.availability.title,
          },
          [Validators.required],
        ],
        custom_title: [!!this.availability.title],
        interval_days: [this.availability.interval_days],
        interval_shifts: [this.availability.interval_shifts],
        store: [this.availability.store],
      },
      {
        validator: Validators.compose([this.validate.bind(this)]),
      },
    );
  }
}
