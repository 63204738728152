<div class="wrapper">
  <div class="filter-bar">
    <tc-hub-dispo-filter section="dispo.schedules"></tc-hub-dispo-filter>
  </div>

  <div class="panel">
    <div class="plans" [ngStyle]="style$ | async">
      <div class="header">
        <tc-hub-dispo-shift-grid-header-weeks
          [weeks]="weeks$ | async"
        ></tc-hub-dispo-shift-grid-header-weeks>
      </div>
      <div class="content">
        <ng-container *ngFor="let plan of plans | async">
          <tc-hub-ds-plan
            #planComponent
            [plan]="plan"
            [showEmpty]="showEmptyPlans$"
            [attr.data-planid]="plan.id"
            (tcSelect)="onSelect($event)"
            tcHubDispoMenuActive
            activeType="DispoPlan"
            [activeId]="plan.id"
          >
          </tc-hub-ds-plan>
        </ng-container>
        <ng-container *tcHubHide="'views.dispo.plan.create.guided'">
          <button
            class="add-button"
            nz-button
            nzSize=""
            nzType="primary"
            (click)="createPlan()"
          >
            <i nz-icon nzType="apartment"></i>
            {{ 'dispo/plan.actions.create' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
