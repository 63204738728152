import { Language } from '../language.model';
import { supportedLanguages } from '../languages';

/**
 * Find a matching language in the supported ones.
 *
 * @param languageCode the language code to search for
 * @return a matching supported language if there is one
 */
export const findMatchingSupportedLanguage = (
  languageCode: string,
): Language | undefined => {
  return (
    supportedLanguages.find(
      (supportedLanguage: Language) =>
        supportedLanguage.isoCode.toLowerCase() ===
          languageCode.toLowerCase() ||
        supportedLanguage.code.toLowerCase() === languageCode.toLowerCase(),
    ) ?? undefined
  );
};
