import { Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tc-hub-dispo-employee-grid-time-balance',
  templateUrl: './employee-grid-time-balance.component.html',
  styleUrls: ['./employee-grid-time-balance.component.scss'],
})
export class EmployeeGridTimeBalanceComponent {
  @Input() timebalance: any;

  @HostBinding('style')
  get style() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--timebalance-color: ${
        this.timebalance.balance_type?.color || 'lightgray'
      }`,
    );
  }

  constructor(private sanitizer: DomSanitizer) {}
}
