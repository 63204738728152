import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { dateToTzUnix } from '@timecount/utils';

import { Announcement } from '../collections/announcement';
import {
  DispoInvitationCollection,
  Invitation,
} from '../collections/invitation';
import { InvitationService } from '../actions/invitation.service';
import { DispoAssignmentCollection } from '../collections/assignment';
import { integerToString } from '../../core/helpers';
import { AssignmentService } from '../actions/assignment.service';
import { CurrentUserService } from '../../core/current-user.service';
import { DispoTaskCollection, SlotPos } from '../collections/task';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuInvitation {
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private invitationActions: InvitationService,
    private assignmentActions: AssignmentService,
    private invitationCollection: DispoInvitationCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private taskCollection: DispoTaskCollection,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/invitation.actions.assign',
        'dispo/invitation.actions.unassign',
        'dispo/invitation.actions.accept',
        'dispo/invitation.actions.refuse',
        'dispo/invitation.actions.delete',
        'dispo/invitation.menu.short',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  itemsForAnnouncement(
    invitations: Invitation[],
    announcement: Announcement,
  ): Observable<any[]> {
    return combineLatest(
      this.invitationCollection.observeItems(invitations),
      this.taskCollection.all(),
      this.assignmentCollection.all(),
    ).pipe(
      map(([invitations, tasks, assignments]) => {
        const invitation_ids = invitations.map((i) => i.id);
        assignments = assignments.filter(
          (a) => invitation_ids.indexOf(a.invitation_id) !== -1,
        );
        tasks = tasks.filter((t) => t.announcement_id === announcement.id);

        const slots = [];

        invitations.forEach((invitation) => {
          const baseSlot = Object.assign(new SlotPos(), {
            invitation_id: invitation.id,
          });

          tasks.forEach((task) => {
            const assignment = assignments.find(
              (a) => a.task_id === task.id && a.invitation_id === invitation.id,
            );

            const slot = Object.assign(baseSlot, {
              x: dateToTzUnix(task.starts_at),
              task_id: task.id,
              assignment_id: assignment ? assignment.id : undefined,
            });

            slots.push(slot);
          });
        });

        const assignableSlots = slots.filter((s) => !s.assignment_id);
        const unassignableSlots = slots.filter((s) => !!s.assignment_id);

        const acceptableSlots = slots.filter((s) => {
          const invitation = invitations.find((i) => i.id === s.invitation_id);
          const lookup = integerToString(s.x);

          return invitation.states[lookup] !== 'accepted';
        });

        const refusableSlots = slots.filter((s) => {
          const invitation = invitations.find((i) => i.id === s.invitation_id);
          const lookup = integerToString(s.x);

          return invitation.states[lookup] !== 'refused';
        });

        const slotActions = [
          {
            label: this.translations['dispo/invitation.actions.assign'],
            icon: 'user-add',
            visible:
              assignableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.assign',
              ),
            command: (event: any) => {
              this.invitationActions.assign(
                assignableSlots,
                announcement,
                invitations,
              );
            },
          },
          {
            label: this.translations['dispo/invitation.actions.unassign'],
            icon: 'user-delete',
            visible:
              unassignableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.unassign',
              ),
            command: (event: any) => {
              this.invitationActions.unassign(
                unassignableSlots,
                announcement,
                invitations,
              );
            },
          },
          {
            label: this.translations['dispo/invitation.actions.accept'],
            icon: 'check',
            visible:
              acceptableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.accept',
              ),
            command: (event: any) => {
              this.invitationActions.accept(acceptableSlots, invitations);
            },
          },
          {
            label: this.translations['dispo/invitation.actions.refuse'],
            icon: 'close',
            visible:
              refusableSlots.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.invitation.refuse',
              ),
            command: (event: any) => {
              this.invitationActions.refuse(refusableSlots, invitations);
            },
          },
          {
            label: this.translations['dispo/invitation.actions.delete'],
            icon: 'delete',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.invitation.delete',
            ),
            command: (event: any) => {
              this.invitationActions.delete(invitations);
            },
          },
        ];

        return [
          ...(slotActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/invitation.menu.short'],
                },
                ...slotActions,
              ]
            : []),
        ];
      }),
    );
  }
}
