import { Deserializer, ExclusionPolicy, Strategy, Type } from 'typeserializer';

import { TcFeedbackRequest, TcFeedbackRequestApi } from '@timecount/core';
import { parseDate } from '@timecount/utils';

import { Plan } from './plan';
import { Task } from './task';

@Strategy(ExclusionPolicy.NONE)
export class Assignment {
  id: number;
  identifier = 'tc_dispo/assignment';
  shallow = false;
  gratis = false;

  task_id: number;
  invitation_id: number;

  schedule_id: number;

  resource_id: number;
  resource_type: string;

  date: string;
  marker: [number, number];

  position: number;

  @Deserializer((value: TcFeedbackRequestApi[], obj: Assignment) =>
    value.map((value) =>
      TcFeedbackRequest.parseFromApi(value, {
        responseInterval: {
          start: parseDate(obj.task.starts_at as unknown as string),
          end: parseDate(obj.task.ends_at as unknown as string),
        },
      }),
    ),
  )
  responses?: TcFeedbackRequest[];

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  intermission_starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  intermission_ends_at: Date;

  times: { [key: string]: string }[];

  tracked_time: boolean;
  times_state: 'untracked' | 'unconfirmed' | 'confirmed';
  note: string;
  plan?: Plan;

  @Type(Object)
  task: Task;

  @Type(Object)
  supplements: object;

  @Type(Object)
  store: object;

  @Deserializer((m: string): Date => parseDate(m))
  read_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  requested_at: Date;
  requested?: boolean;
}
