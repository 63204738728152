import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl, ValidatorFn } from '@angular/forms';
import { startOfDay } from 'date-fns';
import { Observable, Subject } from 'rxjs';

import { isDateAtMidnight, isValidDate } from '@timecount/utils';

import { TcFormValidationService } from '../../form-validation.service';
import {
  TcInputDateTimeReferenceDirective,
  TcInputTimeDateOptionsDirective,
} from '../../shared';

import { TcInputFieldType } from './input-field-type.model';
import { TcInputService } from './input.service';

@Directive()
export abstract class TcInputBaseDirective
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() label: string;
  @Input() hints: string[];
  @Input() tcConfig;
  @Input() hasAsyncValidation = false;
  @Input() tcCanDisable = false;
  @Output() disabledToggle: EventEmitter<boolean> = new EventEmitter();

  /**
   * Used to define which type of field will be used.
   */
  @Input() fieldType: TcInputFieldType = 'text';

  /**
   * Overrides the current date value when popping up a date/datetime picker
   * for the first time
   */
  @Input() pickerInitialValue = new Date();

  destroyed$ = new Subject<void>();

  public shouldShowDateOptions = false;

  private _value;
  get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  private _isDisabled = false;
  public get isDisabled(): boolean {
    return this._isDisabled;
  }

  public set isDisabled(shouldDisabled: boolean) {
    const { control } = this.ngControl ?? {};

    this._isDisabled = shouldDisabled;

    this.disabledToggle.emit(shouldDisabled);

    shouldDisabled ? control?.disable() : control?.enable();
  }

  private _initialValidators: ValidatorFn[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (_) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  constructor(
    public ngControl: NgControl,
    public elementRef: ElementRef,
    public formValidationService: TcFormValidationService,
    public inputService: TcInputService,
    @Optional() public refDateDir?: TcInputDateTimeReferenceDirective,
    @Optional() public dateOptionsDir?: TcInputTimeDateOptionsDirective,
  ) {
    ngControl.valueAccessor = this;
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit(): void {
    this.setFieldType();
    //HACK: avoid setting the limit validator in the initialValidators,
    //as it cannot be updated when the config changes
    if (!this.fieldType.includes('time') && !this.fieldType.includes('date')) {
      this._initialValidators = [this.ngControl?.control.validator];
    }
    this._updateValidators();
  }

  ngOnChanges({ tcConfig, fieldType }: SimpleChanges): void {
    if (fieldType) {
      const tagName = this.elementRef.nativeElement.tagName.toLowerCase();

      if (!['tc-input-web', 'tc-input-ionic'].includes(tagName)) {
        console.warn(`
          The 'fieldType' input is ignored when not using the
          generic 'tc-input-web' and 'tc-input-ionic' selectors.
        `);
      }
    }

    // NOTE: we need to set the `_initialValidators` before updating the
    // validators to avoid losing the ones defined in the form.
    if (tcConfig?.firstChange === false || fieldType?.firstChange === false) {
      this._updateValidators();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // -----------------------
  // Abstract Implementation
  // -----------------------

  writeValue(obj): void {
    const isDateAndShouldBeStartOfDay =
      this.fieldType === 'date' && isValidDate(obj) && !isDateAtMidnight(obj);

    // HACK:FIXME:
    // The `writeValue` should not trigger the `onChange` and `onTouched`
    // methods to avoid a "Too much recursion" error, so the value is, by
    // default, set directly to the private property and not using the setter,
    // unless the value needs to be changed here, which requires the onChange
    // to be called, so the form value gets updated.
    if (isDateAndShouldBeStartOfDay) {
      this.value = startOfDay(obj);
    } else {
      this._value = obj;
    }
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  // --------------
  // Public Methods
  // --------------

  getErrorMessage(errorKey: string, interpolateParams?): Observable<string> {
    return this.formValidationService.getValidatorErrorMessage(
      errorKey,
      interpolateParams,
    );
  }

  // ---------------
  // Private Methods
  // ---------------

  /**
   * Set the input field type based on the element's selector
   *
   * Defaults to 'text' if not possible to define
   */
  private setFieldType(): void {
    const fieldType = this.elementRef.nativeElement.tagName
      .toLowerCase()
      // Remove the component's base name
      .substring('tc-input'.length)
      .split('-')
      // Remove the platform identifier
      .filter((part) => part !== 'web' && part !== 'ionic')
      .pop();

    if (fieldType.length) {
      this.fieldType = fieldType;
    }
  }

  private _updateValidators() {
    if (!this.tcConfig?.noValidators) {
      this.ngControl?.control?.setValidators(
        this.inputService.getInputValidators(
          {
            ...this.tcConfig,
            fieldType: this.fieldType,
          },
          this._initialValidators,
        ),
      );

      this.ngControl?.control?.updateValueAndValidity();
    }
  }
}
