import { ExclusionPolicy, Strategy } from 'typeserializer';

@Strategy(ExclusionPolicy.NONE)
export class Employee {
  id: number;
  type: 'Employee';

  secure_code: string;
  datev_id?: string;
  contact_id: number;
  user_id?: number;

  previous_id?: number;
  next_id?: number;

  tariff_id?: number;
  employment_mode_id?: number;

  token: string;
  token_prefix: string;
  token_postfix: string;
  token_counter: number;

  state?: number;
  visibility?: number;

  title: 'f' | 'm' | 'c';
  first_name: string;
  last_name: string;
  name: string;
  shorthand: string;
  label?: string;

  department_id: number;
  department: {
    id: number;
    key: string;
    title: string;
  };
  department_ids: number[];
  department_ids_additional: number[];

  employee_tags: string[];
  driving_licenses: string[];
  qualification_ids: number[];
  dispo_channel: 'email' | 'mobile' | 'push';
  own_car?: boolean;

  street?: string;
  street_no?: string;
  zipcode?: string;
  place?: string;
  country?: string;
  address_addon?: string;
  card_title?: string;

  phone?: string;
  mobile?: string;
  email?: string;
  fax?: string;
  notification_key?: string;

  latitude?: number;
  langitude?: number;
  coordinates?: [number, number];

  discharge_date?: string;
  re_entry_date?: string;
  first_entry_date?: string;
  contract_limitation_date?: string;

  birth_name?: string;
  birth_date?: string;
  birth_place?: string;
  nationality?: string;
  work_permit?: string;
  gender?: number;

  account_number?: string;
  social_security_number?: string;
  health_insurance_number?: string;

  health_insurance_contribution_type?: number;
  pension_insurance_contribution_type?: number;
  unemployement_insurance_contribution_type?: number;
  nursing_insurance_contribution_type?: number;
  social_legal_treatment?: number;
  social_allocation_method?: number;
  additional_contribution_ignored?: boolean;

  tax_identification_number?: string;
  municipal_key?: string;
  tax_office_number?: string;
  tax_factor?: number;
  child_allowance?: number;
  monthly_tax_allowance?: number;
  annual_tax_allowance?: number;
  requested_annual_tax_allowance?: number;
  flat_rate_taxation?: boolean;

  employer_relations_type?: number;
  transfer_of_personnel?: boolean;
  wage_garnishment?: boolean;
  asset_creating_payments?: boolean;

  latest_timesheet_starts_at?: string;
  latest_timesheet_desc?: string;

  payment_method?: number;
  alternative_account_holder?: string;
  bank_id?: number;
  bank?: {
    id: number;
    key: string;
    title: string;
  };

  health_insurance_id?: number;
  health_insurance_type?: number;
  health_insurance?: {
    id: number;
    key: string;
    title: string;
  };

  tax_bracket_id?: number;
  tax_bracket?: {
    id: number;
    title: string;
  };

  confession_id?: number;
  confession?: {
    id: number;
    key: string;
    title: string;
  };

  spouse_confession_id?: number;
  spouse_confession?: {
    id: number;
    key: string;
    title: string;
  };

  civil_state_id?: number;
  civil_state?: {
    id: number;
    key: string;
    title: string;
  };

  store: Record<string, unknown>;
}
