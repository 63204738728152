import { Inject, Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { TcApiResponse, TcSmtpConfig, TcSmtpConfigApi } from '@timecount/core';

@Injectable({
  providedIn: 'root',
})
export class SmtpApiService {
  endpoint = '/api/preferences/apis/smtp_endpoints';

  private _isSmtpEnabledSubject = new BehaviorSubject<boolean>(false);
  public isSmtpEnabled$: Observable<boolean> =
    this._isSmtpEnabledSubject.asObservable();

  constructor(private http: HttpClient, @Inject('Flash') protected flash) {}

  get() {
    return this.http.get<TcApiResponse<TcSmtpConfigApi>>(this.endpoint).pipe(
      map(({ data }) => TcSmtpConfig.parseFromApi(data)),
      tap((data) => {
        this._isSmtpEnabledSubject.next(data.enabled);
      }),
      catchError((_error: unknown) => {
        return of(new TcSmtpConfig({}));
      }),
    );
  }

  create(payload: TcSmtpConfig) {
    return this.http
      .post<TcApiResponse<TcSmtpConfigApi>>(
        this.endpoint,
        TcSmtpConfig.formatForApi(payload),
      )
      .pipe(
        map(({ meta, data }) => {
          this._isSmtpEnabledSubject.next(data.enabled);
          const { messages } = meta;
          (Array.isArray(messages) ? messages : [messages]).forEach(
            (message) => {
              this.flash.create(message.detail, message.type);
            },
          );
          return TcSmtpConfig.parseFromApi(data);
        }),
        catchError((error) => {
          this.flash.create(error.error?.errors.address, 'error');
          return throwError(() => error);
        }),
      );
  }

  delete() {
    return this.http.delete<TcApiResponse<TcSmtpConfigApi>>(this.endpoint).pipe(
      map(({ meta, data }) => {
        this._isSmtpEnabledSubject.next(data.enabled);
        const { messages } = meta;
        (Array.isArray(messages) ? messages : [messages]).forEach((message) => {
          this.flash.create(message.detail, message.type);
        });
        return TcSmtpConfig.parseFromApi(data);
      }),
      catchError((error) => {
        this.flash.create(error.error?.errors.address, 'error');
        return of(error);
      }),
    );
  }
}
