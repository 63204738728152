import { NgModule } from '@angular/core';

import { TcSelectsModule } from '../base/tc-selects';
import { SharedModule } from '../shared/shared.module';
import { TcDropdownsModule } from '../base/tc-dropdowns';

import { TcProjectCostsFormComponent } from './project-costs/project-costs-form/project-costs-form.component';
import { TcWorkEntriesFormComponent } from './aggregations/work-entries/work-entries-form/work-entries-form.component';
import { TcServiceEntryFormComponent } from './service-entry/service-entry-form/service-entry-form.component';
import { TcCreateWorkEntryBatchComponent } from './aggregations/work-entries/work-entries-batch/create/create.component';
import { TcWorkEntryBatchStep1Component } from './aggregations/work-entries/work-entries-batch/create/step1/step1.component';
import { TcWorkEntryBatchStep2Component } from './aggregations/work-entries/work-entries-batch/create/step2/step2.component';
import { TcWorkEntryBatchStep3Component } from './aggregations/work-entries/work-entries-batch/create/step3/step3.component';
import { TcWorkEntryBatchStep4Component } from './aggregations/work-entries/work-entries-batch/create/step4/step4.component';
import { TcWorkEntryBatchStep5Component } from './aggregations/work-entries/work-entries-batch/create/step5/step5.component';
import { TcAggregationsImportFormComponent } from './aggregations/aggregations-import-form/aggregations-import-form.component';
import { TcAggregationsImportFilterFormComponent } from './aggregations/aggregations-import-form/aggregations-import-filter-form/aggregations-import-filter-form.component';
import { TcAggregationsBaseFormComponent } from './aggregations/base-form/aggregations-base-form.component';
import { TcAggregationsLayoutFormComponent } from './aggregations/layout-form/aggregations-layout-form.component';
import { TcAggregationsConditionsFormComponent } from './aggregations/conditions-form/aggregations-conditions-form.component';
import { TcAggregationsScheduleFormComponent } from './aggregations/aggregations-import-form/aggregations-schedule-form/aggregations-schedule-form.component';
import { TcAggregationsBillFormComponent } from './aggregations/aggregations-bill-form/aggregations-bill-form.component';
import { TcAggregationsPayFormComponent } from './aggregations/aggregations-pay-form/aggregations-pay-form.component';

@NgModule({
  declarations: [
    TcProjectCostsFormComponent,
    TcWorkEntriesFormComponent,
    TcServiceEntryFormComponent,
    TcCreateWorkEntryBatchComponent,
    TcWorkEntryBatchStep1Component,
    TcWorkEntryBatchStep2Component,
    TcWorkEntryBatchStep3Component,
    TcWorkEntryBatchStep4Component,
    TcWorkEntryBatchStep5Component,
    TcAggregationsImportFormComponent,
    TcAggregationsImportFilterFormComponent,
    TcAggregationsBaseFormComponent,
    TcAggregationsLayoutFormComponent,
    TcAggregationsConditionsFormComponent,
    TcAggregationsScheduleFormComponent,
    TcAggregationsBillFormComponent,
    TcAggregationsPayFormComponent,
  ],
  exports: [
    TcProjectCostsFormComponent,
    TcServiceEntryFormComponent,
    TcWorkEntriesFormComponent,
    TcCreateWorkEntryBatchComponent,
    TcWorkEntryBatchStep1Component,
    TcWorkEntryBatchStep2Component,
    TcWorkEntryBatchStep3Component,
    TcWorkEntryBatchStep4Component,
    TcWorkEntryBatchStep5Component,
    TcAggregationsImportFormComponent,
    TcAggregationsImportFilterFormComponent,
    TcAggregationsBaseFormComponent,
    TcAggregationsLayoutFormComponent,
    TcAggregationsConditionsFormComponent,
    TcAggregationsScheduleFormComponent,
    TcAggregationsBillFormComponent,
    TcAggregationsPayFormComponent,
  ],
  imports: [SharedModule, TcSelectsModule, TcDropdownsModule],
})
export class TcHubProjectsModule {}
