<fieldset [formGroup]="formGroup">
  <tc-input-currency-web
    class="group-item rate-field"
    formControlName="rate"
    [label]="'job_rate.attrs.per_hour' | translate"
    [tcConfig]="{ required: true }"
  ></tc-input-currency-web>
  <fieldset class="group-wrapper" formGroupName="zoneGroups">
    <div class="group-title">
      <label>{{
        'activerecord.attributes.pay_grade_rate.surcharges' | translate
      }}</label>
      <nz-radio-group
        [(ngModel)]="selectedOption"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onSelectOption($event)"
      >
        <label nz-radio nzValue="percent">%</label>
        <label nz-radio nzValue="currency">{{
          'angular.NUMBER_FORMATS.CURRENCY_SYM' | translate
        }}</label>
      </nz-radio-group>
    </div>
    <div class="group-fields" *ngFor="let item of items; let i = index">
      <tc-input-web
        [formControlName]="item.id"
        [tcConfig]="{ required: true }"
        [fieldType]="selectedOption"
        [label]="
          item.title + ' ' + ('zone.groups.help.title.label' | translate)
        "
      >
      </tc-input-web>
    </div>
  </fieldset>
</fieldset>
