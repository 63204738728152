import { Injectable } from '@angular/core';
import {
  Deserializer,
  ExclusionPolicy,
  Strategy,
  Type,
} from 'typeserializer/dist';

import { parseDate } from '@timecount/utils';

import { SetsCollection } from '../sets-collection';

@Strategy(ExclusionPolicy.NONE)
export class ValueSet {
  id: number;

  target_id: number;
  target_type: string;

  schema_key: string;

  note: string;

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;

  @Deserializer((m: string): Date => parseDate(m))
  applied_at: Date;

  @Type(Object)
  data: object;
}

@Injectable({
  providedIn: 'root',
})
export class ValueSetCollection extends SetsCollection {
  identifier = 'value_set';
  endpoint = '/api/value_sets';
  type = ValueSet;

  cache = 60 * 60;

  forTarget(target_type, target_id) {
    return this.query(
      { 'filter[target_type]': target_type, 'filter[target_id]': target_id },
      (e) => e.target_type === target_type && e.target_id === target_id,
    );
  }

  forTargetType(target_type) {
    return this.query(
      { 'filter[target_type]': target_type },
      (e) => e.target_type === target_type,
    );
  }
}
