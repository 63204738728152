import {
  add,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMinute,
  isValid,
  isWeekend,
  nextMonday,
  parse,
  startOfDay,
  startOfMonth,
  sub,
} from 'date-fns';
import { memoize } from 'lodash-es';

import { noNegativeZero } from '../number/number-helpers';

import { DateUnicodeFormat } from './date-formats';
import { Holiday } from './holiday.model';

/**
 * Parse an unicode string Date using the provided reference format.
 *
 * @param providedValue The date string to be parsed.
 * @param dateFormat The format of the provided date string. Defaults to the API
 * date/time format.
 * @param referenceDate A reference date, in the `ISO 8601` format, to be used
 * when parsing the date. This is a requirement from `date-fns` to parse strings
 * with incomplete formats. Defaults to `1979-10-12T00:00`, so if you pass a
 * date without time it will be parsed as `00:00` and if you pass a time without
 * date it will be parsed as `1979-10-12`.
 *
 * @returns The parsed Date or undefined when no value is provided.
 */
export const parseDate = memoize(
  (
    providedValue: string,
    dateFormat?: DateUnicodeFormat,
    referenceDate?: string,
  ): Date => {
    if (!providedValue || !providedValue.replace) {
      return undefined;
    }

    let parsedDate: Date;

    if (!dateFormat) {
      parsedDate = new Date(providedValue);

      if (isNaN(parsedDate.getTime())) {
        parsedDate = new Date(providedValue.replace(/-/g, '/'));
      }
    } else {
      parsedDate = parse(
        providedValue,
        dateFormat ?? DateUnicodeFormat.apiDateTime,
        new Date(referenceDate ?? '1979-10-12T00:00'),
      );
    }

    return parsedDate;
  },
  (...args) => args.join(';'),
);

/**
 * Transform a date object into a unix timestamp considering the timezone.
 *
 * This is an extended version of the `getUnixTime` function from `date-fns`,
 * which doesn't support timezones by default.
 *
 * @see {@link https://date-fns.org/docs/getUnixTime getUnixTime}
 */
export function dateToTzUnix(date: Date): number {
  return Math.floor(date.getTime() / 1000 - date.getTimezoneOffset() * 60);
}

export function stringifyDate(
  providedValue: Date,
  dateFormat = DateUnicodeFormat.apiDateTime,
): string {
  return isValid(providedValue) ? format(providedValue, dateFormat) : '';
}

/**
 * Adjust the minutes of a given date to be a multiple of stepMinutes
 * roundUp false will round down
 */
export function roundToStepMinutes(
  date: Date,
  stepMinutes: number,
  roundUp = false,
) {
  const coeff = 1000 * 60 * stepMinutes;
  const roundFunction = roundUp ? Math.ceil : Math.floor;

  return new Date(roundFunction(date.getTime() / coeff) * coeff);
}

export function isValidDate(date: unknown): boolean {
  return date instanceof Date && isValid(date);
}

export function isIsoDateString(date: unknown): boolean {
  return /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))(T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])((\.[0-9]{3})?Z?)?)?$/g.test(
    String(date),
  );
}

export function getUTCDate(dateToConvert: string | number | Date): Date {
  const dateToReturn = new Date(dateToConvert);

  return new Date(
    Date.UTC(
      dateToReturn.getUTCFullYear(),
      dateToReturn.getUTCMonth(),
      dateToReturn.getUTCDate(),
      dateToReturn.getUTCHours(),
      dateToReturn.getUTCMinutes(),
      dateToReturn.getUTCSeconds(),
      dateToReturn.getUTCMilliseconds(),
    ),
  );
}

/**
 * Update the time of a `Date` with the one from another `Date`

 * @param dateSource The date to be updated.
 * @param timeSource Where to get the time from.
 * @param boundaries Boundaries are used to make sure that the updated date
 * follows some rules in relation to the source date. 'Equal' boudaries will
 * retrieve the source date if it has the same time as the provided time source.
 * * `afterSource`: Makes sure that the updated date is always after the source.
 * * `afterOrEqualSource`: Makes sure that the updated date is never before the
 * source.
 * * `beforeSource`: Makes sure that the updated date is always before the
 * source.
 * * `beforeOrEqualSource`: Makes sure that the updated date is never after the
 * source.
 *
 * @example
 * setTimeToDate(
 *   new Date('2015-10-21T00:00'),
 *   new Date('1985-10-26T08:00')
 * )
 * // returns the same day as the source date ('2015-10-21T08:00')
 *
 * setTimeToDate(
 *   new Date('2015-10-21T22:00'),
 *   new Date('1955-11-12T08:00')
 * )
 * // returns the same day as the source date ('2015-10-21T08:00')
 *
 * setTimeToDate(
 *   new Date('2015-10-21T22:00'),
 *   new Date('1955-11-12T08:00'),
 *   'afterSource'
 * )
 * // returns a day after the source date ('2015-10-22T08:00')
 *
 *
 * setTimeToDate(
 *   new Date('2015-10-21T22:00'),
 *   new Date('1955-11-12T08:00'),
 *   'afterOrEqualSource'
 * )
 * // returns a day after the source date ('2015-10-22T08:00')
 *
 * setTimeToDate(
 *   new Date('2015-10-21T08:00'),
 *   new Date('1955-11-12T08:00'),
 *   'afterOrEqualSource'
 * )
 * // returns the same day and time as the source date ('2015-10-21T08:00')
 */
export function setTimeToDate(
  dateSource: string | number | Date,
  timeSource: string | number | Date,
  boundaries?:
    | 'afterSource'
    | 'afterOrEqualSource'
    | 'beforeSource'
    | 'beforeOrEqualSource',
): Date {
  const dateSourceUTC = getUTCDate(dateSource);
  const timeSourceUTC = getUTCDate(timeSource);

  const updatedDate = new Date(dateSourceUTC);
  updatedDate.setHours(timeSourceUTC.getHours());
  updatedDate.setMinutes(timeSourceUTC.getMinutes());
  updatedDate.setSeconds(timeSourceUTC.getSeconds());
  updatedDate.setMilliseconds(timeSourceUTC.getMilliseconds());

  const dateIsAfter = isAfter(updatedDate, dateSourceUTC);
  const dateIsBefore = isBefore(updatedDate, dateSourceUTC);

  let dayOffset = 0;

  if (
    (boundaries === 'afterOrEqualSource' && dateIsBefore) ||
    (boundaries === 'afterSource' && !dateIsAfter)
  ) {
    dayOffset = 1;
  }

  if (
    (boundaries === 'beforeOrEqualSource' && dateIsAfter) ||
    (boundaries === 'beforeSource' && !dateIsBefore)
  ) {
    dayOffset = -1;
  }

  return add(updatedDate, { days: dayOffset });
}

export function isDateAtMidnight(
  date: Date,
  options = { exact: false },
): boolean {
  const isIt = options.exact ? isEqual : isSameMinute;

  return isIt(date, startOfDay(date));
}

export function getMinutesUntilNextMidnight(
  date: Date,
  options = { allowZeroLength: true },
): number {
  const { allowZeroLength } = options;

  return noNegativeZero(
    -differenceInMinutes(
      date,
      startOfDay(
        add(date, {
          // We should not add a day to get the next midnight, if date is already
          // at midnight and zero length is allowed
          days: Number(!(isDateAtMidnight(date) && allowZeroLength)),
        }),
      ),
    ),
  );
}

export function getMinutesSincePreviousMidnight(
  date: Date,
  options = { allowZeroLength: true },
): number {
  const { allowZeroLength } = options;

  return noNegativeZero(
    -differenceInMinutes(
      startOfDay(
        sub(date, {
          // We should only subtract a day to get the previous midnight, if date
          // is already at midnight and zero length is not allowed
          days: Number(isDateAtMidnight(date) && !allowZeroLength),
        }),
      ),
      date,
    ),
  );
}

export function getFirstWorkDayInMonth(
  monthDate: Date,
  options: { holidays: Holiday[] } = { holidays: [] },
): Date {
  let firstWorkDayCandidate = startOfMonth(monthDate);

  if (isWeekend(firstWorkDayCandidate)) {
    firstWorkDayCandidate = nextMonday(firstWorkDayCandidate);
  }

  options.holidays
    .filter((holiday) => !isWeekend(holiday.date))
    .map((holiday) => holiday.date)
    .forEach((holidayDate) => {
      if (isSameDay(holidayDate, firstWorkDayCandidate)) {
        firstWorkDayCandidate = add(firstWorkDayCandidate, { days: 1 });
      }
      if (isWeekend(firstWorkDayCandidate)) {
        firstWorkDayCandidate = nextMonday(firstWorkDayCandidate);
      }
    });

  return firstWorkDayCandidate;
}
