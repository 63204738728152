import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';

import { ModalService } from '@timecount/ui';

import {
  Announcement,
  DispoAnnouncementCollection,
} from '../collections/announcement';
import { DispoLoaderService } from '../loader/loader.service';
import { DispoAnnouncementComponent } from '../dispo-announcement/dispo-announcement.component';
import { DispoTaskCollection, SlotPos, Task } from '../collections/task';
import { DispoPlanCollection, Plan } from '../collections/plan';
import { Schedule } from '../collections/schedule';
import { DispoFilterService } from '../../shared/filter/filter.service';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  private translations: { [key: string]: string } = {};

  constructor(
    private modalService: ModalService,
    private loadingService: DispoLoaderService,
    private filterService: DispoFilterService,
    private announcementCollection: DispoAnnouncementCollection,
    private taskCollection: DispoTaskCollection,
    private planCollection: DispoPlanCollection,
    private translateService: TranslateService,
  ) {
    this.translateService
      .get(['dispo/announcement.modal.title'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  edit(announcement: Announcement) {
    this.section(announcement, 'general');
  }

  invite(announcement: Announcement, resources: any[] = []) {
    this.section(announcement, 'invite', false, { resources: resources });
  }

  invitations(announcement: Announcement) {
    this.section(announcement, 'invitations');
  }

  tasks(announcement: Announcement) {
    this.section(announcement, 'tasks');
  }

  create(announcement: Announcement, tasks: Task[] = []) {
    this.section(announcement, 'create', true, { tasks: tasks });
  }

  createFromTasksAndTemplate(tasks: Task[], template) {
    combineLatest([
      this.taskCollection.activateTasks(tasks),
      tasks[0] ? this.planCollection.get(tasks[0].plan_id) : of({}),
    ])
      .pipe(first())
      .subscribe({
        next: ([generatedTasks, plan]: [Task[], Plan]) => {
          const announcement =
            this.announcementCollection.fromTemplate(template);

          announcement.title =
            announcement.title ||
            this.translateService.instant(
              'dispo/announcement.interpolations.title',
              { task: tasks[0], plan: plan },
            );
          this.section(announcement, 'create', true, { tasks: generatedTasks });
        },
      });
  }

  appendTasks(tasks: Task[] = []) {
    this.taskCollection
      .activateTasks(tasks)
      .pipe(first())
      .subscribe({
        next: (generatedTasks) => {
          this.section(undefined, 'append-tasks', true, {
            tasks: generatedTasks,
          });
        },
      });
  }

  appendSlots(schedule: Schedule, slots: SlotPos[] = []) {
    this.section(undefined, 'append-slots', true, {
      slots: slots,
      schedule: schedule,
    });
  }

  detachTasks(announcement: Announcement, tasks: Task[] = []) {
    this.section(announcement, 'detach-tasks', true, { tasks: tasks });
  }

  increaseSize(announcement: Announcement, tasks: Task[] = []) {
    announcement = {
      ...announcement,
      task_ids: [...announcement.task_ids],
      sizes: { ...announcement.sizes },
    };

    tasks.forEach((task) => {
      if (!announcement.task_ids.includes(task.id)) {
        return;
      }

      const size = announcement.sizes[task.date];

      if (!size) {
        announcement.sizes[task.date] = 1;
      } else {
        announcement.sizes[task.date] = size + 1;
      }
    });

    this.announcementCollection.update(announcement.id, announcement);
  }

  decreaseSize(announcement: Announcement, tasks: Task[] = []) {
    announcement = {
      ...announcement,
      task_ids: [...announcement.task_ids],
      sizes: { ...announcement.sizes },
    };

    tasks.forEach((task) => {
      if (!announcement.task_ids.includes(task.id)) {
        return;
      }

      const size = announcement.sizes[task.date];

      if (!size) {
        announcement.sizes[task.date] = 0;
      } else if (size >= 1) {
        announcement.sizes[task.date] = size - 1;
      }
    });

    this.announcementCollection.update(announcement.id, announcement);
  }

  delete(announcement: Announcement) {
    this.section(announcement, 'delete', true);
  }

  section(
    announcement: any,
    section: string,
    single = false,
    additionalData = {},
  ) {
    const data = Object.assign(
      {
        announcement: announcement,
        section: section,
        single: single,
      },
      additionalData,
    );

    const ref = this.modalService.open(DispoAnnouncementComponent, {
      data: data,
      modalTitle: this.translations['dispo/announcement.modal.title'],
    });
  }

  single(avail: any, section: string) {
    this.section(avail, section, true);
  }
}
