<button
  #menuDir
  nz-button
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzTrigger="click"
  nzClickHide="true"
  nzBackdrop="false"
>
  {{ 'dispo.presets' | translate }}
  <i nz-icon nzType="down"></i>
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="dispo-filter-dropdown">
    <li nz-menu-item *ngFor="let preset of presets" (click)="setPreset(preset)">
      {{ preset.label }}
    </li>
  </ul>
</nz-dropdown-menu>
