import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Provider,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import * as _ from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FrontEndConfig, JsonSchema } from '@timecount/core';

import { SchemaService } from '../../core/schema.service';

export const CUSTOM_FIELD_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomFieldsComponent),
  multi: true,
};

@Component({
  selector: 'tc-hub-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
  providers: [CUSTOM_FIELD_VALUE_ACCESSOR],
})
export class CustomFieldsComponent implements ControlValueAccessor, OnDestroy {
  @Input()
  @HostBinding('class.as-header')
  asHeader = false;

  @Input()
  @HostBinding('class.as-row')
  asRow = false;

  @HostBinding('class.is-admin')
  tcIsAdmin = false;

  @Input() set section(value: string) {
    this._section = value;
    this._setConfigs();
  }
  get section(): string {
    return this._section;
  }
  private _section: string;

  public configs: FrontEndConfig<'form' | 'schema'>[];
  public formArray: FormArray;

  private _initialValue: Record<string, unknown>;
  private _isDisabled: boolean;
  private _destroyed$: Subject<void> = new Subject();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  constructor(
    private _host: ElementRef<HTMLElement>,
    private _formBuilder: FormBuilder,
    private _schemaService: SchemaService,
    private _clipboard: Clipboard,
    private translateService: TranslateService,
    @Inject('Flash') protected flash,
  ) {
    // TODO: This should be replaced by the `TcConfigService` once it has
    // support to all window configs
    const { superadmin, support } = window.config.user;
    this.tcIsAdmin = superadmin || support;

    this.formArray = this._formBuilder.array([]);
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // Public Methods
  // ---------------------------------------------------------------------------

  public onBlur() {
    this.onTouched();
  }

  copySchemaKey() {
    this._clipboard.copy(this.section);
    this.flash.create(this.translateService.instant('copied_to_clipboard'));
  }

  openSchema(config: FrontEndConfig<'form' | 'schema'>) {
    if (!config) {
      return;
    }

    this._schemaService.openSchemaEditor(config);
  }

  // ---------------------------------------------------------------------------
  // Abstract Implementation
  // ---------------------------------------------------------------------------

  public writeValue(obj: unknown): void {
    if (this.configs) {
      this._buildFormArray(obj);
    } else {
      this._initialValue = obj as Record<string, unknown>;
    }
  }

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (!this.configs) {
      this._isDisabled = isDisabled;
    }
    // NOTE: The JsonSchema can't revert from a read-only state yet.
    else if (isDisabled) {
      this._setDisabled();
    }
  }

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  private _setConfigs() {
    this._schemaService.getConfigsForKey(this.section).subscribe((configs) => {
      if (configs?.length > 0) {
        this.configs = configs;

        this._buildFormArray(this._initialValue);
      }
      // NOTE: If there's no config and it's not an admin, we should remove the
      // component from the DOM, so that it doesn't influence in the grid flow
      // layout. If it's an admin, but as a row, we should also remove it as, in
      // this case, the admin info should appear only in the header.
      else {
        this.formArray.clear();
        this.onChange({});

        if (!this.tcIsAdmin || this.asRow) {
          // HACK: There's no practical way to self-destroy a component, so we
          // need to call the `ngOnDestroy` method manually. It would also not
          // remove any listeners or host bindings, so it's not a good practice.
          this.ngOnDestroy();
          this._host.nativeElement.remove();
        }
      }
      this._initialValue = undefined;
    });
  }

  private _buildFormArray(values = {}) {
    this.formArray.clear();

    this.configs.forEach(() => this.formArray.push(new FormControl(values)));

    if (this._isDisabled) {
      this._setDisabled();
    }

    this.formArray.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((values) => {
        const combinedValue = _.merge({}, ...values);
        this.onChange(combinedValue);
      });
  }

  private _setDisabled() {
    this.configs.forEach((config, index) => {
      this.configs[index] = {
        ...config,
        data: new JsonSchema(config.data).asReadOnly(),
      };
    });
  }
}
