import {
  add,
  addSeconds,
  differenceInSeconds,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  intervalToDuration,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  sub,
} from 'date-fns';

import { TcOffsetLength } from '../number';
import { WeekDayIndex } from '../date';

import {
  getMinutesSincePreviousMidnight,
  getMinutesUntilNextMidnight,
  getUTCDate,
  isValidDate,
  setTimeToDate,
} from './date-helpers';

export function isValidInterval(
  interval: Interval,
  canBeTheSame = false,
): boolean {
  const { start, end } = interval ?? { start: undefined, end: undefined };

  return (
    isValidDate(start) &&
    isValidDate(end) &&
    (isAfter(end as Date, start as Date) ||
      (canBeTheSame && !isBefore(end as Date, start as Date)))
  );
}

/**
 * This is an extension of the `date-fns`'s `isWithinInterva()` helper that
 * alows to define the inclusive condition, which is always `true` in their case
 */
export function isDateWithinInterval(
  date: Date,
  interval: Interval,
  options = { inclusive: true },
): boolean {
  if (!isValidDate(date)) {
    throw new Error('The passed Date must be valid');
  }

  const { inclusive } = options;

  return (
    (inclusive && isWithinInterval(date, interval)) ||
    (!inclusive &&
      isWithinInterval(date, interval) &&
      !isEqual(date, interval.start) &&
      !isEqual(date, interval.end))
  );
}

/**
 * Checks if the provided weekDay index is within the provided interval
 */
export function isWeekdayInInterval(
  weekdayIndex: WeekDayIndex,
  interval: Interval,
): boolean {
  return (
    !isValidInterval(interval) ||
    eachDayOfInterval(interval)
      .map((day: Date) => day.getDay())
      .includes(weekdayIndex)
  );
}

/**
 * Returns daily intervals for each day within the specified range
 */
export function splitIntervalInDays(
  interval: Interval,
  { ignoreStartAndEndTimes }: { ignoreStartAndEndTimes?: boolean } = {},
): Interval[] {
  return eachDayOfInterval(interval).map((day, index, array) => {
    return {
      start: index > 0 || ignoreStartAndEndTimes ? day : interval.start,
      end:
        index < array.length - 1 || ignoreStartAndEndTimes
          ? endOfDay(interval.start)
          : interval.end,
    };
  });
}

/**
 * Check if the provided Interval is longet than the provided Duration.
 *
 * Only `days`, `hours` and `minutes` of the Duration will be checked.
 */
export function isIntervalLongerThanDuration(
  interval: Interval,
  duration: Duration,
) {
  if (!isValidInterval(interval, true)) {
    throw new Error('Invalid Interval');
  }

  if (!duration) {
    throw new Error('Duration is missing');
  }

  return (
    differenceInSeconds(interval.start, interval.end) +
      durationInSeconds(duration) <
    0
  );
}

/**
 * Check if the provided interval has a Duration shorter than 24h
 */
export function isIntervalShorterThan24Hours(interval: Interval): boolean {
  if (!isValidInterval(interval, true)) {
    throw new Error('Invalid Interval');
  }

  return !isIntervalLongerThanDuration(interval, {
    hours: 23,
    minutes: 59,
    seconds: 59,
  });
}

/**
 * Updates an interval's end so that it doesn't exceed the provided max duration
 */
export function getMaxDurationInterval(
  interval: Interval,
  maxDuration: Duration,
): Interval {
  const maxEnd = add(interval.start, maxDuration);

  if (isAfter(interval.end, maxEnd)) {
    interval.end = maxEnd;
  }

  return interval;
}

/**
 * This extends `date-fns`'s `eachDayOfInterval` method with the option to pass
 * start and end offsets from midnight, which will define if the start and/or
 * end days should be excluded from the list.
 *
 * @export
 * @param interval
 * @param options
 * @returns
 */
export function getIntervalDays(
  interval: Interval,
  // FIXME: The options default values are useless when any of its properties
  // is passed as the whole object is replaced.
  options: {
    ignoreMidnightEnd?: boolean;
    startOffset?: number;
    endOffset?: number;
  } = {
    ignoreMidnightEnd: true,
    startOffset: 0,
    endOffset: 0,
  },
): Date[] {
  let { start, end } = interval;
  const { ignoreMidnightEnd, startOffset, endOffset } = options;

  if (!isSameDay(interval.start, interval.end)) {
    const minutesFromStartToMidnight = getMinutesUntilNextMidnight(
      interval.start as Date,
    );
    const minutesFromMidnightToEnd = getMinutesSincePreviousMidnight(
      interval.end as Date,
    );

    const shouldStartNextDay =
      minutesFromStartToMidnight > 0 &&
      startOffset > minutesFromStartToMidnight;

    const shouldEndDayBefore =
      (ignoreMidnightEnd && isEqual(end, startOfDay(end))) ||
      (minutesFromMidnightToEnd > 0 && endOffset > minutesFromMidnightToEnd);

    const isBetweenOffsets =
      isIntervalShorterThan24Hours(interval) &&
      shouldStartNextDay &&
      shouldEndDayBefore;

    if (
      shouldStartNextDay &&
      (!isBetweenOffsets ||
        minutesFromStartToMidnight <= minutesFromMidnightToEnd)
    ) {
      start = add(start, { days: 1 });
    }

    if (
      shouldEndDayBefore &&
      (!isBetweenOffsets ||
        minutesFromStartToMidnight > minutesFromMidnightToEnd)
    ) {
      end = sub(end, { days: 1 });
    }
  }

  return eachDayOfInterval({
    start: startOfDay(start),
    end: startOfDay(end),
  }).sort((a, b) => a.getTime() - b.getTime());
}

/**
 * Transform a length to an interval.
 *
 * If no parameter is passed, an 24h interval from the start of today will be
 * returned.
 *
 * @param [length={ hours: 24 }] The length to be converted.
 * @param [offset={ seconds: 0 }] The interval's start offset
 * @param [baseDate=new Date()] The Date to be used as the interval's base
 */
export function lengthToInterval(
  length: Duration = { hours: 24 },
  offset: Duration = { seconds: 0 },
  baseDate = new Date(),
): Interval {
  if (!length || !offset) {
    throw new Error('Missing length or offset');
  }

  const start = add(startOfDay(baseDate), offset);
  const end = add(start, length);

  return { start, end };
}

// TODO: Add Unit Tests
/**
 * Get the number of days that fit in the provided Duration.
 */
export function durationInDays(duration: Duration): number {
  return Math.floor(durationInSeconds(duration) / (24 * 60 * 60));
}

/**
 * Get the number of minutes in the provided duration.
 */
export function durationInMinutes(duration: Duration): number {
  return (
    (duration?.days ?? 0) * 24 * 60 +
    (duration?.hours ?? 0) * 60 +
    (duration?.minutes ?? 0)
  );
}

/**
 * Checks if a value is of type Duration
 */
export function isDurationType(value: unknown): boolean {
  if (typeof value !== 'object' || value === null) return false;

  const validKeys = new Set([
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
  ]);

  return Object.entries(value as Record<string, any>).every(
    ([key, value]) => validKeys.has(key) && typeof value === 'number',
  );
}

/**
 * Check if a duration is 0
 */
export function hasZeroDuration(duration: Duration): boolean {
  return durationInSeconds(duration) === 0;
}

/**
 * Get the number of seconds in the provided duration.
 */
export function durationInSeconds(duration: Duration): number {
  return durationInMinutes(duration) * 60 + (duration?.seconds ?? 0);
}

/**
 *  Get the duration from the provided number of minutes
 */
export function minutesToDuration(totalMinutes: number): Duration {
  return { hours: Math.floor(totalMinutes / 60), minutes: totalMinutes % 60 };
}

// TODO: Add Unit Tests
/**
 * Convert a number of hours to a Duration object
 *
 * Optionally, this also allows to round down by a second all day related hours,
 * which is useful to use the Duration to calculate
 *
 * @export
 * @param hours The number of hours to be converted
 * @param [options.limitDayRelatedHours] If set to true, it will round down by a
 * second all the hours that are exact days (e.g. 24, 48, 72 etc).
 */
export function hoursToDuration(
  hours: number,
  options?: { limitDayRelatedHours: boolean },
): Duration {
  const duration = { hours };
  return options.limitDayRelatedHours && hours % 24 === 0
    ? { seconds: durationInSeconds(duration) - 1 }
    : duration;
}

/**
 * Returns a list of intervals representing the periods of time between the intervals of the given list
 */
export function getBreakIntervalsFromIntervalList(
  intervalList: Interval[],
): Interval[] {
  const breaksList = [];
  if (intervalList?.length > 1) {
    for (let i = 0; i < intervalList.length - 1; i++)
      breaksList.push({
        start: intervalList[i].end,
        end: intervalList[i + 1].start,
      });
  }
  return breaksList;
}

/**
 * Get duration in minutes of the interval array, without considering the breaks between intervals
 */
export function getMinutesFromIntervalList(intervalList: Interval[]): number {
  if (
    !Array.isArray(intervalList) ||
    intervalList.length === 0 ||
    (intervalList.length === 1 && !isValidInterval(intervalList[0]))
  ) {
    return 0;
  }
  let totalMinutes = 0;
  intervalList
    .filter((interval) => isValidInterval(interval))
    .forEach((interval) => {
      totalMinutes += durationInMinutes(intervalToDuration(interval));
    });
  return totalMinutes;
}

/**
 * Get the limit dates from an interval list
 */
export function getWholeInterval(intervalList): Interval {
  if (!intervalList) {
    return {
      start: undefined,
      end: undefined,
    };
  }
  return {
    start: intervalList ? intervalList[0]?.start : undefined,
    end: intervalList ? intervalList[intervalList.length - 1]?.end : undefined,
  };
}

/**
 * Get the duration in minutes of an interval list
 */
export function getTotalMinutesFromIntervalList(
  intervalList: Interval[],
): number {
  if (
    !intervalList ||
    intervalList.length === 0 ||
    (intervalList.length === 1 && !isValidInterval(intervalList[0]))
  ) {
    return 0;
  }
  const wholeInterval = getWholeInterval(intervalList);
  return durationInMinutes(
    isValidInterval(wholeInterval)
      ? intervalToDuration(wholeInterval)
      : { hours: 0 },
  );
}

/**
 * Check if the provided duration is longer than 24 hours
 */
export function isDurationLongerThan24Hours(duration: Duration): boolean {
  return durationInSeconds(duration) > durationInSeconds({ hours: 24 });
}

/**
 * Gets the duration of the Interval in minutes.
 *
 * @param options.ignoreSummerTime (Optional)
 * If true, the duration will be calculated ignoring the timezone offset of the
 * start and end dates.
 *
 * @returns number of minutes between the start and end dates or zero if not a
 * valid interval
 */
export function intervalInMinutes(
  interval: Interval,
  options = { ignoreSummerTime: true },
): number {
  let totalMinutes = 0;

  if (isValidInterval(interval)) {
    totalMinutes = durationInMinutes(intervalToDuration(interval));

    if (options.ignoreSummerTime) {
      totalMinutes -=
        getUTCDate(interval.end).getTimezoneOffset() -
        getUTCDate(interval.start).getTimezoneOffset();
    }
  }

  return totalMinutes;
}

/**
 * Transforms an interval to a TcOffsetLength object using seconds as unit.
 *
 * The offset is defined from the start of the day of the interval's start date.
 *
 * @param interval The interval to be transformed.
 * @return returns interval's offset and length in seconds.
 *
 * @see TcOffsetLength
 */
export function intervalToOffsetLength(interval: Interval): TcOffsetLength {
  const { start, end } = interval;

  return {
    offset: isValidDate(start)
      ? differenceInSeconds(start, startOfDay(start))
      : undefined,
    length:
      isValidDate(start) && isValidDate(end)
        ? differenceInSeconds(end, start)
        : undefined,
  };
}

/**
 * Transforms a provided TcOffsetLength object that uses seconds as unit into an
 * Interval, using the provided base date.
 *
 * The offset is measured from the start of the day of the base date.
 *
 * @param source The TcOffsetLength in seconds
 * @param [baseDate=new Date()] The Date to be used base.
 * @return An Interval object.
 *
 * @see TcOffsetLength
 */
export function offsetLengthToInterval(
  source: TcOffsetLength,
  baseDate = new Date(),
): Interval {
  const { offset, length } = source;
  if (
    (!Number.isInteger(offset) && offset !== null) ||
    (!Number.isInteger(length) && length !== null)
  ) {
    throw new Error('Both offset and length must be integers.');
  }

  const startOfBaseDate = startOfDay(baseDate);

  return {
    start: addSeconds(startOfBaseDate, offset),
    end: addSeconds(startOfBaseDate, offset + length),
  };
}

/**
 * Gets the interval of the month of passed date
 *
 * @param [boundaries={ startOffset: 0, endOffset: 0 }] (Optional) The number of
 * months before and after the selected month to be included in the interval
 */
export function getDateMonthInterval(
  monthDate: Date,
  boundaries = { startOffset: { months: 1 }, endOffset: { months: 1 } },
): Interval {
  return <Interval>{
    start: startOfMonth(
      sub(startOfMonth(monthDate), {
        ...boundaries.startOffset,
      }),
    ),
    end: endOfMonth(add(startOfMonth(monthDate), { ...boundaries.endOffset })),
  };
}

/**
 * Update the dates of an Interval based on another date.
 *
 * @param intervalSource The Interval to be updated
 * @param dateSource Where to get the date from
 */
export function setDateToInterval(
  intervalSource: Interval,
  dateSource: Date,
): Interval {
  if (!isValidDate(dateSource)) {
    throw new Error('Invalid Date Source');
  }

  const updatedInterval = { start: null, end: null };

  const originalInterval = intervalSource as Interval;
  const { start: originalStart, end: originalEnd } = originalInterval;

  if (isValidDate(originalStart)) {
    updatedInterval.start = setTimeToDate(dateSource, originalStart);
  }

  if (isValidDate(originalEnd)) {
    // We need to check how many days we need to add to the end, to keep the
    // difference when updating the end date based on the start.
    const daysToAdd =
      isValidInterval(originalInterval) &&
      eachDayOfInterval(originalInterval).length - 1;

    // If the start was not set, use the source date instead
    const possibleEnd = updatedInterval.start ?? dateSource;

    updatedInterval.end = setTimeToDate(
      add(possibleEnd, { days: daysToAdd }),
      originalEnd,
    );
  }

  return updatedInterval;
}
