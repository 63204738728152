<fieldset
  [formGroup]="formGroup"
  [tcPanel]="shouldShowPanel"
  [tcTitle]="'account/preference.legends.smtp_default_headers' | translate"
>
  <tc-input-email-web
    label="{{ 'account/preference.attrs.smtp_api_sender' | translate }}"
    formControlName="sender"
    [tcConfig]="{
      headerType: true
    }"
  ></tc-input-email-web>
  <tc-input-email-web
    label="{{ 'account/preference.attrs.smtp_api_carbon_copy' | translate }}"
    formControlName="carbon_copy"
    [tcConfig]="{
      headerType: true,
      multiple: true
    }"
  ></tc-input-email-web>

  <tc-input-email-web
    label="{{
      'account/preference.attrs.smtp_api_blind_carbon_copy' | translate
    }}"
    formControlName="blind_carbon_copy"
    [tcConfig]="{
      headerType: true,
      multiple: true
    }"
  ></tc-input-email-web>
</fieldset>
