import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isFirstDayOfMonth } from 'date-fns';

import { DateInfo } from '@timecount/utils';

@Component({
  selector: 'tc-hub-dispo-shift-grid-header-days',
  templateUrl: './shift-grid-header-days.component.html',
  styleUrls: ['./shift-grid-header-days.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispoShiftGridHeaderDaysComponent implements OnInit {
  @Input() dates: Observable<DateInfo[]>;
  @Input() shifts: Observable<any[]>;

  shiftHeaders$: Observable<any[]>;

  ngOnInit() {
    this.shiftHeaders$ = this.shifts.pipe(
      map((shifts: any[]) => {
        const headers = [];
        const dayLength = 60 * 60 * 24;

        shifts.forEach((shift) => {
          const header = Object.assign({}, shift);
          header.x = header.offset / dayLength;
          header.y = (header.offset + header.length) / dayLength;
          header.left = header.x * 100 + '%';
          header.right = 100 - header.y * 100 + '%';

          headers.push(header);
        });

        return headers.sort((r1, r2) => (r1.x > r2.x ? -1 : 1));
      }),
    );
  }

  isFirstOfMonth(day: DateInfo) {
    return isFirstDayOfMonth(day.date);
  }
}
