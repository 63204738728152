import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DateUnicodeFormat, stringifyDate } from '@timecount/utils';
import { TcDatePickerComponent, TcFormPickersService } from '@timecount/ui';

import { DispoFocusService } from '../dispo-focus.service';

@Component({
  selector: 'tc-hub-dispo-date',
  templateUrl: './dispo-date.component.html',
  styleUrls: ['./dispo-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispoDateComponent implements OnInit, OnDestroy {
  @ViewChild('datePickerTarget', { read: ViewContainerRef }) datePickerTarget;

  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  form: UntypedFormGroup;
  focusDate: Date;
  highlightedDates: Date[];

  visibleDateSub: Subscription;
  focusDateSub: Subscription;

  private destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dispoFocus: DispoFocusService,
    private hotkeysService: HotkeysService,
    private formPickersService: TcFormPickersService,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      date: [],
    });

    this.dispoFocus
      .date()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date) => {
        this.focusDate = date;
        this.form.patchValue({
          date: date,
        });
      });

    this.dispoFocus
      .dates()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((dates) => {
        this.highlightedDates = dates;
      });

    this.setHotkeys();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();

    // this.hotkeysService.remove()
  }

  openDatePicker(_event: MouseEvent) {
    const picker = this.formPickersService.open('date', this.datePickerTarget, {
      initialValue: this.focusDate,
    }) as TcDatePickerComponent;

    picker.highlightedDates = this.formatDates(this.highlightedDates);

    picker.valueChange$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: Date) => {
        this.dateChange.emit(value);
      });
  }

  todayDate() {
    this.dateChange.emit(new Date());
  }

  moveDate(days: number) {
    const movedDate = new Date(this.focusDate);
    movedDate.setDate(movedDate.getDate() + days);

    this.dateChange.emit(movedDate);
  }

  // ---------------
  // Private Methods
  // ---------------

  private formatDates(dates: Date[]): string[] {
    return dates.map((date) => stringifyDate(date, DateUnicodeFormat.date));
  }

  private setHotkeys() {
    this.hotkeysService.add(
      new Hotkey('left', (event: KeyboardEvent): boolean => {
        this.moveDate(-1);
        return false; // Prevent bubbling
      }),
    );

    this.hotkeysService.add(
      new Hotkey('shift+left', (event: KeyboardEvent): boolean => {
        this.moveDate(-7);
        return false; // Prevent bubbling
      }),
    );

    this.hotkeysService.add(
      new Hotkey('right', (event: KeyboardEvent): boolean => {
        this.moveDate(1);
        return false; // Prevent bubbling
      }),
    );

    this.hotkeysService.add(
      new Hotkey('shift+right', (event: KeyboardEvent): boolean => {
        this.moveDate(7);
        return false; // Prevent bubbling
      }),
    );
  }
}
