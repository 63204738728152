import { HttpClient } from '@angular/common/http';
import { Deserializer, ExclusionPolicy, Strategy, Type } from 'typeserializer';
import { Injectable } from '@angular/core';

import { parseDate } from '@timecount/utils';

import { ApiErrorService } from '../api-error.service';
import { Cable } from '../cable';
import { CurrentUserService } from '../current-user.service';
import { FrameCollection } from '../frame-collection';
import { StoreService } from '../store.service';
import { DispoFocusService } from '../../dispo/dispo-focus.service';
import { LocalSettingsService } from '../local-settings.service';

@Strategy(ExclusionPolicy.NONE)
export class QualificationAccount {
  id: number;

  resource_id: number;
  resource_type: string;

  qualification_id: number;
  qualification_ids: number[];

  marker: [number, number];

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;

  title: string;
  note: string;
  @Type(Object)
  store: object;
}
@Injectable({
  providedIn: 'root',
})
export class QualificationAccountCollection extends FrameCollection {
  type = QualificationAccount;
  cache = 600;
  identifier = 'qualification_account';
  endpoint = '/api/qualification_accounts/range';

  constructor(
    http: HttpClient,
    cable: Cable,
    dispoFocus: DispoFocusService,
    store: StoreService,
    errorHandler: ApiErrorService,
    currentUser: CurrentUserService,
    localSettingsService: LocalSettingsService,
  ) {
    super(
      http,
      cable,
      dispoFocus,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
    );
  }

  forResource(resource) {
    const filterFunc = (x) =>
      x.resource_id === resource.id && x.resource_type === resource.type;
    const filterLookup = `resource_id: ${resource.id}|resource_type: ${resource.type}`;

    return this.filter(filterFunc, filterLookup);
  }
}
