import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';

import { ModalService } from '@timecount/ui';

import { Action } from '../../core/types/action';
import { EntryType } from '../../core/types/entry-type';
import { ActionType } from '../../core/types/action-type';
import { ValidationService } from '../../dispo/collections/validation.service';

import { TcAggregationsImportFormComponent } from './aggregations-import-form/aggregations-import-form.component';
import { TcAggregationsBillFormComponent } from './aggregations-bill-form/aggregations-bill-form.component';
import { TcAggregationsPayFormComponent } from './aggregations-pay-form/aggregations-pay-form.component';
import { AggregationCollection } from './aggregation.collection';
import { Aggregation } from './aggregation.model';

@Injectable({
  providedIn: 'root',
})
export class AggregationService {
  private translations: { [key: string]: string } = {};
  constructor(
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private _aggregationCollection: AggregationCollection,
    private _validationService: ValidationService,
  ) {
    this._translateService
      .get(['aggregation.import', 'aggregation.bill', 'aggregation.pay'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  openImportModal(object: Aggregation) {
    return this._modalService.open(TcAggregationsImportFormComponent, {
      data: {
        isModal: true,
        tcInitialValue: object,
      },
      modalTitle: this.translations['aggregation.import'],
    });
  }

  openBillingModal(object: Aggregation) {
    this._modalService.open(TcAggregationsBillFormComponent, {
      data: {
        isModal: true,
        tcInitialValue: object,
      },
      modalTitle: this.translations['aggregation.bill'],
    });
  }

  openPayingModal(object: Aggregation) {
    this._modalService.open(TcAggregationsPayFormComponent, {
      data: {
        isModal: true,
        tcInitialValue: object,
      },
      modalTitle: this.translations['aggregation.pay'],
    });
  }

  delete(object) {
    const action = <Action>{
      id: uuid(),
      entry: object,
      entry_type: EntryType.Aggregation,
      type: ActionType.delete,
      validations: (item, stack) => {
        return this._aggregationCollection
          .forProject(object.project_id)
          .remoteValidations(item, stack, ActionType.delete);
      },
      errors: [],
    };
    this._validationService.run([action], true);
  }
}
