<div
  *ngIf="valuePresent()"
  class="widget"
  nz-tooltip
  [ngClass]="widget.html_class"
  [nzTooltipTitle]="widget.tooltip"
>
  <div
    class="widget-indicator"
    *ngIf="widget.colorize"
    [ngClass]="indicatorClass()"
  ></div>
  <div class="widget-content">
    <div class="widget-header">
      <i
        *ngIf="widget.icon"
        nz-icon
        [nzType]="widget.icon"
        nzTheme="outline"
        class="widget-icon"
      ></i>
      <span *ngIf="size === 'large'">{{ widget.tooltip }}</span>
    </div>
    <div class="widget-body">
      {{ widget.prefix }}
      <span class="widget-data">
        <span class="widget-value">{{ valueFor() }}</span>
        <ng-container *ngIf="widget.compare || widget.compare_calc">
          <span class="widget-compare">/{{ compareFor() }}</span>
        </ng-container>
      </span>
      {{ widget.suffix }}
    </div>
  </div>
</div>
