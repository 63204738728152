export enum CollectionType {
  Department = 'Department',
  Contact = 'Contact',
  Job = 'Job',
  Venue = 'Venue',
  Tariff = 'Tariff',
  Employee = 'Employee',
  Contractor = 'Contractor',
  Customer = 'Customer',
  Project = 'Project',
  ProjectSets = 'ProjectSets',
  Qualification = 'Qualification',
  DispoResource = 'DispoResource',
  DispoPlan = 'DispoPlan',
  DispoSchedule = 'DispoSchedule',
  DispoTask = 'DispoTask',
  DispoAnnouncement = 'DispoAnnouncement',
  DispoAssignment = 'DispoAssignment',
  DispoInvitation = 'DispoInvitation',
  DispoAvailability = 'DispoAvailability',
  DispoGroup = 'DispoGroup',
  DispoRole = 'DispoRole',
  DispoShift = 'DispoShift',
  DispoAvailabilityType = 'DispoAvailabilityType',
  DispoAvailabilitySource = 'DispoAvailabilitySource',
  ResourceTemplateCollection = 'ResourceTemplateCollection',
  DispoTaskTemplateCollection = 'DispoTaskTemplateCollection',
  WorkEntryBatchTemplate = 'WorkEntryBatchTemplate',
  MessageTemplate = 'MessageTemplate',
  Attachment = 'Attachment',
  AttachmentRelease = 'AttachmentRelease',
  Tag = 'TagCollection',
  EmploymentMode = 'EmploymentMode',
  DispoTaskTemplate = 'DispoTaskTemplate',
  EmployeeTag = 'EmployeeTag',
  DrivingLicenseTag = 'DrivingLicenseTag',
  AccountRole = 'AccountRole',
  User = 'User',
  Material = 'Material',
  Vat = 'Vat',
}
