import { Environment } from '@timecount/core';

import { buildInfo } from './build-info';

export const environment: Environment = {
  name: 'test',
  production: true,
  platform: 'web',
  sentry: {
    dsn: 'https://62860d823d294beab2e545091d0564ce@o131845.ingest.sentry.io/291219',
  },
  buildInfo,
};
