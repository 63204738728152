import { Component, EventEmitter, Output } from '@angular/core';

import { TcIssueCollector } from '../../shared';

import { TcMenuItemHelpService } from './menu-item-help.service';

@Component({
  selector: 'tc-hub-menu-item-help',
  templateUrl: './menu-item-help.component.html',
  styleUrls: ['./menu-item-help.component.scss'],
})
export class MenuItemHelpComponent {
  issueCollectors = this.menuItemHelpService.issueCollectors;

  @Output() tcDocClick = new EventEmitter();

  constructor(private menuItemHelpService: TcMenuItemHelpService) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  showDialog(issueCollector: TcIssueCollector): void {
    this.menuItemHelpService.showDialog(issueCollector);
  }
}
