<div
  #container
  [ngClass]="
    'ui-contextmenu ui-widget ui-widget-content ui-corner-all ui-shadow'
  "
  [class]="styleClass"
  [ngStyle]="style"
>
  <tc-hub-context-menu-sub
    [item]="model"
    [parentActive]="parentActive"
    root="root"
  ></tc-hub-context-menu-sub>
</div>
