import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { DispoFilterService } from '../../shared/filter/filter.service';
import { QualificationCollection } from '../../core/collections/qualification';
import { DispoSelectorService } from '../selector/selector.service';
import { AnnouncementService } from '../actions/announcement.service';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../collections/announcement';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { MessageService } from '../actions/message.service';
import { CurrentUserService } from '../../core/current-user.service';
import { TaskService } from '../actions/task.service';
import { DispoTaskCollection, Task } from '../collections/task';
import { AssignmentService } from '../actions/assignment.service';
import { JobCollection } from '../../jobs/job.collection';
import { DispoGroupCollection } from '../collections/group';
import { DispoRoleCollection } from '../collections/role';
import { DispoCombinedTaskCollection } from '../collections/combined_task';
import { TimesheetService } from '../../timesheets/timesheet.service';
import { DispoAssignmentCollection } from '../collections/assignment';
import { Assignment } from '../collections/assignment.model';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuTask {
  private taskFilters: any[] = [];
  private groups: any[] = [];
  private roles: any[] = [];
  private jobs: any[] = [];
  private qualifications: any[] = [];
  private taskMessageTemplates: any[] = [];
  private announcementTemplates: any[] = [];
  private announcementMessageTemplates: any[] = [];
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private taskActions: TaskService,
    private assignmentActions: AssignmentService,
    private announcementActions: AnnouncementService,
    private timesheetActions: TimesheetService,
    private messageActions: MessageService,
    private filterService: DispoFilterService,
    private selectorService: DispoSelectorService,
    private announcementCollection: DispoAnnouncementCollection,
    private jobCollection: JobCollection,
    private qualificationCollection: QualificationCollection,
    private groupCollection: DispoGroupCollection,
    private roleCollection: DispoRoleCollection,
    private taskCollection: DispoTaskCollection,
    private combinedTaskCollection: DispoCombinedTaskCollection,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private currentUser: CurrentUserService,
    private assignmentCollection: DispoAssignmentCollection,
  ) {
    this.translateService
      .get([
        'dispo/task.actions.activate',
        'dispo/task.actions.deactivate',
        'dispo/task.actions.edit',
        'dispo/task.actions.public',
        'dispo/task.actions.track_time',
        'dispo/task.actions.delete',
        'dispo/task.actions.move',
        'dispo/task.actions.assign_contractor',
        'dispo/task.actions.message',
        'dispo/task.menu.message',
        'dispo/announcement.actions.edit',
        'dispo/announcement.actions.invite',
        'dispo/announcement.actions.invitations',
        'dispo/announcement.actions.invitations_for_task',
        'dispo/announcement.actions.message',
        'dispo/announcement.menu.message',
        'dispo/task.actions.hide',
        'dispo/task.actions.show',
        'dispo/task.actions.confirmation',
        'dispo/task.actions.hide_all_tasks',
        'dispo/task.actions.show_all_tasks',
        'dispo/task.actions.request_all_tasks',
        'dispo/task.actions.enable_time_tracking',
        'dispo/announcement.actions.detach_tasks',
        'dispo/announcement.actions.append_tasks',
        'dispo/announcement.actions.create_with_tasks',
        'dispo/announcement.menu.template',
        'dispo/task.menu.filter',
        'dispo/task.menu.task',
        'dispo/task.menu.public',
        'dispo/task.menu.announcement',
        'trails.actions.resource',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.jobCollection.all().subscribe((jobs) => {
      this.jobs = jobs;
    });

    this.qualificationCollection.all().subscribe((qualifications) => {
      this.qualifications = qualifications;
    });

    this.groupCollection.all().subscribe((groups) => {
      this.groups = groups;
    });

    this.roleCollection.all().subscribe((roles) => {
      this.roles = roles;
    });

    this.resourceTemplateCollection
      .setForResource('dispo.announcements')
      .all()
      .subscribe((templates) => {
        this.announcementTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.task')
      .all()
      .subscribe((templates) => {
        this.taskMessageTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.announcement')
      .all()
      .subscribe((templates) => {
        this.announcementMessageTemplates = templates;
      });

    this.filterService.getPresets('dispo.tasks').subscribe((presets) => {
      this.taskFilters = presets;
    });
  }

  // --------------
  // Public Methods
  // --------------

  itemsForTasks(currTasks: Task[]): Observable<any[]> {
    return this.combinedTaskCollection.observeItems(currTasks).pipe(
      map((tasks: Task[]) => {
        const taskActions = [];
        const announcementActions = [];
        const publicActions = [];
        let filterActions = [];

        filterActions = this.taskFilters.map((preset) => {
          return {
            label: preset.label,
            icon: 'filter',
            command: (event: any) => {
              // TODO: check if slots selection can be from another task
              this.selectorService.setTasks(tasks);
              this.filterService.setPreset(preset.id);
            },
          };
        });

        const draftTasks = tasks.filter((t) => t.state === 'draft');
        const publicTasks = tasks.filter((t) => t.state === 'open');
        const untrackedTasks = tasks.filter(
          (t) => t.tracking_enabled === false,
        );
        const scheduledTasks = tasks.filter((t) => t.schedule_id);

        if (scheduledTasks.length > 0) {
          taskActions.push({
            label: this.translations['dispo/task.actions.move'],
            icon: 'calendar',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.task.move_scheduled',
            ),
            command: (event: any) => {
              this.taskActions.moveScheduled(scheduledTasks);
            },
          });
        }

        publicActions.push({
          label: this.translations['dispo/task.actions.hide'],
          icon: 'eye-invisible',
          visible:
            publicTasks.length > 0 &&
            this.currentUser.hasAccessToSection('views.dispo.task.hide'),
          command: (event) => {
            this.taskActions.setHidden(tasks);
          },
        });

        publicActions.push({
          label: this.translations['dispo/task.actions.show'],
          icon: 'eye',
          visible:
            draftTasks.length > 0 &&
            this.currentUser.hasAccessToSection('views.dispo.task.show'),
          command: (event) => {
            this.taskActions.setPublic(tasks);
          },
        });

        publicActions.push({
          label: this.translations['dispo/task.actions.confirmation'],
          icon: 'wifi',
          visible:
            (draftTasks.length > 0 || publicTasks.length > 0) &&
            this.currentUser.hasAccessToSection('views.dispo.task.confirm'),
          command: (event) => {
            this.taskActions.requestConfirmation(tasks);
          },
        });

        publicActions.push({
          label: this.translations['dispo/task.actions.enable_time_tracking'],
          icon: 'clock-circle',
          visible:
            untrackedTasks.length > 0 &&
            this.currentUser.hasAccessToSection('views.dispo.task.track_time'),
          command: (event) => {
            this.taskActions.enableTimeTracking(tasks);
          },
        });

        announcementActions.push({
          label: this.translations['dispo/announcement.actions.append_tasks'],
          icon: 'plus-circle',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.announcement.append_tasks',
          ),
          command: (event) => {
            this.announcementActions.appendTasks(tasks);
          },
        });

        announcementActions.push({
          label:
            this.translations['dispo/announcement.actions.create_with_tasks'],
          icon: 'plus',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.announcement.create',
          ),
          command: (event) => {
            // use announcement templates instead
            const defaultTemplate = this.announcementTemplates.find(
              (t) => t.default,
            ) || { template: {} };
            this.announcementActions.createFromTasksAndTemplate(
              tasks,
              defaultTemplate.template,
            );
          },
        });

        const announcementTemplateActions: any[] = this.announcementTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: (event: any) => {
                const templ = Object.assign({}, template.template);
                this.announcementActions.createFromTasksAndTemplate(
                  tasks,
                  templ,
                );
              },
            };
          });

        announcementActions.push({
          label: this.translations['dispo/announcement.menu.template'],
          icon: 'copy',
          visible:
            announcementTemplateActions.length > 0 &&
            this.currentUser.hasAccessToSection(
              'views.dispo.announcement.create',
            ),
          items: announcementTemplateActions,
        });

        const items = [
          ...(filterActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/task.menu.filter'],
                },
                ...filterActions,
              ]
            : []),
          ...(taskActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/task.menu.task'],
                },
                ...taskActions,
              ]
            : []),
          ...(publicActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/task.menu.public'],
                },
                ...publicActions,
              ]
            : []),
          ...(announcementActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label: this.translations['dispo/task.menu.announcement'],
                },
                ...announcementActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }

  itemsForTask(currTask: Task): Observable<any[]> {
    return combineLatest(
      this.combinedTaskCollection.observeItem(currTask),
      this.announcementCollection.forTask(currTask),
      this.assignmentCollection.forTask(currTask),
    ).pipe(
      map(
        ([task, announcements, assignments]: [
          Task,
          Announcement[],
          Assignment[],
        ]) => {
          if (!task) {
            return [];
          }

          const items = [
            ...this._getFilterActions(task),
            ...this._getTaskActions(task, announcements, {
              canTrackTime: !!assignments.length,
            }),
            ...this._getPublicActions(task),
            ...this._getAnnouncementActions(announcements, task),
          ];

          return items;
        },
      ),
    );
  }

  // ---------------
  // Private Methods
  // ---------------

  private _injectHeader(items: unknown[], labelKey: string): unknown[] {
    if (items?.length) {
      items.unshift(
        { separator: true },
        {
          header: true,
          label: this.translations[labelKey],
        },
      );
    }

    return items ?? [];
  }

  private _getFilterActions(task: Task): unknown[] {
    const filterActions = this.taskFilters.map((preset) => {
      return {
        label: preset.label,
        icon: 'filter',
        command: () => {
          this.selectorService.setTasks([task]);
          this.filterService.setPreset(preset.id);
        },
      };
    });
    return this._injectHeader(filterActions, 'dispo/task.menu.filter');
  }

  private _getTaskActions(
    task: Task,
    announcements: Announcement[],
    options: { canTrackTime?: boolean } = {},
  ): unknown[] {
    const taskActions = [
      {
        label: this.translations['dispo/task.actions.edit'],
        icon: 'edit',
        visible: this.currentUser.hasAccessToSection(
          'views.dispo.task.general',
        ),
        command: () => {
          this.taskActions.general(task);
        },
      },
      {
        label: this.translations['dispo/task.actions.public'],
        icon: 'eye',
        visible: this.currentUser.hasAccessToSection('views.dispo.task.public'),
        command: () => {
          this.taskActions.public(task);
        },
      },
    ];

    if (task.schedule_id) {
      taskActions.push({
        label: this.translations['dispo/task.actions.move'],
        icon: 'eye',
        visible: this.currentUser.hasAccessToSection(
          'views.dispo.task.move_scheduled',
        ),
        command: () => {
          this.taskActions.moveScheduled([task]);
        },
      });
    } else {
      // Insert the `delete` action in the index 1
      taskActions.splice(1, 0, {
        label: this.translations['dispo/task.actions.delete'],
        icon: 'delete',
        visible: this.currentUser.hasAccessToSection('views.dispo.task.delete'),
        command: () => {
          this.taskActions.delete(task);
        },
      });

      taskActions.push({
        label: this.translations['dispo/task.actions.move'],
        icon: 'calendar',
        visible: this.currentUser.hasAccessToSection('views.dispo.task.move'),
        command: () => {
          this.taskActions.move(task);
        },
      });
    }

    taskActions.push(...this._getAnnouncementsTaskActions(announcements, task));

    if (options.canTrackTime) {
      taskActions.push({
        label: this.translations['dispo/task.actions.track_time'],
        icon: 'clock-circle',
        visible: this.currentUser.hasAccessToSection(
          'views.dispo.assignment.multi',
        ),
        command: () => {
          this.timesheetActions.multiForTask(task);
        },
      });
    }

    taskActions.push({
      label: this.translations['trails.actions.resource'],
      icon: 'audit',
      visible:
        this.currentUser.hasAccessToFeature('logs') &&
        this.currentUser.can('index', 'PaperTrail::Version', {
          item_type: 'TcDispo::Job',
        }),
      command: () => {
        this.taskActions.viewLogs(task.id);
      },
    });

    return this._injectHeader(taskActions, 'dispo/task.menu.task');
  }

  private _getAnnouncementsTaskActions(
    announcements: Announcement[],
    task: Task,
  ) {
    const taskActions = [];
    announcements.forEach((announcement) => {
      taskActions.push({
        label: `${this.translations['dispo/announcement.actions.invitations_for_task']} ${announcement.title}`,
        icon: 'user',
        visible: this.currentUser.hasAccessToSection(
          'views.dispo.task.invitations',
        ),
        command: () => {
          this.taskActions.invitationsForTask(task, announcement);
        },
      });
    });
    return taskActions;
  }

  private _getPublicActions(task): unknown[] {
    const messageTemplates: any[] = this.taskMessageTemplates
      .filter((t) => !t.default)
      .map((template) => {
        return {
          label: template.title,
          command: () => {
            const templ = Object.assign({}, template.template);
            this.messageActions.task(task, templ);
          },
        };
      });
    const publicActions = [
      {
        label: this.translations['dispo/task.actions.message'],
        icon: 'comment',
        visible: this.currentUser.hasAccessToSection(
          'views.dispo.task.message',
        ),
        command: () => {
          this.messageActions.taskDefault(task);
        },
      },
      {
        label: this.translations['dispo/task.menu.message'],
        icon: 'copy',
        visible:
          messageTemplates.length > 0 &&
          this.currentUser.hasAccessToSection('views.dispo.task.message'),
        items: messageTemplates,
      },
      {
        label: this.translations['dispo/task.actions.hide'],
        icon: 'eye-invisible',
        visible:
          task.state === 'open' &&
          this.currentUser.hasAccessToSection('views.dispo.task.hide'),
        command: () => {
          this.taskActions.setHidden([task]);
        },
      },
      {
        label: this.translations['dispo/task.actions.show'],
        icon: 'eye',
        visible:
          task.state === 'draft' &&
          this.currentUser.hasAccessToSection('views.dispo.task.show'),
        command: () => {
          this.taskActions.setPublic([task]);
        },
      },
      {
        label: this.translations['dispo/task.actions.confirmation'],
        icon: 'wifi',
        visible: this.currentUser.hasAccessToSection(
          'views.dispo.task.confirm',
        ),
        command: () => {
          this.taskActions.requestConfirmation([task]);
        },
      },

      {
        label: this.translations['dispo/task.actions.enable_time_tracking'],
        icon: 'clock-circle',
        visible:
          task.tracking_enabled === false &&
          this.currentUser.hasAccessToSection('views.dispo.task.track_time'),
        command: () => {
          this.taskActions.enableTimeTracking([task]);
        },
      },
    ];
    return this._injectHeader(publicActions, 'dispo/task.menu.public');
  }

  private _getAnnouncementActions(announcements, task): unknown[] {
    const announcementActions = announcements.map((announcement) => {
      const announcementMessageTemplates: any[] =
        this.announcementMessageTemplates
          .filter((t) => !t.default)
          .map((template) => {
            return {
              label: template.title,
              command: () => {
                const templ = Object.assign({}, template.template);
                this.messageActions.announcement(
                  announcement,
                  new Date(task.starts_at.getTime()),
                  templ,
                );
              },
            };
          });

      const actions = [
        {
          label: this.translations['dispo/announcement.actions.edit'],
          icon: 'edit',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.announcement.general',
          ),
          command: () => {
            this.announcementActions.edit(announcement);
          },
        },
        {
          label: this.translations['dispo/announcement.actions.detach_tasks'],
          icon: 'delete',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.announcement.detach_tasks',
          ),
          command: () => {
            this.announcementActions.detachTasks(announcement, [task]);
          },
        },
        {
          label: this.translations['dispo/announcement.actions.invite'],
          icon: 'usergroup-add',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.announcement.invite',
          ),
          command: () => {
            this.announcementActions.invite(announcement);
          },
        },
        {
          label: this.translations['dispo/announcement.actions.invitations'],
          icon: 'usergroup-delete',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.announcement.invitations',
          ),
          command: () => {
            this.announcementActions.invitations(announcement);
          },
        },
        {
          label: this.translations['dispo/announcement.actions.message'],
          icon: 'comment',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.announcement.message',
          ),
          command: () => {
            this.messageActions.announcementDefault(
              announcement,
              new Date(task.starts_at.getTime()),
            );
          },
        },
        {
          label: this.translations['dispo/announcement.menu.message'],
          icon: 'copy',
          visible:
            announcementMessageTemplates.length > 0 &&
            this.currentUser.hasAccessToSection(
              'views.dispo.announcement.message',
            ),
          items: announcementMessageTemplates,
        },
      ];

      return {
        label: announcement.title,
        items: actions,
      };
    });

    announcementActions.push({
      label: this.translations['dispo/announcement.actions.append_tasks'],
      icon: 'plus-circle',
      visible: this.currentUser.hasAccessToSection(
        'views.dispo.announcement.append_tasks',
      ),
      command: () => {
        this.announcementActions.appendTasks([task]);
      },
    });

    announcementActions.push({
      label: this.translations['dispo/announcement.actions.create_with_tasks'],
      icon: 'plus',
      visible: this.currentUser.hasAccessToSection(
        'views.dispo.announcement.create',
      ),
      command: () => {
        // use announcement templates instead
        const defaultTemplate = this.announcementTemplates.find(
          (t) => t.default,
        ) || { template: {} };
        this.announcementActions.createFromTasksAndTemplate(
          [task],
          defaultTemplate.template,
        );
      },
    });

    const announcementTemplateActions: any[] = this.announcementTemplates
      .filter((t) => !t.default)
      .map((template) => {
        return {
          label: template.title,
          command: () => {
            const templ = Object.assign({}, template.template);
            this.announcementActions.createFromTasksAndTemplate([task], templ);
          },
        };
      });

    announcementActions.push({
      label: this.translations['dispo/announcement.menu.template'],
      icon: 'copy',
      visible:
        announcementTemplateActions.length > 0 &&
        this.currentUser.hasAccessToSection('views.dispo.announcement.create'),
      items: announcementTemplateActions,
    });
    return this._injectHeader(
      announcementActions,
      'dispo/task.menu.announcement',
    );
  }
}
