import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NzTabSetComponent } from 'ng-zorro-antd/tabs';
import { Subject, Subscription } from 'rxjs';

import { ModalConfig, ModalRef } from '../modal';

@Directive()
export abstract class BaseModalComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  tabs: { [key: string]: number } = {};
  selectedIndex: number;
  section: any;
  single: boolean;

  subs: Subscription[] = [];
  destroyed$ = new Subject<void>();

  @ViewChild('modalBody', { static: true, read: ElementRef })
  modalBody: ElementRef;
  @ViewChild('modalFooter', { static: true, read: ElementRef })
  modalFooter: ElementRef;

  @ViewChildren(NzTabSetComponent) modalTabs: QueryList<NzTabSetComponent>;

  constructor(protected config?: ModalConfig, protected modalRef?: ModalRef) {}

  ngOnInit() {
    this.section = this.config.data.section;
    this.selectedIndex = this.tabs[this.section] || 0;
    this.single = this.config.data.single || false;
  }

  ngAfterViewInit() {
    this.modalTabs.forEach((tab) => {
      this.subs.push(
        tab.nzSelectedIndexChange.subscribe({
          next: () => {
            this.redraw();
          },
        }),
      );
    });
    this.redraw();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.subs.forEach((s) => s.unsubscribe());
  }

  onSignal(signal) {
    if (signal.action === 'close') {
      this.modalRef.close(signal.status);
    }

    if (signal.action === 'resize') {
      setTimeout(() => {
        this.modalRef.resize();
      }, 1);
    }

    if (signal.action === 'reload') {
      Object.keys(signal.items).forEach((key) => {
        this[key] = signal.items[key];
      });
      this.modalRef.resize();
    }
  }

  redraw() {
    this.modalRef?.resize();
  }
}
