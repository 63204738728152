<tc-form-wrapper
  [isModal]="isModal"
  [isSubmitting]="isSubmitting"
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  (primaryButtonClick)="save()"
>
  <form class="form" [formGroup]="formGroup">
    <nz-tabset
      nzAnimated="false"
      nzSize="small"
      [nzSelectedIndex]="selectedIndex"
      *ngIf="!single"
    >
      <nz-tab nzTitle="{{ 'aggregation.tabs.bill' | translate }}">
        <tc-input-date-web
          formControlName="billed_at"
          label="{{ 'invoice/bill.attrs.billed_at' | translate }}"
        ></tc-input-date-web>

        <tc-input-date-web
          formControlName="performed_at"
          label="{{ 'invoice/bill.attrs.performed_at' | translate }}"
        ></tc-input-date-web>

        <tc-hub-custom-fields
          class="form-item full-width custom-fields"
          section="bill.general"
          [formGroup]="formGroup"
          formControlName="store"
        >
        </tc-hub-custom-fields>
      </nz-tab>
    </nz-tabset>
  </form>
</tc-form-wrapper>
