import { Aggregation } from '../../projects/aggregations/aggregation.model';
import { WorkEntry } from '../../projects/aggregations/work-entries/work_entry.model';
import { Project } from '../../projects/project.model';
import { Timesheet } from '../../timesheets';
import { Assignment } from '../collections/assignment.model';
import { Plan } from '../collections/plan';
import { Schedule } from '../collections/schedule';
import { Slot, Task } from '../collections/task';

export class WorkEntryDS extends WorkEntry {
  readonly type = 'WorkEntry';

  aggregation?: Aggregation;
  project: Project;
  plan?: Plan;

  schedule?: Schedule;
  task?: Task;
  assignment?: Assignment;

  slot?: Slot;
  venue?: any;
  timesheet?: Timesheet;
}
