export enum EntryBillingState {
  unbillable = 'unbillable',
  prepared = 'prepared',
  inbilling = 'inbilling',
  billed = 'billed',
  storno = 'storno',
  paid = 'paid',
}

export type BillingStateDetails = {
  color?: string;
  icon?: string;
};

export const BillingStateInfo: Record<EntryBillingState, BillingStateDetails> =
  {
    [EntryBillingState.unbillable]: {},
    [EntryBillingState.prepared]: {},
    [EntryBillingState.inbilling]: {
      color: 'warning',
      icon: 'invoice-billing',
    },
    [EntryBillingState.billed]: { color: 'success', icon: 'invoice-billing' },
    [EntryBillingState.storno]: { color: 'danger', icon: 'invoice-storno' },
    [EntryBillingState.paid]: { color: 'success', icon: 'invoice-billed' },
  };
