import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';

import { Employee } from './employee.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeCollection extends SetsCollection {
  identifier = 'employee';
  endpoint = '/api/employees';

  bindLabel = 'label';

  cache = 60 * 60;

  localSearch = ['name', 'token'];

  decorate(employee: Employee): Employee {
    employee.label = [employee.token, employee.name].filter((a) => a).join(' ');
    return employee;
  }

  visible() {
    return this.query(
      { 'filter[employee_visibility]': '1' },
      (e) => e.visibility === 1,
    );
  }

  invisible() {
    return this.query(
      { 'filter[employee_visibility]': '0' },
      (e) => e.visibility === 0,
    );
  }
}
