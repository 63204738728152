import { Injectable } from '@angular/core';

import { FrameCollection } from '../core/frame-collection';

@Injectable({
  providedIn: 'root',
})
export class ProjectCollection extends FrameCollection {
  cache = 600;
  identifier = 'project';
  bindLabel = 'label';
  endpoint = '/api/projects/range';

  remoteDeleteValidation = true;

  localSearch = ['title', 'token'];

  decorate(project: any, imports) {
    project.label = [project.token, project.title].filter((a) => a).join(' ');

    return project;
  }
}
