<nz-steps [nzCurrent]="currentStep">
  <nz-step
    nzTitle="{{ 'dispo/message.tab_type.create-s1' | translate }}"
  ></nz-step>
  <nz-step
    nzTitle="{{ 'dispo/message.tab_type.create-s2' | translate }}"
  ></nz-step>
</nz-steps>

<div class="steps">
  <div class="modal-container" *ngIf="currentStep === 0">
    <tc-hub-dmsgc-step1
      class="modal-container"
      [message]="message"
      (signal)="onSignal($event)"
    ></tc-hub-dmsgc-step1>
  </div>
  <div class="modal-container" *ngIf="currentStep === 1">
    <tc-hub-dmsgc-step2
      class="modal-container"
      [message]="message"
      (signal)="onSignal($event)"
    ></tc-hub-dmsgc-step2>
  </div>
</div>
