import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { TcFormFieldIonicComponent } from './form-field-ionic/form-field-ionic.component';
import { TcFormFieldWebComponent } from './form-field-web/form-field-web.component';

@NgModule({
  declarations: [TcFormFieldIonicComponent, TcFormFieldWebComponent],
  imports: [SharedModule],
  exports: [TcFormFieldIonicComponent, TcFormFieldWebComponent],
  providers: [],
})
export class TcFormFieldModule {}
