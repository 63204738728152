<tc-form-field-web [tcErrors]="[valueAccessor.ngControl?.errors]">
  <fieldset
    *ngIf="innerForm"
    class="group-wrapper"
    [class.is-compact]="tcIsCompact"
    [formGroup]="innerForm"
  >
    <tc-input-web
      *ngFor="let fieldName of ['start', 'end']"
      class="group-item {{ fieldName }}"
      [fieldType]="tcFieldType"
      [label]="tcLabels ? tcLabels[fieldName] : null"
      [formControlName]="fieldName"
      [tcShowDateOptions]="
        showDateOptionsFields[fieldName]
          ? tcConfig?.alwaysVisibleDateOptions
            ? 'always'
            : 'if-needed'
          : null
      "
      [tcDateOptionsInterval]="tcDateOptionsInterval"
      [tcDateOptionsRelative]="tcConfig?.relativeDateOptions"
      [tcReferenceDate]="
        tcFieldType === 'time' && !showDateOptionsFields[fieldName]
          ? fieldName === 'start' || !innerForm.value.start
            ? tcRefDate
            : innerForm.value.start
          : null
      "
      [isDurationExtended]="
        fieldName === 'end' && !showDateOptionsFields.end && isDurationExtended
      "
      [tcConfig]="{
        required: tcConfig.required,
        limit:
          fieldName === 'start'
            ? tcStartLimit ?? tcConfig?.limit
            : tcEndLimit ?? tcConfig?.limit,
        isCompact: tcIsCompact,
        fieldType: tcFieldType
      }"
      [tcAddOnAfter]="
        fieldName === 'end' &&
        tcFieldType === 'time' &&
        tcFieldWithDateOptions === null &&
        allowDoubledWorkingDay
          ? extendedTimeTemplate
          : null
      "
    ></tc-input-web>
  </fieldset>
</tc-form-field-web>

<ng-template #extendedTimeTemplate>
  <label
    tabindex="-1"
    class="checkbox__extended"
    nz-checkbox
    [disabled]="innerForm.controls['end'].disabled"
    [(ngModel)]="isDurationExtended"
  >
    <span *ngIf="!isCompact">+24h</span>
  </label>
</ng-template>
