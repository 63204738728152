import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { debounceTime } from 'rxjs/operators';

import { DispoFocusService } from '../../dispo-focus.service';
import { DTDayComponent } from '../day/day.component';

const baseDate = new Date(2015, 1, 1, 12, 0, 0);

@Component({
  selector: 'tc-hub-dt-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.scss'],
})
export class DTScrollComponent implements OnInit, OnDestroy {
  @Output() tcSelect: EventEmitter<'selected'> = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  @ViewChildren('dayComponent') dayComponents: QueryList<DTDayComponent>;

  date: Observable<Date>;
  dates: Date[];
  currentDate: Date;
  scrollProgrammatically = false;

  subs = [];

  constructor(
    public dispoFocus: DispoFocusService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.date = this.dispoFocus.date();
    this.dates = Array.from({ length: 5000 }, (_v, i) => {
      return new Date(
        new Date(baseDate.getTime()).setDate(baseDate.getDate() + i),
      );
    });

    this.subs.push(
      this.date.pipe(debounceTime(50)).subscribe((currentDate) => {
        if (this.currentDate !== currentDate) {
          const currPos = Math.round(
            (currentDate.getTime() - baseDate.getTime()) /
              (1000 * 60 * 60 * 24),
          );
          const init = !!this.currentDate;
          this.currentDate = currentDate;

          this.scrollProgrammatically = true;
          this.viewport.scrollToIndex(currPos + (init ? 1 : 0));
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  onScroll(pos) {
    if (!this.currentDate) {
      return;
    }
    if (this.scrollProgrammatically) {
      this.scrollProgrammatically = false;
      return;
    }

    pos = pos + 1;
    const currentDate = new Date(
      baseDate.getTime() + pos * 1000 * 60 * 60 * 24,
    );
    currentDate.setHours(12, 0, 0, 0);

    this.currentDate = currentDate;
    this.dispoFocus.setDate(currentDate);
  }

  // onDateChange(date) {
  //   const pos = Math.round((date.getTime() - baseDate.getTime()) / (1000 * 60 * 60 * 24));

  //   this.dispoFocus.setDate(date);
  //   this.viewport.scrollToIndex(pos);
  // }

  onSelect() {
    this.tcSelect.emit('selected');
  }

  clearSelection() {
    this.dayComponents.map((d) => d.clearSelection());
  }

  trackByDate(index, item) {
    return item.getTime(); // or item.id
  }
}
