import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfHour,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  subDays,
  subHours,
  subMonths,
  subYears,
} from 'date-fns';

@Component({
  selector: 'tc-hub-interval-select',
  templateUrl: './interval-select.component.html',
  styleUrls: ['./interval-select.component.scss'],
})
export class IntervalSelectComponent implements OnInit {
  intervals;
  @Input() type = 'default';
  @Output() selectedInterval = new EventEmitter();

  ngOnInit(): void {
    const today = startOfToday();
    this.intervals = this.getIntervals(today);
  }

  getIntervals(today: Date): any[] {
    const intervals = {
      default: this.getDefaultIntervals(today),
      stats: this.getStatsIntervals(today),
      stats_long: this.getLongStatsIntervals(today),
      logs: this.getLogIntervals(today),
    };
    return intervals[this.type];
  }

  getDefaultIntervals(today: Date): any[] {
    return [
      {
        name: 'yesterday',
        value: { start: subDays(today, 1), end: today },
      },
      {
        name: 'today',
        value: { start: today, end: endOfDay(today) },
      },
      {
        name: 'tomorrow',
        value: { start: addDays(today, 1), end: endOfDay(addDays(today, 1)) },
      },
      {
        name: 'current_week',
        value: { start: startOfWeek(today), end: endOfWeek(today) },
      },
      {
        name: 'next_week',
        value: {
          start: startOfWeek(addWeeks(today, 1)),
          end: endOfWeek(addWeeks(today, 1)),
        },
      },
      {
        name: 'current_month',
        value: { start: startOfMonth(today), end: endOfMonth(today) },
      },
      {
        name: 'next_month',
        value: {
          start: startOfMonth(addMonths(today, 1)),
          end: endOfMonth(addMonths(today, 1)),
        },
      },
    ];
  }

  getStatsIntervals(today: Date): any[] {
    return [
      {
        name: 'last_last_three_months',
        value: {
          start: startOfMonth(subMonths(today, 4)),
          end: endOfMonth(subMonths(today, 2)),
        },
      },
      {
        name: 'last_three_months',
        value: {
          start: startOfMonth(subMonths(today, 3)),
          end: endOfMonth(subMonths(today, 1)),
        },
      },
      {
        name: 'current_month',
        value: { start: startOfMonth(today), end: endOfMonth(today) },
      },
    ];
  }

  getLongStatsIntervals(today: Date): any[] {
    return [
      {
        name: 'current_year',
        value: { start: startOfYear(today), end: endOfYear(today) },
      },
      {
        name: 'last_year',
        value: {
          start: startOfYear(subYears(today, 1)),
          end: endOfYear(subYears(today, 1)),
        },
      },
      {
        name: 'current_and_last_year',
        value: {
          start: startOfYear(subYears(today, 1)),
          end: endOfYear(today),
        },
      },
      {
        name: 'last_five_years',
        value: {
          start: startOfYear(subYears(today, 4)),
          end: endOfYear(today),
        },
      },
      {
        name: 'since_beginning',
        value: { start: '', end: endOfYear(today) },
      },
    ];
  }

  getLogIntervals(today: Date): any[] {
    return [
      {
        name: 'current_hour',
        value: {
          start: startOfHour(today),
          end: addHours(startOfHour(today), 1),
        },
      },
      {
        name: 'last_hour',
        value: {
          start: startOfHour(subHours(today, 1)),
          end: startOfHour(today),
        },
      },
      {
        name: 'today',
        value: {
          start: startOfToday(),
          end: endOfDay(today),
        },
      },
      {
        name: 'yesterday',
        value: {
          start: subDays(today, 1),
          end: today,
        },
      },
      {
        name: 'current_week',
        value: {
          start: startOfWeek(today),
          end: endOfWeek(today),
        },
      },
    ];
  }

  selectRange(range) {
    this.selectedInterval.emit(range.value);
  }
}
