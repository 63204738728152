import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'typeof',
})
export class TcTypeofPipe implements PipeTransform {
  transform(value: unknown) {
    return typeof value;
  }
}
