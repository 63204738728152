<tc-form-wrapper
  [isModal]="true"
  primaryButtonLabelKey="copy"
  (primaryButtonClick)="copyValueToClipboard()"
>
  <nz-alert
    class="alert"
    *ngIf="alertKey"
    nzType="warning"
    [nzMessage]="alertKey | translate"
  ></nz-alert>
  <tc-code-editor [(ngModel)]="value" [disabled]="readOnly"></tc-code-editor>
</tc-form-wrapper>
