import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';

import { CurrentUserService } from '../../core/current-user.service';
import { BillingServiceEntryCollection } from '../../projects/service-entry/billing-service-entry.collection';
import { BillingWorkEntryCollection } from '../../projects/aggregations/work-entries/billing-work-entry.collection';
import { BillingAggregationCollection } from '../../projects/aggregations/billing-aggregation.collection';
import { AggregationEntryService } from '../../projects/aggregations/aggregation-entry.service';
import { Aggregation } from '../../projects/aggregations/aggregation.model';
import { AggregationService } from '../../projects/aggregations/aggregations.service';
import { EntryBillingState } from '../../projects/entry-billing-state.model';
import { ProjectCollection } from '../../projects/project.collection';
import { Project } from '../../projects/project.model';
import { ContactCollection } from '../../contacts/contact.collection';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuAggregationEntry {
  private translations: { [key: string]: string } = {};
  private maxRequests = 150;

  private aggregationsAsHash$: Observable<{ [key: number]: Aggregation }>;

  constructor(
    private translateService: TranslateService,
    private currentUser: CurrentUserService,
    private aggregationEntryActions: AggregationEntryService,
    private aggregationActions: AggregationService,
    private serviceEntryCollection: BillingServiceEntryCollection,
    private workEntryCollection: BillingWorkEntryCollection,
    private aggregationCollection: BillingAggregationCollection,
    private projectCollection: ProjectCollection,
    private contactCollection: ContactCollection,
    @Inject('AttachmentReleaseMessage') private attachmentReleaseMessage,
  ) {
    this.translateService
      .get([
        'aggregation_entry.menu.aggregation_entry',
        'aggregation_entry.menu.aggregation',
        'aggregation_entry.menu.build_and_preview',
        'aggregation_entry.menu.build_preview',
        'aggregation_entry.menu.download_preview',
        'aggregation_entry.menu.aggregation_delete',
        'aggregation_entry.menu.generate_bill',
        'aggregation_entry.menu.download_bill',
        'aggregation_entry.menu.share_bill',
        'aggregation_entry.menu.paid_bill',
        'aggregation_entry.menu.pricing',
        'aggregation_entry.menu.bill',
        'aggregation_entry.menu.cancel',
        'aggregation_entry.menu.edit',
        'aggregation_entry.menu.gratis',
        'aggregation_entry.menu.paid',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  items(currEntries): Observable<any[]> {
    return combineLatest(
      this.workEntryCollection.observeItems(
        currEntries.filter((e) => e.identifier === 'work_entry'),
      ),
      this.serviceEntryCollection.observeItems(
        currEntries.filter((e) => e.identifier === 'service_entry'),
      ),
      this.aggregationsAsHash(),
    ).pipe(
      map(([workEntries, serviceEntries, aggregationsAsHash]) => {
        const entries = [...workEntries, ...serviceEntries];

        if (entries.length === 0) {
          return [];
        }

        entries.forEach((e) => {
          e.aggregation = aggregationsAsHash[e.aggregation_id];
        });

        const entryActions: any[] = [];
        const aggregationActions: any[] = [];

        const allEntriesInSameAggregation =
          new Set(entries.map((e) => e.aggregation_id)).size === 1;

        if (allEntriesInSameAggregation) {
          const mainEntry = entries[0];

          // the entries are not yet part of a normal aggregation
          if (mainEntry.billing_state === EntryBillingState.prepared) {
            aggregationActions.push({
              label:
                this.translations['aggregation_entry.menu.build_and_preview'],
              icon: 'tc:invoice-billing',
              visible: this.currentUser.hasAccessToSection(
                'views.aggregation_entry.build_and_preview',
              ),
              command: (event) => {
                this.aggregationCollection.action('import', undefined, {
                  entry_ids: entries.map((e) => e.id),
                  target_state: 'preview',
                });
              },
            });

            aggregationActions.push({
              label: this.translations['aggregation_entry.menu.pricing'],
              icon: 'tc:projects',
              visible: this.currentUser.hasAccessToSection(
                'views.aggregation_entry.pricing',
              ),
              command: (event) => {
                window.open(
                  `/#/projects/${mainEntry.project_id}/conditions`,
                  '_blank',
                );
              },
            });
          }

          // the entries are part of a normal aggregation but not billed
          if (mainEntry.billing_state === EntryBillingState.inbilling) {
            if (mainEntry.aggregation?.attachment) {
              const attachment = mainEntry.aggregation.attachment;

              aggregationActions.push({
                label:
                  this.translations['aggregation_entry.menu.download_preview'],
                icon: attachment.state === 'generated' ? 'download' : 'loading',
                disabled: !attachment.file,
                visible: this.currentUser.hasAccessToSection(
                  'views.aggregation_entry.download_preview',
                ),
                command: (event) => {
                  window.open(attachment.file, '_blank');
                },
              });
            }

            aggregationActions.push({
              label: this.translations['aggregation_entry.menu.build_preview'],
              icon: 'file',
              visible: this.currentUser.hasAccessToSection(
                'views.aggregation_entry.build_preview',
              ),
              command: (event) => {
                this.aggregationCollection.action(
                  'build_preview',
                  mainEntry.aggregation_id,
                  mainEntry.aggregation,
                );
              },
            });

            entryActions.push({
              label: this.translations['aggregation_entry.menu.cancel'],
              icon: 'delete',
              visible: this.currentUser.hasAccessToSection(
                'views.aggregation_entry.cancel',
              ),
              command: (event) => {
                this.aggregationEntryActions.delete(entries);
              },
            });

            aggregationActions.push({
              label: this.translations['aggregation_entry.menu.generate_bill'],
              icon: 'dollar',
              visible: this.currentUser.hasAccessToSection(
                'views.aggregation_entry.cancel',
              ),
              command: (event) => {
                this.aggregationActions.openBillingModal(mainEntry.aggregation);
              },
            });

            aggregationActions.push({
              label: this.translations['aggregation_entry.menu.pricing'],
              icon: 'tc:projects',
              visible: this.currentUser.hasAccessToSection(
                'views.aggregation_entry.pricing',
              ),
              command: (event) => {
                window.open(
                  `/#/projects/${mainEntry.project_id}/aggregations/${mainEntry.aggregation_type_id}/${mainEntry.aggregation_id}/price`,
                  '_blank',
                );
              },
            });
          }

          if (mainEntry.billing_state === EntryBillingState.billed) {
            aggregationActions.push({
              label: this.translations['aggregation_entry.menu.paid_bill'],
              icon: 'dollar-o',
              visible: this.currentUser.hasAccessToSection(
                'views.aggregation_entry.paid_bill',
              ),
              command: (event) => {
                this.aggregationActions.openPayingModal(mainEntry.aggregation);
              },
            });
          }

          if (
            mainEntry.billing_state === EntryBillingState.billed ||
            mainEntry.billing_state === EntryBillingState.paid
          ) {
            if (mainEntry.aggregation?.attachment) {
              const attachment = mainEntry.aggregation.attachment;

              aggregationActions.push({
                label:
                  this.translations['aggregation_entry.menu.download_bill'],
                icon: attachment.state === 'generated' ? 'download' : 'loading',
                disabled: !attachment.file,
                visible: this.currentUser.hasAccessToSection(
                  'views.aggregation_entry.download_bill',
                ),
                command: (event) => {
                  window.open(attachment.file, '_blank');
                },
              });

              aggregationActions.push({
                label: this.translations['aggregation_entry.menu.share_bill'],
                icon: attachment.state === 'generated' ? 'tc:share' : 'loading',
                disabled: !attachment.file,
                visible: this.currentUser.hasAccessToSection(
                  'views.aggregation_entry.share_bill',
                ),
                command: (event) => {
                  this.projectCollection
                    .get(mainEntry.project_id)
                    .pipe(
                      switchMap((project: Project) => {
                        const contactsCollection =
                          this.contactCollection.visible();
                        const contacts$ = (
                          project.invoice_contact_ids || []
                        ).map((contactId) => {
                          return contactsCollection.get(contactId);
                        });

                        if (contacts$.length === 0) {
                          return of([]);
                        } else {
                          return combineLatest(contacts$);
                        }
                      }),
                      first(),
                    )
                    .subscribe((contacts) => {
                      const emails = contacts
                        .map((c) => c['email'])
                        .filter((e) => e);
                      this.attachmentReleaseMessage.attachTo(
                        attachment,
                        emails,
                      );
                    });
                },
              });

              aggregationActions.push({
                label: this.translations['aggregation_entry.menu.bill'],
                icon: 'tc:finances',
                visible: this.currentUser.hasAccessToSection(
                  'views.aggregation_entry.bill',
                ),
                command: (event) => {
                  window.open(
                    `/#/finances/bills/${mainEntry.invoice_id}`,
                    '_blank',
                  );
                },
              });
            }
          }
        }

        entryActions.push({
          label: this.translations['aggregation_entry.menu.edit'],
          icon: 'edit',
          visible:
            entries.length === 1 &&
            !entries[0].locked &&
            this.currentUser.hasAccessToSection('views.aggregation_entry.edit'),
          command: (event) => {
            this.aggregationEntryActions.edit(entries[0]);
          },
        });

        const entriesGratisable = entries.filter((e) => !e.locked && !e.gratis);

        const entriesPayable = entries.filter((e) => !e.locked && e.gratis);

        if (entriesGratisable.length > 0) {
          entryActions.push({
            label:
              this.translations['aggregation_entry.menu.gratis'] +
              ` (${entriesGratisable.length})`,
            icon: 'dollar',
            disabled: entriesGratisable.length > this.maxRequests,
            visible: this.currentUser.hasAccessToSection(
              'views.aggregation_entry.gratis',
            ),
            command: (event) => {
              this.aggregationEntryActions.setGratisState(
                entriesGratisable,
                true,
              );
            },
          });
        }

        if (entriesPayable.length > 0) {
          entryActions.push({
            label:
              this.translations['aggregation_entry.menu.paid'] +
              ` (${entriesPayable.length})`,
            icon: 'dollar',
            disabled: entriesPayable.length > this.maxRequests,
            visible: this.currentUser.hasAccessToSection(
              'views.aggregation_entry.paid',
            ),
            command: (event) => {
              this.aggregationEntryActions.setGratisState(
                entriesPayable,
                false,
              );
            },
          });
        }

        const items = [
          ...(aggregationActions.length !== 0
            ? [
                {
                  header: true,
                  label:
                    this.translations['aggregation_entry.menu.aggregation'],
                },
                ...aggregationActions,
              ]
            : []),
          ...(entryActions.length !== 0
            ? [
                {
                  header: true,
                  label:
                    this.translations[
                      'aggregation_entry.menu.aggregation_entry'
                    ],
                },
                ...entryActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }

  private aggregationsAsHash() {
    return (this.aggregationsAsHash$ ||= this.aggregationCollection.all().pipe(
      map((aggregations) => {
        return aggregations.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
      }),
      shareReplay(1),
    ));
  }
}
