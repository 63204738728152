import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class VenueCollection extends SetsCollection {
  identifier = 'venue';
  endpoint = '/api/venues';

  cache = 60 * 60;

  localSearch = ['title'];

  visible() {
    return this.query({ 'filter[visible]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visible]': '0' }, (e) => e.visibility === 0);
  }
}
