<ion-card [formGroup]="innerForm">
  <ion-item-divider class="work-times">
    <ion-label>{{ 'ASSIGNMENTS.TIME_TRACKING_WORK' | translate }}</ion-label>
  </ion-item-divider>

  <tc-times-summary-ionic
    [intervalList]="timesList.value"
  ></tc-times-summary-ionic>

  <ion-item-divider class="mini-divider"></ion-item-divider>

  <ng-container formArrayName="timesList">
    <ion-item-sliding
      class="sliding"
      [disabled]="innerForm.disabled"
      #sliding
      *ngFor="
        let time of timesList.controls;
        index as i;
        last as isLast;
        first as isFirst
      "
    >
      <tc-form-field-ionic
        [tcErrors]="[
          timesList.controls[i].controls.start.errors,
          timesList.controls[i].controls.end.errors
        ]"
      >
        <ion-item class="list-item" [lines]="isLast ? 'none' : 'full'">
          <ion-note class="list-item__number" slot="start">
            {{ i + 1 }}.
          </ion-note>
          <tc-form-group-interval-ionic
            [formGroupName]="i"
            [tcConfig]="{
              isCompact: true,
              maxDuration: maxItemDuration,
              timePickerSteps: tcTimePickerSteps,
              alwaysVisibleDateOptions: true,
              fieldWithDateOptions: isFirst ? 'end' : 'both',
              allowZeroDuration: true,
              required: true,
              fieldType: 'time',
              limit: limits[i]
            }"
            [alignRight]="true"
            [tcLabels]="{
              start: 'GENERAL.TIME.START' | translate,
              end: 'GENERAL.TIME.END' | translate
            }"
            [tcRefDate]="
              isFirst || !timesList?.controls[i - 1]?.value.end
                ? tcReferenceDate
                : timesList?.controls[i - 1]?.value.end
            "
          >
          </tc-form-group-interval-ionic>
          <ion-button
            *ngIf="timesList?.controls[i].valid"
            class="list-item__options"
            slot="end"
            fill="clear"
            (click)="toggleSliding(sliding)"
          >
            <ion-icon
              color="dark"
              slot="icon-only"
              name="ellipsis-vertical"
            ></ion-icon>
          </ion-button>
          <ion-button
            *ngIf="timesList?.controls[i].invalid"
            class="list-item__options"
            slot="end"
            fill="clear"
            [disabled]="timesList.length === 1 || innerForm.disabled"
            (click)="deleteTime(i)"
          >
            <ion-icon
              color="danger"
              slot="icon-only"
              name="close-circle-outline"
            ></ion-icon>
          </ion-button>
        </ion-item>
      </tc-form-field-ionic>
      <ion-item-options side="end" [hidden]="!timesList?.controls[i].valid">
        <ion-item-option
          color="medium"
          (click)="sliding.close() && splitTime(i)"
          >{{ 'GENERAL.ACTIONS.SPLIT' | translate }}</ion-item-option
        >
        <ion-item-option
          *ngIf="timesList.value.length > 1"
          color="danger"
          [disabled]="timesList.length === 1 || innerForm.disabled"
          (click)="deleteTime(i)"
          >{{ 'GENERAL.ACTIONS.CLEAR' | translate }}</ion-item-option
        >
      </ion-item-options>
    </ion-item-sliding>

    <ion-button
      [disabled]="innerForm.disabled"
      size="small"
      expand="block"
      color="dark"
      (click)="addAfter()"
    >
      <ion-icon slot="start" name="add"></ion-icon>
      {{ 'ASSIGNMENTS.TIME_TRACKING_ADD_PERIOD' | translate }}
    </ion-button>
  </ng-container>
</ion-card>
