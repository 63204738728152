import { Interval } from 'date-fns';

import { isValidDate, parseDate, stringifyDate } from '@timecount/utils';

import { TcInterval, TcIntervalApi } from './interval.model';

export class TcIntervalList {
  /**
   * Parse `starts_at` and `ends_at` properties from the times array of
   * any object retrieved by the API into an array of type {start, end}
   *
   * @param apiObject An object retrieved by the API that contains time related
   *                  properties
   */
  static parseFromApi(apiObject) {
    const { times } = apiObject ?? {};

    const timesArray: Interval[] = [];

    times?.map((time) => {
      let { starts_at: start, ends_at: end } = time;

      if (start && !isValidDate(start)) {
        start = parseDate(start);
      }
      if (end && !isValidDate(end)) {
        end = parseDate(end);
      }

      timesArray.push({ start, end });
    });

    return timesArray;
  }

  /**
   * Formats a TcTimesList for the API
   *
   * @param value The interval list to be formatted
   */
  static formatForApi(value: TcInterval[]): TcIntervalApi[] {
    value ??= [];

    return value.map((time: Interval) => ({
      [`starts_at`]: stringifyDate(time.start as Date),
      [`ends_at`]: stringifyDate(time.end as Date),
    }));
  }
}
