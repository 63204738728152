import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { intervalToDuration } from 'date-fns';

import {
  durationInSeconds,
  getWholeInterval,
  isValidInterval,
} from '@timecount/utils';

export class TcFieldsetValidators {
  static maxDuration(maxDuration: Duration): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const interval = getWholeInterval(control.value);
      if (
        isValidInterval(interval) &&
        durationInSeconds(intervalToDuration(interval)) >
          durationInSeconds(maxDuration)
      ) {
        return { maxDuration: true };
      }
      return null;
    };
  }
}
