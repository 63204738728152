import { Pipe, PipeTransform } from '@angular/core';

import { UserCollection } from '../core/collections/user';

@Pipe({
  name: 'userById',
})
export class UserByIdPipe implements PipeTransform {
  constructor(private userCollection: UserCollection) {}
  transform(id: number) {
    return this.userCollection.find(id).asObservable();
  }
}
