import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';

import { SmtpApiService } from '../smtp-config.service';

@Component({
  selector: 'tc-hub-smtp-config-form',
  templateUrl: './smtp-config-form.component.html',
  styleUrls: ['./smtp-config-form.component.scss'],
})
export class TcSmtpConfigFormComponent implements OnInit {
  formGroup: FormGroup;

  enabled = false;
  translations: { [key: string]: string } = {};

  constructor(
    private _formBuilder: FormBuilder,
    private _smtpApiService: SmtpApiService,
    private _translateService: TranslateService,
  ) {
    this._translateService
      .get([
        'account/preference.legends.smtp_api',
        'account/preference.smtp_api.enabled',
        'account/preference.smtp_api.disabled',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }
  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this._buildForm();
  }

  // --------------
  // Public Methods
  // --------------

  save() {
    this._smtpApiService
      .create(this.formGroup.value)
      .pipe(
        catchError((error) => {
          return of(null);
        }),
      )
      .subscribe((response) => {
        const { enabled, ...formValue } = response ?? {};
        this.enabled = enabled;
        this.formGroup.reset(formValue);
      });
  }

  delete() {
    this._smtpApiService.delete().subscribe(({ enabled, ...formValue }) => {
      this.enabled = enabled;
      this.formGroup.reset(formValue);
    });
  }

  // ---------------
  // Private Methods
  // ---------------

  private async _buildForm() {
    const smtpApiValue = await this._smtpApiService.get().toPromise();
    const {
      enabled,
      address,
      port,
      user,
      password,
      encryption,
      auth,
      emailHeaders,
    } = smtpApiValue ?? {};

    this.enabled = enabled;

    this.formGroup = this._formBuilder.group({
      address: [address, Validators.required],
      port: [port, Validators.required],
      user: [user, Validators.required],
      password: [password, Validators.required],
      encryption: [encryption, Validators.required],
      auth: [auth, Validators.required],
      emailHeaders: [emailHeaders],
    });

    this.formGroup.get('encryption').valueChanges.subscribe((encryption) => {
      if (encryption === 'tls') {
        this.formGroup.get('port').setValue(465);
      } else {
        this.formGroup.get('port').setValue(587);
      }
    });
  }
}
