import { differenceInSeconds, Interval, startOfDay } from 'date-fns';

import {
  intervalInMinutes,
  intervalToOffsetLength,
  isValidDate,
  offsetLengthToInterval,
  parseDate,
  stringifyDate,
  TcOffsetLength,
} from '@timecount/utils';

import { TcIntervalApi } from './interval.model';

export type TcOffsetLengthWithBreakApi = TcOffsetLength & {
  [`intermission_offset`]?: number;
  [`intermission_length`]?: number;
};

export type TcIntervalWithBreakApi = TcIntervalApi & {
  [`intermission_starts_at`]: string;
  [`intermission_ends_at`]: string;
};

/**
 * TcIntervalWithBreak is the object that defines periods of time
 *
 * It makes use of the `date-fns`'s Interval type,
 * so its "Interval Helpers" can be used. More info:
 *
 * @see {@link https://date-fns.org/v2.11.0/docs/Interval|Interval} for further information
 */
export class TcIntervalWithBreak {
  /**
   * @param mainInterval The interval
   * @param breakInterval (Optional) The break Interval
   */
  constructor(public mainInterval: Interval, public breakInterval?: Interval) {}

  /**
   * Get duration in minutes between the start and end dates of a
   * TcIntervalWithBreak's main interval subtracting the duration of the break
   * interval.
   *
   * If the result is negative, meaning that the break is longer then the main
   * Interval (which is an invalid TcIntervalWithBreak), it returns `0`.
   */
  static getDuration(timeSet: TcIntervalWithBreak): number {
    const { mainInterval, breakInterval } = timeSet;

    const totalMinutes =
      intervalInMinutes(mainInterval) -
      intervalInMinutes(breakInterval as Interval);

    return totalMinutes > 0 ? totalMinutes : 0;
  }

  /**
   * Parse `starts_at`, `ends_at`, `intermission_starts_at` and
   * `intermission_ends_at`  properties of any object retrieved by the
   * API into a TcIntervalWithBreak object.
   *
   * @param apiObject An object retrieved by the API that contains time related
   *                  properties
   */
  static parseFromApi(apiObject): TcIntervalWithBreak {
    let {
      starts_at: start,
      ends_at: end,
      intermission_starts_at: breakStart,
      intermission_ends_at: breakEnd,
    } = apiObject ?? {};

    if (start && !isValidDate(start)) {
      start = parseDate(start);
    }
    if (end && !isValidDate(end)) {
      end = parseDate(end);
    }

    const timeSet: TcIntervalWithBreak = {
      mainInterval: { start, end },
    };

    if (breakStart || breakEnd) {
      if (!isValidDate(breakStart)) {
        breakStart = parseDate(breakStart);
      }
      if (!isValidDate(breakEnd)) {
        breakEnd = parseDate(breakEnd);
      }

      timeSet.breakInterval = {
        start: breakStart,
        end: breakEnd,
      };
    }

    return timeSet;
  }

  /**
   * Formats a Time Set value for the API
   *
   * @param value The TcIntervalWithBreak value to be formatted. If undefined,
   *              the method will return the API properties with empty string
   *              values.
   * @param options.areDatesInISOFormat
   */
  static formatForApi(value: TcIntervalWithBreak): TcIntervalWithBreakApi {
    value ??= {
      mainInterval: { start: undefined, end: undefined },
    };
    const timeSet = new TcIntervalWithBreak(
      value.mainInterval,
      value[`breakInterval`],
    );

    return {
      [`starts_at`]: stringifyDate(timeSet.mainInterval.start as Date),
      [`ends_at`]: stringifyDate(timeSet.mainInterval.end as Date),
      [`intermission_starts_at`]: stringifyDate(
        timeSet.breakInterval?.start as Date,
      ),
      [`intermission_ends_at`]: stringifyDate(
        timeSet.breakInterval?.end as Date,
      ),
    };
  }

  static fromOffSetLength(
    source: TcOffsetLengthWithBreakApi,
    baseDate?: Date,
  ): TcIntervalWithBreak {
    return new TcIntervalWithBreak(
      offsetLengthToInterval(source, baseDate),
      source.intermission_offset
        ? offsetLengthToInterval(
            {
              [`offset`]: source[`intermission_offset`],
              [`length`]: source[`intermission_length`],
            },
            baseDate,
          )
        : undefined,
    );
  }

  /**
   * Formats the TcIntervalWithBreak value for the API
   */
  static toOffsetLengthApi(
    value: TcIntervalWithBreak,
  ): TcOffsetLengthWithBreakApi {
    const formattedObject: TcOffsetLengthWithBreakApi = intervalToOffsetLength(
      value.mainInterval,
    );

    const { start: breakStart } = (value.breakInterval as Interval) ?? {};

    if (isValidDate(breakStart)) {
      const offset = differenceInSeconds(
        breakStart,
        startOfDay(value.mainInterval.start),
      );

      const { length } = intervalToOffsetLength(
        value.breakInterval as Interval,
      );

      formattedObject[`intermission_offset`] = offset;
      formattedObject[`intermission_length`] = length;
    }

    return formattedObject;
  }
}
