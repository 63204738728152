import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TcGeoCoordinates } from '@timecount/core';

import { TcMapMarker } from './map-marker';

@Injectable({
  providedIn: 'root',
})
export class TcMapService {
  // TODO: Find a way to get this from the environment from a lib
  public googleMapsApiKey = 'AIzaSyBrgjdyg_whHZ1yhDfPJUq8uUyHVWwEL0U';

  private _googleMapsApiUrl = 'https://maps.googleapis.com/maps/api/staticmap';

  getStaticMapPath(
    center: TcGeoCoordinates | TcMapMarker,
    options?: {
      extraMarkers?: TcMapMarker[];
      size?: [number, number];
      zoom?: number;
    },
  ): string {
    const size = options?.size ?? [640, 320];
    const markers = options?.extraMarkers ?? [];
    let zoom = options?.zoom ?? 17;

    let location: TcGeoCoordinates;

    if (center instanceof TcMapMarker) {
      location = center.location;

      // When the map's center marker has a circle to display and therefore it
      // needs to fit it in the rendered map, the options zoom is unset
      if (center.hasCircle()) {
        zoom = undefined;
      }

      markers.unshift(center);
    } else {
      location = center;
    }

    let params = new HttpParams({
      fromObject: {
        key: this.googleMapsApiKey,
        center: `${location.latitude},${location.longitude}`,
        size: `${size[0]}x${size[1]}`,
        zoom,
      },
    });

    markers.forEach((marker) => {
      params = params.append('markers', marker.getPin());

      if (marker.hasCircle()) {
        params = params.append('path', marker.getEncodedCirclePath());
      }
    });

    return `${this._googleMapsApiUrl}?${params.toString()}`;
  }
}
