import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { json } from '@codemirror/lang-json';
import { dentaku } from 'codemirror-lang-dentaku';
import { Compartment, EditorState, Extension } from '@codemirror/state';
import { oneDark } from '@codemirror/theme-one-dark';
import { EditorView, ViewUpdate } from '@codemirror/view';
import { basicSetup } from 'codemirror';

import { formatFormula } from '@timecount/utils';

import { ValueAccessorDirective } from '../../shared';

@Component({
  selector: 'tc-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  standalone: true,
  imports: [CommonModule],
  hostDirectives: [ValueAccessorDirective],
})
export class TcCodeEditorComponent implements AfterViewInit {
  @Input() type: 'jsonSchema' | 'dentaku' = 'jsonSchema';
  @Input() knownVariables: string[];

  @ViewChild('editorElement') editorElement!: ElementRef<HTMLElement>;

  extensions: Extension[] = [basicSetup, oneDark];
  editorView!: EditorView;

  readOnlyCompartment = new Compartment();

  initialValue = '';
  initialDisabled = false;

  constructor(protected valueAccessor: ValueAccessorDirective<string>) {
    this.valueAccessor.value.subscribe((value) => {
      if (this.editorView) {
        this.editorView?.dispatch({
          changes: {
            from: 0,
            to: this.editorView.state.doc.length,
            insert: formatFormula(value),
          },
        });
      } else {
        this.initialValue = value;
      }
    });

    this.valueAccessor.disabled.subscribe((isDisabled: boolean) => {
      if (this.editorView) {
        this.editorView?.dispatch({
          effects: this.readOnlyCompartment.reconfigure(
            EditorState.readOnly.of(isDisabled),
          ),
        });
      } else {
        this.initialDisabled = isDisabled;
      }
    });
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngAfterViewInit(): void {
    this._setEditor();
  }

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  private _setEditor() {
    let state!: EditorState;

    this.extensions.push(
      this.readOnlyCompartment.of(
        EditorState.readOnly.of(this.initialDisabled),
      ),
      EditorView.updateListener.of((view: ViewUpdate) => {
        if (view.docChanged) {
          this.valueAccessor.valueChange(view.state.doc.toString());
        }
      }),
    );

    switch (this.type) {
      case 'dentaku':
        this.extensions.push(
          dentaku({
            linterOptions: {
              knownVariables: this.knownVariables,
            },
            completionOptions: {
              variableEntries: this.knownVariables.map((label) => ({
                label,
                type: 'variable',
              })),
            },
          }),
        );
        break;

      default:
        this.extensions.push(json());
        break;
    }

    try {
      state = EditorState.create({
        doc: formatFormula(this.initialValue),
        extensions: this.extensions,
      });
    } catch (e) {
      // If you're here, it's probably because of the installed codemirror
      // version. Make sure codemirror@6.0.1 is installed, as newer versions are
      // not compatible with this code (not sure why).
      console.error(e);
    }

    this.editorView = new EditorView({
      state,
      parent: this.editorElement.nativeElement,
    });
  }
}
