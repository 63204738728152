import { Task } from '../collections/task';
import { Announcement } from '../collections/announcement';
import { Invitation, InvitationState } from '../collections/invitation';
import { Plan } from '../collections/plan';
import { Schedule } from '../collections/schedule';

export class InvitationDS extends Invitation {
  type = 'DispoInvitation';
  state: InvitationState;

  date: string;
  task: Task;
  assigned: boolean;
  announcement: Announcement;
  schedule?: Schedule;
  plan: Plan;
}
