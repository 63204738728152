<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <tc-hub-collection-select
              label="{{ 'dispo/message.attrs.contact_recipients' | translate }}"
              [collectionTypes]="['Contact']"
              [formGroup]="form"
              controlName="contact_ids"
              bindLabel="name"
              bindValue="id"
              multiple="true"
              searchable="true"
            >
            </tc-hub-collection-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-message-template-select
              label="{{ 'message/template.actions.select' | translate }}"
              [formGroup]="form"
              controlName="message_template_id"
              bindLabel="title"
              bindValue="id"
              [resourceType]="templateType"
              [dataType]="templateType"
              [mapping]="{ body: 'body', subject: 'subject' }"
            ></tc-hub-message-template-select>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-option-select
              label="{{ 'dispo/message.attrs.transmission_type' | translate }}"
              type="dispo.transmission_type"
              [formGroup]="form"
              controlName="transmission_type"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <div class="form-controls">
              <label>{{ 'dispo/message.attrs.subject' | translate }}</label>
              <div class="textarea-group">
                <div class="textarea-controls">
                  <tc-hub-placeholder
                    [formGroup]="form"
                    target="subject"
                    [type]="placeholderType"
                  ></tc-hub-placeholder>
                </div>
                <div class="textarea-form">
                  <textarea
                    class="form-control"
                    rows="1"
                    name="subject"
                    formControlName="subject"
                    autosize
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <div class="form-controls">
              <label>{{ 'dispo/message.attrs.body' | translate }}</label>
              <div class="textarea-group">
                <div class="textarea-controls">
                  <tc-hub-placeholder
                    [formGroup]="form"
                    target="body"
                    [type]="placeholderType"
                  ></tc-hub-placeholder>
                </div>
                <div class="textarea-form">
                  <textarea
                    class="form-control"
                    rows="5"
                    name="body"
                    formControlName="body"
                    autosize
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <div class="checkbox lightswitch">
              <label class="sr-only">{{
                'dispo/message.attrs.short_message' | translate
              }}</label>
              <label>
                <input
                  type="checkbox"
                  formControlName="short_message_enabled"
                />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label">{{
                  'dispo/message.attrs.short_message_enabled' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-input-date-web
              label="{{ 'dispo/message.attrs.date' | translate }}"
              formControlName="date"
            ></tc-input-date-web>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      (click)="next()"
      [disabled]="form.invalid"
    >
      {{ 'next' | translate }}
    </button>
  </div>
</form>
