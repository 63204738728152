<div class="sf-group">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>

  <div class="sf-controls">
    <div class="sf-elements">
      <!--
        TODO: Replace this with tc-input-web when it supports all required masks
      -->
      <tc-hub-schema-mask
        class="sf-element"
        [mask]="
          schema.mask ?? schema.widget.id === 'iban'
            ? 'AA99 **** **** **** [**** **** **** **** **** ****]'
            : null
        "
        [alias]="schema.widget.id !== 'iban' ? schema.widget.id : null"
        [formControl]="control"
      ></tc-hub-schema-mask>
    </div>
  </div>
</div>
