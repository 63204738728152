<div class="tc-modal-body" #modalBody>
  <div class="modal-container">
    <div slot="body" class="modal-body">
      <form class="form" [formGroup]="formGroup">
        <div class="row">
          <div class="col-xs-12">
            <tc-hub-collection-select
              class="form-item"
              label="{{ 'bias.attrs.target' | translate }}"
              [collectionTypes]="['Venue', 'Customer', 'Qualification', 'Job']"
              [formGroup]="formGroup"
              controlName="target"
              searchable="true"
              bindLabel="title"
            >
            </tc-hub-collection-select>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <tc-interval-web
              formControlName="range"
              [tcFieldType]="'date'"
              [tcAllowZeroDuration]="true"
              [tcMaxDuration]="{ years: 20 }"
              [tcConfig]="{
                noValidators: true,
                fieldType: 'date'
              }"
              [tcLabels]="{
                start: ('bias.attrs.starts_at' | translate),
                end: ('bias.attrs.ends_at' | translate)
              }"
            ></tc-interval-web>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <tc-hub-option-select
              class="form-item"
              label="{{ 'bias.attrs.impact' | translate }}"
              type="bias.impact"
              [formGroup]="formGroup"
              controlName="impact"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="note">
              <label>{{ 'bias.attrs.note' | translate }} </label>
              <textarea formControlName="note" msd-elastic></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <tc-hub-custom-fields
              class="custom-fields"
              section="bias.general"
              [formGroup]="formGroup"
              formControlName="store"
            >
            </tc-hub-custom-fields>
          </div>
        </div>
      </form>
    </div>
    <div slot="footer" class="modal-footer" #modalFooter>
      <button
        type="button"
        class="save-btn"
        nz-button
        nzType="primary"
        [disabled]="formGroup.invalid"
        (click)="save()"
      >
        <span nz-icon nzType="tc:save"></span>{{ 'save' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="tc-modal-footer" #modalFooter></div>
