<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <tc-hub-option-select
              label="{{ 'dispo/task.attrs.state' | translate }}"
              type="dispo.job_state"
              [formGroup]="form"
              controlName="state"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <tc-hub-message-template-select
              label="{{ 'message/template.actions.select' | translate }}"
              [formGroup]="form"
              controlName="message_template_id"
              bindLabel="title"
              bindValue="id"
              resourceType="dispo_task"
              dataType="dispo_task"
              [mapping]="{ body: 'description' }"
            ></tc-hub-message-template-select>
          </div>
          <div class="form-group">
            <div class="form-controls">
              <label>{{ 'dispo/task.attrs.description' | translate }}</label>
              <div class="textarea-group">
                <div class="textarea-controls">
                  <tc-hub-placeholder
                    [formGroup]="form"
                    target="description"
                    type="message/template.placeholders.dispo_task"
                  ></tc-hub-placeholder>
                </div>
                <div class="textarea-form">
                  <textarea
                    class="form-control"
                    rows="5"
                    name="description"
                    formControlName="description"
                    autosize
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <div class="checkbox lightswitch">
              <label class="sr-only">{{
                'dispo/task.attrs.tracking_enabled' | translate
              }}</label>
              <label>
                <input type="checkbox" formControlName="tracking_enabled" />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label">{{
                  'dispo/task.attrs.tracking_enabled' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <div class="checkbox lightswitch">
              <label class="sr-only">{{
                'dispo/task.attrs.push_notifications' | translate
              }}</label>
              <label>
                <input type="checkbox" formControlName="push_notifications" />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label">{{
                  'dispo/task.attrs.push_notifications' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-6" *ngIf="allowAppAttendance">
          <div class="form-group">
            <div class="checkbox lightswitch">
              <label class="sr-only">{{
                'dispo/task.attrs.attendance_enabled' | translate
              }}</label>
              <label>
                <input type="checkbox" formControlName="attendance_enabled" />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label">{{
                  'dispo/task.attrs.attendance_enabled' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-6"
          *ngIf="allowOnsiteCheckin && schedule.template.venue_id"
        >
          <div class="form-group">
            <div class="checkbox lightswitch">
              <label class="sr-only">{{
                'dispo/task.attrs.onsite_checkin_enabled' | translate
              }}</label>
              <label>
                <input
                  type="checkbox"
                  formControlName="onsite_checkin_enabled"
                />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label">{{
                  'dispo/task.attrs.onsite_checkin_enabled' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save('app')"
    >
      {{ 'dispo/schedule.actions.sync_save' | translate }}
    </button>
  </div>
</form>
