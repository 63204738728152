import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

import { Schedule } from '../collections/schedule';
import { DispoPlanCollection, Plan } from '../collections/plan';

@Component({
  selector: 'tc-hub-dispo-schedule',
  templateUrl: './dispo-schedule.component.html',
  styleUrls: ['./dispo-schedule.component.scss'],
})
export class DispoScheduleComponent
  extends BaseModalComponent
  implements OnInit
{
  schedule: Schedule;
  plan: Plan;

  tabs = {
    general: 0,
    times: 1,
    public: 2,
    days: 3,
    slots: 4,
    move: 5,
  };

  constructor(
    protected config: ModalConfig,
    protected modalRef: ModalRef,
    private planCollection: DispoPlanCollection,
  ) {
    super(config, modalRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.schedule = this.config.data.schedule;

    if (this.schedule.plan_id) {
      this.planCollection
        .get(this.schedule.plan_id)
        .pipe(first())
        .subscribe((plan) => {
          this.plan = <Plan>plan;
        });
    }
  }
}
