import { Injectable } from '@angular/core';

import { SetsCollection } from '../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class BalanceTypeCollection extends SetsCollection {
  identifier = 'preferences/balance';
  endpoint = '/api/preferences/balances';

  cache = 60 * 60 * 12;

  localSearch = ['title'];
}
