<div class="tc-modal-body" #modalBody>
  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="
      section === 'general' ||
      section === 'invitations' ||
      section === 'invite' ||
      section === 'tasks'
    "
  >
    <nz-tab
      nzTitle="{{ 'dispo/announcement.tab_type.general' | translate }}"
      *tcHubHide="'views.dispo.announcement.general'"
    >
      <tc-hub-dan-general
        class="modal-container"
        [announcement]="announcement"
        (signal)="onSignal($event)"
      ></tc-hub-dan-general>
    </nz-tab>
    <nz-tab
      nzTitle="{{ 'dispo/announcement.tab_type.invite' | translate }}"
      *tcHubHide="'views.dispo.announcement.invite'"
    >
      <ng-template nz-tab>
        <tc-hub-dan-invite
          class="modal-container"
          [announcement]="announcement"
          (signal)="onSignal($event)"
        ></tc-hub-dan-invite>
      </ng-template>
    </nz-tab>
    <nz-tab
      nzTitle="{{ 'dispo/announcement.tab_type.invitations' | translate }}"
      *tcHubHide="'views.dispo.announcement.invitations'"
    >
      <ng-template nz-tab>
        <tc-hub-dan-invitations
          class="modal-container"
          [announcement]="announcement"
          (signal)="onSignal($event)"
        ></tc-hub-dan-invitations>
      </ng-template>
    </nz-tab>
    <!--<nz-tab nzTitle="{{ 'dispo/announcement.tab_type.tasks' | translate }}">
      <tc-hub-dan-tasks class="modal-container" [announcement]="announcement" (signal)="onSignal($event)"></tc-hub-dan-tasks>
    </nz-tab>-->
  </nz-tabset>

  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    *ngIf="section === 'append-slots'"
  >
    <nz-tab
      nzTitle="{{ 'dispo/announcement.tab_type.append_slots' | translate }}"
      *tcHubHide="'views.dispo.announcement.append_slots'"
    >
      <tc-hub-dan-append-slots
        class="modal-container"
        [schedule]="schedule"
        [slots]="slots"
        (signal)="onSignal($event)"
      ></tc-hub-dan-append-slots>
    </nz-tab>
  </nz-tabset>

  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    *ngIf="section === 'append-tasks'"
  >
    <nz-tab
      nzTitle="{{ 'dispo/announcement.tab_type.append_tasks' | translate }}"
      *tcHubHide="'views.dispo.announcement.append_tasks'"
    >
      <tc-hub-dan-append-tasks
        class="modal-container"
        [tasks]="tasks"
        (signal)="onSignal($event)"
      ></tc-hub-dan-append-tasks>
    </nz-tab>
  </nz-tabset>

  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    *ngIf="section === 'detach-tasks'"
  >
    <nz-tab
      nzTitle="{{ 'dispo/announcement.tab_type.detach_tasks' | translate }}"
      *tcHubHide="'views.dispo.announcement.detach_tasks'"
    >
      <tc-hub-dan-detach-tasks
        class="modal-container"
        [announcement]="announcement"
        [tasks]="tasks"
        (signal)="onSignal($event)"
      ></tc-hub-dan-detach-tasks>
    </nz-tab>
  </nz-tabset>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'create'">
    <nz-tab
      nzTitle="{{ 'dispo/announcement.tab_type.create' | translate }}"
      *tcHubHide="'views.dispo.announcement.create'"
    >
      <tc-hub-dan-create
        class="modal-container"
        [announcement]="announcement"
        [tasks]="tasks"
        (signal)="onSignal($event)"
      ></tc-hub-dan-create>
    </nz-tab>
  </nz-tabset>

  <nz-tabset nzAnimated="false" nzSize="small" *ngIf="section === 'delete'">
    <nz-tab
      nzTitle="{{ 'dispo/announcement.tab_type.delete' | translate }}"
      *tcHubHide="'views.dispo.announcement.delete'"
    >
      <tc-hub-dan-delete
        class="modal-container"
        [announcement]="announcement"
        (signal)="onSignal($event)"
      ></tc-hub-dan-delete>
    </nz-tab>
  </nz-tabset>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
