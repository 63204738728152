<div
  class="wrapper"
  draggable="true"
  data-resource_type="Employee"
  [attr.data-resource_id]="employee.id"
>
  <header class="header">
    <tc-hub-departments-display
      [ids]="[employee.department_id]"
    ></tc-hub-departments-display>
    <h3 class="name">
      <strong>{{ employee.shorthand }}</strong>
      {{ employee.first_name }}
      {{ employee.last_name }}
    </h3>
    <tc-hub-employee-info-button
      [employee]="resource$ | async"
    ></tc-hub-employee-info-button>
    <tc-hub-pins-button
      type="Employee"
      btnType="link"
      btnSize="small"
      [id]="employee.id"
    ></tc-hub-pins-button>
  </header>

  <tc-hub-data-panel
    [data]="resource$ | async"
    section="dispo.employees.employee"
  ></tc-hub-data-panel>
</div>
