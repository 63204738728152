<tc-form-field-web
  [label]="label"
  [labelFor]="name"
  [tcCanDisable]="tcCanDisable"
  [(disabled)]="isDisabled"
  [class.is-compact]="tcConfig?.isCompact"
>
  <nz-input-group
    #inputWrapper
    class="input-wrapper is-{{ fieldType }}"
    [class.is-required]="ngControl.errors?.required"
    [class.is-invalid]="ngControl.invalid"
    [class.has-icon]="!!iconTypes[fieldType]"
    [class.has-addon]="!!tcAddOnAfter || !!tcAddOnBefore"
    [nzAddOnAfter]="tcAddOnAfter"
    [nzAddOnBefore]="
      (tcAddOnBefore ?? dateOptionsDir?.shouldShowDateOptions) &&
      dateOptionsDir.dateOptions
        ? dateSelectorTemplate
        : null
    "
    [nzSuffix]="iconTypes[fieldType] ? iconTemplate : null"
  >
    <input
      autocomplete="off"
      nz-input
      [type]="inputType"
      [name]="name"
      [step]="tcConfig?.step ?? null"
      class="tc-input"
      [tcInputMask]="maskType"
      [(maskedModel)]="value"
      [disabled]="isDisabled"
      [value]="stringifyValue()"
      (keydown)="onKeydown($event)"
      (input)="parseValue($event.target.value)"
      nz-popover
      #errorsPopover="nzPopover"
      nzPopoverPlacement="bottomLeft"
      [nzPopoverContent]="errorsTemplate"
      [nzPopoverTrigger]="ngControl.errors ? 'hover' : null"
      [nzPopoverOverlayClassName]="
        'tc-popover with-padding' +
        (ngControl.errors?.required
          ? ' is-required'
          : ngControl.invalid
          ? ' is-invalid'
          : '')
      "
      (mouseover)="toggleMouseOver(true)"
      (mouseout)="toggleMouseOver(false)"
      (focus)="toggleFocus(true)"
      (blur)="toggleFocus(false)"
    />
  </nz-input-group>
</tc-form-field-web>

<ng-template #iconTemplate>
  <button
    nz-button
    type="button"
    tabindex="-1"
    nzType="text"
    nzSize="small"
    [disabled]="isDisabled"
    (click)="onIconClick($event)"
  >
    <i class="picker-icon" nz-icon [nzType]="iconTypes[fieldType]"></i>
  </button>
</ng-template>

<div *ngFor="let hint of hints" class="ant-form-item-extra">{{ hint }}</div>

<ng-template #errorsTemplate>
  <div>
    <p
      *ngFor="let errorKey of ngControl.errors | keysList"
      class="tc-error-message"
    >
      {{ getErrorMessage(errorKey, ngControl.errors[errorKey]) | async }}
    </p>
  </div>
</ng-template>

<!--
  The dateSelectorTemplate is only use when the [tcShowDateOptions] directive is
  set on the host component.
-->
<ng-template #dateSelectorTemplate>
  <nz-select
    tabindex="-1"
    [ngModel]="dateOptionsDir.selectedOptionIndex"
    [disabled]="isDisabled"
    (ngModelChange)="dateOptionsDir.onIndexSelect($event)"
  >
    <nz-option
      *ngFor="let dateOption of dateOptionsDir.dateOptions; let i = index"
      [nzLabel]="
        dateOptionsDir.tcDateOptionsRelative
          ? (i + 1 | ordinal) + ' ' + ('datetime.prompts.day' | translate)
          : (dateOption | date : 'dd.MM')
      "
      [nzValue]="i"
    >
    </nz-option>
  </nz-select>
</ng-template>

<ng-template #percentAddOnTemplate>
  <i nz-icon nzType="percentage" nzTheme="outline"></i>
</ng-template>

<ng-template #currencyAddOnTemplate>
  <span>{{ 'angular.NUMBER_FORMATS.CURRENCY_SYM' | translate }}</span>
</ng-template>
