<tc-form-wrapper
  [isModal]="isModal"
  [isLoading]="!formGroup"
  [isInvalid]="formGroup?.invalid"
  (primaryButtonClick)="save()"
>
  <form [formGroup]="formGroup" (ngSubmit)="save()">
    <tc-hub-collection-select
      label="{{ 'job_rate.attrs.job' | translate }}"
      [collectionTypes]="['Job']"
      [formGroup]="formGroup"
      controlName="job_id"
      bindLabel="title"
      bindValue="id"
      [searchable]="true"
    >
    </tc-hub-collection-select>

    <tc-hub-collection-select
      label="{{ 'job_rate.attrs.vat' | translate }}"
      [collectionTypes]="['Vat']"
      [formGroup]="formGroup"
      controlName="vat_id"
      bindLabel="title"
      bindValue="id"
      [searchable]="true"
    >
    </tc-hub-collection-select>

    <tc-hub-option-select
      label="{{ 'job_rate.attrs.daily_rate' | translate }}"
      type="job_rate.daily_rate"
      [formGroup]="formGroup"
      controlName="daily_rate"
      bindLabel="title"
      bindValue="id"
    >
    </tc-hub-option-select>

    <tc-input-currency-web
      *ngIf="formGroup.value.daily_rate"
      label="{{ 'job_rate.attrs.per_day' | translate }}"
      formControlName="per_day"
    ></tc-input-currency-web>

    <tc-hub-fieldset-rate
      *ngIf="!formGroup.value.daily_rate"
      [rate]="tcInitialValue.per_hour"
      [items]="zoneGroups"
      [surcharges]="tcInitialValue.surcharges"
      (tcValueChange)="onRateFieldsetValueChange($event)"
    ></tc-hub-fieldset-rate>

    <tc-hub-custom-fields
      section="job_rate.general"
      formControlName="jobRateGeneral"
    >
    </tc-hub-custom-fields>

    <tc-hub-custom-fields
      section="job_rate.{{
        formGroup.value.daily_rate ? 'per_day' : 'per_hour'
      }}.general"
      formControlName="jobRateTypeGeneral"
    >
    </tc-hub-custom-fields>
  </form>
</tc-form-wrapper>
