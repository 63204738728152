import { NgModule } from '@angular/core';

import { TcFormGroupsModule } from '../../form-groups/form-groups.module';
import { SharedModule } from '../../shared/shared.module';
import { TcIntervalListModule } from '../interval-list/interval-list.module';
import { TcIntervalWithBreakModule } from '../interval-with-break/interval-with-break.module';
import { TcComponentsModule } from '../../components/components.module';

import { TcFieldsetIntervalSetComponent } from './interval-set.component';

@NgModule({
  declarations: [TcFieldsetIntervalSetComponent],
  exports: [TcFieldsetIntervalSetComponent],
  imports: [
    SharedModule,
    TcComponentsModule,
    TcFormGroupsModule,
    TcIntervalListModule,
    TcIntervalWithBreakModule,
  ],
})
export class TcIntervalSetModule {}
