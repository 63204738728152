import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseSelectComponent } from '../base-select.component';
import { CollectionService } from '../../../core/collection.service';

@Component({
  selector: 'tc-hub-message-template-select',
  templateUrl: './message-template-select.component.html',
  styleUrls: [
    '../base-select.component.scss',
    './message-template-select.component.scss',
  ],
})
export class MessageTemplateSelectComponent
  extends BaseSelectComponent
  implements OnInit
{
  @Input() resourceType: any;
  @Input() dataType: any;

  @Input() mapping: { [key: string]: string } = {
    subject: 'subject',
    body: 'body',
  };

  items$: Observable<any[]>;
  private collection: any;

  constructor(private collectionService: CollectionService) {
    super();
  }

  ngOnInit() {
    this.collection = this.collectionService['MessageTemplate'].byType(
      this.resourceType,
      this.dataType,
    );
    this.items$ = this.messageTemplates();
  }

  messageTemplates(): Observable<any> {
    return this.collection.all().pipe(
      catchError(() => of([])),
      tap(() => (this.loading = false)),
      map((items) => {
        return items;
      }),
    );
  }

  onChange(messageTemplate) {
    Object.entries(this.mapping).forEach(([from, to]) => {
      this.formGroup.get(to).setValue(messageTemplate[from]);
    });
  }
}
