<div class="tc-modal-body" #modalBody>
  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="section === 'general'"
  >
    <nz-tab
      nzTitle="{{ 'timesheet.tab_type.general' | translate }}"
      *tcHubHide="'views.timesheet.general'"
    >
      <ng-template nz-tab>
        <tc-hub-ts-general
          class="modal-container"
          [timesheet]="timesheets[0]"
          (signal)="onSignal($event)"
        ></tc-hub-ts-general>
      </ng-template>
    </nz-tab>
  </nz-tabset>

  <ng-container *ngIf="section === 'multi'">
    <tc-hub-ts-multi
      *tcHubHide="'views.timesheet.multi'"
      class="modal-container"
      [timesheets]="timesheets"
      (signal)="onSignal($event)"
    ></tc-hub-ts-multi>
  </ng-container>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
