import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DispoMessage } from '../../collections/message';

@Component({
  selector: 'tc-hub-dmsg-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class DMSGCreateComponent {
  @Input() message: DispoMessage;
  @Input() type: string;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  currentStep = 0;

  onSignal(signal) {
    if (signal.action === 'next') {
      this.message = signal.message;
      this.next();
    }

    if (signal.action === 'prev') {
      this.message = signal.message;
      this.prev();
    }

    this.signal.emit(signal);
  }

  prev(): void {
    this.currentStep -= 1;
  }

  next(): void {
    this.currentStep += 1;
  }
}
