<form class="form" *ngIf="formGroup" [formGroup]="formGroup">
  <div class="checkbox-field">
    <label nz-checkbox formControlName="scheduled">
      {{ 'aggregation.attrs.scheduled' | translate }}
    </label>
  </div>
  <tc-input-date-web
    formControlName="scheduled_at"
    label="{{ 'aggregation.attrs.scheduled_at' | translate }}"
  ></tc-input-date-web>

  <tc-hub-option-select
    label="{{ 'aggregation.attrs.scheduled_target_state' | translate }}"
    [type]="
      aggregationType.handle === 'bill'
        ? 'aggregation.type_bill.target_state'
        : 'aggregation.target_state'
    "
    [formGroup]="formGroup"
    controlName="scheduled_target_state"
    bindLabel="title"
    bindValue="id"
  >
  </tc-hub-option-select>
</form>
