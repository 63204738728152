import { Component, forwardRef, Input, OnInit, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { CollectionService } from '../../../../core/collection.service';
import { CollectionType } from '../../../models/collection-type.model';

export const COLLECTION_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CollectionFilterComponent),
  multi: true,
};

@Component({
  selector: 'tc-hub-schema-collection-filter',
  templateUrl: './collection-filter.component.html',
  styleUrls: ['./collection-filter.component.scss'],
  providers: [COLLECTION_CONTROL_VALUE_ACCESSOR],
})
export class CollectionFilterComponent implements ControlValueAccessor, OnInit {
  @Input() type: CollectionType;

  public bindLabel = 'title';

  private _innerValue: any[] = [];
  private service;
  collection: Observable<any[]>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_: any[]) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {};

  constructor(private collectionService: CollectionService) {}

  ngOnInit() {
    this.service = this.collectionService.get(this.type);
    this.collection = this.service.all();
    this.bindLabel = this.service.label() || 'title';
  }

  public get innerValue(): any[] {
    return this._innerValue;
  }

  public set innerValue(newValue: any[]) {
    newValue = newValue || [];
    this._innerValue = newValue;
    if (newValue.length === 0) {
      this.onChangeCallback(undefined);
    } else {
      this.onChangeCallback(newValue);
    }
  }

  public onBlur() {
    this.onTouchedCallback();
  }

  public writeValue(value: any[]) {
    if (value !== this.innerValue) {
      this.innerValue = value; // transform
    }
  }

  public registerOnChange(callback: (_: any[]) => void) {
    this.onChangeCallback = callback;
  }

  public registerOnTouched(callback: () => void) {
    this.onTouchedCallback = callback;
  }
}
