import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@timecount/ui';

import { DispoAssignmentCollection } from '../../dispo/collections/assignment';
import { DispoLoaderService } from '../../dispo/loader/loader.service';
import { DispoTaskCollection, SlotPos } from '../../dispo/collections/task';
import {
  DispoInvitationCollection,
  Invitation,
  InvitationState,
} from '../collections/invitation';
import { integerToString } from '../../core/helpers';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../dispo/collections/announcement';
import { AssignmentService } from '../../dispo/actions/assignment.service';
import { DispoInvitationComponent } from '../../dispo/dispo-invitation/dispo-invitation.component';
import { DispoAssignService } from '../../dispo/collections/assign.service';
import { Assignment } from '../collections/assignment.model';
import { DispoCombinedTaskCollection } from '../collections/combined_task';
import { LogsComponent } from '../../shared/logs/logs.component';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private loadingService: DispoLoaderService,
    private assignmentService: AssignmentService,
    private assignmentCollection: DispoAssignmentCollection,
    private invitationCollection: DispoInvitationCollection,
    private announcementCollection: DispoAnnouncementCollection,
    private taskCollection: DispoTaskCollection,
    private combinedTaskCollection: DispoCombinedTaskCollection,
    private assignmentActionService: DispoAssignService,
  ) {
    this.translateService
      .get(['dispo/invitation.modal.title'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  assign(
    slots: SlotPos[],
    announcement: Announcement,
    invitations: Invitation[],
  ) {
    combineLatest(
      this.combinedTaskCollection.all(),
      this.assignmentCollection.all(),
    )
      .pipe(first())
      .subscribe({
        next: ([tasks, assignments]) => {
          const actions = [];
          tasks = tasks.filter((t) => announcement.task_ids.includes(t.id));
          tasks.forEach((task) => {
            const taskAssignments = assignments.filter(
              (a) => a.task_id === task.id,
            );
            const slotsForTask = slots.filter(
              (s) => task.date === integerToString(s.x),
            );
            const assignedSlots = taskAssignments.map((a) => a.position);

            let exclusiveSlots;

            if (announcement.task_slots.length !== 0 && task.schedule_id) {
              exclusiveSlots = task.slots.filter((i) =>
                announcement.task_slots.includes(i),
              );
            }

            if (announcement.task_slots.length !== 0 && !task.schedule_id) {
              exclusiveSlots = [...announcement.task_slots];
            }

            if (announcement.task_slots.length === 0 && task.schedule_id) {
              exclusiveSlots = [...task.slots];
            }

            const openSlots = [];
            let position = 0;

            while (openSlots.length < slotsForTask.length) {
              if (
                // slot not assigned
                !assignedSlots.includes(position) &&
                // no exclusive slot or slot in exclusive slots
                (!exclusiveSlots || exclusiveSlots.includes(position))
              ) {
                openSlots.push(position);
              }

              if (exclusiveSlots && exclusiveSlots.every((s) => s < position)) {
                openSlots.push(-1);
              }

              position = position + 1;
            }

            slotsForTask.forEach((slot, i) => {
              const targetPos = openSlots[i];
              const invitation = invitations.find(
                (i) => i.id === slot.invitation_id,
              );

              const assignment = Object.assign(new Assignment(), {
                resource_id: invitation.resource_id,
                resource_type: invitation.resource_type,
                invitation_id: invitation.id,
              });

              actions.push({
                assignment: assignment,
                task: task,
                position: targetPos,
                type: 'create',
              });
            });
          });

          this.assignmentActionService.run(actions);
        },
      });
  }

  unassign(
    slots: SlotPos[],
    announcement: Announcement,
    invitations: Invitation[],
  ) {
    combineLatest(
      this.taskCollection.forAnnouncement(announcement),
      this.assignmentCollection.all(),
    )
      .pipe(first())
      .subscribe({
        next: ([tasks, assignments]) => {
          const assignmentsToDelete = [];

          tasks.forEach((task) => {
            const taskAssignments = assignments.filter(
              (a) => a.task_id === task.id,
            );
            const slotsForTask = slots.filter(
              (s) => task.date === integerToString(s.x),
            );

            slotsForTask.forEach((slot, i) => {
              const invitation = invitations.find(
                (i) => i.id === slot.invitation_id,
              );
              const assignment = taskAssignments.find(
                (a) => a.invitation_id === invitation.id,
              );

              if (assignment) {
                assignmentsToDelete.push(assignment);
              }
            });
          });

          this.assignmentService.delete(assignmentsToDelete);
        },
      });
  }

  accept(slots: SlotPos[], invitations: Invitation[]) {
    invitations.forEach((invitation) => {
      invitation = Object.assign({}, invitation);
      const invitationSlots = slots.filter(
        (s) => s.invitation_id === invitation.id,
      );
      const states = Object.assign({}, invitation.states);

      invitationSlots.forEach((slot) => {
        const lookup = integerToString(slot.x);
        states[lookup] = InvitationState.accepted;
      });

      invitation.states = states;

      this.invitationCollection.update(invitation.id, invitation);
    });
  }

  refuse(slots: SlotPos[], invitations: Invitation[]) {
    invitations.forEach((invitation) => {
      invitation = Object.assign({}, invitation);
      const invitationSlots = slots.filter(
        (s) => s.invitation_id === invitation.id,
      );
      const states = Object.assign({}, invitation.states);

      invitationSlots.forEach((slot) => {
        const lookup = integerToString(slot.x);
        states[lookup] = InvitationState.refused;
      });

      invitation.states = states;

      this.invitationCollection.update(invitation.id, invitation);
    });
  }

  cancel(slots: SlotPos[], invitations: Invitation[]) {
    invitations.forEach((invitation) => {
      invitation = Object.assign({}, invitation);
      const invitationSlots = slots.filter(
        (s) => s.invitation_id === invitation.id,
      );
      const states = Object.assign({}, invitation.states);

      invitationSlots.forEach((slot) => {
        const lookup = integerToString(slot.x);
        states[lookup] = InvitationState.cancelled;
      });

      invitation.states = states;

      this.invitationCollection.update(invitation.id, invitation);
    });
  }

  delete(invitations: Invitation[]) {
    if (invitations.some((i) => !i.shallow)) {
      this.section(invitations, 'delete');
    } else {
      this.announcementCollection
        .all()
        .pipe(first())
        .subscribe({
          next: (announcements) => {
            const announcementIds = [
              ...new Set(invitations.map((i) => i.announcement_id)),
            ];
            announcements = announcements.filter(
              (a) => announcementIds.indexOf(a.id) !== -1,
            );
            announcements = announcements.map((announcement) =>
              Object.assign({}, announcement),
            );

            invitations.forEach((invitation) => {
              const announcement = announcements.find(
                (a) => a.id === invitation.announcement_id,
              );
              announcement.resources = announcement.resources.filter(
                (r) =>
                  !(
                    r.type === invitation.resource_type &&
                    r.id === invitation.resource_id
                  ),
              );
            });

            announcements.map((announcement) =>
              this.announcementCollection.update(announcement.id, announcement),
            );
          },
        });
    }
  }

  section(invitations: any, section: string, single = false) {
    const ref = this.modalService.open(DispoInvitationComponent, {
      data: {
        invitations: invitations,
        section: section,
        single: single,
      },
      modalTitle: this.translations['dispo/invitation.modal.title'],
    });
  }

  single(avail: any, section: string) {
    this.section(avail, section, true);
  }

  viewLogs(resource_id: number) {
    this.modalService.open(LogsComponent, {
      data: {
        resource_id,
        resource_type: 'TcDispo::Invitation',
      },
      modalTitle: this.translations['trails.title'],
    });
  }
}
