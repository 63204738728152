import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  areIntervalsOverlapping,
  differenceInMinutes,
  isAfter,
  isBefore,
} from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isValidInterval } from '@timecount/utils';

import { LocalSettingsService } from '../../../../core/local-settings.service';
import { DispoResourceDataStructure } from '../../../datastructures/resource.service';
import { DispoFocusService } from '../../../dispo-focus.service';
import { EmployeesGridItemTogglerService } from '../../shared/employees-grid-item-toggler/employees-grid-item-toggler.service';

@Component({
  selector: 'tc-hub-de-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DEEmployeeComponent implements OnInit {
  @Input() employee: any;
  @Input() panelBegin: Date;
  @Input() panelEnd: Date;

  gridDateWidth = 360;
  gridResolution = 24;

  dates$: Observable<any[]>;
  resource$: Observable<any>;
  availabilities$: Observable<any>;
  assignments$: Observable<any>;
  invitations$: Observable<any>;
  timebalances$: Observable<any>;

  constructor(
    public gridItemTogglerService: EmployeesGridItemTogglerService,
    private dispoFocus: DispoFocusService,
    private dispoResourceDS: DispoResourceDataStructure,
    private localSettings: LocalSettingsService,
  ) {}

  ngOnInit() {
    this.dates$ = this.dispoFocus.dates();
    this.resource$ = this.dispoResourceDS.employee(this.employee.id);

    this.availabilities$ = this.resource$.pipe(
      map((resource) =>
        resource.availabilities.filter((item) => this.isItemInPanel(item)),
      ),
    );

    this.assignments$ = this.resource$.pipe(
      map((resource) =>
        resource.assignments.filter((item) =>
          item.task ? this.isItemInPanel(item.task) : false,
        ),
      ),
    );

    this.invitations$ = this.resource$.pipe(
      map((resource) =>
        resource.invitations.filter((item) =>
          item.task ? this.isItemInPanel(item.task) : false,
        ),
      ),
    );

    this.timebalances$ = this.resource$.pipe(
      map((resource) =>
        resource.timebalances.filter((item) => this.isItemInPanel(item)),
      ),
    );
  }

  onMenu($event, item_type, item) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({
      item_type: item_type,
      item: [item],
    });
  }

  onMouseOver(type, item) {
    this.localSettings.set('dispoInfo.item', { type: type, item: item });
  }

  public trackById(index, item) {
    return item.id;
  }

  public trackByHash(index, item) {
    return item.hash;
  }

  public getIntervalOffset(start: Date, end: Date): string {
    const hourResolution = this.gridDateWidth / this.gridResolution;
    const startRef = isAfter(start, this.panelBegin) ? start : this.panelBegin;
    const endRef = isBefore(end, this.panelEnd) ? end : this.panelEnd;

    // Define the start offset and length in pixel, based on their minutes
    const startOffset = Math.round(
      (hourResolution * startRef.getMinutes()) / 60,
    );
    const intervalWidth = Math.round(
      (differenceInMinutes(endRef, startRef) * hourResolution) / 60,
    );

    return `transform: translateX(${startOffset}px); width: ${intervalWidth}px;`;
  }

  private isItemInPanel(
    { starts_at, ends_at, ..._args }: { starts_at: Date; ends_at: Date } = {
      starts_at: undefined,
      ends_at: undefined,
    },
  ): boolean {
    const itemInterval = { start: starts_at, end: ends_at };
    const panelInterval = { start: this.panelBegin, end: this.panelEnd };

    return (
      isValidInterval(itemInterval) &&
      isValidInterval(panelInterval) &&
      areIntervalsOverlapping(itemInterval, panelInterval)
    );
  }
}
