<ng-template
  cdkConnectedOverlay
  nzConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOffsetY]="-2"
  [cdkConnectedOverlayTransformOriginOn]="'.ant-picker-dropdown'"
  (detach)="close()"
  (backdropClick)="close()"
>
  <div [@slideMotion]="'enter'" class="ant-picker-dropdown">
    <div class="ant-picker-panel-container">
      <div tabindex="-1" class="ant-picker-panel">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
