import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { takeUntil } from 'rxjs/operators';
import { startOfDay } from 'date-fns';

import {
  DateUnicodeFormat,
  isValidDate,
  stringifyDate,
} from '@timecount/utils';

import { TcFormValidationService } from '../../../form-validation.service';
import { TcFormPickerType } from '../../../shared/form-pickers/form-picker.model';
import { TcFormPickersService } from '../../../shared/form-pickers/form-pickers.service';
import { TcInputBaseDirective } from '../input-base.directive';
import { TcInputService } from '../input.service';
import {
  TcInputDateTimeReferenceDirective,
  TcInputTimeDateOptionsDirective,
} from '../../../shared';
import { TcInputDateTimeConfig } from '../input-datetime.config';

import { TcInputTypeWeb } from './input-type-web.model';
import { TcInputWebMaskType } from './input-web-mask.model';
import { TcInputWebMaskDirective } from './input-web-mask.directive';

@Component({
  selector: `
    tc-input-web,
    tc-input-email-web,
    tc-input-text-web,
    tc-input-number-web,
    tc-input-dateTime-web,
    tc-input-date-web,
    tc-input-time-web,
    tc-input-color-web,
    tc-input-integer-web,
    tc-input-percent-web,
    tc-input-currency-web,
    tc-input-password-web
  `,
  templateUrl: './input-web.component.html',
  styleUrls: ['./input-web.component.scss'],
})
export class TcInputWebComponent
  extends TcInputBaseDirective
  implements OnChanges, OnInit
{
  @Input() name: string = null;

  @Input()
  @HostBinding('class.has-addon-after')
  set tcAddOnAfter(value: TemplateRef<void>) {
    this._tcAddOnAfter = value;
  }
  get tcAddOnAfter(): TemplateRef<void> | null {
    return (
      this._tcAddOnAfter ??
      (this.fieldType === 'percent'
        ? this.percentAddOnTemplate
        : this.fieldType === 'currency'
        ? this.currencyAddOnTemplate
        : null)
    );
  }
  private _tcAddOnAfter: TemplateRef<void> | null;

  @Input()
  @HostBinding('class.has-addon-before')
  tcAddOnBefore: TemplateRef<void>;

  /**
   * Used to define the type attribute of the actual input tag
   *
   * Masked inputs require the input type to be `text`.
   */
  get inputType(): TcInputTypeWeb {
    return this.fieldType === 'number' ||
      this.fieldType === 'color' ||
      this.fieldType === 'password'
      ? this.fieldType
      : this.fieldType === 'integer'
      ? 'number'
      : 'text';
  }

  /**
   * Used to define the mask type to be passed to the mask directive
   *
   * If undefined, no mask will be applied.
   */
  get maskType(): TcInputWebMaskType {
    return this.fieldType !== 'text' && this.fieldType !== 'color'
      ? (this.fieldType as TcInputWebMaskType)
      : null;
  }

  /**
   * Used to hold the information about the current state of the input's
   * mouseover.
   *
   * This is required because we may need to take actions based on
   * the mouse over state, while it hasn't been changed, so the `mouseover`
   * and `mouseout` events can't be used.
   */
  isMouseOverInput = false;

  /**
   * Used to hold the information about the current state of the input's
   * focus.
   *
   * This is required because we may need to take actions based on
   * the focus state, while it hasn't been changed, so the `focus`
   * and `blur` events can't be used.
   */
  isInputFocused = false;

  @ViewChild('errorsPopover', { static: false })
  errorsPopover: NzPopoverDirective;

  @ViewChild('inputWrapper', { read: ViewContainerRef })
  inputWrapper;

  @ViewChild('dateSelectorTemplate', { static: false })
  dateSelectorTemplate: TemplateRef<void>;

  @ViewChild('percentAddOnTemplate', { static: false })
  percentAddOnTemplate: TemplateRef<void>;

  @ViewChild('currencyAddOnTemplate', { static: false })
  currencyAddOnTemplate: TemplateRef<void>;

  iconTypes = {
    dateTime: 'calendar',
    date: 'calendar',
    time: 'clock-circle',
    color: 'bg-colors',
  };

  constructor(
    public ngControl: NgControl,
    public elementRef: ElementRef,
    public formValidationService: TcFormValidationService,
    private formPickersService: TcFormPickersService,
    public inputService: TcInputService,
    @Optional() public refDateDir?: TcInputDateTimeReferenceDirective,
    @Optional() public dateOptionsDir?: TcInputTimeDateOptionsDirective,
    @Optional() public maskDir?: TcInputWebMaskDirective,
  ) {
    super(
      ngControl,
      elementRef,
      formValidationService,
      inputService,
      refDateDir,
      dateOptionsDir,
    );
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const { fieldType } = changes;
    if (
      !!fieldType &&
      this.elementRef.nativeElement.tagName.toLowerCase() !== 'tc-input-web'
    ) {
      console.warn(`
        The 'fieldType' input is ignored when not
        using the generic 'tc-input-web' selector.
      `);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.ngControl.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.errorsPopover) {
          this._togglePopover();
        }
      });
  }

  // ---------------
  // Events Handlers
  // ---------------

  onKeydown(event: KeyboardEvent) {
    if (
      this.fieldType === 'integer' &&
      (event.key === '.' || event.key === ',')
    ) {
      event.preventDefault();
    }
  }

  onIconClick() {
    const pickerOptions = {
      initialValue: this.value,
    };

    const { limit } = (this.tcConfig as TcInputDateTimeConfig) ?? {};

    if (limit) {
      const { start, end } = limit;

      pickerOptions[`limit`] = {
        start: this.fieldType === 'date' ? startOfDay(start) : start,
        end: this.fieldType === 'date' ? startOfDay(end) : end,
      };
    }

    const picker = this.formPickersService.open(
      this.fieldType as TcFormPickerType,
      this.inputWrapper,
      pickerOptions,
    );

    picker.valueChange$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: Date) => {
        this.value = value;
      });
  }

  // --------------
  // Public Methods
  // --------------

  stringifyValue(): string {
    const isDateValue = ['dateTime', 'date', 'time'].some(
      (el) => el === this.fieldType,
    );

    return isDateValue && isValidDate(this.value)
      ? stringifyDate(this.value, DateUnicodeFormat[this.fieldType])
      : this.value || this.value === 0
      ? this.value
      : '';
  }

  parseValue(value: string) {
    // Note: The date and time masked inputs will parse the value inside the
    // mask directive. The value is then emitted and set directly to the
    // `this.value` property in the template.
    this.value =
      (this.fieldType === 'number' || this.fieldType === 'integer') &&
      value.length
        ? Number(value)
        : value;
  }

  toggleFocus(state: boolean) {
    this.isInputFocused = state;
    this._togglePopover();
  }

  toggleMouseOver(state: boolean) {
    this.isMouseOverInput = state;
    this._togglePopover();
  }

  // ---------------
  // Private Methods
  // ---------------

  private _togglePopover() {
    if (
      !this.ngControl.errors ||
      (!this.isInputFocused && !this.isMouseOverInput)
    ) {
      this.errorsPopover.hide();
    } else if (this.isMouseOverInput || this.isInputFocused) {
      this.errorsPopover.show();
    }
  }
}
