import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  add,
  isAfter,
  isBefore,
  startOfDay,
  startOfMinute,
  sub,
} from 'date-fns';

import {
  DateUnicodeFormat,
  isEmail,
  isUrl,
  isValidDate,
  isValidInterval,
  stringifyDate,
} from '@timecount/utils';

import { TcInputDateTimeConfig } from './input-datetime.config';
import { TcInputConfig } from './input.config';

export class TcInputValidators {
  static minMaxNumber(min = 0, max = 100): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const actual = Number(control.value);

        if (typeof actual !== 'number') {
          return {
            // TODO: Replace this backend property with one from the `locale` lib
            [`number`]: true,
          };
        }
        if (min !== undefined && actual < min) {
          return {
            // TODO: Replace this backend property with one from the `locale` lib
            [`min_number`]: { min, actual },
          };
        }

        if (max !== undefined && actual > max) {
          return {
            // TODO: Replace this backend property with one from the `locale` lib
            [`max_number`]: { max, actual },
          };
        }
      }

      return null;
    };
  }

  static integer(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const actual = Number(control.value);

      if (!Number.isInteger(actual)) {
        return {
          // TODO: Replace this backend property with one from the `locale` lib
          [`not_an_integer`]: true,
        };
      }
    }
    return null;
  }

  static dateTime(control: AbstractControl): ValidationErrors | null {
    return control.value && !isValidDate(control.value)
      ? {
          // TODO: Replace this backend property with one from the `locale` lib
          [`date`]: true,
        }
      : null;
  }

  static date(control: AbstractControl): ValidationErrors | null {
    return control.value && !isValidDate(control.value)
      ? {
          // TODO: Replace this backend property with one from the `locale` lib
          [`invalid_date`]: true,
        }
      : null;
  }

  static time(control: AbstractControl): ValidationErrors | null {
    return control.value && !isValidDate(control.value)
      ? {
          // TODO: Replace this backend property with one from the `locale` lib
          [`invalid_time`]: true,
        }
      : null;
  }

  /**
   * Validates if a control matches the value from another
   *
   * @param controlName The name of control with the value to be matched
   * @param matchingControlName The name of control that must to match the other
   */
  static matchingValues(
    controlName: string,
    matchingControlName: string,
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);
      const errorKey = 'mismatch';

      // Ignore when there are already errors from other validators
      if (!matchingControl.errors || matchingControl.errors[errorKey]) {
        matchingControl.setErrors(
          control.value !== matchingControl.value &&
            matchingControl.value.length
            ? { [errorKey]: true }
            : null,
        );
      }

      return null;
    };
  }

  static email({ multiple, headerType }: TcInputConfig): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value?.length > 0) {
        const emails = multiple ? control.value.split(',') : [control.value];
        if (emails.some((value) => !isEmail(value, { headerType }))) {
          return { email: true };
        }
      }

      return null;
    };
  }

  static limit(config: TcInputDateTimeConfig): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { exclusiveLimitDates, outerLimit, fieldType } = config;

      // Create a copy of limit to be able to change it, when necessary, as the
      // received value is immutable.
      const limit: Partial<Interval> = { ...config.limit };

      if (
        isValidDate(control.value) &&
        (isValidDate(limit?.start) || isValidDate(limit?.end))
      ) {
        const valueToCheck =
          fieldType === 'date'
            ? startOfDay(control.value)
            : startOfMinute(control.value);

        limit['start'] =
          fieldType === 'date' ? startOfDay(limit.start) : limit.start;
        limit['end'] = fieldType === 'date' ? startOfDay(limit.end) : limit.end;

        const exclusiveDifference =
          fieldType === 'date'
            ? { days: Number(!!exclusiveLimitDates) }
            : { minutes: Number(!!exclusiveLimitDates) };

        const maxDate = sub(
          outerLimit ? limit.start : limit.end,
          exclusiveDifference,
        );

        const minDate = add(
          outerLimit ? limit.end : limit.start,
          exclusiveDifference,
        );

        const respectsMinDate =
          !isValidDate(minDate) || !isBefore(valueToCheck, minDate);
        const respectsMaxDate =
          !isValidDate(maxDate) || !isAfter(valueToCheck, maxDate);

        let timePeriod;
        let dateMinMaxString;
        let expectedLimit;
        if (!respectsMaxDate && !respectsMinDate) {
          return {
            [outerLimit ? 'date_not_between_dates' : 'date_between_dates']: {
              minDate: stringifyDate(
                minDate,
                DateUnicodeFormat[config.fieldType],
              ),
              maxDate: stringifyDate(
                maxDate,
                DateUnicodeFormat[config.fieldType],
              ),
            },
          };
        } else if (!respectsMinDate) {
          timePeriod = 'after';
          dateMinMaxString = 'minDate';
          expectedLimit = minDate;
        } else if (!respectsMaxDate) {
          timePeriod = 'before';
          dateMinMaxString = 'maxDate';
          expectedLimit = maxDate;
        }

        // if limit is not valid, at least one date will be respected
        if (
          (!!outerLimit && isValidInterval(limit as Interval)) ===
          (respectsMinDate && respectsMaxDate)
        ) {
          return {
            [`date_same_or_${timePeriod}_date`]: {
              [dateMinMaxString]: stringifyDate(
                expectedLimit,
                fieldType.toLowerCase().includes('time')
                  ? DateUnicodeFormat.dateTime
                  : DateUnicodeFormat.date,
              ),
            },
          };
        }
      }
      return null;
    };
  }

  static url(control: AbstractControl): ValidationErrors | null {
    return isUrl(control.value) ? null : { invalidURL: true };
  }
}
