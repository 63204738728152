<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <tc-input-date-web
              label="{{ 'value_set.attrs.starts_at' | translate }}"
              formControlName="starts_at"
            ></tc-input-date-web>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">
              {{ 'value_set.attrs.note' | translate }}
              <textarea
                tcFormControlValidator
                class="form-control"
                rows="2"
                name="note"
                formControlName="note"
                autosize
              >
              </textarea>
            </label>
          </div>
        </div>
      </div>

      <hr />

      <tc-hub-custom-fields
        [section]="valueSet.schema_key"
        [formGroup]="form"
        formControlName="data"
      >
      </tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      *ngIf="!valueSet.applied_at"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      *ngIf="valueSet.id"
      (click)="remove()"
    >
      {{ 'delete' | translate }}
    </button>
  </div>
</form>
