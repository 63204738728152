import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { first, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { TcFieldSetIntervalSetService } from '@timecount/ui';
import { TcConfigService } from '@timecount/core';

import {
  Supplement,
  SupplementCollection,
} from '../../core/collections/supplement';
import { CurrentUserService } from '../../core/current-user.service';
import {
  generateFieldsForSupplements,
  generateSupplementsFromForm,
} from '../../core/supplements';
import { Action } from '../../core/types/action';
import { ActionType } from '../../core/types/action-type';
import { EntryType } from '../../core/types/entry-type';
import { ValidationService } from '../../dispo/collections/validation.service';
import { CollectionType } from '../../shared/models/collection-type.model';
import { CollectionService } from '../../core/collection.service';
import { TimesheetCollection } from '../timesheet.collection';
import { Timesheet } from '../timesheet.model';

@Component({
  selector: 'tc-hub-ts-multi',
  templateUrl: './multi.component.html',
  styleUrls: ['./multi.component.scss'],
})
export class TSMultiComponent implements OnInit {
  @Input() timesheets: Timesheet[];

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  supplements: Supplement[];
  loading = false;
  extendAll$ = new Subject();

  isJobOptionsLoading = true;
  jobOptions$: Observable<unknown[]>;

  isResourceOptionsLoading = true;

  resourceOptionsEmployees$: Observable<unknown[]>;
  resourceOptionsContractors$: Observable<unknown[]>;

  isMulti = window.config.company.multi_breaks;
  allowsPlus24Hours =
    this.configService.config.company.times.allowDoubledWorkingDay;

  constructor(
    private timesheetCollection: TimesheetCollection,
    private collectionService: CollectionService,
    private supplementService: SupplementCollection,
    private formBuilder: UntypedFormBuilder,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
    private validationService: ValidationService,
    private currentUser: CurrentUserService,
    private configService: TcConfigService,
  ) {
    this._getOptions();
  }

  ngOnInit() {
    this.supplementService
      .all()
      .pipe(first())
      .subscribe({
        next: (supplements) => {
          this.supplements = supplements.filter(
            (s) => s.visibility === 'visible' || s.visibility === 'fields_only',
          );

          this._buildForm(this.timesheets);

          setTimeout(() => this.signal.emit({ action: 'resize' }), 200);
        },
      });
  }

  save() {
    this.loading = true;
    const actions = [];
    const formValues = Object.assign([], this.form.get('timesheets').value);

    formValues.forEach((formValue) => {
      let timesheet = this.timesheets.find((a) => a.id === formValue.id);

      if (
        timesheet.state === 'confirmed' &&
        !this.currentUser.can('manage', 'Timesheet', ['unlocked'])
      ) {
        return;
      }

      if (timesheet.shallow && !formValue[`tracked_time`]) {
        return;
      }

      timesheet = Object.assign({}, timesheet, {
        state: formValue.state || timesheet.state,
        job_id: formValue.job_id || timesheet.job_id,
        ...this.fieldSetIntervalSetService.formatValueToApi(formValue.times, {
          includeMarker: true,
        }),
      });

      timesheet.supplements = Object.assign(
        {},
        timesheet.supplements,
        generateSupplementsFromForm(formValue, this.supplements),
      );

      timesheet.store = Object.assign({}, timesheet.store, formValue.store);

      let actionType;
      if (timesheet.shallow) {
        actionType = ActionType.create;
      } else if (!timesheet.shallow) {
        actionType = ActionType.update;
      }

      if (actionType) {
        actions.push(<Action>{
          id: uuid(),
          entry: timesheet,
          entry_type: EntryType.Timesheet,
          type: actionType,
          validations: (item, stack) =>
            this.timesheetCollection.remoteValidations(
              timesheet,
              [],
              actionType,
            ),
          errors: [],
        });
      }
    });

    this.validationService.run(actions);
    this.signal.emit({ action: 'close' });
  }

  applyToAll(path: string) {
    const applyToAllControl = this.form.get(`applyToAll.${path}`);
    const timesheets = this.form.get('timesheets') as UntypedFormArray;

    const { value } = applyToAllControl;

    timesheets.controls.forEach((control) => {
      const specificControl = control.get(path);
      if (!specificControl?.disabled) {
        specificControl.setValue(value);

        if (path === 'tracked_time') {
          this.toggle(control);
        } else {
          // We need to mark the updated controls as touched for the validation
          // feedback to appear.
          specificControl.markAsTouched({ onlySelf: true });
        }
      }
    });

    applyToAllControl.reset();
  }

  toggle(timesheetControl) {
    const { tracked_time, state } = timesheetControl.value;

    if (state === 'confirmed' && !this.currentUser.can('manage', 'Timesheet')) {
      return;
    }

    const fields = [
      'times',
      'state',
      'job_id',
      'gratis',
      ...this.supplements.map((s) => `supplement_${s.id}`),
    ];

    fields.forEach((field) => {
      const control = timesheetControl.get(field);

      if (tracked_time) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }

  // ---------------
  // Private Methods
  // ---------------

  private _getOptions() {
    this.jobOptions$ = this.collectionService
      .get(CollectionType.Job)
      .all()
      .pipe(
        take(1),
        tap(() => {
          this.isJobOptionsLoading = false;
        }),
      );

    this.resourceOptionsEmployees$ = this.collectionService
      .get(CollectionType.Employee)
      .all()
      .pipe(
        take(1),
        tap(() => {
          this.isResourceOptionsLoading = false;
        }),
      );

    this.resourceOptionsContractors$ = this.collectionService
      .get(CollectionType.Contractor)
      .all()
      .pipe(
        take(1),
        tap(() => {
          this.isResourceOptionsLoading = false;
        }),
      );
  }

  private _buildForm(timesheets) {
    timesheets.sort(
      (a, b) =>
        a.starts_at.getTime() - b.starts_at.getTime() ||
        a.position - b.position,
    );
    this.form = this.formBuilder.group({
      applyToAll: this._getRowFormGroupValue(),
      timesheets: this.formBuilder.array(
        timesheets.map((timesheet) => this._getRowFormGroupValue(timesheet)),
      ),
    });
  }

  private _getRowFormGroupValue(timesheet?) {
    const {
      id,
      tracked_time,
      resource_type,
      resource_id,
      starts_at,
      job_id,
      shallow,
      state,
      gratis,
      store,
      position,
    } = timesheet ?? {};

    const formDisabled =
      // When no timesheet is provided the formDisabled will be false, as state
      // is undefined in this case
      state === 'confirmed' &&
      !this.currentUser.can('manage', 'Timesheet', ['unlocked']);

    const timesheetFormGroup = this.formBuilder.group({
      id: [id],
      resource_type: [resource_type],
      tracked_time: [
        {
          value: !!timesheet,
          disabled: !!timesheet && !shallow,
        },
      ],
      date: [starts_at],
      times: timesheet
        ? [
            this.fieldSetIntervalSetService.parseFromApi(timesheet),
            Validators.required,
          ]
        : [],
      gratis: [
        {
          value: gratis,
          disabled: formDisabled,
        },
      ],
      job_id: [
        {
          value: job_id,
          disabled: formDisabled,
        },
      ],
      state: [
        {
          value: state,
          disabled:
            !!timesheet &&
            !this.currentUser.can('manage', 'Timesheet', ['unlocked']),
        },
      ],
      store: [
        {
          value: store,
          disabled: formDisabled,
        },
      ],
      position: [position + 1],
    });

    if (resource_id) {
      timesheetFormGroup.addControl(
        'resource_id',
        new UntypedFormControl({
          value: resource_id,
          disabled: true,
        }),
      );
    }

    const supplementFields = generateFieldsForSupplements(
      timesheet,
      this.supplements,
      { disabled: formDisabled || tracked_time === false },
    );

    for (const key in supplementFields) {
      timesheetFormGroup.addControl(
        key,
        new UntypedFormControl(
          supplementFields[key][0],
          supplementFields[key][1],
        ),
      );
    }

    return timesheetFormGroup;
  }
}
