import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';
import { DispoTaskCollection, SlotPos } from '../../collections/task';
import { DispoCombinedTaskCollection } from '../../collections/combined_task';
import { integerToString } from '../../../core/helpers';
import { Schedule } from '../../collections/schedule';

@Component({
  selector: 'tc-hub-dan-append-slots',
  templateUrl: './append-slots.component.html',
  styleUrls: ['./append-slots.component.css'],
})
export class DANAppendSlotsComponent implements OnInit {
  @Input() schedule: Schedule;
  @Input() slots: SlotPos[];

  @Output() signal: EventEmitter<any> = new EventEmitter();

  loading = false;
  announcements$: Observable<Announcement[]>;

  constructor(
    private announcementCollection: DispoAnnouncementCollection,
    private taskCollection: DispoTaskCollection,
    private combinedTaskCollection: DispoCombinedTaskCollection,
  ) {}

  ngOnInit(): void {
    const plan_id = this.schedule.plan_id;
    const schedule_id = this.schedule.id;
    const task_slots = [...new Set(this.slots.map((s) => s.y))];

    this.announcements$ = combineLatest(
      this.announcementCollection.all(),
      this.taskCollection.all(),
    ).pipe(
      map(([announcements, tasks]) => {
        announcements = announcements
          .filter((a) => a.plan_id === plan_id)
          .filter((a) => a.schedule_id === schedule_id)
          .filter((a) => a.state !== 'closed')
          .filter((a) => {
            return (
              a.task_slots.length === 0 ||
              task_slots.every((s) => a.task_slots.includes(s))
            );
          })
          .map((announcement) => {
            announcement = Object.assign({}, announcement);
            announcement.tasks = tasks
              .filter((t) => announcement.task_ids.indexOf(t.id) !== -1)
              .sort((t1, t2) => t1.marker[0] - t2.marker[0]);

            return announcement;
          })
          .sort((a, b) => {
            return (
              (a.task_slots.length === 0 ? 99 : a.task_slots.length) -
              (b.task_slots.length === 0 ? 99 : b.task_slots.length)
            );
          });

        return announcements;
      }),
    );
  }

  appendTo(announcement: Announcement) {
    const newValues = {
      ...announcement,
      task_ids: [...announcement.task_ids],
      sizes: { ...announcement.sizes },
    };

    this.loading = true;

    this.combinedTaskCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (tasks) => {
          const dates = this.slots.map((s) => integerToString(s.x));

          tasks = tasks.filter(
            (t) => t.schedule_id === this.schedule.id && dates.includes(t.date),
          );

          this.taskCollection
            .activateTasks(tasks)
            .pipe(first())
            .subscribe({
              next: (tasks) => {
                tasks.forEach((task) => {
                  const posToAdd = dates.filter((d) => d === task.date).length;

                  if (!newValues.task_ids.includes(task.id)) {
                    newValues.task_ids.push(task.id);
                  }

                  if (!newValues.sizes[task.date]) {
                    newValues.sizes[task.date] = posToAdd;
                  } else {
                    newValues.sizes[task.date] =
                      newValues.sizes[task.date] + posToAdd;
                  }
                });

                this.announcementCollection
                  .update(announcement.id, newValues)
                  .subscribe({
                    next: (announcement: Announcement) => {
                      this.loading = false;
                      this.signal.emit({ action: 'close' });
                    },
                    error: () => {
                      this.loading = false;
                    },
                  });
              },
            });
        },
      });
  }
}
