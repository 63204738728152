import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TcFormValidationService {
  constructor(private translateService: TranslateService) {}

  /**
   * Check if the passed control should be marked as required
   *
   * It also marks form groups as required if any any of its children should be marked
   */
  isControlRequired(abstractControl: AbstractControl): boolean {
    if (abstractControl?.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      const { value, disabled, errors } = abstractControl;

      if (
        (validator?.required || errors?.required) &&
        !disabled &&
        (value == null || value === '')
      ) {
        return true;
      }
    }

    return this.checkChildControls(abstractControl, this.isControlRequired);
  }

  /**
   * Check if the passed control should be marked as invalid
   *
   * It also marks form groups as invalid if any any of its children should be marked
   */
  isControlInvalid(abstractControl: AbstractControl): boolean {
    const isEmptyRequired =
      abstractControl.errors?.required && !abstractControl.value;

    if (
      abstractControl.invalid &&
      abstractControl.touched &&
      !abstractControl.disabled &&
      !isEmptyRequired
    ) {
      return true;
    }

    return this.checkChildControls(abstractControl, this.isControlInvalid);
  }

  /**
   * Get the translated error message for a specific validator
   *
   * @param interpolateParams If the message has interpolated params, they can be passed here as an object.
   */
  getValidatorErrorMessage(
    validatorName: string,
    interpolateParams?,
  ): Observable<string> {
    return this.translateService.get(
      `errors.messages.${validatorName}`,
      interpolateParams,
    );
  }

  private checkChildControls(
    abstractControl: AbstractControl,
    checkFunction: (abstractControl: AbstractControl) => boolean,
  ): boolean {
    if (abstractControl && abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (
          abstractControl['controls'][controlName] &&
          checkFunction(abstractControl['controls'][controlName])
        ) {
          return true;
        }
      }
    }

    return false;
  }
}
