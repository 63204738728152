<div class="resource"></div>
<div
  class="week"
  *ngFor="let week of weeks; let first = first; let last = last"
  [ngClass]="{ first: first, last: last }"
>
  <div class="kw">{{ week[0].date | date: "'KW' w" }}</div>
  <div class="days">
    <tc-hub-dispo-shift-grid-header-day
      class="day"
      *ngFor="let day of week; let first = first; let last = last"
      [class.is-compact]="isCompact"
      [ngClass]="{ first: first, last: last }"
      [day]="day"
      headerType="short"
    ></tc-hub-dispo-shift-grid-header-day>
  </div>
</div>
