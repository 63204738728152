import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SetsCollection } from '../sets-collection';
import { ResourceTemplate } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class ResourceTemplateCollection extends SetsCollection {
  identifier = 'preferences/resource_template';
  endpoint = '/api/preferences/resource_templates';

  cache = 60 * 60 * 12;

  localSearch = ['title'];

  setForResource(resource) {
    return this.query({ resource: resource }, (e) => e.resource === resource);
  }

  default<ResourceType>(
    resourceKey: string,
  ): Observable<ResourceTemplate<ResourceType>> {
    return this.all().pipe(
      first(),
      map((templates: ResourceTemplate<ResourceType>[]) =>
        templates.find((t) => t.default && t.resource === resourceKey),
      ),
    );
  }
}
