import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { CurrentUserService } from '../../core/current-user.service';
import { TimesheetService } from '../../timesheets/timesheet.service';
import { Timesheet, TimesheetCollection } from '../../timesheets';
import { DispoAssignmentCollection } from '../collections/assignment';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuTimeTracking {
  private translations: { [key: string]: string } = {};
  private maxRequests = 250;

  constructor(
    private translateService: TranslateService,
    private timesheetActions: TimesheetService,
    private timesheetCollection: TimesheetCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'timesheet.menu.timesheet',
        'timesheet.menu.delete',
        'timesheet.menu.confirm',
        'timesheet.menu.unconfirm',
        'timesheet.menu.edit',
        'timesheet.menu.track_time',
        'trails.actions.resource',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  items(currTimesheets, currAssignments): Observable<any[]> {
    return combineLatest(
      this.timesheetCollection.observeItems(currTimesheets),
      this.assignmentCollection.observeItems(currAssignments),
      this.timesheetCollection.forAssignments(currAssignments),
    ).pipe(
      map(([timesheets, assignments, relatedTimesheets]) => {
        timesheets = Object.values(
          [...timesheets, ...relatedTimesheets].reduce((memo, t) => {
            memo[t.id] = t;
            return memo;
          }, {}),
        );

        const assignmentsWithMissingTimes = assignments.filter(
          (a) => !a.tracked_time,
        );
        const timesheetsConfirmed = timesheets.filter(
          (t: Timesheet) => t.state === 'confirmed',
        );
        const timesheetsUnconfirmed = timesheets.filter(
          (t: Timesheet) => t.state === 'unconfirmed',
        );
        const confirmableSheets = [
          ...timesheetsUnconfirmed,
          ...assignmentsWithMissingTimes,
        ];

        timesheets = timesheets.map((timesheet) => {
          const { position } =
            assignments.find(({ id }) => id === timesheet.source_id) ?? {};
          return { ...timesheet, position };
        });

        const allItems = [...timesheets, ...assignmentsWithMissingTimes];

        if (allItems.length === 0) {
          return [];
        }

        const timesheetActions = [];

        timesheetActions.push({
          label: this.translations['timesheet.menu.edit'],
          icon: 'edit',
          visible:
            allItems.length === 1 &&
            this.currentUser.hasAccessToSection('views.timesheet.general'),
          command: (event) => {
            this.timesheetActions.edit(allItems[0]);
          },
        });

        timesheetActions.push({
          label:
            this.translations['timesheet.menu.track_time'] +
            ` (${allItems.length})`,
          icon: 'clock-circle',
          disabled: allItems.length > this.maxRequests,
          visible:
            allItems.length > 0 &&
            this.currentUser.hasAccessToSection('views.timesheet.multi'),
          command: (event) => {
            this.timesheetActions.multi(allItems);
          },
        });

        timesheetActions.push({
          label:
            this.translations['timesheet.menu.confirm'] +
            ` (${confirmableSheets.length})`,
          icon: 'check-circle',
          disabled: confirmableSheets.length > this.maxRequests,
          visible:
            confirmableSheets.length > 0 &&
            this.currentUser.hasAccessToSection('views.timesheet.confirm') &&
            this.currentUser.can('manage', 'Timesheet', ['unlocked']),
          command: (event) => {
            this.timesheetActions.confirm(confirmableSheets);
          },
        });

        timesheetActions.push({
          label:
            this.translations['timesheet.menu.unconfirm'] +
            ` (${timesheetsConfirmed.length})`,
          icon: 'pause-circle',
          disabled: timesheetsConfirmed.length > this.maxRequests,
          visible:
            timesheetsConfirmed.length > 0 &&
            this.currentUser.hasAccessToSection('views.timesheet.unconfirm') &&
            this.currentUser.can('manage', 'Timesheet', ['unlocked']),
          command: (event) => {
            this.timesheetActions.unconfirm(timesheetsConfirmed);
          },
        });

        timesheetActions.push({
          label:
            this.translations['timesheet.menu.delete'] +
            ` (${timesheetsUnconfirmed.length})`,
          icon: 'delete',
          disabled: timesheetsUnconfirmed.length > this.maxRequests,
          visible:
            timesheetsUnconfirmed.length > 0 &&
            this.currentUser.hasAccessToSection('views.timesheet.delete'),
          command: (event) => {
            this.timesheetActions.delete(timesheetsUnconfirmed);
          },
        });

        if (timesheets?.length === 1) {
          timesheetActions.push({
            label: this.translations['trails.actions.resource'],
            icon: 'audit',
            visible:
              this.currentUser.hasAccessToFeature('logs') &&
              this.currentUser.can('index', 'PaperTrail::Version', {
                item_type: 'Timesheet',
              }),
            command: (_event: unknown) => {
              this.timesheetActions.viewLogs(timesheets[0].id);
            },
          });
        }

        const items = [
          ...(timesheetActions.length !== 0
            ? [
                {
                  header: true,
                  label: this.translations['timesheet.menu.timesheet'],
                },
                ...timesheetActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }
}
