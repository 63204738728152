<div class="wrapper">
  <div class="filter-bar">
    <tc-hub-dispo-filter
      section="dispo.employees_condensed"
    ></tc-hub-dispo-filter>
    <tc-hub-employees-grid-item-toggler></tc-hub-employees-grid-item-toggler>
  </div>

  <div class="panel">
    <div class="employees">
      <div class="content">
        <tc-hub-dispo-shift-grid-header-weeks
            [ngStyle]="styleHeader$ | async"
            [weeks]="weeks$ | async"
          ></tc-hub-dispo-shift-grid-header-weeks>

        <cdk-virtual-scroll-viewport
          [ngStyle]="style$ | async"
          [minBufferPx]="minBuffer"
          [maxBufferPx]="maxBuffer"
          itemSize="112"
          (scroll)="onScroll($event)"
        >
          <div
            class="rows"
            *cdkVirtualFor="
              let employee of employees$ | async;
              trackBy: trackById;
              templateCacheSize: 0
            "
          >
            <tc-hub-dec-employee
              [employee]="employee"
              [panelBegin]="panelBegin"
              [panelEnd]="panelEnd"
              (contextmenu)="onMenu($event, 'Employee', employee)"
              (mouseup)="onMenu($event, 'Employee', employee)"
              tcHubDispoMenuActive
              activeType="Employee"
              [activeId]="employee.id"
            ></tc-hub-dec-employee>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</div>
