import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'tc-hub-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
})
export class MarkdownComponent implements OnDestroy, AfterViewInit {
  @Input() data: string;
  @Output() update = new EventEmitter<string>();
  @ViewChild('markdown', { read: ElementRef }) markdown: ElementRef;

  ngAfterViewInit() {
    this.markdown.nativeElement.addEventListener(
      'change',
      this.onCheckboxChange.bind(this),
    );
  }

  ngOnDestroy() {
    this.markdown.nativeElement.removeEventListener(
      'change',
      this.onCheckboxChange.bind(this),
    );
  }

  onCheckboxChange(event) {
    if (!event.target.matches('input[type="checkbox"]')) {
      return;
    }

    const nodeList: NodeList = this.markdown.nativeElement.querySelectorAll(
      'input[type="checkbox"]',
    );
    let index;

    nodeList.forEach((node, i) => {
      if (node === event.target) {
        index = i;
      }
    });

    const updatedMarkdown = this.getUpdatedCheckboxMarkdown(
      index,
      event.target.checked,
    );
    this.update.emit(updatedMarkdown);
  }

  private getUpdatedCheckboxMarkdown(index: number, checked: boolean): string {
    const markdown = (' ' + this.data).slice(1);
    const replace = (match) => {
      if (checked) {
        return match.replace(/\[ ?\]/g, '[x]');
      } else {
        return match.replace(/\[(x|X)\]/g, '[ ]');
      }
    };

    let searchIndex = 0;
    return markdown.replace(
      /([*+-]|\d+[.])[ \t]+\[(x|X| )?\]/g,
      (match, i, original) => {
        if (searchIndex === index) {
          searchIndex++;
          return replace(match);
        } else {
          searchIndex++;
          return match;
        }
      },
    );
  }
}
