import { Pipe, PipeTransform } from '@angular/core';

import { TcFeedbackRequestStatus } from './feedback-request-status';
import { TcFeedbackRequestType } from './feedback-request-type';
import { TcFeedbackRequest } from './feedback-request.model';
import { hasFeedbackRequestsOnStatus } from './feedback-requests-helpers';

@Pipe({
  name: 'hasFeedbackRequestsOnStatus',
})
export class TcFeedbackRequestsHasStatusPipe implements PipeTransform {
  transform(
    feedbackRequests: TcFeedbackRequest[],
    status: TcFeedbackRequestStatus | TcFeedbackRequestStatus[],
    types?: TcFeedbackRequestType | TcFeedbackRequestType[],
  ): boolean {
    if (!Array.isArray(feedbackRequests)) {
      return feedbackRequests;
    }

    return hasFeedbackRequestsOnStatus(feedbackRequests, status, types);
  }
}
