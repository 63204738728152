import { Injectable } from '@angular/core';

import { FrameCollection } from '../../core/frame-collection';

@Injectable({
  providedIn: 'root',
})
export class BillingAggregationCollection extends FrameCollection {
  cache = 600;
  identifier = 'projects/aggregation';
  endpoint = '/api/finances/billing/aggregations/range';

  remoteDeleteValidation = true;
}
