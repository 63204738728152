import { Component } from '@angular/core';
import {
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from '@angular/forms';

import { TcFormGroupIntervalService } from '../../../form-groups/form-group-interval';
import { TcFieldsetIntervalListBaseDirective } from '../interval-list-base.directive';

@Component({
  selector: 'tc-interval-list-ionic',
  templateUrl: './interval-list-ionic.component.html',
  styleUrls: ['./interval-list-ionic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TcFielsetIntervalListIonicComponent,
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TcFielsetIntervalListIonicComponent,
      multi: true,
    },
  ],
})
export class TcFielsetIntervalListIonicComponent extends TcFieldsetIntervalListBaseDirective {
  constructor(
    public formBuilder: UntypedFormBuilder,
    public intervalService: TcFormGroupIntervalService,
    public formGroupIntervalService: TcFormGroupIntervalService,
  ) {
    super(formBuilder, intervalService, formGroupIntervalService, false);
  }

  toggleSliding(sliding) {
    sliding
      .getSlidingRatio()
      .then((ratio) => (ratio === 1 ? sliding.close() : sliding.open()))
      .catch((error) => console.error(error));
  }
}
