import { Component, Inject, OnInit } from '@angular/core';
import { catchError, finalize, first } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

import { ActionType } from '../../core/types/action-type';
import { Action } from '../../core/types/action';
import { EntryType } from '../../core/types/entry-type';
import { DispoAssignmentCollection } from '../collections/assignment';
import { DispoAvailabilityCollection } from '../collections/availability';
import { DispoInvitationCollection } from '../collections/invitation';
import { ValidationService } from '../collections/validation.service';

@Component({
  selector: 'tc-hub-dispo-conflict-resolver',
  templateUrl: './dispo-conflict-resolver.component.html',
  styleUrls: ['./dispo-conflict-resolver.component.scss'],
})
export class DispoConflictResolverComponent
  extends BaseModalComponent
  implements OnInit
{
  actions;
  confirmationRequired = false;
  confirmationMessage = '';
  loading = false;
  actionType = ActionType;

  private translations: { [key: string]: string } = {};

  constructor(
    protected config: ModalConfig,
    protected modalRef: ModalRef,
    private assignmentCollection: DispoAssignmentCollection,
    private validationService: ValidationService,
    private availabilityService: DispoAvailabilityCollection,
    private invitationService: DispoInvitationCollection,
    private translateService: TranslateService,
    @Inject('Flash') private flash,
  ) {
    super(config, modalRef);

    this.translateService
      .get(['errors.messages.validation_not_resolvable'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.actions = this.config.data.actions;
    this.confirmationRequired = this.config.data.confirmationRequired || false;
    this.confirmationMessage = this.config.data.confirmationMessage;
  }

  exclude(action) {
    this.clearErrorsFor(action.entry, action.entry_type);
    this.actions = this.actions.filter((a) => a.id !== action.id);
    if (!this.errorsPresent()) {
      this.commit();
    }
  }

  ignoreErrors(action) {
    action.errors = [];
    if (!this.errorsPresent()) {
      this.commit();
    }
  }

  resolve(error) {
    if (error.resolve) {
      this.loading = true;
      error
        .resolve()
        .pipe(first())
        .subscribe({
          next: () => {
            this.loading = false;
            this.clearError(error);
          },
        });
    }
  }

  abort() {
    this.modalRef.close([]);
  }

  commit() {
    this.actions.forEach((action) => {
      action.errors = [];
    });
    this.modalRef.close(this.actions);
  }

  errorsPresent(actions = this.actions) {
    return actions.filter((a) => a.errors.length !== 0).length !== 0;
  }

  criticalErrorsPresent(actions = this.actions) {
    return (
      actions.filter(
        (a) => a.errors.filter((e) => e.type === 'critical').length !== 0,
      ).length !== 0
    );
  }

  commitableActionsPresent(actions = this.actions) {
    return actions.filter((a) => a.type !== ActionType.noop).length !== 0;
  }

  deleteAssignment(assignment) {
    if (assignment.shallow) {
      const action = this.actions.find(
        (a) => a.entry_type === 'assignments' && a.entry.id === assignment.id,
      );
      this.exclude(action);
      this.clearErrorsFor(assignment, 'assignments');
    } else {
      this.loading = true;

      const action = <Action>{
        id: uuid(),
        entry: assignment,
        entry_type: EntryType.Assignment,
        type: ActionType.delete,
        errors: [],
        validations: (item, stack) =>
          this.assignmentCollection.remoteValidations(
            assignment,
            [],
            ActionType.delete,
          ),
      };

      this.validationService
        .runIfPossible([action])
        .pipe(
          catchError((error: unknown) => {
            this.flash.create(
              this.translations['errors.messages.validation_not_resolvable'],
              'error',
              6000,
            );

            return of(error);
          }),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe(() => {
          this.clearErrorsFor(assignment, 'assignments');
        });
    }
  }

  cancelInvitation(invitation) {
    this.loading = true;

    invitation = Object.assign({}, invitation);
    invitation.states = Object.assign({}, invitation.states);
    invitation.states[invitation.date] = 'cancelled';

    this.invitationService.update(invitation.id, invitation).subscribe({
      next: () => {
        this.loading = false;
        this.clearErrorsFor(invitation, 'invitations');
      },
    });
  }

  deleteAvailability(availability) {
    this.loading = true;

    const action = <Action>{
      id: uuid(),
      entry: availability,
      entry_type: EntryType.Availability,
      type: ActionType.delete,
      errors: [],
      validations: (item, stack) =>
        this.availabilityService.remoteValidations(
          availability,
          [],
          ActionType.delete,
        ),
    };

    this.validationService
      .runIfPossible([action])
      .pipe(
        catchError((error: unknown) => {
          this.flash.create(
            this.translations['errors.messages.validation_not_resolvable'],
            'error',
            6000,
          );

          return of(error);
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(() => {
        this.clearErrorsFor(availability, 'availabilities');
      });
  }

  clearError(error) {
    this.actions.forEach((action) => {
      action.errors = action.errors.filter((e) => e.id !== error.id);
    });

    if (!this.errorsPresent()) {
      this.commit();
    }
  }

  clearErrorsFor(conflicting_entry, conflicting_entry_type) {
    this.actions.forEach((action) => {
      action.errors = action.errors.filter(
        (error) =>
          error.conflicting_entry_type !== conflicting_entry_type ||
          error.conflicting_entry.id !== conflicting_entry.id,
      );
    });

    if (!this.errorsPresent()) {
      this.commit();
    }
  }

  trackById(index, action) {
    return action.id;
  }
}
