import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@timecount/ui';

import { WorkEntryBatchCollection } from '../work_entry_batch.collection';
import { TcCreateWorkEntryBatchComponent } from '../work-entries-batch/create/create.component';

@Injectable({
  providedIn: 'root',
})
export class WorkEntriesBatchService {
  private translation: { [key: string]: string } = {};
  constructor(
    private _modalService: ModalService,
    private _translateService: TranslateService,
    private workEntryBatchCollection: WorkEntryBatchCollection,
  ) {
    this._translateService.get(['aggregation_entry/work.batch.modal.title']);
  }

  openModal(projectId: number, aggregation_id: number) {
    this._modalService.open(TcCreateWorkEntryBatchComponent, {
      data: {
        config: { projectId, aggregation_id },
      },
      modalTitle: this.translation['aggregation_entry/work.batch.modal.title'],
    });
  }

  deleteBatch(targets: unknown[], projectId: number, aggregationId: number) {
    const ids = targets.map((target) => target[`id`]);
    this.workEntryBatchCollection
      .forProject(projectId, aggregationId)
      .delete(ids, targets);
  }
}
