import { ExclusionPolicy, Strategy, Type } from 'typeserializer';

import { Slot } from '../../../dispo/collections/task';

export const DAYS_VALUES = ['0', '1', '2', '3', '4', '5', '6', 'FA', 'FW'];

export class WorkEntryTemplate {
  size: number;
  venue_id?: number;

  offset: number;
  length: number;
  intermission_offset?: number;
  intermission_length?: number;
  description?: string;
}

@Strategy(ExclusionPolicy.NONE)
export class WorkEntryBatch {
  id: number;
  title: string;
  description?: string;

  template: WorkEntryTemplate;

  slots_config: Slot[];

  @Type(Object)
  days_matrix: any;

  @Type(Object)
  store?: any;
}
