<div class="modal-container">
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ 'dispo/schedule.attrs.range_begin' | translate }}</label>
            <input
              class="form-control"
              type="text"
              disabled="true"
              value="{{ config.range_begin | date: 'd. LLL yyyy (\'KW\' w)' }}"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ 'dispo/schedule.attrs.range_end' | translate }}</label>
            <input
              class="form-control"
              type="text"
              disabled="true"
              value="{{ config.range_end | date: 'd. LLL yyyy (\'KW\' w)' }}"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ 'dispo/schedule.attrs.size' | translate }}</label>
            <input
              class="form-control"
              type="text"
              disabled="true"
              value="{{ config.template.size }}"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>{{
              'dispo/schedule.attrs.starts_at_time' | translate
            }}</label>
            <input
              class="form-control"
              type="text"
              disabled="true"
              value="{{ config.starts_at | date: 'HH:mm' }}"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>{{ 'dispo/schedule.attrs.ends_at_time' | translate }}</label>
            <input
              class="form-control"
              type="text"
              disabled="true"
              value="{{ config.ends_at | date: 'HH:mm' }}"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3" *ngIf="config.intermission_starts_at">
          <div class="form-group">
            <label>{{
              'dispo/schedule.attrs.intermission_starts_at_time' | translate
            }}</label>
            <input
              class="form-control"
              type="text"
              disabled="true"
              value="{{ config.intermission_starts_at | date: 'HH:mm' }}"
            />
          </div>
        </div>
        <div class="col-md-3" *ngIf="config.intermission_ends_at">
          <div class="form-group">
            <label>{{
              'dispo/schedule.attrs.intermission_ends_at_time' | translate
            }}</label>
            <input
              class="form-control"
              type="text"
              disabled="true"
              value="{{ config.intermission_ends_at | date: 'HH:mm' }}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" nz-button nzType="primary" (click)="prev()">
      {{ 'back' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      (click)="save()"
    >
      {{ 'create' | translate }}
    </button>
  </div>
</div>
