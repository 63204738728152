import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-collection-select-widget',
  templateUrl: './collection-select-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './collection-select-widget.component.scss',
  ],
})
export class CollectionSelectWidgetComponent extends TcControlWidgetBaseDirective {}
