<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label>{{ 'employee.singular' | translate }}</label>
            <tc-hub-collection-select
              [collectionTypes]="['Employee']"
              [formGroup]="form"
              controlName="employee_id"
              searchable="true"
              bindLabel="name"
              bindValue="id"
            >
            </tc-hub-collection-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="assign()"
    >
      {{ 'assign' | translate }}
    </button>
  </div>
</form>
