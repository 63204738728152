import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateISO',
})
export class DateISOPipe extends DatePipe implements PipeTransform {
  /**
   * This pipe will always transform the date Object into an ISO String
   * keeping the current timezone.
   *
   * The `timezone` and `locale` parameters are ignored.
   *
   * If changing the timezone is required, use Angular's default `date` pipe
   */
  transform(
    value: Date | string | number,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: null | undefined,
    format?: string,
    timezone?: string,
    locale?: string,
  ): null;
  transform(
    value: Date | string | number | null | undefined,
    _format?: string,
    _timezone?: string,
    _locale?: string,
  ): string | null {
    let dateFormat: string;

    switch (_format) {
      case 'date':
        dateFormat = 'yyyy-MM-dd';
        break;
      case 'time':
        dateFormat = 'HH:mm';
        break;
      default:
        dateFormat = 'yyyy-MM-ddTHH:mm:ss.SSS';
    }

    /*
      In JavaScript, using `null` and `false` values to instantiate a new Date
      will return a valid epoch date object. So we need to set any falsy values
      to undefined in order for the DatePipe to not return a date on those cases
    */
    //TODO: find a solution for the DatePipe
    return super.transform(value || undefined, dateFormat);
  }
}
