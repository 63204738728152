<tc-form-custom-picker-base
  #colorPicker
  [overlayPositions]="overlayPositions"
  [origin]="origin"
>
  <nz-list nzSize="small">
    <nz-list-item class="color-group" *ngFor="let colorGroup of colors">
      <button
        nz-button
        nzType="text"
        class="color-item"
        *ngFor="let color of colorGroup"
        [style.background-color]="color"
        (click)="onModelChange(color)"
      >
        &nbsp;
      </button>
    </nz-list-item>
  </nz-list>
</tc-form-custom-picker-base>
