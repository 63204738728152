import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { eachDayOfInterval } from 'date-fns';

import { IntervalSummary } from '@timecount/core';
import { isValidInterval } from '@timecount/utils';

import { TcTimesSummaryService } from './times-summary.service';

@Directive()
export class TcTimesSummaryBaseDirective implements OnChanges {
  @Input() intervalList: Interval[];

  @HostBinding('class.is-tracked') @Input() isTracked: boolean;

  @Input() expanded: boolean;

  @HostBinding('class.is-compact') @Input() isCompact: boolean;
  @HostBinding('class.is-horizontal') @Input() isHorizontal: boolean;

  intervalSummary: IntervalSummary = {};

  endTimeOffset = 0;

  constructor(private summaryService: TcTimesSummaryService) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnChanges({ intervalList }: SimpleChanges): void {
    if (intervalList) {
      this.intervalSummary = this.summaryService.getSummary(this.intervalList, {
        allowMultiBreaks: true,
      });
      if (isValidInterval(this.intervalSummary.workInterval)) {
        this.endTimeOffset =
          eachDayOfInterval(this.intervalSummary.workInterval).length - 1;
      }
    }
  }
}
