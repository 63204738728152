import { Pipe, PipeTransform } from '@angular/core';

import { TcFeedbackRequest } from './feedback-request.model';
import { feedbackRequestsOnStatus } from './feedback-requests-helpers';
import { TcFeedbackRequestStatus } from './feedback-request-status';
import { TcFeedbackRequestType } from './feedback-request-type';

@Pipe({
  name: 'feedbackRequestsOnStatus',
})
export class TcFeedbackRequestsOnStatusPipe implements PipeTransform {
  transform(
    feedbackRequests: TcFeedbackRequest[],
    status: TcFeedbackRequestStatus | TcFeedbackRequestStatus[],
    types?: TcFeedbackRequestType | TcFeedbackRequestType[],
  ): TcFeedbackRequest[] {
    if (!Array.isArray(feedbackRequests)) {
      return feedbackRequests;
    }

    return feedbackRequestsOnStatus(feedbackRequests, status, types);
  }
}
