<div class="wrapper">
  <div class="filter-bar">
    <tc-hub-dispo-filter section="dispo.assignments"></tc-hub-dispo-filter>
  </div>

  <div class="panel">
    <div class="assignments">
      <div class="content">
        <nz-table
          nzVirtualScroll
          nzSize="middle"
          nzVirtualItemSize="40"
          nzVirtualMinBufferPx="1000"
          nzVirtualMaxBufferPx="2600"
          [nzWidthConfig]="widthConfig"
          [nzData]="items$ | async"
          [nzVirtualForTrackBy]="trackByIdAndType"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          [nzScroll]="{ x: '1600px', y: 'calc(100vh - 55px - 50px - 47px)' }"
        >
          <thead>
            <tr>
              <th>
                <label
                  nz-checkbox
                  [(nzChecked)]="root.checked"
                  [nzIndeterminate]="root.indeterminate"
                  (nzCheckedChange)="selectItem('Root', 0, root.checked)"
                ></label>
              </th>
              <th>{{ 'dispo/assignment.attrs.job_short' | translate }}</th>
              <th>{{ 'dispo/assignment.attrs.pos_short' | translate }}</th>
              <th>{{ 'dispo/assignment.attrs.date_short' | translate }}</th>
              <th>
                {{ 'dispo/assignment.attrs.starts_at_short' | translate }}
              </th>
              <th>{{ 'dispo/assignment.attrs.ends_at_short' | translate }}</th>
              <th>
                {{ 'dispo/assignment.attrs.breaks_number' | translate }}
              </th>
              <th>
                {{ 'dispo/assignment.attrs.work_duration' | translate }}
              </th>
              <th>
                {{ 'dispo/assignment.attrs.break_duration' | translate }}
              </th>
              <th>
                {{ 'dispo/assignment.attrs.total_duration' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-template nz-virtual-scroll let-item let-index="index">
              <ng-container *ngIf="item.type === 'Project'">
                <tc-hub-das-project [item]="item"> </tc-hub-das-project>
              </ng-container>
              <ng-container *ngIf="item.type === 'TimesheetGroup'">
                <tc-hub-das-timesheet-group [item]="item">
                </tc-hub-das-timesheet-group>
              </ng-container>
              <ng-container *ngIf="item.type === 'DispoPlan'">
                <tc-hub-das-plan [item]="item"> </tc-hub-das-plan>
              </ng-container>
              <ng-container *ngIf="item.type === 'DispoSchedule'">
                <tc-hub-das-schedule [item]="item"> </tc-hub-das-schedule>
              </ng-container>
              <ng-container *ngIf="item.type === 'DispoTask'">
                <tc-hub-das-task [item]="item"> </tc-hub-das-task>
              </ng-container>
              <ng-container *ngIf="item.type === 'DispoAssignment'">
                <tc-hub-das-assignment
                  [item]="item"
                  tcHubDispoMenuActive
                  [activeType]="item.type"
                  [activeId]="item.id"
                >
                </tc-hub-das-assignment>
              </ng-container>
              <ng-container *ngIf="item.type === 'Timesheet'">
                <tc-hub-das-timesheet [item]="item"> </tc-hub-das-timesheet>
              </ng-container>
            </ng-template>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
