import { NgModule } from '@angular/core';

import { TcIntervalListModule } from './interval-list';
import { TcIntervalSetModule } from './interval-set';
import { TcIntervalWithBreakModule } from './interval-with-break/interval-with-break.module';
import { TcIntervalModule } from './interval/interval.module';
import { TcEmailHeadersFieldsetComponent } from './email-headers/email-headers-fieldset.component';

@NgModule({
  imports: [
    TcIntervalListModule,
    TcIntervalSetModule,
    TcIntervalWithBreakModule,
    TcIntervalModule,
    TcEmailHeadersFieldsetComponent,
  ],
  exports: [
    TcIntervalListModule,
    TcIntervalSetModule,
    TcIntervalWithBreakModule,
    TcIntervalModule,
    TcEmailHeadersFieldsetComponent,
  ],
})
export class TcFieldSetsModule {}
