<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label>{{ 'pin.attrs.note' | translate }}</label>
            <textarea
              class="form-control"
              rows="5"
              name="note"
              formControlName="note"
              autosize
            ></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label>{{ 'pin.attrs.users' | translate }}</label>
            <tc-hub-collection-select
              [collectionTypes]="['User', 'AccountRole']"
              [formGroup]="form"
              controlName="resources"
              bindLabel="title"
              multiple="true"
              [searchable]="true"
            >
            </tc-hub-collection-select>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group">
            <tc-hub-option-select
              label="{{ 'pin.attrs.level' | translate }}"
              type="pin.level"
              [formGroup]="form"
              controlName="level"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group">
            <tc-hub-option-select
              label="{{ 'pin.attrs.state' | translate }}"
              type="pin.state"
              [formGroup]="form"
              controlName="state"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group">
            <tc-input-date-web
              label="{{ 'pin.attrs.visible_at' | translate }}"
              formControlName="visible_at"
            ></tc-input-date-web>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group">
            <tc-input-date-web
              label="{{ 'pin.attrs.expires_at' | translate }}"
              formControlName="expires_at"
            ></tc-input-date-web>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
