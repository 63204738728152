/**
 * Date/Time pickers
 */
export const dateTimePickerType = ['dateTime', 'date', 'time'] as const;
export type TcDateTimePickerType = (typeof dateTimePickerType)[number];

/**
 * All types of pickers
 */
export type TcFormPickerType = TcDateTimePickerType | 'color';
