<nz-table
  [nzData]="breaksList"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
  nzSize="small"
  nzVirtualItemSize="40"
  [nzPageSize]="breaksList?.length"
>
  <thead>
    <tr>
      <th class="th-popover" nzWidth="30px"></th>
      <th class="th-popover" nzWidth="120px">{{ 'from' | translate }}</th>
      <th class="th-popover" nzWidth="120px">{{ 'till' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let time of breaksList; let i = index">
      <td class="td-popover">{{ i + 1 }}.</td>
      <td class="td-popover">
        {{ time.start | date : (showDate ? 'short' : 'shortTime') }}
      </td>
      <td class="td-popover">
        {{ time.end | date : (showDate ? 'short' : 'shortTime') }}
      </td>
    </tr>
  </tbody>
</nz-table>
