import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { startOfDay } from 'date-fns';

import { TcFieldSetIntervalSetService, TcInputValidators } from '@timecount/ui';

import { DispoResourceService } from '../../dispo-resource.service';
import { SetCollection } from '../../../core/sets-collection';
import { DispoPlanCollection, Plan } from '../../collections/plan';
import { DispoTaskCollection, Task } from '../../collections/task';

@Component({
  selector: 'tc-hub-dt-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class DTCreateComponent implements OnInit {
  @Input() task: Task;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  templateForm: UntypedFormGroup;
  templateCollection: SetCollection;

  loading = false;

  dateLimit: Interval;

  private destroyed$ = new Subject<void>();

  constructor(
    private taskCollection: DispoTaskCollection,
    private planCollection: DispoPlanCollection,
    private dispoResourceService: DispoResourceService,
    private formBuilder: UntypedFormBuilder,
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
  ) {}

  ngOnInit() {
    this.buildForm();
    if (this.plan) {
      this._setDateLimit(this.plan);
      this._setDefaultVenue(this.plan.project_id);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save() {
    this.loading = true;

    const { times, ...restOfValues } = this.form.value;

    const formattedTimes =
      this.fieldSetIntervalSetService.formatValueToApi(times);

    const payload = Object.assign({}, this.task, restOfValues, formattedTimes);

    payload.store = Object.assign({}, this.task.store, restOfValues.store);

    this.taskCollection
      .create(payload)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(() => {
        this.signal.emit({ action: 'close' });
      });
  }

  private buildForm() {
    const timesValue = this.fieldSetIntervalSetService.parseFromApi(this.task);

    this.form = this.formBuilder.group({
      title: [this.task.title, [Validators.required]],
      date: [startOfDay(this.task.starts_at), [Validators.required]],
      times: [timesValue, [Validators.required]],
      job_id: [this.task.job_id, [Validators.required]],
      venue_id: [this.task.venue_id],
      plan_id: [this.task.plan_id, [Validators.required]],
      size: [
        this.task.size,
        [
          Validators.required,
          TcInputValidators.minMaxNumber(
            0,
            window.config.company.dispo_task_max_size,
          ),
        ],
      ],
      state: [this.task.state],
      store: [this.task.store],
    });

    this.form
      .get('plan_id')
      .valueChanges.pipe(
        takeUntil(this.destroyed$),
        switchMap((planId) => this.planCollection.get(planId)),
      )
      .subscribe((plan: Plan) => {
        this._setDefaultVenue(plan.project_id);
        this._setDateLimit(plan);
      });
  }

  private _setDateLimit({ starts_at, ends_at }: Plan): void {
    this.dateLimit = {
      start: starts_at,
      end: ends_at,
    };
  }

  private _setDefaultVenue(projectId: number) {
    this.dispoResourceService
      .venues('project', projectId)
      .pipe(take(1))
      .subscribe((venue_roles) => {
        this.form.get('venue_id').setValue(venue_roles[0]?.venue.id);
      });
  }
}
