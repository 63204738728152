<div class="tc-modal-body" #modalBody>
  <nz-table
    class="table"
    #logsTable
    nzSize="small"
    nzPaginationType="small"
    nzPageSize="20"
    [nzNoResult]="'' | translate"
    [nzLoading]="loading"
    [nzData]="logs$ | async"
  >
    <thead>
      <tr>
        <th>{{ 'trails.attrs.created_at' | translate }}</th>
        <th>{{ 'trails.attrs.event' | translate }}</th>
        <th>{{ 'trails.attrs.user' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of logsTable.data">
        <td>{{ data.date | date : 'short' }}</td>
        <td>{{ 'trails.events.' + data.event | translate }}</td>
        <td>
          <span *ngIf="data.user.name; let name;">{{ name }} - </span>
          {{ data.user.email }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
