import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-widget-boolean',
  templateUrl: './boolean-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './boolean-widget.component.scss',
  ],
})
export class BooleanWidgetComponent extends TcControlWidgetBaseDirective {}
