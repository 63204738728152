import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { slideMotion } from 'ng-zorro-antd/core/animation';

/**
 * This component implements the ng-zorro picker for non-available components.
 *
 * It is based on the `time-picker` implementation:
 *
 * @see {@link https://github.com/NG-ZORRO/ng-zorro-antd/blob/9fda3184881d6dec9ce837ddd115e426927f8013/components/time-picker/time-picker.component.ts|time-picker}
 */
@Component({
  selector: 'tc-form-custom-picker-base',
  templateUrl: './custom-picker-base.component.html',
  styleUrls: ['./custom-picker-base.component.scss'],
  animations: [slideMotion],
})
export class TcCustomPickerBaseComponent implements AfterViewInit {
  @HostBinding('class.ant-picker')
  isPicker = true;

  @Input()
  @HostBinding('class.ant-picker-disabled')
  isDisabled = false;

  @Input() isOpen = false;
  @Input() overlayPositions!: ConnectionPositionPair[];
  @Input() origin!: CdkOverlayOrigin;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.open();
  }

  open(): void {
    if (this.isDisabled || this.isOpen) {
      return;
    }
    this.isOpen = true;
  }

  close(): void {
    this.isOpen = false;
    this.cdr.markForCheck();
  }
}
