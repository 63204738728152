<td>
  <label
    nz-checkbox
    [(nzChecked)]="item.checked"
    [nzIndeterminate]="item.indeterminate"
  ></label>

  {{ item.resource?.name }}

  <div class="data-panel">
    <tc-hub-data-panel
      [data]="item"
      section="finance.entries.timesheet.resource"
    ></tc-hub-data-panel>
  </div>
  <span
    *ngIf="billingStateInfo[item.billing_state]?.icon"
    nz-icon
    [class]="item.billing_state"
    nz-tooltip
    nzTooltipTitle="{{
      'aggregation_entry.billing_state.' + item.billing_state | translate
    }}"
    ><i nz-icon [nzType]="'tc:' + billingStateInfo[item.billing_state].icon"></i
  ></span>
</td>
<td>
  {{ item.sku?.title }}
</td>
<td>{{ item.assignment?.position + 1 }}</td>
<td>
  <span [class]="timesheetSummary.workDuration | highlightDurationLabel">{{
    timesheetSummary.workDuration | duration
  }}</span>
</td>
<td>
  <span [class]="item.price | highlightZeroLabel">
    {{
      item.price / 100
        | currency : ('angular.NUMBER_FORMATS.CURRENCY_SYM' | translate)
    }}
  </span>
</td>
<ng-container *ngFor="let zoneGroup of zoneGroups">
  <td *ngIf="getZoneCostValue(item.zones, zoneGroup) as zoneCost">
    <ng-container *ngIf="zoneCost.surcharge_cost !== 0">
      {{
        zoneCost.surcharge_cost / 100
          | currency : ('angular.NUMBER_FORMATS.CURRENCY_SYM' | translate)
      }}
      ({{ (zoneCost.hours | number : '1.0-2') + 'h' }} @
      {{ (zoneCost.surcharge | number : '1.0-2') + '%' }})
    </ng-container>
  </td>
</ng-container>
<td>
  <span [class]="item.total | highlightZeroLabel">
    {{
      (item.gratis ? 0 : item.total) / 100
        | currency : ('angular.NUMBER_FORMATS.CURRENCY_SYM' | translate)
    }}
  </span>
</td>
<td>
  <strong>{{ item.invoice_token }} </strong>
  <span
    *ngIf="item.aggregation?.attachment?.releases?.count > 0"
    nz-icon
    nz-tooltip
    nzTooltipTitle="{{ 'aggregation_entry.shared' | translate }} {{
      item.aggregation?.attachment?.releases?.count
    }}"
    ><i nz-icon nzType="tc:share"></i
  ></span>
</td>
<td>
  {{ item.gratis ? ('aggregation_entry.attrs.gratis' | translate) : null }}
</td>
<td>
  {{ item.starts_at | date : 'd.MM.' }}
</td>
<td>
  {{ item.starts_at | date : 'HH:mm' }}
</td>
<td>
  {{ item.ends_at | date : 'HH:mm' }}
</td>
<td>
  <span>{{ timesheetSummary.breakPeriods }}</span>
  <span
    class="breaks-info"
    *ngIf="timesheetSummary.breakPeriods > 0"
    nz-popover
    [nzPopoverContent]="timesheetTimeframes"
  >
    <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  </span>
</td>
<ng-template #timesheetTimeframes>
  <tc-hub-breaks-list-table
    [timesList]="parsedTimesheetTimes"
  ></tc-hub-breaks-list-table>
</ng-template>

<td>{{ timesheetSummary.breakDuration | duration }}</td>
<td>
  <span [class]="timesheetSummary.workDuration | highlightDurationLabel">{{
    timesheetSummary.totalDuration | duration
  }}</span>
</td>
<td>
  <tc-hub-data-panel
    [data]="item"
    section="finance.entries.timesheet.status"
  ></tc-hub-data-panel>
</td>
