import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DispoMenuService } from '../menus/menu.service';

import { DispoAssignmentsService } from './dispo-assignments.service';
import { DASPlanComponent } from './plan/plan.component';

@Component({
  selector: 'tc-hub-dispo-assignments',
  templateUrl: './dispo-assignments.component.html',
  styleUrls: ['./dispo-assignments.component.scss'],
})
export class DispoAssignmentsComponent implements OnInit, OnDestroy {
  @ViewChildren('planComponent') planComponents: QueryList<DASPlanComponent>;

  plans$: Observable<any[]>;
  items$: Observable<any[]>;

  root = { checked: false, indeterminate: false };

  itemsChecked;
  itemsIntedeterminate;

  widthConfig = [
    '500px',
    '250px',
    '50px',
    '100px',
    '150px',
    '150px',
    '100px',
    '150px',
    '150px',
    '150px',
  ];

  private destroyed$ = new Subject<void>();

  constructor(
    private service: DispoAssignmentsService,
    private contextMenuService: DispoMenuService,
  ) {}

  @HostListener('contextmenu', ['$event'])
  contextMenu(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.service
      .root()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((root) => {
        this.root = root;
      });

    this.items$ = this.service.items();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackByIdAndType(index, item) {
    return `${item.type}|${item.id}`;
  }

  toggleItem(type, id) {
    this.service.toggleItem(type, id);
  }

  selectItem(type, id, state, event = new MouseEvent('click')) {
    this.service.selectItem(type, id, state, event);
  }
}
