<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-input-text-web
              label="{{ 'dispo/announcement.attrs.title' | translate }}"
              formControlName="title"
            ></tc-input-text-web>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-collection-select
              label="{{ 'dispo/announcement.attrs.plan' | translate }}"
              [collectionTypes]="['DispoPlan']"
              [formGroup]="form"
              controlName="plan_id"
              bindLabel="label"
              bindValue="id"
            >
            </tc-hub-collection-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-input-integer-web
              label="{{ 'dispo/announcement.attrs.size' | translate }}"
              formControlName="size"
            ></tc-input-integer-web>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <div class="checkbox lightswitch" style="margin-top: 32px">
              <label class="sr-only">{{
                'dispo/announcement.attrs.auto_size' | translate
              }}</label>
              <label>
                <input
                  type="checkbox"
                  formControlName="auto_size"
                  (change)="toggleSize()"
                />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label">{{
                  'dispo/announcement.attrs.auto_size' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-select
              label="{{ 'dispo/announcement.attrs.task_slots' | translate }}"
              [formGroup]="form"
              bindLabel="title"
              bindValue="id"
              controlName="task_slots"
              [items]="taskSlots"
              multiple="true"
            >
            </tc-hub-select>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-collection-select
              [collectionTypes]="['Venue']"
              label="{{ 'dispo/announcement.attrs.venue' | translate }}"
              [formGroup]="form"
              controlName="venue_id"
              bindLabel="title"
              bindValue="id"
              [searchable]="true"
            >
            </tc-hub-collection-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <tc-hub-option-select
              label="{{ 'dispo/announcement.attrs.state' | translate }}"
              type="dispo.announcement_state"
              [formGroup]="form"
              controlName="state"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <div class="checkbox lightswitch">
              <label class="sr-only">{{
                'dispo/announcement.attrs.push_notifications' | translate
              }}</label>
              <label>
                <input type="checkbox" formControlName="push_notifications" />
                <div class="lightswitch-indicator"></div>
                <span class="lightswitch-label">{{
                  'dispo/announcement.attrs.push_notifications' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <tc-hub-message-template-select
              label="{{ 'message/template.actions.select' | translate }}"
              [formGroup]="form"
              controlName="message_template_id"
              bindLabel="title"
              bindValue="id"
              resourceType="dispo_announcement"
              dataType="dispo_announcement"
              [mapping]="{ body: 'description' }"
            ></tc-hub-message-template-select>
          </div>
          <div class="form-group">
            <div class="form-controls">
              <label>{{
                'dispo/announcement.attrs.description' | translate
              }}</label>
              <div class="textarea-group">
                <div class="textarea-controls">
                  <tc-hub-placeholder
                    [formGroup]="form"
                    target="description"
                    type="message/template.placeholders.dispo_announcement"
                  ></tc-hub-placeholder>
                </div>
                <div class="textarea-form">
                  <textarea
                    class="form-control"
                    rows="5"
                    name="description"
                    formControlName="description"
                    autosize
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <tc-hub-custom-fields
        section="announcement.general"
        [formGroup]="form"
        formControlName="store"
      >
      </tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
