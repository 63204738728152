import { Injectable } from '@angular/core';

import { SetsCollection } from '../../core/sets-collection';

@Injectable({
  providedIn: 'root',
})
export class SmtpTargetsCollection extends SetsCollection {
  identifier = 'preferences/apis/smtp_endpoints/target';
  endpoint = 'api/preferences/apis/smtp_endpoints/targets';

  cache = 0;
}
