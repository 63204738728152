import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusBar } from '@capacitor/status-bar';
import { TranslateLoader } from '@ngx-translate/core';
import * as flatten from 'flat';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { TcMissingTranslationHandler } from './missing-translation-handler';
import { translationsMap } from './translations-map';

export interface ITranslationResource {
  prefix: string;
  suffix: string;
}

/**
 * Custom ngx-translate loader that handles loading multiple files from http
 * (based on the `ngx-translate-multi-http-loader`) along with local files.
 *
 * @see {@link https://github.com/denniske/ngx-translate-multi-http-loader|ngx-translate-multi-http-loader}
 */
@Injectable({ providedIn: 'root' })
export class TcTranslateLoader implements TranslateLoader {
  isFromFactory = false;

  private _remoteResources: ITranslationResource[] = [
    { prefix: '/api/preferences/translations?locale=', suffix: '' },
  ];

  constructor(private http: HttpClient) {}

  public getTranslation(langCode: string): Observable<object> {
    const lang = langCode.split('-').shift();

    return from(this._isDevice()).pipe(
      map((isDevice) =>
        // When running from a device, the user has to input the company address
        // before we can intercept the requests to inject it. As the loader
        // factory is called statically, before the user has logged in, we avoid
        // adding the remote resources. In that case, the getTranslation()
        // method should be called after the company address is confirmed.
        this.isFromFactory && isDevice
          ? []
          : this._remoteResources.map((resource) =>
              this._getHttpRequest(resource, lang),
            ),
      ),
      switchMap((requests) =>
        forkJoin([of(translationsMap[lang]), ...requests]),
      ),
      map((response) =>
        response.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      ),
    );
  }

  // TODO: Move this to a helper
  private async _isDevice(): Promise<boolean> {
    let isDevice = true;

    // We're using try and catch with the StatusBar plugin as it will throw when
    // not running in a real device. The reason is that the platform.ready()
    // method does not work reliably.
    try {
      await StatusBar.getInfo();
    } catch (error) {
      isDevice = false;
    }

    return isDevice;
  }

  private _getHttpRequest(
    resource: ITranslationResource,
    lang: string,
  ): Observable<object> {
    const path = resource.prefix + lang + resource.suffix;

    return this.http.get(path).pipe(
      map(this._getMappedHttpResponse),
      tap((translations) => {
        localStorage.setItem(
          `${TcMissingTranslationHandler.storagePrefix}-${lang}`,
          JSON.stringify(flatten(translations)),
        );
      }),
      catchError((res) => {
        console.error(
          'Something went wrong for the following translation path:',
          path,
        );
        console.error(res.message);
        return of({});
      }),
    );
  }

  private _getMappedHttpResponse(response): object {
    const translations = { ...response.data };

    const activeRecords = translations?.activerecord?.attributes || {};

    Object.keys(activeRecords).forEach((record) => {
      translations[record] ??= {};

      translations[record]['attrs'] = activeRecords[record];
    });

    return translations;
  }
}

// AoT requires an exported function for factories
export function tcLoaderFactory(http: HttpClient) {
  const loader = new TcTranslateLoader(http);

  loader.isFromFactory = true;

  return loader;
}
