import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  first,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';

import { overlaps } from '../../core/helpers';
import { DispoFilterService } from '../../shared/filter/filter.service';
import { TimesheetCollection } from '../../timesheets';
import {
  DispoRangeDataStructure,
  DispoRangeDataStructureFactory,
} from '../datastructures/range.service';
import { DispoFocusService } from '../dispo-focus.service';
import { DispoMenuService } from '../menus/menu.service';
import { TimesheetDS } from '../datastructures/timesheet-ds.model';
import { AssignmentDS } from '../datastructures/assignment-ds.model';

@Injectable({
  providedIn: 'root',
})
export class DispoAssignmentsService {
  private dataStructureService: DispoRangeDataStructure;
  private visibleWorkSheets$;
  private structure$;
  private items$;
  private updateSelectedItems$ = new Subject<(TimesheetDS | AssignmentDS)[]>();
  private root$ = new ReplaySubject(1);

  private hiddenItems = {
    Project: {},
    DispoPlan: {},
    DispoSchedule: {},
    DispoTask: {},
    TimesheetGroup: {},
  };
  private hiddenItems$ = new ReplaySubject(1);

  private selectedItems = {
    DispoAssignment: {},
    Timesheet: {},
  };
  private selectedItems$ = new ReplaySubject(1);

  constructor(
    private dispoRangeDataStructureFactory: DispoRangeDataStructureFactory,
    private filterService: DispoFilterService,
    private dispoFocus: DispoFocusService,
    private contextMenuService: DispoMenuService,
    private timesheetCollection: TimesheetCollection,
  ) {
    this.dataStructureService = this.dispoRangeDataStructureFactory.all();

    this.hiddenItems$.next(this.hiddenItems);
    this.selectedItems$.next(this.selectedItems);

    this.updateSelectedItems$
      .pipe(
        debounceTime(500),
        filter(() => this.root$.observed),
      )
      .subscribe((visibleWorkSheets) => {
        const lookup = visibleWorkSheets.reduce((acc, ws) => {
          acc[`${ws.type}${ws.id}`] = ws;
          return acc;
        }, {});

        Object.keys(this.selectedItems.DispoAssignment).forEach((k) => {
          if (!lookup[`DispoAssignment${k}`]) {
            this.selectedItems.DispoAssignment[k] = false;
          }
        });

        Object.keys(this.selectedItems.Timesheet).forEach((k) => {
          if (!lookup[`Timesheet${k}`]) {
            this.selectedItems.Timesheet[k] = false;
          }
        });

        this.selectItem('null', 0, false, new MouseEvent('click'));
      });
  }

  public toggleItem(type, id) {
    this.hiddenItems[type][id] = !this.hiddenItems[type][id];
    this.hiddenItems$.next(this.hiddenItems);
  }

  public selectItem(type, id, state, event) {
    this.visibleWorkSheets()
      .pipe(first())
      .subscribe((worksheets) => {
        const otherContexts = [];

        if (type === 'Root') {
          worksheets.forEach((ws) => {
            this.selectedItems[ws.type][ws.id] = state;
          });
        }

        if (type === 'Project') {
          worksheets
            .filter((ws) => ws.project_id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });
        }

        if (type === 'DispoPlan') {
          worksheets
            .filter((ws) => ws.type === 'DispoAssignment' && ws.plan_id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });

          otherContexts.push({ item_type: type, item: [{ id: id }] });
        }

        if (type === 'DispoSchedule') {
          worksheets
            .filter(
              (ws) => ws.type === 'DispoAssignment' && ws.schedule_id === id,
            )
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });

          otherContexts.push({ item_type: type, item: [{ id: id }] });
        }

        if (type === 'DispoTask') {
          worksheets
            .filter((ws) => ws.type === 'DispoAssignment' && ws.task_id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });

          otherContexts.push({ item_type: type, item: [{ id: id }] });
        }

        if (type === 'TimesheetGroup') {
          worksheets
            .filter((ws) => ws.type === 'Timesheet' && ws.project_id === id)
            .forEach((ws) => {
              this.selectedItems[ws.type][ws.id] = state;
            });
        }

        if (type === 'DispoAssignment' || type === 'Timesheet') {
          this.selectedItems[type][id] = state;
          this.selectedItems$.next(this.selectedItems);
        }

        const selectedAssignments = Object.keys(
          this.selectedItems.DispoAssignment,
        )
          .filter((key) => this.selectedItems.DispoAssignment[key])
          .map((id) => {
            return { id: parseInt(id) };
          });
        const selectedOrphanedTimesheets = Object.keys(
          this.selectedItems.Timesheet,
        )
          .filter((key) => this.selectedItems.Timesheet[key])
          .map((id) => {
            return { id: parseInt(id) };
          });

        this.contextMenuService.onViewClick(event, 'dispo.assignments', [
          { item_type: 'DispoAssignment', item: selectedAssignments },
          { item_type: 'Timesheet', item: [...selectedOrphanedTimesheets] },
          ...otherContexts,
        ]);

        this.selectedItems$.next(this.selectedItems);
      });
  }

  public root(): Observable<any> {
    return this.root$.asObservable();
  }

  public items(): Observable<any[]> {
    return (this.items$ ||= combineLatest(
      this.structure(),
      this.filterService.getSort('dispo.assignments', 'projects'),
      this.filterService.getSort('dispo.assignments', 'plans'),
      this.filterService.getSort('dispo.assignments', 'schedules'),
      this.filterService.getSort('dispo.assignments', 'tasks'),
      this.filterService.getSort('dispo.assignments', 'assignments'),
      this.filterService.getSort('dispo.assignments', 'timesheets'),
      this.hiddenItems$,
      this.selectedItems$,
    ).pipe(
      debounceTime(100),
      map(
        ([
          structure,
          projectSort,
          planSort,
          scheduleSort,
          taskSort,
          assignmentSort,
          timesheetSort,
          hiddenItems,
          selectedItems,
        ]) => {
          const rootItem = { checked: false, indeterminate: false };
          let rootItemAllEnabled = true;
          let rootItemAllDisabled = true;

          const items = [];

          Object.values(structure)
            .sort(projectSort)
            .forEach((projectGroupItem: any) => {
              items.push(projectGroupItem);

              let projectGroupItemAllEnabled = true;
              let projectGroupItemAllDisabled = true;
              const showProjectLeafs =
                !hiddenItems['Project'][projectGroupItem.id];

              // Project -> Plans
              Object.values(projectGroupItem.plans)
                .sort(planSort)
                .forEach((planGroupItem: any) => {
                  if (showProjectLeafs) {
                    items.push(planGroupItem);
                  }

                  let planGroupItemAllEnabled = true;
                  let planGroupItemAllDisabled = true;
                  const showPlanLeafs =
                    showProjectLeafs &&
                    !hiddenItems['DispoPlan'][planGroupItem.id];

                  // Project -> Plan -> Tasks
                  Object.values(planGroupItem.tasks)
                    .sort(taskSort)
                    .forEach((taskGroupItem: any) => {
                      if (showPlanLeafs) {
                        items.push(taskGroupItem);
                      }

                      let taskGroupItemAllEnabled = true;
                      let taskGroupItemAllDisabled = true;
                      const showTaskLeafs =
                        showPlanLeafs &&
                        !hiddenItems['DispoTask'][taskGroupItem.id];

                      // Project -> Plan -> Task -> Assignment
                      Object.values(taskGroupItem.assignments)
                        .sort(assignmentSort)
                        .forEach((assignmentGroupItem: any) => {
                          if (
                            selectedItems['DispoAssignment'][
                              assignmentGroupItem.id
                            ]
                          ) {
                            assignmentGroupItem.checked = true;

                            rootItemAllDisabled = false;
                            projectGroupItemAllDisabled = false;
                            planGroupItemAllDisabled = false;
                            taskGroupItemAllDisabled = false;
                          } else {
                            assignmentGroupItem.checked = false;

                            rootItemAllEnabled = false;
                            projectGroupItemAllEnabled = false;
                            planGroupItemAllEnabled = false;
                            taskGroupItemAllEnabled = false;
                          }

                          if (showTaskLeafs) {
                            items.push(assignmentGroupItem);
                          }
                        });

                      taskGroupItem.expanded = showTaskLeafs;
                      taskGroupItem.checked = taskGroupItemAllEnabled;
                      taskGroupItem.indeterminate =
                        !taskGroupItemAllEnabled && !taskGroupItemAllDisabled;
                    });

                  // Project -> Plan -> Schedules
                  Object.values(planGroupItem.schedules)
                    .sort(scheduleSort)
                    .forEach((scheduleGroupItem: any) => {
                      if (showPlanLeafs) {
                        items.push(scheduleGroupItem);
                      }

                      let scheduleGroupItemAllEnabled = true;
                      let scheduleGroupItemAllDisabled = true;
                      const showScheduleLeafs =
                        showPlanLeafs &&
                        !hiddenItems['DispoSchedule'][scheduleGroupItem.id];

                      // Project -> Plan -> Schedule -> Assignments
                      Object.values(scheduleGroupItem.assignments)
                        .sort(assignmentSort)
                        .forEach((assignmentGroupItem: any) => {
                          if (
                            selectedItems['DispoAssignment'][
                              assignmentGroupItem.id
                            ]
                          ) {
                            assignmentGroupItem.checked = true;

                            rootItemAllDisabled = false;
                            projectGroupItemAllDisabled = false;
                            planGroupItemAllDisabled = false;
                            scheduleGroupItemAllDisabled = false;
                          } else {
                            assignmentGroupItem.checked = false;

                            rootItemAllEnabled = false;
                            projectGroupItemAllEnabled = false;
                            planGroupItemAllEnabled = false;
                            scheduleGroupItemAllEnabled = false;
                          }

                          if (showScheduleLeafs) {
                            items.push(assignmentGroupItem);
                          }
                        });

                      scheduleGroupItem.expanded = showScheduleLeafs;
                      scheduleGroupItem.checked = scheduleGroupItemAllEnabled;
                      scheduleGroupItem.indeterminate =
                        !scheduleGroupItemAllEnabled &&
                        !scheduleGroupItemAllDisabled;
                    });

                  planGroupItem.expanded = showPlanLeafs;
                  planGroupItem.checked = planGroupItemAllEnabled;
                  planGroupItem.indeterminate =
                    !planGroupItemAllEnabled && !planGroupItemAllDisabled;
                });

              // Project -> Timesheets
              const timesheets = Object.values(projectGroupItem.timesheets);
              if (timesheets.length > 0) {
                const timesheetGroupItem = {
                  id: projectGroupItem.id,
                  type: 'TimesheetGroup',
                  checked: false,
                  indeterminate: false,
                  expanded: true,
                };

                let timesheetGroupItemAllEnabled = true;
                let timesheetGroupItemAllDisabled = true;
                const showTimesheetGroupLeafs =
                  showProjectLeafs &&
                  !hiddenItems['TimesheetGroup'][projectGroupItem.id];

                if (showProjectLeafs) {
                  items.push(timesheetGroupItem);
                }

                timesheets.sort(timesheetSort).forEach((timesheetItem: any) => {
                  if (selectedItems['Timesheet'][timesheetItem.id]) {
                    timesheetItem.checked = true;

                    rootItemAllDisabled = false;
                    timesheetGroupItemAllDisabled = false;
                    projectGroupItemAllDisabled = false;
                  } else {
                    timesheetItem.checked = false;

                    rootItemAllEnabled = false;
                    timesheetGroupItemAllEnabled = false;
                    projectGroupItemAllEnabled = false;
                  }

                  if (showTimesheetGroupLeafs) {
                    items.push(timesheetItem);
                  }
                });

                timesheetGroupItem.expanded = showTimesheetGroupLeafs;
                timesheetGroupItem.checked = timesheetGroupItemAllEnabled;
                timesheetGroupItem.indeterminate =
                  !timesheetGroupItemAllEnabled &&
                  !timesheetGroupItemAllDisabled;
              }

              projectGroupItem.expanded = showProjectLeafs;
              projectGroupItem.checked = projectGroupItemAllEnabled;
              projectGroupItem.indeterminate =
                !projectGroupItemAllEnabled && !projectGroupItemAllDisabled;
            });

          rootItem.checked = rootItemAllEnabled;
          rootItem.indeterminate = !rootItemAllEnabled && !rootItemAllDisabled;
          this.root$.next(rootItem);

          return items;
        },
      ),
    ));
  }

  private structure() {
    return (this.structure$ ||= this.visibleWorkSheets().pipe(
      debounceTime(500),
      map((workSheets) => {
        const structure = {};

        workSheets.forEach((worksheet) => {
          let leaf = undefined;

          if (worksheet.type === 'DispoAssignment') {
            if (!worksheet.project) {
              return;
            }
            if (!structure[worksheet.project.id]) {
              structure[worksheet.project.id] = Object.assign(
                { type: 'Project', plans: {}, timesheets: {} },
                worksheet.project,
              );
            }
            leaf = structure[worksheet.project.id]['plans'];

            if (!worksheet.plan) {
              return;
            }
            if (!leaf[worksheet.plan.id]) {
              leaf[worksheet.plan.id] = Object.assign(
                { type: 'DispoPlan', tasks: {}, schedules: {} },
                worksheet.plan,
              );
            }

            if (worksheet.schedule_id) {
              leaf = leaf[worksheet.task.plan_id]['schedules'];

              if (!worksheet.schedule) {
                return;
              }
              if (!leaf[worksheet.schedule_id]) {
                leaf[worksheet.schedule_id] = Object.assign(
                  { type: 'DispoSchedule', assignments: {} },
                  worksheet.schedule,
                );
              }

              leaf[worksheet.schedule_id]['assignments'][worksheet.id] =
                worksheet;
            } else {
              leaf = leaf[worksheet.task.plan_id]['tasks'];

              if (!worksheet.task) {
                return;
              }
              if (!leaf[worksheet.task_id]) {
                leaf[worksheet.task_id] = Object.assign(
                  { type: 'DispoTask', assignments: {} },
                  worksheet.task,
                );
              }

              leaf[worksheet.task_id]['assignments'][worksheet.id] = worksheet;
            }
          }

          if (worksheet.type === 'Timesheet') {
            if (!worksheet.project) {
              return;
            }
            if (!structure[worksheet.project_id]) {
              structure[worksheet.project_id] = Object.assign(
                { type: 'Project', plans: {}, timesheets: {} },
                worksheet.project,
              );
            }
            leaf = structure[worksheet.project_id]['timesheets'];

            leaf[worksheet.id] = worksheet;
          }
        });

        return structure;
      }),
      shareReplay(1),
    ));
  }

  private visibleWorkSheets(): Observable<(TimesheetDS | AssignmentDS)[]> {
    return (this.visibleWorkSheets$ ||= combineLatest(
      this.workSheetsDS(),
      this.assignmentQuery(),
      this.dispoFocus.range(),
    ).pipe(
      map(([workSheetsDS, query, [begin, end]]) => {
        return workSheetsDS
          .filter((s) => overlaps(s.marker[0], begin, end))
          .filter(query);
      }),
      tap((visibleWorkSheets) => {
        this.updateSelectedItems$.next(visibleWorkSheets);
      }),
      shareReplay(1),
    ));
  }

  private assignmentQuery() {
    return this.filterService.getQuery('dispo.assignments');
  }

  private workSheetsDS(): Observable<(TimesheetDS | AssignmentDS)[]> {
    return this.dataStructureService.workSheets();
  }
}
