import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { TcModalModule } from './modal.module';
import { ModalComponent } from './modal.component';
import { ModalInjector } from './modal-injector';
import { ModalConfig } from './modal-config';
import { ModalRef } from './modal-ref';

@Injectable({
  providedIn: TcModalModule,
})
export class ModalService {
  modalComponentRefs: Map<string, ComponentRef<ModalComponent>> = new Map();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public open(componentType: Type<any>, config: ModalConfig) {
    const modalId = uuid();
    const modalRef = this.appendDialogComponentToBody(config, modalId);

    this.modalComponentRefs[modalId].instance.childComponentType =
      componentType;
    return modalRef;
  }

  private appendDialogComponentToBody(config: ModalConfig, modalId) {
    const map = new WeakMap();
    map.set(ModalConfig, config);

    const modalRef = new ModalRef();
    map.set(ModalRef, modalRef);

    const resize = modalRef.tcResize.pipe(debounceTime(30)).subscribe(() => {
      this.modalComponentRefs[modalId].instance.resize();
    });

    const setConfig = modalRef.tcSetConfig.subscribe((newConfig) => {
      this.modalComponentRefs[modalId].instance.setConfig(newConfig);
    });

    const sub = modalRef.tcClose.subscribe(() => {
      this.removeDialogComponentFromBody(modalId);
      resize.unsubscribe();
      sub.unsubscribe();
      setConfig.unsubscribe();
    });

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    const componentRef = componentFactory.create(
      new ModalInjector(this.injector, map, modalRef),
    );

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.modalComponentRefs[modalId] = componentRef;

    return modalRef;
  }

  private removeDialogComponentFromBody(modalId) {
    this.appRef.detachView(this.modalComponentRefs[modalId].hostView);
    this.modalComponentRefs[modalId].destroy();
  }
}
