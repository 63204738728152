import { Deserializer, ExclusionPolicy, Strategy } from 'typeserializer';

import { parseDate } from '@timecount/utils';

import { EntryBillingState } from '../entry-billing-state.model';

@Strategy(ExclusionPolicy.NONE)
export class ServiceEntry {
  id: number;
  identifier = 'service_entry';

  project_id: number;
  aggregation_id?: number;
  aggregation_type_id: number;

  main: boolean;
  in_main: boolean;
  locked: boolean;
  billing_state: EntryBillingState;

  marker: [number, number];

  title: string;
  count: number;

  price: number;

  invoice_id?: number;
  invoice_token?: string;

  @Deserializer((m: string): Date => parseDate(m))
  starts_at: Date;
  @Deserializer((m: string): Date => parseDate(m))
  ends_at: Date;

  description?: string;

  store?: Record<string, unknown>;

  unit?: string;

  vat_id?: number;

  source_id?: number;
  // NOTE: When an entry is generated (by importing) from another ServiceEntry this field is set to ServiceEntry
  // When an entry is created manually it is not set
  source_type?: 'ServiceEntry';

  sku_id?: number;
  sku_type?: string;

  venue_id?: number;
}
