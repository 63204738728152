import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { isValidDate } from './date-helpers';

@Pipe({
  name: 'date',
  pure: false, // required to update the value when changing the language
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: unknown, format = 'mediumDate'): unknown {
    const datePipe = new DatePipe(this.translateService.currentLang);
    return isValidDate(value)
      ? datePipe.transform(value as Date, format)
      : value;
  }
}
