import { Component, Input } from '@angular/core';

import { EmployeeInfoColumnsService } from '../../../../employees/info-columns.service';

@Component({
  selector: 'tc-hub-employee-info-button',
  templateUrl: './info-btn.component.html',
  styleUrls: ['./info-btn.component.css'],
})
export class EmployeeInfoBtnComponent {
  @Input() employee: any;

  @Input() size: 'large' | 'small' | 'default' = 'small';

  public visible = false;

  public columnDefs$ = this.columnsService.columns$;

  constructor(private columnsService: EmployeeInfoColumnsService) {}
}
