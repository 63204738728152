<div class="tc-modal-body" #modalBody>
  <nz-tabset
    nzAnimated="false"
    nzSize="small"
    [nzSelectedIndex]="selectedIndex"
    *ngIf="!single"
  >
    <nz-tab
      nzTitle="{{ 'dispo/availability.tab_type.range' | translate }}"
      *tcHubHide="'views.dispo.availability.general'"
    >
      <tc-hub-dav-range
        class="modal-container"
        [availability]="availability"
        (signal)="onSignal($event)"
      ></tc-hub-dav-range>
    </nz-tab>

    <nz-tab
      nzTitle="{{ 'dispo/availability.tab_type.interval' | translate }}"
      *tcHubHide="'views.dispo.availability.general'"
    >
      <tc-hub-dav-interval
        class="modal-container"
        [availability]="availability"
        (signal)="onSignal($event)"
      ></tc-hub-dav-interval>
    </nz-tab>
  </nz-tabset>
</div>

<div class="tc-modal-footer" #modalFooter>
  <!--  -->
</div>
