import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { format } from 'date-fns';

import { TcConfigService } from '@timecount/core';

import { CollectionService } from '../../../core/collection.service';
import { DispoScheduleCollection, Schedule } from '../../collections/schedule';
import { Plan } from '../../collections/plan';

@Component({
  selector: 'tc-hub-ds-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css'],
})
export class DSPublicComponent implements OnInit {
  @Input() schedule: Schedule;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  attachmentRecipientTitle: string;

  allowOnsiteCheckin = this.configService.config.company.allowOnsiteCheckin;
  allowAppAttendance = this.configService.config.company.allowAppAttendance;

  constructor(
    private scheduleService: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
    private collectionService: CollectionService,
    private translate: TranslateService,
    private configService: TcConfigService,
  ) {}

  ngOnInit() {
    this.translate
      .get('dispo/task.attachment_releases.recipient_title', {
        event_title: this.plan ? this.plan.title : '',
        schedule_title: this.schedule.title,
        date: format(this.schedule.starts_at, 'dd.MM.yyyy'),
      })
      .subscribe((recipientTitle) => {
        this.attachmentRecipientTitle = recipientTitle;
      });

    this.form = this.buildForm(this.schedule);
  }

  ngAfterViewInit() {
    from(
      this.collectionService['MessageTemplate']
        .byType('dispo_schedule', 'dispo_schedule')
        .all(),
    ).subscribe((templates: any[]) => {
      const messageTemplate = templates.find((template) => {
        return template.body === this.schedule.description;
      });

      if (messageTemplate) {
        this.form.get('message_template_id').setValue(messageTemplate.id);
      }
    });
  }

  validate() {
    // validate intermission in range
  }

  save(...sync) {
    this.loading = true;
    const { onsite_checkin_enabled, attendance_enabled, ...rest } =
      this.form.value;
    const formValues = Object.assign({}, this.schedule, rest);

    formValues.template.response_requirements = {
      onsite_checkin: onsite_checkin_enabled,
      attend: attendance_enabled,
    };

    const payload = {
      ...this.schedule,
      template: {
        ...this.schedule.template,
        ...formValues,
      },
      sync: sync,
    };

    this.loading = true;
    this.scheduleService.update(this.schedule.id, payload).subscribe({
      next: (schedule) => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
        this.form = this.buildForm(schedule);
        // this.signal.emit({ action: 'reload', items: { schedule: schedule }});
      },
    });
  }

  private buildForm(schedule) {
    return this.formBuilder.group({
      tracking_enabled: [
        {
          value: schedule.template.tracking_enabled,
          disabled: schedule.template.state !== 'open',
        },
      ],
      onsite_checkin_enabled: [
        schedule.template.response_requirements.onsite_checkin,
      ],
      attendance_enabled: [
        schedule.template.response_requirements?.attend ?? null,
      ],
      push_notifications: [schedule.template.push_notifications],
      description: [schedule.template.description],
      state: [schedule.template.state, Validators.required],
      message_template_id: [null],
    });
  }
}
