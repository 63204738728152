import { Injectable } from '@angular/core';
import { ExclusionPolicy, Strategy } from 'typeserializer';

import { SetsCollection } from '../sets-collection';

@Strategy(ExclusionPolicy.NONE)
export class Supplement {
  id: number;

  title: string;
  display_key: string;
  visibility: string;
  data_type: string;
}
@Injectable({
  providedIn: 'root',
})
export class SupplementCollection extends SetsCollection {
  identifier = 'preferences/supplement';
  endpoint = '/api/preferences/supplements';
  // loadOnInit = true;

  type = Supplement;

  cache = 60 * 60 * 24;
}
