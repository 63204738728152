import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { parseDate } from '@timecount/utils';

import { DispoFilterService } from '../../shared/filter/filter.service';
import { DispoSelectorService } from '../selector/selector.service';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { AnnouncementService } from '../actions/announcement.service';
import { MessageService } from '../actions/message.service';
import { CurrentUserService } from '../../core/current-user.service';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../collections/announcement';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuAnnouncement {
  private announcementTemplates: any[] = [];
  private announcementMessageTemplates: any[] = [];

  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private filterService: DispoFilterService,
    private selectorService: DispoSelectorService,
    private announcementActions: AnnouncementService,
    private messageActions: MessageService,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private announcementCollection: DispoAnnouncementCollection,
    private currentUser: CurrentUserService,
  ) {
    this.translateService
      .get([
        'dispo/announcement.actions.edit',
        'dispo/announcement.actions.delete',
        'dispo/announcement.actions.create',
        'dispo/announcement.actions.detach_tasks',
        'dispo/announcement.actions.increase_size',
        'dispo/announcement.actions.decrease_size',
        'dispo/announcement.actions.invite',
        'dispo/announcement.actions.message',
        'dispo/announcement.menu.message',
        'dispo/announcement.menu.announcement',
        'dispo/announcement.menu.template',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });

    this.resourceTemplateCollection
      .setForResource('dispo.announcements')
      .all()
      .subscribe((templates) => {
        this.announcementTemplates = templates;
      });

    this.resourceTemplateCollection
      .setForResource('dispo.messages.announcement')
      .all()
      .subscribe((templates) => {
        this.announcementMessageTemplates = templates;
      });
  }

  items(currAnnouncement, date?: Date): Observable<any[]> {
    return this.announcementCollection.observeItem(currAnnouncement).pipe(
      map((announcement: Announcement) => {
        if (!announcement) {
          return [];
        }

        let announcementActions = [];

        announcementActions = [
          {
            label: this.translations['dispo/announcement.actions.edit'],
            icon: 'edit',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.announcement.general',
            ),
            command: (event: any) => {
              this.announcementActions.edit(announcement);
            },
          },
          {
            label: this.translations['dispo/announcement.actions.delete'],
            icon: 'delete',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.announcement.delete',
            ),
            command: (event: any) => {
              this.announcementActions.delete(announcement);
            },
          },
          {
            label: this.translations['dispo/announcement.actions.invite'],
            icon: 'usergroup-add',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.announcement.invite',
            ),
            command: (event) => {
              this.announcementActions.invite(announcement);
            },
          },
        ];

        announcementActions.push({
          label: this.translations['dispo/announcement.actions.message'],
          icon: 'comment',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.announcement.message',
          ),
          command: (event) => {
            const defaultDate = parseDate(Object.keys(announcement.sizes)[0]);
            this.messageActions.announcementDefault(announcement, defaultDate);
          },
        });

        const announcementMessageTemplates: any[] =
          this.announcementMessageTemplates
            .filter((t) => !t.default)
            .map((template) => {
              return {
                label: template.title,
                command: (event: any) => {
                  const templ = Object.assign({}, template.template);
                  const defaultDate = parseDate(
                    Object.keys(announcement.sizes)[0],
                  );
                  this.messageActions.announcement(
                    announcement,
                    defaultDate,
                    templ,
                  );
                },
              };
            });

        announcementActions.push({
          label: this.translations['dispo/announcement.menu.message'],
          icon: 'copy',
          visible:
            announcementMessageTemplates.length > 0 &&
            this.currentUser.hasAccessToSection(
              'views.dispo.announcement.message',
            ),
          items: announcementMessageTemplates,
        });

        const items = [
          ...(announcementActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label:
                    this.translations['dispo/announcement.menu.announcement'],
                },
                ...announcementActions,
              ]
            : []),
        ];

        return items;
      }),
    );
  }
}
