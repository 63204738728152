import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

import {
  Announcement,
  DispoAnnouncementCollection,
} from '../collections/announcement';
import { SlotPos, Task } from '../collections/task';
import { Schedule } from '../collections/schedule';

@Component({
  selector: 'tc-hub-dispo-announcement',
  templateUrl: './dispo-announcement.component.html',
  styleUrls: ['./dispo-announcement.component.css'],
})
export class DispoAnnouncementComponent
  extends BaseModalComponent
  implements OnInit
{
  schedule: Schedule;
  announcement: Announcement;
  tasks: Task[];
  slots: SlotPos;

  tabs = {
    general: 0,
    invite: 1,
    invitations: 2,
    task: 3,
  };

  constructor(
    protected config: ModalConfig,
    protected modalRef: ModalRef,
    protected announcementCollection: DispoAnnouncementCollection,
  ) {
    super(config, modalRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.announcement = this.config.data.announcement;
    this.schedule = this.config.data.schedule;
    this.tasks = this.config.data.tasks || [];
    this.slots = this.config.data.slots || [];

    if (this.announcement && this.announcement.id) {
      this.announcementCollection
        .observeItem(this.announcement)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (announcement) => {
            if (announcement) {
              this.announcement = announcement;
            }
          },
        });
    }
  }
}
