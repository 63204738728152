import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RxState } from '@rx-angular/state';
import { add, isAfter, isBefore, isSameMinute } from 'date-fns';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { isEqual } from 'lodash';

import { TcConfigService } from '@timecount/core';
import {
  durationInMinutes,
  isIntervalShorterThan24Hours,
  isValidDate,
  isValidInterval,
} from '@timecount/utils';

import { TcFormGroupIntervalComponentConfig } from './form-group-interval.config';
import { TcFormGroupIntervalService } from './form-group-interval.service';

export interface TcFormGroupIntervalComponentStateModel
  extends TcFormGroupIntervalComponentConfig {
  labels?: { start: string; end: string };

  /**
   * The Interval's Form Group provides v
   */
  formGroup?: UntypedFormGroup;

  /**
   * Limit of the start input, calculated based on the config limit and the formGroup values changes
   */
  startInputLimit?: Interval;

  /**
   * Limit of the end input, calculated based on the config limit and the formGroup values changes
   */
  endInputLimit?: Interval;

  /**
   * If the current interval Duration is extended
   */
  isDurationExtended?: boolean;

  /**
   * Default interval duration length to be used to define the end picker's
   * value based on the start.
   */
  defaultLength?: Duration;

  /**
   * The value of the date Form Control
   */
  refDate?: Date;
}

@Injectable()
export class TcFormGroupIntervalComponentState extends RxState<TcFormGroupIntervalComponentStateModel> {
  readonly state$ = this.select();

  private _currentValidators: ValidatorFn[] = [];

  private _initState: Partial<TcFormGroupIntervalComponentStateModel> = {
    fieldType: 'date',
    formGroupName: 'dateInterval',
    labels: { start: null, end: null },
  };

  constructor(
    private translateService: TranslateService,
    private formGroupIntervalService: TcFormGroupIntervalService,
    private configService: TcConfigService,
  ) {
    super();

    this._setSideEffects();
    this.set({
      ...this._initState,
      ...this.configService.config.company.times,
    });
  }

  // --------------
  // Public Methods
  // --------------

  setDefaultLabels(): void {
    const labelsKeys = [
      'dispo/task.attrs.starts_at',
      'dispo/task.attrs.ends_at',
    ];

    this.translateService.get(labelsKeys).subscribe((translatedValues) => {
      this.set({
        labels: {
          start: translatedValues[labelsKeys[0]],
          end: translatedValues[labelsKeys[1]],
        },
      });
    });
  }

  // ------------
  // Side Effects
  // ------------

  private _setSideEffects(): void {
    this.hold(
      combineLatest([
        this.select('limit').pipe(
          // HACK:FIXME: This is a workaround to avoid the select only emitting
          // with null values.
          // Should become irrelevant after DEV-1594 is done
          filter((limit) => !!limit),
          distinctUntilChanged(isEqual),
        ),
        this.select('formGroup').pipe(
          filter((formGroup) => formGroup instanceof UntypedFormGroup),
        ),
      ]),
      ([limit, formGroup]) => {
        this._updateInputsLimits(limit as Interval);

        // Set isDurationExtended based on initial form value
        this.set({
          isDurationExtended:
            this.get().allowDoubledWorkingDay &&
            isValidInterval(formGroup.value) &&
            !isIntervalShorterThan24Hours(formGroup.value),
        });

        formGroup
          .get('start')
          .valueChanges.pipe(
            // TODO: Check if possible to replace with distinctUntilChanged
            // Lodash's `isEqual` may fail with invalid dates. More Info:
            // https://github.com/lodash/lodash/issues/2532
            // Should become irrelevant after DEV-1594 is done
            startWith(null),
            pairwise(),
            filter(([prev, next]: [Date, Date]) =>
              this._hasDateValueChanged(prev, next),
            ),
            map(([_prev, next]) => next),
          )
          .subscribe((start: Date) => {
            this._updateEndInputLimit(limit as Interval, start as Date);
          });
      },
    );
  }

  // ---------------
  // Private Methods
  // ---------------

  private _hasDateValueChanged(prev: unknown, next: unknown): boolean {
    const hasValueBeenToggled = (!prev && !!next) || (!!prev && !next);

    return (
      hasValueBeenToggled ||
      (isValidDate(next) && prev === null) ||
      (isValidDate(prev) &&
        isValidDate(next) &&
        !isSameMinute(prev as Date, next as Date))
    );
  }

  private _updateInputsLimits(limit: Interval) {
    const { formGroup } = this.get();

    this.set({ startInputLimit: limit });
    this._updateEndInputLimit(limit, formGroup.value.start);

    for (const validator of this._currentValidators) {
      if (formGroup.hasValidator(validator)) {
        formGroup.removeValidators(validator);
      }
    }

    const newValidators = this.formGroupIntervalService.getValidators(
      this.get(),
    );
    this._currentValidators = newValidators;

    formGroup.addValidators(newValidators);
    formGroup.updateValueAndValidity({ onlySelf: true });
  }

  private _updateEndInputLimit(limit: Interval, startToCheck: Date) {
    let maxDurationInMinutes = durationInMinutes(this.get().maxItemDuration);

    if (
      this.get().fieldType.toLowerCase().includes('time') &&
      maxDurationInMinutes % (24 * 60) === 0
    ) {
      // On time fields, if the max duration is exactly equal to a multiple of
      // 24h, we can't check which day the end input time refers to, so we need
      // to change the duration from 24h, 48h etc to 23h59, 47h59, etc.
      maxDurationInMinutes--;
    }

    let possibleEnd;
    if (isValidDate(startToCheck)) {
      possibleEnd = add(startToCheck, { minutes: maxDurationInMinutes });
    }

    const endInputLimit = {
      start:
        isValidDate(limit?.start) &&
        (!startToCheck || isBefore(startToCheck, limit?.start))
          ? limit?.start
          : isValidDate(startToCheck)
          ? startToCheck
          : null,
      end:
        isValidDate(limit?.end) &&
        (!possibleEnd || isAfter(possibleEnd, limit?.end))
          ? limit?.end
          : possibleEnd,
    };

    this.set({ endInputLimit });
  }
}
