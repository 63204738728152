import { Component, Input, OnInit } from '@angular/core';

import { TcGeoCoordinates } from '@timecount/core';

import { TcMapService } from './map.service';
import { TcMapMarker } from './map-marker';

@Component({
  selector: 'tc-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class TcMapComponent implements OnInit {
  @Input()
  center: TcGeoCoordinates | TcMapMarker;

  @Input()
  size: [number, number];

  @Input()
  zoom = 17;

  @Input()
  extraMarkers: TcMapMarker[];

  mapPath: string;

  constructor(private _staticMapService: TcMapService) {}

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    // Make sure to always pass a TcMapMarker instance to the service, when not
    // simple TcGeoCoordinates are passed.
    const center =
      (this.center as TcMapMarker).location !== undefined
        ? new TcMapMarker(this.center as TcMapMarker)
        : this.center;

    this.mapPath = this._staticMapService.getStaticMapPath(center, {
      size: this.size,
      zoom: this.zoom,
      extraMarkers: (this.extraMarkers ?? []).map(
        (marker) => new TcMapMarker(marker),
      ),
    });
  }
}
