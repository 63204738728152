import { AbstractControl, ValidationErrors } from '@angular/forms';
import { of, timer } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  startWith,
  switchMap,
  takeWhile,
} from 'rxjs/operators';

/**
 * Asynchronously get the status of the provided form control and avoid
 * completing while the state is `PENDING`.
 *
 * This is required to implement custom validation of a `ControlValueAccessor`
 * when it has an inner `FormGroup`, due to the following bug in Angular:
 * https://github.com/angular/angular/issues/41519
 *
 * @param control The control to have its status checked. Default: `10`
 * @param options.error The error to be returned when the `STATUS` is `INVALID`.
 * Default: `{ invalid: true }`
 * @param options.pollingRate The rate in which a `PENDING` status should be
 * rechecked. Ideally, this should just work, not requiring to be customized.
 * @param options.needsTimeOut If a timeout should be applied before subscribing
 * to the status changes. It can be required to give some `FormControl`s some
 * time to dispatch all statuses changes triggered by different/custom
 * validators. A 100ms timeout will be applied. Default: false
 *
 * @example
 * ```
 * // Default options
 * validate(): Promise<ValidationErrors | null> {
 *    return asyncStatusOfControl(this.innerForm);
 * }
 * ```
 *
 * @example
 * ```
 * // Custom options
 * validate(): Promise<ValidationErrors | null> {
 *   return asyncStatusOfControl(this.innerForm, {
 *     error: { customError: true },
 *     pollingRate: 50,
 *     needsTimeOut: true
 *   });
 * }
 * ```
 */
export async function asyncStatusOfControl(
  control: AbstractControl,
  options: {
    error?: ValidationErrors;
    pollingRate?: number;
    needsTimeOut?: boolean;
  } = {},
): Promise<ValidationErrors | null> {
  let { error, pollingRate } = options;

  error ??= { invalid: true };
  pollingRate ??= 10;

  if (options.needsTimeOut) {
    // TODO: Remove this when all custom `FormControl`s that need this have been
    // refactored to implement a `ControlValueAccessor` with their own inner
    // validator
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  return control.statusChanges
    .pipe(
      startWith(control.status),
      switchMap((status) =>
        status !== 'PENDING'
          ? of(status)
          : timer(0, pollingRate).pipe(
              map(() => control.status),
              takeWhile((status) => status === 'PENDING', true),
            ),
      ),
      distinctUntilChanged(),
      map((status) => (status === 'VALID' ? null : error)),
      first(),
    )
    .toPromise();
}
