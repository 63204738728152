import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

import { DispoPlanCollection, Plan } from '../collections/plan';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../collections/announcement';
import { Task } from '../collections/task';

@Component({
  selector: 'tc-hub-dispo-task',
  templateUrl: './dispo-task.component.html',
  styleUrls: ['./dispo-task.component.scss'],
})
export class DispoTaskComponent extends BaseModalComponent implements OnInit {
  task: Task;
  plan: Plan;

  announcements$: Observable<Announcement[]>;

  tabs = {
    general: 0,
    public: 1,
    slots: 2,
    move: 3,
    moveScheduled: 4,
  };

  constructor(
    protected config: ModalConfig,
    protected modalRef: ModalRef,
    private planCollection: DispoPlanCollection,
    private announcementCollection: DispoAnnouncementCollection,
  ) {
    super(config, modalRef);
  }

  ngOnInit() {
    this.task = this.config.data.task;

    this.announcements$ = this.announcementCollection
      .forTask(this.task)
      .pipe(first());
    this.announcements$.subscribe({
      next: (announcements) => {
        const baseIndex = this.task.schedule_id ? 3 : 4;
        announcements.forEach((a, index) => {
          this.tabs[`invitations${a.id}`] = baseIndex + index;
        });
        super.ngOnInit();
      },
    });

    if (this.task.plan_id) {
      this.planCollection.get(this.task.plan_id).subscribe((plan: Plan) => {
        this.plan = plan;
      });
    }
  }
}
