<nz-steps [nzCurrent]="currentStep" (nzIndexChange)="redraw()">
  <nz-step
    nzTitle="{{ 'dispo/schedule.tab_type.create-s1' | translate }}"
  ></nz-step>
  <nz-step
    nzTitle="{{ 'dispo/schedule.tab_type.create-s2' | translate }}"
  ></nz-step>
  <nz-step
    nzTitle="{{ 'dispo/schedule.tab_type.create-s3' | translate }}"
  ></nz-step>
  <nz-step
    nzTitle="{{ 'dispo/schedule.tab_type.create-s4' | translate }}"
  ></nz-step>
  <nz-step
    nzTitle="{{ 'dispo/schedule.tab_type.create-s5' | translate }}"
  ></nz-step>
</nz-steps>

<div class="steps">
  <div class="modal-container" *ngIf="currentStep === 0">
    <tc-hub-dsc-step1
      class="modal-container"
      [config]="config"
      (signal)="onSignal($event)"
    ></tc-hub-dsc-step1>
  </div>
  <div class="modal-container" *ngIf="currentStep === 1">
    <tc-hub-dsc-step2
      class="modal-container"
      [config]="config"
      (signal)="onSignal($event)"
    ></tc-hub-dsc-step2>
  </div>
  <div class="modal-container" *ngIf="currentStep === 2">
    <tc-hub-dsc-step3
      class="modal-container"
      [config]="config"
      (signal)="onSignal($event)"
    ></tc-hub-dsc-step3>
  </div>
  <div class="modal-container" *ngIf="currentStep === 3">
    <tc-hub-dsc-step4
      class="modal-container"
      [config]="config"
      (signal)="onSignal($event)"
    ></tc-hub-dsc-step4>
  </div>
  <div class="modal-container" *ngIf="currentStep === 4">
    <tc-hub-dsc-step5
      class="modal-container"
      [config]="config"
      (signal)="onSignal($event)"
    ></tc-hub-dsc-step5>
  </div>
</div>
