import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tc-hub-dispo-shift-grid',
  templateUrl: './dispo-shift-grid.component.html',
  styleUrls: ['./dispo-shift-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispoShiftGridComponent implements OnInit, OnDestroy {
  panelBegin: number;
  panelEnd: number;

  columnCount: number;
  columnWidth: number;

  @Input() dates: Observable<Date[]>;
  @Input() dateWidth = 360; // dateWidth per day
  @Input() resolution = 24; // 1 => 1 Spalte pro Tag, 24 => 1 Spalte pro Stunde
  // @Input() height = 42; // tile height, can be done via css

  destroyed$ = new Subject<void>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.columnWidth = Math.round(this.dateWidth / this.resolution);
    // this.elementRef.nativeElement.style.height = `${this.height}px`; // activate to have fixed size elements
    // this.elementRef.nativeElement.style.gridTemplateRows = `${this.height}px`; // activate to have fixed height
    this.dates.pipe(takeUntil(this.destroyed$)).subscribe((dates) => {
      this.columnCount = Math.round(dates.length * this.resolution);

      this.elementRef.nativeElement.style.background =
        this.generateShiftGridBackGround(dates[0].getDay());
      this.elementRef.nativeElement.style.gridTemplateColumns = `repeat(${this.columnCount}, ${this.columnWidth}px)`;
      this.elementRef.nativeElement.style.width =
        dates.length * this.dateWidth + 'px';
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private generateShiftGridBackGround(firstDay) {
    if (this.resolution === 1) {
      const daysOrder = [];

      [0, 1, 2, 3, 4, 5, 6].forEach((dow) => {
        const index = dow - firstDay >= 0 ? dow - firstDay : 7 + dow - firstDay;

        daysOrder[index] = dow;
      });

      const gradients = daysOrder.map((dow, index) => {
        if (dow === 0) {
          return `rgba(0, 0, 0, 0.035) ${
            this.columnWidth * index
          }px, rgba(0, 0, 0, 0.035) ${this.columnWidth * (index + 1)}px`;
        } else if (dow === 6) {
          return `rgba(0, 0, 0, 0.035) ${
            this.columnWidth * index
          }px, rgba(0, 0, 0, 0.035) ${
            this.columnWidth * (index + 1) - 1
          }px, transparent ${
            this.columnWidth * (index + 1) - 1
          }px, transparent ${this.columnWidth * (index + 1)}px`;
        } else if (dow % 2 === 0) {
          return `rgba(0, 0, 0, 0.024) ${
            this.columnWidth * index
          }px, rgba(0, 0, 0, 0.024) ${this.columnWidth * (index + 1)}px`;
        } else {
          return `transparent ${this.columnWidth * index}px, transparent ${
            this.columnWidth * (index + 1)
          }px`;
        }
      });

      return `repeating-linear-gradient(90deg, ${gradients.join(', ')})`;
    } else {
      return `repeating-linear-gradient(90deg, transparent 0px, transparent ${
        this.columnWidth
      }px, rgba(0, 0, 0, 0.024) ${this.columnWidth}px, rgba(0, 0, 0, 0.024) ${
        this.columnWidth * 2
      }px)`;
    }
  }
}
