import { Component, HostListener, Input } from '@angular/core';

import { DispoAssignmentsService } from '../dispo-assignments.service';
import { DASItemComponent } from '../item.component';

@Component({
  selector: 'tc-hub-das-timesheet-group',
  templateUrl: './timesheet-group.component.html',
  styleUrls: ['./timesheet-group.component.scss'],
})
export class DASTimesheetGroupComponent extends DASItemComponent {
  @Input() item: any;

  constructor(protected service: DispoAssignmentsService) {
    super(service);
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    const targetButton = !!$event
      .composedPath()
      .find((item) => item.nodeName?.toLowerCase() === 'button');

    if ($event.type === 'mouseup' && $event.button === 2) {
      return;
    }
    if (targetButton) {
      return;
    }

    const nextState = $event.type === 'contextmenu' ? true : !this.item.checked;
    this.selectItem(this.item.type, this.item.id, nextState, $event);
  }
}
