import { NgModule } from '@angular/core';

import { TcSelectsModule } from '../base/tc-selects';
import { SharedModule } from '../shared/shared.module';

import { TcEmployeeBiasesFormComponent } from './employee-biases-form/employee-biases-form.component';

@NgModule({
  declarations: [TcEmployeeBiasesFormComponent],
  exports: [TcEmployeeBiasesFormComponent],
  imports: [SharedModule, TcSelectsModule],
})
export class TcHubEmployeesModule {}
