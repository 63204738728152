<button
  class="pin--button"
  nz-button
  [nzType]="btnType"
  [nzSize]="btnSize"
  nz-popover
  nzPopoverTrigger="click"
  nzPopoverPlacement="right"
  [nzPopoverContent]="contentTemplate"
  [(nzPopoverVisible)]="visible"
  (nzPopoverVisibleChange)="onVisibleChange($event)"
>
  <nz-badge
    *ngIf="(activeCount$ | async) > 0"
    nzSize="small"
    [nzCount]="activeCount$ | async"
    [nzStyle]="style$ | async"
  >
    <i class="pin--icon" nz-icon nzType="tc:pin"></i>
  </nz-badge>

  <nz-badge
    nzDot
    *ngIf="(activeCount$ | async) === 0 && (inactiveCount$ | async) > 0"
  >
    <i class="pin--icon" nz-icon nzType="tc:pin"></i>
  </nz-badge>

  <i
    *ngIf="(activeCount$ | async) === 0 && (inactiveCount$ | async) === 0"
    class="pin--icon"
    nz-icon
    nzType="tc:pin"
  ></i>
</button>

<ng-template #contentTemplate>
  <table class="table">
    <thead>
      <tr>
        <th></th>
        <th>{{ 'pin.attrs.note' | translate }}</th>
        <th>{{ 'pin.attrs.users' | translate }}</th>
        <th>{{ 'pin.attrs.pinned_by' | translate }}</th>
        <th>{{ 'pin.attrs.created_at' | translate }}</th>
        <th>{{ 'pin.attrs.visible_at' | translate }}</th>
        <th>{{ 'pin.attrs.expires_at' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pin of pins$ | async">
        <td>
          <span class="badge badge-warning" *ngIf="pin.level === 'warning'">
            !
          </span>
          <span class="badge badge-danger" *ngIf="pin.level === 'error'">
            !
          </span>
          <span
            class="badge badge-default"
            *ngIf="pin.state === 'backlog'"
            (click)="state(pin, 'inbox')"
            nzTooltipTitle="{{ 'pin.state.backlog' | translate }}"
            nz-tooltip
          >
            <span nz-icon nzType="pause-circle" nzTheme="outline"></span>
          </span>
          <span
            class="badge badge-info"
            *ngIf="pin.state === 'inbox'"
            (click)="state(pin, 'progress')"
            nzTooltipTitle="{{ 'pin.state.inbox' | translate }}"
            nz-tooltip
          >
            <span nz-icon nzType="login" nzTheme="outline"></span>
          </span>
          <span
            class="badge badge-success"
            *ngIf="pin.state === 'progress'"
            (click)="state(pin, 'complete')"
            nzTooltipTitle="{{ 'pin.state.progress' | translate }}"
            nz-tooltip
          >
            <span nz-icon nzType="play-circle" nzTheme="outline"></span>
          </span>
          <span
            class="badge badge-default"
            *ngIf="pin.state === 'complete'"
            (click)="state(pin, 'progress')"
            nzTooltipTitle="{{ 'pin.state.complete' | translate }}"
            nz-tooltip
          >
            <span nz-icon nzType="check-circle" nzTheme="outline"></span>
          </span>
        </td>
        <td class="pin--break">
          {{ pin.note }}
        </td>
        <td>
          <nz-tag
            *ngFor="let user of pin.users; let ui = index; trackBy: trackById"
          >
            {{ user.shorthand }}
          </nz-tag>
          <nz-tag
            class="role-tag"
            *ngFor="let role of pin.roles; let ui = index; trackBy: trackById"
          >
            {{ role.title }}
          </nz-tag>
        </td>
        <td>
          <span *ngIf="pin.pinned_by">
            {{ (pin.pinned_by | userById | async)?.shorthand }}
          </span>
        </td>
        <td>
          <span *ngIf="pin.created_at">
            {{ pin.created_at | date : 'dd.MM.yyyy' }}
          </span>
        </td>
        <td>
          <span *ngIf="pin.visible_at">
            {{ pin.visible_at | date : 'dd.MM.yyyy' }}
          </span>
        </td>
        <td>
          <span *ngIf="pin.expires_at">
            {{ pin.expires_at | date : 'dd.MM.yyyy' }}
          </span>
        </td>
        <td>
          <button
            nz-button
            nzSize="small"
            nzShape="circle"
            nzType="default"
            (click)="edit(pin)"
          >
            <i nz-icon nzType="edit"></i>
          </button>
          <button
            nz-button
            nzSize="small"
            nzShape="circle"
            nzType="default"
            (click)="delete(pin)"
          >
            <i nz-icon nzType="delete"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <form [formGroup]="form" novalidate>
    <div class="form-group">
      <div class="input-group">
        <input type="text" formControlName="note" class="form-control" />
        <span class="input-group-btn">
          <button
            type="button"
            nz-button
            nzType="primary"
            [nzLoading]="loading"
            [disabled]="form.invalid"
            (click)="create()"
          >
            {{ 'save' | translate }}
          </button>
        </span>
      </div>
    </div>
  </form>
</ng-template>
