import { NgModule } from '@angular/core';

import { OrdinalNumberPipe } from './ordinal-number.pipe';
import { BytesPipe } from './bytes.pipe';
import { TcBinaryFromBoolean } from './binary-from-boolean.pipe';

@NgModule({
  declarations: [OrdinalNumberPipe, BytesPipe],
  imports: [TcBinaryFromBoolean],
  exports: [OrdinalNumberPipe, BytesPipe, TcBinaryFromBoolean],
})
export class NumberModule {}
