export class ModalConfig {
  data?: any;
  position?: any;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  backdrop?: boolean;
  align?: 'left' | 'center' | 'right' | null;
  noPadding?: boolean;
  modalTitle?: string;
  adminTags?: string[];
  maximizable?: boolean;
  closeOnEscape?: boolean;
  scrollTop?: boolean;
  baseZIndex?: number;
  dismissOnOverlayClick?: boolean;
  boundarySpacing?: number;
  safeGap?: number;
  disableEscKey?: boolean;
}

export const TC_MODAL_CONFIG_DEFAULT: ModalConfig = {
  data: {},
  position: { x: 0, y: 0 },
  minWidth: 500,
  minHeight: 200,
  backdrop: false,
  align: 'center',
  noPadding: false,
  modalTitle: '',
  maximizable: true,
  closeOnEscape: true,
  scrollTop: true,
  baseZIndex: 1000,
  dismissOnOverlayClick: false,
  boundarySpacing: 0,
  safeGap: 10,
  disableEscKey: false,
};
