import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared';

import { DispoShiftGridItemDirective } from './dispo-shift-grid-item.directive';
import { DispoShiftGridComponent } from './dispo-shift-grid.component';
import { DispoShiftGridHeaderDaysComponent } from './shift-grid-header-days/shift-grid-header-days.component';
import { DispoShiftGridHeaderWeeksComponent } from './shift-grid-header-weeks/shift-grid-header-weeks.component';
import { DispoShiftGridHeaderDayComponent } from './shift-grid-header-day/shift-grid-header-day.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    DispoShiftGridComponent,
    DispoShiftGridHeaderDaysComponent,
    DispoShiftGridHeaderWeeksComponent,
    DispoShiftGridItemDirective,
    DispoShiftGridHeaderDayComponent,
  ],
  exports: [
    DispoShiftGridComponent,
    DispoShiftGridHeaderDaysComponent,
    DispoShiftGridHeaderWeeksComponent,
    DispoShiftGridItemDirective,
  ],
})
export class DispoShiftGridModule {}
