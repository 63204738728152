import { Injectable } from '@angular/core';
import { isSameDay, isWeekend } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateInfo } from '@timecount/utils';

import { Holiday, TariffHolidayCollection } from '../core/collections/holiday';

import { DispoFocusService } from './dispo-focus.service';

@Injectable({
  providedIn: 'root',
})
export class DispoDateInfoService {
  constructor(
    private focusService: DispoFocusService,
    private holidayCollection: TariffHolidayCollection,
  ) {}

  dates(): Observable<DateInfo[]> {
    return combineLatest([
      this.focusService.dates(),
      this.holidayCollection.all(),
    ]).pipe(
      map(([dates, holidays]) => {
        return dates.map((date) => this.mapDateInfo(date, holidays));
      }),
    );
  }

  weeks(): Observable<DateInfo[][]> {
    return combineLatest([
      this.focusService.weeks(),
      this.holidayCollection.all(),
    ]).pipe(
      map(([weeks, holidays]) =>
        weeks.map((week) =>
          week.map((date) => this.mapDateInfo(date, holidays)),
        ),
      ),
    );
  }

  private mapDateInfo(date: Date, holidays: Holiday[]): DateInfo {
    const holiday = holidays.find((h) => isSameDay(h.date, date));

    return <DateInfo>{
      date: date,
      isWeekend: isWeekend(date),
      isHoliday: !!holiday,
      holidayTitle: holiday?.title || null,
    };
  }
}
