import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

import {
  ValueSet,
  ValueSetCollection,
} from '../../../core/collections/value_set';
import { RemoteConfig } from '../../../core/remote_config.service';
import { SetCollection } from '../../../core/sets-collection';

@Component({
  selector: 'tc-hub-vs-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class VSGeneralComponent implements OnInit {
  @Input() valueSet: ValueSet;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  private valueSetCollection: SetCollection;
  private schema: any;
  public loading = false;
  public form: UntypedFormGroup;

  constructor(
    private valueSets: ValueSetCollection,
    private remoteConfig: RemoteConfig,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.remoteConfig
      .getItem('schema', this.valueSet.schema_key)
      .pipe(first())
      .subscribe({
        next: (remoteConfig) => {
          if (remoteConfig) {
            this.schema = remoteConfig.data;
          } else {
            this.schema = {};
          }
        },
      });

    this.form = this.buildForm();
  }

  save() {
    const formValues = Object.assign({}, this.valueSet, this.form.value);

    if (this.schema && this.schema.properties) {
      Object.keys(this.schema.properties).forEach((key) => {
        if (!(key in formValues.data)) {
          formValues.data[key] = null;
        }
      });
    }

    this.loading = true;

    if (formValues.id) {
      this.valueSets.update(formValues.id, formValues).subscribe({
        next: (valueSet: ValueSet) => {
          if (valueSet.starts_at < new Date()) {
            window.location.reload();
          }
          this.loading = false;
          this.signal.emit({ action: 'close' });
        },
        error: () => {
          this.loading = false;
        },
      });
    } else {
      this.valueSets.create(formValues).subscribe({
        next: (valueSet: ValueSet) => {
          if (valueSet.starts_at < new Date()) {
            window.location.reload();
          }
          this.loading = false;
          this.signal.emit({ action: 'close' });
        },
        error: () => {
          this.loading = false;
        },
      });
    }
  }

  remove() {
    this.valueSets.delete(this.valueSet.id, this.valueSet).subscribe({
      next: () => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm() {
    return this.formBuilder.group({
      starts_at: [
        {
          value: this.valueSet.starts_at,
          disabled: !!this.valueSet.applied_at,
        },
        [Validators.required],
      ],
      note: [
        {
          value: this.valueSet.note,
          disabled: !!this.valueSet.applied_at,
        },
      ],
      data: [this.valueSet.data],
    });
  }
}
