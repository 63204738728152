<div class="sf-group">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>

  <div class="sf-controls">
    <div class="sf-elements">
      <nz-input-number
        [formControl]="control"
        class="sf-element"
      ></nz-input-number>
    </div>
  </div>
</div>
