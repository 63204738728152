<td>
  <label
    nz-checkbox
    [(nzChecked)]="item.checked"
    [nzIndeterminate]="item.indeterminate"
  ></label>

  {{ item.resource?.name }}

  <div class="data-panel">
    <tc-hub-data-panel
      [data]="item"
      section="dispo.assignments.timesheet.resource"
    ></tc-hub-data-panel>
  </div>
  <span
    *ngIf="item.gratis"
    nz-tooltip
    nzTooltipTitle="{{ 'dispo/assignment.tooltip.gratis' | translate }}"
    ><i
      nz-icon
      style="color: rgb(202, 3, 3)"
      nzType="dollar"
      nzTheme="outline"
    ></i
  ></span>

  <div class="status">
    <span
      class="assignment-missing"
      *ngIf="item.source_type === 'TcDispo::Assignment'"
      nzTooltipTitle="{{ 'dispo/assignment.tooltip.missing' | translate }}"
      nz-tooltip
      ><i nz-icon nzType="exclamation-circle" nzTheme="fill"></i
    ></span>
  </div>
</td>
<td>
  {{ item.job.title }}
</td>
<td></td>
<td>
  {{ item.starts_at | date : 'd.MM.' }}
</td>
<td>
  {{ item.starts_at | date : 'HH:mm' }}
</td>
<td>
  {{ item.ends_at | date : 'HH:mm' }}
</td>
<td>
  <span>{{ timesheetSummary.breakPeriods }}</span>
  <span
    class="breaks-info"
    *ngIf="timesheetSummary.breakPeriods > 0"
    nz-popover
    [nzPopoverContent]="timesheetTimeframes"
  >
    <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  </span>
</td>
<ng-template #timesheetTimeframes>
  <tc-hub-breaks-list-table
    [timesList]="parsedTimesheetTimes"
    [showDate]="true"
  ></tc-hub-breaks-list-table>
</ng-template>
<td>
  <span [class]="timesheetSummary.workDuration | highlightDurationLabel">{{
    timesheetSummary.workDuration | duration
  }}</span>
</td>
<td>{{ timesheetSummary.breakDuration | duration }}</td>
<td>
  <span [class]="timesheetSummary.workDuration | highlightDurationLabel">{{
    timesheetSummary.totalDuration | duration
  }}</span>
</td>
<td>
  <tc-hub-data-panel
    [data]="item"
    section="dispo.assignments.timesheet.status"
  ></tc-hub-data-panel>
</td>
