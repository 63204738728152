import { Component, OnInit } from '@angular/core';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

import { Pin, PinCollection } from '../../pins/pin';
import { SetCollection } from '../../core/sets-collection';

@Component({
  selector: 'tc-hub-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent extends BaseModalComponent implements OnInit {
  private pin: Pin;
  private pinTypeCollection: SetCollection;

  tabs = {
    general: 0,
  };

  constructor(
    protected config: ModalConfig,
    protected modalRef: ModalRef,
    private pinCollection: PinCollection,
  ) {
    super(config, modalRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.pin = this.config.data.pin;
  }
}
