import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

@Injectable({
  providedIn: 'root',
})
export class ZoneGroupCollection extends SetsCollection {
  identifier = 'preferences/zone_group';
  endpoint = '/api/preferences/zone_groups';
  cache = 0;
}
