import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

import { CollectionService } from '../../../core/collection.service';
import { BaseDropdownComponent } from '../base-dropdown/base-dropdown.component';

@Component({
  selector: 'tc-hub-attachment-placeholder',
  templateUrl: '../base-dropdown/base-dropdown.component.html',
  styleUrls: ['attachment-placeholder.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        }),
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        }),
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}')),
    ]),
  ],
})
export class AttachmentPlaceholderComponent
  extends BaseDropdownComponent
  implements OnInit
{
  @Input() formGroup: UntypedFormGroup;
  @Input() target: string;
  @Input() attachableId: number;
  @Input() attachableType: string;
  @Input() recipientTitle = '';

  items$: Observable<any[]>;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public router: Router,
    public cd: ChangeDetectorRef,
    private collectionService: CollectionService,
    private translateService: TranslateService,
  ) {
    super(el, renderer, router, cd);

    this.translateService
      .get('directives.attachment_placeholder.variable')
      .subscribe((value) => {
        this.label = value;
      });
  }

  ngOnInit() {
    this.items$ = from(
      this.collectionService['Attachment']
        .forItem(this.attachableId, this.attachableType)
        .all(),
    ).pipe(
      catchError(() => of([])),
      map((attachments: { [key: string]: string }[]) => {
        return attachments.map((attachment: any) => {
          return {
            label: attachment.description,
            value: attachment,
          };
        });
      }),
    );
  }

  itemClick($event, item) {
    const attachment = item.value;

    from(
      this.collectionService['AttachmentRelease']
        .forAttachment(attachment.id)
        .create({
          message: {
            subject: attachment.description,
            resource_id: attachment.attachable_id,
            resource_type: attachment.attachable_type,
            recipients: [{ name: this.recipientTitle }],
          },
        }),
    )
      .pipe(
        first(),
        catchError(() => of(null)),
      )
      .subscribe((release: any) => {
        if (release) {
          this.add(
            `{attachment: token="${release.token}", label="${release.message.subject}"}`,
          );
        }
      });
  }

  add(placeholder) {
    this.formGroup
      .get(this.target)
      .setValue(
        (this.formGroup.get(this.target).value || '') + ' ' + placeholder,
      );
  }
}
