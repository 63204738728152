import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import { MarkdownModule } from 'ngx-markdown';

import { TcFormsModule } from '@timecount/ui';
import { TcLocaleModule } from '@timecount/locale';
import { FrontEndConfigPipe, TcCoreModule } from '@timecount/core';

import { TcSelectsModule } from '../base/tc-selects';
import { DataPanelWidgetComponent } from '../base/data-panel/data-panel-widget/data-panel-widget.component';
import { DataPanelComponent } from '../base/data-panel/data-panel.component';

import { DispoMenuActiveDirective } from './active.directive';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { DepartmentsDisplayComponent } from './departments/departments-display/departments-display.component';
import { DispoFilterDisplayComponent } from './filter/filter-display/filter-display.component';
import { DispoFilterFormComponent } from './filter/filter-form/filter-form.component';
import { DispoFilterPresetComponent } from './filter/filter-preset/filter-preset.component';
import { DispoToggleResourcesComponent } from './toggle-views/toggle-dispo-resources.component';
import { DispoFilterSortComponent } from './filter/filter-sort/filter-sort.component';
import { DispoFilterComponent } from './filter/filter.component';
import { PinsButtonComponent } from './pins-button/pins-button.component';
import { FieldsetRateComponent } from './fieldsets/fieldset-rate/fieldset-rate.component';
import { LogsComponent } from './logs/logs.component';
import { TcSchemaFormModule } from './tc-schema-form';
import { TcZorroAntdModule } from './tc-zorro-antd.module';
import { HighlightDurationLabelPipe } from './highlight-duration-label.pipe';
import { HighlightZeroLabelPipe } from './highlight-zero-label.pipe';
import { UserByIdPipe } from './user.pipe';
import { TcJobRatesFormComponent } from './job-rates-form/job-rates-form.component';
import { IntervalSelectComponent } from './interval-select/interval-select.component';

/**
 * App-wide shared components, directives and pipes.
 *
 * TODO: This is a Work in Progress. As features are organised in modules, their
 * dependencies (that are also dependencies for other features) declarations
 * should be moved here. Some of the imports and declarations here may later be
 * moved to a feature specific shared module if they are not needed app-wide.
 */
@NgModule({
  imports: [
    CommonModule,
    TcLocaleModule,
    TcCoreModule,
    TcFormsModule,
    TcSchemaFormModule.forRoot(),
    TcZorroAntdModule,
    ScrollingModule,
    NgPipesModule,
    MarkdownModule,
    TcSelectsModule,
    FrontEndConfigPipe,
  ],
  declarations: [
    CustomFieldsComponent,
    DataPanelComponent,
    DataPanelWidgetComponent,
    PinsButtonComponent,
    DepartmentsDisplayComponent,
    DispoFilterComponent,
    DispoFilterFormComponent,
    DispoFilterDisplayComponent,
    DispoFilterPresetComponent,
    DispoFilterSortComponent,
    DispoToggleResourcesComponent,
    FieldsetRateComponent,
    LogsComponent,
    TcJobRatesFormComponent,
    DispoMenuActiveDirective,
    HighlightDurationLabelPipe,
    HighlightZeroLabelPipe,
    UserByIdPipe,
    IntervalSelectComponent,
  ],
  exports: [
    CommonModule,
    TcLocaleModule,
    TcCoreModule,
    TcFormsModule,
    TcSchemaFormModule,
    TcZorroAntdModule,
    ScrollingModule,
    NgPipesModule,
    MarkdownModule,
    CustomFieldsComponent,
    DataPanelComponent,
    DataPanelWidgetComponent,
    PinsButtonComponent,
    DepartmentsDisplayComponent,
    DispoMenuActiveDirective,
    DispoFilterComponent,
    DispoFilterFormComponent,
    DispoFilterDisplayComponent,
    DispoFilterPresetComponent,
    DispoFilterSortComponent,
    DispoToggleResourcesComponent,
    FieldsetRateComponent,
    LogsComponent,
    TcJobRatesFormComponent,
    HighlightDurationLabelPipe,
    HighlightZeroLabelPipe,
    UserByIdPipe,
    IntervalSelectComponent,
  ],
})
export class SharedModule {}
