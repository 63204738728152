import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  hasFeedbackRequestsOfType,
  hasFeedbackRequestsOnStatus,
  TcConfigService,
  TcFeedbackRequestType,
} from '@timecount/core';

import { AssignmentService } from '../../dispo/actions/assignment.service';
import { DispoAssignmentCollection } from '../../dispo/collections/assignment';
import { ResourceTemplateCollection } from '../../core/collections/resource_template';
import { CurrentUserService } from '../../core/current-user.service';
import { Assignment } from '../collections/assignment.model';
import { MessageService } from '../actions/message.service';
import { DispoMessage } from '../collections/message';

import { MenuItem } from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class DispoMenuAssignment {
  private translations: { [key: string]: string } = {};
  private taskMessageTemplates: any[] = [];

  private maxRequests = 200;

  allowOnsiteCheckin = this.configService.config.company.allowOnsiteCheckin;
  allowAppAttendance = this.configService.config.company.allowAppAttendance;

  constructor(
    private translateService: TranslateService,
    private assignmentActions: AssignmentService,
    private assignmentCollection: DispoAssignmentCollection,
    private messageActions: MessageService,
    private resourceTemplateCollection: ResourceTemplateCollection,
    private currentUser: CurrentUserService,
    private configService: TcConfigService,
  ) {
    this.resourceTemplateCollection
      .setForResource('dispo.messages.task')
      .all()
      .subscribe((templates) => {
        this.taskMessageTemplates = templates;
      });

    this.translateService
      .get([
        'dispo/assignment.actions.edit',
        'dispo/assignment.actions.info',
        'dispo/assignment.actions.confirmation',
        'dispo/assignment.actions.convert_assignment_to_availability',
        'dispo/assignment.actions.convert_assignment_to_time_balance',
        'dispo/assignment.actions.delete',
        'dispo/assignment.actions.validate',
        'dispo/assignment.menu.assignments',
        'dispo/assignment.actions.message',
        'dispo/assignment.menu.message',
        'dispo/assignment.actions.confirm_onsite_checkin',
        'dispo/assignment.actions.unconfirm_onsite_checkin',
        'dispo/assignment.actions.confirm_attend',
        'dispo/assignment.actions.unconfirm_attend',
        'dispo/assignment.actions.gratis',
        'dispo/assignment.actions.invoiceable',
        'trails.actions.resource',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  items(currAssignments): Observable<any[]> {
    return this.assignmentCollection.observeItems(currAssignments).pipe(
      map((assignments: Assignment[]) => {
        if (assignments.length === 0) {
          return [];
        }

        const messagesFromAssignments = () => {
          const messages = [];

          assignments
            .filter((a) => a.resource_type === 'Employee')
            .forEach((assignment) => {
              let message = messages.find(
                (m) => m.task_id === assignment.task_id,
              );

              if (!message) {
                message = Object.assign(new DispoMessage(), {
                  type: 'task',
                  task_id: assignment.task_id,
                  recipients: [],
                });
                messages.push(message);
              }

              const recipient = message.recipients.find(
                (r) =>
                  r.type === assignment.resource_type &&
                  r.id === assignment.resource_id,
              );

              if (!recipient) {
                message.recipients.push({
                  id: assignment.resource_id,
                  type: assignment.resource_type,
                });
              }
            });

          return messages;
        };

        const assignmentActions: MenuItem[] = [];

        if (assignments.length === 1) {
          assignmentActions.push({
            label: this.translations['dispo/assignment.actions.edit'],
            icon: 'edit',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.assignment.general',
            ),
            command: (event: any) => {
              this.assignmentActions.edit(assignments[0]);
            },
          });
        }

        assignmentActions.push({
          label: this.translations['dispo/assignment.actions.validate'],
          icon: 'check',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.assignment.validate',
          ),
          disabled: assignments.length > this.maxRequests * 4,
          command: (event: any) => {
            this.assignmentActions.validate(assignments);
          },
        });

        const gratisAssignments = new Map<number, boolean>(
          assignments.map((assignment) => [assignment.id, assignment.gratis]),
        );

        ['gratis', 'invoiceable'].forEach((action) => {
          const isGratis = action === 'invoiceable';
          assignmentActions.push({
            label: this.translations[`dispo/assignment.actions.${action}`],
            icon: 'dollar',
            visible:
              this.currentUser.hasAccessToSection(
                'views.dispo.assignment.gratis',
              ) &&
              assignments.some(
                (assignment) =>
                  gratisAssignments.get(assignment.id) === isGratis,
              ),
            command: (event: any) => {
              this.assignmentActions.setGratisState(assignments, !isGratis);
            },
          });
        });

        assignmentActions.push({
          label: this.translations['dispo/assignment.actions.info'],
          icon: 'info',
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.assignment.info',
          ),
          command: (event: any) => {
            this.assignmentActions.info(assignments);
          },
        });

        const confirmationFeedbackMenuItems = new Map<
          TcFeedbackRequestType,
          string
        >();

        if (this.allowOnsiteCheckin) {
          confirmationFeedbackMenuItems.set('onsite_checkin', 'environment');
        }

        if (this.allowAppAttendance) {
          confirmationFeedbackMenuItems.set('attend', 'carry-out');
        }

        [...confirmationFeedbackMenuItems.entries()].forEach(
          ([confirmationFeedbackType, icon]) => {
            const actionAssignments = assignments.filter((assignment) =>
              hasFeedbackRequestsOfType(
                assignment.responses,
                confirmationFeedbackType,
              ),
            );
            const responseStatusOfAssignment = new Map<number, boolean>(
              actionAssignments.map((assignment) => {
                return [
                  assignment.id,
                  hasFeedbackRequestsOnStatus(
                    assignment.responses,
                    'responded',
                    confirmationFeedbackType,
                  ),
                ];
              }),
            );

            ['confirm', 'unconfirm'].forEach((action) => {
              // When setting the 'unconfirm' action, we need to take actions
              // on the already confirmed assignments.
              const isConfirmed = action === 'unconfirm';

              const toggleAssignments = actionAssignments.filter(
                (assignment) =>
                  responseStatusOfAssignment.get(assignment.id) === isConfirmed,
              );

              if (toggleAssignments.length) {
                assignmentActions.push({
                  label:
                    this.translations[
                      `dispo/assignment.actions.${action}_${confirmationFeedbackType}`
                    ],
                  icon,
                  iconNzTheme: isConfirmed ? 'fill' : 'outline',
                  visible: this.currentUser.hasAccessToSection(
                    `views.dispo.assignment.${confirmationFeedbackType}`,
                  ),
                  command: (_event: any) => {
                    this.assignmentActions.respondFeedbackRequest(
                      toggleAssignments,
                      confirmationFeedbackType,
                      !isConfirmed,
                    );
                  },
                });
              }
            });
          },
        );

        const requestableAssignments = assignments.filter(
          (a) => a.task.state == 'open',
        );
        if (requestableAssignments.length > 0) {
          assignmentActions.push({
            label: this.translations['dispo/assignment.actions.confirmation'],
            icon: 'wifi',
            disabled: requestableAssignments.length > this.maxRequests,
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.assignment.confirm',
            ),
            command: (event: any) => {
              this.assignmentActions.requestReadConfirmation(
                requestableAssignments,
              );
            },
          });
        }

        const employeeAssignments = assignments.filter(
          (a) => a.resource_type === 'Employee',
        );
        if (employeeAssignments.length > 0) {
          assignmentActions.push({
            label:
              this.translations[
                'dispo/assignment.actions.convert_assignment_to_availability'
              ],
            icon: 'stop',
            disabled: employeeAssignments.length > this.maxRequests,
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.assignment.availability',
            ),
            command: (event: any) => {
              this.assignmentActions.convertToAvailabilites(
                employeeAssignments,
              );
            },
          });

          assignmentActions.push({
            label:
              this.translations[
                'dispo/assignment.actions.convert_assignment_to_time_balance'
              ],
            icon: 'ico:aid',
            disabled: employeeAssignments.length > this.maxRequests,
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.assignment.timebalance',
            ),
            command: (event: any) => {
              this.assignmentActions.convertToTimeBalances(employeeAssignments);
            },
          });

          assignmentActions.push({
            label: this.translations['dispo/assignment.actions.message'],
            icon: 'comment',
            visible: this.currentUser.hasAccessToSection(
              'views.dispo.assignment.message',
            ),
            command: (event: any) => {
              const defaultTemplate = this.taskMessageTemplates.find(
                (t) => t.default,
              ) || { template: {} };
              this.messageActions.multi(
                messagesFromAssignments(),
                'task',
                defaultTemplate.template,
              );
            },
          });

          const taskMessageTemplates: any[] = this.taskMessageTemplates
            .filter((t) => !t.default)
            .map((template) => {
              return {
                label: template.title,
                command: (event: any) => {
                  this.messageActions.multi(
                    messagesFromAssignments(),
                    'task',
                    template.template,
                  );
                },
              };
            });

          assignmentActions.push({
            label: this.translations['dispo/assignment.menu.message'],
            icon: 'copy',
            visible:
              taskMessageTemplates.length > 0 &&
              this.currentUser.hasAccessToSection(
                'views.dispo.assignment.message',
              ),
            items: taskMessageTemplates,
          });
        }

        assignmentActions.push({
          label: this.translations['dispo/assignment.actions.delete'],
          icon: 'delete',
          disabled: assignments.length > this.maxRequests,
          visible: this.currentUser.hasAccessToSection(
            'views.dispo.assignment.delete',
          ),
          command: (event: any) => {
            this.assignmentActions.delete(assignments);
          },
        });

        if (assignments?.length === 1) {
          assignmentActions.push({
            label: this.translations['trails.actions.resource'],
            icon: 'audit',
            visible:
              this.currentUser.hasAccessToFeature('logs') &&
              this.currentUser.can('index', 'PaperTrail::Version', {
                item_type: 'TcDispo::Assignment',
              }),
            command: (_event: unknown) => {
              this.assignmentActions.viewLogs(assignments[0].id);
            },
          });
        }

        return [
          ...(assignmentActions.length !== 0
            ? [
                { separator: true },
                {
                  header: true,
                  label:
                    this.translations['dispo/assignment.menu.assignments'] +
                    (assignments.length > 1 ? ` (${assignments.length})` : ''),
                },
                ...assignmentActions,
              ]
            : []),
        ];
      }),
    );
  }
}
