import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { BaseModalComponent, ModalConfig, ModalRef } from '@timecount/ui';

import { ProjectSetsCollection } from '../../../../project-sets.collection';
import { Project } from '../../../../project.model';

@Component({
  selector: 'tc-hub-entries-batch-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class TcCreateWorkEntryBatchComponent
  extends BaseModalComponent
  implements OnInit
{
  @Output() signal: EventEmitter<any> = new EventEmitter();
  config: unknown;
  currentStep = 0;
  projectLoaded = false;

  constructor(
    private _projectSetsCollection: ProjectSetsCollection,
    protected modalConfig?: ModalConfig,
    protected modalRef?: ModalRef,
  ) {
    super(modalConfig, modalRef);
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit(): void {
    const { data } = this.modalConfig ?? {};

    if (data) {
      const { config, ...restOfData } = data;
      const { projectId, ...restOfConfig } = config;

      this._projectSetsCollection
        .get(projectId)
        .subscribe((project: Project) => {
          Object.assign(this, {
            config: {
              project,
              ...restOfConfig,
            },
            ...restOfData,
          });

          this.projectLoaded = true;
        });
    }
  }

  // ---------------
  // Events Handlers
  // ---------------

  onNavigate(signal) {
    if (signal.action === 'next') {
      this.config = signal.config;
      this.next();
    }

    if (signal.action === 'prev') {
      this.config = signal.config;
      this.prev();
    }

    this.onSignal(signal);
  }

  // --------------
  // Public Methods
  // --------------

  redraw() {
    this.signal.emit({ action: 'resize' });
  }

  prev(): void {
    this.currentStep -= 1;
  }

  next(): void {
    this.currentStep += 1;
  }
}
