import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { DispoAnnouncementCollection } from '../../dispo/collections/announcement';
import { DispoFilterService } from '../../shared/filter/filter.service';

@Component({
  selector: 'tc-hub-tc-transfer',
  templateUrl: './tc-transfer.component.html',
  styleUrls: ['./tc-transfer.component.scss'],
})
export class TcTransferComponent implements OnInit {
  @Input() resources$: Observable<any[]>;
  @Input() resourcesDS$: Observable<any[]>;

  @Input() selected: any[];
  @Input() section: string;

  @Input() shouldDisableResources = true;

  @Output() tcSelect: EventEmitter<unknown[]> = new EventEmitter();

  public resources: any[] = [];

  public selectedResources$ = new BehaviorSubject([]);

  public resourcesAdd$: Observable<any[]>;
  private resourcesAdd: any[];
  public resourcesToAdd: { [key: string]: boolean } = {};
  public resourcesAddAll = false;
  public resourcesAddAllIndeterminate = false;

  public resourcesRemove$: Observable<any[]>;
  private resourcesRemove: any[];
  public resourcesToRemove: { [key: string]: boolean } = {};
  public resourcesRemoveAll = false;
  public resourcesRemoveAllIndeterminate = false;

  public filters = [];
  public currSelectedFilter;

  constructor(
    private filterService: DispoFilterService,
    private announcementCollection: DispoAnnouncementCollection,
  ) {}

  ngOnInit() {
    this.filterService
      .getPresets(this.section)
      .pipe(first())
      .subscribe((presets) => {
        this.filters = presets;

        if (presets[0]) {
          this.setPreset(presets[0].id);
        }
      });

    this.resources$.pipe(first()).subscribe({
      next: (resources) => {
        const selectedRes = resources.filter((r) => {
          return (
            this.selected.findIndex(
              (i) => i.type === r.type && i.id === r.id,
            ) !== -1
          );
        });

        this.selectedResources$.next(selectedRes);
      },
    });

    this.resourcesRemove$ = this.selectedResources$.pipe(
      map((resources) => {
        this.resourcesRemove = resources;
        this.tcSelect.emit([...resources]);
        this.updateAllRemoveResources();
        return resources.map((resource, index) =>
          Object.assign({ index: index }, resource),
        );
      }),
    );

    this.resourcesAdd$ = combineLatest(
      this.resources$,
      this.resourcesDS$,
      this.filterService.getQuery(this.section),
      this.selectedResources$,
    ).pipe(
      map(([resources, resourcesDS, query, selectedResources]) => {
        const visibleResourceIds = resourcesDS
          .filter(query)
          .map((s: any) => s.id);
        const selectedResourcesIds = selectedResources.map((i) => i.id);

        return resources.filter(
          (s) =>
            selectedResourcesIds.indexOf(s.id) === -1 &&
            visibleResourceIds.indexOf(s.id) !== -1,
        );
      }),
      map((resources) => {
        this.resourcesAdd = resources;
        this.updateAllAddResources();
        return resources.map((resource, index) =>
          Object.assign({ index: index }, resource),
        );
      }),
    );
  }

  setPreset(id) {
    this.currSelectedFilter = id;
    this.filterService.setPreset(id);
  }

  addSelected() {
    const selectedRes = [...this.selectedResources$.value];
    const selectedIds = [];

    Object.keys(this.resourcesToAdd).forEach((id) => {
      if (this.resourcesToAdd[id]) {
        selectedIds.push(parseInt(id, 10));
      }
    });

    this.resources$.pipe(first()).subscribe({
      next: (resources) => {
        const addRes = resources.filter(
          (r) => selectedIds.indexOf(r.id) !== -1,
        );
        this.selectedResources$.next(selectedRes.concat(addRes));
      },
    });
  }

  removeSelected() {
    let selectedRes = [...this.selectedResources$.value];
    const selectedIds = [];

    Object.keys(this.resourcesToRemove).forEach((id) => {
      if (this.resourcesToRemove[id]) {
        selectedIds.push(parseInt(id, 10));
      }
    });

    selectedRes = selectedRes.filter((s) => selectedIds.indexOf(s.id) === -1);

    this.selectedResources$.next(selectedRes);
  }

  updateAllAddResources() {
    this.resourcesAddAllIndeterminate = false;

    // reset
    Object.keys(this.resourcesToAdd).forEach((id) => {
      delete this.resourcesToAdd[id];
    });

    // refill
    this.resourcesAdd.forEach((resource) => {
      if (
        !(
          this.shouldDisableResources &&
          resource.type === 'Employee' &&
          !resource.email &&
          !resource.user_id
        )
      ) {
        this.resourcesToAdd[resource.id] = this.resourcesAddAll;
      }
    });
  }

  updateSingleAddResource() {
    if (Object.values(this.resourcesToAdd).every((checked) => !checked)) {
      this.resourcesAddAll = false;
      this.resourcesAddAllIndeterminate = false;
    } else if (Object.values(this.resourcesToAdd).every((checked) => checked)) {
      this.resourcesAddAll = true;
      this.resourcesAddAllIndeterminate = true;
    } else {
      this.resourcesAddAllIndeterminate = true;
    }
  }

  updateAllRemoveResources() {
    this.resourcesRemoveAllIndeterminate = false;

    // reset
    Object.keys(this.resourcesToRemove).forEach((id) => {
      delete this.resourcesToRemove[id];
    });

    this.resourcesRemove.forEach((resource) => {
      this.resourcesToRemove[resource.id] = this.resourcesRemoveAll;
    });
  }

  updateSingleRemoveResource() {
    if (Object.values(this.resourcesToRemove).every((checked) => !checked)) {
      this.resourcesRemoveAll = false;
      this.resourcesRemoveAllIndeterminate = false;
    } else if (
      Object.values(this.resourcesToRemove).every((checked) => checked)
    ) {
      this.resourcesRemoveAll = true;
      this.resourcesRemoveAllIndeterminate = true;
    } else {
      this.resourcesRemoveAllIndeterminate = true;
    }
  }

  trackByIndex(_: number, data: any): number {
    return data.index;
  }

  addCount() {
    return Object.values(this.resourcesToAdd).filter((v) => v).length;
  }

  removeCount() {
    return Object.values(this.resourcesToRemove).filter((v) => v).length;
  }

  resource$(id: number) {
    return this.resourcesDS$.pipe(
      map((resourcesDS) => {
        return resourcesDS.find((r) => r.id === id);
      }),
    );
  }

  private query() {
    return this.filterService.getQuery(this.section);
  }
}
