<div class="sf-group sf-lightswitch">
  <div class="sf-controls">
    <div class="sf-elements">
      <label *ngIf="schema.title">
        <nz-switch [formControl]="control" class="sf-element"></nz-switch>
        {{ schema.title }}
      </label>
    </div>
  </div>
</div>
