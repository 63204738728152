import { Injectable } from '@angular/core';

import { SetsCollection } from '../sets-collection';

export class EmploymentMode {
  id: number;
  visibility: number;

  name: string;

  title: string;
  key: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmploymentModeCollection extends SetsCollection {
  identifier = 'preferences/employment_mode';
  endpoint = '/api/preferences/employment_modes';

  bindLabel = 'title';

  cache = 60 * 60;

  localSearch = ['title', 'key'];

  decorate(mode: EmploymentMode, imports) {
    mode.name = `${mode.key} ${mode.title}`;

    return mode;
  }

  visible() {
    return this.query({ 'filter[visibility]': '1' }, (e) => e.visibility === 1);
  }

  invisible() {
    return this.query({ 'filter[visibility]': '0' }, (e) => e.visibility === 0);
  }
}
