<ng-container *ngIf="currentDispoViews.length">
  <button
    nz-button
    nz-dropdown
    [nzDropdownMenu]="menu"
    nzTrigger="click"
    nzClickHide="true"
    nzBackdrop="false"
  >
    {{ 'directives.toggle.label' | translate }}
    <i nz-icon nzType="down"></i>
  </button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu *ngFor="let view of currentDispoViews">
      <li nz-menu-item (click)="onShowClick(view)">
        {{ 'directives.toggle.show_all' | translate: { resource: view.title } }}
      </li>
      <li nz-menu-item (click)="onHideClick(view)">
        {{ 'directives.toggle.hide_all' | translate: { resource: view.title } }}
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-container>
