import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { format } from 'date-fns';

import { DateUnicodeFormat } from '@timecount/utils';

import { dig } from '../../../core/helpers';
import { RemoteConfig } from '../../../core/remote_config.service';
import { JsonCalculationObject, JsonCalculator } from '../../../shared';

import { DataPanelWidget } from './data-panel-widget.model';

@Component({
  selector: 'tc-hub-data-panel-widget',
  templateUrl: './data-panel-widget.component.html',
  styleUrls: ['./data-panel-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPanelWidgetComponent {
  @Input() widget: DataPanelWidget;
  @Input() data: Record<string, any>;

  @HostBinding('class') @Input() size = 'small';

  constructor(private remoteConfig: RemoteConfig) {}

  indicatorClass() {
    let classes = {};

    if (
      this.widget.colorize &&
      (this.widget.value || this.widget.value_calc) &&
      (this.widget.compare || this.widget.compare_calc)
    ) {
      const value = this.value(this.widget.value || this.widget.value_calc);
      const compare = this.value(
        this.widget.compare || this.widget.compare_calc,
      );

      if (!isNaN(value) && !isNaN(compare)) {
        classes = {
          'widget-indicator-zero': value === 0,
          'widget-indicator-progress': value > 0 && value < compare,
          'widget-indicator-filled': value === compare,
          'widget-indicator-over': value > compare,
        };
      } else {
        classes = {
          // eslint-disable-next-line: triple-equals
          'widget-indicator-zero': value != compare,
          // eslint-disable-next-line: triple-equals
          'widget-indicator-filled': value == compare,
        };
      }
    }

    return classes;
  }

  valueFor() {
    const value = this.value(this.widget.value || this.widget.value_calc);

    if (typeof value === 'undefined' || value === null) {
      return undefined;
    }

    if (this.widget.format === 'boolean') {
      if (this.widget.icon) {
        return value ? '' : undefined;
      } else {
        return value ? 'Ja' : 'Nein';
      }
    }

    return this.formatValue(value, this.widget.format);
  }

  compareFor() {
    const value = this.value(this.widget.compare || this.widget.compare_calc);

    return this.formatValue(value, this.widget.format);
  }

  valuePresent() {
    if (this.widget.visible || this.widget.visible_calc) {
      return this.value(this.widget.visible || this.widget.visible_calc);
    }

    return typeof this.valueFor() !== 'undefined';
  }

  private value(calc: JsonCalculationObject | string) {
    if (typeof calc === 'object') {
      return new JsonCalculator(this.data).value(calc);
    }

    return dig(this.data, calc);
  }

  private formatValue(value, type: DataPanelWidget['format'] = undefined) {
    const numericValue = Number(value);
    if (!type) {
      if (Number.isInteger(numericValue)) {
        type = 'integer';
      } else if (!Number.isNaN(numericValue)) {
        type = 'float';
      }
    }

    switch (type) {
      case 'integer': {
        return Number.isNaN(numericValue) ? 'NaN' : numericValue.toFixed(0);
      }
      case 'float': {
        return Number.isNaN(numericValue)
          ? 'NaN'
          : numericValue.toFixed(2).replace('.', ',');
      }
      case 'money': {
        return Number.isNaN(numericValue)
          ? 'NaN'
          : (numericValue / 100).toFixed(2).replace('.', ',');
      }
      case 'date': {
        const dateValue = new Date(value);
        return Number.isNaN(dateValue.getTime())
          ? 'NaN'
          : format(dateValue, DateUnicodeFormat.date);
      }
      case 'datetime': {
        const dateValue = new Date(value);
        return Number.isNaN(dateValue.getTime())
          ? 'NaN'
          : format(dateValue, DateUnicodeFormat.dateTime);
      }
      case 'duration': {
        const padNumber = (n: number) => (n + '').padStart(2, '0');
        return Number.isNaN(numericValue)
          ? 'invalid'
          : `${padNumber(Math.floor(numericValue / 3600))}:${padNumber(
              Math.floor((numericValue / 60) % 60),
            )}`;
      }
      case 'string': {
        return String(value ?? '');
      }
      case 'debug': {
        return JSON.stringify(value);
      }
      default: {
        return value;
      }
    }
  }
}
