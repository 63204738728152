import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {
  IntervalSummary,
  TcConfigService,
  TcIntervalList,
} from '@timecount/core';
import {
  TcFieldSetIntervalSetService,
  TcTimesSummaryService,
} from '@timecount/ui';

import { DispoAssignmentsService } from '../dispo-assignments.service';

@Component({
  selector: 'tc-hub-das-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class DASTaskComponent implements OnChanges {
  @Input() item: any;

  taskSummary: IntervalSummary;
  parsedTaskTimes: Interval[];

  constructor(
    private service: DispoAssignmentsService,
    private configService: TcConfigService,
    private intervalSetService: TcFieldSetIntervalSetService,
    private summaryService: TcTimesSummaryService,
  ) {}

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    const targetButton = !!$event
      .composedPath()
      .find((item) => item.nodeName?.toLowerCase() === 'button');

    if ($event.type === 'mouseup' && $event.button === 2) {
      return;
    }
    if (targetButton) {
      return;
    }

    const nextState = $event.type === 'contextmenu' ? true : !this.item.checked;
    this.selectItem(this.item.type, this.item.id, nextState, $event);
  }

  ngOnChanges({ item }: SimpleChanges): void {
    if (item) {
      this.parsedTaskTimes = TcIntervalList.parseFromApi(this.item);

      const parsedTimes = this.intervalSetService.parseFromApi(this.item);
      this.taskSummary = this.summaryService.getSummary(parsedTimes);
    }
  }

  toggleItem(type, id) {
    this.service.toggleItem(type, id);
  }

  selectItem(type, id, state, event = new MouseEvent('click')) {
    this.service.selectItem(type, id, state, event);
  }
}
