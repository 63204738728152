import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import DragSelect from 'dragselect';

import { DispoLoaderService } from '../../loader/loader.service';
import { LocalSettingsService } from '../../../core/local-settings.service';
import { Plan } from '../../collections/plan';
import { SlotPos } from '../../collections/task';
import { Announcement } from '../../collections/announcement';
import { DispoAnnouncementsService } from '../dispo-announcements.service';
import { DispoFocusService } from '../../dispo-focus.service';

// REFACTOR: same as dispo-tasks/task.component
const isDroppable = (e) => {
  const dragType = e.dataTransfer.getData('drag');

  if (
    dragType === 'timecount/resources' ||
    dragType === 'timecount/dispo_assignments'
  ) {
    return true;
  }

  return false;
};

@Component({
  selector: 'tc-hub-dan-announcement',
  templateUrl: './announce.component.html',
  styleUrls: ['./announce.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DANAnnounceComponent implements OnInit, OnDestroy {
  @Input() plan: Plan;
  @Input() announcement: Announcement;

  @Output() tcSelect: EventEmitter<'started'> = new EventEmitter();

  dates$: Observable<Date[]>;
  tasks$: Observable<any[]>;
  invitations$: Observable<any[]>;
  announcement$: Observable<any>;
  expanded$: Observable<boolean>;
  subs = [];
  assignments = [];
  invitations = [];
  tasks = [];

  updateSelectables$: Subject<any> = new Subject();

  selectable: DragSelect;
  active = false;
  loading = false;
  visible = false;

  constructor(
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
    private localSettings: LocalSettingsService,
    private dispoFocus: DispoFocusService,
    private service: DispoAnnouncementsService,
    private loadingService: DispoLoaderService,
  ) {
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  ngOnInit() {
    console.dir('init announcement');
    this.announcement$ = this.service.announcementDS(this.announcement);
    this.tasks$ = this.service.announcementTasks(this.announcement);
    this.invitations$ = this.service.announcementInvitations(this.announcement);
    this.dates$ = this.dispoFocus.dates();
    this.expanded$ = this.localSettings.get(
      `dispo.announcementExpanded.${this.announcement.id}`,
      false,
    );
  }

  // REFACTOR: same as dispo-tasks/task.component#onDestroy
  ngOnDestroy() {
    if (this.selectable) {
      this.selectable.stop();
    }
    this.elementRef.nativeElement.removeEventListener(
      'dragstart',
      this.onDragStart,
    );
    this.elementRef.nativeElement.removeEventListener('drop', this.onDrop);
    this.elementRef.nativeElement.removeEventListener(
      'dragend',
      this.onDragEnd,
    );
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // REFACTOR: similar to dispo-tasks/task.component#contextmenu
  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMenu($event) {
    const slotTarget = $event
      .composedPath()
      .find((item) => item.classList && item.classList.contains('selectable'));

    if (
      $event.type === 'contextmenu' &&
      (this.selectable.getSelection().length === 0 ||
        this.selectable.getSelection().indexOf(slotTarget) === -1)
    ) {
      if (slotTarget) {
        this.selectable.setSelection([slotTarget], true);
      } else {
        this.selectable.setSelection([], true);
      }

      this.active = true;
      this.tcSelect.emit('started');
      this.active = false;
    }

    const slots = this.selectable.getSelection().map((el) => {
      return Object.assign(new SlotPos(), {
        x: parseInt(el.dataset.hslot, 10),
        y: 0,
        task_id: parseInt(el.dataset.taskid, 10),
        invitation_id: parseInt(el.dataset.invitationid, 10),
        assignment_id: parseInt(el.dataset.assignmentid, 10),
        announcement_id: this.announcement.id,
      });
    });

    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    let invitationSlots = slots.filter((s) => s.invitation_id);
    const invitations = [
      ...new Set(
        invitationSlots.map((slot) => {
          return this.invitations.find((i) => i.id === slot.invitation_id);
        }),
      ),
    ];
    const employees = invitations
      .map((i: any) => i.resource)
      .filter((r) => r && r.type === 'Employee');

    invitationSlots = invitationSlots.filter((s) => s.x);
    if (invitationSlots.length > 0) {
      $event.menu_contexts.push({
        item_type: 'DispoInvitationSlot',
        item: invitationSlots,
      });
    }

    if (invitations.length > 0) {
      $event.menu_contexts.push({
        item_type: 'DispoInvitation',
        item: invitations,
      });
    }

    if (employees.length > 0) {
      $event.menu_contexts.push({
        item_type: 'Employee',
        item: employees,
      });
    }

    $event.menu_contexts.push({
      item_type: 'DispoAnnouncement',
      item: [this.announcement],
    });

    const taskSlots = slots.filter((s) => s.task_id);
    const tasks = [
      ...new Set(
        taskSlots.map((slot) => {
          return this.tasks.find((t) => t.id === slot.task_id);
        }),
      ),
    ];

    if (tasks.length > 0) {
      $event.menu_contexts.push({
        item_type: 'DispoTask',
        item: tasks,
      });

      const slotItems = [];
      tasks.forEach((task: any) => {
        slotItems.push(this.slotFor(task));
      });

      $event.menu_contexts.push({
        item_type: 'DispoAnnouncementSlot',
        item: slotItems,
      });
    }
  }

  onVisible() {
    if (this.visible) {
      return;
    }
    this.visible = true;

    this.subs.push(
      this.invitations$.subscribe({
        next: (invitations) => {
          this.invitations = invitations;
        },
      }),
    );

    this.subs.push(
      this.tasks$.subscribe({
        next: (tasksConfig) => {
          const tasks = [];
          tasksConfig.forEach((taskConfig) => {
            if (taskConfig.task) {
              tasks.push(taskConfig.task);
            }
          });

          this.tasks = tasks;
        },
      }),
    );

    setTimeout(() => {
      this.init();
    }, 1000);
  }

  init() {
    this.elementRef.nativeElement.addEventListener(
      'dragstart',
      this.onDragStart,
    );
    this.elementRef.nativeElement.addEventListener('dragend', this.onDragEnd);
    this.elementRef.nativeElement.addEventListener('drop', this.onDrop);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.selectable = new DragSelect({
      selectables: [],
      area: this.elementRef.nativeElement.querySelector('.select-content'),
      selectedClass: 'selected',
      onDragStartBegin: function (event) {
        if (that.loading) {
          this.break();
        }
        const nodes = this.getSelection();

        if (nodes.indexOf(event.target) > -1) {
          this.break();
          event.target.setAttribute('draggable', true);
        } else {
          event.target.setAttribute('draggable', false);
        }
      },
      onDragStart: function (event) {
        that.active = true;
        that.tcSelect.emit('started');
      },
      callback: function (slots) {
        that.onSelect(slots);
        that.active = false;
      },
    });

    // performance improvement
    // build a grid based on subscription to announcement.size / dates
    // only update selectables after this grid changes (keys, size)
    // then hand it over to service to combine it with the data
    this.subs.push(
      this.tasks$.pipe().subscribe((tasks) => {
        this.updateSelectables();
      }),
    );

    this.subs.push(
      this.updateSelectables$
        .pipe(
          // throttleTime(100)
          debounceTime(500),
        )
        .subscribe(() => {
          // do not update selectables, but use classes to prevent selection...
          const elements = [].slice.call(
            this.elementRef.nativeElement.querySelectorAll('.selectable'),
          );
          const selectables = this.selectable.selectables;
          const addToSelectables = elements.filter(
            (e) => selectables.indexOf(e) === -1,
          );
          const removeFromSelectables = selectables.filter(
            (s) => elements.indexOf(s) === -1,
          );

          this.selectable.removeSelectables(removeFromSelectables);
          this.selectable.addSelectables(addToSelectables);
          this.selectable.setSelection([]);
        }),
    );

    this.subs.push(
      this.loadingService
        .loading('announcement', this.announcement.id)
        .pipe(distinctUntilChanged())
        .subscribe((loading) => {
          this.loading = loading;
          this.cd.markForCheck();
        }),
    );

    this.updateSelectables();
  }

  slotFor(task) {
    const slotItem = {
      announcement_id: this.announcement.id,
    };

    slotItem[task.date] = true;

    return slotItem;
  }

  // REFACTOR: same as dispo-tasks/task.component#clearSelection
  clearSelection() {
    if (!this.active) {
      this.selectable?.clearSelection();
    }
  }

  // REFACTOR: same as dispo-tasks/task.component#updateSelectables
  updateSelectables() {
    this.updateSelectables$.next(true);
  }

  onTasksChange(e) {
    // this.updateSelectables();
  }

  // REFACTOR: same as dispo-tasks/task.component#onSelect
  private onSelect(elements) {
    const slots = elements.map((el) => {
      return Object.assign(new SlotPos(), {
        x: parseInt(el.dataset.hslot, 10),
        y: parseInt(el.dataset.vslot, 10),
      });
    });

    this.service.handleSelect(this.announcement, slots);
  }

  onToggleAnnouncement() {
    this.localSettings.toggle(
      `dispo.announcementExpanded.${this.announcement.id}`,
      false,
    );
    this.updateSelectables();
  }

  // REFACTOR: similar to  dispo-tasks/task.component#onDragStart
  private onDragStart(e) {
    const dragType = e.target.getAttribute('drag');

    if (dragType === 'timecount/dispo_invitations') {
      const slots = [];
      const action = e.metaKey || e.ctrlKey || e.altKey ? 'create' : 'update';

      this.selectable.getSelection().map((el) => {
        slots.push({
          x: parseInt(el.dataset.hslot, 10),
          y: parseInt(el.dataset.vslot, 10),
        });
      });

      e.dataTransfer.effectAllowed = 'all';
      e.dataTransfer.setData('drag', dragType);
      e.dataTransfer.setData(
        dragType,
        JSON.stringify({
          announcement: this.announcement,
          slots: slots,
          x: parseInt(e.srcElement.dataset.hslot, 10),
          y: parseInt(e.srcElement.dataset.vslot, 10),
          action: action,
        }),
      );
    }

    if (dragType === 'timecount/resources') {
      const resource = e
        .composedPath()
        .find(
          (node) =>
            node.dataset &&
            node.dataset.resource_type &&
            node.dataset.resource_id,
        );

      if (!resource) {
        return;
      }

      e.dataTransfer.effectAllowed = 'all';
      e.dataTransfer.setData('drag', dragType);
      e.dataTransfer.setData(
        dragType,
        JSON.stringify({
          resource: {
            type: resource.dataset.resource_type,
            id: parseInt(resource.dataset.resource_id, 10),
          },
          action: 'create',
        }),
      );
    }
  }

  // REFACTOR: similar to  dispo-tasks/task.component#onDrop
  private onDrop(e) {
    if (isDroppable(e)) {
      e.preventDefault();

      if (e.stopPropagation) {
        e.stopPropagation();
      }

      const source_type = e.dataTransfer.getData('drag');
      let data = e.dataTransfer.getData(source_type);
      if (data) {
        data = JSON.parse(data);
      } else {
        return false;
      }

      const slot = e
        .composedPath()
        .find(
          (node) => node.dataset && node.dataset.hslot && node.dataset.vslot,
        );

      if (!slot) {
        return;
      }
      const toPosition = slot
        ? {
            x: parseInt(slot.dataset.hslot, 10),
            y: parseInt(slot.dataset.vslot, 10),
          }
        : {};

      this.service.handleInsert(
        source_type,
        data,
        this.announcement,
        toPosition,
      );
      this.active = false;
      this.clearSelection();
      this.updateSelectables();

      return true;
    }
  }

  // REFACTOR: same as dispo-tasks/task.component#onDragEnd
  private onDragEnd(e) {
    e.preventDefault();
  }

  trackByDateAndId(index, item) {
    return item.date + item.id;
  }
}
