<div class="view">
  <div class="view-header">
    <header class="header-bar">
      <div class="header-bar-title">
        <h2 class="header-bar-headline">
          <span class="title">{{ 'dispo.singular' | translate }}</span>
          <i class="separator" nz-icon nzType="right" nzTheme="outline"></i>
          <span class="additional"
            >{{ begin$ | async | date : 'd. MMMM' }} -
            {{ end$ | async | date : 'd. MMMM yyyy' }}</span
          >
        </h2>
      </div>
      <div class="header-bar-controls">
        <tc-hub-dispo-date
          class="header-bar-control"
          (dateChange)="onDateChange($event)"
        ></tc-hub-dispo-date>
        <tc-hub-dispo-view-settings
          class="header-bar-control"
        ></tc-hub-dispo-view-settings>

        <div>
          <button
            nz-button
            nz-dropdown
            [nzDropdownMenu]="menu"
            nzTrigger="click"
            nzClickHide="true"
            nzBackdrop="false"
          >
            {{ 'dispo.add_item' | translate }}
            <i nz-icon nzType="down"></i>
          </button>

          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li
                nz-menu-item
                *tcHubHide="'views.dispo.import.create'"
                (click)="createImport()"
              >
                <span>
                  <i nz-icon nzType="import"></i>
                  {{ 'dispo/import.actions.create' | translate }}
                </span>
              </li>

              <li
                nz-menu-item
                *tcHubHide="'views.dispo.plan.create'"
                (click)="createPlan()"
              >
                <span>
                  <i nz-icon nzType="apartment"></i>
                  {{ 'dispo/plan.actions.create' | translate }}
                </span>
              </li>

              <li
                nz-menu-item
                *tcHubHide="'views.dispo.task.create'"
                (click)="createTask()"
              >
                <span>
                  <i nz-icon nzType="group"></i>
                  {{ 'dispo/task.actions.create' | translate }}
                </span>
              </li>

              <li
                nz-menu-item
                *tcHubHide="'views.dispo.schedule.create'"
                (click)="createSchedule()"
              >
                <span>
                  <i nz-icon nzType="calendar"></i>
                  {{ 'dispo/schedule.actions.create' | translate }}
                </span>
              </li>

              <li
                nz-menu-item
                *tcHubHide="'views.dispo.message.create'"
                (click)="createMessage()"
              >
                <span>
                  <i nz-icon nzType="mail"></i>
                  {{ 'dispo/message.actions.create' | translate }}
                </span>
              </li>

              <li
                nz-menu-item
                *ngFor="let tour of tours$ | async"
                (click)="openTour(tour)"
              >
                <span>
                  <i nz-icon nzType="question"></i> {{ tour.label }}
                </span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
    </header>
  </div>
  <div class="viewport">
    <ng-container *ngIf="views.length === 0">
      <h1
        style="
          font-size: 100px;
          position: absolute;
          top: 50%;
          z-index: 5;
          left: 50%;
          margin-left: -50px;
          margin-top: -50px;
        "
      >
        <i nz-icon [nzType]="'loading'"></i>
      </h1>
    </ng-container>
    <as-split direction="horizontal">
      <ng-container *ngFor="let view of views; let last = last">
        <as-split-area [size]="sizeFor(view)">
          <tc-hub-dispo-assignments
            class="view"
            *ngIf="view === 'dispo.assignments'"
          ></tc-hub-dispo-assignments>
          <tc-hub-finances-entries
            class="view"
            *ngIf="view === 'finances.entries'"
          ></tc-hub-finances-entries>
          <tc-hub-dispo-tasks
            class="view"
            *ngIf="view === 'dispo.tasks'"
          ></tc-hub-dispo-tasks>
          <tc-hub-dispo-employees
            class="view"
            *ngIf="view === 'dispo.employees'"
          ></tc-hub-dispo-employees>
          <tc-hub-dispo-employees-condensed
            class="view"
            *ngIf="view === 'dispo.employees_condensed'"
          ></tc-hub-dispo-employees-condensed>
          <tc-hub-dispo-schedules
            class="view"
            *ngIf="view === 'dispo.schedules'"
          ></tc-hub-dispo-schedules>
          <tc-hub-dispo-announcements
            class="view"
            *ngIf="view === 'dispo.announcements'"
          ></tc-hub-dispo-announcements>
          <tc-hub-dispo-action-menu
            *ngIf="view === 'dispo.actions'"
          ></tc-hub-dispo-action-menu>
        </as-split-area>
      </ng-container>
    </as-split>
  </div>
</div>

<tc-hub-tour section="dispo.tour.intro"></tc-hub-tour>
<tc-hub-dispo-context-menu></tc-hub-dispo-context-menu>
