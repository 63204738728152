<fieldset
  class="group-wrapper"
  [class.is-horizontal]="tcIsHorizontal"
  [class.is-compact]="tcIsCompact"
  [class.is-disabled]="innerForm?.disabled"
>
  <ng-container *ngIf="tcIsHorizontal; else panelTemplate">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="durationTemplate"></ng-container>
  </ng-container>

  <ng-template #panelTemplate>
    <div [tcPanel]>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      <label tcFooterStart *ngIf="!tcHideLabels" for="duration"
        >{{ 'dispo/task.attrs.duration' | translate }}:
      </label>
      <p tcFooterEnd>
        <ng-container *ngTemplateOutlet="durationTemplate"></ng-container>
      </p>
    </div>
  </ng-template>
</fieldset>

<ng-template #contentTemplate>
  <div class="interval-fields" [formGroup]="innerForm">
    <tc-interval-web
      class="group-item"
      formControlName="mainInterval"
      [tcFieldType]="'time'"
      [tcIsCompact]="tcIsCompact"
      [tcAllowZeroDuration]="true"
      [tcFieldWithDateOptions]="null"
      [tcConfig]="{
        relativeDateOptions: tcConfig.relativeDateOptions,
        required: tcConfig.required,
        fieldType: 'time'
      }"
      [tcRefDate]="tcReferenceDate"
      [tcLabels]="tcHideLabels ? null : 'default'"
    ></tc-interval-web>

    <tc-interval-web
      class="group-item"
      formControlName="breakInterval"
      [class.is-invalid]="
        innerForm?.controls.breakInterval?.errors !== null &&
        innerForm?.controls.breakInterval?.invalid &&
        !innerForm?.controls.breakInterval?.errors?.required
      "
      [tcFieldType]="'time'"
      [tcIsCompact]="tcIsCompact"
      [tcAllowZeroDuration]="true"
      [tcFieldWithDateOptions]="'start'"
      [tcConfig]="{
        relativeDateOptions: tcConfig.relativeDateOptions,
        limit: innerForm.controls['mainInterval']?.value,
        fieldType: 'time'
      }"
      [tcRefDate]="innerForm?.value?.mainInterval?.start ?? tcReferenceDate"
      [tcLabels]="
        tcHideLabels
          ? null
          : {
              start: 'dispo/task.attrs.intermission_starts_at' | translate,
              end: 'dispo/task.attrs.intermission_ends_at' | translate
            }
      "
    ></tc-interval-web>
  </div>
</ng-template>

<ng-template #durationTemplate>
  <span class="duration" id="duration">{{ getDuration() | duration }}</span>
</ng-template>
