import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { SplitButton } from 'primeng/splitbutton';

@Component({
  selector: 'tc-hub-base-dropdown',
  templateUrl: './base-dropdown.component.html',
  styleUrls: ['./base-dropdown.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        }),
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        }),
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}')),
    ]),
  ],
})
export class BaseDropdownComponent extends SplitButton {
  @Input() iconPos = 'right';

  // items$: BehaviorSubject<any> = new BehaviorSubject({label: "NO ENTRIES"});

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public router: Router,
    public cd: ChangeDetectorRef,
  ) {
    super(el, renderer, router, cd);
  }
}
