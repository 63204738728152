import {
  Component,
  OnDestroy,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroupName } from '@angular/forms';

import { TcFormGroupTimeSetBaseDirective } from '../form-group-time-set-base.directive';
import { TcFormGroupTimeSetService } from '../form-group-time-set.service';
import { TcFormGroupTimeSetComponentState } from '../form-group-time-set.state';

@Component({
  selector: 'tc-form-group-time-set-ionic',
  templateUrl: './form-group-time-set-ionic.component.html',
  styleUrls: ['./form-group-time-set-ionic.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [TcFormGroupTimeSetComponentState],
})
export class TcFormGroupTimeSetIonicComponent
  extends TcFormGroupTimeSetBaseDirective
  implements OnInit, OnDestroy
{
  constructor(
    @Optional() public formGroupName: FormGroupName,
    public state: TcFormGroupTimeSetComponentState,
    public timeSetService: TcFormGroupTimeSetService,
  ) {
    super(formGroupName, state, timeSetService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
