import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TcConfigService, TcIntervalWithBreak } from '@timecount/core';

import {
  TcFormGroupIntervalComponentConfig,
  TcFormGroupTimeSetService,
} from '../../form-groups';
import { asyncStatusOfControl } from '../../shared';

import { TcFieldSetIntervalSetService } from './interval-set.service';

@Component({
  selector: `
    tc-interval-set,
    tc-interval-set-web,
    tc-interval-set-ionic,
  `,
  templateUrl: './interval-set.component.html',
  styleUrls: ['./interval-set.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TcFieldsetIntervalSetComponent,
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TcFieldsetIntervalSetComponent,
      multi: true,
    },
  ],
})
export class TcFieldsetIntervalSetComponent
  implements ControlValueAccessor, OnChanges, OnDestroy, OnInit
{
  // TODO: Replace with dynamic templates and styles.
  @Input() platform: 'ionic' | 'web' = 'web';

  @Input() tcConfig: TcFormGroupIntervalComponentConfig = {};

  /**
   * The date to be used as a reference for the intervals time inputs.
   *
   * It's ignored if `tcShowDateInput` is true.
   */
  @Input() tcReferenceDate: Date;

  /**
   * When true, a separated date input will be added to the fieldset and the
   * start of the first or main intervals will have its Date object defined by
   * the value of this input, instead of the `tcReferenceDate` value.
   */
  @Input() tcShowDateInput = false;

  /**
   * When true, a separated date input will be added to the fieldset and the
   * start of the first or main intervals will have its Date object defined by
   * the value of this input, instead of the `tcReferenceDate` value.
   */
  @Input() tcHideLabels = false;

  /**
   * Whether or not the fields in the fieldset should be presented horizontally,
   * so that they can fit in a table row, for example.
   */
  @Input()
  @HostBinding('class.is-horizontal')
  tcIsHorizontal = false;

  /**
   * Whether or not the fields in the fieldset should be presented with a
   * compact style so that they can fit in a table row, for example.
   */
  @Input()
  @HostBinding('class.is-compact')
  tcIsCompact = false;

  /**
   * The label to be used in the date input, if present.
   *
   * It's ignored if `tcHideLabels` is true.
   */
  @Input() tcDateLabel: string;

  // Both status and value change outputs are required when using in legacy
  @Output() tcStatusChange = new EventEmitter();
  @Output() tcValueChange = new EventEmitter();

  innerForm: UntypedFormGroup;
  controlName = 'times';

  timesConfig = this.configService.config.company.times;

  /**
   * Required when using as a downgraded component in AngularJS to store the
   * initial value to be used when the form is created, as the `ngOnInit` is
   * called after the `writeValue` in that case.
   */
  private _initialValue: unknown;

  private _onChange: (_: unknown) => void;
  private _onTouched: () => void;

  private _destroyed$ = new Subject<void>();

  constructor(
    private fieldSetIntervalSetService: TcFieldSetIntervalSetService,
    private configService: TcConfigService,
    private timeSetService: TcFormGroupTimeSetService,
    private elementRef: ElementRef,
  ) {
    this._setPlatform();
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    const { tcConfig, tcIsHorizontal, tcIsCompact, tcHideLabels } = changes;

    if (tcConfig || tcIsHorizontal || tcIsCompact || tcHideLabels) {
      this.tcConfig = {
        ...this.tcConfig,
        maxDuration: this.timesConfig.maxTotalDuration,
        isHorizontal: this.tcIsHorizontal,
        isCompact: this.tcIsCompact,
        hideLabels: this.tcHideLabels,
      };
    }
  }

  ngOnInit(): void {
    this._setForm();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // ControlValueAccessor Implementation
  // ---------------------------------------------------------------------------

  writeValue(value: unknown): void {
    this._initialValue = value;

    const emptyValue = this.timesConfig.allowMultiBreaks ? [] : undefined;

    if (this.innerForm instanceof UntypedFormGroup) {
      this.innerForm.get(this.controlName).reset(value ?? emptyValue);

      if (this.tcShowDateInput) {
        this.innerForm
          .get('date')
          .reset(
            this.fieldSetIntervalSetService.getWholeSetInterval(value)?.start,
          );
      }
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    if (this.innerForm instanceof UntypedFormGroup) {
      this._setValueChangeHandler(fn);
    } else {
      this._onChange = fn;
    }
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.innerForm?.disable() : this.innerForm?.enable();
  }

  // ---------------------------------------------------------------------------
  // Validator Implementation
  // ---------------------------------------------------------------------------

  validate(): Promise<ValidationErrors | null> {
    return asyncStatusOfControl(this.innerForm, {
      needsTimeOut: true,
    });
  }

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  private _setPlatform(): void {
    const tagName = this.elementRef.nativeElement.tagName
      .toLowerCase()
      .substring('tc-interval-set-'.length);

    if (tagName?.length) {
      this.platform = tagName;
    }
  }

  private _setForm(): void {
    this.innerForm = new UntypedFormGroup({
      [this.controlName]:
        this.platform === 'web' ||
        this.configService.config.company.times.allowMultiBreaks
          ? new UntypedFormControl(this._initialValue)
          : this.timeSetService.getTimeSetFormGroup({
              ...this.tcConfig,
              initialValue: this._initialValue as TcIntervalWithBreak,
            }),
    });

    if (this.tcShowDateInput) {
      this.tcReferenceDate =
        this.fieldSetIntervalSetService.getWholeSetInterval(this._initialValue)
          ?.start as Date;

      const dateControl = new UntypedFormControl({
        value: this.tcReferenceDate,
      });
      dateControl.valueChanges
        .pipe(takeUntil(this._destroyed$))
        .subscribe((date) => {
          this.tcReferenceDate = date;
        });

      this.innerForm.addControl('date', dateControl);
    }

    if (this._onChange) {
      this._setValueChangeHandler(this._onChange);
    }

    this.innerForm.statusChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((status) => {
        this.tcStatusChange.emit(status);
      });

    // Initial emission for the legacy output
    this.tcStatusChange.emit(this.innerForm.status);
  }

  private _setValueChangeHandler(fn: (_: unknown) => void): void {
    this.innerForm.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value: unknown) => {
        const times = value[this.controlName];

        this.tcValueChange.emit(times);
        fn(times);
      });
  }
}
