// Zone.js flags for disable some zone.js feature for performance improvement

(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

// (window as any).__Zone_disable_timers = true;
// (window as any).__Zone_disable_EventTarget = true;
// (window as any).__Zone_disable_ZoneAwarePromise = true;
// (window as any).__Zone_disable_blocking = true;
// (window as any).__Zone_disable_geolocation = true;
// (window as any).__Zone_disable_toString = true;
// (window as any).__Zone_disable_XHR = true;
