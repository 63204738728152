import {
  Component,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IonPopover } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { min, parseISO } from 'date-fns';

import {
  findMatchingSupportedLanguage,
  SupportedLanguageIsoCode,
} from '@timecount/locale';
import {
  DateUnicodeFormat,
  getSteppedNumbersArray,
  isValidDate,
} from '@timecount/utils';

import { TcDateTimePickerType } from '../../../shared/form-pickers/form-picker.model';
import { TcInputDateTimeConfig } from '../../input/input-datetime.config';
import { TcInputTimeDateOptionsDirective } from '../../../shared';
import { TcInputService } from '../../input/input.service';

@Component({
  selector: 'tc-date-time-picker-ionic',
  templateUrl: './date-time-picker-ionic.component.html',
  styleUrls: ['./date-time-picker-ionic.component.scss'],
})
export class TcDateTimePickerIonicComponent
  implements ControlValueAccessor, OnChanges
{
  /**
   * Overrides the current date value when popping up a the picker for the first
   * time.
   */
  @Input()
  tcInitialValue = new Date();

  @Input()
  fieldType: TcDateTimePickerType;

  @Input()
  localeIsoCode: SupportedLanguageIsoCode;

  @Input()
  set tcConfig(value: TcInputDateTimeConfig) {
    this._tcConfig = value;

    this.stepMinutesArray = getSteppedNumbersArray(value.timePickerSteps);
  }
  get tcConfig(): TcInputDateTimeConfig {
    return this._tcConfig;
  }
  private _tcConfig: TcInputDateTimeConfig;

  @ViewChild('popover', { static: false })
  ionPopover?: IonPopover;

  public get buttonDisplayFormat(): string {
    return DateUnicodeFormat[this.fieldType];
  }

  public stepMinutesArray: number[];

  // TODO: Replace this with a config object by key
  public get placeholder(): string {
    switch (this.fieldType) {
      case 'time':
        return '__:__';

      case 'date':
        return '__.__.____';

      case 'dateTime':
        return '__.__.____ __:__';

      default:
        return undefined;
    }
  }

  public max: Date;
  public min: Date;

  public set value(value: unknown) {
    this._value = value as Date;
    this.onChange(this._value);
  }
  public get value(): unknown {
    return this._value;
  }
  public _value: Date;

  public isOpen = false;

  public isDisabled = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange = (_) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  constructor(
    public ngControl: NgControl,
    public inputService: TcInputService,
    private translateService: TranslateService,
    @Optional() public dateOptionsDir?: TcInputTimeDateOptionsDirective,
  ) {
    this.ngControl.valueAccessor = this;

    this.localeIsoCode = findMatchingSupportedLanguage(
      this.translateService.currentLang,
    ).isoCode;
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnChanges({ tcConfig, fieldType }: SimpleChanges): void {
    if (tcConfig) {
      const { limit, max, min } = tcConfig.currentValue;
      const { start, end } = limit ?? {};

      // HACK: Ionic's DateTime ignores the dates from min and max values when
      // pickers are time only, so we need to have means to avoid seeting them
      // when this might be the case.
      this.min = this.fieldType !== 'time' && isValidDate(start) ? start : min;
      this.max = this.fieldType !== 'time' && isValidDate(end) ? end : max;
    }

    if (tcConfig || fieldType) {
      //TODO: avoid duplicating the setValidators also in the input-base.directive.ts
      this.ngControl?.control?.setValidators(
        this.inputService.getInputValidators({
          ...this.tcConfig,
          fieldType: fieldType?.currentValue ?? this.fieldType,
        }),
      );

      this.ngControl?.control?.updateValueAndValidity({ emitEvent: false });
    }
  }

  // ---------------------------------------------------------------------------
  // ControlValueAccessor Implementation
  // ---------------------------------------------------------------------------

  writeValue(value: unknown): void {
    this._value = value as Date;
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // ---------------------------------------------------------------------------
  // Events Handlers
  // ---------------------------------------------------------------------------

  openDateTimePicker(event: Event): void {
    if (this.isDisabled) {
      return;
    }

    const limits = [this.min, this.max].filter(isValidDate);

    if (!isValidDate(this.value) && limits.length) {
      this.value = min(limits);
    }

    this.ionPopover.event = event;
    this.isOpen = true;
  }

  onIonChange(ionChange: CustomEvent) {
    const possibleDate = parseISO(ionChange.detail.value);

    if (this.dateOptionsDir?.shouldShowDateOptions) {
      this.dateOptionsDir.onDateSelect(possibleDate);
    }

    this.value = isValidDate(possibleDate) ? possibleDate : undefined;
  }
}
