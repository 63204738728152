import {
  Directive,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormGroupName } from '@angular/forms';

import { isValidDate } from '@timecount/utils';

import { TcFormGroupIntervalComponentConfig } from './form-group-interval.config';
import { TcFormGroupIntervalService } from './form-group-interval.service';
import { TcFormGroupIntervalComponentState } from './form-group-interval.state';

@Directive()
export abstract class TcFormGroupIntervalBaseDirective {
  /**
   * The component's configuration
   */
  @Input()
  set tcConfig(config: TcFormGroupIntervalComponentConfig) {
    const { max, min, limit } = config;

    if (!isValidDate(min) && isValidDate(limit?.start)) {
      config.min = limit?.start as Date;
    }

    if (!isValidDate(max) && isValidDate(limit?.end)) {
      config.max = limit?.end as Date;
    }

    this.state.set({ ...config });
  }

  @Input()
  set tcRefDate(refDate: Date) {
    this.state.set({ refDate });
  }

  @Input()
  set tcFieldType(fieldType: 'date' | 'time' | 'dateTime') {
    this.state.set({ fieldType });
  }

  /**
   * The interval that should be used by the inputs to define the date options.
   */
  @Input()
  set tcDateOptionsInterval(dateOptionsInterval: Interval) {
    this.state.set({ dateOptionsInterval });
  }

  /**
   * Set the interval's `start` and `end` labels.
   *
   * If not set, the input fields will not have labels.
   * It can also be set to use the 'default' labels.
   */
  @Input()
  set tcLabels(labels: { start: string; end: string } | 'default') {
    if (labels === 'default') {
      this.state.setDefaultLabels();
    } else {
      this.state.set({ labels });
    }
  }

  @Output() tcValueChange = new EventEmitter();

  readonly viewState$ = this.state.state$;

  constructor(
    public state: TcFormGroupIntervalComponentState,
    public intervalService: TcFormGroupIntervalService,
    @Optional() public formGroupName?: FormGroupName,
  ) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit(): void {
    this._setFormGroup();
  }

  // --------------
  // Public Methods
  // --------------

  get startLimit(): Partial<Interval> {
    const { startLimit, startInputLimit } = this.state.get();

    return startLimit || startInputLimit;
  }

  get endLimit(): Partial<Interval> {
    const { endLimit, endInputLimit } = this.state.get();

    return endLimit || endInputLimit;
  }

  // ---------------
  // Private Methods
  // ---------------

  private _setFormGroup() {
    const formGroup =
      this.formGroupName?.control ??
      this.intervalService.getIntervalFormGroup(
        this.state.get() as TcFormGroupIntervalComponentConfig,
      );

    if (!formGroup.get('start') || !formGroup.get('end')) {
      throw new Error(`
        The TcFormGroupIntervalComponent requires the provided [formGroupName]
        FormGroup to have a 'start' and an 'end' controls.
      `);
    }

    formGroup.valueChanges.subscribe((value) => {
      this.tcValueChange.emit(value);
    });

    // TODO: Find a way to avoid having to store the config in the FormGroup
    if (formGroup.get('config')) {
      const config = formGroup.get('config').value;

      this.state.set({ ...config });
    }

    // Use the control of the provided FormGroupName
    this.state.set({ formGroup });
  }
}
