<ng-container *ngIf="visible">
  <tc-hub-data-panel
    [data]="task$ | async"
    [actions]="availableWidgetActions"
    section="dispo.tasks.task"
    class="task-stats"
  >
  </tc-hub-data-panel>
  <div
    class="header"
    [ngClass]="{
      shallow: task.shallow,
      schedule: task.schedule_id,
      loading: loading
    }"
    (mouseup)="clearSelection()"
  >
    <div class="title-wrapper">
      <div class="title">
        <tc-hub-toggle
          class="content-toggle"
          [active$]="expanded$"
          (mouseup)="onToggleTask($event)"
        ></tc-hub-toggle>
        <h5>
          {{ task.title }}
          <ng-container *ngIf="(task$ | async).venue">
            @ {{ (task$ | async).venue.title }}
          </ng-container>
        </h5>
      </div>
    </div>
    <div class="summary-wrapper">
      <tc-times-summary-web
        class="times-summary"
        [expanded]="true"
        [intervalList]="timesList"
        [isCompact]="true"
      ></tc-times-summary-web>
    </div>
  </div>

  <div
    #content
    class="content select-root"
    [class.collapsed]="(expanded$ | async) === false"
    [class.loading]="loading"
  >
    <div class="note" *ngIf="task.note">
      <tc-hub-markdown
        [data]="task.note"
        (update)="onMarkdownUpdate($event)"
      ></tc-hub-markdown>
    </div>
    <ng-container *ngIf="((slots$ | async) || []).length">
      <div class="slots">
        <ng-container *ngFor="let slot of slots$ | async; trackBy: trackByPos">
          <div class="slots-header" *ngIf="slot.position === task.size">
            <span>{{ 'dispo/task.overflow' | translate }}</span>
          </div>
          <div
            class="slot"
            [tcAssignmentStateClasses]="
              slot.responses
                | feedbackRequestsOnStatus
                  : ['urgent']
                  : ['attend', 'onsite_checkin']
            "
            drag="timecount/dispo_assignments"
            [ngClass]="{
              overloaded: slot.overloaded,
              selectable: !slot.blank,
              blank: slot.blank,
              missing: slot.missing
            }"
            [attr.data-hslot]="hslot()"
            [attr.data-vslot]="slot.position"
          >
            <span class="slot-position">{{ slot.position + 1 }}</span>
            <div class="slot-content">
              <div>
                <span class="slot-title">{{ slot.resource_title }}</span>
              </div>
              <div class="slot-meta">
                <tc-hub-data-panel
                  [data]="slot"
                  section="dispo.tasks.assignment"
                  class="assignment-stats"
                ></tc-hub-data-panel>
                <tc-hub-dispo-slot-labels
                  [slot]="slot"
                ></tc-hub-dispo-slot-labels>
              </div>
            </div>
            <div
              class="slot-status"
              *ngIf="
                slot.overloaded ||
                slot.note ||
                slot.invited ||
                slot.tracked ||
                slot.requested ||
                slot.gratis ||
                (slot.responses
                  | hasFeedbackRequestsOnStatus : ['responded', 'expired'])
              "
            >
              <span
                class="info"
                *ngIf="slot.overloaded"
                nz-tooltip
                nzTooltipTitle="{{
                  'dispo/assignment.tooltip.overloaded' | translate
                }}"
                ><i nz-icon nzType="warning" nzTheme="fill"></i
              ></span>
              <span
                class="info"
                *ngIf="slot.note"
                nz-tooltip
                nzTooltipTitle="{{ slot.note }}"
                ><i nz-icon nzType="info-circle"></i
              ></span>
              <span
                class="info"
                *ngIf="slot.invited"
                nz-tooltip
                nzTooltipTitle="{{
                  'dispo/assignment.tooltip.invited' | translate
                }}"
                ><i nz-icon nzType="link"></i
              ></span>
              <span
                class="info"
                *ngIf="slot.tracked"
                [ngClass]="{
                  'times-confirmed': slot.times_state === 'confirmed',
                  'times-not-confirmed': slot.times_state === 'unconfirmed'
                }"
                nz-popover
                [nzPopoverContent]="trackedDetails"
                nzPopoverPlacement="bottom"
                nzpop
                ><i nz-icon nzType="clock-circle" nzTheme="fill"></i
              ></span>
              <span
                class="info"
                *ngIf="slot.gratis"
                nz-tooltip
                nzTooltipTitle="{{
                  'dispo/assignment.tooltip.gratis' | translate
                }}"
                ><i
                  nz-icon
                  nzType="dollar"
                  style="color: rgb(202, 3, 3)"
                  nzTheme="outline"
                ></i
              ></span>

              <span
                *ngIf="requestWithHighestPriority(slot.responses); let request"
                class="info"
                [class.unconfirmable]="!slot.confirmable"
                nz-tooltip
                nzTooltipTrigger="hover"
                nzTooltipTitle="{{
                  'dispo/assignment.tooltip.' +
                    (!slot.confirmable
                      ? 'not_confirmable'
                      : request.type + '_' + request.status) | translate
                }}"
              >
                <i
                  nz-icon
                  [nzType]="
                    request.type === 'onsite_checkin'
                      ? 'environment'
                      : request.type === 'read'
                      ? !slot.confirmable
                        ? 'meh'
                        : 'wifi'
                      : 'carry-out'
                  "
                  [tcAssignmentStateClasses]="[request]"
                ></i>
              </span>
            </div>
            <ng-template #trackedDetails>
              <div class="details-popover">
                <span>{{
                  'dispo/assignment.tooltip.tracked' | translate
                }}</span>
                <tc-times-summary-web
                  [expanded]="true"
                  [intervalList]="slot.times"
                  [isCompact]="true"
                  [isTracked]="true"
                ></tc-times-summary-web>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!visible">
  <div
    tcHubObserveVisibility
    [debounceTime]="300"
    [threshold]="0.05"
    (visible)="onVisible()"
  >
    <div class="task-stats">...</div>
    <div class="header">
      <div>
        <div class="title">
          <h5>
            {{ task.title }}
          </h5>
        </div>
      </div>
      <div>
        <div class="times">
          <div>
            <b>...</b>
          </div>
          <div>
            <b>&nbsp;</b>
          </div>
        </div>
      </div>
    </div>
    <div class="content" [class.collapsed]="(expanded$ | async) === false">
      <div class="note" *ngIf="task.note">
        <tc-hub-markdown [data]="task.note"></tc-hub-markdown>
      </div>
      <ng-container *ngIf="((slots$ | async) || []).length">
        <div class="slots">
          <ng-container
            *ngFor="let slot of slots$ | async; trackBy: trackByPos"
          >
            <div class="slot">
              <span class="slot-position">{{ slot.position + 1 }}</span>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
