import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AssignmentsModule } from './resources';

@NgModule({
  imports: [CommonModule, AssignmentsModule],
  exports: [AssignmentsModule],
})
export class TcCoreModule {}
