import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AsyncValidator,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  UntypedFormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { asyncStatusOfControl, ValueAccessorDirective } from '@timecount/ui';
import { DateUnicodeFormat, parseDate } from '@timecount/utils';

import { Aggregation } from '../../aggregation.model';
import { AggregationType } from '../../aggregation_type.model';

@Component({
  selector: 'tc-hub-aggregations-schedule-form',
  templateUrl: './aggregations-schedule-form.component.html',
  styleUrls: ['./aggregations-schedule-form.component.scss'],
  hostDirectives: [ValueAccessorDirective],
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TcAggregationsScheduleFormComponent,
      multi: true,
    },
  ],
})
export class TcAggregationsScheduleFormComponent
  implements OnInit, AsyncValidator, OnDestroy
{
  @Input() aggregationType: AggregationType;

  formGroup: FormGroup;
  private destroyed$ = new Subject<void>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public valueAccessor: ValueAccessorDirective<Partial<Aggregation>>,
  ) {}

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this.valueAccessor.value.subscribe((value: Partial<Aggregation>) => {
      if (this.formGroup instanceof FormGroup) {
        this.formGroup.patchValue(value);
      } else {
        this._buildForm(value);
      }
    });

    this.valueAccessor.disabled.subscribe((isDisabled: boolean) => {
      if (this.formGroup?.disabled !== isDisabled) {
        if (isDisabled) {
          this.formGroup.get('scheduled').disable();
          this.formGroup.get('scheduled_at').disable();
        } else {
          this.formGroup.enable();
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // Validator Implementation
  // ---------------------------------------------------------------------------

  validate(): Promise<ValidationErrors | null> {
    return asyncStatusOfControl(this.formGroup);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm(value: Partial<Aggregation>) {
    const { scheduled, scheduled_at, scheduled_target_state } = value ?? {};
    this.formGroup = this._formBuilder.group({
      scheduled: [scheduled],
      scheduled_at: [
        {
          value: parseDate(scheduled_at, DateUnicodeFormat.apiDate),
          disabled: !scheduled,
        },
        [Validators.required],
      ],
      scheduled_target_state: [
        { value: scheduled_target_state, disabled: !scheduled },
        [Validators.required],
      ],
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.valueAccessor.valueChange(value);
        this.valueAccessor.touchedChange(true);
      });

    this.formGroup
      .get('scheduled')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => this._updateValidators(value));
  }

  private _updateValidators(isScheduled: boolean) {
    ['scheduled_at', 'scheduled_target_state'].forEach((controlName) => {
      const control = this.formGroup.get(controlName);
      if (isScheduled) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }
}
