import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tc-hub-dispo-filter-preset',
  templateUrl: './filter-preset.component.html',
  styleUrls: ['./filter-preset.component.css'],
})
export class DispoFilterPresetComponent implements OnInit {
  @Input() section: string;
  @Input() presets = [];

  @Output() requestPreset: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    const defaultPreset = this.presets.find((p) => p.default);

    if (defaultPreset) {
      this.setPreset(defaultPreset);
    }
  }

  setPreset(preset) {
    this.requestPreset.emit(preset);
  }
}
