import { AfterViewInit, Component, Input } from '@angular/core';

import { TourService } from './tour.service';

@Component({
  selector: 'tc-hub-tour',
  templateUrl: './tc-tour.component.html',
  styleUrls: ['./tc-tour.component.scss'],
})
export class TcTourComponent implements AfterViewInit {
  @Input() section: string;

  constructor(private tourService: TourService) {}

  ngAfterViewInit() {
    this.tourService.autoStart(this.section);
  }
}
