import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { DateInfo } from '@timecount/utils';

import { LocalSettingsService } from '../../../core/local-settings.service';
import { RemoteConfig } from '../../../core/remote_config.service';
import { DispoFocusService } from '../../dispo-focus.service';
import { DispoResourceService } from '../../dispo-resource.service';
import { DispoMenuService } from '../../menus/menu.service';

import { DispoDateInfoService } from './../../dispo-date-info.service';

const PX_EACH_DAY = 60;

@Component({
  selector: 'tc-hub-dispo-employees-condensed',
  templateUrl: './dispo-employees-condensed.component.html',
  styleUrls: ['./dispo-employees-condensed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispoEmployeesCondensedComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  config$: Observable<any>;
  employees$: Observable<any[]>;
  weeks$: Observable<DateInfo[][]>;
  dates$: Observable<DateInfo[]>;

  style$ = new Subject();
  styleHeader$ = new BehaviorSubject({});
  resizeObserver: ResizeObserver;

  subs = [];

  panelBegin: Date;
  panelEnd: Date;
  currHoverDate: Date;

  minX: number;
  maxX: number;
  dateLength: number;

  minBuffer = 1680; // performance!
  maxBuffer = 2800;

  constructor(
    private dispoResourceService: DispoResourceService,
    private elementRef: ElementRef,
    private localSettings: LocalSettingsService,
    private remoteConfig: RemoteConfig,
    private contextMenuService: DispoMenuService,
    private dateInfoService: DispoDateInfoService,
    private focusService: DispoFocusService,
    private zone: NgZone,
  ) {
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  ngOnInit() {
    this.config$ = this.remoteConfig.getItem(
      'view',
      'dispo.employees-condensed',
    );
    this.weeks$ = this.dateInfoService.weeks();

    // to make the sticky resource section work container must have a fixed width
    this.resizeObserver = new ResizeObserver((entries) => {
      this.zone.run(() => {
        const width = entries[0].contentRect.width + 20;
        this.style$.next({ 'width.px': width });
        // keep the transform for the header but update the width
        this.styleHeader$.next(
          Object.assign({}, this.styleHeader$.getValue(), {
            'width.px': width,
          }),
        );
      });
    });
    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.subs.push(
      this.focusService.dates().subscribe((dates) => {
        this.minX = dates[0].getTime();
        this.maxX = dates[dates.length - 1].getTime();
        this.dateLength = dates.length;

        const panelBegin = new Date(dates[0].getTime());
        panelBegin.setHours(0, 0, 0, 0);
        this.panelBegin = panelBegin;

        const panelEnd = new Date(dates[dates.length - 1].getTime());
        panelEnd.setHours(24, 0, 0, 0);
        this.panelEnd = panelEnd;
      }),
    );

    this.employees$ = this.dispoResourceService.employeesCondensed();
  }

  ngAfterContentInit() {
    this.elementRef.nativeElement
      .querySelector('.panel')
      .addEventListener('mousemove', this.onMouseMove);
    this.elementRef.nativeElement.addEventListener(
      'dragstart',
      this.onDragStart,
    );
    this.elementRef.nativeElement.addEventListener('dragend', this.onDragEnd);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.elementRef.nativeElement);

    this.elementRef.nativeElement
      .querySelector('.panel')
      .removeEventListener('mousemove', this.onMouseMove);
    this.elementRef.nativeElement.removeEventListener('drop', this.onDragStart);
    this.elementRef.nativeElement.removeEventListener(
      'dragend',
      this.onDragEnd,
    );

    this.subs.forEach((sub) => sub.unsubscribe());
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  contextMenuHandler(event) {
    if (event.button === 2) {
      event.preventDefault();
      event.stopPropagation();
    }

    const contexts = event.menu_contexts || [];

    if (this.currHoverDate) {
      contexts.push({ item_type: 'Date', item: [this.currHoverDate] });
    }

    if (!contexts.find((i) => i.item_type === 'DispoAvailability')) {
      contexts.push({ item_type: 'DispoAvailability', item: [] });
    }

    this.contextMenuService.onViewClick(
      event,
      'dispo/employees-condensed',
      contexts,
    );
  }

  onMenu($event, item_type, item) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({
      item_type: item_type,
      item: [item],
    });
  }

  onScroll($event) {
    const updatedHeaderStyle = Object.assign(
      {},
      this.styleHeader$.getValue(), // keep the current header width
      { transform: `translateX(-${$event.target.scrollLeft}px` }, // move the header with the scroll
    );
    this.styleHeader$.next(updatedHeaderStyle);
  }

  public trackById(index, item) {
    return item.id;
  }

  public trackByHash(index, item) {
    return item.hash;
  }

  private onMouseMove(e) {
    if (
      !e
        .composedPath()
        .find((node: HTMLElement) => node.tagName === 'TC-HUB-DISPO-SHIFT-GRID')
    ) {
      this.currHoverDate = undefined;
      return;
    }

    let x = e.offsetX;
    if (e.target.tagName !== 'TC-HUB-DISPO-SHIFT-GRID') {
      const target = e
        .composedPath()
        .find(
          (node: HTMLElement) =>
            node.hasAttribute && node.hasAttribute('tchubdisposhiftgriditem'),
        ) || { offsetLeft: 0 };
      x = x + target.offsetLeft;
    }

    const timestamp = (x / PX_EACH_DAY) * 60 * 60 * 24 * 1000 + this.minX;
    const roundedTimestamp =
      Math.round(timestamp / 30 / 60 / 1000) * 30 * 60 * 1000;
    const date = new Date(roundedTimestamp);

    this.currHoverDate = date;
    this.localSettings.set('dispoInfo.date', date);
  }

  private onDragStart(e) {
    const dragType = 'timecount/resources';
    const resource = e
      .composedPath()
      .find(
        (node: HTMLElement) =>
          node.dataset &&
          node.dataset.resource_type &&
          node.dataset.resource_id,
      );

    if (!resource) {
      return;
    }

    e.dataTransfer.effectAllowed = 'all';
    e.dataTransfer.setData('drag', dragType);
    e.dataTransfer.setData(
      dragType,
      JSON.stringify({
        resource: {
          type: resource.dataset.resource_type,
          id: parseInt(resource.dataset.resource_id, 10),
        },
        action: 'create',
      }),
    );

    // e.dataTransfer.setDragImage(..., 0, 0);
  }

  private onDragEnd(e) {
    e.preventDefault();
    console.dir(e);
  }
}
