<td
  [nzExpand]="item.expanded"
  (nzExpandChange)="toggleItem(item.type, item.id)"
>
  <label
    nz-checkbox
    [(nzChecked)]="item.checked"
    [nzIndeterminate]="item.indeterminate"
  ></label>
  {{ item.title }}

  <div class="data-panel">
    <tc-hub-data-panel
      [data]="item"
      section="finance.entries.schedule.title"
    ></tc-hub-data-panel>
  </div>
</td>
<td>
  {{ item.job?.title }}
</td>
<td>{{ item.template.size }}</td>
<td>
  <span [class]="scheduleSummary.workDuration | highlightDurationLabel">{{
    scheduleSummary.workDuration | duration
  }}</span>
</td>
<td [colSpan]="zoneGroupNumber"></td>
<td colspan="4"></td>
<td>
  {{ item.starts_at | date : 'd.MM.' }} -
  {{ item.ends_at | date : 'd.MM.' }}
</td>
<td>{{ begin }}</td>
<td>{{ end }}</td>
<td>
  <span>{{ scheduleSummary.breakPeriods }}</span>
  <span
    class="breaks-info"
    *ngIf="scheduleSummary.breakPeriods > 0"
    nz-popover
    [nzPopoverContent]="scheduleTimeframes"
  >
    <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  </span>
</td>
<ng-template #scheduleTimeframes>
  <tc-hub-breaks-list-table
    [timesList]="parsedScheduleTimes"
  ></tc-hub-breaks-list-table>
</ng-template>

<td>{{ scheduleSummary.breakDuration | duration }}</td>
<td>
  <span [class]="scheduleSummary.workDuration | highlightDurationLabel">{{
    scheduleSummary.totalDuration | duration
  }}</span>
</td>
<td>
  <tc-hub-data-panel
    [data]="item"
    section="finance.entries.schedule.status"
  ></tc-hub-data-panel>
</td>
