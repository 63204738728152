import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import DragSelect from 'dragselect';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  feedbackRequestsOnStatus,
  getFeedbackRequestWithHighestPriority,
  TcConfigService,
} from '@timecount/core';

import { integerToString } from '../../../core/helpers';
import { LocalSettingsService } from '../../../core/local-settings.service';
import { Plan } from '../../collections/plan';
import { Schedule } from '../../collections/schedule';
import { SlotPos } from '../../collections/task';
import { DispoLoaderService } from '../../loader/loader.service';
import { DispoFocusService } from '../../dispo-focus.service';
import { DispoSchedulesService } from '../dispo-schedules.service';

// REFACTOR: same as dispo-tasks/task.component
const isDroppable = (e) => {
  const dragType = e.dataTransfer.getData('drag');

  if (
    dragType === 'timecount/resources' ||
    dragType === 'timecount/dispo_assignments'
  ) {
    return true;
  }

  return false;
};

@Component({
  selector: 'tc-hub-ds-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DSScheduleComponent implements OnInit, OnDestroy {
  @Input() schedule: Schedule;
  @Input() plan: Plan;

  @Output() tcSelect: EventEmitter<'started'> = new EventEmitter();

  dates$: Observable<Date[]>;
  tasks$: Observable<any[]>;
  schedule$: Observable<any>;
  expanded$: Observable<boolean>;
  subs = [];
  assignments = [];
  tasks = [];

  updateSelectables$: Subject<any> = new Subject();

  selectable: DragSelect;
  active = false;
  loading = false;
  visible = false;

  allowOnsiteCheckin = this.configService.config.company.allowOnsiteCheckin;
  allowAppAttendance = this.configService.config.company.allowAppAttendance;

  constructor(
    private elementRef: ElementRef,
    private localSettings: LocalSettingsService,
    private dispoFocus: DispoFocusService,
    private service: DispoSchedulesService,
    private loadingService: DispoLoaderService,
    private configService: TcConfigService,
  ) {
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  ngOnInit() {
    console.dir('init schedule');
    this.schedule$ = this.service.scheduleDS(this.schedule);
    this.tasks$ = this.service.scheduleTasks(this.schedule);
    this.dates$ = this.dispoFocus.dates();
    this.expanded$ = this.localSettings.get(
      `dispo.scheduleExpanded.${this.schedule.id}`,
      false,
    );
  }

  // REFACTOR: same as dispo-tasks/task.component#onDestroy
  ngOnDestroy() {
    if (this.selectable) {
      this.selectable.stop();
    }
    this.elementRef.nativeElement.removeEventListener(
      'dragstart',
      this.onDragStart,
    );
    this.elementRef.nativeElement.removeEventListener('drop', this.onDrop);
    this.elementRef.nativeElement.removeEventListener(
      'dragend',
      this.onDragEnd,
    );
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // REFACTOR: similar to dispo-tasks/task.component#contextmenu
  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onEvent($event) {
    const slotTarget = $event
      .composedPath()
      .find((item: Element) => item.classList?.contains('selectable'));

    if (
      // $event.type === 'contextmenu' &&
      this.selectable.getSelection().length === 0 ||
      this.selectable.getSelection().indexOf(slotTarget) === -1
    ) {
      if (slotTarget) {
        this.selectable.setSelection([slotTarget], true);
      } else {
        this.selectable.setSelection([], true);
      }

      this.active = true;
      this.tcSelect.emit('started');
      this.active = false;
    }

    const slots = this.selectable.getSelection().map((el) => {
      // shallow task id as uuid / non shallow as id
      // eslint-disable-next-line: triple-equals
      const task_id =
        el.dataset.taskid == parseInt(el.dataset.taskid, 10)
          ? parseInt(el.dataset.taskid, 10)
          : el.dataset.taskid;

      return Object.assign(new SlotPos(), {
        x: parseInt(el.dataset.hslot, 10),
        y: parseInt(el.dataset.vslot, 10),
        task_id: task_id,
        schedule_id: this.schedule.id,
      });
    });

    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    const assignableSlots = slots.filter((s) => s.y !== -1);
    const assignments = assignableSlots
      .map((slot) => {
        return this.assignments.find(
          (a) => a.position === slot.y && a.date === integerToString(slot.x),
        );
      })
      .filter((a) => a);

    if (assignments.length > 0) {
      $event.menu_contexts.push({
        item_type: 'DispoAssignment',
        item: assignments,
      });
    }

    if (assignableSlots.length > 0) {
      $event.menu_contexts.push({
        item_type: 'DispoSlot',
        item: assignableSlots,
      });
    }

    const taskIds = [...new Set(slots.map((s) => s.task_id))];
    const tasks = taskIds
      .map((id) => {
        return this.tasks.find((t) => t.id === id);
      })
      .filter((t) => t);

    if (tasks.length > 0) {
      $event.menu_contexts.push({
        item_type: 'DispoTask',
        item: tasks,
      });
    }

    $event.menu_contexts.push({
      item_type: 'DispoSchedule',
      item: [this.schedule],
    });
  }

  onVisible() {
    if (this.visible) {
      return;
    }
    this.visible = true;

    this.subs.push(
      this.service.scheduleAssignments(this.schedule).subscribe({
        next: (assignments) => {
          this.assignments = assignments;
        },
      }),
    );

    this.subs.push(
      this.tasks$.subscribe({
        next: (tasksConfig) => {
          const tasks = [];
          tasksConfig.forEach((taskConfig) => {
            if (taskConfig.task) {
              tasks.push(taskConfig.task);
            }
          });

          this.tasks = tasks;
        },
      }),
    );

    setTimeout(() => {
      this.init();
    }, 1000);
  }

  init() {
    this.elementRef.nativeElement.addEventListener(
      'dragstart',
      this.onDragStart,
    );
    this.elementRef.nativeElement.addEventListener('dragend', this.onDragEnd);
    this.elementRef.nativeElement.addEventListener('drop', this.onDrop);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.selectable = new DragSelect({
      selectables: [],
      area: this.elementRef.nativeElement.querySelector('.select-content'),
      selectedClass: 'selected',
      onDragStartBegin: function (event) {
        if (that.loading) {
          this.break();
        }
        const nodes = this.getSelection();
        const target = event
          .composedPath()
          .find((item) => item.getAttribute && item.getAttribute('drag'));
        if (!target) {
          return;
        }

        if (nodes.indexOf(target) > -1) {
          this.break();
          target.setAttribute('draggable', true);
        } else {
          target.setAttribute('draggable', false);
        }
      },
      onDragStart: function (event) {
        that.active = true;
        that.tcSelect.emit('started');
      },
      callback: function (slots) {
        that.onSelect(slots);
        that.active = false;
      },
    });

    // performance improvement
    // build a grid based on subscription to schedule.size / dates
    // only update selectables after this grid changes (keys, size)
    // then hand it over to service to combine it with the data
    this.subs.push(
      this.tasks$.pipe().subscribe((tasks) => {
        this.updateSelectables();
      }),
    );

    this.subs.push(
      this.updateSelectables$
        .pipe(
          // throttleTime(100)
          debounceTime(500),
        )
        .subscribe(() => {
          // do not update selectables, but use classes to prevent selection...
          const elements = [].slice.call(
            this.elementRef.nativeElement.querySelectorAll('.selectable'),
          );
          const selectables = this.selectable.selectables;
          const addToSelectables = elements.filter(
            (e) => selectables.indexOf(e) === -1,
          );
          const removeFromSelectables = selectables.filter(
            (s) => elements.indexOf(s) === -1,
          );

          this.selectable.removeSelectables(removeFromSelectables);
          this.selectable.addSelectables(addToSelectables);
          this.selectable.setSelection([]);
        }),
    );

    this.subs.push(
      this.loadingService
        .loading('schedule', this.schedule.id)
        .pipe(distinctUntilChanged())
        .subscribe((loading) => {
          this.loading = loading;
          // this.cd.markForCheck();
        }),
    );

    this.updateSelectables();
  }

  // REFACTOR: same as dispo-tasks/task.component#clearSelection
  clearSelection() {
    if (!this.active && this.selectable) {
      this.selectable.clearSelection();
    }
  }

  // REFACTOR: same as dispo-tasks/task.component#updateSelectables
  updateSelectables() {
    this.updateSelectables$.next(true);
  }

  onTasksChange(e) {
    // this.updateSelectables();
  }

  // REFACTOR: same as dispo-tasks/task.component#onSelect
  private onSelect(elements) {
    const slots = elements.map((el) => {
      return Object.assign(new SlotPos(), {
        x: parseInt(el.dataset.hslot, 10),
        y: parseInt(el.dataset.vslot, 10),
      });
    });

    this.service.handleSelect(this.schedule, slots);
  }

  onToggleSchedule() {
    this.localSettings.toggle(
      `dispo.scheduleExpanded.${this.schedule.id}`,
      false,
    );
    this.updateSelectables();
  }

  scheduleTimes() {
    if (this.schedule.shift) {
      return this.schedule.shift.title;
    } else {
      const offset = this.schedule.template.offset;
      const length = this.schedule.template.length;
      const padNumber = (n: number) => (n + '').padStart(2, '0');

      const begin = `${padNumber(Math.floor(offset / 3600))}:${padNumber(
        Math.floor((offset / 60) % 60),
      )}`;
      const end = `${padNumber(
        Math.floor((offset + length) / 3600) % 24,
      )}:${padNumber(Math.floor(((offset + length) / 60) % 60))}`;

      return `${begin} - ${end}`;
    }
  }

  requestWithHighestPriority(feedbackRequests) {
    return getFeedbackRequestWithHighestPriority([
      ...feedbackRequestsOnStatus(feedbackRequests, ['responded', 'expired']),
      ...feedbackRequestsOnStatus(feedbackRequests, 'pending', 'read'),
    ]);
  }

  slots() {
    const slotsConfigs = this.schedule.slots_config || [];

    return Array.from(Array(this.schedule.template.size || 0)).map(
      (_, position) => {
        const config = slotsConfigs.find((s) => s.position === position);

        return (
          config || {
            roles: [],
            qualifications: [],
            job: undefined,
            group: undefined,
            position: position,
          }
        );
      },
    );
  }

  // REFACTOR: similar to  dispo-tasks/task.component#onDragStart
  private onDragStart(e) {
    const dragType = e.target.getAttribute('drag');

    if (dragType === 'timecount/dispo_assignments') {
      const slots = [];
      const action = e.metaKey || e.ctrlKey || e.altKey ? 'create' : 'update';

      this.selectable.getSelection().map((el) => {
        slots.push({
          x: parseInt(el.dataset.hslot, 10),
          y: parseInt(el.dataset.vslot, 10),
        });
      });

      e.dataTransfer.effectAllowed = 'all';
      e.dataTransfer.setData('drag', dragType);
      e.dataTransfer.setData(
        dragType,
        JSON.stringify({
          schedule: this.schedule,
          slots: slots,
          x: parseInt(e.srcElement.dataset.hslot, 10),
          y: parseInt(e.srcElement.dataset.vslot, 10),
          action: action,
        }),
      );
    }
  }

  // REFACTOR: similar to  dispo-tasks/task.component#onDrop
  private onDrop(e) {
    if (isDroppable(e)) {
      e.preventDefault();

      if (e.stopPropagation) {
        e.stopPropagation();
      }

      const source_type = e.dataTransfer.getData('drag');
      let data = e.dataTransfer.getData(source_type);
      if (data) {
        data = JSON.parse(data);
      } else {
        return false;
      }

      const slot = e
        .composedPath()
        .find(
          (node) => node.dataset && node.dataset.hslot && node.dataset.vslot,
        );

      if (!slot) {
        return;
      }
      const toPosition = slot
        ? {
            x: parseInt(slot.dataset.hslot, 10),
            y: parseInt(slot.dataset.vslot, 10),
          }
        : {};
      const selectedSlots = this.selectable.getSelection().map((el) => {
        return {
          x: parseInt(el.dataset.hslot, 10),
          y: parseInt(el.dataset.vslot, 10),
        };
      });

      this.service.handleInsert(
        source_type,
        data,
        this.schedule,
        toPosition,
        selectedSlots,
      );
      this.active = false;
      this.clearSelection();
      this.updateSelectables();

      return true;
    }
  }

  // REFACTOR: same as dispo-tasks/task.component#onDragEnd
  private onDragEnd(e) {
    e.preventDefault();
  }

  trackByDateAndId(index, item) {
    return item.date + item.id;
  }
}
