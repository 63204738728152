<ng-container *ngIf="visible">
  <a
    nz-button
    nz-dropdown
    nzTrigger="click"
    nzBackdrop="false"
    nzClickHide="true"
    [nzDropdownMenu]="menu"
  >
    {{ 'value_set.dropdown' | translate }}

    <nz-badge
      *ngIf="(count$ | async) > 0"
      [nzCount]="count$ | async"
      [nzStyle]="{ backgroundColor: '#38404a' }"
    >
    </nz-badge>

    <i nz-icon nzType="down"></i>
  </a>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="create()">
        <i nz-icon nzType="plus"></i>
        {{ 'value_set.actions.create' | translate }}
      </li>

      <li
        *ngFor="let valueSet of valueSets$ | async"
        nz-menu-item
        (click)="edit(valueSet)"
      >
        <i nz-icon nzType="calendar"></i>
        {{ valueSet.starts_at | date: 'dd.MM.yyyy' }} {{ valueSet.note }}
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-container>
