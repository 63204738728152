import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, map, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { Validation } from '../../core/types/validation';
import { Action } from '../../core/types/action';
import { ActionType } from '../../core/types/action-type';
import { ConflictingEntryType } from '../../core/types/conflicting-entry-type';
import { EntryType } from '../../core/types/entry-type';
import { ValidationErrorLevel } from '../../core/types/validation-error-level';

import { ValidationService } from './validation.service';
import { DispoAssignmentCollection } from './assignment';
import { Assignment } from './assignment.model';
import { DispoScheduleCollection, Schedule } from './schedule';
import { Task } from './task';

@Injectable({
  providedIn: 'root',
})
export class DispoAssignService {
  private translations: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private scheduleCollection: DispoScheduleCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private validationService: ValidationService,
  ) {
    this.translateService
      .get([
        'dispo/assignment.errors.assignment_missing',
        'dispo/assignment.errors.task_missing',
        'dispo/assignment.errors.slot_missing',
        'dispo/assignment.errors.slot_occupied',
        'dispo/assignment.errors.slot_deactivated',
        'dispo/assignment.errors.connected_with_invitation',
        'dispo/assignment.errors.cannot_move_confirmed_assignment',
        'dispo/assignment.resolves.slot_deactivated',
        'dispo/assignment.resolves.connected_with_invitation',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  run(
    actions: {
      assignment: Assignment;
      task: Task;
      position: number;
      type: ActionType;
    }[],
  ) {
    const validationActions = actions.map((action) => {
      const resultingAssignment = this.assignmentCollection.move(
        action.assignment,
        action.position,
        action.task,
      );

      if (action.type === ActionType.create) {
        resultingAssignment.id = uuid();
        resultingAssignment.shallow = true;
      }

      return <Action>{
        id: uuid(),
        entry: resultingAssignment,
        entry_type: EntryType.Assignment,
        type: resultingAssignment.shallow
          ? ActionType.create
          : ActionType.update,
        validations: (item, stack) => this.staticValidations(item, stack),
        errors: [],
      };
    });

    this.validationService.run(validationActions);
  }

  private staticValidations(
    assignment: Assignment,
    stack: Assignment[] = [],
  ): Observable<Validation[]>[] {
    const validations$: Observable<Validation[]>[] = [];
    const stackIds = stack.map((a) => a.id);

    // Argument Error - no assignment
    if (!assignment) {
      validations$.push(
        of([
          Object.assign(new Validation(), {
            id: uuid(),
            message:
              this.translations['dispo/assignment.errors.assignment_missing'],
            type: ValidationErrorLevel.critical,
          }),
        ]),
      );

      return validations$;
    }

    // Argument Error - no task
    if (!assignment.task) {
      validations$.push(
        of([
          Object.assign(new Validation(), {
            id: uuid(),
            message: this.translations['dispo/assignment.errors.task_missing'],
            type: ValidationErrorLevel.critical,
          }),
        ]),
      );

      return validations$;
    }

    if (assignment.position === -1) {
      validations$.push(
        of([
          Object.assign(new Validation(), {
            id: uuid(),
            message: this.translations['dispo/assignment.errors.slot_missing'],
            type: ValidationErrorLevel.critical,
          }),
        ]),
      );

      return validations$;
    }

    // Moving an assignment to another task/slot once you have confirmed tracked times should not be possible
    if (assignment.times_state === 'confirmed') {
      validations$.push(
        of([
          Object.assign(new Validation(), {
            id: uuid(),
            message:
              this.translations[
                'dispo/assignment.errors.cannot_move_confirmed_assignment'
              ],
            type: ValidationErrorLevel.critical,
          }),
        ]),
      );

      return validations$;
    }

    // Moving an assignment connected to a Invitation to another Task
    if (!assignment.shallow) {
      validations$.push(
        this.assignmentCollection.get(assignment.id).pipe(
          first(),
          map((oldAssignment: Assignment) => {
            if (
              oldAssignment.invitation_id &&
              oldAssignment.task_id !== assignment.task_id
            ) {
              return [
                Object.assign(new Validation(), {
                  id: uuid(),
                  message:
                    this.translations[
                      'dispo/assignment.errors.connected_with_invitation'
                    ],
                  type: ValidationErrorLevel.critical,
                  resolve_message:
                    this.translations[
                      'dispo/assignment.resolves.connected_with_invitation'
                    ],
                  resolve: () => {
                    assignment.invitation_id = undefined;
                    return of(assignment);
                  },
                }),
              ];
            }

            return [];
          }),
        ),
      );
    }

    // Scheduled Task with disabled slot
    if (assignment.schedule_id) {
      validations$.push(
        this.scheduleCollection.get(assignment.schedule_id).pipe(
          first(),
          map((schedule: Schedule) => {
            const slots = schedule.slots_matrix[assignment.date] || [];
            if (assignment.position >= schedule.template.size) {
              return [
                Object.assign(new Validation(), {
                  id: uuid(),
                  message:
                    this.translations['dispo/assignment.errors.slot_missing'],
                  type: ValidationErrorLevel.critical,
                }),
              ];
            }

            if (
              assignment.position < schedule.template.size &&
              slots.indexOf(assignment.position) === -1
            ) {
              return [
                Object.assign(new Validation(), {
                  id: uuid(),
                  message:
                    this.translations[
                      'dispo/assignment.errors.slot_deactivated'
                    ],
                  type: ValidationErrorLevel.critical,
                  resolve_message:
                    this.translations[
                      'dispo/assignment.resolves.slot_deactivated'
                    ], // TODO: Datum hinzufügen
                  resolve: (_) => {
                    return this.scheduleCollection
                      .get(assignment.schedule_id)
                      .pipe(
                        first(),
                        switchMap((schedule: Schedule) => {
                          schedule = Object.assign({}, schedule);
                          schedule.slots_matrix = Object.assign(
                            {},
                            schedule.slots_matrix,
                          );
                          schedule.slots_matrix[assignment.date] = [
                            ...schedule.slots_matrix[assignment.date],
                            assignment.position,
                          ];

                          return this.scheduleCollection.update(
                            schedule.id,
                            schedule,
                          );
                        }),
                      );
                  },
                }),
              ];
            }

            return [];
          }),
        ),
      );
    }

    validations$.push(
      this.assignmentCollection.forTask(assignment.task).pipe(
        first(),
        map((assignments) => {
          // replace assignments with assignments on action stack
          assignments = assignments.filter(
            (a) => stackIds.indexOf(a.id) === -1,
          );
          assignments = [
            ...assignments,
            ...stack.filter(
              (a) => a.id !== assignment.id && a.task_id === assignment.task_id,
            ),
          ];

          const assignmentOnSlot = assignments.find(
            (a) => a.position === assignment.position,
          );

          if (assignmentOnSlot) {
            return [
              Object.assign(new Validation(), {
                id: uuid(),
                message:
                  this.translations['dispo/assignment.errors.slot_occupied'],
                type: ValidationErrorLevel.critical,
                conflicting_entry: assignmentOnSlot,
                conflicting_entry_type: ConflictingEntryType.Assignment,
              }),
            ];
          }

          return [];
        }),
      ),
    );

    return validations$;
  }
}
