<div class="sf-group" [formGroup]="form">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>
  <div class="sf-controls">
    <div class="sf-elements">
      <tc-interval-web
        [tcFieldType]="'date'"
        formControlName="interval"
        [tcConfig]="{
          fieldType: 'date'
        }"
      ></tc-interval-web>
    </div>
  </div>
</div>
