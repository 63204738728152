<!-- TODO: Replace legacy-dependent styling -->

<section class="tile">
  <div class="tile-header">
    <h2 class="tile-title">
      <span nz-icon nzType="message" nzTheme="fill"></span>
      {{ 'administration/post.plural' | translate }}
    </h2>
  </div>

  <div class="progress" *ngIf="loading"></div>

  <ul class="list-group" *ngIf="posts?.length; else noPost">
    <li class="list-group-item" *ngFor="let post of posts">
      <div class="list-group-icon">
        <span
          class="icon"
          nz-icon
          [nzType]="postsService.postConfigs[post.type].icon"
          [style]="'--icon-color: ' + postsService.postConfigs[post.type].color"
        ></span>
      </div>
      <div style="margin-left: 60px; margin-top: -40px">
        <small>
          {{ post.createdAt | date }} |
          {{ 'administration/post.types.' + post.type | translate }}
        </small>
        <h4>{{ post.title }}</h4>
        <markdown [data]="post.text"></markdown>
      </div>
    </li>
  </ul>

  <ng-template #noPost>
    <li class="list-group-item">
      <p>{{ 'administration/post.no_posts' | translate }}</p>
    </li>
  </ng-template>
</section>
