<div class="sf-group" [formGroup]="form">
  <label class="sf-label" *ngIf="schema.title">{{ schema.title }}</label>
  <div class="sf-controls">
    <div class="sf-elements" [formGroup]="form">
      <tc-input-web
        class="datetime-field"
        formControlName="datetime"
        [tcConfig]="{
          disabled: schema.readOnly
        }"
        [fieldType]="
          schema.widget.id === 'datetime' ? 'dateTime' : schema.widget.id
        "
      ></tc-input-web>
    </div>
  </div>
</div>
