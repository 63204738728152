<tc-form-field-web
  [label]="label"
  [labelFor]="controlName"
  [formGroup]="formGroup"
  [tcCanDisable]="tcCanDisable"
  [(disabled)]="disabled"
>
  <ng-select
    tcFormControlValidator
    [hasLabel]="!!label"
    class="tc-select"
    appendTo="body"
    [name]="controlName"
    [inputAttrs]="{ class: 'form-control' }"
    [formControlName]="controlName"
    [items]="items$ | async"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [searchable]="searchable"
    [multiple]="multiple"
    [loading]="loading"
  >
  </ng-select>
</tc-form-field-web>
