<div class="modal-container">
  <div class="modal-body">
    <tc-hub-tc-transfer
      [resources$]="resources$"
      [resourcesDS$]="resourcesDS$"
      [selected]="selectedResources"
      section="timebalances.subtotal.document"
      (tcSelect)="onSelect($event)"
      [shouldDisableResources]="!!document.release"
    ></tc-hub-tc-transfer>
  </div>
  <div class="modal-footer">
    <button type="button" nz-button nzType="primary" (click)="prev()">
      {{ 'back' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      [disabled]="!valid"
      [nzLoading]="loading"
      (click)="create()"
    >
      {{ 'create' | translate }}
    </button>
  </div>
</div>
