import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[tcNoPadding]',
  standalone: true,
})
export class TcNoPaddingDirective {
  @Input()
  @HostBinding('class.no-padding')
  tcNoPadding = false;
}
