import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@timecount/ui';
import { TcFeedbackRequestType } from '@timecount/core';

import { DispoAssignmentCollection } from '../../dispo/collections/assignment';
import { DispoLoaderService } from '../../dispo/loader/loader.service';
import { DispoAssignmentComponent } from '../../dispo/dispo-assignment/dispo-assignment.component';
import {
  DispoTaskCollection,
  SlotPos,
  Task,
} from '../../dispo/collections/task';
import { Schedule } from '../../dispo/collections/schedule';
import { integerToString } from '../../core/helpers';
import { Action } from '../../core/types/action';
import { ActionType } from '../../core/types/action-type';
import { EntryType } from '../../core/types/entry-type';
import { Assignment } from '../collections/assignment.model';
import { ValidationService } from '../collections/validation.service';
import {
  DispoRangeDataStructure,
  DispoRangeDataStructureFactory,
} from '../datastructures/range.service';
import { TimesheetCollection } from '../../timesheets';
import { LogsComponent } from '../../shared/logs/logs.component';

@Injectable({
  providedIn: 'root',
})
export class AssignmentService {
  private translations: { [key: string]: string } = {};

  private rangeDataStructure: DispoRangeDataStructure;

  constructor(
    private modalService: ModalService,
    private loadingService: DispoLoaderService,
    private assignmentCollection: DispoAssignmentCollection,
    private timesheetCollection: TimesheetCollection,
    private taskCollection: DispoTaskCollection,
    private translateService: TranslateService,
    private validationService: ValidationService,
    private rangeDataStructureFactory: DispoRangeDataStructureFactory,
  ) {
    this.rangeDataStructure = this.rangeDataStructureFactory.all();

    this.translateService
      .get([
        'dispo/assignment.modal.title',
        'dispo/assignment.confirms.delete',
        'dispo/assignment.confirms.validate',
        'timesheet.confirms.gratis',
        'timesheet.confirms.paid',
        'trails.title',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  edit(assignment: Assignment) {
    this.section([assignment], 'general');
  }

  info(assignments: Assignment[]) {
    this.single(assignments, 'info');
  }

  assignToSchedule(schedule: Schedule, slots: SlotPos[], type = 'Contractor') {
    this.taskCollection
      .activateSlots(schedule, slots)
      .pipe(first())
      .subscribe((tasks: Task[]) => {
        this.assignmentCollection
          .all()
          .pipe(first())
          .subscribe({
            next: (assignments) => {
              assignments = slots.map((slot) => {
                const task = tasks.find(
                  (t) => t.date === integerToString(slot.x),
                );
                let assignment = assignments.find(
                  (a) => a.position === slot.y && a.task_id === task.id,
                );

                if (assignment) {
                  return Object.assign({}, assignment, {
                    invitation_id: undefined,
                  });
                } else {
                  assignment = new Assignment();
                  assignment = this.assignmentCollection.move(
                    assignment,
                    slot.y,
                    task,
                  );
                  return assignment;
                }
              });

              this.section(assignments, `assign${type}`);
            },
          });
      });
  }

  assignToTask(task: Task, slots: SlotPos[], type = 'Contractor') {
    this.taskCollection
      .activateTasks([task])
      .pipe(
        first(),
        map((tasks) => tasks[0]),
      )
      .subscribe({
        next: (task) => {
          this.assignmentCollection
            .forTask(task)
            .pipe(first())
            .subscribe({
              next: (assignments) => {
                assignments = slots.map((slot) => {
                  let assignment = assignments.find(
                    (a) => a.position === slot.y,
                  );

                  if (assignment) {
                    return Object.assign({}, assignment, {
                      invitation_id: undefined,
                    });
                  } else {
                    assignment = new Assignment();
                    assignment = this.assignmentCollection.move(
                      assignment,
                      slot.y,
                      task,
                    );
                    return assignment;
                  }
                });

                this.section(assignments, `assign${type}`);
              },
            });
        },
      });
  }

  convertToAvailabilites(assignments) {
    this.section(assignments, 'availability');
  }

  convertToTimeBalances(assignments) {
    this.section(assignments, 'timebalance');
  }

  requestReadConfirmation(assignments: Assignment[]) {
    assignments.forEach((assignment) => {
      this.assignmentCollection.update(assignment.id, {
        ...assignment,
        requested: true,
      });
    });
  }

  respondFeedbackRequest(
    assignments: Assignment[],
    type: TcFeedbackRequestType,
    value: boolean,
  ) {
    assignments.forEach((assignment) => {
      this.assignmentCollection.update(assignment.id, {
        ...assignment,
        // The `respond` property is only required when sending a update request
        // and not on the front-end (which uses the `responses` array).
        respond: { [type]: value },
      });
    });
  }

  setGratisState(assignments: Assignment[], value: boolean) {
    const assignmentsWithoutTimesheet = assignments.filter(
      (a) => a.times_state === 'untracked',
    );
    const assignmentsWithTimesheet = assignments.filter(
      (a) => a.times_state !== 'untracked',
    );

    this.timesheetCollection
      .forAssignments(assignmentsWithTimesheet)
      .pipe(first())
      .subscribe((timesheets) => {
        const actions = [...assignmentsWithoutTimesheet, ...timesheets].map(
          (entry) => {
            return <Action>{
              id: uuid(),
              entry: {
                ...entry,
                gratis: value,
              },
              entry_type:
                entry.identifier === this.assignmentCollection.identifier
                  ? EntryType.Assignment
                  : EntryType.Timesheet,
              type: ActionType.update,
              errors: [],
              validations: (item, stack) => [],
            };
          },
        );

        this.validationService.run(
          actions,
          true,
          value
            ? this.translations['timesheet.confirms.gratis']
            : this.translations['timesheet.confirms.paid'],
        );
      });
  }

  delete(assignments: Assignment[]) {
    const actions = assignments.map((assignment) => {
      return <Action>{
        id: uuid(),
        entry: assignment,
        entry_type: EntryType.Assignment,
        type: ActionType.delete,
        errors: [],
        validations: (item, stack) =>
          this.assignmentCollection.remoteValidations(
            assignment,
            [],
            ActionType.delete,
          ),
      };
    });

    return this.validationService.run(
      actions,
      true,
      this.translations['dispo/assignment.confirms.delete'],
    );
  }

  validate(assignments: Assignment[]) {
    const actions = assignments.map((assignment) => {
      return <Action>{
        id: uuid(),
        entry: assignment,
        entry_type: EntryType.Assignment,
        type: ActionType.noop,
        errors: [],
        validations: (item, stack) => [],
      };
    });

    return this.validationService.run(
      actions,
      true,
      this.translations['dispo/assignment.confirms.validate'],
    );
  }

  section(assignments: any, section: string, single = false) {
    const ref = this.modalService.open(DispoAssignmentComponent, {
      data: {
        assignments: assignments,
        section: section,
        single: single,
      },
      modalTitle: this.translations['dispo/assignment.modal.title'],
    });
  }

  single(avail: any, section: string) {
    this.section(avail, section, true);
  }

  viewLogs(resource_id: number) {
    this.modalService.open(LogsComponent, {
      data: {
        resource_id,
        resource_type: 'TcDispo::Assignment',
      },
      modalTitle: this.translations['trails.title'],
    });
  }
}
