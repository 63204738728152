<div
  #menuDir
  nz-dropdown
  nzTrigger="click"
  nzClickHide="false"
  nzBackdrop="false"
  [nzDropdownMenu]="menu"
  [nzVisible]="menuVisible"
  (nzVisibleChange)="menuVisibilityChanged($event)"
></div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="dispo-filter-dropdown">
    <li nz-menu-item class="dispo-filter-dropdown-body">
      <div class="dispo-filter-form" *ngIf="schema" #dispoFilterForm>
        <sf-form
          class="tc-form-horizontal tc-form-dividers"
          [schema]="schema"
          [model]="innerValue"
          (onChange)="onChange($event)"
          (keydown)="onKey($event)"
        ></sf-form>
      </div>
    </li>
    <li nz-menu-item class="dispo-filter-dropdown-footer">
      <button nz-button nzType="primary" (click)="applyFilter()">
        {{ 'dispo.actions.filter' | translate }}
      </button>
      <button nz-button nzType="secondary" (click)="removeFilters($event)">
        <i nz-icon nzType="close-circle"></i>
        {{ 'remove_all' | translate }}
      </button>
      <button nz-button nzType="secondary" (click)="hide()">
        {{ 'close' | translate }}
      </button>
    </li>
  </ul>
</nz-dropdown-menu>
