import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';

import { LocalSettingsService } from '../../../core/local-settings.service';
import { DispoFocusService } from '../../dispo-focus.service';
import { DispoAnnouncementsService } from '../dispo-announcements.service';
import { DANAnnounceComponent } from '../announce/announce.component';
@Component({
  selector: 'tc-hub-dan-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DANPlanComponent implements OnInit {
  @ViewChildren('announcementComponent')
  announcementComponents: QueryList<DANAnnounceComponent>;

  @Input() plan: any;

  @Output() tcSelect: EventEmitter<unknown> = new EventEmitter();

  plan$: Observable<any>;
  weeks$: Observable<any[]>;
  expanded$: Observable<boolean>;
  announcements$: Observable<any[]>;

  constructor(
    private dispoFocus: DispoFocusService,
    private localSettings: LocalSettingsService,
    private service: DispoAnnouncementsService,
  ) {}

  @HostListener('contextmenu', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('touchstart', ['$event'])
  onContextMenu($event) {
    if (!$event.menu_contexts) {
      $event.menu_contexts = [];
    }

    $event.menu_contexts.push({ item_type: 'DispoPlan', item: [this.plan] });
  }

  ngOnInit() {
    this.plan$ = this.service.planDS(this.plan);
    this.weeks$ = this.dispoFocus.weeks();
    this.expanded$ = this.localSettings.get(
      `dispo.planExpanded.${this.plan.id}`,
      true,
    );
    this.announcements$ = this.service.planAnnouncements(this.plan);
  }

  onTogglePlan($event) {
    this.localSettings.toggle(`dispo.planExpanded.${this.plan.id}`, true);
  }

  onSelect(e) {
    this.tcSelect.emit(e);
  }

  clearSelection() {
    this.announcementComponents.map((s) => s.clearSelection());
  }

  trackById(index, item) {
    return item.id;
  }

  includes(date: Date) {
    const timestamp = new Date(date.getTime()).setHours(12, 0, 0, 0) / 1000;

    return this.plan.marker[0] <= timestamp && this.plan.marker[1] >= timestamp;
  }

  isWeekend(date: Date) {
    const wday = date.getDay();

    return wday === 0 || wday === 6;
  }
}
