<tc-form-field-web [tcErrors]="[timesList.errors]">
  <tc-panel [tcNoPadding]="tcIsHorizontal">
    <!-- TODO:
    Should be replaced when the pannel supports a compact mode
    Jira issue: DEV-1594
  -->
    <div class="header-horizontal" *ngIf="tcIsHorizontal; else summaryTemplate">
      <button
        nz-button
        nzSize="small"
        nzType="link"
        (click)="expanded = !expanded"
      >
        <span nz-icon [nzType]="expanded ? 'down' : 'right'"></span>
      </button>
      <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
    </div>

    <ng-container [formGroup]="innerForm">
      <fieldset
        [hidden]="!expanded"
        class="interval-fields"
        [class.is-horizontal]="tcIsHorizontal"
        [class.is-compact]="tcIsCompact"
        formArrayName="timesList"
      >
        <div
          *ngFor="
            let time of timesList?.controls;
            index as i;
            last as isLast;
            first as isFirst
          "
        >
          <div class="time-item">
            <tc-interval-web
              #listItems
              class="time-item__inputs"
              [formControlName]="i"
              [tcIsCompact]="true"
              [tcFieldWithDateOptions]="isFirst ? 'end' : 'both'"
              [tcMaxDuration]="maxItemDuration"
              [tcAllowZeroDuration]="true"
              [tcFieldType]="'time'"
              [tcConfig]="{
                alwaysVisibleDateOptions: true,
                limit: {
                  start: timesList?.controls[i - 1]?.value.end,
                  end: timesList?.controls[i + 1]?.value?.start
                },
                fieldType: 'time',
                required: true
              }"
              [tcLabels]="!tcHideLabels && isFirst ? 'default' : null"
              [tcDateOptionsInterval]="{
                start:
                  isFirst || !timesList?.controls[0]?.value.start
                    ? tcReferenceDate
                    : timesList?.controls[0]?.value.start,
                end: maxEndLimit
              }"
              [tcRefDate]="
                isFirst || !timesList?.controls[i - 1]?.value.end
                  ? tcReferenceDate
                  : timesList?.controls[i - 1]?.value.end
              "
            ></tc-interval-web>
            <div class="time-item__options">
              <label class="options-label" *ngIf="!tcHideLabels && isFirst"
                >Options</label
              >
              <nz-button-group class="group-options">
                <button
                  type="button"
                  class="option"
                  nz-button
                  nzType="link"
                  nzTooltipTitle="Split"
                  nzTooltipPlacement="topLeft"
                  [disabled]="this.innerForm.disabled"
                  nz-tooltip
                  (click)="splitTime(i)"
                >
                  <i
                    nz-icon
                    nzType="split-cells"
                    nzRotate="90"
                    nzTheme="outline"
                  ></i>
                </button>
                <button
                  type="button"
                  class="option"
                  nz-button
                  nzType="link"
                  nzDanger
                  nzTooltipTitle="Remove"
                  nzTooltipPlacement="topRight"
                  nz-tooltip
                  [disabled]="
                    this.innerForm.disabled || timesList.value.length < 2
                  "
                  (click)="deleteTime(i)"
                >
                  <i nz-icon nzType="delete" nzTheme="outline"></i>
                </button>
              </nz-button-group>
            </div>
          </div>
          <nz-divider
            class="add-between"
            *ngIf="!isLast"
            nzDashed
            (click)="addAfter(i)"
            [nzText]="add"
          >
            <ng-template #add>
              <i class="add-between-icon" nz-icon nzType="plus"></i>
            </ng-template>
          </nz-divider>
        </div>
        <button
          type="button"
          class="add-end"
          nz-button
          nzBlock
          nzType="dashed"
          [disabled]="this.innerForm.disabled"
          (click)="addAfter()"
        >
          <span nz-icon nzType="plus"></span>
          {{ 'ASSIGNMENTS.TIME_TRACKING_ADD_PERIOD' | translate }}
        </button>
      </fieldset>
    </ng-container>
  </tc-panel>
</tc-form-field-web>
<ng-template #summaryTemplate>
  <tc-times-summary-web
    class="times-summary"
    [expanded]="!tcIsHorizontal && expanded"
    [intervalList]="timesList.value"
    [isCompact]="tcIsCompact"
    [isHorizontal]="tcIsHorizontal"
  ></tc-times-summary-web>
</ng-template>
