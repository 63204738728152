import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'tc-hub-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  @Input() active$: Observable<boolean>;
}
