import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';

import { siftFilter } from '../../../core/sift';
import { CollectionService } from '../../../core/collection.service';

@Component({
  selector: 'tc-hub-dispo-filter-display',
  templateUrl: './filter-display.component.html',
  styleUrls: ['./filter-display.component.scss'],
})
export class DispoFilterDisplayComponent implements OnDestroy {
  @Input() section: string;

  @Input() set filter(value) {
    this._filter = value;
    this.updateFilter();
  }
  get filter(): any {
    return this._filter;
  }

  @Input() set schema(value) {
    this._schema = value;
    this.updateFilter();
  }
  get schema(): any {
    return this._schema;
  }

  @Output() requestFilter: EventEmitter<any> = new EventEmitter();

  _filter: any = {};
  _schema: any;

  private _currentFilters$: BehaviorSubject<any> = new BehaviorSubject({});
  private _destroyed$: Subject<void> = new Subject();

  currentFilters$ = this._currentFilters$.asObservable();

  constructor(private collectionService: CollectionService) {
    // refresh ui
    setTimeout(() => this.updateFilter(), 5000);
  }

  removeFilters(event) {
    event.preventDefault();
    event.stopPropagation();

    this.updateFilter(this.schema, {});
    this.requestFilter.emit({});
  }

  removeFilter(event, filter: { key: string; value: any }): void {
    event.preventDefault();
    event.stopPropagation();

    const schema = this.schema;
    const key = filter.key;

    const newFilters = Object.assign({}, this.filter);
    let newValue;

    // get default filter value from schema
    if (schema && schema.properties && schema.properties[key]) {
      const schemaFilter = schema.properties[key];

      if (typeof schemaFilter.default !== 'undefined') {
        newValue = schemaFilter.default;
      }
      // guess default filter value from current value
    } else {
      if (Array.isArray(newFilters[key])) {
        newValue = [];
      }
    }

    // set new value
    if (typeof newValue === 'undefined') {
      delete newFilters[key];
    } else {
      newFilters[key] = newValue;
    }

    this.updateFilter(this.schema, newFilters);
    this.requestFilter.emit(Object.assign({}, newFilters));
  }

  filterDescription(value) {
    if (Array.isArray(value)) {
      return value.map((obj) => this.filterDescription(obj)).join(', ');
    }

    if (typeof value === 'object') {
      if (value.title) {
        return value.title;
      }
      if (value.starts_at && value.ends_at && value.valid) {
        return [
          format(new Date(value.starts_at), 'd.M HH:mm'),
          format(new Date(value.ends_at), 'HH:mm'),
        ].join(' - ');
      }
    }

    if (typeof value === 'boolean') {
      return value ? 'Ja' : 'Nein';
    }

    if (value === '$and') {
      return 'UND';
    }

    if (value === '$or') {
      return 'ODER';
    }

    return value;
  }

  private updateFilter(schema = this.schema, filters = this.filter): void {
    const filterData = {};

    Object.keys(filters).forEach((filter: string): void => {
      if (schema && schema.properties && schema.properties[filter]) {
        const schemaFilter = schema.properties[filter];
        const filterValue = filters[filter];

        if (
          typeof schemaFilter.default !== 'undefined' &&
          filterValue === schemaFilter.default
        ) {
          return;
        }

        if (Array.isArray(filterValue) && filterValue.length === 0) {
          return;
        }

        if (schemaFilter.collection) {
          const query = {
            id: {
              $in: filters[filter] || [],
            },
          };

          const service = this.collectionService.get(schemaFilter.collection);
          const collection: BehaviorSubject<any[]> = service.all();
          let filtered: any[] = [];

          collection.pipe(first(), takeUntil(this._destroyed$)).subscribe({
            next: (resources) => {
              filtered = resources.filter(siftFilter(query));
            },
          });

          if (filtered.length) {
            filterData[filter] = {
              description: schemaFilter.description
                ? schemaFilter.description
                : schemaFilter.title,
              resources: filtered,
              value: filtered.map((res) => res.name || res.title).join(', '),
            };
          } else {
            if (filterData[filter]) {
              delete filterData[filter];
            }
          }
        } else if (schemaFilter.oneOf) {
          const selected = schemaFilter.oneOf.find(
            (opt) => opt.enum.indexOf(filterValue) !== -1,
          );

          filterData[filter] = {
            description: schemaFilter.description
              ? schemaFilter.description
              : schemaFilter.title,
            value: selected ? selected.description : undefined,
          };
        } else {
          // TODO: prevent display of empty arrays, default values, etc.
          filterData[filter] = {
            description: schemaFilter.description
              ? schemaFilter.description
              : schemaFilter.title,
            value: filterValue,
          };
        }
      }
    });

    this._currentFilters$.next(filterData);
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
