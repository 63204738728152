import { Component, OnInit, Optional } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash-es';

import { ModalConfig, ModalRef } from '@timecount/ui';

import { TcProjectFormsBaseDirective } from '../../projects/project-forms-base.directive';
import { JobRate } from '../../core/types/job-rate.model';
import { ZoneGroup } from '../../core/types/zone-group.model';
import { JobRateCollection } from '../../core/collections/job-rate.collection';

@Component({
  selector: 'tc-hub-job-rates-form',
  templateUrl: './job-rates-form.component.html',
  styleUrls: ['./job-rates-form.component.scss'],
})
export class TcJobRatesFormComponent
  extends TcProjectFormsBaseDirective<JobRate>
  implements OnInit
{
  formGroup: FormGroup;
  rateFieldSet;
  zoneGroups: ZoneGroup[];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _jobRateCollection: JobRateCollection,
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super();
  }
  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    super.ngOnInit();

    this._buildForm();
  }

  // --------------
  // Public Methods
  // --------------
  save() {
    const {
      job_id,
      vat_id,
      daily_rate,
      per_day,
      jobRateGeneral,
      jobRateTypeGeneral,
    } = this.formGroup.value;
    const {
      parent_type,
      parent_id,
      per_hour,
      per_day: dailyPrice,
      surcharges,
    } = this.tcInitialValue ?? {};
    const { rate, zoneGroups } = this.rateFieldSet ?? {};

    const payload = {
      ...this.tcInitialValue,
      job_id,
      vat_id,
      daily_rate,
      per_day:
        per_day != null ? Number((per_day * 100).toFixed(0)) : dailyPrice,
      per_hour: rate != null ? Number((rate * 100).toFixed(0)) : per_hour,
      surcharges: zoneGroups ?? surcharges,
      store: _.merge(
        this.tcInitialValue?.store,
        jobRateGeneral,
        jobRateTypeGeneral,
      ),
    };

    if (this.tcInitialValue?.id) {
      this._jobRateCollection
        .forTypeAndId(parent_type, parent_id)
        .update(this.tcInitialValue.id, payload);
    } else {
      this._jobRateCollection
        .forTypeAndId(parent_type, parent_id)
        .create(payload);
    }

    this.modalRef?.close();
  }

  onRateFieldsetValueChange(value) {
    this.rateFieldSet = value;
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm() {
    const { job_id, vat_id, daily_rate, per_day, store } =
      this.tcInitialValue ?? {};
    this.formGroup = this._formBuilder.group({
      job_id: [job_id, Validators.required],
      vat_id: [vat_id, Validators.required],
      daily_rate: [daily_rate, Validators.required],
      per_day: [per_day / 100],
      jobRateGeneral: [store],
      jobRateTypeGeneral: [store],
    });
  }
}
