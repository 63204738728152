import { Directive, Input } from '@angular/core';

import { FinancesEntriesService } from './finances-entries.service';

@Directive()
export abstract class FinancesEntriesItemComponent {
  @Input() item: any;
  @Input() zoneGroupNumber: number;

  constructor(protected service: FinancesEntriesService) {}

  toggleItem(type, id) {
    this.service.toggleItem(type, id);
  }

  selectItem(type, id, state, event = new MouseEvent('click')) {
    this.service.selectItem(type, id, state, event);
  }
}
