import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntil, takeWhile } from 'rxjs/operators';

import { isValidInterval } from '@timecount/utils';

import { TcFormValidationService } from '../../../form-validation.service';
import { TcInputBaseDirective } from '../input-base.directive';
import { isInputOfType } from '../input-helpers';
import {
  TcInputDateTimeReferenceDirective,
  TcInputTimeDateOptionsDirective,
} from '../../../shared';
import { TcInputService } from '../input.service';

@Component({
  selector: `
    tc-input-ionic,
    tc-input-email-ionic,
    tc-input-text-ionic,
    tc-input-password-ionic,
    tc-input-number-ionic,
    tc-input-time-ionic,
    tc-input-date-ionic,
    tc-input-dateTime-ionic,
  `,
  templateUrl: './input-ionic.component.html',
  styleUrls: ['./input-ionic.component.scss'],
})
export class TcInputIonicComponent
  extends TcInputBaseDirective
  implements OnChanges
{
  @Input()
  autofocus = false;

  // Add in order to bypass Angular issue of setting the control as touched
  // before a blur event
  blurred = false;

  public get isInputType(): boolean {
    return isInputOfType(this.fieldType);
  }

  constructor(
    public ngControl: NgControl,
    public elementRef: ElementRef,
    public formValidationService: TcFormValidationService,
    public inputService: TcInputService,
    @Optional() public refDateDir?: TcInputDateTimeReferenceDirective,
    @Optional() public dateOptionsDir?: TcInputTimeDateOptionsDirective,
  ) {
    super(
      ngControl,
      elementRef,
      formValidationService,
      inputService,
      refDateDir,
      dateOptionsDir,
    );
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const { tcConfig } = changes;
    const { limit } = tcConfig?.currentValue ?? {};

    if (
      isValidInterval(limit) &&
      this.fieldType.toLowerCase().includes('time')
    ) {
      this.fieldType = this.dateOptionsDir?.shouldShowDateOptions
        ? 'dateTime'
        : 'time';
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.ngControl.asyncValidator !== null) {
      this.ngControl.control.statusChanges
        .pipe(
          takeWhile(() => !this.blurred),
          takeUntil(this.destroyed$),
        )
        .subscribe((status) => {
          //the async validation errors should appear even before blurring
          //pending is a status set when an asyncValidation starts
          this.blurred = status === 'PENDING';
        });
    }
  }
}
