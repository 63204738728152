import { AfterViewInit, Directive } from '@angular/core';
import { ControlWidget } from 'ngx-schema-form';

@Directive({
  selector: '[tcHubControlWidgetBase]',
})
export class TcControlWidgetBaseDirective
  extends ControlWidget
  implements AfterViewInit
{
  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this.schema.readOnly) {
      this.control.disable();
    }
  }
}
