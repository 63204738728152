import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@timecount/ui';

import {
  DispoImport,
  DispoImportComponent,
} from '../dispo-import/dispo-import.component';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  private translations: { [key: string]: string } = {};

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {
    this.translateService
      .get(['dispo/import.modal.title'])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  create(dispoImport: DispoImport) {
    this.section(dispoImport, 'general');
  }

  createFromDateRange(begin: Date, end: Date) {
    const dispoImport = Object.assign(new DispoImport(), {
      begin: begin,
      end: end,
    });

    this.create(dispoImport);
  }

  section(
    dispoImport: DispoImport,
    section: string,
    single = false,
    additionalData = {},
  ) {
    const data = Object.assign(
      {
        import: dispoImport,
        section: section,
        single: single,
      },
      additionalData,
    );

    const ref = this.modalService.open(DispoImportComponent, {
      data: data,
      modalTitle: this.translations['dispo/import.modal.title'],
    });
  }

  single(avail: any, section: string) {
    this.section(avail, section, true);
  }
}
