import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SetCollection } from '../../core/sets-collection';
import { ContractorCollection } from '../../contractors/contractor.collection';
import { EmployeeCollection } from '../../employees/employee.collection';

@Injectable({
  providedIn: 'root',
})
export class DispoResourceCollection {
  private employeeService: SetCollection;
  private contractorService: SetCollection;

  constructor(
    private employeeSets: EmployeeCollection,
    private contractorSets: ContractorCollection,
  ) {
    this.employeeService = employeeSets.visible();
    this.contractorService = contractorSets.visible();
  }

  all(): Observable<any[]> {
    return combineLatest(
      this.employeeService.all(),
      this.contractorService.all(),
    ).pipe(
      map(([employees, contractors]: [any[], any[]]) => {
        return [].concat(employees, contractors);
      }),
    );
  }
}
