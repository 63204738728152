import { Injectable } from '@angular/core';
import { Deserializer, ExclusionPolicy, Strategy, Type } from 'typeserializer';
import { combineLatest, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { parseDate } from '@timecount/utils';

import { FrameCollection } from '../../core/frame-collection';

export enum InvitationState {
  accepted = 'accepted',
  refused = 'refused',
  open = 'open',
  cancelled = 'cancelled',
}

@Strategy(ExclusionPolicy.NONE)
export class Invitation {
  id: number;

  resource_id: number;
  resource_type: string;
  announcement_id: number;

  marker: [number, number];

  @Type(Object)
  states: Record<string, InvitationState>;

  @Deserializer((m: string): Date => parseDate(m))
  read_at: Date;

  shallow = false;

  @Type(Object)
  store: object;

  accepted_size = 0;

  // task specific state
  state?: InvitationState;
}
@Injectable({
  providedIn: 'root',
})
export class DispoInvitationCollection extends FrameCollection {
  type = Invitation;
  identifier = 'tc_dispo/invitation';
  endpoint = '/api/dispo/invitations/range';
  cache = 0;

  forResource(resource) {
    const filterFunc = (x) =>
      x.resource_id === resource.id && x.resource_type === resource.type;
    const filterLookup = `resource_id: ${resource.id}|resource_type: ${resource.type}`;

    return this.filter(filterFunc, filterLookup);
  }

  forAnnouncement(announcement) {
    const filterFunc = (i) => i.announcement_id === announcement.id;
    const filterLookup = `announcement_id:${announcement.id}`;

    return this.filter(filterFunc, filterLookup);
  }

  visibleForAnnouncement(announcement) {
    const filterFunc = (i) => {
      if (i.announcement_id === announcement.id) {
        const states = Object.values(i.states);
        return states.some(
          (s) => s === 'accepted' || s === 'refused' || s === 'cancelled',
        );
      }

      return false;
    };
    const filterLookup = `announcement_id:${announcement.id}|state:some[accepted,refused,cancelled]`;

    return this.filter(filterFunc, filterLookup);
  }

  decorate(invitation, decorators) {
    const states = Object.values(invitation.states);

    invitation.accepted_size = states.filter((s) => s === 'accepted').length;

    return invitation;
  }

  activateInvitations(invitations: Invitation[]) {
    if (invitations.every((i) => !i.shallow)) {
      return of(invitations);
    }

    return this.all().pipe(
      first(),
      switchMap((allInvitations) => {
        return combineLatest(
          ...invitations.map((invitation) => {
            const presentInvitation = allInvitations.find(
              (i) =>
                i.announcement_id === invitation.announcement_id &&
                i.resource_id === invitation.resource_id &&
                i.resource_type === invitation.resource_type,
            );

            if (presentInvitation) {
              return of(presentInvitation);
            } else {
              return this.create(invitation);
            }
          }),
        );
      }),
    );
  }
}
