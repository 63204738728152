<div class="modal-body">
  <div class="announcement" *ngFor="let announcement of announcements$ | async">
    <h5>{{ announcement.title }}</h5>
    <div class="task" *ngFor="let task of announcement.tasks">
      <strong
        >{{ task.starts_at | date: 'd. LLL' }} {{ task.job.title }}
      </strong>
      <span
        >{{ task.starts_at | date: 'HH:mm' }} -
        {{ task.ends_at | date: 'HH:mm' }}</span
      >
    </div>
    <button
      type="button"
      nz-button
      nzBlock
      nzType="primary"
      [nzLoading]="loading"
      (click)="appendTo(announcement)"
    >
      {{ 'dispo/announcement.buttons.append' | translate }}
    </button>
  </div>
  <nz-alert
    *ngIf="(announcements$ | async).length === 0 && !loading"
    nzType="error"
    nzMessage="{{
      'dispo/announcement.errors.no_matching_announcement_found' | translate
    }}"
  ></nz-alert>
</div>
