import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, map } from 'rxjs/operators';

import { SetsCollection } from '../../core/sets-collection';
import { Cable } from '../../core/cable';
import { StoreService } from '../../core/store.service';
import { ApiErrorService } from '../../core/api-error.service';
import { CurrentUserService } from '../../core/current-user.service';
import { LocalSettingsService } from '../../core/local-settings.service';

import { DispoResourceCollection } from './resource';

export const DISPO_MESSAGE_TEMPLATES = {
  task: {
    template: 'dispo_task',
    placeholder: 'message/template.placeholders.dispo_task',
    date: false,
  },
  announcement: {
    template: 'dispo_announcement',
    placeholder: 'message/template.placeholders.dispo_announcement',
    date: true,
  },
  schedule: {
    template: 'dispo_schedule',
    placeholder: 'message/template.placeholders.dispo_schedule',
    date: false,
  },
  plan: {
    template: 'dispo_plan',
    placeholder: 'message/template.placeholders.dispo_plan',
    date: true,
  },
  general: {
    template: 'dispo_general',
    placeholder: 'message/template.placeholders.dispo_general',
    date: false,
  },
};

export class DispoMessageRecipient {
  id: number;
  type: 'Contact' | 'Employee';
}

export class DispoMessage {
  id: number;
  type: 'task' | 'announcement' | 'schedule' | 'plan' | 'general';
  transmission_type: 'auto' | 'email' | 'push' | 'mobile' = 'auto';
  short_message_enabled = false;

  recipients: DispoMessageRecipient[] = [];
  subject: string;
  body: string;

  task_id?: number;
  announcement_id?: number;
  schedule_id?: number;
  plan_id?: number;
  date?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class DispoMessageCollection extends SetsCollection {
  identifier = 'tc_dispo/messages';
  endpoint = '/api/dispo/messages';

  localSearch = ['subject'];

  cache = 60 * 60 * 12;

  type = DispoMessage;

  constructor(
    protected http: HttpClient,
    protected cable: Cable,
    protected store: StoreService,
    protected errorHandler: ApiErrorService,
    protected currentUser: CurrentUserService,
    private resourceCollection: DispoResourceCollection,
    protected localSettingsService: LocalSettingsService,
    @Inject('Flash') protected flash,
  ) {
    super(
      http,
      cable,
      store,
      errorHandler,
      currentUser,
      localSettingsService,
      flash,
    );
  }

  estimatedSmsCount(message: DispoMessage) {
    return this.resourceCollection.all().pipe(
      first(),
      map((resources) => {
        let smsCount = 0;

        if (message.transmission_type === 'auto') {
          smsCount = message.recipients.filter((recipient) => {
            const resource =
              resources.find(
                (r) => r.id === recipient.id && r.type === recipient.type,
              ) || {};
            return resource.dispo_channel === 'mobile';
          }).length;
        }

        if (message.transmission_type === 'mobile') {
          smsCount = message.recipients.length;
        }

        if (smsCount === 0) {
          return 0;
        }

        const messageToInspect = message.short_message_enabled
          ? message.subject || ''
          : `${message.subject} ${message.body}`;
        const charCount = messageToInspect.length;
        const expensivePlaceholderCount = (
          messageToInspect.match(
            /{task_assignments}|{task_assignments_by_group}|{schedule_assignments}|{plan_assignments}|{plan_assignments_by_group}|{plan_assignments_by_venue}{venue_address}|{project_contacts}|{customer_contacts}/g,
          ) || []
        ).length;

        smsCount =
          smsCount *
          Math.ceil((charCount + expensivePlaceholderCount * 100) / 70);

        return smsCount;
      }),
    );
  }
}
