<form class="modal-container" [formGroup]="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <tc-interval-with-break-web
            formControlName="times"
            [tcConfig]="{
              relativeDateOptions: true
            }"
          ></tc-interval-with-break-web>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="move()"
    >
      {{ 'move' | translate }}
    </button>
  </div>
</form>
