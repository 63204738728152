import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-number-widget',
  templateUrl: './number-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './number-widget.component.scss',
  ],
})
export class NumberWidgetComponent extends TcControlWidgetBaseDirective {}
