<form class="modal-container" [formGroup]="formGroup" novalidate>
  <div class="modal-body">
    <div class="container">
      <tc-input-integer-web
        class="form-item"
        label="{{ 'aggregation_entry/work.batch.attrs.size' | translate }}"
        formControlName="size"
      ></tc-input-integer-web>

      <tc-interval-web
        class="form-item"
        formControlName="dateInterval"
        [tcFieldType]="'date'"
        [tcConfig]="{
          limit: initialDateRangeValue,
          fieldType: 'date'
        }"
        [tcLabels]="{
          start: ('aggregation_entry/work.batch.attrs.range_begin' | translate),
          end: ('aggregation_entry/work.batch.attrs.range_end' | translate)
        }"
        [tcAllowZeroDuration]="true"
      ></tc-interval-web>

      <tc-interval-with-break-web
        class="form-item"
        formControlName="times"
        [tcConfig]="{
          relativeDateOptions: true
        }"
      ></tc-interval-with-break-web>

      <tc-hub-collection-select
        class="form-item half-width"
        label="{{ 'aggregation_entry/work.batch.attrs.job' | translate }}"
        #jobSelect
        [collectionTypes]="['Job']"
        [formGroup]="formGroup"
        controlName="job_id"
        bindLabel="title"
        bindValue="id"
        [searchable]="true"
      ></tc-hub-collection-select>

      <tc-hub-collection-select
        class="form-item half-width"
        label="{{ 'aggregation_entry/work.batch.attrs.venue' | translate }}"
        [collectionTypes]="['Venue']"
        [formGroup]="formGroup"
        controlName="venue_id"
        bindLabel="title"
        bindValue="id"
        [searchable]="true"
      ></tc-hub-collection-select>

      <label class="control-label" class="form-item">
        {{ 'aggregation_entry/work.batch.attrs.note' | translate }}
        <textarea
          tcFormControlValidator
          class="form-control"
          rows="3"
          name="note"
          formControlName="note"
          autosize
        ></textarea>
      </label>

      <tc-hub-custom-fields
        section="work_entry.batch.general"
        [formGroup]="formGroup"
        formControlName="store"
      ></tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" nz-button nzType="primary" (click)="prev()">
      {{ 'back' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      (click)="next()"
      [disabled]="formGroup.invalid"
    >
      {{ 'next' | translate }}
    </button>
  </div>
</form>
