<ng-container *ngIf="visible">
  <div
    class="row"
    [class.expanded]="expanded$ | async"
    [class.loading]="loading"
  >
    <div class="col col-header">
      <div class="announcement-toggle">
        <tc-hub-toggle
          [active$]="expanded$"
          (mouseup)="onToggleAnnouncement()"
        ></tc-hub-toggle>
        <span class="title">
          {{ announcement.title }}
          <span *ngIf="(announcement$ | async).venue">
            @ {{ (announcement$ | async).venue.title }}
          </span>
          <span *ngFor="let slot of announcement.task_slots">
            {{ 'dispo/announcement.attrs.slot_pos' | translate }} {{ slot + 1 }}
          </span>
        </span>
        <tc-hub-data-panel
          [data]="announcement$ | async"
          section="dispo.announcements.announcement"
          class="announcement-stats"
        ></tc-hub-data-panel>
      </div>

      <ng-container *ngIf="expanded$ | async">
        <div
          class="slot"
          *ngFor="
            let invitation of invitations$ | async;
            let even = even;
            let odd = odd;
            let first = first;
            let last = last
          "
          [ngClass]="{ odd: odd, even: even, first: first, last: last }"
          draggable="true"
          drag="timecount/resources"
          data-resource_type="Employee"
          [attr.data-invitationid]="invitation.id"
          [attr.data-resource_id]="invitation.resource.id"
        >
          <div class="position-wrapper">
            <span class="position"></span>
          </div>
          <div class="content">
            {{ invitation.resource.name }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="col-body select-content">
      <div
        class="col"
        *ngFor="
          let task of tasks$ | async;
          trackBy: trackByDateAndId;
          let first = first;
          let last = last
        "
        [ngClass]="{ first: first, last: last, weekend: task.weekend }"
      >
        <ng-container *ngIf="!task.blank">
          <div class="task">
            <div
              class="day day-header selectable"
              [ngClass]="{
                empty: task.empty,
                unfilled: task.unfilled,
                filled: task.filled,
                over: task.accepted_size > task.size
              }"
              nz-tooltip
              nzTooltipTitle="{{ task.task.title }}: {{
                task.task.starts_at | date: 'HH:mm'
              }} - {{ task.task.ends_at | date: 'HH:mm' }}"
              tcHubDispoMenuActive
              activeType="DispoAnnouncementSlot"
              [activeMatch]="slotFor(task)"
              [attr.data-hslot]="task.marker[0]"
              [attr.data-taskid]="task.id"
            >
              <span
                class="accepted"
                *ngIf="!task.filled || task.accepted_size > task.size"
              >
                {{ task.accepted_size }}/</span
              ><strong class="actual">{{ task.size }}</strong>
            </div>
            <ng-container *ngIf="expanded$ | async">
              <div
                class="day selectable"
                drag="timecount/dispo_assignments"
                *ngFor="
                  let slot of task.slots;
                  let first = first;
                  let last = last;
                  let even = even;
                  let odd = odd
                "
                [ngClass]="{
                  assigned: slot.assigned,
                  missing: slot.missing,
                  collision: slot.collision,
                  unavailable: slot.unavailable,
                  cancelled: slot.cancelled,
                  blank: slot.blank,
                  first: first,
                  last: last,
                  even: even,
                  odd: odd
                }"
                [attr.data-hslot]="task.marker[0]"
                [attr.data-invitationid]="slot.invitation_id"
                [attr.data-taskid]="slot.task_id"
                [attr.data-assignmentid]="slot.assignment_id"
              >
                <i
                  nz-icon
                  nzType="check"
                  *ngIf="slot.state === 'accepted'"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.tooltip.accepted' | translate
                  }}"
                ></i>
                <i
                  nz-icon
                  nzType="close-circle"
                  *ngIf="slot.state === 'cancelled'"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.tooltip.cancelled' | translate
                  }}"
                ></i>
                <i
                  nz-icon
                  nzType="stop"
                  *ngIf="slot.state === 'refused'"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.tooltip.refused' | translate
                  }}"
                ></i>
                <i
                  nz-icon
                  nzType="link"
                  *ngIf="slot.assigned"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.tooltip.assigned' | translate
                  }}"
                ></i>
                <i
                  nz-icon
                  nzType="warning"
                  *ngIf="slot.collision"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.tooltip.collision' | translate
                  }}"
                ></i>
                <i
                  nz-icon
                  nzType="close"
                  *ngIf="slot.unavailable"
                  nz-tooltip
                  nzTooltipTitle="{{
                    'dispo/invitation.tooltip.unavailable' | translate
                  }}"
                ></i>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!visible">
  <div
    class="row row-full"
    [class.expanded]="expanded$ | async"
    [class.loading]="loading"
    tcHubObserveVisibility
    [debounceTime]="300"
    [threshold]="0.05"
    (visible)="onVisible()"
  >
    <div class="col col-header">
      <div class="announcement-toggle">
        <div class="toggle-header">
          <span class="title">
            <span>loading...</span>
          </span>
        </div>
        <div class="announcement-stats">loading...</div>
      </div>

      <ng-container *ngIf="expanded$ | async">
        <div
          class="slot"
          *ngFor="
            let invitation of invitations$ | async;
            let even = even;
            let odd = odd;
            let first = first;
            let last = last
          "
          [ngClass]="{ odd: odd, even: even, first: first, last: last }"
        >
          <div class="position-wrapper">
            <span class="position"></span>
          </div>
          <div class="content">
            {{ invitation.resource.name }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
