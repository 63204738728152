import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-email-widget',
  templateUrl: './email-widget.component.html',
  styleUrls: ['../shared-widgets-styles.scss', './email-widget.component.scss'],
})
export class EmailWidgetComponent extends TcControlWidgetBaseDirective {}
