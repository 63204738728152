import { ExtendedRenderer, MarkedOptions } from 'ngx-markdown';

class CustomRenderer extends ExtendedRenderer {
  checkbox: (checked: boolean) => string;
}

export function MarkdownOptionsFactory(): MarkedOptions {
  const renderer = new CustomRenderer();

  renderer.listitem = (text: string) => {
    if (text.includes('<input type="checkbox"')) {
      return `<li><label>${text}</label></li>`;
    } else {
      return `<li>${text}</li>`;
    }
  };

  renderer.checkbox = (checked: boolean) => {
    return `<input type="checkbox" ${checked ? 'checked' : ''}> `;
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}
