<ng-container>
  <div
    #container
    [ngClass]="{
      'ui-splitbutton ui-buttonset ui-widget': true,
      'ui-state-disabled': disabled
    }"
    [ngStyle]="style"
    [class]="styleClass"
  >
    <a class="btn btn-link" (click)="onDropdownButtonClick($event)">
      <span class="text">{{ label }}</span>
      <i class="{{ icon || 'pi pi-chevron-down' }}"></i>
    </a>
    <div
      #overlay
      [ngClass]="
        'ui-menu ui-menu-dynamic ui-widget ui-widget-content ui-corner-all ui-helper-clearfix ui-shadow'
      "
      *ngIf="overlayVisible"
      [ngStyle]="menuStyle"
      [class]="menuStyleClass"
      [@overlayAnimation]="{
        value: 'visible',
        params: {
          showTransitionParams: showTransitionOptions,
          hideTransitionParams: hideTransitionOptions
        }
      }"
      (@overlayAnimation.start)="onOverlayAnimationStart($event)"
    >
      <ul class="ui-menu-list ui-helper-reset">
        <ng-template ngFor let-item [ngForOf]="items$ | async">
          <li
            class="ui-menuitem ui-widget ui-corner-all"
            role="menuitem"
            *ngIf="item.visible !== false"
          >
            <a
              *ngIf="!item.routerLink"
              [attr.href]="item.url"
              class="ui-menuitem-link ui-corner-all"
              [attr.target]="item.target"
              [ngClass]="{ 'ui-state-disabled': item.disabled }"
              (click)="itemClick($event, item)"
            >
              <span
                [ngClass]="'ui-menuitem-icon'"
                [class]="item.icon"
                *ngIf="item.icon"
              ></span>
              <span class="ui-menuitem-text">{{ item.label }}</span>
            </a>
            <a
              *ngIf="item.routerLink"
              [routerLink]="item.routerLink"
              [queryParams]="item.queryParams"
              class="ui-menuitem-link ui-corner-all"
              [attr.target]="item.target"
              [ngClass]="{ 'ui-state-disabled': item.disabled }"
              (click)="itemClick($event, item)"
            >
              <span
                [ngClass]="'ui-menuitem-icon'"
                [class]="item.icon"
                *ngIf="item.icon"
              ></span>
              <span class="ui-menuitem-text">{{ item.label }}</span>
            </a>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</ng-container>
