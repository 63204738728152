import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import { DispoTaskCollection } from '../../collections/task';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';
import {
  DispoInvitationCollection,
  Invitation,
} from '../../collections/invitation';

@Component({
  selector: 'tc-hub-dan-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css'],
})
export class DANDeleteComponent implements OnInit {
  @Input() announcement: Announcement;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  loading = true;
  messages: string[] = [];
  invitations: Invitation[] = [];

  constructor(
    private announcementCollection: DispoAnnouncementCollection,
    private invitationCollection: DispoInvitationCollection,
    private taskCollection: DispoTaskCollection,
  ) {}

  ngOnInit(): void {
    combineLatest(
      this.announcementCollection.deletable(
        this.announcement.id,
        this.announcement,
      ),
      this.invitationCollection.forAnnouncement(this.announcement),
    )
      .pipe(first())
      .subscribe({
        next: ([deletable, invitations]) => {
          this.loading = false;
          this.messages = Object.values(deletable.messages);
          this.invitations = invitations;
        },
      });
  }

  delete(): void {
    this.loading = true;

    this.announcementCollection
      .delete(this.announcement.id, this.announcement)
      .subscribe({
        next: () => {
          this.loading = false;
          this.signal.emit({ action: 'close' });
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
