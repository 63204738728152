import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DispoMenuPlan } from './plan.service';
import { DispoMenuTask } from './task.service';
import { DispoMenuSchedule } from './schedule.service';
import { DispoMenuEmployee } from './employee.service';
import { DispoMenuAssignment } from './assignment.service';
import { DispoMenuAvailability } from './availability.service';
import { DispoMenuTaskSlot } from './task_slot.service';
import { DispoMenuDate } from './date.service';
import { DispoMenuInvitationSlot } from './invitation_slot.service';
import { DispoMenuAnnouncement } from './announcement.service';
import { DispoMenuAnnouncementSlot } from './announcement_slot.service';
import { DispoMenuInvitation } from './invitation.service';
import { DispoMenuTimeTracking } from './time-tracking.service';
import { DispoMenuAggregationEntry } from './aggregation-entry.service';

export class ClickContext {
  item_type: string;
  item: any;
}

export interface MenuItem {
  header?: boolean;
  label?: string;
  icon?: string;
  // Ng-Zorro icon theme (default: 'outline')
  iconNzTheme?: 'fill' | 'outline' | 'twotone';
  command?: (event?: any) => void;
  url?: string;
  routerLink?: any;
  queryParams?: {
    [k: string]: any;
  };
  items?: MenuItem[];
  expanded?: boolean;
  disabled?: boolean;
  visible?: boolean;
  target?: string;
  routerLinkActiveOptions?: any;
  separator?: boolean;
  badge?: string;
  badgeStyleClass?: string;
  style?: any;
  styleClass?: string;
  title?: string;
  id?: string;
  automationId?: any;
  tabindex?: string;
}

export const convertToPrimeMenuItem = (
  items: MenuItem[],
  maxCount = 5,
  count = 0,
) => {
  count = count + 1;

  return items.map((item) => {
    if (item.header) {
      item.styleClass = 'ui-menuitem-header';
    }

    if (item.items && count < maxCount) {
      item.items = convertToPrimeMenuItem(item.items, maxCount, count);
    } else {
      delete item.items;
    }

    return item;
  });
};

const MENU_ORDER = {
  DispoDate: 0,
  DispoAssignment: 1,
  Timesheet: 1,
  AggregationEntry: 1,
  DispoSlot: 2,
  DispoInvitationSlot: 3,
  DispoInvitation: 4,
  DispoAnnouncementSlot: 5,
  DispoTask: 6,
  DispoSchedule: 7,
  DispoAnnouncement: 8,
  DispoPlanDay: 9,
  DispoPlan: 10,
  DispoAvailability: 11,
  Employee: 13,
  Date: 14,
};

const CONTEXT_ORDER = {
  DispoDate: 0,
  DispoSlot: 1,
  DispoAssignment: 2,
  Timesheet: 2,
  AggregationEntry: 2,
  DispoInvitationSlot: 3,
  DispoInvitation: 4,
  DispoAnnouncementSlot: 5,
  DispoTask: 6,
  DispoSchedule: 7,
  DispoAnnouncement: 8,
  DispoPlanDay: 9,
  DispoPlan: 10,
  DispoAvailability: 11,
  Employee: 13,
  Date: 14,
};

@Injectable({
  providedIn: 'root',
})
export class DispoMenuService {
  public items: EventEmitter<any> = new EventEmitter();
  public contexts: ReplaySubject<any> = new ReplaySubject(1);

  private translations: { [key: string]: string } = {};

  FORCED_CONTEXTS = ['Timesheet'];

  constructor(
    private translateService: TranslateService,
    private assignmentMenu: DispoMenuAssignment,
    private taskSlotMenu: DispoMenuTaskSlot,
    private announcementMenu: DispoMenuAnnouncement,
    private announcementSlotMenu: DispoMenuAnnouncementSlot,
    private invitationMenu: DispoMenuInvitation,
    private invitationSlotMenu: DispoMenuInvitationSlot,
    private planMenu: DispoMenuPlan,
    private taskMenu: DispoMenuTask,
    private dateMenu: DispoMenuDate,
    private scheduleMenu: DispoMenuSchedule,
    private availabilityMenu: DispoMenuAvailability,
    private employeeMenu: DispoMenuEmployee,
    private timeTrackingMenu: DispoMenuTimeTracking,
    private aggregationEntryMenu: DispoMenuAggregationEntry,
  ) {
    this.translateService
      .get([
        'dispo/employee.menu.short',
        'dispo/plan.menu.short',
        'dispo/plan.menu.short',
        'dispo/task.date.menu.short',
        'dispo/task.menu.short',
        'dispo/schedule.menu.short',
        'dispo/assignment.menu.short',
        'dispo/availability.menu.short',
        'dispo/task_slot.menu.short',
        'dispo/announcement.menu.short',
        'dispo/announcement_slot.menu.short',
        'dispo/invitation.menu.short',
        'dispo/invitation_slot.menu.short',
        'timesheet.menu.short',
        'aggregation_entry.menu.short',
      ])
      .subscribe((value) => {
        Object.assign(this.translations, value);
      });
  }

  reset() {
    this.contexts.next([]);
    this.items.emit({ originalEvent: { button: 0 }, items: [of([])] });
  }

  onViewClick(
    event: MouseEvent,
    view: string,
    contexts: ClickContext[] = [],
  ): void {
    if (contexts) {
      contexts = [...contexts];
      this.FORCED_CONTEXTS.forEach((forcedContext) => {
        if (!contexts.find((c) => c.item_type === forcedContext)) {
          contexts.push({ item: [], item_type: forcedContext });
        }
      });

      const menuItems = [...contexts]
        .sort((a, b) => {
          return (
            (MENU_ORDER[a.item_type] || 99) - (MENU_ORDER[b.item_type] || 99)
          );
        })
        .map((context) => {
          return this.itemsFor(
            context,
            view,
            contexts,
            event,
            MENU_ORDER[context.item_type] || 99,
          );
        });

      const menuContexts = [...contexts]
        .sort((a, b) => {
          return (
            (CONTEXT_ORDER[a.item_type] || 99) -
            (CONTEXT_ORDER[b.item_type] || 99)
          );
        })
        .filter((context) => {
          return context.item.length > 0;
        });
      this.contexts.next(menuContexts);
      this.items.emit({ originalEvent: event, items: menuItems });
    }
  }

  itemsFor(
    context: ClickContext,
    view,
    otherContexts: ClickContext[],
    event: MouseEvent,
    prio,
  ): Observable<any[]> {
    const menu =
      {
        Employee: this.employeesItems,
        DispoPlan: this.plansItems,
        DispoPlanDay: this.plansDayItems,
        DispoDate: this.dateItems,
        DispoTask: this.tasksItems,
        DispoSchedule: this.schedulesItems,
        DispoAssignment: this.assignmentsItems,
        DispoAvailability: this.availabilitiesItems,
        DispoSlot: this.slotsItems,
        DispoAnnouncementSlot: this.announcementSlotsItems,
        DispoAnnouncement: this.announcementsItems,
        DispoInvitation: this.invitationsItems,
        DispoInvitationSlot: this.invitationSlotsItems,
        Timesheet: this.timesheetItems,
        AggregationEntry: this.aggregationEntryItems,
      }[context.item_type] || false;

    const langKey =
      {
        Employee: 'dispo/employee',
        DispoPlan: 'dispo/plan',
        DispoPlanDay: 'dispo/plan',
        DispoTask: 'dispo/task',
        DispoDate: 'dispo/task.date',
        DispoSchedule: 'dispo/schedule',
        DispoAssignment: 'dispo/assignment',
        DispoAvailability: 'dispo/availability',
        DispoSlot: 'dispo/task_slot',
        DispoAnnouncement: 'dispo/announcement',
        DispoAnnouncementSlot: 'dispo/announcement_slot',
        DispoInvitation: 'dispo/invitation',
        DispoInvitationSlot: 'dispo/invitation_slot',
        Timesheet: 'timesheet',
        AggregationEntry: 'aggregation_entry',
      }[context.item_type] || false;

    if (!menu) {
      return of([]);
    }

    return menu.call(this, context, view, otherContexts).pipe(
      map((items: MenuItem[]) => {
        if (items.length > 0) {
          return [
            {
              header: true,
              label: this.translations[`${langKey}.menu.short`],
              expanded: prio <= 2,
              items: items,
            },
          ];
        } else {
          return [];
        }
      }),
    );
  }

  private employeesItems(context, view, otherContexts) {
    const employees = context.item;

    if (employees && employees.length === 1) {
      return this.employeeMenu.items(employees[0]);
    } else {
      return of([]);
    }
  }

  private plansItems(context, view, otherContexts) {
    const plans = context.item;

    if (plans && plans.length === 1) {
      return this.planMenu.items(plans[0]);
    }

    // TODO: add date menu
    // else if (dates && dates.length === 1) {
    //   return this.planMenu.base(dates[0]);
    // }

    return of([]);
  }

  private plansDayItems(context, view, otherContexts) {
    const plans = context.item;

    if (plans && plans.length === 1) {
      const date = new Date(plans[0].date);
      return this.planMenu.itemsForDate(plans[0], date);
    }

    return of([]);
  }

  private schedulesItems(context, view, otherContexts) {
    const slotsItem = otherContexts.find((c) => c.item_type === 'DispoSlot');
    const slots = slotsItem ? slotsItem.item : undefined;
    const schedules = context.item;

    if (schedules && schedules.length === 1 && slots) {
      return this.scheduleMenu.itemsForSlots(schedules[0], slots);
    } else if (schedules && schedules.length === 1) {
      return this.scheduleMenu.items(schedules[0]);
    } else {
      return of([]);
    }
  }

  private tasksItems(context, view, otherContexts) {
    const tasks = context.item;

    if (tasks && tasks.length > 1) {
      return this.taskMenu.itemsForTasks(tasks);
    } else if (tasks && tasks.length === 1) {
      return this.taskMenu.itemsForTask(tasks[0]);
    } else {
      return of([]);
    }
  }

  private dateItems(context, view, otherContexts) {
    const dates = context.item;

    return dates.length === 1 ? this.dateMenu.itemsForDate(dates[0]) : of([]);
  }

  private assignmentsItems(context, view, otherContexts) {
    const assignments = context.item;

    if (assignments && assignments.length > 0) {
      return this.assignmentMenu.items(assignments);
    } else {
      return of([]);
    }
  }

  private timesheetItems(context, view, otherContexts) {
    const timesheets = context.item || [];
    const assignments =
      otherContexts.find((c) => c.item_type === 'DispoAssignment')?.item || [];

    if (timesheets.length > 0 || assignments.length > 0) {
      return this.timeTrackingMenu.items(timesheets, assignments);
    } else {
      return of([]);
    }
  }

  private aggregationEntryItems(context, view, otherContexts) {
    const aggregationEntries = context.item || [];

    if (aggregationEntries.length > 0) {
      return this.aggregationEntryMenu.items(aggregationEntries);
    } else {
      return of([]);
    }
  }

  private announcementsItems(context, view, otherContexts) {
    const announcements = context.item;

    if (announcements && announcements.length === 1) {
      return this.announcementMenu.items(announcements[0]);
    }

    return of([]);
  }

  private announcementSlotsItems(context, view, otherContexts) {
    const announcementsItem = otherContexts.find(
      (c) => c.item_type === 'DispoAnnouncement',
    );
    const announcements = announcementsItem
      ? announcementsItem.item
      : undefined;
    const tasksItem = otherContexts.find((c) => c.item_type === 'DispoTask');
    const tasks = tasksItem ? tasksItem.item : undefined;

    if (
      announcements &&
      announcements.length === 1 &&
      tasks &&
      tasks.length > 0
    ) {
      return this.announcementSlotMenu.items(announcements[0], tasks);
    }

    return of([]);
  }

  private slotsItems(context, view, otherContexts) {
    const schedulesItem = otherContexts.find(
      (c) => c.item_type === 'DispoSchedule',
    );
    const schedules = schedulesItem ? schedulesItem.item : undefined;
    const tasksItem = otherContexts.find((c) => c.item_type === 'DispoTask');
    const tasks = tasksItem ? tasksItem.item : undefined;
    const assignmentsItem = otherContexts.find(
      (c) => c.item_type === 'DispoAssignment',
    );
    const assignments = assignmentsItem ? assignmentsItem.item : [];
    const slots = context.item;

    if (schedules && schedules.length === 1) {
      return this.taskSlotMenu.itemsForSchedule(
        slots,
        schedules[0],
        assignments,
      );
    } else if (tasks && tasks.length === 1) {
      return this.taskSlotMenu.itemsForTask(slots, tasks[0], assignments);
    }

    return of([]);
  }

  private availabilitiesItems(context, view, otherContexts) {
    const datesItem = otherContexts.find((c) => c.item_type === 'Date');
    const dates = datesItem ? datesItem.item : undefined;
    const employeesItem = otherContexts.find((c) => c.item_type === 'Employee');
    const employees = employeesItem ? employeesItem.item : undefined;
    const avails = context.item;

    if (employees && employees.length === 1 && dates && dates.length === 1) {
      return this.availabilityMenu.itemsForResourceAndDate(
        avails,
        employees[0],
        dates[0],
      );
    } else if (employees && employees.length === 1) {
      return this.availabilityMenu.itemsForResource(avails, employees[0]);
    }

    return of([]);
  }

  private invitationsItems(context, view, otherContexts) {
    const announcementsItem = otherContexts.find(
      (c) => c.item_type === 'DispoAnnouncement',
    );
    const announcements = announcementsItem
      ? announcementsItem.item
      : undefined;

    if (announcements && announcements.length === 1) {
      return this.invitationMenu.itemsForAnnouncement(
        context.item,
        announcements[0],
      );
    }

    return of([]);
  }

  private invitationSlotsItems(context, view, otherContexts) {
    const invitationSlots = context.item;
    const invitationsItem = otherContexts.find(
      (c) => c.item_type === 'DispoInvitation',
    );
    const invitations = invitationsItem ? invitationsItem.item : undefined;
    const announcementsItem = otherContexts.find(
      (c) => c.item_type === 'DispoAnnouncement',
    );
    const announcements = announcementsItem
      ? announcementsItem.item
      : undefined;

    if (announcements && announcements.length === 1) {
      return this.invitationSlotMenu.itemsForAnnouncement(
        context.item,
        announcements[0],
        invitations,
      );
    }

    return of([]);
  }
}
