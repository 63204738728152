import { Language } from './language.model';

/**
 * Supported languages.
 */
export class Languages {
  /**
   * English language
   */
  public static EN_US: Language = {
    isoCode: 'en-US',
    translationKey: 'GENERAL.LANGUAGES.EN.FULL',
    shortTranslationKey: 'GENERAL.LANGUAGES.EN.SHORT',
    code: 'en',
    region: 'US',
  };

  /**
   * French language
   */
  public static DE_DE: Language = {
    isoCode: 'de-DE',
    translationKey: 'GENERAL.LANGUAGES.DE.FULL',
    shortTranslationKey: 'GENERAL.LANGUAGES.DE.SHORT',
    code: 'de',
    region: 'DE',
  };
}

export const supportedLanguages = [Languages.EN_US, Languages.DE_DE] as const;

export const defaultLanguage = Languages.DE_DE;
