import { Component, OnInit } from '@angular/core';

import { BaseModalComponent } from '@timecount/ui';

import { Assignment } from '../collections/assignment.model';

@Component({
  selector: 'tc-hub-dispo-assignment',
  templateUrl: './dispo-assignment.component.html',
  styleUrls: ['./dispo-assignment.component.scss'],
})
export class DispoAssignmentComponent
  extends BaseModalComponent
  implements OnInit
{
  assignments: Assignment[] = [];

  tabs = {
    general: 0,
    availability: 1,
    assignContractor: 0,
    assignEmployee: 1,
  };

  ngOnInit() {
    super.ngOnInit();
    this.assignments = this.config.data.assignments;
  }
}
