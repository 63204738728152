<tc-button-ionic
  class="date-time-button {{ fieldType }}"
  size="small"
  [color]="
    ngControl?.errors?.required
      ? 'warning'
      : ngControl.control.invalid
      ? 'danger'
      : ngControl.control.value
      ? 'primary'
      : 'medium'
  "
  [disabled]="isDisabled"
  (click)="openDateTimePicker($event)"
>
  {{ (value | date : buttonDisplayFormat) || placeholder }}
</tc-button-ionic>

<ion-popover
  #popover
  class="popover {{ fieldType }}"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
>
  <ng-template>
    <ion-datetime
      hourCycle="h23"
      [id]="datepickerId"
      [locale]="localeIsoCode"
      [presentation]="fieldType === 'dateTime' ? 'date-time' : fieldType"
      [disabled]="isDisabled"
      [value]="value | dateISO"
      [min]="(min | dateISO) || undefined"
      [max]="(max | dateISO) || undefined"
      [minuteValues]="stepMinutesArray"
      cancelText="{{ 'GENERAL.ACTIONS.CANCEL' | translate }}"
      doneText="{{ 'GENERAL.ACTIONS.DONE' | translate }}"
      (ionChange)="onIonChange($event)"
    ></ion-datetime>
  </ng-template>
</ion-popover>
