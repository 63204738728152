<div class="modal-container">
  <div class="modal-body">
    <nz-table
      nzVirtualScroll
      nzSize="small"
      nzVirtualItemSize="27"
      nzVirtualMinBufferPx="200"
      nzVirtualMaxBufferPx="400"
      [nzData]="invitations$ | async"
      [nzVirtualForTrackBy]="trackByIndex"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzScroll]="{ x: '500px', y: '412px' }"
    >
      <thead>
        <tr>
          <th
            nzWidth="50px"
            nzShowCheckbox
            [(nzChecked)]="resourcesRemoveAll"
            [nzIndeterminate]="resourcesRemoveAllIndeterminate"
            (nzCheckedChange)="updateAllResources()"
          ></th>
          <th nzWidth="450px">Name</th>
        </tr>
      </thead>
      <tbody>
        <ng-template nz-virtual-scroll let-invitation let-index="index">
          <tr>
            <td
              nzWidth="50px"
              nzShowCheckbox
              [nzDisabled]="invitation.disabled"
              [(nzChecked)]="resourcesToRemove[invitation.resource_id]"
              (nzCheckedChange)="updateSingleResource()"
            ></td>
            <td nzWidth="450px">{{ invitation.resource?.name }}</td>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      [nzLoading]="loading"
      (click)="deleteSelected()"
    >
      {{ 'dispo/invitation.actions.remove_resources' | translate }}
      ({{ removeCount() }})
    </button>
  </div>
</div>
