import { Component } from '@angular/core';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-mask-widget',
  templateUrl: './mask-widget.component.html',
  styleUrls: ['../shared-widgets-styles.scss', './mask-widget.component.scss'],
})
export class MaskWidgetComponent extends TcControlWidgetBaseDirective {}
