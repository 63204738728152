import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isAfter, isBefore, startOfMinute } from 'date-fns';

import { isValidDate, isValidInterval } from '@timecount/utils';

export class TcFormGroupTimeSetValidators {
  /**
   * Validates a Time Set form group.
   */
  static timeSet(control: AbstractControl): ValidationErrors | null {
    // Debounce to avoid flickering with errors when masked inputs are not
    // finishing emitting their values
    setTimeout(() => {
      const mainControl = control.get('mainInterval');
      const breakControl = control.get('breakInterval');

      const mainControlValue = mainControl.value;

      if (isValidInterval(mainControlValue)) {
        const error = { [`invalid_intermission_range`]: true };
        const { start, end } = breakControl.value as Interval;

        if (
          isValidDate(start as Date) &&
          isBefore(startOfMinute(start), mainControlValue.start)
        ) {
          breakControl.get('start').setErrors(error);
        }

        if (
          isValidDate(end as Date) &&
          isAfter(startOfMinute(end), mainControlValue.end)
        ) {
          breakControl.get('end').setErrors(error);
        }
      }
    }, 100);

    return null;
  }
}
