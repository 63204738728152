import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { add, sub } from 'date-fns';

@Directive({
  selector: '[tcHubDispoShiftGridItem]',
})
export class DispoShiftGridItemDirective implements OnChanges {
  @Input() begin: Date;
  @Input() end?: Date;
  @Input() panelBegin: Date;
  @Input() panelEnd: Date;
  @Input() resolution = 24; // TODO: read from ShiftGrid 1 => 1 Spalte pro Tag, 24 => 1 Spalte pro Stunde
  @Input() row = undefined;

  @Input() cutoffStart = false;
  @Input() cutoffEnd = false;

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    this.redraw();
  }

  private redraw() {
    if (!this.begin || !this.panelBegin || !this.panelEnd) {
      return;
    }

    const secondsInADay = 60 * 60 * 24;

    const beginDate = add(this.begin, { days: Number(this.cutoffStart ?? 0) });
    const begin =
      (beginDate.getTime() - beginDate.getTimezoneOffset() * 60 * 1000) / 1000;
    const endDate = sub(this.end ?? this.begin, {
      days: Number(this.cutoffEnd ?? 0),
    });
    const end =
      (endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000) / 1000;

    const panelBegin =
      (this.panelBegin.getTime() -
        this.panelBegin.getTimezoneOffset() * 60 * 1000) /
      1000;
    const panelEnd =
      (this.panelEnd.getTime() -
        this.panelEnd.getTimezoneOffset() * 60 * 1000) /
      1000;

    const gridColumnStart =
      begin <= panelBegin
        ? 1
        : 1 +
          Math.floor((this.resolution * (begin - panelBegin)) / secondsInADay);

    const gridColumnEnd =
      end > panelEnd
        ? 1 +
          Math.ceil((this.resolution * (panelEnd - panelBegin)) / secondsInADay)
        : 1 + Math.ceil((this.resolution * (end - panelBegin)) / secondsInADay);

    this.el.nativeElement.style.gridColumnStart = gridColumnStart;
    this.el.nativeElement.style.gridColumnEnd = gridColumnEnd;

    if (this.row) {
      this.el.nativeElement.style.gridRowStart = this.row;
    }
  }
}
