import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import { DispoTaskCollection, Task } from '../../collections/task';
import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';
import { DispoLoaderService } from '../../loader/loader.service';
import {
  DispoInvitationCollection,
  Invitation,
} from '../../collections/invitation';

@Component({
  selector: 'tc-hub-dan-detach-tasks',
  templateUrl: './detach-tasks.component.html',
  styleUrls: ['./detach-tasks.component.css'],
})
export class DANDetachTasksComponent implements OnInit {
  @Input() announcement: Announcement;
  @Input() tasks: Task[];
  @Output() signal: EventEmitter<any> = new EventEmitter();

  affectedInvitations: Invitation[];
  affectedAnnouncements: Announcement[];
  loading = false;

  constructor(
    private loadingService: DispoLoaderService,
    private taskCollection: DispoTaskCollection,
    private announcementCollection: DispoAnnouncementCollection,
    private invitationCollection: DispoInvitationCollection,
  ) {}

  ngOnInit() {
    this.invitationCollection
      .all()
      .pipe(first())
      .subscribe({
        next: (invitations) => {
          invitations = invitations.filter(
            (i) => i.announcement_id === this.announcement.id,
          );
          this.affectedInvitations = invitations;

          if (!invitations.some((i) => i.read_at)) {
            this.detachAndReset();
          }
        },
      });
  }

  detachAndReset() {
    this.loading = true;

    if (this.affectedInvitations.length === 0) {
      this.detach();
    } else {
      const subjects = this.affectedInvitations.map((invitation) => {
        invitation = Object.assign({}, invitation);
        this.tasks.forEach((task) => delete invitation.states[task.date]);
        return this.invitationCollection.update(invitation.id, invitation);
      });

      combineLatest(subjects).subscribe({
        next: (items) => {
          this.detach();
        },
      });
    }
  }

  detach() {
    this.loading = true;

    const announcement = {
      ...this.announcement,
      task_ids: [...this.announcement.task_ids],
      sizes: { ...this.announcement.sizes },
    };

    this.tasks.forEach((task) => {
      announcement.task_ids = announcement.task_ids.filter(
        (id) => id !== task.id,
      );
      delete announcement.sizes[task.date];
    });

    this.announcementCollection
      .update(announcement.id, announcement)
      .subscribe({
        next: () => {
          this.loading = false;
          this.signal.emit({ action: 'close' });
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
