import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  AsyncValidator,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  UntypedFormBuilder,
  ValidationErrors,
} from '@angular/forms';
import { ReplaySubject } from 'rxjs';

import {
  asyncStatusOfControl,
  BaseModalComponent,
  ModalConfig,
  ModalRef,
  ValueAccessorDirective,
} from '@timecount/ui';
import { getUTCDate, isValidInterval } from '@timecount/utils';

import { AggregationImporterSettings } from '../../aggregation-importer-settings.model';

@Component({
  selector: 'tc-hub-aggregations-import-filter-form',
  templateUrl: './aggregations-import-filter-form.component.html',
  styleUrls: ['./aggregations-import-filter-form.component.scss'],
  hostDirectives: [ValueAccessorDirective],
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TcAggregationsImportFilterFormComponent,
      multi: true,
    },
  ],
})
export class TcAggregationsImportFilterFormComponent
  extends BaseModalComponent
  implements OnInit, OnDestroy, AsyncValidator
{
  formGroup;

  @Input() importer: string;
  @Input() tcProjectId: number;
  @Output() filterValues: EventEmitter<any> = new EventEmitter();

  tasksUrl$ = new ReplaySubject(1);
  schedulesUrl$ = new ReplaySubject(1);

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public valueAccessor: ValueAccessorDirective<
      Partial<AggregationImporterSettings>
    >,
    @Optional() protected config?: ModalConfig,
    @Optional() protected modalRef?: ModalRef,
  ) {
    super(config, modalRef);
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    this.valueAccessor.value.subscribe(
      (value: Partial<AggregationImporterSettings>) => {
        if (this.formGroup instanceof FormGroup) {
          this.formGroup.patchValue(value);
        } else {
          this._buildForm(value);
        }
      },
    );

    this.valueAccessor.disabled.subscribe((isDisabled: boolean) => {
      if (this.formGroup?.disabled !== isDisabled) {
        if (isDisabled) {
          this.formGroup.disable();
        } else {
          this.formGroup.enable();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // Validator Implementation
  // ---------------------------------------------------------------------------

  validate(): Promise<ValidationErrors | null> {
    return asyncStatusOfControl(this.formGroup);
  }

  // --------------
  // Public Methods
  // --------------

  onIntervalTypeSelected(interval) {
    this.formGroup.get('intervalFilter').setValue(interval);
  }

  taskDecorator(task) {
    return {
      ...task,
      dateLabel: [task.date, task.title].filter(Boolean).join(' '),
    };
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm(value: Partial<AggregationImporterSettings>) {
    const {
      gratis_type,
      resources,
      resource_type,
      skus,
      entry_type,
      availability_types,
      venues,
      storno,
      target_time,
      gross_duration,
      events,
      offset,
      schedules,
      tasks,
      starts_at,
      ends_at,
    } = value ?? {};

    const intervalValue = {
      start: getUTCDate(starts_at),
      end: getUTCDate(ends_at),
    };

    this.formGroup = this._formBuilder.group({
      intervalFilter: [isValidInterval(intervalValue) ? intervalValue : null],
      resources: [resources],
      resource_type: [resource_type],
      skus: [skus],
      entry_type: [entry_type],
      venues: [venues],
      target_time: [target_time],
      gross_duration: [gross_duration],
      storno: [storno],
      offset: [offset],
      availability_types: [availability_types],
      gratis_type: [gratis_type],
      events: [events],
      schedules: [{ value: schedules, disabled: true }],
      tasks: [{ value: tasks, disabled: true }],
      isSubFilter: [false],
    });
    const schedulesControl = this.formGroup.get('schedules');
    const tasksControl = this.formGroup.get('tasks');
    const isSubFilterControl = this.formGroup.get('isSubFilter');

    isSubFilterControl.valueChanges.subscribe((isSubFilter) => {
      const eventsValue = this.formGroup.get('events').value;
      if (isSubFilter) {
        const singleEvent = Array.isArray(eventsValue)
          ? eventsValue.length === 1
          : eventsValue;
        if (singleEvent) {
          const eventId = Array.isArray(eventsValue)
            ? eventsValue[0]
            : eventsValue;
          this.tasksUrl$.next(`/api/dispo/plans/${eventId}/tasks`);
          this.schedulesUrl$.next(`/api/dispo/plans/${eventId}/schedules`);
        }
        schedulesControl.enable({ emitEvent: false });
        tasksControl.enable({ emitEvent: false });
      } else {
        schedulesControl.setValue(null, { emitEvent: false });
        schedulesControl.disable({ emitEvent: false });
        tasksControl.setValue(null, { emitEvent: false });
        tasksControl.disable({ emitEvent: false });
      }
    });

    schedulesControl.valueChanges.subscribe((schedules: number[]) => {
      tasksControl[schedules?.length ? 'disable' : 'enable']({
        emitEvent: false,
      });
    });

    this.formGroup.get('events').valueChanges.subscribe((events: number[]) => {
      if (
        ((Array.isArray(events) && events?.length === 1) ||
          (!Array.isArray(events) && events)) &&
        isSubFilterControl.value
      ) {
        this.tasksUrl$.next(
          `/api/dispo/plans/${this.formGroup.value.events}/tasks`,
        );
        this.schedulesUrl$.next(
          `/api/dispo/plans/${this.formGroup.value.events}/schedules`,
        );
      }
      isSubFilterControl[events?.length > 1 ? 'disable' : 'enable']();
    });

    tasksControl.valueChanges.subscribe((tasks: number[]) => {
      schedulesControl[tasks?.length ? 'disable' : 'enable']({
        emitEvent: false,
      });
    });

    this.formGroup.valueChanges.subscribe(
      (value: Partial<AggregationImporterSettings>) => {
        this.valueAccessor.valueChange(value);
        this.valueAccessor.touchedChange(true);
      },
    );
  }
}
