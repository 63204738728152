import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { TcInputValidators } from '@timecount/ui';

import { Plan } from '../../collections/plan';
import { DispoScheduleCollection, Schedule } from '../../collections/schedule';

@Component({
  selector: 'tc-hub-ds-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
})
export class DSGeneralComponent implements OnInit {
  @Input() schedule: Schedule;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private scheduleService: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.buildForm(this.schedule);
  }

  save() {
    this.loading = true;

    const { title, note, store, ...formValues } = this.form.value;
    const payload = {
      ...this.schedule,
      title: title,
      note: note,
      store: Object.assign({}, this.schedule.store, store),
      template: {
        ...this.schedule.template,
        ...formValues,
      },
      sync: ['job', 'venue', 'size'],
    };

    this.scheduleService.update(this.schedule.id, payload).subscribe({
      next: (schedule) => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
        // this.form = this.buildForm(schedule);
        // this.signal.emit({ action: 'reload', items: { schedule: schedule }});
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(schedule) {
    return this.formBuilder.group({
      title: [schedule.title, [Validators.required]],
      note: [schedule.note],
      store: [schedule.store],
      size: [
        schedule.template.size,
        [
          Validators.required,
          TcInputValidators.minMaxNumber(
            1,
            window.config.company.dispo_schedule_max_size,
          ),
        ],
      ],
      job_id: [schedule.template.job_id, [Validators.required]],
      venue_id: [schedule.template.venue_id, []],
    });
  }
}
