import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { TcInterval, TcIntervalWithBreak } from '@timecount/core';
import { TcInputValidators } from '@timecount/ui';
import { DateUnicodeFormat } from '@timecount/utils';

@Component({
  selector: 'tc-hub-batch-create-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class TcWorkEntryBatchStep2Component implements OnInit {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  formGroup: UntypedFormGroup;
  initialDateRangeValue: Interval;

  constructor(private formBuilder: UntypedFormBuilder) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnInit() {
    this._buildForm();
    this.signal.emit({ action: 'resize' });
  }

  // --------------
  // Public Methods
  // --------------

  prev() {
    this.signal.emit({ action: 'prev', config: this.config });
  }

  next() {
    const { dateInterval, store, times, ...formValues } = this.formGroup.value;
    const dateRange = TcInterval.formatForApi(dateInterval);

    const nextConfig = {
      ...this.config,
      dateInterval,
      template: {
        ...this.config.template,
        ...formValues,
        times,
      },
      store,
    };

    this.signal.emit({ action: 'next', config: nextConfig });
  }

  // ---------------
  // Private Methods
  // ---------------

  private _buildForm() {
    const { template, store, project } = this.config ?? {};
    const { size, job_id, venue_id, note } = template ?? {};

    this.initialDateRangeValue = TcInterval.parseFromApi(project, {
      parseFormat: DateUnicodeFormat.apiDate,
    });

    this.formGroup = this.formBuilder.group({
      dateInterval: [this.initialDateRangeValue],
      times: [
        TcIntervalWithBreak.fromOffSetLength(template),
        [Validators.required],
      ],
      job_id: [job_id, [Validators.required]],
      size: [
        size || 5,
        [
          Validators.required,
          TcInputValidators.minMaxNumber(
            1,
            window.config.company.dispo_schedule_max_size,
          ),
        ],
      ],
      venue_id: [venue_id ?? project.venue_ids[0]],
      note: [note],
      store: [store],
    });
  }
}
