import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { TcInputValidators } from '@timecount/ui';

import { DispoTaskCollection, Task } from '../../collections/task';
import { Plan } from '../../collections/plan';

@Component({
  selector: 'tc-hub-dt-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class DTGeneralComponent implements OnInit {
  @Input() task: Task;
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private taskService: DispoTaskCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.buildForm(this.task);
  }

  save() {
    this.loading = true;
    const formValues = this.form.value;
    const newValues = Object.assign({}, this.task, formValues);

    newValues.store = Object.assign({}, this.task.store, formValues.store);

    this.taskService.update(this.task.id, newValues).subscribe({
      next: (task: Task) => {
        this.loading = false;
        this.signal.emit({ action: 'close' });
        // this.form = this.buildForm(task);
        // this.signal.emit({ action: 'reload', items: { task: task } });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(task) {
    return this.formBuilder.group({
      title: [
        { value: task.title, disabled: task.scheduled },
        [Validators.required],
      ],
      job_id: [
        { value: task.job_id, disabled: task.scheduled },
        [Validators.required],
      ],
      plan_id: [
        { value: task.plan_id, disabled: task.scheduled },
        [Validators.required],
      ],
      venue_id: [{ value: task.venue_id, disabled: task.scheduled }, []],
      size: [
        { value: task.size, disabled: task.scheduled },
        [
          Validators.required,
          TcInputValidators.minMaxNumber(
            0,
            window.config.company.dispo_task_max_size,
          ),
        ],
      ],
      note: [task.note],
      store: [task.store],
    });
  }
}
