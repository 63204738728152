import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { add, startOfDay, sub } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DispoAssignmentCollection } from '../../collections/assignment';
import { DispoPlanCollection, Plan } from '../../collections/plan';
import { DispoScheduleCollection } from '../../collections/schedule';
import { DispoTaskCollection } from '../../collections/task';
import { ProjectCollection } from '../../../projects/project.collection';

@Component({
  selector: 'tc-hub-dp-move',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss'],
})
export class DPMoveComponent implements OnInit, OnDestroy {
  @Input() plan: Plan;

  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  loading = false;

  dateLimit: Interval;

  destroyed$ = new Subject<void>();

  readonly overlapDays = window.config.company.dispo_project_overlap_days;

  constructor(
    private planCollection: DispoPlanCollection,
    private assignmentCollection: DispoAssignmentCollection,
    private projectCollection: ProjectCollection,
    private taskCollection: DispoTaskCollection,
    private scheduleCollection: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.buildForm(this.plan);
    this.signal.emit({ action: 'resize' });

    this.form
      .get('project_id')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (project_id) => {
          this.setPlanDateLimit(project_id);
        },
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  validate() {
    // validate intermission in range
  }

  copy() {
    this.move(true);
  }

  move(copy = false) {
    this.loading = true;

    const { dateInterval, ...formValues } = this.form.value;

    const payload = {
      title: formValues.title,
      starts_at: dateInterval.start,
      ends_at: dateInterval.end,
      project_id: formValues.project_id,
    };

    const action = copy ? 'copy' : 'move';

    this.planCollection.action(action, this.plan.id, payload).subscribe({
      next: (schedule) => {
        this.loading = false;

        this.assignmentCollection.refreshCollection();
        this.taskCollection.refreshCollection();
        this.scheduleCollection.refreshCollection();
        this.planCollection.refreshCollection();

        this.signal.emit({ action: 'close' });
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  private buildForm(plan) {
    const initialDateRangeValue = {
      start: startOfDay(plan.starts_at),
      end: startOfDay(plan.ends_at),
    };

    this.setPlanDateLimit(plan.project_id);

    return this.formBuilder.group({
      project_id: [plan.project_id, [Validators.required]],
      dateInterval: [initialDateRangeValue],
      title: [plan.title, [Validators.required]],
    });
  }

  private setPlanDateLimit(project_id: number) {
    this.projectCollection.get(project_id).subscribe((project: any) => {
      this.dateLimit = {
        start: sub(project.starts_at, { days: this.overlapDays }),
        end: add(project.ends_at, { days: this.overlapDays }),
      };
    });
  }
}
