import { ExclusionPolicy, Strategy, Type } from 'typeserializer';

import { Job } from '../jobs/job.collection';

import { PayGradeRate } from './pay-grade-rate.model';

@Strategy(ExclusionPolicy.NONE)
export class PayGrade {
  id: number;
  tariff_id: number;

  title: string;
  rates: PayGradeRate[] = [];

  job_ids: number[] = [];
  jobs: Job[] = [];

  @Type(Object)
  store: Record<string, unknown>;
}
