import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { add, isBefore, isSameMinute } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  isIntervalShorterThan24Hours,
  isValidDate,
  setTimeToDate,
} from '@timecount/utils';

import { TcInputFieldType } from '../components/input/input-field-type.model';

/**
 * The tcReferenceDate directive
 */
@Directive({
  selector: `
    tc-input-web[tcReferenceDate][fieldType],
    tc-input-ionic[tcReferenceDate][fieldType],
    tc-input-time-web[tcReferenceDate],
    tc-input-time-ionic[tcReferenceDate],
    tc-date-time-picker-ionic[tcReferenceDate]
  `,
})
export class TcInputDateTimeReferenceDirective
  implements OnChanges, OnDestroy, OnInit
{
  @Input()
  tcReferenceDate: Date;

  @Input()
  fieldType: TcInputFieldType;

  @Input()
  isDurationExtended: boolean;

  private _destroyed$ = new Subject<void>();

  constructor(private ngControl: NgControl) {}

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnChanges({ isDurationExtended, tcReferenceDate }: SimpleChanges): void {
    const { previousValue, currentValue } = tcReferenceDate ?? {};
    const hasReferenceDateChanged =
      (isValidDate(currentValue) && !previousValue) ||
      (isValidDate(previousValue) &&
        isValidDate(currentValue) &&
        !isSameMinute(previousValue, currentValue));

    if (
      this.fieldType.toLowerCase().includes('time') &&
      (isDurationExtended?.firstChange === false || hasReferenceDateChanged)
    ) {
      this.constrainValue();
    }
  }

  ngOnInit(): void {
    // NOTE: We should only contrain when own values are changed for the time
    // inputs, as date values are already constrained by the validation limit.
    if (this.fieldType.toLowerCase() === 'time') {
      this.ngControl.valueChanges
        .pipe(takeUntil(this._destroyed$))
        .subscribe(() => {
          this.constrainValue();
        });
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  // ---------------
  // Private Methods
  // ---------------

  /**
   * Constrain the form control value to the reference date
   */
  constrainValue() {
    const { control } = this.ngControl;
    const { value } = control ?? {};

    if (!isValidDate(value) || !isValidDate(this.tcReferenceDate)) {
      return;
    }

    // The date to be used as the base to constrain the value is the reference
    // date, with a 1 day offset when the duration is extended.
    const offsetDate = add(this.tcReferenceDate, {
      days: Number(this.isDurationExtended),
    });

    // To respect the base date, the value must never be before nor more
    // than 24h after it.
    if (
      isBefore(value, offsetDate) ||
      !isIntervalShorterThan24Hours({
        start: offsetDate,
        end: value,
      })
    ) {
      const valueToSet = setTimeToDate(offsetDate, value, 'afterOrEqualSource');

      control.setValue(valueToSet, { emitEvent: false });

      // HACK: Timeout used to wait before all time inputs of a form/fieldset
      // have been constrained to avoid that the still-to-constrained ones
      // become bubbles as invalid before they could even be changed.
      // This should be undone with a refactor of all time related fieldsets as
      // ControlValueAccessor.
      setTimeout(() => {
        control.updateValueAndValidity();
      }, 100);
    }
  }
}
