import { Inject, Injectable } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FrontEndConfig, FrontEndConfigPipe } from '@timecount/core';
import { ModalService, TcCodeEditorModalComponent } from '@timecount/ui';

import { RemoteConfig } from './remote_config.service';

@Injectable({
  providedIn: 'root',
})
export class SchemaService {
  constructor(
    private _translateService: TranslateService,
    private _modalService: ModalService,
    private remoteConfig: RemoteConfig,
    @Inject('ResourceConfig') private resourceConfig,
  ) {}

  getConfigsForKey(
    key: string,
  ): Observable<FrontEndConfig<'form' | 'schema'>[]> {
    const schemas$: Observable<FrontEndConfig<'schema'>[]> =
      this.remoteConfig.get('schema', key, false);

    const forms$ = from(
      this.resourceConfig.getForms(key, false) as Promise<
        FrontEndConfig<'form'>[]
      >,
    );

    return combineLatest([schemas$, forms$]).pipe(
      map(([schemas = [], forms = []]) =>
        [...schemas, ...forms]
          // NOTE: Both forms and schemas lists items are already sorted by
          // position at their respective service, but we need to sort the
          // merged list.
          .sort((a, b) => a.position - b.position),
      ),
      catchError(() => of([])),
    );
  }

  openSchemaEditor(
    config: FrontEndConfig<'form' | 'schema'>,
    { readOnly = true }: { readOnly?: boolean } = {},
  ): void {
    const value = JSON.stringify(
      new FrontEndConfigPipe().transform(config),
      null,
      2,
    );

    this._modalService.open(TcCodeEditorModalComponent, {
      data: {
        value,
        readOnly,
        alertKey:
          config.type === 'form'
            ? 'frontend_config/schema.state.converted'
            : null,
      },
      modalTitle: this._translateService.instant(
        'frontend_config/schema.editor.title' + (readOnly ? '_preview' : ''),
      ),
      adminTags: [config.key],
      minWidth: 720,
    });
  }
}
