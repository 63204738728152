export enum EntryType {
  Assignment = 'assignments',
  Timesheet = 'timesheets',
  ProjectTimesheet = 'projectTimesheets',
  Availability = 'availabilities',
  WorkEntry = 'workEntries',
  Bias = 'biases',
  ServiceEntry = 'serviceEntry',
  Aggregation = 'aggregation',
  JobRate = 'job_rate',
}
