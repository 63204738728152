import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

import { TcNoPaddingDirective } from '../../shared';

@Component({
  selector: `
    tc-panel,
    [tcPanel]
  `,
  standalone: true,
  imports: [CommonModule, NzCollapseModule],
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  hostDirectives: [
    {
      directive: TcNoPaddingDirective,
      inputs: ['tcNoPadding'],
    },
  ],
})
export class TcPanelComponent implements AfterViewInit, OnChanges {
  @Input() tcTitle: string;
  @Input() tcContentClass: string;
  @Input() tcColor: 'success' | 'warning' | 'danger' | 'admin';

  @Input()
  @HostBinding('class.collapsible')
  tcCollapsible = false;

  @Input() tcCollapsed = false;
  @Output() readonly tcCollapsedChange = new EventEmitter<boolean>();

  @Input()
  @HostBinding('class.fit-height')
  tcFitHeight = false;

  @Input('tcPanel') shouldShow = true;

  hasHeaderTemplate = true;

  @ViewChild('panel', { read: ElementRef })
  panel: ElementRef<Element>;

  @ViewChild('footer', { read: ElementRef })
  footer: ElementRef<Element>;

  @HostBinding('class.hide-header') hideHeader = false;
  @HostBinding('class.hide-footer') hideFooter = false;
  @HostBinding('class.no-content') noContent = false;
  @HostBinding('class.is-field-set') isFieldSet = false;

  constructor(private _elementRef: ElementRef) {
    this.isFieldSet = this._elementRef.nativeElement.nodeName === 'FIELDSET';
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnChanges({ tcCollapsed, tcTitle }: SimpleChanges): void {
    if ((tcCollapsed && !tcCollapsed.firstChange) || tcTitle) {
      this._setFlags();
    }
  }

  ngAfterViewInit(): void {
    this._setFlags();
  }

  // ---------------------------------------------------------------------------
  // Changes Handlers
  // ---------------------------------------------------------------------------

  onActiveChange(isActive: boolean) {
    this.tcCollapsed = !isActive;

    this._setFlags();
    this.tcCollapsedChange.emit(this.tcCollapsed);
  }

  // ---------------------------------------------------------------------------
  // Private Methods
  // ---------------------------------------------------------------------------

  private _setFlags(): void {
    if (this.panel) {
      const selectors =
        this.panel?.nativeElement.querySelectorAll('[tcheaderstart]');
      this.hasHeaderTemplate = Array.from(selectors).some((selector) => {
        const closestPanel = selector.closest('.panel');
        return this.panel?.nativeElement.isSameNode(closestPanel);
      });
    }

    this.hideHeader =
      !this.tcCollapsible &&
      !this.tcTitle &&
      !this.hasHeaderTemplate &&
      !this.panel?.nativeElement.querySelector('[tcheaderend]');

    this.hideFooter =
      this.tcCollapsed ||
      (!this.footer?.nativeElement.querySelector('[tcfooterstart]') &&
        !this.footer?.nativeElement.querySelector('[tcfooterend]'));

    this.noContent =
      this.panel?.nativeElement.querySelector('.content')?.childElementCount ===
      0;
  }
}
