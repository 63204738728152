<div class="modal-footer">
  <button
    type="button"
    nz-button
    nzType="default"
    [nzLoading]="loading"
    (click)="detachAndReset()"
  >
    {{ 'dispo/announcement.buttons.detach_tasks_and_reset' | translate }}
  </button>
  <button
    type="button"
    nz-button
    nzType="primary"
    [nzLoading]="loading"
    (click)="detach()"
  >
    {{ 'dispo/announcement.buttons.detach_tasks' | translate }}
  </button>
</div>
