import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import {
  Announcement,
  DispoAnnouncementCollection,
} from '../../collections/announcement';
import { DispoTaskCollection } from '../../collections/task';
import { DispoResourceDataStructure } from '../../datastructures/resource.service';
import { DispoFilterService } from '../../../shared/filter/filter.service';
import { SetCollection } from '../../../core/sets-collection';
import { DispoSelectorService } from '../../selector/selector.service';
import { EmployeeCollection } from '../../../employees/employee.collection';

@Component({
  selector: 'tc-hub-dan-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DANInviteComponent implements OnInit {
  @Input() announcement: Announcement;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  public resources$: Observable<any[]>;
  public invitations$: Observable<any[]>;
  public announcement$: ReplaySubject<Announcement> = new ReplaySubject(1);

  public loading = false;
  public resources: any[] = [];
  public resourcesToAdd: { [key: string]: boolean } = {};
  public resourcesAddAll = false;
  public resourcesAddAllIndeterminate = false;

  private employeeService: SetCollection;

  public announcementFilters = [];
  public currSelectedFilter;

  constructor(
    private employeeSets: EmployeeCollection,
    private filterService: DispoFilterService,
    private taskCollection: DispoTaskCollection,
    private announcementCollection: DispoAnnouncementCollection,
    private resourceDS: DispoResourceDataStructure,
    private selectorService: DispoSelectorService,
    private dispoResourceDS: DispoResourceDataStructure,
  ) {
    this.employeeService = this.employeeSets.visible();
  }

  ngOnInit() {
    this.announcement$.next(this.announcement);
    this.selectorService.setAnnouncement(this.announcement);

    this.filterService
      .getPresets('dispo.announcement.employees')
      .pipe(first())
      .subscribe((presets) => {
        this.announcementFilters = presets;

        if (presets[0]) {
          this.setPreset(presets[0].id);
        }
      });

    this.resources$ = combineLatest(
      this.employeeService.all().pipe(first()),
      this.employeesDS().pipe(first()),
      this.query(),
      this.announcement$,
    ).pipe(
      map(([employees, employeesDS, query, announcement]) => {
        const visibleEmployeeIds = employeesDS
          .filter(query)
          .map((s: any) => s.id);
        const resourceIds = announcement.resources.map((i) => i.id);

        return employees.filter(
          (s) =>
            resourceIds.indexOf(s.id) === -1 &&
            visibleEmployeeIds.indexOf(s.id) !== -1,
        );
      }),
      map((resources) => {
        this.resources = resources;
        this.updateAllResources();
        return resources.map((resource, index) =>
          Object.assign({ index: index }, resource),
        );
      }),
    );
  }

  setPreset(id) {
    this.currSelectedFilter = id;
    this.filterService.setPreset(id);
  }

  create(resource_ids: number[]) {
    this.loading = true;

    this.announcementCollection
      .observeItem(this.announcement)
      .pipe(first())
      .subscribe({
        next: (announcement) => {
          announcement = Object.assign({}, announcement);
          const resources = Object.assign([], announcement.resources);

          resource_ids.forEach((resource_id) => {
            const resource = resources.find(
              (r) => r.type === 'Employee' && r.id === resource_id,
            );

            if (!resource) {
              resources.push({ type: 'Employee', id: resource_id });
            }

            delete this.resourcesToAdd[resource_id];
          });

          announcement.resources = resources;
          this.announcementCollection
            .update(announcement.id, announcement)
            .subscribe({
              next: (announcement) => {
                this.loading = false;
                this.announcement = <Announcement>announcement;
                this.announcement$.next(this.announcement);
              },
              error: () => {
                this.loading = false;
              },
            });
        },
      });
  }

  createSelected() {
    this.loading = true;

    const selectedIds = [];

    Object.keys(this.resourcesToAdd).forEach((id) => {
      if (this.resourcesToAdd[id]) {
        selectedIds.push(parseInt(id, 10));
      }
    });

    this.create(selectedIds);
  }

  updateAllResources() {
    this.resourcesAddAllIndeterminate = false;

    Object.keys(this.resourcesToAdd).forEach((id) => {
      delete this.resourcesToAdd[id];
    });

    this.resources.forEach((resource) => {
      this.resourcesToAdd[resource.id] = this.resourcesAddAll;
    });
  }

  updateSingleResource() {
    if (Object.values(this.resourcesToAdd).every((checked) => !checked)) {
      this.resourcesAddAll = false;
      this.resourcesAddAllIndeterminate = false;
    } else if (Object.values(this.resourcesToAdd).every((checked) => checked)) {
      this.resourcesAddAll = true;
      this.resourcesAddAllIndeterminate = true;
    } else {
      this.resourcesAddAllIndeterminate = true;
    }
  }

  resource$(id: number) {
    this.dispoResourceDS.employee(id);
  }

  trackByIndex(_: number, data: any): number {
    return data.index;
  }

  createCount() {
    return Object.values(this.resourcesToAdd).filter((v) => v).length;
  }

  private employeesDS() {
    return this.resourceDS.employees();
  }

  private query() {
    return this.filterService.getQuery('dispo.announcement.employees');
  }
}
