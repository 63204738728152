<div #datePickerOrigin class="dispo-date-controls" [formGroup]="form">
  <nz-button-group #datePickerTarget>
    <button nz-button nzType="primary" (click)="moveDate(-7)">
      <i nz-icon nzType="backward" nzTheme="outline"></i>
    </button>
    <button nz-button nzType="primary" (click)="moveDate(-1)">
      <i nz-icon nzType="caret-left" nzTheme="outline"></i>
    </button>
    <button nz-button nzType="primary" class="today" (click)="todayDate()">
      {{ 'today' | translate }}
    </button>
    <button
      nz-button
      nzType="primary"
      class="calendar"
      (click)="openDatePicker($event)"
    >
      <i nz-icon nzType="calendar" nzTheme="outline"></i>
    </button>
    <button nz-button nzType="primary" (click)="moveDate(+1)">
      <i nz-icon nzType="caret-right" nzTheme="outline"></i>
    </button>
    <button nz-button nzType="primary" (click)="moveDate(+7)">
      <i nz-icon nzType="forward" nzTheme="outline"></i>
    </button>
  </nz-button-group>
</div>
