<div (click)="onClick()" class="item">
  <div class="list-items">
    <span class="dot" [ngStyle]="{ backgroundColor: color }"></span>
    <span class="department-icon">
      <i nz-icon nzType="ico:{{ icon }}"></i>
    </span>
    <span>{{ title }} </span>
  </div>
  <span
    *ngIf="this.ngControl.control.dirty"
    nz-icon
    nzType="loading"
    nzTheme="outline"
  ></span>
  <span
    *ngIf="!!value && this.ngControl.control.pristine"
    nz-icon
    nzType="check"
    nzTheme="outline"
  ></span>
</div>
