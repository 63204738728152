import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgControl } from '@angular/forms';

import {
  DateUnicodeFormat,
  isValidDate,
  parseDate,
  stringIncludesAny,
} from '@timecount/utils';

import { TcInputWebMaskType } from './input-web-mask.model';
import { TcInputWebComponent } from './input-web.component';

// Using legacy Inputmask
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Inputmask = (window as any).Inputmask;

// Used to generate min and max dates
const thisYear = new Date().getFullYear();

/**
 * A Directive to be use on web form inputs to mask common types of data
 */
@Directive({
  selector: '[tcInputMask]',
})
export class TcInputWebMaskDirective implements OnChanges, OnDestroy {
  /**
   * The type of the mask to be used.
   */
  @Input('tcInputMask')
  maskType: TcInputWebMaskType;

  @Input()
  maskedModel;

  @Output()
  maskedModelChange = new EventEmitter();

  private _destroyed$ = new Subject<void>();

  /**
   * Optional mask options
   *
   * It will override the directive default options
   *
   * @see {@link https://github.com/RobinHerbots/Inputmask#options|Docs}
   */
  @Input()
  maskOptions;

  private translations: { [key: string]: string } = {};

  /**
   * The configuration of each supported input type
   *
   * `Inputmask` does not support the browser's default input type number, so we
   * should NOT use a mask for when `maskType == number` as we currently require
   * the input to be this way.
   *
   * TODO: Find a way to implement a number mask with the input type number.
   */
  private typeOptions = {
    dateTime: {
      alias: 'dd.mm.yyyy',
      mask: '1.2.y h:s',
      placeholder: '__.__.____ __:__',
      clearMaskOnLostFocus: false,
    },
    date: {
      alias: 'dd.mm.yyyy',
      placeholder: '_',
      yearrange: { minyear: thisYear - 10, maxyear: thisYear + 10 },
      clearMaskOnLostFocus: false,
    },
    time: {
      alias: 'hh:mm',
      placeholder: '_',
      clearMaskOnLostFocus: false,
    },
    currency: {
      radixPoint: '',
      alias: 'numeric',
      placeholder: '0',
      autoGroup: true,
      autoUnmask: true,
      digits: 2,
      digitsOptional: false,
    },
    percent: {
      radixPoint: '',
      alias: 'numeric',
      placeholder: '0',
      autoUnmask: true,
      autoGroup: true,
      digits: 5,
      digitsOptional: true,
      clearMaskOnLostFocus: false,
    },
  };

  /**
   * The default options for Inputmask
   *
   * @see maskOptions
   */
  private defaultOptions = {
    insertMode: false,
    oncomplete: () => this.onMaskChange(new CustomEvent('complete')),
    onincomplete: () => this.onMaskChange(new CustomEvent('incomplete')),
    oncleared: () => this.onMaskChange(new CustomEvent('clear')),
  };

  /**
   * A local reference of the date object to be used when parsing masked values
   */
  private _parseDateReference: Date;

  private mask;
  private _isValid = false;
  private _selectionStart;
  private _selectionEnd;

  constructor(
    private ngControl: NgControl,
    private elementRef: ElementRef,
    private host: TcInputWebComponent,
    private translateService: TranslateService,
  ) {
    this.translateService
      .get(['number.currency.format.separator'])
      .pipe(takeUntil(this._destroyed$))
      .subscribe((value) => {
        Object.assign(this.translations, value);
        this.typeOptions.currency.radixPoint =
          this.typeOptions.percent.radixPoint =
            this.translations['number.currency.format.separator'];
      });
  }

  // ---------------
  // Lifecycle Hooks
  // ---------------

  ngOnChanges({ maskedModel, maskType }: SimpleChanges): void {
    if (maskType) {
      if (Object.keys(this.typeOptions).includes(maskType?.currentValue)) {
        this.setMask();

        if (!maskType.firstChange) {
          this.refreshMask();
        }
      } else if (!maskType.firstChange) {
        this.removeMask();
      }
    }

    if (maskedModel) {
      if (
        this.host.inputType !== 'number' &&
        Object.keys(this.typeOptions).includes(this.maskType)
      ) {
        this.elementRef.nativeElement.setSelectionRange(
          this._selectionStart,
          this._selectionEnd,
        );
      }

      if (
        DateUnicodeFormat[this.maskType] &&
        isValidDate(maskedModel.currentValue)
      ) {
        this._parseDateReference = maskedModel.currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this.removeMask();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  // ---------------
  // Private Methods
  // ---------------

  /**
   * Set the mask based on the passed type
   */
  private setMask(): void {
    this.mask = Inputmask(
      Object.assign(
        this.typeOptions[this.maskType],
        this.maskOptions,
        this.defaultOptions,
      ),
    ).mask(this.elementRef.nativeElement);
  }

  /**
   * Make sure that the mask is refreshed with the latest options.
   */
  private refreshMask(): void {
    // To update the mask, it needs to be formatted
    this.mask.format(this.ngControl.value, this.mask.getmetadata());

    // It will only update the interface after an user interaction, so we force
    this.elementRef.nativeElement.focus();
    this.elementRef.nativeElement.blur();
  }

  private removeMask(): void {
    this.mask?.remove();
  }

  // ---------------
  // Events Handlers
  // ---------------

  /**
   * Handle changes in the mask value
   */
  private onMaskChange(_event: Event): void {
    const { value, selectionStart, selectionEnd } =
      this.elementRef.nativeElement;

    this._selectionStart = selectionStart;
    this._selectionEnd = selectionEnd;

    const isMasked = Object.prototype.hasOwnProperty.call(
      this.typeOptions,
      this.maskType,
    );

    this.maskedModelChange.emit(
      isMasked && this.isValid(value) ? this.getParsedValue(value) : value,
    );
  }

  /**
   * Check if the masked value is valid based on the mask config
   */
  private isValid(value: string): boolean {
    this._isValid = Inputmask.isValid(value, {
      ...this.typeOptions[this.maskType],
    });

    return this._isValid;
  }

  /**
   * Parse the masked value as per each mask type config options
   */
  private getParsedValue(value: string): string | Date | number {
    let referenceDate: string;

    if (this.maskType === 'time' && isValidDate(this._parseDateReference)) {
      referenceDate = this._parseDateReference.toISOString().split('T')[0];
    }

    return stringIncludesAny(this.maskType.toLowerCase(), ['date', 'time'])
      ? parseDate(value, DateUnicodeFormat[this.maskType], referenceDate)
      : this.maskType === 'currency' || this.maskType === 'percent'
      ? value
        ? parseFloat(value.replace(',', '.'))
        : 0
      : value;
  }
}
