import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { parseDate, stringifyDate } from '@timecount/utils';

import { TcControlWidgetBaseDirective } from '../tc-widget-base.directive';

@Component({
  selector: 'tc-hub-datetime-widget',
  templateUrl: './datetime-widget.component.html',
  styleUrls: [
    '../shared-widgets-styles.scss',
    './datetime-widget.component.scss',
  ],
})
export class DatetimeWidgetComponent extends TcControlWidgetBaseDirective {
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit() {
    this._buildForm();

    this.form.get('datetime').valueChanges.subscribe((value: Date) => {
      this.formProperty.setValue(stringifyDate(value), false);
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    //FIXME: avoid setting the formGroup to prevent it from being marked as 'dirty' prior to user interaction.
    //Issue key: DEV-1814
    this.formProperty.valueChanges.subscribe((value: string) => {
      this._setFormControlValue(value);
    });

    this._setFormControlValue(this.formProperty.value);
  }

  // ---------------
  // Private Methods
  // ---------------

  private _setFormControlValue(value: string) {
    this.form.get('datetime').setValue(parseDate(value), {
      onlySelf: true,
      emitEvent: false,
    });
  }

  private _buildForm() {
    const { value } = this.formProperty;
    this.form = this.formBuilder.group({
      datetime: [
        {
          value: value ? parseDate(value) : undefined,
          disabled: this.schema.readOnly,
        },
      ],
    });
  }
}
