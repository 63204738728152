import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { startOfDay } from 'date-fns';

import { TcConfigService } from '@timecount/core';
import {
  DateUnicodeFormat,
  durationInDays,
  getMaxDurationInterval,
  isIntervalLongerThanDuration,
  isIntervalShorterThan24Hours,
  isValidDate,
  isValidInterval,
  stringifyDate,
} from '@timecount/utils';

import { TcInputService } from '../../components/input/input.service';
import { ValueAccessorDirective } from '../../shared';

import { TcFieldsetIntervalComponentConfig } from './interval.config';

@Directive()
export abstract class TcIntervalBaseDirective
  implements OnInit, Validator, OnDestroy
{
  @Input()
  set tcLabels(labels: { start: string; end: string } | 'default') {
    if (labels === 'default') {
      this._setDefaultLabels();
    } else {
      this._labels = labels;
    }
  }
  get tcLabels(): { start: string; end: string } | 'default' {
    return this._labels;
  }
  private _labels: { start: string; end: string };

  @Input() tcRefDate: Date;

  @Input() tcFieldType: 'date' | 'time' | 'dateTime';

  @Input() tcConfig: TcFieldsetIntervalComponentConfig;

  @Input() tcAllowZeroDuration: boolean;

  @Input() tcFieldWithDateOptions: 'start' | 'end' | 'both';

  @Input() tcMaxDuration: Duration | 0;

  @Input() tcDateOptionsInterval: Interval;

  @Input() tcStartLimit: Interval;
  @Input() tcEndLimit: Interval;

  innerForm: FormGroup;
  allowDoubledWorkingDay =
    this.configService.config.company.times.allowDoubledWorkingDay;

  isDurationExtended = false;
  private destroyed$ = new Subject<void>();

  constructor(
    protected _formBuilder: UntypedFormBuilder,
    protected valueAccessor: ValueAccessorDirective<Interval>,
    protected translateService: TranslateService,
    protected inputService: TcInputService,
    protected configService: TcConfigService,
  ) {}

  // ---------------------------------------------------------------------------
  // Lifecycle Hooks
  // ---------------------------------------------------------------------------

  ngOnInit(): void {
    if (this.tcFieldType === 'date') {
      this.tcMaxDuration ??= {
        days: 365,
      };
    }

    this.valueAccessor.value.subscribe((value) => {
      if (this.innerForm instanceof FormGroup) {
        this.innerForm.reset(value ?? undefined);
      } else {
        this._setForm(value);
      }
      this.isDurationExtended =
        this.allowDoubledWorkingDay &&
        isValidInterval(this.innerForm.value) &&
        !isIntervalShorterThan24Hours(this.innerForm.value);
    });

    this.valueAccessor.disabled.subscribe((isDisabled: boolean) => {
      if (this.innerForm?.disabled !== isDisabled) {
        if (isDisabled) {
          this.innerForm.disable();
        } else {
          this.innerForm.enable();
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // ---------------------------------------------------------------------------
  // Validator Implementation
  // ---------------------------------------------------------------------------

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.tcConfig.noValidators) {
      return null;
    }

    const { start, end } = control.value ?? {};

    if (isValidDate(start) && isValidDate(end)) {
      if (!isValidInterval(control.value, this.tcAllowZeroDuration)) {
        return {
          [this.tcAllowZeroDuration ? `date_same_or_after` : `date_after`]:
            true,
        };
      } else if (
        this.tcFieldType === 'date' &&
        this.tcMaxDuration !== 0 &&
        durationInDays(this.tcMaxDuration) > 0 &&
        isValidInterval(control.value) &&
        isIntervalLongerThanDuration(control.value, this.tcMaxDuration)
      ) {
        return {
          [!this.tcConfig?.exclusiveLimitDates
            ? `date_same_or_before_date`
            : `date_before_date`]: {
            maxDate: stringifyDate(
              getMaxDurationInterval(control.value, this.tcMaxDuration)
                .end as Date,
              DateUnicodeFormat[this.tcFieldType],
            ),
          },
        };
      }
    } else if (Boolean(start) !== Boolean(end)) {
      return {
        incomplete: true,
      };
    }
    return this.innerForm.valid ? null : { invalid: true };
  }

  // ---------------
  // Private Methods
  // ---------------

  private _setForm(value?: Interval) {
    value ??= { start: undefined, end: undefined };
    let { start, end } = value;
    if (this.tcFieldType === 'date') {
      start = isValidDate(start) ? startOfDay(start) : undefined;
      end = isValidDate(end) ? startOfDay(end) : undefined;
    }

    const validators = this.inputService.getInputValidators(this.tcConfig);

    this.innerForm = this._formBuilder.group({
      start: [start, validators],
      end: [end, validators],
    });

    this.innerForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.valueAccessor.valueChange(value);
        this.valueAccessor.touchedChange(true);
      });
  }

  private _setDefaultLabels(): void {
    const labelsKeys = [
      'dispo/task.attrs.starts_at',
      'dispo/task.attrs.ends_at',
    ];
    this.translateService.get(labelsKeys).subscribe((translatedValues) => {
      this._labels = {
        start: translatedValues[labelsKeys[0]],
        end: translatedValues[labelsKeys[1]],
      };
    });
  }
}
