import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { DispoScheduleCollection } from '../../../../dispo/collections/schedule';
import { Slot } from '../../../../dispo/collections/task';

@Component({
  selector: 'tc-hub-dsc-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
})
export class DSCStep4Component implements OnInit {
  @Input() config: any;
  @Output() signal: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  controlForm: UntypedFormGroup;

  constructor(
    private scheduleService: DispoScheduleCollection,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.buildForm();
    this.signal.emit({ action: 'resize' });
  }

  validate() {
    // validate starts/ends in range
  }

  set(field, value) {
    const formArrayControls = <UntypedFormArray>this.form.get('slots_config');
    formArrayControls.controls.forEach((control) => {
      control.get(field).setValue(value);
    });
  }

  prev() {
    this.signal.emit({ action: 'prev', config: this.config });
  }

  next() {
    const form = this.form.value;
    const nextConfig = { ...this.config };
    const slotsConfig = form.slots_config;

    nextConfig.slots_config = slotsConfig;
    this.signal.emit({ action: 'next', config: nextConfig });
  }

  get slots_config(): UntypedFormArray {
    return <UntypedFormArray>this.form.get('slots_config');
  }

  private buildForm() {
    const allSlots = Array.from(
      new Array(this.config.template.size),
      (_val, position) => {
        return {
          ...((this.config.template.slots_config ?? []).find(
            (s) => s.position === position,
          ) ?? {
            ...new Slot(),
            position,
          }),
        };
      },
    );

    const slotsFormGroup = allSlots.map((slot: Slot) =>
      this.formBuilder.group({
        position: [slot.position],
        gratis: [slot.gratis],
        job_id: [slot.job_id],
        group_id: [slot.group_id],
        role_ids: [slot.role_ids],
        qualification_ids: [slot.qualification_ids],
        store: [slot.store],
      }),
    );

    return this.formBuilder.group(
      {
        slots_config: this.formBuilder.array(slotsFormGroup),
        gratis: [],
        job_id: [],
        group_id: [],
        role_ids: [],
        qualification_ids: [],
        store: {},
      },
      {
        validator: Validators.compose([this.validate.bind(this)]),
      },
    );
  }
}
