<form class="modal-container" [formGroup]="form" *ngIf="form" novalidate>
  <div class="modal-body">
    <div class="container">
      <div class="alert alert-danger" *ngFor="let msg of errors">{{ msg }}</div>
      <div class="alert alert-warning" *ngFor="let msg of warnings">
        {{ msg }}
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <tc-hub-option-select
              label="{{ 'time_balance.attrs.type' | translate }}"
              type="time_balance.type"
              [formGroup]="form"
              controlName="type"
              bindLabel="title"
              bindValue="id"
            >
            </tc-hub-option-select>
          </div>
        </div>
        <div class="col-xs-6 col-md-3">
          <div class="form-group">
            <label>
              {{ 'time_balance.attrs.hours' | translate }}
              <input
                tcFormControlValidator
                class="form-control"
                type="number"
                step="0.01"
                formControlName="hours"
              />
            </label>
          </div>
        </div>
        <div class="col-xs-6 col-md-3">
          <div class="form-group">
            <label>
              {{ 'time_balance.attrs.modifier' | translate }}
              <input
                tcFormControlValidator
                class="form-control"
                type="number"
                step="0.01"
                formControlName="modifier"
              />
            </label>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group">
            <tc-input-text-web
              label="{{ 'time_balance.attrs.description' | translate }}"
              formControlName="description"
            ></tc-input-text-web>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6 col-md-3" *ngFor="let supplement of supplements">
          <div class="form-group">
            <label>
              {{ supplement.display_key }}
              <input
                tcFormControlValidator
                class="form-control"
                type="number"
                step="0.01"
                formControlName="supplement_{{ supplement.id }}"
              />
            </label>
          </div>
        </div>
      </div>

      <tc-hub-custom-fields
        section="dispo.time_balance.{{ type }}.general"
        [formGroup]="form"
        formControlName="timeBalanceTypeGeneral"
      >
      </tc-hub-custom-fields>

      <tc-hub-custom-fields
        section="dispo.time_balance.general"
        [formGroup]="form"
        formControlName="timeBalanceGeneral"
      >
      </tc-hub-custom-fields>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      nz-button
      nzType="primary"
      *ngIf="validated"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="save()"
    >
      {{ 'save_still' | translate }}
    </button>
    <button
      type="button"
      nz-button
      nzType="primary"
      *ngIf="!validated"
      [nzLoading]="loading"
      [disabled]="form.invalid"
      (click)="validate()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</form>
